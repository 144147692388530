import { EditOutlined } from "@ant-design/icons";
import { Table, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import UpdateTask from "../../Pages/Tasks/UpdateTask";
import { FaExchangeAlt } from "react-icons/fa";

import UpdateTaskStatus from "./UpdateTaskStatus";

export default function TaskTable({
  record,
  updateType,
  fetchClientById,
  fetchCaseDetail,
  fetchCompanyByID,
  fetchInstituteById,
}) {
  const [isUpdateTaskModalVisible, setIsUpdateTaskModalVisible] =
    useState(false);
  const [isStatusUpdateModalVisible, setStatusUpdateModalVisible] =
    useState(false);
  const [dataObj, setdataObj] = useState(null);

  const columns = [
    {
      title: "Task Title",
      dataIndex: "taskTitle",
      sorter: (a, b) => {
        if (a.taskTitle < b.taskTitle) {
          return -1;
        }
        if (a.taskTitle > b.taskTitle) {
          return 1;
        }
        return 0;
      },
      align: "center",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      align: "center",
      render: (data) => (data ? moment(data).format("YYYY-MM-DD") : "N/A"),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      align: "center",
      render: (data) => (data ? moment(data).format("YYYY-MM-DD") : "N/A"),
    },
    {
      title: "Task Status",
      dataIndex: "taskStatus",
      align: "center",
      render: (data) =>
        data === "PENDING" ? (
          <p className="blueTag">PENDING</p>
        ) : data === "ASSIGNED" ? (
          <p className="orangeTag">ASSIGNED</p>
        ) : data === "COMPLETED" ? (
          <p className="greenTag">COMPLETED</p>
        ) : (
          "N/A"
        ),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      align: "center",
      render: (data) =>
        data === "HIGH" ? (
          <p className="blueTag">HIGH</p>
        ) : data === "URGENT" ? (
          <p className="redTag">URGENT</p>
        ) : data === "NORMAL" ? (
          <p className="greenTag">NORMAL</p>
        ) : (
          "N/A"
        ),
    },
    {
      title: "Assigned By",
      dataIndex: "assignedBy",
      align: "center",
      render: (data) =>
        data?.emailId ? (
          <li
            style={{
              padding: "0.1rem 0.5rem",
              borderRadius: "0.5rem",
              border: "1px solid #ccc",
              background: "#e6f4ff30",
              cursor: "pointer",
            }}
          >
            {data?.emailId}
          </li>
        ) : (
          "N/A"
        ),
    },
    {
      title: "Assigned To",
      dataIndex: "assignedToResponse",
      align: "center",
      render: (data) => {
        return data?.length > 0 ? (
          <div
            style={{ display: "flex", gap: "0.2rem", flexDirection: "column" }}
          >
            {data?.map((item) => (
              <li
                style={{
                  padding: "0.1rem 0.5rem",
                  borderRadius: "0.5rem",
                  border: "1px solid #ccc",
                  background: "#e6f4ff30",
                  cursor: "pointer",
                }}
              >
                {item?.email}
              </li>
            ))}
          </div>
        ) : (
          "N/A"
        );
      },
    },
    {
      title: "Actions",
      align: "center",
      fixed: "right",
      render: (data) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <Tooltip title="Update Task Status">
            <FaExchangeAlt
              onClick={() => {
                setdataObj(data);
                setStatusUpdateModalVisible(true);
              }}
              style={{ cursor: "pointer", color: "#1890ff" }}
            />
          </Tooltip>
          <Tooltip title="Update Task">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setdataObj(data);
                  setIsUpdateTaskModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table
        style={{ width: "100%" }}
        columns={columns}
        dataSource={record}
        pagination={false}
      />
      {isUpdateTaskModalVisible && (
        <UpdateTask
          record={dataObj}
          fetchClientById={fetchClientById}
          fetchCaseDetail={fetchCaseDetail}
          fetchCompanyByID={fetchCompanyByID}
          fetchInstituteById={fetchInstituteById}
          isUpdateTaskModalVisible={isUpdateTaskModalVisible}
          setIsUpdateTaskModalVisible={setIsUpdateTaskModalVisible}
        />
      )}

      {isStatusUpdateModalVisible && (
        <UpdateTaskStatus
          record={dataObj}
          updateType={updateType}
          fetchClientById={fetchClientById}
          fetchCaseDetail={fetchCaseDetail}
          fetchCompanyByID={fetchCompanyByID}
          fetchInstituteById={fetchInstituteById}
          isStatusUpdateModalVisible={isStatusUpdateModalVisible}
          setStatusUpdateModalVisible={setStatusUpdateModalVisible}
        />
      )}
    </div>
  );
}
