import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Radio,
  Select,
  Space,
  TimePicker,
} from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  date,
  dateAndTime,
  getTimeZone,
  scrollToTopFormError,
  YYYYMMDD,
} from "../../HelperFunction";
import moment from "moment/moment";
import StatusHistory from "./StatusHistory";
import AddSalesRep from "../Visas/AddSalesRep";
import Cookies from "js-cookie";
import axios from "axios";
const { Option } = Select;
const { TextArea } = Input;

const UpdateLead = ({
  record,
  isUpdateLeadModelVisible,
  setIsUpdateLeadModelVisible,
  isAddContactVisible,
  setIsAddContactVisible,
  isAddAboutUsModalVisible,
  setIsAddAboutUsModalVisible,
  isAddLeadSourceModalVisible,
  setIsAddLeadSourceModalVisible,
  isAddLeadStatusModalVisible,
  setIsAddLeadStatusModalVisible,
  isAddPurposeModalVisible,
  setIsAddPurposeModalVisible,
  setIsAddCountryModalVisible,
  setIsAddConsultantModalVisible,
  setIsAddReferralModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      timingDropdownState: state.AppointmentsReducer,
      leadState: state.LeadReducer,
    };
  });
  const addContactsState = useSelector((state) => state.LeadReducer);

  const [contactId, setContactId] = useState();
  const [purposeId, setPurposeId] = useState();
  const [aboutUsId, setAboutUsId] = useState();
  const [countryId, setCountryId] = useState();
  const [leadSourceId, setLeadSourceId] = useState();
  const [referredById, setReferredById] = useState();
  // const [fieldsData, setFieldsData] = useState({
  //   contact: contactRecord ? `${record.firstName} ${record.lastName}` : "",
  //   bookingStatus: false,
  //   todayDescription: record.todayDescription,
  //   sendByEmail: record.sendByEmail,
  //   status: isCouncelling,
  //   isReferral: record.referral,
  //   description: record?.description,
  //   remainderTime: moment(),
  //   remainderDate: moment(),
  //   referredBy: record?.referredByFullName,
  //   leadType: record?.leadType,
  //   leadSource: record?.leadSourceName,
  //   country: record?.countryName,
  //   aboutUs: record?.aboutUs,
  //   purpose: record?.purposeName,
  //   bookingStatus: record?.bookingStatus,
  //   mobile: record?.phoneNumber,
  //   email: record?.email,
  //   lastName: record?.lastName,
  //   firstName: record?.firstName,
  // });

  const [contactName, setContactName] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [purposeName, setPurposeName] = useState();
  const [aboutUsName, setAboutUsName] = useState();
  const [countryName, setCountryName] = useState();
  const [leadType, setLeadType] = useState();
  const [countryOfResidence, setCountryOfResidence] = useState();
  const [countryToMigrate, setCountryToMigrate] = useState();
  const [leadSourceName, setLeadSourceName] = useState();
  const [referralName, setReferralName] = useState();
  const [reminderDate, setReminderDate] = useState();
  const [reminderTime, setReminderTime] = useState();
  const [description, setDescription] = useState();
  const [todayDescription, setTodayDescription] = useState();

  // const [contactId, setContactId] = useState();
  // const [contactId, setContactId] = useState();
  // const [contactId, setContactId] = useState();
  // const [contactId, setContactId] = useState();
  // const [contactId, setContactId] = useState();
  const [isBookingCouncelling, setIsBookingCouncelling] = useState(false);
  const [isStatusHistoryModelVisible, setIsStatusHistoryModelVisible] =
    useState(false);

  const [isCouncelling, setIsCouncelling] = useState(false);
  const [isReferral, setIsReferral] = useState(false);
  const [contactRecord, setContactRecord] = useState(record);
  const [counsellorId, setCounsellorId] = useState();
  const [appointmentDate, setAppointmentDate] = useState();
  const [sessionTime, setSessionTime] = useState();
  const [activeEmployees, setActiceEmployees] = useState([]);
  const [leadData, setLeadData] = useState({
    salesRepresentativeName: record?.salesRepName,
    salesRepresentativeId: record?.salesRepId,
    updateWeightage: record?.weightage,
  });
  const [isAddSalesRepModalVisible, setIsAddSalesRepModalVisible] =
    useState(false);
  useEffect(() => {
    if (counsellorId && appointmentDate && sessionTime) {
      dispatch({
        type: "FETCH_TIMING_DROPDOWN_REQUEST",
        payload: {
          counsellorId,
          sessionTime,
          values: {
            appointmentDate: appointmentDate ? YYYYMMDD(appointmentDate) : null,
            timeOffset: getTimeZone(),
          },
        },
      });
    }
  }, [counsellorId, appointmentDate, sessionTime]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    if (isUpdateLeadModelVisible === true) {
      setIsCouncelling(record.bookingStatus);
      setIsReferral(record.referral);
      if (record) {
        setContactRecord(record);
        setContactName(`${record?.firstName} ${record?.lastName}`);
        setFirstName(record?.firstName);
        setLastName(record?.lastName);
        setEmail(record?.email);
        setMobileNumber(record?.mobile);

        setContactId(record.customerResponse?.id);
        setPurposeId(record.purposeId);
        setAboutUsId(record.aboutUsId);
        setLeadSourceId(record.leadSourceId);
        setLeadType(record.leadType);
        setCountryOfResidence(record.customerResponse?.countryOfResidence);
        setIsReferral(record.referral);
        setReferredById(record.referredById);
        setIsCouncelling(record?.leadStatus?.statusName);
        setReminderDate(record.reminderDateAndTime);
        setReminderTime(record.reminderDateAndTime);
        setIsBookingCouncelling(record.assignCounsellor);
        setPurposeName(record?.purposeName);
        setCountryToMigrate(record?.countryToMigrate);
        setAboutUsName(record?.aboutUs);
        setCountryName(record?.countryName);
        setLeadSourceName(record?.leadSourceName);
        setReferralName(
          record?.referredByFirstName && record?.referredByLastName
            ? `${record?.referredByFirstName} ${record?.referredByLastName}`
            : ""
        );
        setDescription(record?.description);
        setTodayDescription(record?.todayDescription);

        setSessionTime(record.counsellingBooking?.sessionTime);

        setAppointmentDate(record.appointmentDate);
        setCounsellorId(record.assignedCounsellor?.id);
      }
    }
  }, [isUpdateLeadModelVisible]);
  useEffect(() => {
    fetchActiveEmployeList();
  }, []);

  const fetchActiveEmployeList = async () => {
    const tempToken = Cookies.get("accessToken");
    await axios
      .get(`v1/api/client/get-active-user-list`, {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      })
      .then((res) => {
        setActiceEmployees(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    dispatch({ type: "FETCH_LEADS_DROPDOWN_REQUEST" });
  }, [isAddContactVisible]);
  const updateLeadSubmitHandler = (values) => {
    const reminderDateAndTime = dateAndTime(
      values.remainderDate,
      values.remainderTime
    );
    const bookingDate =
      isCouncelling && values.appointmentDate
        ? date(values.appointmentDate)
        : null;
    const bookingDay = values.appointmentDate
      ? moment(values.appointmentDate).format("dddd")
      : null;
    console.log("date and time", reminderDateAndTime);
    const formData = {
      ...values,
      contactId,
      purposeId,
      aboutUsId,
      countryId,
      referral: isReferral,
      referredById: isReferral ? referredById : null,
      leadSourceId,
      leadType,
      countryOfResidence: countryOfResidence,
      reminderDateAndTime,
      branchId: parseInt(localStorage.getItem("branchId")),
      appointmentDate: values.appointmentDate
        ? date(values.appointmentDate)
        : null,
      assignCounsellor: isBookingCouncelling,
      bookingDate,
      bookingDay,
      consultantId: counsellorId,
      sendByEmail: values.sendByEmail ? values.sendByEmail : false,
      salesRepresentativeId: leadData?.salesRepresentativeId
        ? leadData?.salesRepresentativeId
        : null,
      updateWeightage: leadData?.updateWeightage
        ? leadData?.updateWeightage
        : null,
      assignedToEmail: values?.assignedToEmail,
    };
    console.log("values", values);
    dispatch({
      type: "UPDATE_LEAD_REQUEST",
      payload: { id: record.id, formData },
    });
    dispatch({ type: "CLEAR_AVAILABLE_DATE_AND_TIME" });

    form.resetFields();
    setIsUpdateLeadModelVisible(false);
  };

  console.log("record", record);

  return (
    <Drawer
      title="Update Lead"
      placement="right"
      open={isUpdateLeadModelVisible}
      onClose={() => {
        setIsUpdateLeadModelVisible(false);
      }}
      width={1163}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateLeadSubmitHandler}
        onFinishFailed={scrollToTopFormError}
        colon={true}
        form={form}
        className="drawerStyle"
        initialValues={{
          assignedToEmail: record?.leadAssignee,
        }}
        fields={[
          {
            name: ["contact"],
            value: contactName,
          },
          {
            name: ["firstName"],
            value: firstName,
          },
          {
            name: ["lastName"],
            value: lastName,
          },
          {
            name: ["email"],
            value: email,
          },
          {
            name: ["mobile"],
            value: mobileNumber,
          },
          {
            name: ["bookingStatus"],
            value: isBookingCouncelling,
          },

          {
            name: ["purpose"],
            value: purposeName,
          },
          {
            name: ["aboutUs"],
            value: aboutUsName,
          },
          {
            name: ["country"],
            value: countryName,
          },
          {
            name: ["leadSource"],
            value: leadSourceName,
          },
          {
            name: ["leadType"],
            value: leadType ?? "SELF_ASSIGNED",
          },
          {
            name: ["countryToMigrate"],
            value: countryToMigrate,
          },
          {
            name: ["countryOfResidence"],
            value: countryOfResidence,
          },

          {
            name: ["referredBy"],
            value: referralName,
          },
          {
            name: ["remainderDate"],
            value: reminderDate ? moment.utc(reminderDate).local() : "",
          },
          {
            name: ["remainderTime"],
            value: reminderTime ? moment.utc(reminderTime).local() : "",
          },
          {
            name: ["description"],
            value: description,
          },

          {
            name: ["isReferral"],
            value: isReferral,
          },
          {
            name: ["todayDescription"],
            value: todayDescription,
          },
          {
            name: ["status"],
            value: isCouncelling,
          },
          {
            name: ["counsellor"],
            value: counsellorId,
          },
          {
            name: ["appointmentDate"],
            value: appointmentDate ? moment.utc(appointmentDate).local() : "",
          },
          {
            name: ["sessionTime"],
            value: sessionTime ? sessionTime : null,
          },

          {
            name: ["sendByEmail"],
            value: record?.sendByEmail,
          },
          {
            name: ["salesRepresentative"],
            value: leadData?.salesRepresentativeName,
          },
          {
            name: ["updateWeightage"],
            value: leadData?.updateWeightage,
          },
          {
            name: ["campaign"],
            value: record?.customerResponse?.campaign?.name
              ? record?.customerResponse?.campaign?.name
              : record?.campaign,
          },
        ]}
        // initialValues={{
        //   contact: contactRecord
        //     ? `${contactRecord.firstName} ${contactRecord.lastName}`
        //     : "",
        //   bookingStatus: false,
        //   todayDescription: record.todayDescription,
        //   sendByEmail: record.sendByEmail,
        //   status: isCouncelling,
        //   isReferral: record.referral,
        //   description: record?.description,
        //   remainderTime: moment(),
        //   remainderDate: moment(),
        //   referredBy: record?.referredByFullName,
        //   leadType: record?.leadType,
        //   leadSource: record?.leadSourceName,
        //   country: record?.countryName,
        //   aboutUs: record?.aboutUs,
        //   purpose: record?.purposeName,
        //   bookingStatus: record?.bookingStatus,
        //   mobile: contactRecord?.phoneNumber || record?.mobile,
        //   email: contactRecord?.email || record?.email,
        //   lastName: contactRecord?.lastName || record?.lastName,
        //   firstName: contactRecord?.firstName || record?.firstName,
        //   contact: contactRecord
        //     ? `${contactRecord.firstName} ${contactRecord.lastName}`
        //     : "",
        // }}
      >
        <div className="flexColumn">
          <div>
            <h2 className="cardHeader">Update Lead</h2>
            <div className="flexRowWithoutStyle" style={{ gap: "0.3rem" }}>
              <Form.Item
                label="Select Contact"
                name={"contact"}
                style={{ width: "95%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter contact!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a Contact"
                  optionFilterProp="children"
                  onSearch={(e) => {
                    console.log("On search", e);
                  }}
                  filterOption={(input, option) => {
                    console.log("input and option", input, option);
                    return (option?.children?.props?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {store.leadState.leadsDropdown?.contacts?.map((dataObj) => {
                    return (
                      <Option key={dataObj.id} value={dataObj.id}>
                        <div
                          onClick={() => {
                            setContactId(dataObj.id);
                            setContactRecord(dataObj);
                            setContactName(
                              `${dataObj.firstName} ${dataObj.lastName}`
                            );
                            setFirstName(dataObj?.firstName);
                            setLastName(dataObj?.lastName);
                            setEmail(dataObj?.email);
                            setMobileNumber(dataObj?.phoneNumber);
                          }}
                          className={"selectOptionDiv"}
                        >
                          {`${dataObj.firstName} ${dataObj?.lastName} 
                            ${
                              dataObj.email !== null && dataObj.email !== ""
                                ? `(${dataObj.email})`
                                : dataObj.phoneNumber !== null &&
                                  dataObj.phoneNumber !== ""
                                ? `(${dataObj.phoneNumber ?? ""})`
                                : ""
                            }
                            `}
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <a
                className="plusButton"
                onClick={() => {
                  setIsAddContactVisible(true);
                }}
              >
                <AiOutlinePlus className="iconColor" />
              </a>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="First Name"
                name={"firstName"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter first name!",
                  },
                ]}
              >
                <Input
                  placeholder="First Name"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name={"lastName"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter last name!",
                  },
                ]}
              >
                <Input
                  placeholder="Last Name"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Email"
                name={"email"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     type: "email",
                //     message: "Please enter email!",
                //   },
                // ]}
              >
                <Input
                  type="email"
                  placeholder="user@domain.com"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Mobile Number"
                name={"mobile"}
                style={{ width: "100%" }}
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please enter mobile number!",
                  // },
                  {
                    pattern: /^[\d]{9,11}$/,
                    message: "Mobile number should contain 9 to 11 numbers",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Mobile Number"
                  onChange={(e) => {
                    setMobileNumber(e.target.value);
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Purpose"
                  name={"purpose"}
                  style={{ width: "95%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select your purpose!",
                    },
                  ]}
                >
                  <Select>
                    {store.leadState.leadsDropdown?.purposes?.map((dataObj) => {
                      return (
                        <Option key={dataObj.id}>
                          <div
                            onClick={() => {
                              setPurposeId(dataObj.id);
                              setPurposeName(dataObj.purposeName);
                            }}
                          >
                            {dataObj.purposeName}
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddPurposeModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="How do you know about us?"
                  name={"aboutUs"}
                  style={{ width: "95%" }}
                >
                  <Select>
                    {store.leadState.leadsDropdown?.aboutUs?.map((dataObj) => {
                      return (
                        <Option key={dataObj.id}>
                          <div
                            onClick={() => {
                              setAboutUsId(dataObj.id);
                              setAboutUsName(dataObj.aboutUs);
                            }}
                          >
                            {dataObj.aboutUs}
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddAboutUsModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
            </div>

            <Form.Item label="Description" name={"description"}>
              <TextArea
                placeholder="Write something here"
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </Form.Item>
            {/* <Form.Item
              label="Want to book for councelling?"
              name={"bookingStatus"}
              rules={[
                {
                  required: true,
                  message: "Please select a option!",
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => {
                  setIsCouncelling(e.target.value);
                }}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            {isCouncelling === true && (
              <>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.3rem", width: "100%" }}
                  >
                    <Form.Item
                      label="Counsellor"
                      name={"counsellor"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please select counsellor!",
                        },
                      ]}
                    >
                      <Select
                        onChange={(e) => {
                          setCounsellorId(e);
                        }}
                      >
                        {store.leadState?.leadsDropdown?.consultantList?.map(
                          (dataObj) => {
                            return (
                              <Option key={dataObj.id}>
                                {dataObj.firstName} {dataObj.lastName}
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    <a
                      className="plusButton"
                      onClick={() => {
                        setIsAddConsultantModalVisible(true);
                      }}
                    >
                      <AiOutlinePlus className="iconColor" />
                    </a>
                  </div>
                  <Form.Item
                    label="Booking Date"
                    name={"bookingDate"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please pick a booking date!",
                      },
                    ]}
                  >
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      disabledDate={(cd) => {
                        const d = new Date();
                        return cd < d;
                      }}
                      onChange={(e) => {
                        setAppointmentDate(YYYYMMDD(e));
                      }}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  label="Session Time"
                  name={"sessionTime"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select a session time!",
                    },
                  ]}
                >
                  <Select
                    onChange={(e) => {
                      console.log(e);
                      setSessionTime(e);
                    }}
                  >
                    <Option value={"THIRTY_MINUTES"}>30 Minutes</Option>
                    <Option value={"ONE_HOUR"}>1 Hour</Option>
                    <Option value={"TWO_HOUR"}>2 Hour</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Available Time Slots"
                  name={"startTime"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please pick a your time slot!",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      console.log("selected start time: ", e.target.value);
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {store.timingDropdownState &&
                      store.timingDropdownState.timingDropdown.map(
                        (dataObj, index) => {
                          return (
                            <div key={index}>
                              <Radio value={dataObj.startTime}>
                                {dataObj.startTime} - {dataObj.endTime}
                              </Radio>
                            </div>
                          );
                        }
                      )}
                  </Radio.Group>
                </Form.Item>
              </>
            )} */}
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              {purposeName?.toUpperCase() === "MIGRATION" && (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.3rem", width: "100%" }}
                >
                  <Form.Item
                    label="Country to Migrate"
                    name={"countryToMigrate"}
                    style={{ width: "100%" }}
                  >
                    <Select
                      optionFilterProp="children"
                      showSearch
                      filterOption={(input, option) => {
                        console.log("input and option", input, option);
                        return (option?.value ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      {store.leadState?.leadsDropdown?.countries?.map(
                        (dataObj) => {
                          return (
                            <Option
                              key={dataObj.id}
                              value={dataObj.countryName}
                            >
                              <div
                                onClick={() => {
                                  setCountryToMigrate(dataObj.countryName);
                                }}
                                className={"selectOptionDiv"}
                              >
                                {dataObj.countryName}
                              </div>
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                  <a
                    className="plusButton"
                    onClick={() => {
                      setIsAddCountryModalVisible(true);
                    }}
                  >
                    <AiOutlinePlus className="iconColor" />
                  </a>
                </div>
              )}

              {purposeName?.toUpperCase() === "EDUCATION" && (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.3rem", width: "100%" }}
                >
                  <Form.Item
                    label="Country to Study"
                    name={"country"}
                    style={{ width: "100%" }}
                  >
                    <Select
                      optionFilterProp="children"
                      showSearch
                      filterOption={(input, option) => {
                        console.log("input and option", input, option);
                        return (option?.children?.props?.children ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      {store.leadState.leadsDropdown?.countries?.map(
                        (dataObj) => {
                          return (
                            <Option key={dataObj.id}>
                              <div
                                onClick={(e) => {
                                  setCountryId(dataObj.id);
                                  setCountryName(dataObj.countryName);
                                }}
                              >
                                {dataObj.countryName}
                              </div>
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                  <a
                    className="plusButton"
                    onClick={() => {
                      setIsAddCountryModalVisible(true);
                    }}
                  >
                    <AiOutlinePlus className="iconColor" />
                  </a>
                </div>
              )}
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Lead Source"
                  name={"leadSource"}
                  style={{ width: "95%" }}
                >
                  <Select>
                    {store.leadState.leadsDropdown?.leadSource?.map(
                      (dataObj) => {
                        return (
                          <Option key={dataObj.id}>
                            <div
                              onClick={() => {
                                setLeadSourceId(dataObj.id);
                                setLeadSourceName(dataObj.leadSource);
                              }}
                            >
                              {dataObj.leadSource}
                            </div>
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddLeadSourceModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                label="Campaign"
                name={"campaign"}
                style={{ width: "100%" }}
              >
                <Select>
                  {addContactsState.leadsDropdown?.campaign?.map((dataObj) => {
                    return (
                      <Option value={dataObj.campaignName}>
                        {dataObj.campaignName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Lead Assign to"
                name={"assignedToEmail"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select weightage",
                //   },
                // ]}
              >
                <Select placeholder="Lead Assign to">
                  {activeEmployees?.map((emp) => (
                    <Select.Option value={emp.emailId}>
                      {emp.fullName}({emp.emailId})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                label="Weightage"
                name={"updateWeightage"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select weightage",
                //   },
                // ]}
              >
                <Select>
                  {store.leadState?.leadsDropdown?.weightage?.map(
                    (dataObj, index) => {
                      return (
                        <Option key={dataObj}>
                          <div
                            onClick={(e) => {
                              setLeadData((previouData) => {
                                return {
                                  ...previouData,
                                  updateWeightage: dataObj,
                                };
                              });
                            }}
                          >
                            {dataObj}
                          </div>
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Country of Residence"
                  name={"countryOfResidence"}
                  style={{ width: "100%" }}
                >
                  <Select
                    optionFilterProp="children"
                    showSearch
                    filterOption={(input, option) => {
                      console.log("input and option", input, option);
                      return (option?.children?.props?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {store.leadState?.leadsDropdown?.countries?.map(
                      (dataObj) => {
                        return (
                          <Option key={dataObj.id} value={dataObj.countryName}>
                            <div
                              onClick={() => {
                                setCountryOfResidence(dataObj.countryName);
                              }}
                              className={"selectOptionDiv"}
                            >
                              {dataObj.countryName}
                            </div>
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddCountryModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
            </div>
            {/* <Form.Item
            label="Lead Source (Attachment)"
            name={"knowAbout"}
            rules={[
              {
                required: true,
                message: "Please select a option!",
              },
            ]}
          >
            <input type={"file"} />
          </Form.Item> */}
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Referral"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select a option!",
                  },
                ]}
              >
                <Radio.Group
                  value={isReferral}
                  onChange={(e) => {
                    console.log("referral radio", e.target.value);
                    setIsReferral(e.target.value);
                  }}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              {isReferral === true && (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.3rem", width: "100%" }}
                >
                  <Form.Item
                    label="Referral"
                    name={"referredBy"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select a referral!",
                      },
                    ]}
                  >
                    <Select>
                      {store.leadState.leadsDropdown?.references?.map(
                        (dataObj) => {
                          return (
                            <Option key={dataObj.id}>
                              <div
                                onClick={() => {
                                  setReferredById(dataObj.id);
                                  setReferralName(
                                    `${dataObj.firstName} ${dataObj.lastName}`
                                  );
                                }}
                              >
                                {dataObj.firstName} {dataObj.lastName}
                              </div>
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                  <a
                    className="plusButton"
                    onClick={() => {
                      setIsAddReferralModalVisible(true);
                    }}
                  >
                    <AiOutlinePlus className="iconColor" />
                  </a>
                </div>
              )}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Sales Reps"
                  name={"salesRepresentative"}
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select Sales Reps",
                  //   },
                  // ]}
                >
                  <Select>
                    {store.leadState?.leadsDropdown?.salesRepresentativeResponse?.map(
                      (dataObj) => {
                        return (
                          <Option key={dataObj.id}>
                            <div
                              onClick={() => {
                                setLeadData((previouData) => {
                                  return {
                                    ...previouData,
                                    salesRepresentativeId: dataObj.id,
                                    salesRepresentativeName:
                                      dataObj.salesRepName,
                                  };
                                });
                                // setSalesRepresentativeId(dataObj.id);
                                // setSalesRepresentativeName(dataObj.salesRepName);
                              }}
                            >
                              {dataObj.salesRepName}
                            </div>
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddSalesRepModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
              <Form.Item
                label="Lead Type"
                name={"leadType"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter lead type!",
                  },
                ]}
              >
                <Select
                  onChange={(e) => {
                    setLeadType(e);
                  }}
                >
                  <Option value={"SELF ASSIGNED"}>Self Assigned</Option>
                  <Option value={"COMPANY ASSIGNED"}>Company Assigned</Option>
                </Select>
              </Form.Item>
            </div>
            <Space direction="hoizontal">
              <div
                className="flexRowWithoutStyle"
                style={{
                  gap: "0.3rem",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item
                  label="Reminder Date"
                  name={"remainderDate"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please pick a remainder date!",
                    },
                  ]}
                >
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    disabledDate={(currentDate) => {
                      const date = new Date();
                      return currentDate < date;
                    }}
                    onChange={(e) => {
                      setReminderDate(e);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Reminder Time"
                  name={"remainderTime"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please pick a reminder time!",
                    },
                  ]}
                >
                  <TimePicker
                    onChange={(e) => {
                      setReminderTime(e);
                    }}
                  />
                </Form.Item>
              </div>
            </Space>

            {/* <Form.Item
            label="Attach Document"
            name={"knowAbout"}
            rules={[
              {
                required: true,
                message: "Please select a file!",
              },
            ]}
          >
            <input type={"file"} />
          </Form.Item> */}
          </div>
          <div>
            <h2 className="cardHeader">
              Status Update{" "}
              <Button
                type="primary"
                onClick={() => {
                  setIsStatusHistoryModelVisible(true);
                }}
                style={{ float: "right" }}
              >
                Status Update
              </Button>
            </h2>
            <Form.Item
              label="Today's Description"
              name={"todayDescription"}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select a file!",
              //   },
              // ]}
            >
              <TextArea
                placeholder="Type here"
                onChange={(e) => {
                  setTodayDescription(e.target.value);
                }}
                disabled
              />
            </Form.Item>
            <div className="flexRowWithoutStyle" style={{ gap: "0.3rem" }}>
              <Form.Item
                label="Status"
                name={"status"}
                style={{ width: "95%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select a status!",
                //   },
                // ]}
              >
                <Select value={isCouncelling} disabled>
                  {store.leadState.leadsDropdown?.leadStatus?.map((dataObj) => {
                    return (
                      <Option key={dataObj.id}>
                        <div
                          onClick={() => {
                            setIsCouncelling(dataObj.statusName);
                          }}
                        >
                          {dataObj.statusName}
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <a
                className="plusButton"
                onClick={() => {
                  setIsAddLeadStatusModalVisible(true);
                }}
              >
                <AiOutlinePlus className="iconColor" />
              </a>
            </div>
            {isCouncelling === "BOOK_AN_APPOINTMENT" ? (
              <>
                <Form.Item
                  label="Want to confirm appointment now?"
                  name={"bookingStatus"}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select a option!",
                  //   },
                  // ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      setIsBookingCouncelling(e.target.value);
                    }}
                    disabled
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                {isBookingCouncelling === false ? (
                  <>
                    <Form.Item
                      label="Appointment Date"
                      name={"appointmentDate"}
                      style={{ width: "100%" }}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please enter appointment date!",
                      //   },
                      // ]}
                    >
                      <DatePicker
                        onChange={(e) => {
                          setAppointmentDate(e);
                        }}
                        disabled
                      />
                    </Form.Item>
                    <div
                      className="flexRowWithoutStyle"
                      style={{ gap: "0.3rem", width: "100%" }}
                    >
                      <Form.Item
                        label="Counsellor"
                        name={"counsellor"}
                        style={{ width: "100%" }}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please select counsellor!",
                        //   },
                        // ]}
                      >
                        <Select disabled>
                          {store.leadState?.leadsDropdown?.consultantList?.map(
                            (dataObj) => {
                              return (
                                <Option key={dataObj.id} value={dataObj.id}>
                                  <div
                                    onClick={() => {
                                      setCounsellorId(dataObj.id);
                                    }}
                                    className={"selectOptionDiv"}
                                  >
                                    {dataObj.firstName} {dataObj.lastName}
                                  </div>
                                </Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                      <a
                        className="plusButton"
                        onClick={() => {
                          setIsAddConsultantModalVisible(true);
                        }}
                      >
                        <AiOutlinePlus className="iconColor" />
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="flexRowWithoutStyle"
                      style={{ justifyContent: "space-between", gap: "1rem" }}
                    >
                      <div
                        className="flexRowWithoutStyle"
                        style={{ gap: "0.3rem", width: "100%" }}
                      >
                        <Form.Item
                          label="Counsellor"
                          name={"counsellor"}
                          style={{ width: "100%" }}
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please select counsellor!",
                          //   },
                          // ]}
                        >
                          <Select disabled>
                            {store.leadState?.leadsDropdown?.consultantList?.map(
                              (dataObj) => {
                                return (
                                  <Option key={dataObj.id} value={dataObj.id}>
                                    <div
                                      onClick={() => {
                                        setCounsellorId(dataObj.id);
                                      }}
                                      className={"selectOptionDiv"}
                                    >
                                      {dataObj.firstName} {dataObj.lastName}
                                    </div>
                                  </Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>
                        <a
                          className="plusButton"
                          onClick={() => {
                            setIsAddConsultantModalVisible(true);
                          }}
                        >
                          <AiOutlinePlus className="iconColor" />
                        </a>
                      </div>
                      <Form.Item
                        label="Booking Date"
                        name={"appointmentDate"}
                        style={{ width: "100%" }}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please pick a booking date!",
                        //   },
                        // ]}
                      >
                        <DatePicker
                          format={"DD-MM-YYYY"}
                          disabledDate={(cd) => {
                            const d = new Date();
                            return cd < d;
                          }}
                          onChange={(e) => {
                            setAppointmentDate(e);
                          }}
                          disabled
                        />
                      </Form.Item>
                    </div>
                    <Form.Item
                      label="Session Time"
                      name={"sessionTime"}
                      style={{ width: "100%" }}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please select a session time!",
                      //   },
                      // ]}
                    >
                      <Select
                        onChange={(e) => {
                          console.log(e);
                          setSessionTime(e);
                        }}
                        disabled
                      >
                        <Option value={"THIRTY_MINUTES"}>30 Minutes</Option>
                        <Option value={"ONE_HOUR"}>1 Hour</Option>
                        <Option value={"TWO_HOUR"}>2 Hour</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Available Time Slots"
                      name={"startTime"}
                      style={{ width: "100%" }}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please pick a your time slot!",
                      //   },
                      // ]}
                    >
                      <Radio.Group
                        onChange={(e) => {
                          console.log("selected start time: ", e.target.value);
                        }}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                        disabled
                      >
                        {store.timingDropdownState &&
                          store.timingDropdownState.timingDropdown.map(
                            (dataObj, index) => {
                              return (
                                <div key={index}>
                                  <Radio value={dataObj.startTime}>
                                    {dataObj.startTime} - {dataObj.endTime}
                                  </Radio>
                                </div>
                              );
                            }
                          )}
                      </Radio.Group>
                    </Form.Item>
                  </>
                )}
              </>
            ) : null}
            <Form.Item name={"sendByEmail"} valuePropName="checked">
              <Checkbox
                onChange={(e) => {
                  console.log(e);
                }}
              >
                Send email
              </Checkbox>
            </Form.Item>
          </div>
        </div>
        <Form.Item
          name="leadSubmit"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                console.log("value frm head agent", value);
                console.log(
                  "is percentage frm head agent",
                  getFieldValue("mobile"),
                  getFieldValue("email")
                );
                if (
                  (getFieldValue("email") === undefined ||
                    getFieldValue("email") === "") &&
                  (getFieldValue("mobile") === undefined ||
                    getFieldValue("mobile") === "")
                ) {
                  return Promise.reject(
                    new Error("Please enter either email or phone number!")
                  );
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
          hasFeedback
        >
          <Button name="leadSubmit" block type="primary" htmlType="submit">
            Update Lead
          </Button>
        </Form.Item>
      </Form>
      <StatusHistory
        type={"LEAD"}
        record={record}
        isStatusHistoryModalVisible={isStatusHistoryModelVisible}
        setIsStatusHistoryModalVisible={setIsStatusHistoryModelVisible}
      />
      {isAddSalesRepModalVisible && (
        <AddSalesRep
          isAddSalesRepModalVisible={isAddSalesRepModalVisible}
          setIsAddSalesRepModalVisible={setIsAddSalesRepModalVisible}
        />
      )}
    </Drawer>
  );
};

export default UpdateLead;
