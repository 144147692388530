import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const AddRoles = ({ isAddRoleModalVisible, setIsAddRoleModalVisible }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addRoleSubmitHandler = (value) => {
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_ROLES_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddRoleModalVisible(false);
  };
  return (
    <Modal
      title="Add New Role"
      open={isAddRoleModalVisible}
      onCancel={() => {
        setIsAddRoleModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addRoleSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Role Name"
          name={"roleName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter role name!",
            },
          ]}
        >
          <Input placeholder="Role" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add New Role
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddRoles;
