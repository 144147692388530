import moment from "moment";
export const date = (date) => {
  return moment.utc(date).local().format("YYYY-MM-DD");
};
export const dateUTC = (date) => {
  return moment.utc(date).format("YYYY-MM-DD");
};
export const date00Time = (date) => {
  return (
    moment.utc(date).local().format("YYYY-MM-DD") +
    "T" +
    moment.utc(time).local().format("00:00:00")
  );
};
export const date00 = (date) => {
  return moment.utc(date).local().format("YYYY-MM-DD") + "T" + "00:00:00";
};
export const time = (time) => {
  return moment.utc(time).local().format("HH:mm:ss");
};

export const dateAndTime = (date, time) => {
  return (
    moment.utc(date).local().format("YYYY-MM-DD") +
    "T" +
    moment.utc(time).local().format("HH:mm:ss")
  );
};
export const dateAndTimeUTC = (date, time) => {
  return (
    moment.utc(date).format("YYYY-MM-DD") +
    "T" +
    moment.utc(time).format("HH:mm:ss")
  );
};

export const formattedDate = (date) => {
  return date ? moment.utc(date).local().format("DD-MM-YYYY") : "";
};
export const YYYYMMDD = (date) => {
  return date ? moment.utc(date).local().format("YYYY-MM-DD") : "";
};
export const formattedDateTime = (dateTime) => {
  return dateTime
    ? moment.utc(dateTime).local().format("DD-MM-YYYY HH:mm:ss")
    : "";
};

export function getTimeZone() {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    ":" +
    ("00" + (o % 60)).slice(-2)
  );
}

export const threeTagResponses = (dataObj, status1, status2, status3) => {
  let status;
  if (dataObj === status1) {
    status = <p className="orangeTag">{status1}</p>;
  } else if (dataObj === status2) {
    status = <p className="blueTag">{status2}</p>;
  } else if (dataObj === status3) {
    status = <p className="greenTag">{status3}</p>;
  }
  return status;
};
export const fourTagResponses = (
  dataObj,
  status1,
  status2,
  status3,
  status4
) => {
  let status;
  if (dataObj === status1) {
    status = <p className="orangeTag">{status1}</p>;
  } else if (dataObj === status2) {
    status = <p className="blueTag">{status2}</p>;
  } else if (dataObj === status3) {
    status = <p className="greenTag">{status3}</p>;
  } else if (dataObj === status4) {
    status = <p className="redTag">{status4}</p>;
  }
  return status;
};
export const fourDivTagResponses = (
  dataObj,
  status1,
  status2,
  status3,
  status4
) => {
  let status;
  if (dataObj === status1) {
    status = <div className="orangeTag">{status1}</div>;
  } else if (dataObj === status2) {
    status = <div className="blueTag">{status2}</div>;
  } else if (dataObj === status3) {
    status = <div className="greenTag">{status3}</div>;
  } else if (dataObj === status4) {
    status = <div className="redTag">{status4}</div>;
  }
  return status;
};
export const twoTagResponses = (dataObj, status1, status2) => {
  let status;
  if (dataObj === status1) {
    status = <p className="greenTag">YES</p>;
  } else if (dataObj === status2) {
    status = <p className="redTag">NO</p>;
  }
  return status;
};
export const twoTagResponsesWithStatuses = (dataObj, status1, status2) => {
  let status;
  if (dataObj === status1) {
    status = <p className="greenTag">{status1}</p>;
  } else if (dataObj === status2) {
    status = <p className="redTag">{status2}</p>;
  }
  return status;
};

export const timeZoneOffset = [
  {
    offset: "GMT-12:00",
    name: "Etc/GMT-12",
    gmt: "-12:00",
  },
  {
    offset: "GMT-11:00",
    name: "Etc/GMT-11",
    gmt: "-11:00",
  },
  {
    offset: "GMT-11:00",
    name: "Pacific/Midway",
    gmt: "-11:00",
  },
  {
    offset: "GMT-10:00",
    name: "America/Adak",
    gmt: "-10:00",
  },
  {
    offset: "GMT-09:00",
    name: "America/Anchorage",
    gmt: "-09:00",
  },
  {
    offset: "GMT-09:00",
    name: "Pacific/Gambier",
    gmt: "-09:00",
  },
  {
    offset: "GMT-08:00",
    name: "America/Dawson_Creek",
    gmt: "-08:00",
  },
  {
    offset: "GMT-08:00",
    name: "America/Ensenada",
    gmt: "-08:00",
  },
  {
    offset: "GMT-08:00",
    name: "America/Los_Angeles",
    gmt: "-08:00",
  },
  {
    offset: "GMT-07:00",
    name: "America/Chihuahua",
    gmt: "-07:00",
  },
  {
    offset: "GMT-07:00",
    name: "America/Denver",
    gmt: "-07:00",
  },
  {
    offset: "GMT-06:00",
    name: "America/Belize",
    gmt: "-06:00",
  },
  {
    offset: "GMT-06:00",
    name: "America/Cancun",
    gmt: "-06:00",
  },
  {
    offset: "GMT-06:00",
    name: "America/Chicago",
    gmt: "-06:00",
  },
  {
    offset: "GMT-06:00",
    name: "Chile/EasterIsland",
    gmt: "-06:00",
  },
  {
    offset: "GMT-05:00",
    name: "America/Bogota",
    gmt: "-05:00",
  },
  {
    offset: "GMT-05:00",
    name: "America/Havana",
    gmt: "-05:00",
  },
  {
    offset: "GMT-05:00",
    name: "America/New_York",
    gmt: "-05:00",
  },
  {
    offset: "GMT-04:30",
    name: "America/Caracas",
    gmt: "-04:30",
  },
  {
    offset: "GMT-04:00",
    name: "America/Campo_Grande",
    gmt: "-04:00",
  },
  {
    offset: "GMT-04:00",
    name: "America/Glace_Bay",
    gmt: "-04:00",
  },
  {
    offset: "GMT-04:00",
    name: "America/Goose_Bay",
    gmt: "-04:00",
  },
  {
    offset: "GMT-04:00",
    name: "America/Santiago",
    gmt: "-04:00",
  },
  {
    offset: "GMT-04:00",
    name: "America/La_Paz",
    gmt: "-04:00",
  },
  {
    offset: "GMT-03:00",
    name: "America/Argentina/Buenos_Aires",
    gmt: "-03:00",
  },
  {
    offset: "GMT-03:00",
    name: "America/Montevideo",
    gmt: "-03:00",
  },
  {
    offset: "GMT-03:00",
    name: "America/Araguaina",
    gmt: "-03:00",
  },
  {
    offset: "GMT-03:00",
    name: "America/Godthab",
    gmt: "-03:00",
  },
  {
    offset: "GMT-03:00",
    name: "America/Miquelon",
    gmt: "-03:00",
  },
  {
    offset: "GMT-03:00",
    name: "America/Sao_Paulo",
    gmt: "-03:00",
  },
  {
    offset: "GMT-03:30",
    name: "America/St_Johns",
    gmt: "-03:30",
  },
  {
    offset: "GMT-02:00",
    name: "America/Noronha",
    gmt: "-02:00",
  },
  {
    offset: "GMT-01:00",
    name: "Atlantic/Cape_Verde",
    gmt: "-01:00",
  },

  {
    offset: "GMT+01:00",
    name: "Africa/Algiers",
    gmt: "+01:00",
  },
  {
    offset: "GMT+01:00",
    name: "Africa/Windhoek",
    gmt: "+01:00",
  },
  {
    offset: "GMT+01:00",
    name: "Atlantic/Azores",
    gmt: "+01:00",
  },
  {
    offset: "GMT+01:00",
    name: "Atlantic/Stanley",
    gmt: "+01:00",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Amsterdam",
    gmt: "+01:00",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Belgrade",
    gmt: "+01:00",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Brussels",
    gmt: "+01:00",
  },
  {
    offset: "GMT+02:00",
    name: "Africa/Cairo",
    gmt: "+02:00",
  },
  {
    offset: "GMT+02:00",
    name: "Africa/Blantyre",
    gmt: "+02:00",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Beirut",
    gmt: "+02:00",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Damascus",
    gmt: "+02:00",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Gaza",
    gmt: "+02:00",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Jerusalem",
    gmt: "+02:00",
  },
  {
    offset: "GMT+03:00",
    name: "Africa/Addis_Ababa",
    gmt: "+03:00",
  },
  {
    offset: "GMT+03:00",
    name: "Asia/Riyadh89",
    gmt: "+03:00",
  },
  {
    offset: "GMT+03:00",
    name: "Europe/Minsk",
    gmt: "+03:00",
  },
  {
    offset: "GMT+03:30",
    name: "Asia/Tehran",
    gmt: "+03:30",
  },
  {
    offset: "GMT+04:00",
    name: "Asia/Dubai",
    gmt: "+04:00",
  },
  {
    offset: "GMT+04:00",
    name: "Asia/Yerevan",
    gmt: "+04:00",
  },
  {
    offset: "GMT+04:00",
    name: "Europe/Moscow",
    gmt: "+04:00",
  },
  {
    offset: "GMT+04:30",
    name: "Asia/Kabul",
    gmt: "+04:30",
  },
  {
    offset: "GMT+05:00",
    name: "Asia/Tashkent",
    gmt: "+05:00",
  },
  {
    offset: "GMT+05:30",
    name: "Asia/Kolkata",
    gmt: "+05:30",
  },
  {
    offset: "GMT+05:45",
    name: "Asia/Katmandu",
    gmt: "+05:45",
  },
  {
    offset: "GMT+06:00",
    name: "Asia/Dhaka",
    gmt: "+06:00",
  },
  {
    offset: "GMT+06:00",
    name: "Asia/Yekaterinburg",
    gmt: "+06:00",
  },
  {
    offset: "GMT+06:30",
    name: "Asia/Rangoon",
    gmt: "+06:30",
  },
  {
    offset: "GMT+07:00",
    name: "Asia/Bangkok",
    gmt: "+07:00",
  },
  {
    offset: "GMT+07:00",
    name: "Asia/Novosibirsk",
    gmt: "+07:00",
  },
  {
    offset: "GMT+08:00",
    name: "Etc/GMT+8",
    gmt: "+08:00",
  },
  {
    offset: "GMT+08:00",
    name: "Asia/Hong_Kong",
    gmt: "+08:00",
  },
  {
    offset: "GMT+08:00",
    name: "Asia/Krasnoyarsk",
    gmt: "+08:00",
  },
  {
    offset: "GMT+08:00",
    name: "Australia/Perth",
    gmt: "+08:00",
  },
  {
    offset: "GMT+08:45",
    name: "Australia/Eucla",
    gmt: "+08:45",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Irkutsk",
    gmt: "+09:00",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Seoul",
    gmt: "+09:00",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Tokyo",
    gmt: "+09:00",
  },
  {
    offset: "GMT+09:30",
    name: "Australia/Adelaide",
    gmt: "+09:30",
  },
  {
    offset: "GMT+09:30",
    name: "Australia/Darwin",
    gmt: "+09:30",
  },
  {
    offset: "GMT+09:30",
    name: "Pacific/Marquesas",
    gmt: "+09:30",
  },
  {
    offset: "GMT+10:00",
    name: "Etc/GMT+10",
    gmt: "+10:00",
  },
  {
    offset: "GMT+10:00",
    name: "Australia/Brisbane",
    gmt: "+10:00",
  },
  {
    offset: "GMT+10:00",
    name: "Australia/Hobart",
    gmt: "+10:00",
  },
  {
    offset: "GMT+10:00",
    name: "Asia/Yakutsk",
    gmt: "+10:00",
  },
  {
    offset: "GMT+10:30",
    name: "Australia/Lord_Howe",
    gmt: "+10:30",
  },
  {
    offset: "GMT+11:00",
    name: "Asia/Vladivostok",
    gmt: "+11:00",
  },
  {
    offset: "GMT+11:30",
    name: "Pacific/Norfolk",
    gmt: "+11:30",
  },
  {
    offset: "GMT+12:00",
    name: "Etc/GMT+12",
    gmt: "+12:00",
  },
  {
    offset: "GMT+12:00",
    name: "Asia/Anadyr",
    gmt: "+12:00",
  },
  {
    offset: "GMT+12:00",
    name: "Asia/Magadan",
    gmt: "+12:00",
  },
  {
    offset: "GMT+12:00",
    name: "Pacific/Auckland",
    gmt: "+12:00",
  },
  {
    offset: "GMT+12:45",
    name: "Pacific/Chatham",
    gmt: "+12:45",
  },
  {
    offset: "GMT+13:00",
    name: "Pacific/Tongatapu",
    gmt: "+13:00",
  },
  {
    offset: "GMT+14:00",
    name: "Pacific/Kiritimati",
    gmt: "+14:00",
  },
];

export const scrollToTopFormError = () => {
  const errorFields = document.querySelectorAll(".ant-form-item-has-error");
  if (errorFields.length > 0) {
    const firstErrorField = errorFields[0];
    firstErrorField.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};
