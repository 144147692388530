import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const AddDepartment = ({
  isAddDepartmentModalVisible,
  setIsAddDepartmentModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addDepartmentSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({
      type: "ADD_DEPARTMENT_REQUEST",
      payload: { branchId: localStorage.getItem("branchId"), formData },
    });
    form.resetFields();
    setIsAddDepartmentModalVisible(false);
  };
  return (
    <Modal
      title="Add User Group"
      open={isAddDepartmentModalVisible}
      onCancel={() => {
        setIsAddDepartmentModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addDepartmentSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="User Group Name"
          name={"departmentName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter user group name!",
            },
          ]}
        >
          <Input placeholder="User Group" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add User Group
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddDepartment;
