import React, { useState, useEffect } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

const UpdateDocument = ({
  isUpdateDocumentModalVisible,
  setIsUpdateDocumentModalVisible,
  documentRecord,
  workflowRecord,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const store = useSelector((state) => {
    return { documentChecklistState: state?.DocumentCheckListReducer };
  });

  useEffect(() => {
    handleGetDocumentChecklist();
  }, []);

  const handleGetDocumentChecklist = () => {
    dispatch({
      type: "FETCH_DOCUMENT_CHECKLIST_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  const updateDocumentSubmitHandler = (value) => {
    const formData = {
      ...value,
      documentType: value.item,
    };
    dispatch({
      type: "UPDATE_DOCUMENT_REQUEST",
      payload: {
        id: documentRecord.id,
        formData,
        workflowId: workflowRecord.id,
      },
    });
    // form.resetFields();
    setIsUpdateDocumentModalVisible(false);
  };
  return (
    <Modal
      title="Update Document Checklist"
      open={isUpdateDocumentModalVisible}
      onCancel={() => {
        setIsUpdateDocumentModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateDocumentSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["item"],
            value: documentRecord.item,
          },
        ]}
      >
        <Form.Item
          label="Document Name"
          name={"item"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a item!",
            },
          ]}
        >
          {/* <Select showSearch placeholder="Select Document">
            {store?.documentChecklistState?.documentCheclistData?.data?.map(
              (item) => (
                <Select.Option value={item?.name}>{item?.name}</Select.Option>
              )
            )}
          </Select> */}
          <Checkbox.Group
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
            options={store?.documentChecklistState?.documentCheclistData?.data?.map(
              (data) => {
                return {
                  label: data?.name,
                  value: data?.name,
                };
              }
            )}
          />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Document
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateDocument;
