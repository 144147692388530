import React, { useEffect } from "react";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Select,
  Tag,
} from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import AddSalesRep from "../AddSalesRep";
import moment from "moment";
import CaseSummary from "./CaseSummary";
import { getCookie } from "../../../Helpers/FrontendHelper";
import axios from "axios";
import AddCountry from "../../Leads/Country/AddCountry";
import Cookies from "js-cookie";
import { scrollToTopFormError } from "../../../HelperFunction";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
const { Option } = Select;
const { TextArea } = Input;

const VisaUserInfo = ({
  visaCaseData,
  setVisaCaseData,
  current,
  setCurrent,
  recordData,
}) => {
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
    };
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [activeEmployees, setActiceEmployees] = useState([]);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    fetchActiveEmployeList();
  }, []);

  const fetchActiveEmployeList = async () => {
    const tempToken = Cookies.get("accessToken");
    await axios
      .get(`v1/api/client/get-active-user-list`, {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      })
      .then((res) => {
        setActiceEmployees(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    dispatch({
      type: "FETCH_LEADS_DROPDOWN_REQUEST",
      payload: {
        branchId: localStorage.getItem("branchId"),
        fromInstituteVisaCase: true,
        visaSuccessFunction: visaSuccessFunction,
      },
    });
    dispatch({
      type: "FETCH_CASES_DROPDOWN_REQUEST",
      payload: {
        branchId: localStorage.getItem("branchId"),
      },
    });
  }, []);
  const [error, setError] = useState("");
  const [isAddSalesRepModalVisible, setIsAddSalesRepModalVisible] =
    useState(false);
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const addVisaCasesSubmitHandler = () => {
    setCurrent(1);
  };
  const visaSuccessFunction = (response) => {
    setVisaCaseData((previousData) => {
      return {
        ...previousData,
        currency: response?.data?.defaultSelectedCurrency,
        countryOfResidence: response?.data?.defaultCountry?.name,
      };
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexFlow: "wrap",
          gap: "1rem",
          alignItems: "stretch",
          position: "relative",
        }}
      >
        <div style={{ width: "100%", display: "flex", flex: 1 }}>
          <Form
            layout="vertical"
            onFinish={addVisaCasesSubmitHandler}
            onFinishFailed={scrollToTopFormError}
            colon={true}
            form={form}
            className="drawerStyle"
            fields={[
              {
                name: ["startDate"],
                value:
                  visaCaseData.startDate === ""
                    ? moment()
                    : visaCaseData.startDate,
              },
              {
                name: ["leadType"],
                value: visaCaseData.leadType,
              },
              {
                name: ["salesRepresentative"],
                value: visaCaseData.salesRepresentativeName,
              },
              {
                name: ["clientType"],
                value: visaCaseData.clientType,
              },
              {
                name: ["customerId"],
                value: visaCaseData.customer,
              },
              {
                name: ["firstName"],
                value: visaCaseData.firstName,
              },

              {
                name: ["lastName"],
                value: visaCaseData.lastName,
              },
              {
                name: ["mobile"],
                value: visaCaseData.mobile,
              },
              {
                name: ["email"],
                value: visaCaseData.email,
              },
              {
                name: ["dob"],
                value:
                  visaCaseData.dob !== null &&
                  visaCaseData.dob !== "" &&
                  visaCaseData.dob !== undefined
                    ? moment.utc(visaCaseData.dob).local()
                    : null,
              },
              {
                name: ["phone"],
                value: visaCaseData.phone,
              },
              {
                name: ["address"],
                value: visaCaseData.address,
              },
              {
                name: ["passportNumber"],
                value: visaCaseData.passportNumber,
              },
              {
                name: ["maritalStatus"],
                value: visaCaseData.maritalStatus,
              },
              {
                name: ["sexStatus"],
                value: visaCaseData.sexStatus,
              },
              {
                name: ["countryOfResidence"],
                value: visaCaseData.countryOfResidence,
              },
            ]}
          >
            <Form.Item
              label="Start Date"
              name={"startDate"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please pick start date!",
                },
              ]}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                onChange={(e) => {
                  setVisaCaseData((previousData) => {
                    return {
                      ...previousData,
                      startDate: e,
                    };
                  });
                  //   setStartDate(formattedDate(e));
                }}
              />
            </Form.Item>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Client Type"
                name={"clientType"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select client type",
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => {
                    setVisaCaseData((previousData) => {
                      return {
                        ...previousData,
                        clientType: e.target.value,
                        customerId: null,
                        customer: null,
                        firstName: null,
                        lastName: null,
                        mobile: null,
                        email: null,
                        dob: null,
                        phone: null,
                        address: null,
                        passportNumber: null,
                        maritalStatus: null,
                        sexStatus: null,
                        countryOfResidence: null,
                      };
                    });

                    // setIsClientType(e.target.value);
                  }}
                >
                  <Radio value={"EXISTING"}>Existing Client</Radio>
                  <Radio value={"NEW"}>New Client</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            {visaCaseData.clientType === "EXISTING" ? (
              <>
                <Form.Item
                  label="Client"
                  name={"customerId"}
                  style={{ style: "50%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select client!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select a Client"
                    optionFilterProp="children"
                    onSearch={(e) => {}}
                    filterOption={(input, option) => {
                      return (option?.value ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {store.caseState.caseDropdown?.client?.map((dataObj) => {
                      return (
                        <Option
                          key={dataObj.id}
                          value={`${dataObj?.firstName ?? ""} ${
                            dataObj?.lastName ?? ""
                          } ${
                            dataObj.email !== null && dataObj.email !== ""
                              ? `(${dataObj.email})`
                              : dataObj.mobile !== null && dataObj.mobile !== ""
                              ? `(${dataObj.mobile})`
                              : ""
                          }`}
                        >
                          <div
                            onClick={() => {
                              //   setExistingClientDetails(dataObj);
                              setVisaCaseData((previousData) => {
                                return {
                                  ...previousData,
                                  customerId: dataObj.id,
                                  customer: `${dataObj?.firstName ?? ""} ${
                                    dataObj?.lastName ?? ""
                                  } ${
                                    dataObj.email !== null &&
                                    dataObj.email !== ""
                                      ? `(${dataObj.email})`
                                      : dataObj.mobile !== null &&
                                        dataObj.mobile !== ""
                                      ? `(${dataObj.mobile})`
                                      : ""
                                  }`,
                                  firstName: dataObj?.firstName,
                                  lastName: dataObj?.lastName,
                                  mobile: dataObj?.mobile,
                                  email: dataObj?.email,
                                  dob: dataObj.dob,
                                  phone: dataObj.phone,
                                  address: dataObj.address,
                                  passportNumber: dataObj.passportNumber,
                                  maritalStatus: dataObj.maritalStatus,
                                  sexStatus: dataObj.sexStatus,
                                  countryOfResidence:
                                    dataObj.countryOfResidence ??
                                    visaCaseData.countryOfResidence,
                                };
                              });
                            }}
                            className={"selectOptionDiv"}
                          >
                            {`${dataObj?.firstName ?? ""} ${
                              dataObj?.lastName ?? ""
                            } ${
                              dataObj.email !== null && dataObj.email !== ""
                                ? `(${dataObj.email})`
                                : dataObj.mobile !== null &&
                                  dataObj.mobile !== ""
                                ? `(${dataObj.mobile})`
                                : ""
                            }`}

                            {dataObj?.isClient ? (
                              <Tag
                                style={{
                                  marginRight: "1rem",
                                  color: "white",
                                  background: "#1890ff",
                                  borderRadius: "0.5rem",
                                }}
                              >
                                Client
                              </Tag>
                            ) : (
                              <Tag
                                style={{
                                  marginRight: "1rem",
                                  color: "white",
                                  background: "#EDAB15",
                                  borderRadius: "0.5rem",
                                }}
                              >
                                Contact
                              </Tag>
                            )}
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </>
            ) : null}
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="First Name"
                name={"firstName"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter first name!",
                  },
                ]}
              >
                <Input
                  placeholder="First Name"
                  onChange={(e) => {
                    setVisaCaseData((previousData) => {
                      return {
                        ...previousData,
                        firstName: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name={"lastName"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter last name!",
                  },
                ]}
              >
                <Input
                  placeholder="Last Name"
                  onChange={(e) => {
                    setVisaCaseData((previousData) => {
                      return {
                        ...previousData,
                        lastName: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Mobile Number"
                name={"mobile"}
                style={{ width: "100%" }}
                rules={[
                  {
                    pattern: /^[\d]{9,11}$/,
                    message: "Mobile number should contain 9 to 11 numbers",
                  },
                  {
                    validator: async (_, value) => {
                      const token = getCookie("accessToken");
                      const response = await axios.get(
                        // `${
                        //   process.env.REACT_APP_TEST_URL
                        // }v2/api/client/branch/customer/validate-mobile/${localStorage.getItem(
                        //   "branchId"
                        // )}?mobile=${value}`,
                        `v2/api/client/branch/customer/validate-mobile/${localStorage.getItem(
                          "branchId"
                        )}?mobile=${value}`,
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                          },
                        }
                      );
                      if (
                        response?.data === false ||
                        value === "" ||
                        value === null ||
                        value === undefined
                      ) {
                        return Promise.resolve();
                      } else if (
                        response?.data === true &&
                        visaCaseData.clientType === "NEW"
                      ) {
                        return Promise.reject(
                          new Error("Entered mobile number already exists")
                        );
                      }
                    },
                  },
                ]}
                validateTrigger={["onBlur"]}
              >
                <Input
                  type="number"
                  placeholder="Mobile Number"
                  onChange={(e) => {
                    setVisaCaseData((previousData) => {
                      return {
                        ...previousData,
                        mobile: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Email"
                name={"email"}
                style={{ width: "100%" }}
                rules={[
                  {
                    type: "email",
                    message: "Please enter valid email",
                  },
                  {
                    validator: async (_, value) => {
                      const token = getCookie("accessToken");
                      const response = await axios.get(
                        // `${
                        //   process.env.REACT_APP_TEST_URL
                        // }v2/api/client/branch/customer/validate-email/${localStorage.getItem(
                        //   "branchId"
                        // )}?email=${value}`,
                        `v2/api/client/branch/customer/validate-email/${localStorage.getItem(
                          "branchId"
                        )}?email=${value}`,
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                          },
                        }
                      );
                      if (
                        response?.data === false ||
                        value === "" ||
                        value === null ||
                        value === undefined
                      ) {
                        return Promise.resolve();
                      } else if (
                        response?.data === true &&
                        visaCaseData.clientType === "NEW"
                      ) {
                        return Promise.reject(
                          new Error("Entered email already exists")
                        );
                      }
                    },
                  },
                ]}
                validateTrigger={["onBlur"]}
              >
                <Input
                  type="email"
                  placeholder="E.g. name@domainname.com"
                  onChange={(e) => {
                    setVisaCaseData((previousData) => {
                      return {
                        ...previousData,
                        email: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            {!showMore && (
              <div style={{ margin: "0 0 1rem" }}>
                <Divider style={{ margin: 0 }} />
                <Button
                  type="outlined"
                  size="small"
                  icon={<DownOutlined />}
                  onClick={() => {
                    setShowMore(true);
                  }}
                >
                  View More
                </Button>
              </div>
            )}
            {showMore && (
              <>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <Form.Item
                    label="Date of Birth"
                    name={"dob"}
                    style={{ width: "100%" }}
                    validateStatus={error ? "error" : undefined}
                    help={error}
                  >
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      disabledDate={(currentDate) => {
                        const date = new Date();
                        return currentDate > date;
                      }}
                      onChange={(e) => {
                        // setDob(e);
                        setVisaCaseData((previousData) => {
                          return {
                            ...previousData,
                            dob: e,
                          };
                        });
                        if (e) {
                          const age = moment().diff(e, "years");
                          if (age < 18) {
                            setError("Employee must be above 18 years old.");
                            form.setFields([
                              {
                                name: "dateOfBirth",
                                errors: [
                                  "Employee must be at least 18 years old.",
                                ],
                              },
                            ]);
                          } else {
                            setError("");
                            form.setFields([
                              {
                                name: "dateOfBirth",
                                errors: [],
                              },
                            ]);
                          }
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Phone"
                    name={"phone"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        pattern: /^[\d]{9,11}$/,
                        message: "Mobile number should contain 9 to 11 numbers",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="Phone number"
                      onChange={(e) => {
                        // setPhone(e.target.value);
                        setVisaCaseData((previousData) => {
                          return {
                            ...previousData,
                            phone: e.target.value,
                          };
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <Form.Item
                    label="Address"
                    name={"address"}
                    style={{ width: "100%" }}
                  >
                    <Input
                      placeholder="Address"
                      onChange={(e) => {
                        // setAddress(e.target.value);
                        setVisaCaseData((previousData) => {
                          return {
                            ...previousData,
                            address: e.target.value,
                          };
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Passport Number"
                    name={"passportNumber"}
                    style={{ width: "100%" }}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter passport number!",
                    //   },
                    // ]}
                  >
                    <Input
                      type="number"
                      placeholder="passport number"
                      onChange={(e) => {
                        // setPassportNumber(e.target.value);
                        setVisaCaseData((previousData) => {
                          return {
                            ...previousData,
                            passportNumber: e.target.value,
                          };
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <Form.Item
                    label="Marital Status"
                    name={"maritalStatus"}
                    style={{ width: "100%" }}
                  >
                    <Select
                      onChange={(e) => {
                        setVisaCaseData((previousData) => {
                          return {
                            ...previousData,
                            maritalStatus: e,
                          };
                        });
                        // setMaritalStatus(e);
                      }}
                    >
                      <Option value="SINGLE">Single</Option>
                      <Option value="MARRIED">Married</Option>
                      <Option value="DIVORCED">Divorced</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Gender"
                    name={"sexStatus"}
                    style={{ width: "100%" }}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter gender!",
                    //   },
                    // ]}
                  >
                    <Select
                      onChange={(e) => {
                        // setSexStatus(e);
                        setVisaCaseData((previousData) => {
                          return {
                            ...previousData,
                            sexStatus: e,
                          };
                        });
                      }}
                    >
                      <Option value={"MALE"}>Male</Option>
                      <Option value={"FEMALE"}>Female</Option>
                      <Option value={"OTHERS"}>Others</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.3rem", width: "100%" }}
                >
                  <Form.Item
                    label="Country of Residence"
                    name={"countryOfResidence"}
                    style={{ width: "100%" }}
                  >
                    <Select
                      optionFilterProp="children"
                      showSearch
                      filterOption={(input, option) => {
                        return (option?.children?.props?.children ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      {store.leadState?.leadsDropdown?.countries?.map(
                        (dataObj) => {
                          return (
                            <Option
                              key={dataObj.id}
                              value={dataObj.countryName}
                            >
                              <div
                                onClick={() => {
                                  setVisaCaseData((previousData) => {
                                    return {
                                      ...previousData,
                                      countryOfResidence: dataObj.countryName,
                                    };
                                  });
                                }}
                                className={"selectOptionDiv"}
                              >
                                {dataObj.countryName}
                              </div>
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                  <a
                    className="plusButton"
                    onClick={() => {
                      setIsAddCountryModalVisible(true);
                    }}
                  >
                    <AiOutlinePlus className="iconColor" />
                  </a>
                </div>

                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.3rem", width: "100%" }}
                  >
                    <Form.Item
                      label="Lead Type"
                      name={"leadType"}
                      style={{ width: "100%" }}
                    >
                      <Select
                        onChange={(e) => {
                          setVisaCaseData((previousData) => {
                            return {
                              ...previousData,
                              leadType: e,
                            };
                          });
                        }}
                      >
                        <Option value={"SELF ASSIGNED"}>Self Assigned</Option>
                        <Option value={"COMPANY ASSIGNED"}>
                          Company Assigned
                        </Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.3rem", width: "100%" }}
                  >
                    <Form.Item
                      label="Sales Reps"
                      name={"salesRepresentative"}
                      style={{ width: "100%" }}
                    >
                      <Select>
                        {store.caseState.caseDropdown?.salesRepresentative?.map(
                          (dataObj) => {
                            return (
                              <Option
                                key={dataObj.id}
                                value={dataObj.salesRepName}
                              >
                                <div
                                  onClick={() => {
                                    setVisaCaseData((previousData) => {
                                      return {
                                        ...previousData,
                                        salesRepresentativeId: dataObj.id,
                                        salesRepresentativeName:
                                          dataObj.salesRepName,
                                      };
                                    });
                                  }}
                                >
                                  {dataObj.salesRepName}
                                </div>
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    <a
                      className="plusButton"
                      onClick={() => {
                        setIsAddSalesRepModalVisible(true);
                      }}
                    >
                      <AiOutlinePlus className="iconColor" />
                    </a>
                  </div>
                </div>

                <Divider />
                <Form.Item
                  label="Case Assign to"
                  name={"assignedToEmail"}
                  style={{ width: "100%" }}
                >
                  <Select
                    placeholder="Select Case Assign to"
                    onChange={(value) => {
                      setVisaCaseData((prev) => ({
                        ...prev,
                        assignedToEmail: value,
                      }));
                    }}
                  >
                    {activeEmployees?.map((emp) => (
                      <Select.Option value={emp.emailId}>
                        {emp.fullName}({emp.emailId})
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <div
                  style={{
                    margin: "1rem 0",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    type="outlined"
                    size="small"
                    icon={<UpOutlined />}
                    onClick={() => {
                      setShowMore(false);
                    }}
                  >
                    View Less
                  </Button>

                  <Divider style={{ margin: "0" }} />
                </div>
              </>
            )}

            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "flex-end", gap: "1rem" }}
            >
              <Form.Item
                name="clientInfoNext"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        (getFieldValue("email") === undefined ||
                          getFieldValue("email") === "" ||
                          getFieldValue("email") === null) &&
                        (getFieldValue("mobile") === undefined ||
                          getFieldValue("mobile") === "" ||
                          getFieldValue("mobile") === null)
                      ) {
                        return Promise.reject(
                          new Error(
                            "Please enter either email or mobile number!"
                          )
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
                hasFeedback
              >
                <Button name="clientInfoNext" type="primary" htmlType="submit">
                  Next
                </Button>
              </Form.Item>
            </div>
          </Form>
          {isAddSalesRepModalVisible && (
            <AddSalesRep
              isAddSalesRepModalVisible={isAddSalesRepModalVisible}
              setIsAddSalesRepModalVisible={setIsAddSalesRepModalVisible}
            />
          )}
          {isAddCountryModalVisible && (
            <AddCountry
              isAddCountryModalVisible={isAddCountryModalVisible}
              setIsAddCountryModalVisible={setIsAddCountryModalVisible}
            />
          )}
        </div>
        <div
          style={{
            width: "30%",
          }}
        >
          <CaseSummary current={current} visaCaseData={visaCaseData} />
        </div>
      </div>
    </>
  );
};

export default VisaUserInfo;
