import React, { useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const Confirmation = ({
  onSubmit = () => {},
  isconfirmationModalVisible,
  setIsconfirmationModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  return (
    <Modal
      title={"Enter the Confirmation Code"}
      open={isconfirmationModalVisible}
      onCancel={() => {
        setIsconfirmationModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={(value) => {
          onSubmit(value);
          form.resetFields();
        }}
        colon={true}
        form={form}
      >
        <Alert
          message="Please remember confirmation code to toggle activation status!"
          type="warning"
          style={{ marginBottom: "0.5rem" }}
        />
        <Form.Item
          label="Confirmation Code"
          name={"confirmationText"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please confirm with a note!",
            },
          ]}
        >
          <Input type="number" placeholder="Confirmation Code" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Confirmation;
