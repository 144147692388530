import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AddCasePackage from "../AddCasePackage";
import { scrollToTopFormError } from "../../../HelperFunction";

export default function UpdateAdditionalInformation({
  setCurrent,
  caseFormData,
  setCaseFormData,
}) {
  const { Option } = Select;
  const { TextArea } = Input;
  const [form] = Form?.useForm();
  const dispatch = useDispatch();
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
      documentChecklistState: state?.DocumentCheckListReducer,
    };
  });
  const [traverseStatus, setTraverseStatus] = useState("");
  const [isAddPackageModalVisible, setIsAddPackageModalVisible] =
    useState(false);

  useEffect(() => {
    handleGetDocumentChecklist();
    handleFetchActiveCasePackage();
  }, []);

  const handleFetchActiveCasePackage = () => {
    dispatch({
      type: "FETCH_ACTIVE_CASE_PACKAGE_REQUEST",
      payload: {
        branchId: localStorage.getItem("branchId"),
      },
    });
  };

  const handleGetDocumentChecklist = () => {
    dispatch({
      type: "FETCH_DOCUMENT_CHECKLIST_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  const handleSubmitPackageInfo = (values) => {
    delete values?.isAddPackage;

    setCaseFormData((prev) => {
      return {
        ...prev,
        ...values,
      };
    });
    traverseStatus === "next" && setCurrent((prev) => prev + 1);
    traverseStatus === "back" && setCurrent((prev) => prev - 1);
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmitPackageInfo}
        onFinishFailed={scrollToTopFormError}
        layout="vertical"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
          marginTop: "1.5rem",
        }}
        initialValues={{
          priorNote: caseFormData?.priorNote,
          deadlineDate: caseFormData?.deadlineDate,
          consultantId: caseFormData?.consultantId,
          superAgentId: caseFormData?.superAgentId,
          workflowDocumentChecklist: caseFormData?.workflowDocumentChecklist,
          coursesDocumentChecklist: caseFormData?.coursesDocumentChecklist,
        }}
      >
        <Form.Item label="Prior Note" name="priorNote">
          <TextArea placeholder="Write something..." />
        </Form.Item>

        <Form.Item label="Deadline" name="deadlineDate">
          <DatePicker format={"DD-MM-YYYY"} />
        </Form.Item>

        <Form.Item
          style={{ gridColumn: "span 2" }}
          name="consultantId"
          label="Assign Consultants"
        >
          <Checkbox.Group
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gap: "0.5rem",
            }}
          >
            {store.caseState?.caseDropdown?.consultant?.map((dataObj) => {
              return (
                <Checkbox
                  className="checkboxEllipsis"
                  key={dataObj.id}
                  value={dataObj.id}
                  style={{
                    width: "100%",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  <Tooltip title={dataObj.emailId}>{dataObj.emailId}</Tooltip>
                </Checkbox>
              );
            })}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item label="Assign Sub Agent" name="subAgentId">
          <Select placeholder="Select SubAgent">
            {store.caseState?.caseDropdown?.subAgent?.map((dataObj) => {
              return (
                <Select.Option key={dataObj.id} value={dataObj.id}>
                  {dataObj.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Assign Super Agent" name="superAgentId">
          {/* <Checkbox.Group
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "0.5rem",
            }}
          >
            {store.caseState?.caseDropdown?.headAgent?.map((dataObj) => {
              return (
                <Checkbox key={dataObj.id} value={dataObj.id}>
                  {dataObj.name}
                </Checkbox>
              );
            })}
          </Checkbox.Group> */}
          <Select placeholder="Select Super Agent">
            {store.caseState?.caseDropdown?.headAgent?.map((dataObj) => {
              return (
                <Select.Option key={dataObj.id} value={dataObj.id}>
                  {dataObj.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Workflow Document Checklist"
          name="workflowDocumentChecklist"
        >
          <Checkbox.Group
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            {store?.documentChecklistState?.documentCheclistData?.data?.map(
              (dataObj) => {
                return (
                  <Checkbox key={dataObj.id} value={dataObj.id}>
                    {dataObj.name}
                  </Checkbox>
                );
              }
            )}
          </Checkbox.Group>
        </Form.Item>

        {caseFormData?.visaType === "EDUCATION" && (
          <Form.Item
            label={`Courses Document Checklist ${
              caseFormData?.currentStatus ? "(Offshore)" : "(Onshore)"
            }`}
            name="coursesDocumentChecklist"
          >
            {caseFormData.currentStatus ? (
              <Checkbox.Group
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                {store?.documentChecklistState?.documentCheclistData?.data?.map(
                  (dataObj) => {
                    return (
                      <Checkbox
                        key={dataObj.id}
                        value={dataObj.id}
                        style={{ margin: 0 }}
                      >
                        {dataObj.name}
                      </Checkbox>
                    );
                  }
                )}
              </Checkbox.Group>
            ) : (
              <Checkbox.Group
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                {store?.documentChecklistState?.documentCheclistData?.data?.map(
                  (dataObj) => {
                    return (
                      <Checkbox
                        key={dataObj.id}
                        value={dataObj.id}
                        style={{ margin: 0 }}
                      >
                        {dataObj.name}
                      </Checkbox>
                    );
                  }
                )}
              </Checkbox.Group>
            )}
          </Form.Item>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gridColumn: "span 2",
          }}
        >
          <Button
            type="outline"
            onClick={() => {
              // setCurrent((prev) => prev - 1);
              setTraverseStatus("back");
            }}
            htmlType="submit"
          >
            Back
          </Button>
          <Button
            type="primary"
            onClick={() => {
              // setCurrent((prev) => prev + 1);
              setTraverseStatus("next");
            }}
            htmlType="submit"
          >
            Next
          </Button>
        </div>
      </Form>
      {isAddPackageModalVisible && (
        <AddCasePackage
          from={"CASE"}
          isAddPackageModalVisible={isAddPackageModalVisible}
          setIsAddPackageModalVisible={setIsAddPackageModalVisible}
        />
      )}
    </div>
  );
}
