import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  mailboxInbox: {
    data: [],
    pageNumber: 0,
    totalData: 0,
    totalPage: 0,
  },
  mailboxSent: {
    data: [],
    pageNumber: 0,
    totalData: 0,
    totalPage: 0,
  },
  mailboxDraft: {
    data: [],
    pageNumber: 0,
    totalData: 0,
    totalPage: 0,
  },
  previousData: 0,
  searchedAvialbleEmails: {
    data: [],
    hasNext: false,
    pageNumber: 0,
    totalData: 0,
    totalPage: 0,
  },
};

const MailboxReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_MAILBOX_INBOX_REQUEST": {
      return {
        ...state,

        isLoading: true,
      };
    }
    case "FETCH_MAILBOX_INBOX_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        mailboxInbox: action.payload,
        // previousData: action.payload?.totalData,
      };
    }

    case "FETCH_MAILBOX_INBOX_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load inbox emails.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_PREVIOUS_DATA_REQUEST": {
      return {
        ...state,
        previousData: action.payload,
      };
    }

    case "FETCH_MAILBOX_SENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_MAILBOX_SENT_REQUEST_SUCCESS": {
      return {
        ...state,
        mailboxSent: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_MAILBOX_SENT_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load sent emails.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_MAILBOX_DRAFT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_MAILBOX_DRAFT_REQUEST_SUCCESS": {
      return {
        ...state,
        mailboxDraft: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_MAILBOX_DRAFT_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load draft.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "SEND_COMPOSED_MAIL_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "SEND_COMPOSED_MAIL_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Email sent.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "SEND_COMPOSED_MAIL_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to send email.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "REPLY_TO_MAIL_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "REPLY_TO_MAIL_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Replied.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "REPLY_TO_MAIL_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to reply.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_MAILBOX_INBOX_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_MAIL_TO_DRAFT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_MAIL_TO_DRAFT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Saved to draft.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_MAIL_TO_DRAFT_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add to draft.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_DRAFT_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_DRAFT_REQUEST_SUCCESS_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Draft deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_DRAFT_REQUEST_SUCCESS_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to delete draft.");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "UPDATE_MAILBOX_INBOX_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Mailbox updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_MAILBOX_INBOX_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update Mailbox.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_MAILBOX_INBOX_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_MAILBOX_INBOX_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Mailbox deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_MAILBOX_INBOX_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete Mailbox.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_MAILBOX_INBOX_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_MAILBOX_INBOX_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Mailbox status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_MAILBOX_INBOX_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change Mailbox status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_AVAILABLE_EMAILS_REQUEST": {
      return {
        ...state,
      };
    }

    case "FETCH_AVAILABLE_EMAILS_REQUEST_SUCCESS": {
      return {
        ...state,
        searchedAvialbleEmails: action.payload,
      };
    }
    case "FETCH_AVAILABLE_EMAILS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to search available emails.");

      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default MailboxReducer;
