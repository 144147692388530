import { EditOutlined } from "@ant-design/icons";
import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AddEmailTemplate from "./AddEmailTemplate";

export default function EmailTemplateList() {
  const emailTemplateList = useSelector(
    (store) => store?.OrganizationSettingsReducer?.emailTemplateList
  );
  const dispatch = useDispatch();
  const [isAddEmailTemplateVisible, setIsAddEmailTemplateVisible] =
    useState(false);
  const [emailTemplate, setEmailTemplate] = useState();

  useEffect(() => {
    fetchTemplateList();
  }, []);
  const fetchTemplateList = () => {
    dispatch({
      type: "FETCH_ALL_EMAIL_TEMPLATE",
      payload: {},
      isSuccessFn: true,
      successFunction: (res) => {},
    });
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "Email Type",
      dataIndex: "templateType",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "templateDescription",
      align: "center",
    },
    {
      title: "Subject",
      dataIndex: "templateSubject",
      align: "center",
    },
    // {
    //   title: "Body",
    //   dataIndex: "templateText",
    //   // align: "center",
    //   render: (data) => <div dangerouslySetInnerHTML={{ __html: data }}></div>,
    // },
    {
      title: "Action",
      align: "center",
      render: (data) => (
        <EditOutlined
          onClick={() => {
            setEmailTemplate(data);
            setIsAddEmailTemplateVisible(true);
          }}
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        bordered
        dataSource={emailTemplateList}
        pagination={false}
      />
      {isAddEmailTemplateVisible && (
        <AddEmailTemplate
          templateSubject={emailTemplate?.templateSubject}
          emailTemplateType={emailTemplate?.templateType}
          templateText={emailTemplate?.templateText}
          templateDescription={emailTemplate?.templateDescription}
          isAddEmailTemplateModalVisible={isAddEmailTemplateVisible}
          setIsAddEmailTemplateModalVisible={setIsAddEmailTemplateVisible}
          fetchTemplateList={fetchTemplateList}
        />
      )}
    </div>
  );
}
