import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  todoList: { data: [], totalData: 0, totalPage: 0, pageNumber: 0 },
};

const TodoReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_TODO_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_TODO_REQUEST_SUCCESS": {
      return {
        ...state,
        todoList: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_TODO_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load todo list.");
      return {
        ...state,
        isLoading: false,
      };
    }

    case "ADD_TODO_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_TODO_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Todo added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_TODO_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add Todo.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_TODO_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_TODO_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Todo updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_TODO_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update Todo.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_TODO_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_TODO_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Todo deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_TODO_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete Todo.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_TODO_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_TODO_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Todo status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_TODO_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change Todo status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default TodoReducer;
