import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Modal, Radio, Select } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const AddAgreementInstitute = ({
  record,
  isAsignModelVisible,
  setIsAsignModelVisible,
  instituteId,
  isUpdate,
  fetchInstituteById,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [agreementtype, setAgreementtype] = useState(null);
  const HeadAgentState = useSelector((store) => store?.HeadAgentReducer);

  console.log("data res", record);

  useEffect(() => {
    dispatch({
      type: "FETCH_ACTIVE_HEADAGENT_REQUEST",
      payload: {
        page: 1,
        pageSize: 100,
        branchId: localStorage.getItem("branchId"),
      },
    });
  }, []);

  const handleSubmitAgreementDate = (values) => {
    dispatch({
      type: "ADD_AGREEMENTDATE_REQUEST",
      payload: {
        ...values,
        id: instituteId,
        superAgentEmail:
          values?.agreementType === "Partner" ? "" : values?.superAgentEmail,
      },
      isSuccessFn: true,
      successFunction: () => {
        setIsAsignModelVisible(false);
        fetchInstituteById();
      },
    });
  };

  const disabledDate = (current) => {
    const startDate = form.getFieldValue("agreementStartDate");
    return current && current < startDate;
  };

  return (
    <Modal
      title={`${isUpdate ? "Edit" : "Add"} Agreement Date`}
      open={isAsignModelVisible}
      onCancel={() => {
        setIsAsignModelVisible(false);
      }}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmitAgreementDate}
        initialValues={{
          agreementStartDate: record?.agreementStartDate
            ? moment(record?.agreementStartDate)
            : null,
          agreementEndDate: record?.agreementExpiryDate
            ? moment(record?.agreementExpiryDate)
            : null,
          agreementType: record?.agreementType,
          superAgentEmail: record?.headAgentEmail,
        }}
      >
        <Form.Item
          label="Agreement Start Date"
          name={"agreementStartDate"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter Agreement StartDate",
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} format={"DD-MM-YYYY"} />
        </Form.Item>
        <Form.Item
          label="Agreement Expiry Date"
          name={"agreementEndDate"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter Agreement ExpiryDate",
            },
          ]}
        >
          <DatePicker
            style={{ width: "100%" }}
            format={"DD-MM-YYYY"}
            disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item
          label="Agreement Type"
          name={"agreementType"}
          style={{ width: "100%" }}
        >
          <Radio.Group
            onChange={(e) => {
              setAgreementtype(e.target.value);
            }}
          >
            <Radio value="Partner">Partner</Radio>
            <Radio value="Head Agent">Head Agent</Radio>
          </Radio.Group>
        </Form.Item>
        {agreementtype === "Head Agent" && (
          <Form.Item
            label="Head Agent"
            name={"superAgentEmail"}
            style={{ width: "100%" }}
          >
            <Select placeholder="Select Super Agent" style={{ width: "100%" }}>
              {HeadAgentState?.activeHeadAgent?.data?.map((dataObj) => (
                <Select.Option value={dataObj?.email}>
                  {dataObj?.email}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            block
            type="primary"
            htmlType="submit"
            style={{ width: "fit-content" }}
          >
            {`${isUpdate ? "Edit" : "Add"} Agreement Date`}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddAgreementInstitute;
