import React, { useEffect, useRef, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Button,
  Image,
  Input,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import client from "./../../Assets/account.png";
import activeClient from "./../../Assets/add-contact.png";
import AddClient from "./AddClient";
import {
  AiOutlineCloudUpload,
  AiOutlineDownload,
  AiOutlineEye,
  AiOutlinePlus,
  AiOutlinePoweroff,
} from "react-icons/ai";
import ClientDetails from "./ClientDetails";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import { BiBlock } from "react-icons/bi";
import { formattedDate } from "../../HelperFunction";
import AddLead from "../Leads/AddLead";
import { BsDownload } from "react-icons/bs";
import { CSVLink } from "react-csv";
import SendComposedMail from "../Mailbox/SendComposedMail";
const { Search } = Input;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => {
      if (
        a?.name?.props?.children?.props?.children?.props?.children <
        b?.name?.props?.children?.props?.children?.props?.children
      ) {
        return -1;
      }
      if (
        a?.name?.props?.children?.props?.children?.props?.children >
        b?.name?.props?.children?.props?.children?.props?.children
      ) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Image",
    dataIndex: "image",
    align: "center",
  },
  {
    title: "Mobile No.",
    dataIndex: "mobile",
    sorter: (a, b) => {
      if (a.mobile < b.mobile) {
        return -1;
      }
      if (a.mobile > b.mobile) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    sorter: (a, b) => {
      if (a.email < b.email) {
        return -1;
      }
      if (a.email > b.email) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },

  {
    title: "Country of Residence",
    dataIndex: "country",
    sorter: (a, b) => {
      if (a.country < b.country) {
        return -1;
      }
      if (a.country > b.country) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    sorter: (a, b) => {
      if (a.phone < b.phone) {
        return -1;
      }
      if (a.phone > b.phone) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Address",
    dataIndex: "address",
    sorter: (a, b) => {
      if (a.address < b.address) {
        return -1;
      }
      if (a.address > b.address) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Campaign",
    dataIndex: "campaign",
    width: 100,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "activeStatus",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
    fixed: "right",
  },
];

const Clients = () => {
  const store = useSelector((state) => {
    return {
      clientState: state.ClientsReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const [searchValue, setSearchValue] = useState("");
  const csvDownloadRef = useRef(null);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [isAddClientModalVisible, setIsAddClientModalVisible] = useState(false);
  const [isUpdateClientsModalVisible, setIsUpdateClientsModalVisible] =
    useState(false);
  const [isClientDetailsModelVisible, setIsClientDetailsModelVisible] =
    useState(false);
  const [isAddLeadModelVisible, setIsAddLeadModelVisible] = useState(false);

  const [record, setRecord] = useState({});
  const [clientType, setClientsType] = useState("get-all-customer");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState(false);
  const [dataArray, setDataArray] = useState([]);

  const dispatch = useDispatch();
  const payloadFunction = (response) => {};
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  const onSearch = (e) => {
    if (clientType === "active") {
      dispatch({
        type: "SEARCH_ACTIVE_CLIENTS_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else if (clientType === "get-all-customer") {
      dispatch({
        type: "SEARCH_CLIENTS_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    }
  };
  useEffect(() => {
    if (clientType === "active") {
      dispatch({
        type: "FETCH_ACTIVE_CLIENT_REQUEST",
        payload: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else {
      dispatch({
        type: "FETCH_CLIENT_REQUEST",
        payload: payloadFunction,
        payloadPage: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    }
  }, [clientType, page, pageSize, store.branchState?.branchRecord]);
  useEffect(() => {
    if (
      isAddClientModalVisible === false ||
      isUpdateClientsModalVisible === false
    ) {
      dispatch({
        type: "FETCH_CLIENT_REQUEST",
        payloadPage: { page, pageSize },
      });
    }
  }, [isAddClientModalVisible, isUpdateClientsModalVisible]);
  useEffect(() => {
    if (
      dataArray &&
      csvDownloadRef &&
      csvDownloadRef.current &&
      csvDownloadRef.current.link
    ) {
      csvDownloadRef.current.link.click();
    }
  }, [dataArray]);
  let data = [];
  if (clientType === "get-all-customer") {
    data = store?.clientState.clients?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        name: (
          <Tooltip title="Client Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsClientDetailsModelVisible(true);
                  setRecord(dataObj);
                }}
              >
                {dataObj.firstName + " " + dataObj.lastName}
              </div>
            </a>
          </Tooltip>
        ),
        image: dataObj.photo ? (
          <Image
            src={dataObj.photo}
            style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
          />
        ) : (
          "N/A"
        ),
        mobile: dataObj.mobile ? dataObj.mobile : "N/A",
        email: dataObj.email ? (
          <Tooltip title="Send Email">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                {dataObj.email}
              </div>
            </a>
          </Tooltip>
        ) : (
          "N/A"
        ),
        campaign: dataObj.campaign?.name ?? "N/A",
        address: dataObj.address ? dataObj.address : "N/A",
        country: dataObj.countryOfResidence
          ? dataObj.countryOfResidence
          : "N/A",
        // dob: formattedDate(dataObj.dob),
        phone: dataObj.phone ? dataObj.phone : "N/A",
        activeStatus: dataObj.activeStatus ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {dataObj.activeStatus &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.LEADS?.includes(
                "ADD"
              ) && (
                <Tooltip title="Add Lead">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setRecord(dataObj);
                        setIsAddLeadModelVisible(true);
                      }}
                    >
                      <AiOutlinePlus />
                    </div>
                  </a>
                </Tooltip>
              )}
            {/* <Tooltip title="Update client">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateClientsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip> */}
            {/* <Tooltip title="Delete client">
              <Popconfirm
                title="Are you sure to delete this client?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_CLIENT_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip> */}
            <Tooltip title="Client Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsClientDetailsModelVisible(true);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {!dataObj.activeStatus &&
            store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
              "CHANGE_STATUS"
            ) ? (
              <Tooltip title="Activate contact">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus = dataObj.activeStatus
                        ? "inactive"
                        : "active";
                      dispatch({
                        type: "CHANGE_CLIENT_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            ) : store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
                "CHANGE_STATUS"
              ) ? (
              <Tooltip placement="topRight" title="Deactivate contact">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus = dataObj.activeStatus
                        ? "inactive"
                        : "active";
                      dispatch({
                        type: "CHANGE_CLIENT_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            ) : null}

            {/* <Tooltip title="Toggle client status">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    const actionStatus =
                      dataObj.status === "INACTIVE" ? "active" : "inactive";
                    dispatch({
                      type: "TOGGLE_CLIENT_STATUS_REQUEST",
                      payload: { id: dataObj.id, actionStatus },
                    });
                  }}
                >
                  <MdOutlineDisabledVisible />
                </div>
              </a>
            </Tooltip> */}
          </Space>
        ),
      };
    });
  } else {
    data = store?.clientState.activeClients?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        name: (
          <Tooltip title="Client Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsClientDetailsModelVisible(true);
                  setRecord(dataObj);
                }}
              >
                {dataObj.firstName + " " + dataObj.lastName}
              </div>
            </a>
          </Tooltip>
        ),
        image: dataObj.photo ? (
          <Image
            src={dataObj.photo}
            style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
          />
        ) : (
          "N/A"
        ),
        mobile: dataObj.mobile ? dataObj.mobile : "N/A",
        email: dataObj.email ? (
          <Tooltip title="Send Email">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                {dataObj.email}
              </div>
            </a>
          </Tooltip>
        ) : (
          "N/A"
        ),
        campaign: dataObj.campaign?.name ?? "N/A",

        country: dataObj.countryOfResidence
          ? dataObj.countryOfResidence
          : "N/A",

        address: dataObj.address ? dataObj.address : "N/A",
        dob: formattedDate(dataObj.dob),
        phone: dataObj.phone ? dataObj.phone : "N/A",
        activeStatus: dataObj.activeStatus ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {dataObj.activeStatus &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.LEADS?.includes(
                "ADD"
              ) && (
                <Tooltip title="Add Lead">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setRecord(dataObj);
                        setIsAddLeadModelVisible(true);
                      }}
                    >
                      <AiOutlinePlus />
                    </div>
                  </a>
                </Tooltip>
              )}
            {/* <Tooltip title="Update client">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateClientsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip> */}
            {/* <Tooltip title="Delete client">
              <Popconfirm
                title="Are you sure to delete this client?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_CLIENT_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip> */}
            <Tooltip title="Client Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsClientDetailsModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
              "CHANGE_STATUS"
            ) && (
              <Tooltip placement="topRight" title="Deactivate Client">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus = dataObj.activeStatus
                        ? "inactive"
                        : "active";
                      dispatch({
                        type: "CHANGE_CLIENT_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            )}
            {/* <Tooltip title="Toggle client status">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus =
                        dataObj.=== "INACTIVE" ? "active" : "inactive";
                      dispatch({
                        type: "TOGGLE_CLIENT_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <MdOutlineDisabledVisible />
                  </div>
                </a>
              </Tooltip> */}
          </Space>
        ),
      };
    });
  }
  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  const CSVSuccessFunction = (response) => {
    const dataArray = response?.data?.split("\n").map((row) => row.split(","));
    setDataArray(dataArray);
  };
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="leadFlexRow">
          <div style={{ width: "30%" }}>
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
              "ADD"
            ) && (
              <button
                className="button"
                onClick={() => {
                  setIsAddClientModalVisible(true);
                }}
              >
                <span>Add Clients</span>
              </button>
            )}
          </div>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={client} size={20} shape="square" />
                    <div>All Clients</div>
                  </div>
                ),
                value: "get-all-customer",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={activeClient} size={20} shape="square" />
                    <div>Active Clients</div>
                  </div>
                ),
                value: "active",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setClientsType(value);
            }}
          />
          <div
            style={{
              display: "flex",
              width: "30%",
              alignItems: "center",
              gap: "0.3rem",
            }}
          >
            <Search
              className="search"
              style={{
                width: "100%",
                borderRadius: "10px ! important",
              }}
              placeholder="Search for clients"
              allowClear
              size="large"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                onSearch(e.target.value);
              }}
            />
            {/* <div>
              <Tooltip title={"Upload CSV"} placement={"topLeft"}>
                <input
                  type={"file"}
                  name="file1"
                  style={{ display: "none" }}
                  id="file1"
                  onChange={(e) => {
                    console.log("from CSV file change", e);
                    const importCSVFormData = new FormData();
                    importCSVFormData.append("file", e.target.files[0]);
                    dispatch({
                      type: "IMPORT_CLIENT_CSV_FILE_REQUEST",
                      payload: importCSVFormData,
                    });
                  }}
                />
                <label for="file1">
                  <div
                    className="flexCenter"
                    style={{
                      border: "1px solid #d9d9d9",
                      borderRadius: "50%",
                      padding: "0.3rem",
                      height: "2rem",
                      width: "2rem",
                    }}
                  >
                    <AiOutlineCloudUpload style={{ fontSize: "20px" }} />
                  </div>
                </label>
              </Tooltip>
            </div> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {store?.clientState.isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              {selectedRowKeys.length > 0 && selectedVisible && (
                <Alert
                  message={`Selected ${selectedRowKeys.length} items.`}
                  type="success"
                  closable
                  afterClose={() => {
                    setSelectedVisible(false);
                    setSelectedRowKeys([]);
                  }}
                  action={
                    <Tooltip title={"Download CSV"}>
                      {store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
                        "DOWNLOAD_CSV"
                      ) && (
                        <Button
                          size="small"
                          type="primary"
                          onClick={(e) => {
                            const csvPayload = {
                              customerIds: selectedRowKeys?.map((dataObj) => {
                                return {
                                  id: dataObj,
                                };
                              }),
                            };

                            dispatch({
                              type: "DOWNLOAD_CSV_REQUEST",
                              payload: csvPayload,
                              fromClient: true,
                              CSVSuccessFunction: CSVSuccessFunction,
                            });
                          }}
                        >
                          Download CSV
                        </Button>
                      )}
                      <CSVLink
                        ref={csvDownloadRef}
                        asyncOnClick={true}
                        data={dataArray}
                        target="_blank"
                        filename="ClientCSV.csv"
                      />
                    </Tooltip>
                  }
                />
              )}
              <Table
                rowSelection={{
                  selectedRowKeys,
                  onChange: onSelectChange,
                }}
                bordered
                columns={columns}
                dataSource={data}
                pagination={false}
              />
            </>
          )}
        </div>

        {store?.clientState.clients?.totalData ? (
          <Pagination
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
              alignSelf: "flex-end",
            }}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            defaultCurrent={1}
            current={page}
            defaultPageSize={pageSize}
            onChange={onShowSizeChange}
            total={store?.clientState.clients?.totalPage * 10}
          />
        ) : null}
      </div>
      {isAddClientModalVisible && (
        <AddClient
          isAddClientModalVisible={isAddClientModalVisible}
          setIsAddClientModalVisible={setIsAddClientModalVisible}
        />
      )}
      {/* <UpdateClients
        record={record}
        isUpdateClientsModalVisible={isUpdateClientsModalVisible}
        setIsUpdateClientsModalVisible={setIsUpdateClientsModalVisible}
      /> */}
      {isClientDetailsModelVisible && (
        <ClientDetails
          Record={record}
          isClientDetailsModelVisible={isClientDetailsModelVisible}
          setIsClientDetailsModelVisible={setIsClientDetailsModelVisible}
        />
      )}
      {isAddLeadModelVisible && (
        <AddLead
          fromSiderDemo={true}
          fromContacts={false}
          contactRecord={record}
          isAddLeadVisible={isAddLeadModelVisible}
          setIsAddLeadVisible={setIsAddLeadModelVisible}
        />
      )}
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          composeMailRecord={{
            toUser: [record?.email],
          }}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
    </SiderDemo>
  );
};

export default Clients;
