import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import { BiUserCheck, BiUser } from "react-icons/bi";
import profile from "../../Assets/profile.png";
import { CiWarning } from "react-icons/ci";
import {
  Badge,
  Button,
  Card,
  Divider,
  Form,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import UpdateProfile from "./UpdateProfile";
import {
  formattedDate,
  formattedDateTime,
  twoTagResponses,
} from "../../HelperFunction";
import { AiOutlineCheck } from "react-icons/ai";
import Confirmation from "../EmployeeManager/Department/Confirmation";
import { BsCamera } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import ProfileTabs from "./ProfileTabs";
import ChangePassword from "../../Components/PasswordComponent/ChangePassword";
const { Paragraph } = Typography;

const Profile = ({ children }) => {
  const store = useSelector((state) => {
    return {
      profileState: state.LoginReducer,
      loginState: state.ProfileReducer,
    };
  });
  const [record, setRecord] = useState({});
  const [subscriptionRecord, setSubscriptionRecord] = useState();
  const [isUpdateProfileModalVisible, setIsUpdateProfileModalVisible] =
    useState(false);
  const [isconfirmationModalVisible, setIsconfirmationModalVisible] =
    useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "FETCH_PROFILE_REQUEST" });
    dispatch({ type: "FETCH_PACKAGE_REQUEST" });
    setRecord(store.loginState.profile);
  }, []);

  const navigate = useNavigate();
  //   setRecord(store.loginState.profile);

  return (
    <SiderDemo organizationalSettings={true}>
      <div style={{ display: "flex", gap: "1rem", margin: "1.5rem 1rem 1rem" }}>
        <div
          style={{
            width: "35%",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              border: "10px",
              border: "2px solid #f0f2f5",
              display: "flex",
              flexDirection: "column",
              padding: "0.5rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "1rem",
                  height: "80px",
                  width: "80px",
                }}
              >
                <input
                  type={"file"}
                  name="file1"
                  style={{ display: "none" }}
                  id="file1"
                  onChange={(e) => {
                    const profileFormData = new FormData();
                    profileFormData.append("image", e.target.files[0]);

                    dispatch({
                      type: "CHANGE_PROFILE_IMAGE_REQUEST",
                      payload: profileFormData,
                    });
                  }}
                />
                <label for="file1">
                  <div className="container">
                    <img
                      className="profileImage"
                      src={
                        store.loginState.profile?.profileImage
                          ? store.loginState.profile?.profileImage
                          : profile
                      }
                      alt="profile image"
                      style={{
                        borderRadius: "50%",
                        height: "80px",
                        width: "80px",
                      }}
                    />
                    <div className="middle">
                      <div className="text">Upload</div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className="center" style={{}}>
              <h3>
                {store.loginState.profile.firstName +
                  " " +
                  store.loginState.profile.lastName}
              </h3>
            </div>
            {/* <div className="center">
              <Tooltip title="Edit" placement="bottom">
                <a className="icon">
                  <div className="bordered" onClick={() => {}}>
                    <EditOutlined style={{ fontSize: "15px" }} />
                  </div>
                </a>
              </Tooltip>
            </div> */}

            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

            <div>
              <h4>PERSONAL INFORMATION:</h4>
            </div>
            <div>
              <div className="profileTitle">Office:</div>
              <div>
                {store.loginState.profile.companyName
                  ? store.loginState.profile.companyName
                  : "N/A"}
              </div>
            </div>
            <div>
              <div className="profileTitle">Email:</div>
              <div>
                <Paragraph ellipsis={true}>
                  {store.loginState.profile.emailId
                    ? store.loginState.profile.emailId
                    : "N/A"}
                </Paragraph>
              </div>
            </div>
            <div>
              <div className="profileTitle">Registration Number:</div>
              <div>
                {store.loginState.profile.companyRegistrationNumber
                  ? store.loginState.profile.companyRegistrationNumber
                  : "N/A"}
              </div>
            </div>
            <div>
              <div className="profileTitle">Subscription Type:</div>
              <div>{store.loginState.profile.subscriptionName ?? "N/A"}</div>
            </div>
            <div>
              <div className="profileTitle">Subscription Ends in:</div>
              <div>
                {store.loginState.profile.subscriptionEndDate
                  ? formattedDate(store.loginState.profile.subscriptionEndDate)
                  : ""}
              </div>
            </div>
            <div>
              <span
                style={{
                  color: "#1890ff",
                  cursor: "pointer",
                  padding: "1rem 0",
                }}
                onClick={() => {
                  setIsModalVisible(true);
                }}
              >
                Change Password
              </span>
            </div>
            {/* <div>
              <div className="profileTitle">Permissions:</div>
              <div>
                {store.loginState?.profile?.allowedPermissionList?.map(
                  (dataObj, index) => {
                    return (
                      <Tag
                        color="blue"
                        key={index}
                        style={{
                          fontSize: "8px",
                          borderRadius: "0.8rem",
                          padding: "1px",
                          margin: "2px",
                          color: "black",
                        }}
                      >
                        {dataObj}
                      </Tag>
                    );
                  }
                )}
              </div>
            </div> */}
          </div>
        </div>
        <div style={{ width: "95%" }}>
          <ProfileTabs />
        </div>
      </div>
      {isModalVisible && (
        <ChangePassword
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </SiderDemo>
  );
};

export default Profile;

// {/* {store.loginState.subscriptionResponse?.paymentStatus === "unpaid" &&
//       store.loginState.subscriptionResponse?.redirectURL ? (
//         <a
//           href={store.loginState.subscriptionResponse?.redirectURL}
//           target="_blank"
//           rel="noopener noreferrer"
//         ></a>
//       ) : ( */}
// <Card loading={store.loginState.isLoading}>
//   <div
//     style={{
//       padding: "2rem",
//       borderRadius: "2rem",
//     }}
//   >
//     <div
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         margin: "2rem",
//       }}
//     >
//       <div
//         style={{
//           width: "100%",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           flexDirection: "column",
//           gap: "0.5rem",
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             border: "1px solid #121212",
//             borderRadius: "50%",
//           }}
//         >
//           <input
//             type={"file"}
//             name="file"
//             style={{ display: "none" }}
//             id="file"
//             onChange={(e) => {
//               const profileFormData = new FormData();
//               profileFormData.append("image", e.target.files[0]);
//               dispatch({
//                 type: "CHANGE_PROFILE_IMAGE_REQUEST",
//                 payload: profileFormData,
//               });
//             }}
//           />
//           <label for="file">
//             <div className="container">
//               <img
//                 className="profileImage"
//                 src={
//                   store.loginState.profile?.profileImage
//                     ? store.loginState.profile?.profileImage
//                     : profile
//                 }
//                 alt="profile image"
//                 style={{
//                   borderRadius: "50%",
//                   height: "150px",
//                   width: "150px",
//                 }}
//               />
//               <div className="middle">
//                 <div className="text">Upload new profile picture</div>
//               </div>
//             </div>
//           </label>
//         </div>

//         <p
//           style={{
//             backgroundColor: "#eeeeee",
//             color: "#555",
//             fontSize: "25px",
//             padding: "0.3rem",
//             fontWeight: "500",
//             borderRadius: "20px",
//           }}
//         >
//           {store.loginState.profile.firstName +
//             " " +
//             store.loginState.profile.lastName}
//         </p>
//       </div>
//     </div>
//     <div
//       className="flexRowWithoutStyle"
//       style={{
//         justifyContent: "space-between",
//         gap: "1rem",
//       }}
//     >
//       <div
//         className="flexColumnwithoutStyle"
//         style={{
//           width: "50%",
//           border: "1px solid #dedede",
//           borderRadius: "2rem",
//           padding: "1rem",
//         }}
//       >
//         <div
//           className="flexRowWithoutStyle"
//           style={{
//             justifyContent: "space-between",
//             padding: "0.6rem",
//             borderRadius: "2rem",
//           }}
//         >
//           <span>username:</span>
//           <span>
//             {store.loginState.profile.userName
//               ? store.loginState.profile.userName
//               : "N/A"}
//           </span>
//         </div>
//         <div className="flexRowWithoutStyle cardStyle">
//           <span>Email:</span>
//           <span>
//             {store.loginState.profile.emailId
//               ? store.loginState.profile.emailId
//               : "N/A"}
//           </span>
//         </div>
//         <div className="flexRowWithoutStyle cardStyle">
//           <span>Phone Number:</span>
//           <span>
//             {store.loginState.profile.phoneNumber
//               ? store.loginState.profile.phoneNumber
//               : "N/A"}
//           </span>
//         </div>

//         <div className="flexRowWithoutStyle cardStyle">
//           <span>company Name:</span>
//           <span>
//             {store.loginState.profile.companyName
//               ? store.loginState.profile.companyName
//               : "N/A"}
//           </span>
//         </div>

//         <div className="flexRowWithoutStyle cardStyle">
//           <span>ABN/ACN:</span>
//           <span>
//             {store.loginState.profile.companyRegistrationNumber
//               ? store.loginState.profile.companyRegistrationNumber
//               : "N/A"}
//           </span>
//         </div>
//       </div>
//       <div
//         className="flexColumnwithoutStyle"
//         style={{
//           width: "50%",
//           border: "1px solid #dedede",
//           borderRadius: "2rem",
//           padding: "1rem",
//         }}
//       >
//         <div className="flexRowWithoutStyle cardStyle">
//           <span>Subscription Type:</span>
//           <span>{store.loginState.profile.subscriptionName}</span>
//         </div>
//         {store.profileState?.loginData.isSubscriptionExpired && (
//           <div className="flexRowWithoutStyle cardStyle">
//             <span>Is subscription expired?:</span>
//             <span>
//               {twoTagResponses(
//                 store.profileState?.loginData.isSubscriptionExpired,
//                 true,
//                 false
//               )}
//             </span>
//           </div>
//         )}

//         <div className="flexRowWithoutStyle cardStyle">
//           <span>Subscription Start Date:</span>
//           <span>
//             {store.loginState.profile.subscriptionStartDate
//               ? formattedDateTime(
//                   store.loginState.profile.subscriptionStartDate
//                 )
//               : ""}
//           </span>
//         </div>
//         <div className="flexRowWithoutStyle cardStyle">
//           <span>Subscription End Date:</span>
//           <span>
//             {store.loginState.profile.subscriptionEndDate
//               ? formattedDateTime(
//                   store.loginState.profile.subscriptionEndDate
//                 )
//               : ""}
//           </span>
//         </div>
//         {store.profileState?.loginData.isConsultant !== null &&
//           store.profileState?.loginData.isConsultant !== undefined && (
//             <div className="flexRowWithoutStyle cardStyle">
//               <span>Is consultant?:</span>
//               <span>
//                 {twoTagResponses(
//                   store.profileState?.loginData.isConsultant,
//                   true,
//                   false
//                 )}
//               </span>
//             </div>
//           )}
//         {store.profileState?.loginData.isTwoFAEnabled !== null &&
//           store.profileState?.loginData.isTwoFAEnabled !== undefined && (
//             <div className="flexRowWithoutStyle cardStyle">
//               <span>Is two factor authentication enabled?:</span>
//               <span>
//                 {twoTagResponses(
//                   store.profileState?.loginData.isTwoFAEnabled,
//                   true,
//                   false
//                 )}
//               </span>
//             </div>
//           )}
//       </div>
//     </div>
//     <div
//       className="flexColumnwithoutStyle"
//       style={{
//         border: "1px solid #dedede",
//         borderRadius: "2rem",
//         padding: "1rem",
//         margin: "1rem 0",
//       }}
//     >
//       <div className="flexRowWithoutStyle cardStyle">
//         <span>Permissions:</span>
//         <span>
// {store.profileState?.loginData?.allowedPermissionList?.map(
//   (dataObj, index) => {
//     console.log("this is akdflj");
//     return (
//       <Tag color="blue" key={index}>
//         {dataObj}
//       </Tag>
//     );
//   }
// )}
//         </span>
//       </div>
//     </div>
//     <div
//       className="cardStyle"
//       style={{
//         justifyContent: "flex-start",
//         backgroundColor: "white",
//         margin: "1rem 0",
//         display: "flex",
//         alignItems: "center",
//         gap: "0.5rem",
//         color: "red",
//         fontWeight: "bold",
//       }}
//     >
//       <span>
//         <CiWarning size={20} />
//       </span>
//       <span>
//         {store.loginState.profile.subscriptionEndDate
//           ? `Your subscription will expire on ${formattedDateTime(
//               store.loginState.profile.subscriptionEndDate
//             )}`
//           : null}
//       </span>
//     </div>
//     <div>
//       <Button
//         block
//         type="primary"
//         onClick={() => {
//           setIsUpdateProfileModalVisible(true);
//           setSubscriptionRecord(store.loginState.profile);
//         }}
//       >
//         Update Profile
//       </Button>
//     </div>
// <div
//   style={{
//     display: "flex",
//     gap: "0.5rem",
//     margin: "2rem",
//     justifyContent: "space-between",
//     flexWrap: "wrap",
//   }}
// >
//   {store.loginState.packages.map((dataObj) => {
//     return (
//       <Badge.Ribbon text={dataObj.packageName} color="cyan">
//         <Card
//           key={dataObj.id}
//           style={{
//             borderRadius: "1rem",
//             minWidth: "100%",
//           }}
//           title={dataObj.packageName}
//         >
//           <p>
//             <span>
//               <AiOutlineCheck color="green" />
//             </span>{" "}
//             Package Price: <span>${dataObj.packagePrice}</span>
//           </p>
//           <p>
//             <AiOutlineCheck color="green" />
//             Discount: <span>{dataObj.discount}%</span>
//           </p>
//           <p>
//             <AiOutlineCheck color="green" />
//             Lead Form: <span>{dataObj.leadForms}</span>
//           </p>
//           <p>
//             <AiOutlineCheck color="green" />
//             Lead Generation: <span>{dataObj.leadGeneration}</span>
//           </p>
//           <p>
//             <AiOutlineCheck color="green" />
//             Storage: <span>{dataObj.storage}</span>
//           </p>

//           <div>
//             <Button
//               block
//               type="primary"
//               onClick={() => {
//                 dispatch({
//                   type: "PURCHASE_SUBSCRIPTION_REQUEST",
//                   payload: {
//                     subscriptionId: dataObj.id,
//                     confirmationText: "",
//                   },
//                 });
//                 // setSubscriptionRecord(dataObj);
//                 // setIsconfirmationModalVisible(true);
//               }}
//               style={{ borderRadius: "2rem" }}
//             >
//               GET STARTED
//             </Button>
//           </div>
//         </Card>
//       </Badge.Ribbon>
//     );
//   })}
// </div>
//   </div>
//   <UpdateProfile
//     record={record}
//     isUpdateProfileModalVisible={isUpdateProfileModalVisible}
//     setIsUpdateProfileModalVisible={setIsUpdateProfileModalVisible}
//   />
//   {isconfirmationModalVisible && (
//     <Confirmation
//       onSubmit={(value) => {
//         console.warn(value, "value from teh confirmation box");
//         setIsconfirmationModalVisible(false);
//         dispatch({
//           type: "PURCHASE_SUBSCRIPTION_REQUEST",
//           payload: {
//             subscriptionId: subscriptionRecord.id,
//             confirmationText: value.confirmationText,
//           },
//         });
//       }}
//       isconfirmationModalVisible={isconfirmationModalVisible}
//       setIsconfirmationModalVisible={setIsconfirmationModalVisible}
//     />
//   )}
// </Card>
{
  /* )} */
}
