import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { BsRecord } from "react-icons/bs";
const { Option } = Select;

const UpdateMigrationWorkflow = ({
  workflowRecord,
  countryRecord,
  isUpdateMigrationWorkflowModalVisible,
  setIsUpdateMigrationWorkflowModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const reloadFn = () => {
    dispatch({
      type: "FETCH_OTHERS_WORKFLOW_REQUEST",
      payload: { id: countryRecord.id },
    });
  };
  const updateMigrationWorkflowSubmitHandler = (value) => {
    const formData = {
      ...value,
    };

    dispatch({
      type: "UPDATE_MIGRATION_WORKFLOW_REQUEST",
      payload: {
        id: workflowRecord.id,
        formData,
        countryId: countryRecord.id,
        isReloadFn: true,
        reloadFn: reloadFn,
      },
    });
    form.resetFields();
    setIsUpdateMigrationWorkflowModalVisible(false);
  };
  return (
    <Modal
      title="Update Workflow"
      open={isUpdateMigrationWorkflowModalVisible}
      onCancel={() => {
        setIsUpdateMigrationWorkflowModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateMigrationWorkflowSubmitHandler}
        colon={true}
        form={form}
        fields={[{ name: ["name"], value: workflowRecord.statusName }]}
      >
        <Form.Item
          label="Workflow Name"
          name={"name"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter workflow name!",
            },
          ]}
        >
          <Input placeholder="Workflow Name" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Workflow
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateMigrationWorkflow;
