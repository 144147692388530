import React from "react";
import CountUp from "react-countup";

const DashboardHeader = ({ title, end, dashboardHeaderIcon }) => {
  return (
    <div
      style={{
        border: "1px solid #cccccc",
        padding: "1rem",
        backgroundColor: "white",
        // width: "260px",
        minWidth: "14rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: "5px",
        height: "100px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "80%",
        }}
      >
        <div
          style={{
            fontSize: "12px",
            fontWeight: "700",
            color: "#888888",
          }}
        >
          {title}
        </div>
        <div>
          <CountUp
            end={end}
            style={{
              fontSize: "22px",
              fontWeight: "700",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        {dashboardHeaderIcon}
      </div>
    </div>
  );
};

export default DashboardHeader;
