import { Menu, Layout, Divider, Card } from "antd";
import React, { useEffect, useState } from "react";
import { Children } from "react";
import { GoDashboard } from "react-icons/go";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SiderDemo from "../../Components/Layout/SiderDemo";
import SubscriptionPlan from "./SubscriptionPlan/SubscriptionPlan";
import UpdateCompanyProfile from "./UpdateCompanyProfile";
const { Header, Sider, Content } = Layout;

const ProfileTabs = () => {
  const store = useSelector((state) => {
    return {
      profileState: state.LoginReducer,
      loginState: state.ProfileReducer,
    };
  });
  const [record, setRecord] = useState({});
  const [current, setCurrent] = useState("companyProfile");

  const dispatch = useDispatch();

  const clientDetailsTabList = [
    {
      key: "companyProfile",
      tab: "Company Profile",
    },
    {
      key: "subscriptionPlan",
      tab: "Subscription",
    },
    // {
    //   key: "subscriptionBilling",
    //   tab: "Subscription Billing",
    // },
  ];
  return (
    <div>
      <Card
        bordered={false}
        tabList={clientDetailsTabList}
        activeTabKey={current}
        onTabChange={(key) => {
          setCurrent(key);
        }}
      >
        {current === "companyProfile" && (
          <>
            <div>
              <UpdateCompanyProfile profileRecord={store.loginState?.profile} />
            </div>
          </>
        )}
        {current === "subscriptionPlan" && (
          <>
            <div>
              <SubscriptionPlan profileRecord={store.loginState} />
            </div>
          </>
        )}
        {current === "subscriptionBilling" && (
          <>
            <div>coming soon...</div>
          </>
        )}
      </Card>
    </div>
  );
};

export default ProfileTabs;
