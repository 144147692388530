import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Tag,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import parse, { domToReact } from "html-react-parser";
import { useSelector } from "react-redux";
import { scrollToTopFormError } from "../../HelperFunction";
const { Panel } = Collapse;
const { Option } = Select;

const SendComposedMail = ({
  mailTemplateType = "",
  forWhatId = null,
  composeMailRecord,
  isSendComposedEmailModalVisible,
  setIsSendComposedEmailModalVisible,
  fetchEmailLogDetails = () => {},
}) => {
  const store = useSelector((state) => {
    return {
      emailTemplateState: state.OrganizationSettingsReducer,
      searchedEmailState: state.MailboxReducer,
    };
  });

  const quillRef = useRef(null);
  const [selectedText, setselectedText] = useState(null);
  const [quillValue, setQuillValue] = useState("");
  const [templateType, setTemplateType] = useState("Select");
  const [mailboxComposeData, setMailboxComposeData] = useState({
    bodyContent: composeMailRecord?.bodyContent,
    // subjectContent: composeMailRecord?.subjectContent,
    subjectContent: "",
    toEmail:
      composeMailRecord?.toUser?.length > 0
        ? composeMailRecord?.toUser?.map((item) => {
            return {
              value: item,
              label: item,
            };
          })
        : [],
    cc: [],
    bcc: [],
    identifierId: composeMailRecord?.identifierId,
    draftId: composeMailRecord?.draftId,
  });
  const [keyword, setKeyword] = useState("");
  const dispatch = useDispatch();

  const successPayloadFn = (data) => {
    setMailboxComposeData((previousData) => {
      return {
        ...previousData,
        bodyContent: data?.data?.templateText,
        subjectContent: data?.data?.templateSubject,
      };
    });
  };

  useEffect(() => {
    if (mailTemplateType === "LEAD") {
      dispatch({
        type: "FETCH_LEAD_EMAIL_TEMPLATE_REQUEST",
        payload: "LEAD",
        isSuccessFn: true,

        successPayload: successPayloadFn,
      });
    } else if (mailTemplateType === "CASE") {
      dispatch({
        type: "FETCH_CASE_EMAIL_TEMPLATE_REQUEST",
        payload: "CASE",
        isSuccessFn: true,

        successPayload: successPayloadFn,
      });
    } else if (mailTemplateType === "APPOINTMENT") {
      dispatch({
        type: "FETCH_APPOINTMENT_EMAIL_TEMPLATE_REQUEST",
        payload: "APPOINTMENT",
        isSuccessFn: true,

        successPayload: successPayloadFn,
      });
    } else if (mailTemplateType === "CLIENT") {
      dispatch({
        type: "FETCH_CLIENT_EMAIL_TEMPLATE_REQUEST",
        payload: "CLIENT",
        isSuccessFn: true,

        successPayload: successPayloadFn,
      });
    } else if (mailTemplateType === "QUOTATION") {
      dispatch({
        type: "FETCH_QUOTATION_EMAIL_TEMPLATE_REQUEST",
        payload: "QUOTATION",
        isSuccessFn: true,

        successPayload: successPayloadFn,
      });
    } else if (mailTemplateType === "SERVICE") {
      dispatch({
        type: "FETCH_SERVICE_EMAIL_TEMPLATE_REQUEST",
        payload: "SERVICE",
        isSuccessFn: true,

        successPayload: successPayloadFn,
      });
    }
  }, []);

  useEffect(() => {
    if (templateType === "LEAD") {
      dispatch({
        type: "FETCH_LEAD_EMAIL_TEMPLATE_REQUEST",
        payload: "LEAD",
        isSuccessFn: true,

        successPayload: successPayloadFn,
      });
    } else if (templateType === "CASE") {
      dispatch({
        type: "FETCH_CASE_EMAIL_TEMPLATE_REQUEST",
        payload: "CASE",
        isSuccessFn: true,

        successPayload: successPayloadFn,
      });
    } else if (templateType === "APPOINTMENT") {
      dispatch({
        type: "FETCH_APPOINTMENT_EMAIL_TEMPLATE_REQUEST",
        payload: "APPOINTMENT",
        isSuccessFn: true,

        successPayload: successPayloadFn,
      });
    } else if (templateType === "CLIENT") {
      dispatch({
        type: "FETCH_CLIENT_EMAIL_TEMPLATE_REQUEST",
        payload: "CLIENT",
        isSuccessFn: true,

        successPayload: successPayloadFn,
      });
    }
  }, [templateType]);
  const [form] = Form.useForm();

  const sendComposedMailSubmitHandler = (values) => {
    const formData = {
      toEmail: mailboxComposeData.toEmail?.map((dataObj) => {
        return dataObj?.value;
      }),
      ccTo: mailboxComposeData.cc?.map((dataObj) => {
        return dataObj?.value;
      }),
      bccTo: mailboxComposeData.bcc?.map((dataObj) => {
        return dataObj?.value;
      }),
      // subjectContent: mailboxComposeData.subjectContent,
      subjectContent: mailboxComposeData.subjectContent,
      bodyContent: values?.bodyContent,
      forWhat: mailTemplateType ? mailTemplateType : null,
      forWhatId: forWhatId ? forWhatId : null,
    };

    setIsSendComposedEmailModalVisible(false);

    dispatch({
      type: "SEND_COMPOSED_MAIL_REQUEST",
      payload: { formData },
      isSuccessFn: true,
      successFunction: () => {
        form.resetFields();
        if (forWhatId) {
          setTimeout(() => {
            fetchEmailLogDetails();
          }, 1500);
        }
      },
    });
  };
  const saveToDraftHandler = () => {
    const formData = {
      toEmail: mailboxComposeData.toEmail?.map((dataObj) => {
        return dataObj?.value;
      }),
      ccTo: mailboxComposeData.cc?.map((dataObj) => {
        return dataObj?.value;
      }),
      bccTo: mailboxComposeData.bcc?.map((dataObj) => {
        return dataObj?.value;
      }),
      subjectContent: mailboxComposeData.subjectContent,
      bodyContent: mailboxComposeData.bodyContent,
      identifierId: mailboxComposeData.identifierId,
    };
    if (
      mailboxComposeData.toEmail?.length !== 0 ||
      (mailboxComposeData.bodyContent !== "" &&
        mailboxComposeData.bodyContent !== undefined &&
        mailboxComposeData.bodyContent !== null) ||
      (mailboxComposeData.subjectContent !== "" &&
        mailboxComposeData.subjectContent !== undefined &&
        mailboxComposeData.subjectContent !== null)
    ) {
      dispatch({
        type: "ADD_MAIL_TO_DRAFT_REQUEST",
        payload: {
          formData: formData,
          draftId:
            mailboxComposeData.draftId !== undefined
              ? mailboxComposeData.draftId
              : "",
        },
      });
    }
  };

  return (
    <Drawer
      title="Compose Email"
      open={isSendComposedEmailModalVisible}
      onClose={() => {
        saveToDraftHandler();
        setIsSendComposedEmailModalVisible(false);
      }}
      footer={null}
      width={630}
    >
      <Form
        layout="vertical"
        onFinish={sendComposedMailSubmitHandler}
        onFinishFailed={scrollToTopFormError}
        colon={true}
        form={form}
        className={"drawerStyle"}
        initialValues={{
          templates: mailTemplateType,
        }}
        fields={[
          {
            name: ["subjectContent"],
            value: mailboxComposeData.subjectContent,
          },
          {
            name: ["toEmail"],
            value: mailboxComposeData.toEmail,
          },
          {
            name: ["cc"],
            value: mailboxComposeData.cc,
          },
          {
            name: ["bcc"],
            value: mailboxComposeData.bcc,
          },
          {
            name: ["bodyContent"],
            value: mailboxComposeData.bodyContent,
          },
        ]}
      >
        <Form.Item
          label="To"
          name={"toEmail"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter email address you want to send to!",
            },
          ]}
        >
          <Select
            mode="tags"
            disabled={composeMailRecord?.toUser?.length > 0 ? true : false}
            value={mailboxComposeData.toEmail}
            labelInValue
            showSearch
            placeholder="Select Emails"
            onChange={(e) => {
              setMailboxComposeData((previousData) => {
                return {
                  ...previousData,
                  toEmail: e,
                };
              });
            }}
            onSearch={(e) => {
              dispatch({
                type: "FETCH_AVAILABLE_EMAILS_REQUEST",
                payload: {
                  keyword: e,
                },
              });
              console.log("On search", e);
            }}
            options={store.searchedEmailState?.searchedAvialbleEmails?.data?.map(
              (dataObj, index) => {
                return {
                  value: dataObj.email,
                  label: (
                    <div className="flexRowSpaceBetween">
                      <span>{dataObj.email}</span>
                      <Tag
                        color="#2db7f5"
                        style={{ borderRadius: "10px", margin: "3px" }}
                      >
                        {dataObj.sourceType}
                      </Tag>
                    </div>
                  ),
                };
              }
            )}
          />
        </Form.Item>

        <Collapse
          size="small"
          style={{ padding: "0", margin: "0" }}
          bordered={false}
        >
          <Panel header="CC and BCC" key="1">
            <Form.Item label="CC" name={"cc"} style={{ width: "100%" }}>
              <Select
                mode="tags"
                value={mailboxComposeData.toEmail}
                labelInValue
                showSearch
                placeholder="Select Emails"
                onChange={(e) => {
                  console.log("e", e);
                  setMailboxComposeData((previousData) => {
                    return {
                      ...previousData,
                      cc: e,
                    };
                  });
                }}
                onSearch={(e) => {
                  dispatch({
                    type: "FETCH_AVAILABLE_EMAILS_REQUEST",
                    payload: {
                      keyword: e,
                    },
                  });
                  console.log("On search", e);
                }}
                options={store.searchedEmailState?.searchedAvialbleEmails?.data?.map(
                  (dataObj, index) => {
                    return {
                      value: dataObj.email,
                      label: (
                        <div className="flexRowSpaceBetween">
                          <span>{dataObj.email}</span>
                          <Tag
                            color="#2db7f5"
                            style={{ borderRadius: "10px", margin: "3px" }}
                          >
                            {dataObj.sourceType}
                          </Tag>
                        </div>
                      ),
                    };
                  }
                )}
              />
            </Form.Item>
            <Form.Item label="BCC" name={"bcc"} style={{ width: "100%" }}>
              <Select
                mode="tags"
                value={mailboxComposeData.toEmail}
                labelInValue
                showSearch
                placeholder="Select Emails"
                onChange={(e) => {
                  console.log("e", e);
                  setMailboxComposeData((previousData) => {
                    return {
                      ...previousData,
                      bcc: e,
                    };
                  });
                }}
                onSearch={(e) => {
                  dispatch({
                    type: "FETCH_AVAILABLE_EMAILS_REQUEST",
                    payload: {
                      keyword: e,
                    },
                  });
                  console.log("On search", e);
                }}
                options={store.searchedEmailState?.searchedAvialbleEmails?.data?.map(
                  (dataObj, index) => {
                    return {
                      value: dataObj.email,
                      label: (
                        <div className="flexRowSpaceBetween">
                          <span>{dataObj.email}</span>
                          <Tag
                            color="#2db7f5"
                            style={{ borderRadius: "10px", margin: "3px" }}
                          >
                            {dataObj.sourceType}
                          </Tag>
                        </div>
                      ),
                    };
                  }
                )}
              />
            </Form.Item>
          </Panel>
        </Collapse>
        <Form.Item
          label="Choose a Template"
          name={"templates"}
          style={{ width: "100%" }}
        >
          <Select
            onChange={(e) => {
              setTemplateType(e);
            }}
          >
            <Option value="Select">Select</Option>
            <Option value="LEAD">Lead</Option>
            <Option value="CASE">Case</Option>
            <Option value="APPOINTMENT">Appointment</Option>
            <Option value="CLIENT">Client</Option>
            <Option value="QUOTATION">Quotation</Option>
            <Option value="SERVICE">Service</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Subject"
          name={"subjectContent"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a subject!",
            },
          ]}
        >
          <Input
            placeholder=""
            value={mailboxComposeData.subjectContent}
            onChange={(e) => {
              setMailboxComposeData((previousData) => {
                return {
                  ...previousData,
                  subjectContent: e.target.value,
                };
              });
            }}
          />
        </Form.Item>
        {/* <div>
          <select
            id="selectField"
            onChange={(e) => {
              setselectedText(e.target.value);
            }}
          >
            <option value="[sender_name]">Sender Name</option>
            <option value="[Receiver_name]">Receiver Name</option>
          </select>{" "}
          <button
            onClick={(e) => {
              e.preventDefault();
              const cursorPosition = quillRef.current
                .getEditor()
                .getSelection()?.index;

              let currentIndex = 0;
              let actualIndex = 0;
              let insideTag = false;
              const bodyContent = form.getFieldValue("bodyContent");

              const result = Array.from(bodyContent).map(
                (char, index, array) => {
                  if (char === "<" || char === "") {
                    index !== 0 && actualIndex++;
                    insideTag = true;
                  } else if (char === ">") {
                    actualIndex++;
                    insideTag = false;
                  } else {
                    if (!insideTag) {
                      currentIndex++;
                      actualIndex++;
                    } else if (!insideTag && array[index - 1] === "") {
                      insideTag = false;
                    }
                    if (currentIndex === cursorPosition) {
                      return char;
                    }
                  }
                  return null;
                }
              );

              const newText =
                bodyContent.slice(0, actualIndex) +
                selectedText +
                bodyContent.slice(actualIndex);
              form.setFieldValue("bodyContent", newText);
            }}
          >
            Insert Field
          </button>
        </div> */}
        <Form.Item
          label={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "1rem",
                width: "100%",
              }}
            >
              <span>Message</span>
            </div>
          }
          name={"bodyContent"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a message!",
            },
          ]}
        >
          <ReactQuill
            ref={quillRef}
            style={{
              background: "white",
              height: "200px",
              marginBottom: "2.5rem",
            }}
            modules={{
              toolbar: {
                container: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ align: [] }],
                  ["link", "image"],
                  ["clean"],
                  [{ color: [] }],
                ],
              },
            }}
            theme="snow"
            preserveWhitespace={true}
            // value={mailboxComposeData.bodyContent}
            // onChange={(value, delta, source, editor) => {
            //   const cursorPosition = editor.getSelection().index;
            //   setMailboxComposeData((previousData) => {
            //     return {
            //       ...previousData,
            //       bodyContent: value,
            //     };
            //   });
            // }}
          />
        </Form.Item>
        {/* <Form.Item label="Add New Attachment">
          <Input placeholder="Upload New Attachment" type="file" />
        </Form.Item> */}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Send
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default SendComposedMail;

{
  /* <Input
            type="email"
            placeholder=""
            value={mailboxComposeData.toEmail}
            onChange={(e) => {
              setMailboxComposeData((previousData) => {
                return {
                  ...previousData,
                  toEmail: e.target.value,
                };
              });
            }}
          /> */
}
