import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import AddEmployee from "../EmployeeManager/AddEmployee";
import AddHeadAgent from "../HeadAgent/AddHeadAgent";
const { Option } = Select;

const AssignAgents = ({
  record,
  isAssignAgentsModalVisible,
  setIsAssignAgentsModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
    };
  });
  const [assignAgentsData, setAssignAgent] = useState({});
  const superAgentIds = () => {
    let ids = [];
    if (record?.superAgent !== null) {
      ids = record?.superAgent?.map((item) => {
        if (item.id !== null) {
          return item.id;
        } else {
          return;
        }
      });
    }
    return ids;
  };
  const consultantsIds = () => {
    let ids = [];
    if (record?.consultant !== null) {
      ids = record?.consultant?.map((item) => {
        if (item.id !== null) {
          return item.id;
        } else {
          return;
        }
      });
    }
    return ids;
  };
  useEffect(() => {
    dispatch({
      type: "FETCH_LEADS_DROPDOWN_REQUEST",
      payload: {
        branchId: localStorage.getItem("branchId"),
      },
    });
    dispatch({
      type: "FETCH_CASES_DROPDOWN_REQUEST",
      payload: {
        branchId: localStorage.getItem("branchId"),
      },
    });
    dispatch({ type: "FETCH_ACTIVE_CASE_PACKAGE_REQUEST" });
  }, []);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isAddConsultantModalVisible, setIsAddConsultantModalVisible] =
    useState(false);
  const [isAddHeadAgentModalVisible, setIsAddHeadAgentModalVisible] =
    useState(false);
  const AssignAgentsSubmitHandler = (value) => {
    console.log("from  assign tasks", value);
    const formData = {
      consultantId: value.consultantId === undefined ? [] : value.consultantId,
      superAgentId: value.superAgentId === undefined ? [] : value.superAgentId,
    };
    dispatch({
      type: "ASSIGN_AGENTS_REQUEST",
      payload: { id: record.id, formData },
    });
    // form.resetFields();
    setIsAssignAgentsModalVisible(false);
  };
  console.log("from  assign tasks", record);
  console.log("from  consultantsIds", consultantsIds());
  console.log("from  superAgentIds", superAgentIds());

  return (
    <Drawer
      title="Assign Agents"
      open={isAssignAgentsModalVisible}
      onClose={() => {
        setIsAssignAgentsModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={AssignAgentsSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["consultantId"],
            value: consultantsIds(),
          },
          {
            name: ["superAgentId"],
            value: superAgentIds(),
          },
        ]}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ gap: "0.3rem", width: "100%" }}
        >
          <Form.Item
            name={"consultantId"}
            label="Assign Consultants"
            style={{ width: "100%" }}
          >
            <Checkbox.Group
              // value={[...consultantsIds()]}

              onChange={(e) => {
                console.log("from consultant group", e);
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",

                width: "100%",
              }}
            >
              {store.caseState?.caseDropdown?.consultant?.map((dataObj) => {
                return (
                  <Checkbox
                    key={dataObj.id}
                    value={dataObj.id}
                    style={{ width: "30%", margin: 0 }}
                  >
                    {dataObj.emailId}
                  </Checkbox>
                );
              })}
            </Checkbox.Group>
          </Form.Item>
          <a
            className="plusButton"
            onClick={() => {
              setIsAddConsultantModalVisible(true);
            }}
          >
            <AiOutlinePlus className="iconColor" />
          </a>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ gap: "0.3rem", width: "100%" }}
        >
          <Form.Item
            label="Assign Super Agent"
            name={"superAgentId"}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select super agent!",
            //   },
            // ]}
          >
            <Checkbox.Group
              // value={[...superAgentIds()]}
              onChange={(e) => {
                console.log("from visa cases group", e);
              }}
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {store.caseState?.caseDropdown?.headAgent?.map((dataObj) => {
                return (
                  <Checkbox
                    key={dataObj.id}
                    value={dataObj.id}
                    style={{
                      width: "30%",
                      margin: "0",
                    }}
                  >
                    {dataObj.name}
                  </Checkbox>
                );
              })}
            </Checkbox.Group>
          </Form.Item>
          <a
            className="plusButton"
            onClick={() => {
              setIsAddHeadAgentModalVisible(true);
            }}
          >
            <AiOutlinePlus className="iconColor" />
          </a>
        </div>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Assign Agents
          </Button>
        </Form.Item>
      </Form>
      <AddEmployee
        isAddEmployeeModalVisible={isAddConsultantModalVisible}
        setIsAddEmployeeModalVisible={setIsAddConsultantModalVisible}
      />
      <AddHeadAgent
        isAddHeadAgentModalVisible={isAddHeadAgentModalVisible}
        setIsAddHeadAgentModalVisible={setIsAddHeadAgentModalVisible}
      />
    </Drawer>
  );
};

export default AssignAgents;
