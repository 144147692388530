import React, { useEffect, useMemo } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import moment from "moment";
import AddCasePackage from "../AddCasePackage";
import AddHeadAgent from "../../HeadAgent/AddHeadAgent";
import AddEmployee from "../../EmployeeManager/AddEmployee";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import CaseSummary from "./CaseSummary";
import AddSubAgent from "../../SubAgent/AddSubAgent";
const { Option } = Select;
const { TextArea } = Input;
const { Paragraph } = Typography;

const AdditionalInformation = ({
  visaCaseData,
  setVisaCaseData,
  setCurrent,
  current,
}) => {
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
      documentChecklistState: state?.DocumentCheckListReducer,
    };
  });

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [isAddHeadAgentModalVisible, setIsAddHeadAgentModalVisible] =
    useState(false);
  const [isAddConsultantModalVisible, setIsAddConsultantModalVisible] =
    useState(false);
  const [isAddPackageModalVisible, setIsAddPackageModalVisible] =
    useState(false);
  const [isAddSubAgentModalVisible, setIsAddSubAgentModalVisible] =
    useState(false);

  useEffect(() => {
    handleGetDocumentChecklist();
  }, []);

  const handleGetDocumentChecklist = () => {
    dispatch({
      type: "FETCH_DOCUMENT_CHECKLIST_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  const addVisaCasesSubmitHandler = () => {
    setCurrent(4);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexFlow: "wrap",
          gap: "1rem",
          alignItems: "stretch",
        }}
      >
        <div style={{ width: "100%", display: "flex", flex: 1 }}>
          <Form
            layout="vertical"
            onFinish={addVisaCasesSubmitHandler}
            colon={true}
            form={form}
            className="drawerStyle"
            initialValues={{
              workflowDocumentChecklist: visaCaseData?.documentCheckList,
              // workflowDocumentChecklist: [
              //   ...visaCaseData?.workflowDocumentCheckList?.map((dataObj) => {
              //     return dataObj.documentCheckListId;
              //   }),
              //   ...visaCaseData?.documentCheckList,

              //   coursesDocumentChecklist:
              //     visaCaseData.currentStatus === "OFFSHORE"
              //       ? visaCaseData?.course?.offShoreCheckList?.map((dataObj) => {
              //           return dataObj.documentCheckListId;
              //         })
              //       : [
              //           visaCaseData?.course?.onShoreCheckList?.map((dataObj) => {
              //             return dataObj.documentCheckListId;
              //           }),
              //         ],
              coursesDocumentChecklist: visaCaseData?.coursesChecklist,
              // subAgentId:visaCaseData?.subAgentId
            }}
            fields={[
              {
                name: ["priorNote"],
                value: visaCaseData.priorNote,
              },
              {
                name: ["deadlineDate"],
                value:
                  visaCaseData.deadlineDate !== null &&
                  visaCaseData.deadlineDate !== ""
                    ? moment.utc(visaCaseData.deadlineDate).local()
                    : null,
              },
              {
                name: ["consultantId"],
                value: visaCaseData.consultantId,
              },
              {
                name: ["subAgentId"],
                value: visaCaseData.subAgentId,
              },
              {
                name: ["superAgentId"],
                value: visaCaseData.superAgentId,
              },
            ]}
          >
            <Form.Item
              label="Prior Note"
              name={"priorNote"}
              style={{ width: "100%" }}
            >
              <TextArea
                placeholder="Write something..."
                onChange={(e) => {
                  setVisaCaseData((previousData) => {
                    return {
                      ...previousData,
                      priorNote: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Deadline"
              name={"deadlineDate"}
              style={{ width: "100%" }}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                onChange={(e) => {
                  setVisaCaseData((previousData) => {
                    return {
                      ...previousData,
                      deadlineDate: e,
                    };
                  });
                }}
              />
            </Form.Item>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                name={"consultantId"}
                label="Assign Consultants"
                style={{ width: "100%" }}
              >
                <Checkbox.Group
                  onChange={(e) => {
                    setVisaCaseData((previousData) => {
                      return {
                        ...previousData,
                        consultantId: e,
                      };
                    });
                  }}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                  }}
                >
                  {store.caseState?.caseDropdown?.consultant?.map((dataObj) => {
                    return (
                      <div>
                        <Checkbox
                          className="checkboxEllipsis"
                          key={dataObj.id}
                          value={dataObj.id}
                          style={{
                            width: "100%",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          <Tooltip title={dataObj.emailId}>
                            {dataObj.emailId}
                          </Tooltip>
                        </Checkbox>
                      </div>
                    );
                  })}
                </Checkbox.Group>
              </Form.Item>
              <a
                className="plusButton"
                onClick={() => {
                  setIsAddConsultantModalVisible(true);
                }}
              >
                <AiOutlinePlus className="iconColor" />
              </a>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Assign Sub Agent"
                  name={"subAgentId"}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Select
                    placeholder="Select Sub Agent"
                    onChange={(value) => {
                      console.log("testing changes", value);
                      setVisaCaseData((prev) => ({
                        ...prev,
                        subAgentId: value,
                      }));
                    }}
                  >
                    {store.caseState?.caseDropdown?.subAgent?.map((dataObj) => (
                      <Option key={dataObj.id} value={dataObj.id}>
                        {dataObj.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddSubAgentModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Assign Super Agent"
                  name={"superAgentId"}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Select
                    placeholder="Select Super Agent"
                    onChange={(value) => {
                      setVisaCaseData((prev) => ({
                        ...prev,
                        superAgentId: value,
                      }));
                    }}
                  >
                    {store.caseState?.caseDropdown?.headAgent?.map(
                      (dataObj) => (
                        <Option key={dataObj.id} value={dataObj.id}>
                          {dataObj.name}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddHeadAgentModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                label="Workflow Document Checklist"
                name={"workflowDocumentChecklist"}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Checkbox.Group
                  onChange={(e) => {
                    setVisaCaseData((previousData) => {
                      return {
                        ...previousData,
                        documentCheckList: e,
                      };
                    });
                  }}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  {store?.documentChecklistState?.documentCheclistData?.data?.map(
                    (dataObj) => {
                      return (
                        <Checkbox
                          key={dataObj.id}
                          value={dataObj.id}
                          style={{ margin: 0 }}
                        >
                          {dataObj.name}
                        </Checkbox>
                      );
                    }
                  )}
                </Checkbox.Group>
              </Form.Item>
              {visaCaseData.visaType === "EDUCATION" ? (
                <>
                  <Form.Item
                    label={`Courses Document Checklist ${
                      visaCaseData?.currentStatus === "OFFSHORE"
                        ? "(Offshore)"
                        : visaCaseData?.currentStatus === "ONSHORE"
                        ? "(Onshore)"
                        : ""
                    }`}
                    name={"coursesDocumentChecklist"}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {visaCaseData.currentStatus === "OFFSHORE" ? (
                      <Checkbox.Group
                        onChange={(e) => {
                          setVisaCaseData((previousData) => {
                            return {
                              ...previousData,
                              coursesChecklist: e,
                            };
                          });
                        }}
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        {store?.documentChecklistState?.documentCheclistData?.data?.map(
                          (dataObj) => {
                            return (
                              <Checkbox
                                key={dataObj.id}
                                value={dataObj.id}
                                style={{ margin: 0 }}
                              >
                                {dataObj.name}
                              </Checkbox>
                            );
                          }
                        )}
                      </Checkbox.Group>
                    ) : visaCaseData.currentStatus === "ONSHORE" ? (
                      <Checkbox.Group
                        onChange={(e) => {
                          setVisaCaseData((previousData) => {
                            return {
                              ...previousData,
                              coursesChecklist: e,
                            };
                          });
                        }}
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        {store?.documentChecklistState?.documentCheclistData?.data?.map(
                          (dataObj) => {
                            return (
                              <Checkbox
                                key={dataObj.id}
                                value={dataObj.id}
                                style={{ margin: 0 }}
                              >
                                {dataObj.name}
                              </Checkbox>
                            );
                          }
                        )}
                      </Checkbox.Group>
                    ) : null}
                  </Form.Item>
                </>
              ) : null}
            </div>

            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              {current === 3 && (
                <Form.Item>
                  <Button
                    onClick={() => {
                      setCurrent(2);
                    }}
                  >
                    Back
                  </Button>
                </Form.Item>
              )}
              <Form.Item name="caseSubmit">
                <Button
                  name="caseSubmit"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Next
                </Button>
              </Form.Item>
            </div>
          </Form>
          {isAddConsultantModalVisible && (
            <AddEmployee
              isAddEmployeeModalVisible={isAddConsultantModalVisible}
              setIsAddEmployeeModalVisible={setIsAddConsultantModalVisible}
            />
          )}
          {isAddHeadAgentModalVisible && (
            <AddHeadAgent
              isAddHeadAgentModalVisible={isAddHeadAgentModalVisible}
              setIsAddHeadAgentModalVisible={setIsAddHeadAgentModalVisible}
            />
          )}
          {isAddSubAgentModalVisible && (
            <AddSubAgent
              isAddSubAgentModalVisible={isAddSubAgentModalVisible}
              setIsAddSubAgentModalVisible={setIsAddSubAgentModalVisible}
            />
          )}
          {isAddPackageModalVisible && (
            <AddCasePackage
              from={"CASE"}
              isAddPackageModalVisible={isAddPackageModalVisible}
              setIsAddPackageModalVisible={setIsAddPackageModalVisible}
            />
          )}
        </div>
        <div
          style={{
            width: "30%",
          }}
        >
          <CaseSummary
            current={current}
            documentChecklist={
              store?.documentChecklistState?.documentCheclistData?.data
            }
            visaCaseData={visaCaseData}
          />
        </div>
      </div>
    </>
  );
};

export default AdditionalInformation;
