import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
const { Option } = Select;

const AddMigrationVisa = ({
  country,
  countryList,
  isAddMigrationVisasModalVisible,
  setIsAddMigrationVisasModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedDocument, setSelectedDocument] = useState(null);

  const addEducationVisaSubmitHandler = (values) => {
    // const formData = {
    //   subClass: values.subClass,
    //   visaCategory: "MIGRATION",
    //   country: values.country,
    // };
    const formData = new FormData();
    selectedDocument && formData.append("document", selectedDocument);
    formData.append("subClass", values?.subClass);
    formData.append("country", values?.country);
    formData.append("description", values?.description);
    formData.append("visaCategory", "MIGRATION");
    dispatch({
      type: "ADD_MIGRATION_VISA_REQUEST",
      payload: { branchId: localStorage.getItem("branchId"), formData },
      isSuccessFn: true,
      successFunction: () => {
        form.resetFields();
        setIsAddMigrationVisasModalVisible(false);
      },
    });
  };
  return (
    <Modal
      title="Add Migration Visa"
      open={isAddMigrationVisasModalVisible}
      onCancel={() => {
        setIsAddMigrationVisasModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addEducationVisaSubmitHandler}
        colon={true}
        form={form}
        initialValues={{ country: country }}
      >
        <Form.Item
          label="Country"
          name={"country"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select country name!",
            },
          ]}
        >
          <Select placeholder="Country name">
            {countryList?.map((country) => (
              <Select.Option value={country.countryName}>
                {country.countryName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Visa class name"
          name={"subClass"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter visa class name!",
            },
          ]}
        >
          <Input placeholder="Visa class name" />
        </Form.Item>

        <Form.Item
          label="Description"
          name={"description"}
          style={{ width: "100%" }}
        >
          <ReactQuill
            style={{
              background: "white",
              marginBottom: "2rem",
              height: "150px",
            }}
            modules={{
              toolbar: {
                container: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ align: [] }],
                  ["link", "image"],
                  ["clean"],
                  [{ color: [] }],
                ],
              },
            }}
            theme="snow"
          />
        </Form.Item>
        <Form.Item
          label="Add New Attachment"
          name="document"
          style={{ paddingTop: "1rem", display: "none" }}
        >
          <Input
            onChange={(e) => {
              setSelectedDocument(e.target.files[0]);
            }}
            placeholder="Upload New Attachment"
            type="file"
          />
        </Form.Item>

        <Form.Item style={{ paddingTop: "2rem" }}>
          <Button block type="primary" htmlType="submit">
            Add Migration Visa
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddMigrationVisa;
