import React, { useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

const AddDegree = ({
  isAddDegreeModalVisible,
  setIsAddDegreeModalVisible,
  fromCases,
}) => {
  const store = useSelector((state) => {
    return {
      caseState: state.VisasReducer,
    };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_CASES_DROPDOWN_REQUEST",
      branchId: localStorage.getItem("branchId"),
    });
  }, []);
  const [form] = Form.useForm();
  const successFunction = (response) => {
    if (fromCases) {
      dispatch({
        type: "FETCH_DEGREE_BY_INSTITUTE_REQUEST",
        payload: {
          institute: fromCases,
        },
      });
    }
  };
  const addDegreeSubmitHandler = (value) => {
    const formData = {
      ...value,
      branchId: localStorage.getItem("branchId"),
    };
    dispatch({
      type: "ADD_DEGREE_REQUEST",
      payload: formData,
      // payload2: successFunction,
    });
    // form.resetFields();
    setIsAddDegreeModalVisible(false);
  };
  return (
    <Modal
      title="Add Degree"
      open={isAddDegreeModalVisible}
      onCancel={() => {
        setIsAddDegreeModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addDegreeSubmitHandler}
        colon={true}
        form={form}
      >
        {/* <Form.Item
          label="Institute"
          name={"institute"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select an institute!",
            },
          ]}
        >
          <Select>
            {store.caseState.caseDropdown?.institute?.map((dataObj) => {
              return <Option key={dataObj.id}>{dataObj.name}</Option>;
            })}
          </Select>
        </Form.Item> */}
        <Form.Item
          label="Degree Name"
          name={"degreeName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter degree name!",
            },
          ]}
        >
          <Input placeholder="Degree Name" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Degree
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddDegree;
