import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
  QRCode,
} from "antd";
import campaign from "./../../Assets/campaign.png";
import activeCampaign from "./../../Assets/activeCampaign.png";
import { MdLink, MdMoreTime, MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import { BiBlock } from "react-icons/bi";
import {
  AiOutlineLink,
  AiOutlinePoweroff,
  AiOutlineQrcode,
} from "react-icons/ai";
import AddCampaign from "../Contacts/AddCampaign";
import UpdateCampaign from "./UpdateCampaign";
import QRcode from "./QRcode";
import UrlLink from "./UrlLink";
import AddAppointmentForm from "./AddAppointmentForm";
import AppointmentFormUrlLink from "./AppointmentFormUrlLink";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Campaign",
    dataIndex: "campaignName",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
    fixed: "right",
  },
];

const Campaign = () => {
  const store = useSelector((state) => {
    return {
      campaignState: state.CampaignReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });

  const [isAddCampaignModalVisible, setIsAddCampaignModalVisible] =
    useState(false);
  const [isUpdateCampaignModalVisible, setIsUpdateCampaignModalVisible] =
    useState(false);
  const [isQRcodeModalVisible, setIsQRcodeModalVisible] = useState(false);
  const [isUrlLinkModalVisible, setIsUrlLinkModalVisible] = useState(false);
  const [
    isAddAppointmentFormModalVisible,
    setIsAddAppointmentFormModalVisible,
  ] = useState(false);
  const [
    isAppointmetnFormUrlLinkModalVisible,
    setIsAppointmetnFormUrlLinkModalVisible,
  ] = useState(false);
  const [record, setRecord] = useState({});
  const [campaignType, setCampaignType] = useState("campaign");

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  useEffect(() => {
    if (campaignType === "activeCampaign") {
      dispatch({
        type: "FETCH_ACTIVE_CAMPAIGN_REQUEST",
        payload: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else {
      dispatch({
        type: "FETCH_CAMPAIGN_REQUEST",
        payload: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    }
  }, [campaignType, page, pageSize, store.branchState?.branchRecord]);
  let data = [];

  if (campaignType === "campaign") {
    data = store?.campaignState.campaign?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: dataObj.id,
        campaignId: dataObj.id,
        campaignName: dataObj.campaignName,

        status: dataObj.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {dataObj.isActive &&
            store.profileState?.profile?.department?.rolesAndPermissionList?.CAMPAIGN?.includes(
              "GENERATE_APPOINTMENT_URL"
            ) ? (
              <Tooltip title="Add Appointment form fields">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAddAppointmentFormModalVisible(true);
                    }}
                  >
                    <MdMoreTime />
                  </div>
                </a>
              </Tooltip>
            ) : null}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CAMPAIGN?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update campaign">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateCampaignModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}

            {dataObj.isActive ? (
              <>
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CAMPAIGN?.includes(
                  "CHANGE_STATUS"
                ) && (
                  <Tooltip title="Deactivate campaign">
                    <a style={{ color: "red" }}>
                      <div
                        className="bordered"
                        onClick={() => {
                          const actionStatus = !dataObj.isActive
                            ? "active"
                            : "inactive";
                          dispatch({
                            type: "TOGGLE_CAMPAIGN_STATUS_REQUEST",
                            payload: {
                              campaignId: dataObj.id,
                              status: actionStatus,
                            },
                          });
                        }}
                      >
                        <BiBlock />
                      </div>
                    </a>
                  </Tooltip>
                )}
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CAMPAIGN?.includes(
                  "GENERATE_LEAD_URL"
                ) && (
                  <Tooltip title="QR Code">
                    <a>
                      <div
                        className="bordered"
                        onClick={() => {
                          setRecord(dataObj);
                          setIsQRcodeModalVisible(true);
                        }}
                      >
                        <AiOutlineQrcode />
                      </div>
                    </a>
                  </Tooltip>
                )}
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CAMPAIGN?.includes(
                  "GENERATE_LEAD_URL"
                ) && (
                  <Tooltip title="Lead Form URL Link">
                    <a>
                      <div
                        className="bordered"
                        onClick={() => {
                          setRecord(dataObj);
                          setIsUrlLinkModalVisible(true);
                        }}
                      >
                        <AiOutlineLink />
                      </div>
                    </a>
                  </Tooltip>
                )}
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CAMPAIGN?.includes(
                  "GENERATE_APPOINTMENT_URL"
                ) && (
                  <Tooltip title="Appointment Form URL Link">
                    <a>
                      <div
                        className="bordered"
                        onClick={() => {
                          setRecord(dataObj);
                          setIsAppointmetnFormUrlLinkModalVisible(true);
                        }}
                      >
                        <MdLink />
                      </div>
                    </a>
                  </Tooltip>
                )}
              </>
            ) : store.profileState?.profile?.department?.rolesAndPermissionList?.CAMPAIGN?.includes(
                "CHANGE_STATUS"
              ) ? (
              <Tooltip title="Activate campaign">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus = !dataObj.isActive
                        ? "active"
                        : "inactive";
                      dispatch({
                        type: "TOGGLE_CAMPAIGN_STATUS_REQUEST",
                        payload: {
                          campaignId: dataObj.id,
                          status: actionStatus,
                        },
                      });
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            ) : null}
          </Space>
        ),
      };
    });
  } else {
    data = store?.campaignState.activeCampaign?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        key: dataObj.id,
        id: dataObj.id,
        campaignName: dataObj.campaignName,

        status: dataObj.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {dataObj.isActive &&
            store.profileState?.profile?.department?.rolesAndPermissionList?.CAMPAIGN?.includes(
              "GENERATE_APPOINTMENT_CONSULTANT_URL"
            ) ? (
              <Tooltip title="Add Appointment form fields">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAddAppointmentFormModalVisible(true);
                    }}
                  >
                    <MdMoreTime />
                  </div>
                </a>
              </Tooltip>
            ) : null}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CAMPAIGN?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update campaign">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateCampaignModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}

            {store.profileState?.profile?.department?.rolesAndPermissionList?.CAMPAIGN?.includes(
              "CHANGE_STATUS"
            ) && (
              <Tooltip title="Deactivate campaign">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus = !dataObj.isActive
                        ? "active"
                        : "inactive";
                      dispatch({
                        type: "TOGGLE_CAMPAIGN_STATUS_REQUEST",
                        payload: {
                          campaignId: dataObj.id,
                          status: actionStatus,
                        },
                      });
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            )}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CAMPAIGN?.includes(
              "GENERATE_LEAD_URL"
            ) && (
              <Tooltip title="QR Code">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsQRcodeModalVisible(true);
                    }}
                  >
                    <AiOutlineQrcode />
                  </div>
                </a>
              </Tooltip>
            )}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CAMPAIGN?.includes(
              "GENERATE_LEAD_URL"
            ) && (
              <Tooltip title="Lead Form URL Link">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsUrlLinkModalVisible(true);
                    }}
                  >
                    <AiOutlineLink />
                  </div>
                </a>
              </Tooltip>
            )}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CAMPAIGN?.includes(
              "GENERATE_APPOINTMENT_URL"
            ) && (
              <Tooltip title="Appointment Form URL Link">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAppointmetnFormUrlLinkModalVisible(true);
                    }}
                  >
                    <MdLink />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
  }
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div style={{ display: "grid", gridTemplateColumns: "2fr 3fr" }}>
          {store.profileState?.profile?.department?.rolesAndPermissionList?.CAMPAIGN?.includes(
            "ADD"
          ) && (
            <button
              className="button"
              style={{ width: "fit-content" }}
              onClick={() => {
                setIsAddCampaignModalVisible(true);
              }}
            >
              <span>Add Campaign</span>
            </button>
          )}
          <Segmented
            style={{ width: "fit-content" }}
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={campaign} size={20} shape="square" />
                    <div>All Campaign</div>
                  </div>
                ),
                value: "campaign",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={activeCampaign} size={20} shape="square" />
                    <div>Active Campaign</div>
                  </div>
                ),
                value: "activeCampaign",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setCampaignType(value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "4rem",
          }}
        >
          <Table
            bordered
            columns={columns}
            dataSource={data}
            pagination={false}
          />

          {campaignType === "campaign" &&
            store?.campaignState.campaign?.totalData && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                current={page}
                defaultPageSize={pageSize}
                onChange={onShowSizeChange}
                total={store?.campaignState.campaign?.totalPage * 10}
              />
            )}
          {campaignType === "activeCampaign" &&
            store?.campaignState.activeCampaign?.totalData && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                current={page}
                defaultPageSize={pageSize}
                onChange={onShowSizeChange}
                total={store?.campaignState.activeCampaign?.totalPage * 10}
              />
            )}
        </div>
      </div>
      {isAddCampaignModalVisible && (
        <AddCampaign
          isAddCampaignModalVisible={isAddCampaignModalVisible}
          setIsAddCampaignModalVisible={setIsAddCampaignModalVisible}
        />
      )}
      {isUpdateCampaignModalVisible && (
        <UpdateCampaign
          record={record}
          isUpdateCampaignModalVisible={isUpdateCampaignModalVisible}
          setIsUpdateCampaignModalVisible={setIsUpdateCampaignModalVisible}
        />
      )}
      {isQRcodeModalVisible && (
        <QRcode
          record={record}
          isQRcodeModalVisible={isQRcodeModalVisible}
          setIsQRcodeModalVisible={setIsQRcodeModalVisible}
        />
      )}
      {isUrlLinkModalVisible && (
        <UrlLink
          record={record}
          isUrlLinkModalVisible={isUrlLinkModalVisible}
          setIsUrlLinkModalVisible={setIsUrlLinkModalVisible}
        />
      )}
      {isAppointmetnFormUrlLinkModalVisible && (
        <AppointmentFormUrlLink
          record={record}
          isUrlLinkModalVisible={isAppointmetnFormUrlLinkModalVisible}
          setIsUrlLinkModalVisible={setIsAppointmetnFormUrlLinkModalVisible}
        />
      )}
      {isAddAppointmentFormModalVisible && (
        <AddAppointmentForm
          record={record}
          isAddAppointmentFormModalVisible={isAddAppointmentFormModalVisible}
          setIsAddAppointmentFormModalVisible={
            setIsAddAppointmentFormModalVisible
          }
        />
      )}
    </SiderDemo>
  );
};

export default Campaign;
