import { Carousel } from "antd";
import React from "react";
import carousel1 from "./../../Assets/carousel/carousel1.png";
import carousel2 from "./../../Assets/carousel/carousel2.png";
import carousel3 from "./../../Assets/carousel/carousel3.png";
// import carousel4 from "./../../Assets/carousel/carousel4.png";

const CarouselComponent = () => {
  const contentStyle = {
    height: "100vh",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  return (
    <Carousel
      autoplay
      style={{
        height: "100vh",
      }}
    >
      <div className="corouselImageBackground">
        {/* <h3 style={contentStyle}>1</h3> */}
        <img
          className="imageClass"
          style={{
            color: "#fff",
            lineHeight: "160px",
            textAlign: "center",
            background: "#364d79",
          }}
          src={carousel1}
          alt="Carousel1"
        />
        <h1
          style={{
            color: "#fff",
            lineHeight: "160px",
            textAlign: "center",
          }}
        >
          Manage Data
        </h1>
      </div>
      <div className="corouselImageBackground">
        {/* <h3 style={contentStyle}>1</h3> */}
        <img
          className="imageClass"
          style={{
            color: "#fff",
            lineHeight: "160px",
            textAlign: "center",
            background: "#364d79",
          }}
          src={carousel2}
          alt="Carousel2"
        />
        <h1
          style={{
            color: "#fff",
            lineHeight: "160px",
            textAlign: "center",
          }}
        >
          Automation
        </h1>
      </div>
      <div className="corouselImageBackground">
        <img
          className="imageClass"
          style={{
            color: "#fff",
            lineHeight: "160px",
            textAlign: "center",
            background: "#364d79",
          }}
          src={carousel3}
          alt="Carousel3"
        />
        <h1
          style={{
            color: "#fff",
            lineHeight: "160px",
            textAlign: "center",
          }}
        >
          Reporting
        </h1>
      </div>
      {/* <div className="corouselImageBackground">
        <img
          style={{
            color: "#fff",
            lineHeight: "160px",
            textAlign: "center",
            background: "#364d79",
          }}
          src={carousel4}
          alt="Carousel4"
        />
        <h1
          style={{
            color: "#fff",
            lineHeight: "160px",
            textAlign: "center",
          }}
        >
          100% Secure
        </h1>
      </div> */}
    </Carousel>
  );
};

export default CarouselComponent;
{
  /* <>
<div>
        <img style={contentStyle} src={carousel1} alt="Carousel1" />
      </div>
      <div>
        <img style={contentStyle} src={carousel2} alt="Carousel2" />
      </div>
      <div>
        <img style={contentStyle} src={carousel3} alt="Carousel3" />
      </div>
      <div>
        <img style={contentStyle} src={carousel4} alt="Carousel4" />
      </div>

      </> */
}
