import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

export default function ChangePassword({ isModalVisible, setIsModalVisible }) {
  const dispatch = useDispatch();

  const handleSubmitNewPassword = (values) => {
    dispatch({
      type: "CHANGE_NEW_PASSWORD_REQUEST",
      payload: values,
      isSuccessFn: true,
      successFunction: (res) => {
        setIsModalVisible(false);
      },
    });
  };
  return (
    <Modal
      title="Change Password"
      open={isModalVisible}
      onCancel={() => {
        setIsModalVisible(false);
      }}
      footer={false}
    >
      <Form layout="vertical" onFinish={handleSubmitNewPassword}>
        <Form.Item
          label="Current Password"
          name="oldPassword"
          rules={[
            {
              required: true,
              message: "Old password is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="password"
          rules={[
            {
              required: true,
              message: "New password is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Confrim password is required",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    "New Password and confirm new password do not match!"
                  )
                );
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button.Group
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
            }}
          >
            <Button
              onClick={() => {
                setIsModalVisible(false);
              }}
              danger
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" outlined>
              Save
            </Button>
          </Button.Group>
        </div>
      </Form>
    </Modal>
  );
}
