import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const EmployeeConfirmation = ({
  record,
  message,
  isConfirmationModalVisible,
  setIsConfirmationModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const employeeConfirmationSubmitHandler = (value) => {
    const formData = {
      ...value,
    };
    record.isActive
      ? dispatch({
          type: "EMPLOYEE_DEACTIVATE_CONFIRMATION_REQUEST",
          payload: { id: record.id, formData },
        })
      : dispatch({
          type: "EMPLOYEE_ACTIVATE_CONFIRMATION_REQUEST",
          payload: { id: record.id, formData },
        });
    form.resetFields();
    setIsConfirmationModalVisible(false);
  };
  return (
    <Modal
      title={message ? message : "Confirmation Message"}
      open={isConfirmationModalVisible}
      onCancel={() => {
        setIsConfirmationModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={employeeConfirmationSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Confirmation Text"
          name={"confirmationText"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please confirm with a note!",
            },
          ]}
        >
          <Input placeholder="Confirmation text" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EmployeeConfirmation;
