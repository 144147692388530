import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
} from "antd";
import { useDispatch } from "react-redux";
const { Option } = Select;

const AddSubAgent = ({
  isAddSubAgentModalVisible,
  setIsAddSubAgentModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [agentType, setAgentType] = useState("individual");

  const addSubAgentSubmitHandler = (value) => {
    const formData = {
      ...value,
      branchId: localStorage.getItem("branchId"),
    };
    dispatch({ type: "ADD_SUBAGENT_REQUEST", payload: formData });
    form.resetFields();
    setIsAddSubAgentModalVisible(false);
  };
  return (
    <Modal
      title="Add Sub Agent"
      open={isAddSubAgentModalVisible}
      onCancel={() => {
        setIsAddSubAgentModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addSubAgentSubmitHandler}
        colon={true}
        form={form}
        initialValues={{
          agentType: agentType,
        }}
      >
        <Form.Item label="Agent Type" name="agentType">
          <Radio.Group
            onChange={(e) => {
              setAgentType(e.target.value);
            }}
          >
            <Radio value="individual">Individual</Radio>
            <Radio value="company">Company</Radio>
          </Radio.Group>
        </Form.Item>
        {agentType === "company" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Form.Item
              label="Company Name"
              name={"companyName"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter company name!",
                },
              ]}
            >
              <Input placeholder="Company Name" />
            </Form.Item>
            <Form.Item
              label="ABN/ACN Number"
              name={"abnNumber"}
              style={{ width: "100%" }}
            >
              <InputNumber
                type="number"
                style={{ width: "100%" }}
                placeholder="ABN/ACN Number"
              />
            </Form.Item>
          </div>
        )}
        <Divider style={{ marginTop: "0" }} />

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Full Name"
            name={"name"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter name!",
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name={"email"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter valid email!",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Phone Number"
            name={"phoneNumber"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter phone number",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Phone number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input type="number" placeholder="Phone Number" />
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name={"mobileNumber"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter mobile number",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Mobile number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input type="number" placeholder="mobile number" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Commission Share"
            name={"commissionShare"}
            dependencies={["isPercentage"]}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter commission share!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value <= 100 && getFieldValue("isPercentage")) {
                    return Promise.resolve();
                  } else if (!getFieldValue("isPercentage")) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Percentage must be less than 100!")
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input type="number" placeholder="commission share" />
          </Form.Item>
          <Form.Item
            label="Action"
            name={"status"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select status !",
              },
            ]}
          >
            <Select>
              <Option value="ACTIVE">Active</Option>
              <Option value="INACTIVE">Inactive</Option>
            </Select>
          </Form.Item>
        </div>
        <Form.Item name={"isPercentage"} valuePropName="checked">
          <Checkbox>Is Percentage</Checkbox>
        </Form.Item>
        <Divider />
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Country" name={"country"} style={{ width: "100%" }}>
            <Input placeholder="Country" />
          </Form.Item>
          <Form.Item label="State" name={"state"} style={{ width: "100%" }}>
            <Input placeholder="State" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="City" name={"city"} style={{ width: "100%" }}>
            <Input placeholder="City" />
          </Form.Item>
          <Form.Item
            label="Zip/Post code"
            name={"zipCode"}
            style={{ width: "100%" }}
          >
            <Input type="number" placeholder="Zip/Post code" />
          </Form.Item>
        </div>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Sub Agent
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddSubAgent;
