import React, { useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import AddVisaCases from "../Visas/AddVisas";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { AiOutlineEye } from "react-icons/ai";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import CollegeFinderInstituteDescription from "../CollegeFinder/CollegeFinderInstituteDescription";
import { formattedDate } from "../../HelperFunction";
import Search from "antd/lib/input/Search";
import { debounce } from "lodash";
const { Option } = Select;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Image",
    dataIndex: "image",
    align: "center",
  },
  {
    title: "Institute Name",
    dataIndex: "instituteName",
    align: "center",
  },
  {
    title: "Level of College",
    dataIndex: "levelOfCollege",
    align: "center",
  },
  {
    title: "Location",
    dataIndex: "location",
    align: "center",
  },

  {
    title: "Institute URL",
    dataIndex: "universityUrl",
    align: "center",
  },

  {
    title: "contact Person Name",
    dataIndex: "contactPersonName",
    align: "center",
  },
  {
    title: "Contact",
    dataIndex: "contact",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const CollegeFinderDrawer = ({
  reload,
  record,
  isCollegeFinderModalVisible,
  setIsCollegeFinderModalVisible,
}) => {
  const collegeFinderState = useSelector((state) => state.CollegeFinderReducer);

  const [searchValue, setSearchValue] = useState("");

  const [collegeRecord, setCollegeRecord] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("Select");
  const [selectedDegree, setSelectedDegree] = useState("Select");
  const [selectedCourse, setSelectedCourse] = useState("Select");
  const [selectedIntake, setSelectedIntake] = useState("Select");
  const [isInstituteDetailsModelVisible, setIsInstituteDetailsModelVisible] =
    useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "FETCH_ALL_COUNTRY_REQUEST" });
  }, []);
  const data = collegeFinderState.allCollege?.data?.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      image: dataObj.image ? (
        <Image
          src={dataObj.image}
          style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
        />
      ) : (
        "N/A"
      ),
      instituteName: (
        <Tooltip title="Institute Details">
          <a>
            <div
              className="bordered"
              onClick={() => {
                setCollegeRecord(dataObj);
                setIsInstituteDetailsModelVisible(true);
              }}
            >
              {dataObj.instituteName ?? "N/A"}
            </div>
          </a>
        </Tooltip>
      ),
      levelOfCollege: dataObj.levelOfCollege?.name ?? "N/A",
      location: dataObj.location ?? "N/A",
      universityUrl: dataObj.universityUrl ?? "N/A",
      contactPersonName: dataObj.contactPersonName ?? "N/A",
      contact: dataObj.contact ?? "N/A",
      actions: (
        <Space>
          <Tooltip title="Institute Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setCollegeRecord(dataObj);
                  setIsInstituteDetailsModelVisible(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });

  const [form] = Form.useForm();
  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);

  const SearchIntitituteList = (e) => {
    dispatch({
      type: "SEARCH_NEW_ACTIVE_INSTITUTES_REQUEST",
      payload: {
        page: 1,
        pageSize: 10,
        searchKey: { keyword: e },
        branchId: localStorage.getItem("branchId"),
      },
    });
  };

  const onSearch = debounce(SearchIntitituteList, 2000);

  return (
    <Drawer
      title="College Finder"
      open={isCollegeFinderModalVisible}
      onClose={() => {
        setIsCollegeFinderModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <div className="drawerStyle">
        <Form layout="vertical" onFinish={() => {}} colon={true} form={form}>
          <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
            <Form.Item
              label="Country"
              name={"country"}
              style={{ width: "100%" }}
              labelAlign="left"
            >
              <Select
                optionFilterProp="children"
                showSearch
                filterOption={(input, option) => {
                  return (option?.children?.props?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                <Option value="Select">
                  <div
                    onClick={() => {
                      setSelectedCountry("Select");
                    }}
                  >
                    Select
                  </div>
                </Option>
                {collegeFinderState.countryFilter?.map((dataObj, index) => {
                  return (
                    <Option key={index} value={dataObj.filterName}>
                      <div
                        onClick={() => {
                          setSelectedCountry(dataObj.filterName);
                          dispatch({
                            type: "FETCH_ALL_DEGREE_REQUEST",
                            payload: { param: dataObj.filterName },
                          });
                        }}
                      >
                        {dataObj.filterName}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Degree" name={"degree"} style={{ width: "100%" }}>
              <Select>
                <Option value="Select">
                  <div
                    onClick={() => {
                      setSelectedDegree("Select");
                    }}
                  >
                    Select
                  </div>
                </Option>
                {collegeFinderState.degreeFilter?.map((dataObj, index) => {
                  return (
                    <Option key={index} value={dataObj.filterName}>
                      <div
                        onClick={() => {
                          setSelectedDegree(dataObj.filterName);
                          dispatch({
                            type: "FETCH_ALL_COURSE_REQUEST",
                            payload: { param: dataObj.filterName },
                          });
                        }}
                      >
                        {dataObj.filterName}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Course" name={"course"} style={{ width: "100%" }}>
              <Select>
                <Option value="Select">
                  <div
                    onClick={() => {
                      setSelectedCourse("Select");
                    }}
                  >
                    Select
                  </div>
                </Option>
                {collegeFinderState.courseFilter?.map((dataObj, index) => {
                  return (
                    <Option key={index} value={dataObj.filterName}>
                      <div
                        onClick={() => {
                          setSelectedCourse(dataObj.filterName);
                          dispatch({
                            type: "FETCH_ALL_INTAKE_REQUEST",
                            payload: { param: dataObj.filterName },
                          });
                        }}
                      >
                        {dataObj.filterName}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Intake" name={"intake"} style={{ width: "100%" }}>
              <Select>
                <Option value="Select">
                  <div
                    onClick={() => {
                      setSelectedIntake("Select");
                    }}
                  >
                    Select
                  </div>
                </Option>
                {collegeFinderState.intakeFilter?.map((dataObj, index) => {
                  return (
                    <Option key={index} value={dataObj.filterName}>
                      <div
                        onClick={() => {
                          setSelectedIntake(dataObj.filterName);
                          dispatch({
                            type: "FETCH_ALL_COLLEGE_REQUEST",
                            payload: {
                              selectedCountry,
                              selectedDegree,
                              selectedCourse,
                              selectedIntake: dataObj.filterName,
                            },
                          });
                        }}
                      >
                        {dataObj.filterName}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <div>
              <Button
                type="primary"
                onClick={() => {
                  dispatch({
                    type: "FETCH_ALL_COLLEGE_REQUEST",
                    payload: {
                      selectedCountry,
                      selectedDegree,
                      selectedCourse,
                      selectedIntake,
                    },
                  });
                }}
              >
                FIND OPTIONS
              </Button>
            </div>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "3fr 2fr" }}>
            <span></span>
            <Input.Search
              className="search"
              style={{
                width: "100%",
                borderRadius: "10px ! important",
              }}
              placeholder="Search for institute"
              allowClear
              size="large"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                onSearch(e.target.value);
              }}
            />
          </div>
        </Form>
        <div style={{ marginTop: "2rem" }}>
          {collegeFinderState.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table bordered columns={columns} dataSource={data} />
          )}
        </div>
      </div>

      {isInstituteDetailsModelVisible && (
        <CollegeFinderInstituteDescription
          fromCounselling={true}
          recordData={record}
          record={collegeRecord}
          isInstituteDetailsModelVisible={isInstituteDetailsModelVisible}
          setIsInstituteDetailsModelVisible={setIsInstituteDetailsModelVisible}
          setIsStartCounsellingModalVisible={setIsCollegeFinderModalVisible}
        />
      )}
      {isAddVisasModalVisible && (
        <AddVisaCases
          navigateCaseList={true}
          fromCounselling={true}
          recordData={record}
          isAddVisaCasesModalVisible={isAddVisasModalVisible}
          setIsAddVisaCasesModalVisible={setIsAddVisasModalVisible}
          setIsStartCounsellingModalVisible={setIsCollegeFinderModalVisible}
        />
      )}
    </Drawer>
  );
};

export default CollegeFinderDrawer;
