import React, { useEffect, useMemo } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import moment from "moment";
import AddCasePackage from "../AddCasePackage";
import AddHeadAgent from "../../HeadAgent/AddHeadAgent";
import AddEmployee from "../../EmployeeManager/AddEmployee";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import CaseSummary from "./CaseSummary";
const { Option } = Select;
const { TextArea } = Input;
const { Paragraph } = Typography;

const VisaPackageInfo = ({
  visaCaseData,
  setVisaCaseData,
  packages,
  setPackages,
  current,
  setCurrent,
  addCaseSubmitHandler,
}) => {
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
      documentChecklistState: state?.DocumentCheckListReducer,
    };
  });

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [isAddHeadAgentModalVisible, setIsAddHeadAgentModalVisible] =
    useState(false);
  const [isAddConsultantModalVisible, setIsAddConsultantModalVisible] =
    useState(false);
  const [isAddPackageModalVisible, setIsAddPackageModalVisible] =
    useState(false);

  useEffect(() => {
    handleGetDocumentChecklist();
  }, []);

  const handleGetDocumentChecklist = () => {
    dispatch({
      type: "FETCH_DOCUMENT_CHECKLIST_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  useEffect(() => {
    dispatch({
      type: "FETCH_ACTIVE_CASE_PACKAGE_REQUEST",
      payload: {
        branchId:
          store.branchState?.branchRecord.id ??
          localStorage.getItem("branchId"),
      },
    });
    if (visaCaseData?.documentCheckList?.length === 0) {
      const workflowDocumentChecklistIdsArr =
        visaCaseData?.workflowDocumentCheckList?.map((dataObj) => {
          return dataObj.documentCheckListId;
        });
      setVisaCaseData((previousData) => {
        return {
          ...previousData,
          documentCheckList: workflowDocumentChecklistIdsArr,
        };
      });
    }
    if (
      visaCaseData?.visaType === "EDUCATION" &&
      visaCaseData?.coursesChecklist?.length === 0
    ) {
      if (visaCaseData?.currentStatus === "ONSHORE") {
        const courseOnshoreChecklist =
          visaCaseData?.course?.onShoreCheckList?.map((dataObj) => {
            return dataObj.documentCheckListId;
          });
        setVisaCaseData((previousData) => {
          return {
            ...previousData,
            coursesChecklist: courseOnshoreChecklist,
          };
        });
      }
      if (visaCaseData?.currentStatus === "OFFSHORE") {
        const courseOffshoreChecklist =
          visaCaseData?.course?.offShoreCheckList?.map((dataObj) => {
            return dataObj.documentCheckListId;
          });
        setVisaCaseData((previousData) => {
          return {
            ...previousData,
            coursesChecklist: courseOffshoreChecklist,
          };
        });
      }
    }
  }, []);

  const removePackagesInput = (index) => {
    const list = [...packages];
    list.splice(index, 1);
    setPackages(list);
  };

  const addPackagesInput = () => {
    setPackages([
      ...packages,
      {
        id: null,
        quantity: null,
        totalPrice: null,
        unitPrice: null,
        hasGst: null,
        gst: null,
        isGstFlat: null,
        isGstPercentage: null,
      },
    ]);
  };
  const caseIds = () => {
    let ids = [];
    if (packages[0].id !== null) {
      ids = packages.map((item) => {
        if (item.id !== null) {
          return item.id;
        } else {
          return;
        }
      });
    }
    return ids;
  };
  const handlePackageChange = (e, index) => {
    const list = [...packages];
    list[index] = e;
    setPackages(list);
  };
  const handleQuantityChange = (e, index) => {
    const list = [...packages];
    list[index].quantity = e.target.value;
    setPackages(list);
  };
  const handleUnitPriceChange = (e, index) => {
    const list = [...packages];
    list[index].unitPrice = e.target.value;
    setPackages(list);
  };
  const handleTotalPriceChange = (e, index) => {
    const list = [...packages];
    list[index].totalPrice = list[index].quantity * list[index].unitPrice;
    setPackages(list);
  };

  useMemo(() => {
    let sum = 0;
    let previousTotalfee = visaCaseData.fee === NaN ? 0 : visaCaseData.fee;

    packages.map((item, index) => {
      sum = sum + +item.totalPrice;
      return sum;
    });
    if (visaCaseData.isDiscountAllowed === true) {
      if (visaCaseData.packageDiscountType === "FLAT") {
        sum = sum - +visaCaseData.packageDiscount;
      } else if (visaCaseData.packageDiscountType === "PERCENTAGE") {
        sum = sum - 0.01 * +visaCaseData.packageDiscount * sum;
      } else {
        sum = sum;
      }
    } else {
      sum = sum;
    }
    setVisaCaseData((previousData) => {
      return {
        ...previousData,
        packageTotal: sum,
      };
    });
  }, [
    packages,
    visaCaseData.isDiscountAllowed,
    visaCaseData.packageDiscountType,
    visaCaseData.packageDiscount,
  ]);

  const addVisaCasesSubmitHandler = () => {
    setCurrent(3);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexFlow: "wrap",
          gap: "1rem",
          alignItems: "stretch",
        }}
      >
        <div style={{ width: "100%", display: "flex", flex: 1 }}>
          <Form
            layout="vertical"
            onFinish={addVisaCasesSubmitHandler}
            colon={true}
            form={form}
            className="drawerStyle"
            initialValues={{
              workflowDocumentChecklist:
                visaCaseData?.workflowDocumentCheckList?.map((dataObj) => {
                  return dataObj.documentCheckListId;
                }),
              coursesDocumentChecklist:
                visaCaseData.currentStatus === "OFFSHORE"
                  ? visaCaseData?.course?.offShoreCheckList?.map((dataObj) => {
                      return dataObj.documentCheckListId;
                    })
                  : visaCaseData?.course?.onShoreCheckList?.map((dataObj) => {
                      return dataObj.documentCheckListId;
                    }),
            }}
            fields={[
              {
                name: ["isAddPackage"],
                value: visaCaseData.isAddPackage,
              },
              {
                name: ["isDiscountAllowed"],
                value: visaCaseData.isDiscountAllowed,
              },
              {
                name: ["discount"],
                value: visaCaseData.packageDiscount,
              },
              {
                name: ["packageDiscountType"],
                value: visaCaseData.packageDiscountType,
              },
              {
                name: ["currency"],
                value: visaCaseData.currency,
              },
              {
                name: ["description"],
                value: visaCaseData.description,
              },
              {
                name: ["priorNote"],
                value: visaCaseData.priorNote,
              },
              {
                name: ["deadlineDate"],
                value:
                  visaCaseData.deadlineDate !== null &&
                  visaCaseData.deadlineDate !== ""
                    ? moment.utc(visaCaseData.deadlineDate).local()
                    : null,
              },
              {
                name: ["consultantId"],
                value: visaCaseData.consultantId,
              },
              {
                name: ["superAgentId"],
                value: visaCaseData.superAgentId,
              },
              // {
              //   name: ["workflowDocumentChecklist"],
              //   value: visaCaseData.documentCheckList,
              // },
              // {
              // name: ["coursesDocumentChecklist"],
              // value: visaCaseData.coursesChecklist,
              // },
            ]}
          >
            <Form.Item name={"isAddPackage"} valuePropName="checked">
              <Checkbox
                onChange={(e) => {
                  setVisaCaseData((previousData) => {
                    return {
                      ...previousData,
                      isAddPackage: e.target.checked,
                    };
                  });
                  setPackages([
                    {
                      id: null,
                      packageName: "",
                      quantity: 0,
                      totalPrice: 0,
                      unitPrice: 0,
                      hasGst: null,
                      gst: null,
                      isGstFlat: null,
                      isGstPercentage: null,
                    },
                  ]);
                }}
              >
                Add Other Services
              </Checkbox>
            </Form.Item>
            {visaCaseData.isAddPackage === true ? (
              <>
                <div
                  style={{
                    backgroundColor: "#eeeeee55",
                    borderRadius: "0.5rem",
                    padding: "0.5rem",
                  }}
                >
                  <h3>Package:</h3>
                  {packages.map((onePackage, index) => {
                    return (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <div
                            className="flexRowWithoutStyle"
                            style={{
                              justifyContent: "space-between",
                              gap: "1rem",
                              width: "100%",
                            }}
                          >
                            <div
                              className="flexRowWithoutStyle"
                              style={{
                                gap: "0.3rem",
                                width: "100%",
                              }}
                            >
                              <Form.Item
                                label="Item"
                                style={{ width: "100%" }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter current status!",
                                  },
                                ]}
                              >
                                <Select
                                  name={"casePackagesId"}
                                  value={packages[index].packageName}
                                >
                                  {store.caseState.activeCasePackage?.data?.map(
                                    (dataObj) => {
                                      return (
                                        <Option key={dataObj.id}>
                                          <div
                                            onClick={(e) => {
                                              let updatedPackage = [
                                                ...packages,
                                              ];

                                              updatedPackage[index].id =
                                                dataObj.id;
                                              updatedPackage[
                                                index
                                              ].packageName =
                                                dataObj.packageName;
                                              updatedPackage[
                                                index
                                              ].quantity = 1;
                                              updatedPackage[index].unitPrice =
                                                dataObj.unitPrice;
                                              updatedPackage[index].totalPrice =
                                                +updatedPackage[index]
                                                  .quantity *
                                                +updatedPackage[index]
                                                  .unitPrice;
                                              updatedPackage[index].gst =
                                                dataObj.gst;
                                              updatedPackage[index].hasGst =
                                                dataObj.hasGst;
                                              updatedPackage[index].isGstFlat =
                                                dataObj.isGstFlat;
                                              updatedPackage[
                                                index
                                              ].isGstPercentage =
                                                dataObj.isGstPercentage;
                                              setPackages(updatedPackage);
                                            }}
                                            className={"selectOptionDiv"}
                                          >
                                            {dataObj.packageName}
                                            {dataObj.packageType ===
                                              "IN_HOUSE" && (
                                              <Tag
                                                color="#2db7f5"
                                                style={{
                                                  borderRadius: "10px",
                                                  margin: "3px",
                                                }}
                                              >
                                                IH
                                              </Tag>
                                            )}
                                            {dataObj.packageType ===
                                              "INSTITUTE" && (
                                              <Tag
                                                color="#2db7f5"
                                                style={{
                                                  borderRadius: "10px",
                                                  margin: "3px",
                                                }}
                                              >
                                                IN
                                              </Tag>
                                            )}
                                            {dataObj.packageType ===
                                              "COMPANY" && (
                                              <Tag
                                                color="#2db7f5"
                                                style={{
                                                  borderRadius: "10px",
                                                  margin: "3px",
                                                }}
                                              >
                                                CO
                                              </Tag>
                                            )}
                                          </div>
                                        </Option>
                                      );
                                    }
                                  )}
                                </Select>
                              </Form.Item>
                              <a
                                className="plusButton"
                                onClick={() => {
                                  setIsAddPackageModalVisible(true);
                                }}
                              >
                                <AiOutlinePlus className="iconColor" />
                              </a>
                            </div>
                            <Form.Item
                              label="Quantity"
                              style={{ width: "100%" }}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter quantity!",
                              //   },
                              // ]}
                            >
                              <Input
                                name={"quantity"}
                                type="number"
                                value={packages[index].quantity}
                                placeholder="Quantity"
                                onChange={(e) => {
                                  let updatedPackage = [...packages];
                                  updatedPackage[index].quantity =
                                    +e.target.value;
                                  updatedPackage[index].totalPrice =
                                    updatedPackage[index].quantity *
                                    updatedPackage[index].unitPrice;
                                  setPackages(updatedPackage);
                                  // if (totalAmount !== null && unitPrice !== null) {
                                  //   setTotalAmount(
                                  //     totalAmount + quantity * unitPrice
                                  //   );
                                  // }
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              label="Unit Price"
                              style={{ width: "100%" }}

                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter unit price!",
                              //   },
                              // ]}
                            >
                              <Input
                                name={"unitPrice"}
                                type="number"
                                placeholder="Unit price"
                                value={packages[index].unitPrice}
                                onChange={(e) => {
                                  let updatedPackage = [...packages];
                                  updatedPackage[index].unitPrice =
                                    +e.target.value;
                                  updatedPackage[index].totalPrice =
                                    updatedPackage[index].quantity *
                                    updatedPackage[index].unitPrice;
                                  setPackages(updatedPackage);
                                  // if (totalAmount !== null && unitPrice !== null) {
                                  //   setTotalAmount(
                                  //     totalAmount + quantity * unitPrice
                                  //   );
                                  // }
                                }}
                              />
                            </Form.Item>
                            {/* packages[index].hasGst && */}
                            {/* `${true ? "%" : "Flat"}` */}
                            <Form.Item
                              label={`Price ${
                                packages[index].gst && packages[index].hasGst
                                  ? `(GST/VAT: ${packages[index].gst} ${
                                      packages[index].isGstPercentage
                                        ? `%`
                                        : packages[index].isGstFlat
                                        ? "FLAT"
                                        : ""
                                    })`
                                  : ""
                              }`}
                              style={{ width: "100%" }}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter total price!",
                              //   },
                              // ]}
                            >
                              <Input
                                name={"totalPrice"}
                                type="number"
                                placeholder="Total Price"
                                value={packages[index].totalPrice + ""}
                                onChange={(e) => {
                                  let updatedPackage = [...packages];
                                  updatedPackage[index].totalPrice =
                                    +e.target.value;

                                  setPackages(updatedPackage);
                                }}
                                onBlur={() => {}}
                              />
                            </Form.Item>
                          </div>
                          {packages.length > 1 && (
                            <Button
                              color="red"
                              onClick={() => removePackagesInput(index)}
                              style={{
                                padding: "0",
                                borderRadius: "50%",
                                height: "1.5rem",
                                width: "1.5rem",
                              }}
                            >
                              <CloseOutlined />
                            </Button>
                          )}
                        </div>
                        {packages.length - 1 === index && (
                          <Button
                            block
                            type="dashed"
                            style={{ marginBottom: "1rem" }}
                            onClick={addPackagesInput}
                          >
                            <PlusOutlined />
                            Add Package
                          </Button>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <Form.Item
                    name={"isDiscountAllowed"}
                    valuePropName="checked"
                    style={{ width: "100%" }}
                  >
                    <Checkbox
                      onChange={(e) => {
                        setVisaCaseData((previousData) => {
                          return {
                            ...previousData,
                            isDiscountAllowed: e.target.checked,
                          };
                        });
                      }}
                    >
                      is Discount Allowed?
                    </Checkbox>
                  </Form.Item>
                  {visaCaseData.isDiscountAllowed ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          gap: "0.5rem",
                        }}
                      >
                        <Form.Item
                          label="Discount amount"
                          name={"discount"}
                          style={{ width: "100%" }}
                        >
                          <Input
                            type="number"
                            placeholder="Discount"
                            onChange={(e) => {
                              setVisaCaseData((previousData) => {
                                return {
                                  ...previousData,
                                  packageDiscount: e.target.value,
                                };
                              });
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label={"Type"}
                          name={"packageDiscountType"}
                          style={{ width: "100%" }}
                        >
                          <Radio.Group
                            onChange={(e) => {
                              setVisaCaseData((previousData) => {
                                return {
                                  ...previousData,
                                  packageDiscountType: e.target.value,
                                };
                              });
                            }}
                          >
                            <Radio value={"FLAT"}>is Flat</Radio>
                            <Radio value={"PERCENTAGE"}>is Percentage</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </>
                  ) : null}
                </div>
              </>
            ) : null}

            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Currency"
                name={"currency"}
                style={{ width: "100%" }}
              >
                <Select
                  onChange={(e) => {
                    setVisaCaseData((previousData) => {
                      return {
                        ...previousData,
                        currency: e,
                      };
                    });
                  }}
                >
                  <Option
                    value={
                      store.leadState.leadsDropdown?.defaultSelectedCurrency
                    }
                  >
                    {store.leadState.leadsDropdown?.defaultSelectedCurrency}
                  </Option>
                </Select>
              </Form.Item>
            </div>

            <Form.Item
              label="Description"
              name={"description"}
              style={{ width: "100%" }}
            >
              <TextArea
                placeholder="Write something..."
                onChange={(e) => {
                  setVisaCaseData((previousData) => {
                    return {
                      ...previousData,
                      description: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            {/* <Form.Item
              label="Prior Note"
              name={"priorNote"}
              style={{ width: "100%" }}
            >
              <TextArea
                placeholder="Write something..."
                onChange={(e) => {
                  setVisaCaseData((previousData) => {
                    return {
                      ...previousData,
                      priorNote: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Deadline"
              name={"deadlineDate"}
              style={{ width: "100%" }}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                onChange={(e) => {
                  setVisaCaseData((previousData) => {
                    return {
                      ...previousData,
                      deadlineDate: e,
                    };
                  });
                }}
              />
            </Form.Item>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                name={"consultantId"}
                label="Assign Consultants"
                style={{ width: "100%" }}
              >
                <Checkbox.Group
                  onChange={(e) => {
                    setVisaCaseData((previousData) => {
                      return {
                        ...previousData,
                        consultantId: e,
                      };
                    });
                  }}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                  }}
                >
                  {store.caseState?.caseDropdown?.consultant?.map((dataObj) => {
                    return (
                      <div>
                        <Checkbox
                          className="checkboxEllipsis"
                          key={dataObj.id}
                          value={dataObj.id}
                          style={{
                            width: "100%",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          <Tooltip title={dataObj.emailId}>
                            {dataObj.emailId}
                          </Tooltip>
                        </Checkbox>
                      </div>
                    );
                  })}
                </Checkbox.Group>
              </Form.Item>
              <a
                className="plusButton"
                onClick={() => {
                  setIsAddConsultantModalVisible(true);
                }}
              >
                <AiOutlinePlus className="iconColor" />
              </a>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Assign Super Agent"
                  name={"superAgentId"}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Select placeholder="Select Super Agent">
                    {store.caseState?.caseDropdown?.headAgent?.map(
                      (dataObj) => (
                        <Option key={dataObj.id} value={dataObj.id}>
                          {dataObj.name}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddHeadAgentModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Assign Super Agent"
                  name={"superAgentId"}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Select placeholder="Select Super Agent">
                    {store.caseState?.caseDropdown?.headAgent?.map(
                      (dataObj) => (
                        <Option key={dataObj.id} value={dataObj.id}>
                          {dataObj.name}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddHeadAgentModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                label="Workflow Document Checklist"
                name={"workflowDocumentChecklist"}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Checkbox.Group
                  onChange={(e) => {
                    setVisaCaseData((previousData) => {
                      return {
                        ...previousData,
                        documentCheckList: e,
                      };
                    });
                  }}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  {store?.documentChecklistState?.documentCheclistData?.data?.map(
                    (dataObj) => {
                      return (
                        <Checkbox
                          key={dataObj.id}
                          value={dataObj.id}
                          style={{ margin: 0 }}
                        >
                          {dataObj.name}
                        </Checkbox>
                      );
                    }
                  )}
                </Checkbox.Group>
              </Form.Item>
              {visaCaseData.visaType === "EDUCATION" ? (
                <>
                  <Form.Item
                    label={`Courses Document Checklist ${
                      visaCaseData?.currentStatus === "OFFSHORE"
                        ? "(Offshore)"
                        : visaCaseData?.currentStatus === "ONSHORE"
                        ? "(Onshore)"
                        : ""
                    }`}
                    name={"coursesDocumentChecklist"}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {visaCaseData.currentStatus === "OFFSHORE" ? (
                      <Checkbox.Group
                        onChange={(e) => {
                          setVisaCaseData((previousData) => {
                            return {
                              ...previousData,
                              coursesChecklist: e,
                            };
                          });
                        }}
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        {store?.documentChecklistState?.documentCheclistData?.data?.map(
                          (dataObj) => {
                            return (
                              <Checkbox
                                key={dataObj.id}
                                value={dataObj.id}
                                style={{ margin: 0 }}
                              >
                                {dataObj.name}
                              </Checkbox>
                            );
                          }
                        )}
                      </Checkbox.Group>
                    ) : visaCaseData.currentStatus === "ONSHORE" ? (
                      <Checkbox.Group
                        onChange={(e) => {
                          setVisaCaseData((previousData) => {
                            return {
                              ...previousData,
                              coursesChecklist: e,
                            };
                          });
                        }}
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        {store?.documentChecklistState?.documentCheclistData?.data?.map(
                          (dataObj) => {
                            return (
                              <Checkbox
                                key={dataObj.id}
                                value={dataObj.id}
                                style={{ margin: 0 }}
                              >
                                {dataObj.name}
                              </Checkbox>
                            );
                          }
                        )}
                      </Checkbox.Group>
                    ) : null}
                  </Form.Item>
                </>
              ) : null}
            </div> */}

            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              {current === 2 && (
                <Form.Item>
                  <Button
                    onClick={() => {
                      setCurrent(1);
                    }}
                  >
                    Back
                  </Button>
                </Form.Item>
              )}
              <Form.Item name="caseSubmit">
                <Button
                  name="caseSubmit"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Next
                </Button>
              </Form.Item>
            </div>
          </Form>
          {isAddConsultantModalVisible && (
            <AddEmployee
              isAddEmployeeModalVisible={isAddConsultantModalVisible}
              setIsAddEmployeeModalVisible={setIsAddConsultantModalVisible}
            />
          )}
          {isAddHeadAgentModalVisible && (
            <AddHeadAgent
              isAddHeadAgentModalVisible={isAddHeadAgentModalVisible}
              setIsAddHeadAgentModalVisible={setIsAddHeadAgentModalVisible}
            />
          )}
          {isAddPackageModalVisible && (
            <AddCasePackage
              from={"CASE"}
              isAddPackageModalVisible={isAddPackageModalVisible}
              setIsAddPackageModalVisible={setIsAddPackageModalVisible}
            />
          )}
        </div>
        <div
          style={{
            width: "30%",
          }}
        >
          <CaseSummary
            current={current}
            documentChecklist={
              store?.documentChecklistState?.documentCheclistData?.data
            }
            visaCaseData={visaCaseData}
          />
        </div>
      </div>
    </>
  );
};

export default VisaPackageInfo;
