import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
const { Option } = Select;
const { TextArea } = Input;

const UpdateCaseStatusCaseLog = ({
  fetchCaseDetail,
  record,
  isUpdateCaseStatusCaseLogModalVisible,
  setIsUpdateCaseStatusCaseLogModalVisible,
}) => {
  const updateCaseStatusState = useSelector((state) => state.VisasReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_WORKFLOW_STATUSES_BY_WORKFLOW_ID_REQUEST",
      payload: { id: record?.workFlowDto?.id },
    });
  }, [isUpdateCaseStatusCaseLogModalVisible]);
  const [form] = Form.useForm();
  const fetchCaseLogFN = (response) => {
    dispatch({
      type: "FETCH_CASE_LOG_BY_WORKFLOWDTO_ID_REQUEST",
      payload: { id: record?.id },
    });
    fetchCaseDetail();
  };

  const updateCaseStatusSubmitHandler = (value) => {
    const formData = {
      ...value,
      isSendEmail: false,
    };
    dispatch({
      type: "UPDATE_CASE_STATUS_CASE_LOG_REQUEST",

      payload: {
        id: record?.id,
        formData,
        isFetchCaseLogFN: true,
        fetchCaseLogFN: fetchCaseLogFN,
      },
    });
    form.resetFields();
    setIsUpdateCaseStatusCaseLogModalVisible(false);
  };
  return (
    <Modal
      title="Update Case Status"
      open={isUpdateCaseStatusCaseLogModalVisible}
      onCancel={() => {
        setIsUpdateCaseStatusCaseLogModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateCaseStatusSubmitHandler}
        colon={true}
        form={form}
        initialValues={{
          workFlowStatusId: record?.workFlowStatusDto?.id,
        }}
      >
        <Form.Item
          label="Assign Status"
          name={"workFlowStatusId"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select status !",
            },
          ]}
        >
          <Select>
            {updateCaseStatusState?.workflowStatusesByWorkflowId?.data?.map(
              (dataObj, index) => {
                return (
                  <Option key={dataObj.id} value={dataObj?.id}>
                    {dataObj?.statusName}
                  </Option>
                );
              }
            )}
          </Select>
        </Form.Item>
        <Form.Item
          label="Customer Note"
          name={"customerNote"}
          style={{ width: "100%" }}
          //   rules={[
          //     {
          //       required: true,
          //       message: "Please enter customer note!",
          //     },
          //   ]}
        >
          <TextArea placeholder="Please write something..." />
        </Form.Item>

        <Form.Item
          label="Admin Note"
          name={"adminNote"}
          style={{ width: "100%" }}
          //   rules={[
          //     {
          //       required: true,
          //       message: "Please enter admin note",
          //     },
          //   ]}
        >
          <TextArea placeholder="Please write something..." />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Case Status
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateCaseStatusCaseLog;
