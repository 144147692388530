import {
  Card,
  Divider,
  Drawer,
  Image,
  Modal,
  Input,
  Tooltip,
  Checkbox,
  Button,
  Form,
} from "antd";
import React, { useEffect } from "react";
import {
  formattedDate,
  formattedDateTime,
  twoTagResponses,
} from "../../HelperFunction";
import client from "../../Assets/client.png";
import { AiOutlineHeart, AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { FcDocument } from "react-icons/fc";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ImCheckboxChecked } from "react-icons/im";
import AddVisaCases from "../Visas/AddVisas";
import { ExclamationCircleFilled } from "@ant-design/icons";
import StatusHistoryOnlyUpdate from "../Appointments/StatusHistoryOnlyUpdate";
import AddCourseCommission from "./AddCcourseCommission";
import course from "../../Assets/newcourse.png";
const { Meta } = Card;
const { confirm } = Modal;
const { TextArea } = Input;

const CoursesDetail = ({
  fromWishlist,
  record,
  fromCounselling,
  recordData,
  instituteRecordForCountry,
  setIsStartCounsellingModalVisible,
  isCourseDetailsModelVisible,
  setIsCourseDetailsModelVisible,
}) => {
  const store = useSelector((state) => {
    return {
      profileState: state.ProfileReducer,
      courseState: state.CoursesReducer,
    };
  });
  const dispatch = useDispatch();

  const [Record, setRecord] = useState(record);
  const [isUpdate, setIsUpdate] = useState(false);
  const [current, setCurrent] = useState("description");
  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);
  const [isAsignModelVisible, setIsAsignModelVisible] = useState(false);
  const [
    isStatusHistoryOnlyUpdateModalVisible,
    setIsStatusHistoryOnlyUpdateModalVisible,
  ] = useState(false);
  const [enrollType, setEnrollType] = useState("ONSHORE");
  const clientDetailsTabList = [
    {
      key: "description",
      tab: "Description",
    },
    {
      key: "courseDetail",
      tab: "Course Detail",
    },
    {
      key: "document",
      tab: "Document Checklist",
    },
    {
      key: "courseCommission",
      tab: "Course Commission",
    },
  ];

  useEffect(() => {
    Record?.offshoreCommission || Record?.onshoreCommission
      ? setIsUpdate(true)
      : setIsUpdate(false);
  }, [Record]);

  useEffect(() => {
    fetchCourseDetail();
  }, []);

  console.log("data data", Record);

  const fetchCourseDetail = () => {
    dispatch({
      type: "FETCH_COURSES_BY_ID_REQUEST",
      payload: Record?.id,
      isSuccessFn: true,
      successFunction: (res) => {
        setRecord(res?.data);
      },
    });
  };

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  const reloadFn = (response) => {
    dispatch({
      type: "FETCH_COURSE_WISHLIST_FOR_LEAD_ID_REQUEST",
      payload: {
        leadId: recordData?.leadResponse?.leadId,
        page: 1,
        pageSize: 10,
      },
    });
  };

  return (
    <Drawer
      title="Course Details"
      open={isCourseDetailsModelVisible}
      onClose={() => {
        setIsCourseDetailsModelVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.5rem" }} className="drawerStyle">
        <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
          <Image className={"clientImage"} src={course} alt="" />
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              width: "100%",
              gap: "0.5rem",
              minHeight: "10rem",
              position: "relative",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {Record?.name}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.4rem", color: "#aaa" }}
            >
              {Record?.courseCode}
            </div>
            <div>{Record?.degree?.name}</div>

            {Record?.courseDuration && (
              <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
                <span>Time Period: </span>
                <span>
                  {Record?.courseDuration} {Record?.courseAccordingTo}
                </span>
              </div>
            )}
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", position: "absolute", bottom: 0 }}
            >
              {fromCounselling && fromWishlist !== true && (
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch({
                      type: "ADD_TO_COURSE_WISHLIST_REQUEST",
                      payload: {
                        formData: { course: [{ id: Record?.id }] },
                        leadId: recordData?.leadResponse?.leadId,
                        isReload: true,
                        reloadFn: reloadFn,
                      },
                    });
                  }}
                >
                  Add to wishlist
                </Button>
              )}
              {fromCounselling && (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsStatusHistoryOnlyUpdateModalVisible(true);
                  }}
                >
                  Status Update
                </Button>
              )}
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "courseDetail" && (
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.5rem",
                width: "100%",
              }}
            >
              {Record?.onshore ? (
                <Card className="clientCard" style={{ width: "100%" }}>
                  {detailFun("Onshore", `${Record?.onshore ? "Yes" : "No"}`)}
                  {detailFun(
                    "Onshore Fee",
                    `${Record?.onShoreFee ? Record?.onShoreFee : "N/A"}`
                  )}
                  {detailFun(
                    "Is Onshore Discount?",

                    Record?.onshoreIsDiscount
                      ? twoTagResponses(Record?.onshoreIsDiscount, true, false)
                      : "N/A"
                  )}
                  {detailFun(
                    "Onshore Discount",
                    `${
                      Record?.onshoreDiscountAmount
                        ? Record?.onshoreDiscountAmount
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Onshore Discount Type",
                    `${
                      Record?.onshoreDiscountType
                        ? Record?.onshoreDiscountType
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Is Onshore Scholarship?",

                    Record?.onshoreIsScholarship
                      ? twoTagResponses(
                          Record?.onshoreIsScholarship,
                          true,
                          false
                        )
                      : "N/A"
                  )}
                  {detailFun(
                    "Onshore Scholarship",
                    `${
                      Record?.onshoreScholarshipAmount
                        ? Record?.onshoreScholarshipAmount
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Onshore Scholarship Type",
                    `${
                      Record?.onshoreScholarshipType
                        ? Record?.onshoreScholarshipType
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Onshore Material Fee",
                    `${
                      Record?.onshoreMaterialFee
                        ? Record?.onshoreMaterialFee
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Onshore Application Fee",
                    `${
                      Record?.onshoreApplicationFee
                        ? Record?.onshoreApplicationFee
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Onshore Additional Fee",
                    `${
                      Record?.onshoreAdditionalFee
                        ? Record?.onshoreAdditionalFee
                        : "N/A"
                    }`
                  )}
                  {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
                    "ADD"
                  ) && (
                    <div className="flexCenter">
                      <Button
                        type="primary"
                        style={{ borderRadius: "1rem" }}
                        onClick={() => {
                          setEnrollType("ONSHORE");
                          setIsAddVisasModalVisible(true);
                        }}
                      >
                        Onshore Enroll
                      </Button>
                    </div>
                  )}
                </Card>
              ) : null}
              {Record?.offshore ? (
                <Card className="clientCard" style={{ width: "100%" }}>
                  {detailFun("Offshore", `${Record?.offshore ? "Yes" : "No"}`)}
                  {detailFun(
                    "Offshore Fee",
                    `${Record?.offShoreFee ? Record?.offShoreFee : "N/A"}`
                  )}
                  {detailFun(
                    "Is Offshore Discount?",

                    Record?.offshoreIsDiscount
                      ? twoTagResponses(Record?.offshoreIsDiscount, true, false)
                      : "N/A"
                  )}
                  {detailFun(
                    "Offshore Discount",
                    `${
                      Record?.offshoreDiscountAmount
                        ? Record?.offshoreDiscountAmount
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Offshore Discount Type",
                    `${
                      Record?.offshoreDiscountType
                        ? Record?.offshoreDiscountType
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Is Offshore Scholarship?",

                    twoTagResponses(Record?.offshoreIsScholarship, true, false)
                  )}
                  {detailFun(
                    "Offshore Scholarship",
                    `${
                      Record?.offshoreScholarshipAmount
                        ? Record?.offshoreScholarshipAmount
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Offshore Scholarship Type",
                    `${
                      Record?.offshoreScholarshipType
                        ? Record?.offshoreScholarshipType
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Offshore Material Fee",
                    `${
                      Record?.offshoreMaterialFee
                        ? Record?.offshoreMaterialFee
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Offshore Application Fee",
                    `${
                      Record?.offshoreApplicationFee
                        ? Record?.offshoreApplicationFee
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Offshore Additional Fee",
                    `${
                      Record?.offshoreAdditionalFee
                        ? Record?.offshoreAdditionalFee
                        : "N/A"
                    }`
                  )}
                  {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
                    "ADD"
                  ) && (
                    <div className="flexCenter">
                      <Button
                        type="primary"
                        style={{ borderRadius: "1rem" }}
                        onClick={() => {
                          setEnrollType("OFFSHORE");
                          setIsAddVisasModalVisible(true);
                        }}
                      >
                        Offshore Enroll
                      </Button>
                    </div>
                  )}
                </Card>
              ) : null}
              {Record?.both ? (
                <>
                  <Card className="clientCard" style={{ width: "100%" }}>
                    {/* {detailFun(
                      "Both (Onshore/Offshore)",
                      `${Record?.both ? "Yes" : "No"}`
                    )} */}
                    {detailFun(
                      "Onshore Fee",
                      `${Record?.onShoreFee ? Record?.onShoreFee : "N/A"}`
                    )}
                    {detailFun(
                      "Is Onshore Discount?",

                      Record?.onshoreIsDiscount
                        ? twoTagResponses(
                            Record?.onshoreIsDiscount,
                            true,
                            false
                          )
                        : "N/A"
                    )}
                    {detailFun(
                      "Onshore Discount",
                      `${
                        Record?.onshoreDiscountAmount
                          ? Record?.onshoreDiscountAmount
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Onshore Discount Type",
                      `${
                        Record?.onshoreDiscountType
                          ? Record?.onshoreDiscountType
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Is Onshore Scholarship?",
                      Record?.onshoreIsScholarship
                        ? twoTagResponses(
                            Record?.onshoreIsScholarship,
                            true,
                            false
                          )
                        : "N/A"
                    )}
                    {detailFun(
                      "Onshore Scholarship",
                      `${
                        Record?.onshoreScholarshipAmount
                          ? Record?.onshoreScholarshipAmount
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Onshore Scholarship Type",
                      `${
                        Record?.onshoreScholarshipType
                          ? Record?.onshoreScholarshipType
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Onshore Material Fee",
                      `${
                        Record?.onshoreMaterialFee
                          ? Record?.onshoreMaterialFee
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Onshore Application Fee",
                      `${
                        Record?.onshoreApplicationFee
                          ? Record?.onshoreApplicationFee
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Onshore Additional Fee",
                      `${
                        Record?.onshoreAdditionalFee
                          ? Record?.onshoreAdditionalFee
                          : "N/A"
                      }`
                    )}

                    {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
                      "ADD"
                    ) && (
                      <div className="flexCenter">
                        <Button
                          type="primary"
                          style={{ borderRadius: "1rem" }}
                          onClick={() => {
                            setEnrollType("ONSHORE");
                            setIsAddVisasModalVisible(true);
                          }}
                        >
                          Onshore Enroll
                        </Button>
                      </div>
                    )}
                  </Card>
                  <Card className="clientCard" style={{ width: "100%" }}>
                    {detailFun(
                      "Offshore Fee",
                      `${Record?.offShoreFee ? Record?.offShoreFee : "N/A"}`
                    )}
                    {detailFun(
                      "Is Offshore Discount?",

                      Record?.offshoreIsDiscount
                        ? twoTagResponses(
                            Record?.offshoreIsDiscount,
                            true,
                            false
                          )
                        : "N/A"
                    )}
                    {detailFun(
                      "Offshore Discount",
                      `${
                        Record?.offshoreDiscountAmount
                          ? Record?.offshoreDiscountAmount
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Offshore Discount Type",
                      `${
                        Record?.offshoreDiscountType
                          ? Record?.offshoreDiscountType
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Is Offshore Scholarship?",

                      Record?.offshoreIsScholarship
                        ? twoTagResponses(
                            Record?.offshoreIsScholarship,
                            true,
                            false
                          )
                        : "N/A"
                    )}
                    {detailFun(
                      "Offshore Scholarship",
                      `${
                        Record?.offshoreScholarshipAmount
                          ? Record?.offshoreScholarshipAmount
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Offshore Scholarship Type",
                      `${
                        Record?.offshoreScholarshipType
                          ? Record?.offshoreScholarshipType
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Offshore Material Fee",
                      `${
                        Record?.offshoreMaterialFee
                          ? Record?.offshoreMaterialFee
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Offshore Application Fee",
                      `${
                        Record?.offshoreApplicationFee
                          ? Record?.offshoreApplicationFee
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Offshore Additional Fee",
                      `${
                        Record?.offshoreAdditionalFee
                          ? Record?.offshoreAdditionalFee
                          : "N/A"
                      }`
                    )}
                    {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
                      "ADD"
                    ) && (
                      <div className="flexCenter">
                        <Button
                          type="primary"
                          style={{ borderRadius: "1rem" }}
                          onClick={() => {
                            setEnrollType("OFFSHORE");
                            setIsAddVisasModalVisible(true);
                          }}
                        >
                          Offshore Enroll
                        </Button>
                      </div>
                    )}
                  </Card>
                </>
              ) : null}
            </div>
          )}
          {current === "document" && (
            <>
              {Record?.documents?.map((dataObj) => {
                return (
                  <div key={dataObj.id}>
                    <a
                      href={dataObj?.documentUrl}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "1px",
                        flexWrap: "wrap",
                      }}
                    >
                      <FcDocument style={{ fontSize: "30px" }} />
                      <span>{dataObj?.documentName}</span>
                    </a>
                    <Divider
                      orientationMargin="0"
                      style={{ margin: "0.5rem" }}
                    />
                  </div>
                );
              })}
              <div>
                <h3>Offshore Document Checklist</h3>
                {Record?.offShoreCheckList?.length > 0 ? (
                  Record?.offShoreCheckList?.map((dataObj) => {
                    return (
                      <div
                        key={dataObj.id}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "0.5rem",
                          flexWrap: "wrap",
                        }}
                      >
                        <ImCheckboxChecked style={{ color: "blue" }} />
                        {dataObj?.item}
                      </div>
                    );
                  })
                ) : (
                  <div style={{ textAlign: "center" }}>
                    No offshore document checklist Available.
                  </div>
                )}
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
              <div>
                <h3>Onshore Document Checklist</h3>
                {Record?.onShoreCheckList?.length > 0 ? (
                  Record?.onShoreCheckList?.map((dataObj) => {
                    return (
                      <div
                        key={dataObj.id}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "0.5rem",
                          flexWrap: "wrap",
                        }}
                      >
                        <ImCheckboxChecked style={{ color: "blue" }} />
                        {dataObj?.item}
                      </div>
                    );
                  })
                ) : (
                  <div style={{ textAlign: "center" }}>
                    No onshore document checklist Available.
                  </div>
                )}
              </div>
            </>
          )}
          {current === "description" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1px",
                flexWrap: "wrap",
              }}
            >
              {Record?.description}
            </div>
          )}
          {current === "courseCommission" && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Button
                type="primary"
                onClick={() => setIsAsignModelVisible(true)}
              >
                {isUpdate ? "Edit" : "Add"} Course Commission
              </Button>

              <Card style={{ width: "100%" }}>
                {detailFun(
                  "Course Name",
                  `${Record?.name ? Record?.name : "N/A"}`
                )}
                {detailFun(
                  "Course Type",
                  `${
                    Record?.offShoreFee > 0 && Record?.onShoreFee > 0
                      ? "Offshore & Onshore"
                      : Record?.offShoreFee > 0
                      ? "Offshore"
                      : Record?.onShoreFee > 0
                      ? "Onshore"
                      : "N/A"
                  }`
                )}
                {Record?.onShoreFee > 0 &&
                  detailFun(
                    "Onshore Commission",
                    `${
                      Record?.onshoreCommission
                        ? `${Record?.onshoreCommission} %`
                        : "N/A"
                    }`
                  )}
                {Record?.offShoreFee > 0 &&
                  detailFun(
                    "Ofshore Commission",
                    `${
                      Record?.offshoreCommission
                        ? `${Record?.offshoreCommission} %`
                        : "N/A"
                    }`
                  )}
              </Card>
            </div>
          )}
        </Card>
      </Card>
      {isAddVisasModalVisible && (
        <AddVisaCases
          navigateCaseList={true}
          fromCounselling={fromCounselling}
          recordData={recordData}
          courseRecord={Record}
          instituteRecordForCountry={instituteRecordForCountry}
          enrollType={enrollType}
          isAddVisaCasesModalVisible={isAddVisasModalVisible}
          setIsAddVisaCasesModalVisible={setIsAddVisasModalVisible}
          setIsStartCounsellingModalVisible={setIsStartCounsellingModalVisible}
        />
      )}
      {isStatusHistoryOnlyUpdateModalVisible && (
        <StatusHistoryOnlyUpdate
          recordData={recordData}
          isStatusHistoryOnlyUpdateModalVisible={
            isStatusHistoryOnlyUpdateModalVisible
          }
          setIsStatusHistoryOnlyUpdateModalVisible={
            setIsStatusHistoryOnlyUpdateModalVisible
          }
        />
      )}

      {isAsignModelVisible && (
        <AddCourseCommission
          record={Record}
          fetchCourseDetail={fetchCourseDetail}
          isUpdate={isUpdate}
          isAsignModelVisible={isAsignModelVisible}
          commissionType={
            Record?.offShoreFee > 0 && Record?.onShoreFee > 0
              ? "both"
              : Record?.offShoreFee > 0
              ? "offshore"
              : Record?.onShoreFee > 0
              ? "onshore"
              : "N/A"
          }
          setIsAsignModelVisible={setIsAsignModelVisible}
        />
      )}
    </Drawer>
  );
};

export default CoursesDetail;
