import { RedoOutlined } from "@ant-design/icons";
import { Empty } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

export default function EmailLogDetail({ fetchEmailLogDetails }) {
  const { emailLogById, isLoading } = useSelector(
    (store) => store?.ProfileReducer
  );

  return (
    <div>
      {emailLogById?.length === 0 ? (
        <Empty />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            maxHeight: "80vh",
            overflowY: "scroll",
          }}
        >
          {/* <div>
            <RedoOutlined
              onClick={fetchEmailLogDetails}
              style={{ cursor: "pointer", fontSize: "2rem", color: "black" }}
            />
          </div> */}
          {emailLogById?.map((email, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              {email?.threadCollection && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "1rem",
                      border: "1px solid #e4edfb",
                      background: "#e4edfb",
                      borderRadius: "1rem",
                      width: "70%",
                    }}
                  >
                    <div>
                      <p style={{ margin: 0 }}>
                        From: {email?.threadCollection?.fromUserName}
                      </p>
                      <p style={{ margin: 0 }}>
                        Date:
                        {moment(
                          email?.threadCollection?.receivedDateTime
                        ).format("dd:mm:yyyy hh:mm A")}
                      </p>
                    </div>
                    <p
                      style={{ margin: "0.5rem 0 0" }}
                      dangerouslySetInnerHTML={{
                        __html: email?.threadCollection?.bodyContent,
                      }}
                    ></p>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1rem",
                  border: "1px solid #e6e6e6",
                  background: "#e6e6e6",
                  borderRadius: "1rem",
                  width: "70%",
                }}
              >
                <div>
                  <p style={{ margin: 0 }}>From: {email?.fromEmail}</p>
                  <p style={{ margin: 0 }}>
                    Date:
                    {moment(email?.sentDatetime).format("dd:mm:yyyy hh:mm A")}
                  </p>
                </div>
                <p
                  style={{ margin: "0.5rem 0 0" }}
                  dangerouslySetInnerHTML={{ __html: email?.bodyContent }}
                ></p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
