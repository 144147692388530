import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  assignedTotasks: {},
  myTask: {},
  taskCsv: {},
};

const TaskReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_ASSIGNED_BY_TASK_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ASSIGNED_BY_TASK_REQUEST_SUCCESS": {
      return {
        ...state,
        myTask: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ASSIGNED_BY_TASK_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ASSIGNED_TO_TASK_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ASSIGNED_TO_TASK_REQUEST_SUCCESS": {
      return {
        ...state,
        assignedTotasks: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ASSIGNED_TO_TASK_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_TASK_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_TASK_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Task added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_TASK_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add Task.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_TASK_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_TASK_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Task updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_TASK_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update Task.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_TASK_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_TASK_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Task deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_TASK_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to delete Task.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_TASK_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_TASK_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Task status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_TASK_STATUS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to change Task status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DOWNLOAD_TASK_CSV_REQUEST": {
      message.success("Success! CSV downloaded.");

      return {
        ...state,
        isLoading: true,
      };
    }

    case "DOWNLOAD_TASK_CSV_REQUEST_SUCCESS": {
      return {
        ...state,
        taskCsv: action.payload,
        isLoading: false,
      };
    }
    case "DOWNLOAD_TASK_CSV_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to download csv.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "IMPORT_TASK_CSV_FILE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "IMPORT_TASK_CSV_FILE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! CSV imported.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "IMPORT_TASK_CSV_FILE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to import CSV.");

      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default TaskReducer;
