import { Card, Divider } from "antd";
import moment from "moment";
import React from "react";
import { formattedDate } from "../../../HelperFunction";

export default function ServiceDetailComponent({ record }) {
  return (
    <div className="flexRowWithFlexStart">
      {" "}
      <Card className="clientCard" style={{ width: "100%" }}>
        <div className="flexColumnWithoutStyle" style={{ width: "100%" }}>
          <div className="flexRowSpaceBetween">
            Name:
            <span>
              {record?.customerId?.fullName
                ? record?.customerId?.fullName
                : "N//A"}
            </span>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
          <div className="flexRowSpaceBetween">
            Email:
            <span>
              {record?.customerId?.email ? record?.customerId?.email : "N/A"}
            </span>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
          <div className="flexRowSpaceBetween">
            Mobile:
            <span>
              {record?.customerId?.mobile ? record?.customerId?.mobile : "N/A"}
            </span>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
          <div className="flexRowSpaceBetween">
            Service Status:
            <span
              style={{
                background: "#1d63a9",
                color: "white",
                padding: "0.2rem 1rem",
                borderRadius: "1rem",
              }}
            >
              {record?.serviceStatus ? record?.serviceStatus : "N/A"}
            </span>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
          <div className="flexRowSpaceBetween">
            Service Start Date:
            <span>
              {record?.serviceStartDate
                ? formattedDate(record?.serviceStartDate)
                : "N/A"}
            </span>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
          <div className="flexRowSpaceBetween">
            Service Expiry Date:
            <span>
              {record?.serviceExpiryDate
                ? formattedDate(record?.serviceExpiryDate)
                : "N/A"}
            </span>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        </div>
      </Card>
      <Card className="clientCard" style={{ width: "100%" }}>
        <div className="flexColumnWithoutStyle" style={{ width: "100%" }}>
          <div className="flexRowSpaceBetween">
            Service Package:<span>{record?.serviceType}</span>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
          {record?.productPackagesDetails?.map((pkg) => (
            <>
              {" "}
              <div className="flexRowSpaceBetween">
                Package Name:
                <span>
                  {/* {record?.serviceExpiryDate
                    ? formattedDate(record?.serviceExpiryDate)
                    : "N/A"} */}
                  {pkg?.packageName}
                </span>
                <span>
                  {/* {record?.serviceExpiryDate
                    ? formattedDate(record?.serviceExpiryDate)
                    : "N/A"} */}
                  {pkg?.unitPrice} AUD/pkg
                </span>
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            </>
          ))}
          <div className="flexRowSpaceBetween">
            GST:
            <span>{record?.gstAmount ? `${record?.gstAmount} %` : "N/A"}</span>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
          <div className="flexRowSpaceBetween">
            Discount:
            <span>
              {record?.discount
                ? `${record?.discount} ${
                    record?.isPercentage ? "PERCENTAGE" : "FLAT"
                  }`
                : "N/A"}
            </span>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
          <div className="flexRowSpaceBetween">
            Total Amount:
            <span>
              {record?.totalAmount ? `${record?.totalAmount} AUD` : "N/A"}
            </span>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        </div>
      </Card>
    </div>
  );
}
