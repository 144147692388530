import React from "react";
import SiderDemo from "../../Components/Layout/SiderDemo";

const Settings = () => {
  return (
    <SiderDemo>
      <div>Settings</div>
    </SiderDemo>
  );
};

export default Settings;
