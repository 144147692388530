import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Drawer } from "antd";
import React from "react";
import { formattedDate } from "../../../HelperFunction";

const PDFviewer = ({
  record,
  companyProfile,
  isPDFviewerModalVisible,
  setIsPDFviewerModalVisible,
}) => {
  return (
    <Drawer
      title="PDF Viewer"
      open={isPDFviewerModalVisible}
      onClose={() => {
        setIsPDFviewerModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <PDFviewer>
        <Document>
          <Page
            style={{
              marginTop: "20pt",
              marginLeft: "20pt",
              marginRight: "20pt",
              marginBottom: "20pt",
              padding: "10pt",
            }}
          >
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text>Quote No: #{record?.id}</Text>
              <View style={{ flexDirection: "column", gap: "2pt" }}>
                <Text>{companyProfile?.companyName}</Text>
                <Text>{companyProfile?.companyAddress}</Text>
                <Text>
                  Phone: {companyProfile?.callingCode}{" "}
                  {companyProfile?.phoneNumber}
                </Text>
                <Text>{companyProfile?.emailId}</Text>
                <Text>ABN: {companyProfile?.companyRegistrationNumber}</Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFviewer>
    </Drawer>
  );
};

export default PDFviewer;
