import { Button, Form, Input, Modal, Select } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default function AddEditInstituteBranch({
  type,
  instituteIdNumber,
  isModalOpen,
  setIsModalOpen,
  record,
  fetchInstituteBranches,
}) {
  const dispatch = useDispatch();
  const LeadState = useSelector((store) => store?.LeadReducer);
  const { leadsDropdown } = LeadState;
  console.log("dropdown", leadsDropdown);

  useEffect(() => {
    dispatch({ type: "FETCH_LEADS_DROPDOWN_REQUEST" });
  }, []);

  const handleAddInstituteBranch = (values) => {
    type === "Add"
      ? dispatch({
          type: "ADD_INSTITUTE_BRANCH_REQUEST",
          payload: { instituteId: instituteIdNumber, ...values },
          isSuccessFn: true,
          successFunction: () => {
            setIsModalOpen(false);
            fetchInstituteBranches();
          },
        })
      : dispatch({
          type: "EDIT_INSTITUTE_BRANCH_REQUEST",
          payload: {
            instituteId: instituteIdNumber,
            branchId: record?.id,
            ...values,
          },
          isSuccessFn: true,
          successFunction: () => {
            setIsModalOpen(false);
            fetchInstituteBranches();
          },
        });
  };
  return (
    <Modal
      title={`${type} Institute Branch`}
      open={isModalOpen}
      footer={false}
      onCancel={() => {
        setIsModalOpen(false);
      }}
    >
      <Form
        layout="vertical"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "0 1rem",
        }}
        onFinish={handleAddInstituteBranch}
        initialValues={{
          branchName: record?.branchName,
          email: record?.email,
          country: record?.country,
          city: record?.city,
        }}
      >
        <Form.Item
          name="branchName"
          label="Branch Name"
          rules={[
            {
              required: true,
              message: "Branchname is required",
            },
          ]}
        >
          <Input placeholder="Branchname" />
        </Form.Item>
        <Form.Item
          //   style={{ gridColumn: "span 2" }}
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Email is required",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item name="country" label="Country">
          <Select placeholder="Select Country">
            {leadsDropdown?.countries?.map((country) => (
              <Select.Option value={country?.countryName}>
                {country?.countryName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="city" label="City">
          <Input placeholder="City" />
        </Form.Item>
        <span></span>
        <Button type="primary" htmlType="submit">
          {type} Branch
        </Button>
      </Form>
    </Modal>
  );
}
