import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const BranchLogin = ({
  isBranchLoginModalVisible,
  setIsBranchLoginModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  return (
    <Modal
      title="Branch Login"
      open={isBranchLoginModalVisible}
      onCancel={() => {
        setIsBranchLoginModalVisible(false);
      }}
      footer={null}
    ></Modal>
  );
};

export default BranchLogin;
