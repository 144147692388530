import SignupReducer from "./Reducers/Signup/Signup";
import LoginReducer from "./Reducers/Login";
import PasswordResetReducer from "./Reducers/PasswordReset";
import ContactsReducer from "./Reducers/Contacts";
import LeadReducer from "./Reducers/Leads";
import CompaniesReducer from "./Reducers/Companies";
import InstituteReducer from "./Reducers/Education/Institute";
import CoursesReducer from "./Reducers/Education/Courses";
import ProfileReducer from "./Reducers/Profile/Profile";
import CounsellingReducer from "./Reducers/Counselling";
import AppointmentsReducer from "./Reducers/Appointments";
import HeadAgentReducer from "./Reducers/HeadAgent";
import SubAgentReducer from "./SubAgent";
import SupportReducer from "./Reducers/Support";
import RoleAndPermissionReducer from "./Reducers/RolesAndPermission/RolesAndPermission";
import DepartmentReducer from "./Reducers/ManageEmployee/Department";
import ConsultancyReducer from "./Reducers/Consultancy";
import EmployeeReducer from "./Reducers/ManageEmployee/Employee";
import ResourcesReducer from "./Reducers/Resources/Resources";
import ClientsReducer from "./Reducers/Clients";
import TaskReducer from "./Reducers/Task";
import VisasReducer from "./Reducers/Visas";
import SettingsReducer from "./Reducers/Settings";
import DashboardReducer from "./Reducers/Dashboard";
import CollegeFinderReducer from "./Reducers/CollegeFinder";
import VisaClassReducer from "./Reducers/VisaClass";
import TodoReducer from "./Todo";
import AttendanceReducer from "./Reducers/Attendance";
import MyCounsellingAndCasesReducer from "./Reducers/MyCounsellingAndCases";
import BranchReducer from "./Reducers/BranchReducer";
import TermsAndPrivacyReducer from "./Reducers/TermsAndPrivacyReducer";
import OrganizationSettingsReducer from "./Reducers/OrgnizationSettings/OrganizationSettingsReducer";
import MailboxReducer from "./Reducers/Mailbox/MailboxReducer";
import DegreeReducer from "./Reducers/Education/Degree";
import CampaignReducer from "./Reducers/Campaign";
import QuotationReducer from "./Reducers/Quotation/QuotationReducer";
import QuickInquiryReducer from "./Reducers/QuickInquiry";
import DocumentCheckListReducer from "./Reducers/DocumentChecklist/DocumentChecklist";
import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import {
  checkDocumentNameEpic,
  changeNewPasswordEpic,
  FetchEmailLogDetailsEpic,
  searchNewActiveInstitutesEpic,
  approveQuoteEpic,
  updateTaskStatusEpic,
  fetchVisasDocumentByIdEpic,
  AddSubclassDocumentpic,
  AddInstituteFormFileEpic,
  generatePaymentPlanByCaseEpic,
  fetchCasePaymentPlanByIdEpic,
  fetchTaskDropdownEpic,
  editInstituteBranchEpic,
  fetchInstituteBranchesEpic,
  addInstituteBranchEpic,
  fetchDegreeByInstituteIdEpic,
  superadminGetVisaSubclassEpic,
  fetchCompanyByIdEpic,
  updatePackageDeactivateEpic,
  updatePackageActiveEpic,
  updateCompanyPackageEpic,
  fetchEductionVisasByIdEpic,
  fetchInstituteByIdEpic,
  addUpdateCourseCommissionEpic,
  addEditInstituteAgreementDate,
  UpdateQuotationStatusEpic,
  fetchAllEmailTemplateEpic,
  fetchServiceEmailTemplateEpic,
  addCaseAsignToEmailEpic,
  fetchProductServiceActivityLogEpic,
  updateServiceStatusEpic,
  fetchProductServiceByIdEpic,
  updateServiceEpic,
  fetchProductServiceEpic,
  addServiceEpic,
  fetchServiceSourceEpic,
  addServiceSourceEpic,
  fetchVisasSubclassEpic,
  fetchQuotationEmailTemplateEpic,
  getQoutePdfFileEpic,
  superadminGetDocumentChecklistEpic,
  updateDocumentChecklistEpic,
  getdocumentChecklistEpic,
  documentChecklistEpic,
  addContactEpic,
  authenticateUserEpic,
  fetchActiveContactEpic,
  fetchContactEpic,
  updateContactEpic,
  deleteContactEpic,
  fetchLeadsEpic,
  fetchLeadsDropdownEpic,
  addLeadsEpic,
  deleteLeadEpic,
  addAboutUsEpic,
  addLeadSourceEpic,
  addLeadStatusEpic,
  addPurposeEpic,
  updateLeadEpic,
  fetchCompaniesEpic,
  fetchActiveCompaniesEpic,
  updateCompaniesEpic,
  deleteCompaniesEpic,
  changeCompanyStatusEpic,
  addCompanyEpic,
  fetchInstituteEpic,
  fetchActiveInstituteEpic,
  AddInstituteEpic,
  registerEmailEpic,
  otpVerificationEpic,
  signupEpic,
  resendOtpEpic,
  forgotPasswordOtpverificationEpic,
  forgotPasswordOtpEpic,
  changePasswordEpic,
  addCountryEpic,
  fetchCoursesEpic,
  fetchCourseByIdEpic,
  fetchActiveCoursesEpic,
  UpdateInstituteEpic,
  deleteInstitueEpic,
  AddCourseEpic,
  FetchProfileEpic,
  updateProfileEpic,
  fetchCounsellingEpic,
  addCounsellingEpic,
  addConsultantEpic,
  addReferralEpic,
  fetchAppointmentsEpic,
  updateAppointmentEpic,
  addAppointmentEpic,
  changeAppointmentStatusEpic,
  headAgentEpic,
  addHeadAgentEpic,
  updateHeadAgentEpic,
  deleteHeadAgentEpic,
  toggleHeadAgentDisabledEpic,
  fetchActiveHeadAgentEpic,
  fetchSubAgentEpic,
  fetchActiveSubAgentEpic,
  addSubAgentEpic,
  updateSubAgentEpic,
  toggleSubAgentStatusEpic,
  fetchSupportTicketEpic,
  updateCounsellingEpic,
  fetchSupportEpic,
  replyToSupportTicketEpic,
  fetchTicketByIdEpic,
  addSupportEpic,
  toggleCourseStatusEpic,
  toggleCompanyStatusEpic,
  addLevelOfCollegeEpic,
  fetchAllRolesEpic,
  fetchActiveRolesEpic,
  addRoleEpic,
  fetchPermissionsEpic,
  updatePermissionEpic,
  changeContactStatusEpic,
  AddCampaignEpic,
  fetchDepartmentsEpic,
  addDepartmentEpic,
  updateDepartmentEpic,
  deleteDepartmentEpic,
  activateDepartmentEpic,
  deactivateDepartmentEpic,
  fetchActiveDepartmentsEpic,
  assignRolesToDepartmentEpic,
  fetchActiveAppointmentEpic,
  deleteAppointmentEpic,
  fetchEmployeesEpic,
  fetchActiveEmployeeEpic,
  updateEmployeeEpic,
  fetchConsultancyEpic,
  fetchActiveConsultancyEpic,
  addConsultancyEpic,
  fetchAssignedByTaskEpic,
  fetchAssignedToTaskEpic,
  addtaskEpic,
  updateTaskEpic,
  fetchPrivateDriveEpic,
  fetchPublicDriveEpic,
  fetchResourceByIdEpic,
  fetchPrivateResourceByIdEpic,
  AddNewFolderEpic,
  renameFolderEpic,
  deleteFolderEpic,
  fileUploadEpic,
  fetchClientsEpic,
  employeeActivateConfirmationEpic,
  employeeDeactivateConfirmationEpic,
  timingScheduleDropdownEpic,
  addStateEpic,
  fetchActiveCounsellingEpic,
  addScheduleEpic,
  deleteCounsellingEpic,
  changeCounsellingStatusEpic,
  fetchVisaEpic,
  fetchCasesDropdownEpic,
  addVisaSubCategoryEpic,
  addSalesRepNameEpic,
  AddDegreeEpic,
  AddIntakeEpic,
  addLeadTypeEpic,
  addOrderStatusEpic,
  addVisaCaseEpic,
  fetchCountryEpic,
  addMigrationWorkflowEpic,
  fetchMigrationWorkflowEpic,
  deleteMigrationWorkflowEpic,
  updateMigrationWorkflowEpic,
  setWorkflowAsDefaultEpic,
  addDocumentEpic,
  fetchDocumentsEpic,
  updateDocumentEpic,
  deleteDocumentEpic,
  fetchEducationWorkflowEpic,
  addEducationWorkflowEpic,
  setEducationWorkflowAsDefaultEpic,
  addClientEpic,
  addCustomerPhotoEpic,
  fetchDocumentTypesEpic,
  addDocumentTypeEpic,
  addClientQualificationEpic,
  addClientDocumentEpic,
  fetchPackageEpic,
  fetchActiveClientsEpic,
  deleteClientEpic,
  addCasePackageEpic,
  updateCaseStatusEpic,
  uploadContactImageEpic,
  fetchLeadByIdEpic,
  addSupportCategoryEpic,
  fetchActiveSupportCategoryEpic,
  updateLeadStatusEpic,
  updateRoleEpic,
  fetchCountryWorkflowByIdEpic,
  fetchCourcesDocumentChecklistEpic,
  addCoursesDocumentChecklistEpic,
  updateCoursesDocumentChecklistEpic,
  fetchWorkflowByIdEpic,
  addWorkflowStatusEpic,
  updateWorkflowStatusEpic,
  fetchWorkflowStatusByIdEpic,
  fetchActiveCasePackageEpic,
  fetchCoursesByInstituteAndDegreeEpic,
  changeWorkflowStatusAsFirstEpic,
  changeWorkflowStatusAsFinalEpic,
  fetchCaseLogByWorkflowDtoEpic,
  fetchWorkflowStatusesByWorkflowIdEpic,
  updateCaseStatusByWorkflowIdEpic,
  updateVisaOutcomeStatusEpic,
  uploadCaseDocumentsEpic,
  fetchDashboardDataEpic,
  updateCourseEpic,
  uploadCountryImageEpic,
  changeCountryStatusEpic,
  fetchActiveCountriesEpic,
  purchaseSubscriptionEpic,
  updateWorkflowStatusPriorityEpic,
  uploadURLfromUploadResponseEpic,
  fetchFilterVisaEpic,
  changeStartCounsellingEpic,
  fetchCustomerLeadByIdEpic,
  fetchCustomerAppointmentByIdEpic,
  uploadInstituteImageEpic,
  uploadInstituteDocumentEpic,
  deleteInstituteDocumentsEpic,
  uploadCourseDocumentEpic,
  fetchAllCountryEpic,
  fetchAllDegreeEpic,
  logoutEpic,
  fetchAllCourseEpic,
  fetchAllIntakeEpic,
  changeClientStatusEpic,
  updateCustomerInformatiionEpic,
  updateCustomerCourseEpic,
  updateCustomerQualificationEpic,
  addCustomerQualificationEpic,
  addCustomerDocumentEpic,
  deleteCustomerQualificationEpic,
  deleteCustomerExperienceEpic,
  deleteCustomerDocumentEpic,
  editCustomerExperienceEpic,
  editCustomerQualificationsEpic,
  fetchEductionVisasEpic,
  fetchMigrationVisasEpic,
  addEducationVisaEpic,
  addMigrationVisaEpic,
  updateEducationVisasEpic,
  updateMigrationVisasEpic,
  deleteMigrationVisaEpic,
  deleteEducationVisaEpic,
  fetchPendingAppointmentsEpic,
  fetchConfirmedAppointmentsEpic,
  fetchCompletedAppointmentsEpic,
  fetchNotAssignedLeadsEpic,
  fetchInProgressLeadsEpic,
  fetchCompletedLeadsEpic,
  assignAgentsEpic,
  fetchAllCollegeEpic,
  fetchClientByIdEpic,
  fetchMigrationVisaCasesEpic,
  fetchEducationVisaCasesEpic,
  startCounsellingEpic,
  fetchInstituteBasedOnLocationTypeEpic,
  fetchDegreeByInstituteFilterEpic,
  addTodoEpic,
  fetchTodoEpic,
  changeTodoStatusEpic,
  fetchActiveConsultantsEpic,
  fetchCompletedFilterVisaEpic,
  fetchNotAssignedFilterVisaEpic,
  fetchInProgressFilterVisaEpic,
  fetchAttendanceEpic,
  checkInEpic,
  changeWorkflowStatusAsCancelledEpic,
  assignDepartmentEpic,
  fetchMyCounsellingEpic,
  fetchMyCasesEpic,
  // fetchMyCasesInProgressEpic,
  // fetchMyCasesCompletedEpic,
  fetchCustomerCasesByIdEpic,
  changeProfileImageEpic,
  fetchBranchEpic,
  addBranchEpic,
  updateBranchEpic,
  fetchPrivacyPolicyEpic,
  searchContactsEpic,
  searchLeadEpic,
  searchAppointmentEpic,
  searchCasesEpic,
  searchClientsEpic,
  fetchEmailTemplateEpic,
  searchActiveContactsEpic,
  searchNotAssignedLeadEpic,
  searchInProgressLeadEpic,
  searchCompletedLeadEpic,
  searchPendingAppointmentEpic,
  searchConfirmedAppointmentEpic,
  searchCompletedAppointmentEpic,
  searchActiveClientsEpic,
  searchEmployeesEpic,
  searchActiveEmployeesEpic,
  searchActiveConsultantsEpic,
  searchInstituesEpic,
  searchActiveInstitutesEpic,
  searchCoursesEpic,
  searchActiveCoursesEpic,
  searchActiveHeadAgentEpic,
  searchHeadAgentEpic,
  searchActiveSubAgentEpic,
  searchSubAgentEpic,
  searchCompaniesEpic,
  searchActiveCompaniesEpic,
  searchTaskEpic,
  searchInProgressCasesEpic,
  searchNotAssignedCasesEpic,
  searchCompletedCasesEpic,
  fetchCaseEmailTemplateEpic,
  fetchAppointmentEmailTemplateEpic,
  fetchClientEmailTemplateEpic,
  fetchLeadEmailTemplateEpic,
  addEmailTemplateEpic,
  changeCompanyLogoEpic,
  updateDashboardSettingsEpic,
  checkOutEpic,
  importDropdownDataFromHeadOfficeEpic,
  fetchReportEpic,
  fetchTodayAssignedTaskEpic,
  fetchAttandanceOfAllEmployeeEpic,
  updatePrefixEpic,
  fetchMailboxSentEpic,
  fetchMailboxInboxEpic,
  sendComposedEmailEpic,
  replyToEmailEpic,
  fetchMailboxDraftEpic,
  addMailToDraftEpic,
  deleteDraftEpic,
  markMailAsSeenEpic,
  updateReminderExpiryWorkingHourEpic,
  fetchReminderExpiryWorkingHourEpic,
  markCountryAsDefaultEpic,
  searchInstituteForCoursesEpic,
  fetchAvailableEmailEpic,
  fetchClientActivityLogByIdEpic,
  setDefaultCurrencyEpic,
  downloadCSVEpic,
  downloadTaskCSVEpic,
  downleadLeadCSVEpic,
  fetchDegreeEpic,
  fetchActiveDegreeEpic,
  changeDegreeStatusEpic,
  updateDegreeEpic,
  deleteDegreeEpic,
  fetchCampaignEpic,
  fetchActiveCampaignEpic,
  changeCampaignStatusEpic,
  updateCampaignEpic,
  addLeadFromCampaignEpic,
  fetchQRcodeOfCampaignEpic,
  fetchFormDetailsOfCampaignEpic,
  fetchCampainFormDropdownEpic,
  deleteSubagentEpic,
  updateAutoreminderSettingsEpic,
  fetchAutoReminderDataEpic,
  verifyPurchaseEpic,
  changeTimezoneEpic,
  fetchTodayRemindersEpic,
  fetchNotificationEpic,
  globalSearchFromDashboardEpic,
  fetchLeadActivityLogByIdEpic,
  importCLientCsvFileEpic,
  importLeadCsvFileEpic,
  importTaskCsvFileEpic,
  addAdditionalEmailsFrmGSEpic,
  searchInstitutesFrmAdminEpic,
  importInstituteFromAdminEpic,
  addOpeningScheduleEpic,
  importInstitutesFromAdminEpic,
  fetchAppointmentsByLeadIdEpic,
  fetchDashboardDataListEpic,
  fetchAppointmentByIdEpic,
  addCourseWishlistForLeadIdEpic,
  fetchCourseWishlistByLeadIdEpic,
  deleteCourseFromWishlistEpic,
  checkForExistingEmailEpic,
  checkForExistingMobileEpic,
  statusHistoryStatusUpdateEpic,
  deleteIntakeFromCourseEpic,
  fetchCourseForAnInstituteEpic,
  fetchCaseByIdEpic,
  deleteBonusFromInstituteEpic,
  fetchConsultantsEpic,
  addAppointmentFormFieldsForCampaignEpic,
  fetchQRcodeForAppointmentFormOfCampaignEpic,
  fetchAppointmentFormDetailsOfCampaignEpic,
  fetchTimingDropdownForCampaignEpic,
  addAppointmentFromCampaignEpic,
  fetchCompanyLogoAndFooterDetailsEpic,
  fetchAllLeadStatusesEpic,
  setLeadStatusAsDefaultEpic,
  fetchActiveBranchEpic,
  changeBranchStatusEpic,
  fetchCounsellorForGivenDateEpic,
  fetchOthersWorkflowEpic,
  addOthersWorkflowEpic,
  setOthersWorkflowAsDefaultEpic,
  companyAuthenticateUserEpic,
  companyEmailAuthenticateUserEpic,
  companyDomainAuthenticateUserEpic,
  companyEmailValidateEpic,
  forgotPasswordUsingCompanyDomainOrURLEpic,
  forgotPasswordOtpVerificationByCompanyUrlEpic,
  addCompanyDomainUrlEpic,
  fetchCompanyDetaislsEpic,
  fetchQuotationEpic,
  addQuotationEpic,
  addTimelineEpic,
  fetchTimelineEpic,
  approveQuotationEpic,
  fetchQuotationByIdEpic,
  fetchQRcodeForConsultantOfAppointmentFormEpic,
  downloadQuotationEpic,
  addQuotationSourceEpic,
  fetchQuotationSourceEpic,
  fetchQuotationByCustomerIdEpic,
  updateQuotationEpic,
  fetchConsultantByAppointmentDateEpic,
  AddPermissionOfEmployeeEpic,
  AddPermissionOfDepartmentEpic,
  fetchWorkingDaysOfCounsellorEpic,
  searchConsultantsEpic,
  fetchAllQuickInquiryEpic,
  fetchQuickInquiryByIdEpic,
  fetchQuickInquiryWithStatusEpic,
  acceptQuickInquiryEpic,
  declineQuickInquiryEpic,
  fetchPendingQuickInquiryEpic,
  fetchAcceptedQuickInquiryEpic,
  fetchDeclinedQuickInquiryEpic,
  fetchCoursesListByInstituteAndDegreeEpic,
  updateCaseDataByWorkflowIdEpic,
} from "./myEpics";
export const rootReducer = combineReducers({
  DocumentCheckListReducer,
  SignupReducer,
  PasswordResetReducer,
  LoginReducer,
  ContactsReducer,
  LeadReducer,
  CompaniesReducer,
  InstituteReducer,
  CoursesReducer,
  ProfileReducer,
  CounsellingReducer,
  AppointmentsReducer,
  HeadAgentReducer,
  SubAgentReducer,
  SupportReducer,
  RoleAndPermissionReducer,
  DepartmentReducer,
  EmployeeReducer,
  ConsultancyReducer,
  TaskReducer,
  ResourcesReducer,
  ClientsReducer,
  VisasReducer,
  SettingsReducer,
  DashboardReducer,
  CollegeFinderReducer,
  VisaClassReducer,
  TodoReducer,
  AttendanceReducer,
  MyCounsellingAndCasesReducer,
  BranchReducer,
  TermsAndPrivacyReducer,
  OrganizationSettingsReducer,
  MailboxReducer,
  DegreeReducer,
  CampaignReducer,
  QuotationReducer,
  QuickInquiryReducer,
});

export const rootEpic = combineEpics(
  superadminGetDocumentChecklistEpic,
  updateDocumentChecklistEpic,
  getdocumentChecklistEpic,
  documentChecklistEpic,
  forgotPasswordOtpEpic,
  forgotPasswordUsingCompanyDomainOrURLEpic,

  forgotPasswordOtpverificationEpic,
  forgotPasswordOtpVerificationByCompanyUrlEpic,

  changePasswordEpic,
  registerEmailEpic,
  otpVerificationEpic,
  resendOtpEpic,
  signupEpic,
  authenticateUserEpic,
  companyAuthenticateUserEpic,
  companyEmailAuthenticateUserEpic,
  companyDomainAuthenticateUserEpic,
  companyEmailValidateEpic,
  addContactEpic,
  fetchContactEpic,
  fetchActiveContactEpic,
  updateContactEpic,
  deleteContactEpic,
  changeContactStatusEpic,
  AddCampaignEpic,
  uploadContactImageEpic,

  fetchLeadsEpic,
  fetchNotAssignedLeadsEpic,
  fetchInProgressLeadsEpic,
  fetchCompletedLeadsEpic,
  fetchLeadByIdEpic,
  fetchLeadsDropdownEpic,
  addLeadsEpic,
  updateLeadStatusEpic,
  updateLeadEpic,
  deleteLeadEpic,
  addAboutUsEpic,
  addCountryEpic,
  uploadCountryImageEpic,
  changeCountryStatusEpic,
  addLeadSourceEpic,
  addLeadStatusEpic,
  addPurposeEpic,
  addConsultantEpic,
  addReferralEpic,

  fetchCompaniesEpic,
  fetchActiveCompaniesEpic,
  updateCompaniesEpic,
  deleteCompaniesEpic,
  changeCompanyStatusEpic,
  addCompanyEpic,

  fetchInstituteEpic,
  fetchActiveInstituteEpic,
  AddInstituteEpic,
  uploadInstituteImageEpic,
  uploadInstituteDocumentEpic,
  deleteInstituteDocumentsEpic,
  UpdateInstituteEpic,
  deleteInstitueEpic,
  toggleCompanyStatusEpic,
  addLevelOfCollegeEpic,

  fetchCoursesEpic,
  fetchActiveCoursesEpic,
  AddCourseEpic,
  uploadCourseDocumentEpic,
  updateCourseEpic,
  toggleCourseStatusEpic,
  fetchCourcesDocumentChecklistEpic,
  addCoursesDocumentChecklistEpic,
  updateCoursesDocumentChecklistEpic,

  FetchProfileEpic,
  updateProfileEpic,

  fetchCounsellingEpic,
  fetchActiveCounsellingEpic,
  addCounsellingEpic,
  updateCounsellingEpic,
  deleteCounsellingEpic,
  changeCounsellingStatusEpic,
  addStateEpic,

  fetchAppointmentsEpic,
  fetchActiveAppointmentEpic,
  fetchPendingAppointmentsEpic,
  fetchConfirmedAppointmentsEpic,
  fetchCompletedAppointmentsEpic,
  addAppointmentEpic,
  updateAppointmentEpic,
  deleteAppointmentEpic,
  timingScheduleDropdownEpic,

  headAgentEpic,
  fetchActiveHeadAgentEpic,
  addHeadAgentEpic,
  updateHeadAgentEpic,
  deleteHeadAgentEpic,
  toggleHeadAgentDisabledEpic,

  fetchSubAgentEpic,
  fetchActiveSubAgentEpic,
  addSubAgentEpic,
  updateSubAgentEpic,
  toggleSubAgentStatusEpic,
  deleteSubagentEpic,

  fetchSupportTicketEpic,
  fetchSupportEpic,
  addSupportEpic,
  replyToSupportTicketEpic,
  fetchTicketByIdEpic,
  addSupportCategoryEpic,
  fetchActiveSupportCategoryEpic,

  fetchAllRolesEpic,
  fetchActiveRolesEpic,
  addRoleEpic,
  updateRoleEpic,
  fetchPermissionsEpic,
  updatePermissionEpic,

  fetchDepartmentsEpic,
  fetchActiveDepartmentsEpic,
  addDepartmentEpic,
  updateDepartmentEpic,
  deleteDepartmentEpic,
  activateDepartmentEpic,
  deactivateDepartmentEpic,
  assignRolesToDepartmentEpic,
  AddPermissionOfDepartmentEpic,

  fetchEmployeesEpic,
  fetchActiveEmployeeEpic,
  fetchActiveConsultantsEpic,
  updateEmployeeEpic,
  employeeActivateConfirmationEpic,
  employeeDeactivateConfirmationEpic,
  assignDepartmentEpic,
  AddPermissionOfEmployeeEpic,
  addScheduleEpic,

  fetchConsultancyEpic,
  fetchActiveConsultancyEpic,
  addConsultancyEpic,

  fetchAssignedToTaskEpic,
  fetchAssignedByTaskEpic,
  addtaskEpic,
  updateTaskEpic,

  fetchPrivateDriveEpic,
  fetchPublicDriveEpic,
  fetchResourceByIdEpic,
  fetchPrivateResourceByIdEpic,
  AddNewFolderEpic,
  renameFolderEpic,
  deleteFolderEpic,
  fileUploadEpic,

  fetchClientsEpic,
  fetchActiveClientsEpic,
  deleteClientEpic,
  fetchDocumentTypesEpic,
  addDocumentTypeEpic,
  addClientQualificationEpic,
  addClientDocumentEpic,
  changeClientStatusEpic,

  fetchVisaEpic,
  fetchCompletedFilterVisaEpic,
  fetchNotAssignedFilterVisaEpic,
  fetchInProgressFilterVisaEpic,
  fetchCasesDropdownEpic,
  fetchCaseLogByWorkflowDtoEpic,
  fetchWorkflowStatusesByWorkflowIdEpic,
  updateCaseStatusByWorkflowIdEpic,
  updateVisaOutcomeStatusEpic,
  addVisaSubCategoryEpic,
  uploadCaseDocumentsEpic,
  uploadURLfromUploadResponseEpic,

  addSalesRepNameEpic,
  AddDegreeEpic,
  AddIntakeEpic,
  addLeadTypeEpic,
  addOrderStatusEpic,
  addVisaCaseEpic,
  fetchInstituteBasedOnLocationTypeEpic,
  fetchDegreeByInstituteFilterEpic,
  fetchCountryWorkflowByIdEpic,
  fetchActiveCasePackageEpic,
  addCasePackageEpic,
  updateCaseStatusEpic,
  fetchCoursesByInstituteAndDegreeEpic,

  fetchCountryEpic,
  fetchActiveCountriesEpic,
  markCountryAsDefaultEpic,
  fetchWorkflowByIdEpic,
  addWorkflowStatusEpic,
  updateWorkflowStatusEpic,
  fetchWorkflowStatusByIdEpic,
  addMigrationWorkflowEpic,
  fetchMigrationWorkflowEpic,
  deleteMigrationWorkflowEpic,
  updateMigrationWorkflowEpic,
  setWorkflowAsDefaultEpic,
  addDocumentEpic,
  fetchDocumentsEpic,
  updateDocumentEpic,
  deleteDocumentEpic,
  changeWorkflowStatusAsFirstEpic,
  changeWorkflowStatusAsFinalEpic,
  changeWorkflowStatusAsCancelledEpic,

  fetchEducationWorkflowEpic,
  addEducationWorkflowEpic,
  setEducationWorkflowAsDefaultEpic,
  addClientEpic,
  addCustomerPhotoEpic,

  fetchPackageEpic,
  fetchDashboardDataEpic,

  purchaseSubscriptionEpic,
  updateWorkflowStatusPriorityEpic,

  changeStartCounsellingEpic,
  fetchCustomerLeadByIdEpic,
  fetchCustomerAppointmentByIdEpic,
  fetchCustomerCasesByIdEpic,

  fetchAllCountryEpic,
  fetchAllDegreeEpic,
  fetchAllCourseEpic,
  fetchAllIntakeEpic,
  fetchAllCollegeEpic,

  fetchClientByIdEpic,
  fetchClientActivityLogByIdEpic,
  updateCustomerInformatiionEpic,
  updateCustomerCourseEpic,
  updateCustomerQualificationEpic,
  addCustomerQualificationEpic,
  addCustomerDocumentEpic,
  deleteCustomerQualificationEpic,
  deleteCustomerExperienceEpic,
  deleteCustomerDocumentEpic,
  editCustomerExperienceEpic,
  editCustomerQualificationsEpic,

  fetchEductionVisasEpic,
  fetchMigrationVisasEpic,
  addEducationVisaEpic,
  addMigrationVisaEpic,
  updateEducationVisasEpic,
  updateMigrationVisasEpic,
  deleteMigrationVisaEpic,
  deleteEducationVisaEpic,
  assignAgentsEpic,

  fetchMigrationVisaCasesEpic,
  fetchEducationVisaCasesEpic,
  startCounsellingEpic,
  addTodoEpic,
  fetchTodoEpic,
  changeTodoStatusEpic,

  fetchAttendanceEpic,
  checkInEpic,
  checkOutEpic,
  fetchReportEpic,
  fetchTodayAssignedTaskEpic,
  fetchAttandanceOfAllEmployeeEpic,

  fetchMyCounsellingEpic,
  // fetchMyCasesInProgressEpic,
  // fetchMyCasesCompletedEpic,

  changeProfileImageEpic,
  fetchBranchEpic,
  addBranchEpic,
  updateBranchEpic,

  fetchPrivacyPolicyEpic,

  searchContactsEpic,
  searchActiveContactsEpic,
  searchLeadEpic,
  searchNotAssignedLeadEpic,
  searchInProgressLeadEpic,
  searchCompletedLeadEpic,
  searchAppointmentEpic,
  searchPendingAppointmentEpic,
  searchConfirmedAppointmentEpic,
  searchCompletedAppointmentEpic,
  searchCasesEpic,
  searchInProgressCasesEpic,
  searchNotAssignedCasesEpic,
  searchCompletedCasesEpic,
  searchClientsEpic,
  searchActiveClientsEpic,
  searchEmployeesEpic,
  searchActiveEmployeesEpic,
  searchActiveConsultantsEpic,
  searchConsultantsEpic,
  searchInstituesEpic,
  searchActiveInstitutesEpic,
  searchCoursesEpic,
  searchActiveCoursesEpic,
  searchHeadAgentEpic,
  searchActiveHeadAgentEpic,
  searchSubAgentEpic,
  searchActiveSubAgentEpic,
  searchCompaniesEpic,
  searchActiveCompaniesEpic,
  searchTaskEpic,

  fetchLeadEmailTemplateEpic,
  fetchCaseEmailTemplateEpic,
  fetchAppointmentEmailTemplateEpic,
  fetchClientEmailTemplateEpic,
  addEmailTemplateEpic,

  changeCompanyLogoEpic,
  updateDashboardSettingsEpic,

  importDropdownDataFromHeadOfficeEpic,
  updatePrefixEpic,
  fetchReminderExpiryWorkingHourEpic,
  updateReminderExpiryWorkingHourEpic,

  fetchMailboxSentEpic,
  fetchMailboxInboxEpic,
  fetchMailboxDraftEpic,
  sendComposedEmailEpic,
  replyToEmailEpic,
  addMailToDraftEpic,
  deleteDraftEpic,
  markMailAsSeenEpic,
  fetchAvailableEmailEpic,

  searchInstituteForCoursesEpic,
  setDefaultCurrencyEpic,
  downloadCSVEpic,
  downloadTaskCSVEpic,
  downleadLeadCSVEpic,

  fetchDegreeEpic,
  fetchActiveDegreeEpic,
  changeDegreeStatusEpic,
  updateDegreeEpic,
  deleteDegreeEpic,

  fetchCampaignEpic,
  fetchActiveCampaignEpic,
  changeCampaignStatusEpic,
  updateCampaignEpic,
  addLeadFromCampaignEpic,
  fetchQRcodeOfCampaignEpic,
  fetchFormDetailsOfCampaignEpic,
  fetchCampainFormDropdownEpic,

  updateAutoreminderSettingsEpic,
  fetchAutoReminderDataEpic,
  verifyPurchaseEpic,

  changeTimezoneEpic,
  fetchTodayRemindersEpic,
  fetchNotificationEpic,

  globalSearchFromDashboardEpic,
  fetchLeadActivityLogByIdEpic,
  importCLientCsvFileEpic,
  importLeadCsvFileEpic,
  importTaskCsvFileEpic,
  addAdditionalEmailsFrmGSEpic,
  searchInstitutesFrmAdminEpic,
  importInstituteFromAdminEpic,
  addOpeningScheduleEpic,
  importInstitutesFromAdminEpic,
  fetchAppointmentsByLeadIdEpic,
  fetchDashboardDataListEpic,
  fetchAppointmentByIdEpic,
  addCourseWishlistForLeadIdEpic,
  fetchCourseWishlistByLeadIdEpic,
  deleteCourseFromWishlistEpic,
  checkForExistingEmailEpic,
  checkForExistingMobileEpic,
  statusHistoryStatusUpdateEpic,
  deleteIntakeFromCourseEpic,
  fetchCourseForAnInstituteEpic,
  fetchCaseByIdEpic,
  deleteBonusFromInstituteEpic,
  fetchConsultantsEpic,
  addAppointmentFormFieldsForCampaignEpic,
  fetchQRcodeForAppointmentFormOfCampaignEpic,
  fetchAppointmentFormDetailsOfCampaignEpic,
  fetchTimingDropdownForCampaignEpic,
  addAppointmentFromCampaignEpic,
  fetchCompanyLogoAndFooterDetailsEpic,
  fetchAllLeadStatusesEpic,
  setLeadStatusAsDefaultEpic,
  fetchActiveBranchEpic,
  changeBranchStatusEpic,
  fetchCounsellorForGivenDateEpic,

  fetchOthersWorkflowEpic,
  addOthersWorkflowEpic,
  setOthersWorkflowAsDefaultEpic,
  addCompanyDomainUrlEpic,
  fetchCompanyDetaislsEpic,

  fetchQuotationEpic,
  addQuotationEpic,
  addTimelineEpic,
  fetchTimelineEpic,
  approveQuotationEpic,
  fetchQuotationByIdEpic,
  fetchQRcodeForConsultantOfAppointmentFormEpic,
  downloadQuotationEpic,
  addQuotationSourceEpic,
  fetchQuotationSourceEpic,
  fetchQuotationByCustomerIdEpic,
  updateQuotationEpic,
  fetchConsultantByAppointmentDateEpic,
  fetchWorkingDaysOfCounsellorEpic,

  fetchAllQuickInquiryEpic,
  fetchQuickInquiryByIdEpic,
  fetchPendingQuickInquiryEpic,
  fetchAcceptedQuickInquiryEpic,
  fetchDeclinedQuickInquiryEpic,
  acceptQuickInquiryEpic,
  declineQuickInquiryEpic,
  getQoutePdfFileEpic,
  fetchCoursesListByInstituteAndDegreeEpic,
  updateCaseDataByWorkflowIdEpic,
  fetchQuotationEmailTemplateEpic,
  fetchVisasSubclassEpic,
  addServiceSourceEpic,
  fetchServiceSourceEpic,
  addServiceEpic,
  fetchProductServiceEpic,
  updateServiceEpic,
  fetchProductServiceByIdEpic,
  updateServiceStatusEpic,
  fetchProductServiceActivityLogEpic,
  addCaseAsignToEmailEpic,
  fetchServiceEmailTemplateEpic,
  fetchAllEmailTemplateEpic,
  UpdateQuotationStatusEpic,
  fetchCourseByIdEpic,
  addEditInstituteAgreementDate,
  addUpdateCourseCommissionEpic,
  fetchInstituteByIdEpic,
  fetchEductionVisasByIdEpic,
  updateCompanyPackageEpic,
  updatePackageActiveEpic,
  updatePackageDeactivateEpic,
  fetchCompanyByIdEpic,
  superadminGetVisaSubclassEpic,
  fetchDegreeByInstituteIdEpic,
  addInstituteBranchEpic,
  fetchInstituteBranchesEpic,
  editInstituteBranchEpic,
  fetchTaskDropdownEpic,
  fetchCasePaymentPlanByIdEpic,
  generatePaymentPlanByCaseEpic,
  AddInstituteFormFileEpic,
  AddSubclassDocumentpic,
  fetchVisasDocumentByIdEpic,
  updateTaskStatusEpic,
  approveQuoteEpic,
  searchNewActiveInstitutesEpic,
  FetchEmailLogDetailsEpic,
  changeNewPasswordEpic,
  checkDocumentNameEpic
);
