import {
  Avatar,
  Button,
  Drawer,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Segmented,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import allContacts from "../../Assets/account.png";
import activeContacts from "../../Assets/add-contact.png";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AddContacts from "./AddContacts";
import ContactDetails from "./ContactDetails";
import AddCampaign from "./AddCampaign";
import {
  AiOutlineEye,
  AiOutlineMail,
  AiOutlinePlus,
  AiOutlinePoweroff,
  AiOutlineUserAdd,
} from "react-icons/ai";
import { MdOutlineDisabledVisible } from "react-icons/md";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import AddLeadSource from "../Leads/LeadSource/AddLeadSource";
import AddLead from "../Leads/AddLead";
import { BiBlock } from "react-icons/bi";
import { CiMobile3 } from "react-icons/ci";
import AddClient from "../Clients/AddClient";
import AddCountry from "../Leads/Country/AddCountry";
import SendComposedMail from "../Mailbox/SendComposedMail";
const { Option } = Select;
const { Search } = Input;

const columns = [
  {
    title: "Full Name",
    dataIndex: "firstName",
    sorter: (a, b) => {
      if (
        a?.firstName?.props?.children?.props?.children?.props?.children <
        b?.firstName?.props?.children?.props?.children?.props?.children
      ) {
        return -1;
      }
      if (
        a?.firstName?.props?.children?.props?.children?.props?.children >
        b?.firstName?.props?.children?.props?.children?.props?.children
      ) {
        return 1;
      }
      return 0;
    },
    width: 350,
    align: "center",
  },
  {
    title: "Image",
    dataIndex: "contactImage",
    width: 250,
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    sorter: (a, b) => {
      if (a.email < b.email) {
        return -1;
      }
      if (a.email > b.email) {
        return 1;
      }
      return 0;
    },
    width: 250,
    align: "center",
  },
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
    sorter: (a, b) => {
      if (a.phoneNumber < b.phoneNumber) {
        return -1;
      }
      if (a.phoneNumber > b.phoneNumber) {
        return 1;
      }
      return 0;
    },
    width: 250,
    align: "center",
  },
  {
    title: "Source",
    dataIndex: "source",
    width: 250,
    sorter: (a, b) => {
      if (a.source < b.source) {
        return -1;
      }
      if (a.source > b.source) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Campaign",
    dataIndex: "campaign",
    sorter: (a, b) => {
      if (a.campaign < b.campaign) {
        return -1;
      }
      if (a.campaign > b.campaign) {
        return 1;
      }
      return 0;
    },
    width: 150,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "activeStatus",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
    fixed: "right",
  },
];

const Contacts = ({ isHeadBranch, branchRecord }) => {
  const store = useSelector((state) => {
    return {
      contactState: state.ContactsReducer,
      leadState: state.LeadReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const [searchValue, setSearchValue] = useState("");
  const [isAddContactVisible, setIsAddContactVisible] = useState(false);
  const [contactsType, setContactsType] = useState("list-all");
  const [isUpdateContactsModelVisible, setIsUpdateContactsModelVisible] =
    useState(false);
  const [isAddClientModalVisible, setIsAddClientModalVisible] = useState(false);

  const [isContactsDetailsModelVisible, setIsContactsDetailsModelVisible] =
    useState(false);

  const [isAddSourceModalVisible, setIsAddSourceModalVisible] = useState(false);
  const [isAddCampaignModalVisible, setIsAddCampaignModalVisible] =
    useState(false);
  const [isAddLeadModelVisible, setIsAddLeadModelVisible] = useState(false);
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [contactsData, setContactsData] = useState({
    campaign: "",
    source: "",
    phoneNumber: "",
    email: "",
    lastName: "",
    firstName: "",
    countryOfResidence: "",
  });
  const [record, setRecord] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onSearch = (e) => {
    if (contactsType === "get-active-contact") {
      dispatch({
        type: "SEARCH_ACTIVE_CONTACTS_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else
      dispatch({
        type: "SEARCH_CONTACTS_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
  };
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  const dropdownList = () => {
    dispatch({ type: "FETCH_LEADS_DROPDOWN_REQUEST" });
  };
  useEffect(() => {
    dispatch({
      type: "FETCH_ACTIVE_CONTACTS_REQUEST",
      payload: {
        page,
        pageSize,
        branchId:
          store.branchState?.branchRecord.id ??
          localStorage.getItem("branchId"),
      },
    });
    dispatch({
      type: "FETCH_CONTACTS_REQUEST",
      payload: {
        page,
        pageSize,
        branchId:
          store.branchState?.branchRecord.id ??
          localStorage.getItem("branchId"),
      },
    });
  }, [
    contactsType,
    isAddContactVisible,
    page,
    pageSize,
    store.branchState?.branchRecord,
  ]);
  const uploadPhotoFn = (response) => {
    const contactFormData = new FormData();
    contactFormData.append("image", contactImage);
    contactImage !== undefined &&
      contactImage !== null &&
      dispatch({
        type: "UPLOAD_CONTACT_IMAGE_REQUEST",
        payload: {
          id: response.id,
          contactFormData,
          branchId: branchRecord.id,
        },
      });
    setContactImage(null);
  };
  const [contactImage, setContactImage] = useState();

  let data = [];
  if (contactsType === "list-all") {
    data = store.contactState.contacts?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        firstName:
          dataObj.firstName || dataObj.lastName ? (
            <Tooltip title="Contact Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsContactsDetailsModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  {dataObj.firstName ?? ""} {dataObj.lastName ?? ""}
                  {dataObj.isClient && (
                    <Tag
                      color="#2db7f5"
                      style={{ borderRadius: "10px", marginLeft: "3px" }}
                    >
                      Client
                    </Tag>
                  )}
                </div>
              </a>
            </Tooltip>
          ) : (
            "N/A"
          ),
        contactImage: dataObj.contactImage ? (
          <Image
            src={dataObj.contactImage}
            style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
          />
        ) : (
          "N/A"
        ),
        email: (
          <div className="flexColumnWithoutStyle">
            {dataObj.email !== "" && dataObj.email !== null ? (
              <Tooltip title="Send Email">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsSendComposedEmailModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <div
                      className="flexRowWithoutStyle"
                      style={{ gap: "2px", color: "000", color: "000" }}
                    >
                      <AiOutlineMail />
                      <a>{dataObj.email ?? "N/A"}</a>
                    </div>{" "}
                  </div>
                </a>
              </Tooltip>
            ) : (
              "N/A"
            )}
          </div>
        ),
        //  dataObj.email ? dataObj.email : "N/A",
        phoneNumber: (
          <div className="flexColumnWithoutStyle">
            {dataObj.phoneNumber !== "" && dataObj.phoneNumber !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000" }}
              >
                <CiMobile3 />
                <a href={`tel:${dataObj.phoneNumber}`}>
                  {dataObj.phoneNumber ?? "N/A"}
                </a>
              </div>
            ) : (
              "N/A"
            )}
          </div>
        ),
        source: dataObj.source?.name ? dataObj.source?.name : "N/A",
        campaign: dataObj.campaign?.name ? dataObj.campaign?.name : "N/A",
        activeStatus: dataObj.activeStatus ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {dataObj.activeStatus &&
            store.profileState?.profile?.department?.rolesAndPermissionList?.LEADS?.includes(
              "ADD"
            ) ? (
              <Tooltip title="Add Lead">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAddLeadModelVisible(true);
                    }}
                  >
                    <AiOutlinePlus />
                  </div>
                </a>
              </Tooltip>
            ) : null}
            {!dataObj.isClient &&
            store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
              "ADD"
            ) ? (
              <Tooltip title="Add Client">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAddClientModalVisible(true);
                    }}
                  >
                    <AiOutlineUserAdd />
                  </div>
                </a>
              </Tooltip>
            ) : null}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CONTACTS?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update Contacts">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateContactsModelVisible(true);
                      setRecord(dataObj);
                      setContactsData({
                        countryOfResidence: dataObj?.countryOfResidence,
                        campaign: dataObj?.campaign,
                        source: dataObj?.source,
                        phoneNumber: dataObj?.phoneNumber,
                        email: dataObj?.email,
                        lastName: dataObj?.lastName,
                        firstName: dataObj?.firstName,
                      });
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}

            <Tooltip title="Contact Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsContactsDetailsModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {!dataObj.activeStatus &&
            store.profileState?.profile?.department?.rolesAndPermissionList?.CONTACTS?.includes(
              "CHANGE_STATUS"
            ) ? (
              <Tooltip title="Activate contact">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus = dataObj.activeStatus
                        ? "inactive"
                        : "active";
                      dispatch({
                        type: "CHANGE_CONTACTS_STATUS_REQUEST",
                        payload: {
                          id: dataObj.id,
                          actionStatus,
                          isHeadBranch,
                          branchId: branchRecord.id,
                        },
                      });
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            ) : store.profileState?.profile?.department?.rolesAndPermissionList?.CONTACTS?.includes(
                "CHANGE_STATUS"
              ) ? (
              <>
                <Tooltip placement="topRight" title="Deactivate contact">
                  <a style={{ color: "red" }}>
                    <div
                      className="bordered"
                      onClick={() => {
                        const actionStatus = dataObj.activeStatus
                          ? "inactive"
                          : "active";
                        dispatch({
                          type: "CHANGE_CONTACTS_STATUS_REQUEST",
                          payload: {
                            id: dataObj.id,
                            actionStatus,
                            isHeadBranch,
                            branchId: branchRecord.id,
                          },
                        });
                      }}
                    >
                      <BiBlock />
                    </div>
                  </a>
                </Tooltip>
              </>
            ) : null}
          </Space>
        ),
      };
    });
  } else if (contactsType === "get-active-contact") {
    // console.log("active", store.contactState.activeContacts);
    data = store.contactState.activeContacts?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        firstName: (
          <Tooltip title="Contact Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsContactsDetailsModelVisible(true);
                  setRecord(dataObj);
                }}
              >
                {dataObj.firstName ?? ""} {dataObj.lastName ?? ""}
                {dataObj.isClient && (
                  <Tag
                    color="#2db7f5"
                    style={{
                      borderRadius: "10px",
                      margin: "4px",
                    }}
                  >
                    Client
                  </Tag>
                )}
              </div>
            </a>
          </Tooltip>
        ),
        contactImage: dataObj.contactImage ? (
          <Image
            src={dataObj.contactImage}
            style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
          />
        ) : (
          "N/A"
        ),
        email: (
          <div className="flexColumnWithoutStyle">
            {dataObj.email !== "" && dataObj.email !== null ? (
              <Tooltip title="Send Email">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsSendComposedEmailModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <div
                      className="flexRowWithoutStyle"
                      style={{ gap: "2px", color: "000", color: "000" }}
                    >
                      <AiOutlineMail />
                      <a>{dataObj.email ?? "N/A"}</a>
                    </div>{" "}
                  </div>
                </a>
              </Tooltip>
            ) : (
              "N/A"
            )}
          </div>
        ),
        phoneNumber: dataObj.phoneNumber ?? "N/A",
        source: dataObj.source?.name ?? "N/A",
        campaign: dataObj.campaign?.name ?? "N/A",
        activeStatus: dataObj.activeStatus ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {dataObj.activeStatus &&
            store.profileState?.profile?.department?.rolesAndPermissionList?.LEADS?.includes(
              "ADD"
            ) ? (
              <Tooltip title="Add Lead">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAddLeadModelVisible(true);
                    }}
                  >
                    <AiOutlinePlus />
                  </div>
                </a>
              </Tooltip>
            ) : null}
            {!dataObj.isClient &&
            store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
              "ADD"
            ) ? (
              <Tooltip title="Add Client">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAddClientModalVisible(true);
                    }}
                  >
                    <AiOutlineUserAdd />
                  </div>
                </a>
              </Tooltip>
            ) : null}

            {store.profileState?.profile?.department?.rolesAndPermissionList?.CONTACTS?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update Contacts">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateContactsModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {/* <Tooltip title="Delete Contacts">
              <Popconfirm
                title="Are you sure to delete this contact?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_CONTACTS_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip> */}
            <Tooltip title="Contact Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsContactsDetailsModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CONTACTS?.includes(
              "CHANGE_STATUS"
            ) && (
              <Tooltip placement="topRight" title="Deactivate contact">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      dispatch({
                        type: "CHANGE_CONTACTS_STATUS_REQUEST",
                        payload: {
                          id: dataObj.id,
                          actionStatus: "inactive",
                          isHeadBranch,
                          branchId: branchRecord.id,
                        },
                      });
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
  }

  const updateContactSubmitHandler = (values) => {
    console.log("thsi is values", values);
    dispatch({
      type: "UPDATE_CONTACT_REQUEST",
      payload: {
        id: record.id,
        values: {
          ...values,
          branchId: branchRecord.id ?? localStorage.getItem("branchId"),
        },
        branchId: branchRecord.id ?? localStorage.getItem("branchId"),
      },
      payload2: uploadPhotoFn,
    });
    form.resetFields();
    setIsUpdateContactsModelVisible(false);
  };
  console.log("contactState", store.contactState);
  return (
    <SiderDemo>
      <div className="flexColumn">
        <div className="leadFlexRow">
          <div style={{ width: "30%" }}>
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CONTACTS?.includes(
              "ADD"
            ) && (
              <button
                className="button"
                onClick={() => {
                  dropdownList();
                  setIsAddContactVisible(true);
                }}
              >
                <span>Add Contact</span>
              </button>
            )}
          </div>

          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={allContacts} size={20} shape="square" />
                    <div>All Contacts</div>
                  </div>
                ),
                value: "list-all",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={activeContacts} size={20} shape="square" />
                    <div>Active Contacts</div>
                  </div>
                ),
                value: "get-active-contact",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setContactsType(value);
              console.log(value);
            }}
          />
          <Search
            className="search"
            style={{
              width: "35%",
              borderRadius: "10px ! important",
            }}
            placeholder="Search for contacts."
            allowClear
            size="large"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              onSearch(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {store.contactState.isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <Table
                bordered
                columns={columns}
                dataSource={data}
                scroll={true}
                style={{ width: "100%" }}
                pagination={false}
              />

              {contactsType === "list-all" &&
              store.contactState.contacts?.totalData ? (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={page}
                  defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={store.contactState.contacts?.totalPage * 10}
                />
              ) : null}
              {contactsType === "get-active-contact" &&
              store.contactState.activeContacts?.totalData ? (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={page}
                  defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={store.contactState.activeContacts?.totalPage * 10}
                />
              ) : null}
            </>
          )}
        </div>
      </div>
      {/* ADD CONTACTS */}
      {isAddContactVisible && (
        <AddContacts
          isHeadBranch={isHeadBranch}
          branchRecord={branchRecord}
          isAddContactVisible={isAddContactVisible}
          setIsAddContactVisible={setIsAddContactVisible}
        />
      )}
      {isContactsDetailsModelVisible && (
        <ContactDetails
          record={record}
          isContactsDetailsModelVisible={isContactsDetailsModelVisible}
          setIsContactsDetailsModelVisible={setIsContactsDetailsModelVisible}
        />
      )}
      {/* UPDATE CONTACTS */}
      <Drawer
        title="Update Contact"
        open={isUpdateContactsModelVisible}
        onClose={() => {
          setIsUpdateContactsModelVisible(false);
        }}
        footer={null}
        width={1163}
      >
        <Form
          layout="vertical"
          onFinish={updateContactSubmitHandler}
          colon={true}
          form={form}
          className={"drawerStyle"}
          fields={[
            {
              name: ["firstName"],
              value: contactsData.firstName,
            },
            {
              name: ["lastName"],
              value: contactsData.lastName,
            },
            {
              name: ["email"],
              value: contactsData.email,
            },
            {
              name: ["phoneNumber"],
              value: contactsData.phoneNumber,
            },
            {
              name: ["source"],
              value: contactsData.source,
            },
            {
              name: ["campaign"],
              value: contactsData.campaign,
            },
            {
              name: ["countryOfResidence"],
              value: contactsData.countryOfResidence,
            },
          ]}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="First Name"
              name={"firstName"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter first name!",
                },
              ]}
            >
              <Input
                placeholder="Please enter first name!"
                onChange={(e) => {
                  setContactsData((previousData) => {
                    return {
                      ...previousData,
                      firstName: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name={"lastName"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter last name!",
                },
              ]}
            >
              <Input
                placeholder="Please enter last name!"
                onChange={(e) => {
                  setContactsData((previousData) => {
                    return {
                      ...previousData,
                      lastName: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Email"
              name={"email"}
              style={{ width: "100%" }}
              // rules={[
              //   {
              //     required: true,
              //     type: "email",
              //     message: "Please enter email!",
              //   },
              // ]}
            >
              <Input
                type="email"
                placeholder="Please enter Email!"
                onChange={(e) => {
                  setContactsData((previousData) => {
                    return {
                      ...previousData,
                      email: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name={"phoneNumber"}
              style={{ width: "100%" }}
              rules={[
                // {
                //   required: true,
                //   message: "Please enter Phone Number!",
                // },
                {
                  pattern: /^[\d]{9,11}$/,
                  message: "Phone number should contain 9 to 11 numbers",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Please enter Phone Number!"
                onChange={(e) => {
                  setContactsData((previousData) => {
                    return {
                      ...previousData,
                      phoneNumber: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          </div>
          {/* <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                label="Source"
                name={"source"}
                valuePropName={"leadSource"}
                getValueProps={(e) => console.log(e, "console.log")}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select a source!",
                  },
                ]}
              >
                <Select>
                  {store.leadState.leadsDropdown?.leadSource?.map((dataObj) => {
                    return (
                      <Option key={dataObj.id}>{dataObj.leadSource}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <a
                className="plusButton"
                onClick={() => {
                  setIsAddSourceModalVisible(true);
                }}
              >
                <AiOutlinePlus className="iconColor" />
              </a>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                label="Campaign"
                name={"campaign"}
                valuePropName={"name"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter campaign!",
                  },
                ]}
              >
                <Select placeholder={"saflkasjdf"}>
                  {store.leadState.leadsDropdown?.campaign?.map((dataObj) => {
                    return (
                      <Option key={dataObj.id}>{dataObj.campaignName}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <a
                className="plusButton"
                onClick={() => {
                  setIsAddCampaignModalVisible(true);
                }}
              >
                <AiOutlinePlus className="iconColor" />
              </a>
            </div>
          </div> */}
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                label="Country of Residence"
                name={"countryOfResidence"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     type: "email",
                //     message: "Please enter country of residence",
                //   },
                // ]}
              >
                <Select
                  optionFilterProp="children"
                  showSearch
                  filterOption={(input, option) => {
                    console.log("input and option", input, option);
                    return (option?.children?.props?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {store.leadState?.leadsDropdown?.countries?.map((dataObj) => {
                    return (
                      <Option key={dataObj.id}>
                        <div
                          onClick={() => {
                            setContactsData((previousData) => {
                              return {
                                ...previousData,
                                countryOfResidence: dataObj.countryName,
                              };
                            });
                          }}
                          className={"selectOptionDiv"}
                        >
                          {dataObj.countryName}
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <a
                className="plusButton"
                onClick={() => {
                  setIsAddCountryModalVisible(true);
                }}
              >
                <AiOutlinePlus className="iconColor" />
              </a>
            </div>
            <Form.Item name="image" label="Image" style={{ width: "100%" }}>
              <Input
                name="image"
                type="file"
                onChange={(e) => {
                  setContactImage(e.target.files[0]);
                }}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="contactSubmit"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  console.log("value frm head agent", value);
                  console.log(
                    "is percentage frm head agent",
                    getFieldValue("phoneNumber"),
                    typeof getFieldValue("email")
                  );
                  if (
                    (getFieldValue("email") === undefined ||
                      getFieldValue("email") === "" ||
                      getFieldValue("email") === null) &&
                    (getFieldValue("phoneNumber" === undefined) ||
                      getFieldValue("phoneNumber") === "" ||
                      getFieldValue("phoneNumber") === null)
                  ) {
                    return Promise.reject(
                      new Error("Please enter either email or phone number!")
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            hasFeedback
          >
            <Button name="contactSubmit" block type="primary" htmlType="submit">
              Update Contact
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
      {isAddSourceModalVisible && (
        <AddLeadSource
          isHeadBranch={isHeadBranch}
          branchRecord={branchRecord}
          isAddLeadSourceModalVisible={isAddSourceModalVisible}
          setIsAddLeadSourceModalVisible={setIsAddSourceModalVisible}
        />
      )}
      {isAddCampaignModalVisible && (
        <AddCampaign
          isHeadBranch={isHeadBranch}
          branchRecord={branchRecord}
          isAddCampaignModalVisible={isAddCampaignModalVisible}
          setIsAddCampaignModalVisible={setIsAddCampaignModalVisible}
        />
      )}
      {isAddLeadModelVisible && (
        <AddLead
          isHeadBranch={isHeadBranch}
          branchRecord={branchRecord}
          fromContacts={true}
          contactRecord={record}
          isAddLeadVisible={isAddLeadModelVisible}
          setIsAddLeadVisible={setIsAddLeadModelVisible}
        />
      )}
      {isAddClientModalVisible && (
        <AddClient
          record={record}
          isAddClientModalVisible={isAddClientModalVisible}
          setIsAddClientModalVisible={setIsAddClientModalVisible}
        />
      )}
      {isAddCountryModalVisible && (
        <AddCountry
          isAddCountryModalVisible={isAddCountryModalVisible}
          setIsAddCountryModalVisible={setIsAddCountryModalVisible}
        />
      )}
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          composeMailRecord={{
            toUser: [record?.email],
          }}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
    </SiderDemo>
  );
};

export default Contacts;
