import React, { useEffect, useMemo } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Tag,
} from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import AddCasePackage from "../Visas/AddCasePackage";
import QuoteSummary from "./QuoteSummary";
import AddTimeline from "./Timeline/AddTimeline";
import { scrollToTopFormError } from "../../HelperFunction";
const { Option } = Select;
const { TextArea } = Input;

const QuotationPackageInfo = ({
  quotationData,
  setQuotationData,
  packages,
  setPackages,
  current,
  setCurrent,
  addCaseSubmitHandler,
}) => {
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
      quoteState: state.QuotationReducer,
    };
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [isAddPackageModalVisible, setIsAddPackageModalVisible] =
    useState(false);
  const [isAddTimelineModalVisible, setIsAddTimelineModalVisible] =
    useState(false);
  useEffect(() => {
    dispatch({ type: "FETCH_ACTIVE_CASE_PACKAGE_REQUEST" });
    dispatch({ type: "FETCH_TIMELINE_REQUEST" });
  }, []);

  const removePackagesInput = (index) => {
    const list = [...packages];
    list.splice(index, 1);
    setPackages(list);
  };

  const addPackagesInput = () => {
    // setQuantity(null);
    // setUnitPrice(null);
    setPackages([
      ...packages,
      {
        id: null,
        quantity: null,
        totalPrice: null,
        unitPrice: null,
        hasGst: null,
        gst: null,
        isGstFlat: null,
        isGstPercentage: null,
      },
    ]);
  };

  useMemo(() => {
    let sum = 0;
    // let previousTotalfee = visaCaseData.fee === NaN ? 0 : visaCaseData.fee;

    packages.map((item, index) => {
      sum = sum + +item.totalPrice;
      return sum;
    });
  }, [packages]);
  const addVisaCasesSubmitHandler = () => {
    setCurrent(2);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexFlow: "wrap",
          gap: "1rem",
          alignItems: "stretch",
        }}
      >
        <div style={{ width: "100%", display: "flex", flex: 1 }}>
          <Form
            layout="vertical"
            onFinish={addVisaCasesSubmitHandler}
            onFinishFailed={scrollToTopFormError}
            colon={true}
            form={form}
            className="drawerStyle"
            fields={[
              {
                name: ["natureOfProject"],
                value: quotationData.natureOfProject,
              },
              {
                name: ["isDiscountAllowed"],
                value: quotationData.isDiscountAllowed,
              },
              {
                name: ["discount"],
                value: quotationData.discount,
              },
              {
                name: ["packageDiscountType"],
                value: quotationData.isPercentage,
              },
              {
                name: ["gstApplicable"],
                value: quotationData.gstApplicable,
              },
              {
                name: ["gstAmount"],
                value: quotationData.gstAmount,
              },
              {
                name: ["isFinanceOptions"],
                value: quotationData.isFinanceOptions,
              },
              {
                name: ["associatedTimelineId"],
                value: quotationData.associatedTimelineName,
              },
              {
                name: ["description"],
                value: quotationData.description,
              },
              {
                name: ["paymentTermsInMonths"],
                value: quotationData.paymentTermsInMonths,
              },
              {
                name: ["specialNote"],
                value: quotationData.specialNote,
              },
              {
                name: ["paymentTermsInfo"],
                value: quotationData.paymentTermsInfo,
              },
              {
                name: ["displayPaymentTermsInfoInQuoteConfirmation"],
                value: quotationData.displayPaymentTermsInfoInQuoteConfirmation,
              },
              {
                name: ["quotesUpdate"],
                value: quotationData.quotesUpdate,
              },
              {
                name: ["displayQuotesUpdateInQuoteConfirmation"],
                value: quotationData.displayQuotesUpdateInQuoteConfirmation,
              },
            ]}
          >
            <Form.Item
              label="Nature of Quote"
              name={"natureOfProject"}
              style={{ width: "100%" }}
            >
              <Input
                placeholder="Nature of Quote"
                onChange={(e) => {
                  setQuotationData((previousData) => {
                    return {
                      ...previousData,
                      natureOfProject: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <div
              style={{
                backgroundColor: "#eeeeee55",
                borderRadius: "0.5rem",
                padding: "0.5rem",
              }}
            >
              <h3>Package:</h3>
              {packages?.map((onePackage, index) => {
                return (
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        className="flexRowWithoutStyle"
                        style={{
                          justifyContent: "space-between",
                          gap: "1rem",
                          width: "100%",
                        }}
                      >
                        <div
                          className="flexRowWithoutStyle"
                          style={{
                            gap: "0.3rem",
                            width: "100%",
                          }}
                        >
                          <Form.Item
                            label="Package"
                            style={{ width: "100%" }}
                            rules={[
                              {
                                required: true,
                                message: "Please enter current status!",
                              },
                            ]}
                          >
                            <Select
                              name={"casePackagesId"}
                              onChange={(value, option) => {
                                const selectedPkg = JSON.parse(option?.key);
                                // const tempPkgList =
                                //   quotationData?.quotationPackageDetailsRequests;
                                // tempPkgList[index] = {
                                //   packageId: selectedPkg?.id,
                                //   packageName: selectedPkg?.packageName,
                                //   quantity: 1,
                                //   unitPrice: selectedPkg?.unitPrice,
                                //   totalAmount: selectedPkg?.unitPrice,
                                // };
                                // setQuotationData((prev) => {
                                //   return {
                                //     ...prev,
                                //     quotationPackageDetailsRequests:
                                //       tempPkgList,
                                //   };
                                // });
                              }}
                              value={packages[index].packageName}
                            >
                              {store.caseState.activeCasePackage?.data?.map(
                                (dataObj) => {
                                  return (
                                    <Option
                                      key={JSON.stringify(dataObj)}
                                      value={dataObj.id}
                                    >
                                      <div
                                        onClick={(e) => {
                                          //   setCasePackage(dataObj);
                                          let updatedPackage = [...packages];

                                          updatedPackage[index].id = dataObj.id;
                                          updatedPackage[index].packageName =
                                            dataObj.packageName;
                                          updatedPackage[index].quantity = 1;
                                          updatedPackage[index].unitPrice =
                                            dataObj.unitPrice;
                                          updatedPackage[index].totalPrice =
                                            +updatedPackage[index].quantity *
                                            +updatedPackage[index].unitPrice;
                                          updatedPackage[index].gst =
                                            dataObj.gst;
                                          updatedPackage[index].hasGst =
                                            dataObj.hasGst;
                                          updatedPackage[index].isGstFlat =
                                            dataObj.isGstFlat;
                                          updatedPackage[
                                            index
                                          ].isGstPercentage =
                                            dataObj.isGstPercentage;
                                          setPackages(updatedPackage);
                                        }}
                                        className={"selectOptionDiv"}
                                      >
                                        {dataObj.packageName}
                                        {dataObj.packageType === "IN_HOUSE" && (
                                          <Tag
                                            color="#2db7f5"
                                            style={{
                                              borderRadius: "10px",
                                              margin: "3px",
                                            }}
                                          >
                                            IH
                                          </Tag>
                                        )}
                                        {dataObj.packageType ===
                                          "INSTITUTE" && (
                                          <Tag
                                            color="#2db7f5"
                                            style={{
                                              borderRadius: "10px",
                                              margin: "3px",
                                            }}
                                          >
                                            IN
                                          </Tag>
                                        )}
                                        {dataObj.packageType === "COMPANY" && (
                                          <Tag
                                            color="#2db7f5"
                                            style={{
                                              borderRadius: "10px",
                                              margin: "3px",
                                            }}
                                          >
                                            CO
                                          </Tag>
                                        )}
                                      </div>
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                          <a
                            className="plusButton"
                            onClick={() => {
                              setIsAddPackageModalVisible(true);
                            }}
                          >
                            <AiOutlinePlus className="iconColor" />
                          </a>
                        </div>
                        <Form.Item
                          label="Quantity"
                          style={{ width: "100%" }}
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please enter quantity!",
                          //   },
                          // ]}
                        >
                          <Input
                            name={"quantity"}
                            type="number"
                            value={packages[index].quantity}
                            placeholder="Quantity"
                            onChange={(e) => {
                              let updatedPackage = [...packages];
                              updatedPackage[index].quantity = +e.target.value;
                              updatedPackage[index].totalPrice =
                                updatedPackage[index].quantity *
                                updatedPackage[index].unitPrice;
                              setPackages(updatedPackage);
                              // if (totalAmount !== null && unitPrice !== null) {
                              //   setTotalAmount(
                              //     totalAmount + quantity * unitPrice
                              //   );
                              // }

                              // console.log(
                              //   "quantity sar",
                              //   e.target.value,
                              //   index
                              // );

                              // const tempPkgList =
                              //   quotationData?.quotationPackageDetailsRequests;
                              // tempPkgList[index] = {
                              //   packageId: tempPkgList[index].packageId,
                              //   packageName: tempPkgList[index].packageName,
                              //   quantity: parseFloat(e.target.value),
                              //   unitPrice: tempPkgList[index].unitPrice,
                              //   totalAmount:
                              //     e.target.value * tempPkgList[index].unitPrice,
                              // };
                              // setQuotationData((prev) => {
                              //   return {
                              //     ...prev,
                              //     quotationPackageDetailsRequests: tempPkgList,
                              //   };
                              // });
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Unit Price"
                          style={{ width: "100%" }}

                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please enter unit price!",
                          //   },
                          // ]}
                        >
                          <Input
                            name={"unitPrice"}
                            type="number"
                            readOnly
                            placeholder="Unit price"
                            value={packages[index].unitPrice}
                            onChange={(e) => {
                              let updatedPackage = [...packages];
                              updatedPackage[index].unitPrice = +e.target.value;
                              updatedPackage[index].totalPrice =
                                updatedPackage[index].quantity *
                                updatedPackage[index].unitPrice;
                              setPackages(updatedPackage);
                              // if (totalAmount !== null && unitPrice !== null) {
                              //   setTotalAmount(
                              //     totalAmount + quantity * unitPrice
                              //   );
                              // }
                            }}
                          />
                        </Form.Item>
                        {/* packages[index].hasGst && */}
                        {/* `${true ? "%" : "Flat"}` */}
                        <Form.Item
                          label={`Price ${
                            packages[index].gst && packages[index].hasGst
                              ? `(GST/VAT: ${packages[index].gst} ${
                                  packages[index].isGstPercentage
                                    ? `%`
                                    : packages[index].isGstFlat
                                    ? "FLAT"
                                    : ""
                                })`
                              : ""
                          }`}
                          style={{ width: "100%" }}
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please enter total price!",
                          //   },
                          // ]}
                        >
                          <Input
                            name={"totalPrice"}
                            type="number"
                            readOnly
                            placeholder="Total Price"
                            value={packages[index].totalPrice + ""}
                            onChange={(e) => {
                              let updatedPackage = [...packages];
                              updatedPackage[index].totalPrice =
                                +e.target.value;

                              setPackages(updatedPackage);
                            }}
                            onBlur={() => {}}
                          />
                        </Form.Item>
                      </div>
                      {/* {packages.length > 1 && ( */}
                      <Button
                        color="red"
                        onClick={() => {
                          removePackagesInput(index);
                          // const tempPkgList =
                          //   quotationData?.quotationPackageDetailsRequests;
                          // tempPkgList.splice(index, 1);

                          // setQuotationData((prev) => {
                          //   return {
                          //     ...prev,
                          //     quotationPackageDetailsRequests: tempPkgList,
                          //   };
                          // });
                        }}
                        style={{
                          padding: "0",
                          borderRadius: "50%",
                          height: "1.5rem",
                          width: "1.5rem",
                        }}
                      >
                        <CloseOutlined />
                      </Button>
                      {/* )} */}
                    </div>
                    {/* {packages.length - 1 === index && (
                      <Button
                        block
                        type="dashed"
                        style={{ marginBottom: "1rem" }}
                        onClick={addPackagesInput}
                      >
                        <PlusOutlined />
                        Add Package
                      </Button>
                    )} */}
                  </div>
                );
              })}
              <Button
                block
                type="dashed"
                style={{ marginBottom: "1rem" }}
                onClick={addPackagesInput}
              >
                <PlusOutlined />
                Add Package
              </Button>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                name={"isDiscountAllowed"}
                valuePropName="checked"
                style={{ width: "100%" }}
              >
                <Checkbox
                  onChange={(e) => {
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        isDiscountAllowed: e.target.checked,
                      };
                    });
                    // setIsDiscountAllowed(e.target.checked);
                  }}
                >
                  is Discount Allowed?
                </Checkbox>
              </Form.Item>
              {quotationData.isDiscountAllowed ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      gap: "0.5rem",
                    }}
                  >
                    <Form.Item
                      label="Discount amount"
                      name={"discount"}
                      style={{ width: "100%" }}
                    >
                      <Input
                        type="number"
                        placeholder="Discount"
                        onChange={(e) => {
                          setQuotationData((previousData) => {
                            return {
                              ...previousData,
                              discount: e.target.value,
                            };
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={"Type"}
                      name={"packageDiscountType"}
                      style={{ width: "100%" }}
                    >
                      <Radio.Group
                        onChange={(e) => {
                          setQuotationData((previousData) => {
                            return {
                              ...previousData,
                              isPercentage: e.target.value,
                            };
                          });
                        }}
                      >
                        <Radio value={"FLAT"}>is Flat</Radio>
                        <Radio value={"PERCENTAGE"}>is Percentage</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap: "0.5rem",
              }}
            >
              <Form.Item
                name={"gstApplicable"}
                valuePropName="checked"
                style={{ width: "100%" }}
              >
                <Checkbox
                  onChange={(e) => {
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        gstApplicable: e.target.checked,
                      };
                    });
                  }}
                >
                  Is GST/VAT applicable?
                </Checkbox>
              </Form.Item>
              {quotationData.gstApplicable && (
                <Form.Item
                  label="GST/TAX"
                  name={"gstAmount"}
                  style={{ width: "100%" }}
                >
                  <Input
                    type="number"
                    placeholder="GST/TAX (%)"
                    onChange={(e) => {
                      setQuotationData((previousData) => {
                        return {
                          ...previousData,
                          gstAmount: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              )}
            </div>
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap: "0.5rem",
              }}
            >
              <Form.Item
                name={"isFinanceOptions"}
                valuePropName="checked"
                style={{ width: "100%" }}
              >
                <Checkbox
                  onChange={(e) => {
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        isFinanceOptions: e.target.checked,
                      };
                    });
                  }}
                >
                  Finance Options?
                </Checkbox>
              </Form.Item>
            </div> */}
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                label="Timeline"
                name={"associatedTimelineId"}
                style={{ width: "100%" }}
              >
                <Select>
                  {store.quoteState?.timelines?.data?.map((dataObj) => {
                    return (
                      <Option key={dataObj.id}>
                        <div
                          onClick={(e) => {
                            setQuotationData((previousData) => {
                              return {
                                ...previousData,
                                associatedTimelineId: dataObj.id,
                                associatedTimelineName: dataObj?.timelineName,
                              };
                            });
                          }}
                          className={"selectOptionDiv"}
                        >
                          {dataObj.timelineName}
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <a
                className="plusButton"
                onClick={() => {
                  setIsAddTimelineModalVisible(true);
                }}
              >
                <AiOutlinePlus className="iconColor" />
              </a>
            </div>
            <Form.Item
              label="Special Note"
              name={"specialNote"}
              style={{ width: "100%" }}
            >
              <TextArea
                placeholder="Write something..."
                onChange={(e) => {
                  setQuotationData((previousData) => {
                    return {
                      ...previousData,
                      specialNote: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              label="Description"
              name={"description"}
              style={{ width: "100%" }}
            >
              <TextArea
                placeholder="Write something..."
                onChange={(e) => {
                  setQuotationData((previousData) => {
                    return {
                      ...previousData,
                      description: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Payment terms in months"
                name={"paymentTermsInMonths"}
                style={{ width: "100%" }}
              >
                <Input
                  type="number"
                  placeholder="Payment terms in months"
                  onChange={(e) => {
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        paymentTermsInMonths: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Payment Terms"
                name={"paymentTermsInfo"}
                style={{ width: "75%" }}
              >
                <TextArea
                  placeholder="Write something..."
                  onChange={(e) => {
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        paymentTermsInfo: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                name={"displayPaymentTermsInfoInQuoteConfirmation"}
                valuePropName="checked"
                style={{ width: "25%" }}
              >
                <Checkbox
                  onChange={(e) => {
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        displayPaymentTermsInfoInQuoteConfirmation:
                          e.target.checked,
                      };
                    });
                  }}
                >
                  Display in quote confirmation?
                </Checkbox>
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Quote Update"
                name={"quotesUpdate"}
                style={{ width: "75%" }}
              >
                <TextArea
                  placeholder="Write something..."
                  onChange={(e) => {
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        quotesUpdate: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                name={"displayQuotesUpdateInQuoteConfirmation"}
                valuePropName="checked"
                style={{ width: "25%" }}
              >
                <Checkbox
                  onChange={(e) => {
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        displayQuotesUpdateInQuoteConfirmation:
                          e.target.checked,
                      };
                    });
                  }}
                >
                  Display in quote confirmation?
                </Checkbox>
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              {current === 1 && (
                <Form.Item>
                  <Button
                    onClick={() => {
                      setCurrent(0);
                    }}
                  >
                    Back
                  </Button>
                </Form.Item>
              )}
              <Form.Item name="caseSubmit">
                <Button
                  name="caseSubmit"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Next
                </Button>
              </Form.Item>
            </div>
          </Form>

          {isAddPackageModalVisible && (
            <AddCasePackage
              from={"CASE"}
              isAddPackageModalVisible={isAddPackageModalVisible}
              setIsAddPackageModalVisible={setIsAddPackageModalVisible}
            />
          )}
          {isAddTimelineModalVisible && (
            <AddTimeline
              isAddTimelineModalVisible={isAddTimelineModalVisible}
              setIsAddTimelineModalVisible={setIsAddTimelineModalVisible}
            />
          )}
        </div>
        <div
          style={{
            width: "30%",
          }}
        >
          <QuoteSummary current={current} quotationData={quotationData} />
        </div>
      </div>
    </>
  );
};

export default QuotationPackageInfo;
