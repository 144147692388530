import { Form, Modal, Select, Typography } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
const { Paragraph } = Typography;
const { Option } = Select;

const ConsultantShortUrl = ({
  record,
  isConsultantUrlLinkModalVisible,
  setIsConsultantUrlLinkModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      profileState: state.LoginReducer,
      loginState: state.ProfileReducer,
      campaignState: state.CampaignReducer,
    };
  });
  const [shortenedUrl, setShortenedUrl] = useState("");
  const [campaignData, setCampaignData] = useState({
    campaignId: null,
    campaignName: null,
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const successFunction = async (response) => {
    setShortenedUrl(response.data.shortUrl);
    // try {
    //   const response1 = await axios.get(
    //     `https://api.shrtco.de/v2/shorten?url=${encodeURIComponent(
    //       response?.data
    //     )}`
    //   );
    //   setShortenedUrl(response1.data.result.full_short_link);
    //   console.log(response1);
    //   return;
    // } catch (e) {
    //   console.log("FromresponseQR", e);
    // }
  };
  useEffect(() => {
    dispatch({
      type: "FETCH_ACTIVE_CAMPAIGN_REQUEST",
      payload: { page: 1, pageSize: 10 },
    });
    // dispatch({
    //   type: "FETCH_QR_CODE_OF_CONSULTANT_OF_APPOINTMENT_FORM_REQUEST",
    //   payload: { campaignId: campaignData.campaignId, consultantId: record.id },
    //   isSuccessFn: true,
    //   successFunction,
    // });
  }, []);
  return (
    <Modal
      title="URL Link"
      open={isConsultantUrlLinkModalVisible}
      onCancel={() => {
        setIsConsultantUrlLinkModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        colon={true}
        form={form}
        fields={[
          {
            name: ["departmentId"],
            value: record?.department?.id,
          },
        ]}
      >
        <Form.Item
          label="Campaign"
          name={"campaign"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select a campaign!",
            },
          ]}
        >
          <Select>
            {store.campaignState.activeCampaign?.data?.map((dataObj) => {
              return (
                <Option key={dataObj?.id} value={dataObj?.id}>
                  <div
                    onClick={() => {
                      setCampaignData((previousData) => {
                        return {
                          ...previousData,
                          campaignId: dataObj.id,
                          campaignName: dataObj.campaignName,
                        };
                      });
                      dispatch({
                        type: "FETCH_QR_CODE_OF_CONSULTANT_OF_APPOINTMENT_FORM_REQUEST",
                        payload: {
                          campaignId: dataObj?.id,
                          consultantId: record.id,
                        },
                        isSuccessFn: true,
                        successFunction,
                      });
                    }}
                  >
                    {dataObj?.campaignName}
                  </div>
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>

      {shortenedUrl !== "" &&
        shortenedUrl !== null &&
        shortenedUrl !== undefined &&
        campaignData?.campaignId !== null && (
          <Paragraph
            copyable={shortenedUrl}
            style={{
              borderColor: "#57a8e9",
              borderRadius: "0.4rem",
              padding: "0.4rem 0.5rem",
              outline: 0,
              boxShadow: "0 0 0 2px rgba(87,168,233, .2)",
            }}
          >
            {shortenedUrl}
          </Paragraph>
        )}
    </Modal>
  );
};

export default ConsultantShortUrl;
