import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  leads: {},
  notAssignedLeads: {},
  inProgressLeads: {},
  completedLeads: {},
  leadsDropdown: {},
  leadById: {},
  leadCsv: {},
  leadActivityLogById: {},
  appointmentsByLeadId: {},
  checkForExistingEmail: false,
  checkForExistingMobile: false,
};

const LeadReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ADD_LEAD_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_LEAD_REQUEST_SUCCESS": {
      action?.payload?.message
        ? message.success(action?.payload?.message)
        : message.success("Success! Lead added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_LEAD_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add lead.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_LEAD_STATUS_UPDATE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_LEAD_STATUS_UPDATE_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Lead status updated.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_LEAD_STATUS_UPDATE_REQUEST_FAILURE": {
      action.payload?.response?.data.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update lead status.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_LEADS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_LEADS_REQUEST_SUCCESS": {
      return {
        ...state,
        leads: action.payload,
        isLoading: false,
      };
    }

    case "FETCH_LEADS_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_NOT_ASSIGNED_LEADS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_NOT_ASSIGNED_LEADS_REQUEST_SUCCESS": {
      return {
        ...state,
        notAssignedLeads: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_NOT_ASSIGNED_LEADS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load not assigned leads.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_IN_PROGRESS_LEADS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_IN_PROGRESS_LEADS_REQUEST_SUCCESS": {
      return {
        ...state,
        inProgressLeads: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_IN_PROGRESS_LEADS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load in progress leads.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_COMPLETED_LEADS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_COMPLETED_LEADS_REQUEST_SUCCESS": {
      return {
        ...state,
        completedLeads: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_COMPLETED_LEADS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load completed leads.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_LEAD_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_LEAD_BY_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        leadById: action.payload,
        isLoading: false,
      };
    }

    case "FETCH_LEAD_BY_ID_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_LEADS_DROPDOWN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_LEADS_DROPDOWN_REQUEST_SUCCESS": {
      return {
        ...state,
        leadsDropdown: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_LEADS_DROPDOWN_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_LEAD_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_LEAD_REQUEST_SUCCESS": {
      action?.payload2?.message
        ? message.success(action?.payload2?.message)
        : message.success("Success! Lead updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_LEAD_REQUEST_FAILURE": {
      action.payload?.response?.data.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update lead.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_LEAD_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_LEAD_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Lead deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_LEAD_REQUEST_FAILURE": {
      action.payload?.response?.data.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to delete lead.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_ABOUT_US_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_ABOUT_US_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! About us added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_ABOUT_US_REQUEST_FAILURE": {
      action.payload?.response?.data.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add about us.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_LEAD_SOURCE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_LEAD_SOURCE_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Lead source added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_LEAD_SOURCE_REQUEST_FAILURE": {
      action.payload?.response?.data.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add lead source.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_COUNTRY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_COUNTRY_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Lead status added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_COUNTRY_REQUEST_FAILURE": {
      action.payload?.response?.data.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add lead status.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPLOAD_COUNTRY_IMAGE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPLOAD_COUNTRY_IMAGE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Image Uploaded.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPLOAD_COUNTRY_IMAGE_REQUEST_FAILURE": {
      action.payload?.response?.data.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to upload an image.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_COUNTRY_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "CHANGE_COUNTRY_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Country status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_COUNTRY_STATUS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to change country status.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_LEAD_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_LEAD_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Lead status added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_LEAD_STATUS_REQUEST_FAILURE": {
      action.payload?.response?.data.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add lead status.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_PURPOSE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_PURPOSE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Purpose added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_PURPOSE_REQUEST_FAILURE": {
      action.payload?.response?.data.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add purpose.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CONSULTANT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_CONSULTANT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Consultant added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CONSULTANT_REQUEST_FAILURE": {
      action.payload?.response?.data.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add consultant.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_REFERRAL_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_REFERRAL_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Referee added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_REFERRAL_REQUEST_FAILURE": {
      action.payload?.response?.data.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add referee.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DOWNLOAD_LEAD_CSV_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DOWNLOAD_LEAD_CSV_REQUEST_SUCCESS": {
      message.success("Success! CSV downloaded.");

      return {
        ...state,
        leadCsv: action.payload,
        isLoading: false,
      };
    }
    case "DOWNLOAD_LEAD_CSV_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to download csv.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_LEAD_ACTIVITY_LOG_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_LEAD_ACTIVITY_LOG_BY_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        leadActivityLogById: action.payload2,
        isLoading: false,
      };
    }
    case "FETCH_LEAD_ACTIVITY_LOG_BY_ID_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load lead activity log.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "IMPORT_LEAD_CSV_FILE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "IMPORT_LEAD_CSV_FILE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! CSV imported.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "IMPORT_LEAD_CSV_FILE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to import csv.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_APPOINTMENT_BY_LEAD_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_APPOINTMENT_BY_LEAD_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        appointmentsByLeadId: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_APPOINTMENT_BY_LEAD_ID_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load appointments of lead.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHECK_FOR_EXISTING_EMAIL_REQUEST": {
      return {
        ...state,

        isLoading: true,
      };
    }

    case "CHECK_FOR_EXISTING_EMAIL_REQUEST_SUCCESS": {
      return {
        ...state,
        checkForExistingEmail: action.payload,
        isLoading: false,
      };
    }
    case "CHECK_FOR_EXISTING_EMAIL_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHECK_FOR_EXISTING_MOBILE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "CHECK_FOR_EXISTING_MOBILE_REQUEST_SUCCESS": {
      return {
        ...state,
        checkForExistingMobile: action.payload,
        isLoading: false,
      };
    }
    case "CHECK_FOR_EXISTING_MOBILE_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "STATUS_HISTORY_ONLY_UPDATE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "STATUS_HISTORY_ONLY_UPDATE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Status update added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "STATUS_HISTORY_ONLY_UPDATE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add status update.");

      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default LeadReducer;
