import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
const { TextArea } = Input;
const { Option } = Select;

const UpdateProfile = ({
  record,
  isUpdateProfileModalVisible,
  setIsUpdateProfileModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const updateProfileSubmitHandler = (values) => {
    dispatch({ type: "UPDATE_PROFILE_REQUEST", payload: { values } });
    // form.resetFields()
    setIsUpdateProfileModalVisible(false);
  };
  return (
    <Modal
      title="Update Profile"
      open={isUpdateProfileModalVisible}
      onCancel={() => {
        setIsUpdateProfileModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateProfileSubmitHandler}
        colon={true}
        form={form}
        fields={[
          //   {
          //     name: ["instituteId"],
          //     value: [record.institute.instituteId],
          //   },
          {
            name: ["firstName"],
            value: record.firstName,
          },
          {
            name: ["lastName"],
            value: record.lastName,
          },
          {
            name: ["email"],
            value: record.emailId,
          },
          {
            name: ["phoneNumber"],
            value: record.phoneNumber,
          },
          {
            name: ["userName"],
            value: record.userName,
          },
          {
            name: ["degree"],
            value: record.degree,
          },
          {
            name: ["country"],
            value: record.country,
          },
          {
            name: ["companyName"],
            value: record.companyName,
          },
          {
            name: ["companyRegistrationNumber"],
            value: record.companyRegistrationNumber,
          },
          {
            name: ["companyAddress"],
            value: record.companyAddress,
          },
        ]}
      >
        <Form.Item
          label="First Name"
          name={"firstName"}
          rules={[
            {
              required: true,
              message: "Please enter first name!",
            },
          ]}
        >
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name={"lastName"}
          rules={[
            {
              required: true,
              message: "Please enter last name!",
            },
          ]}
        >
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item
          label="Email"
          name={"email"}
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter valid email!",
            },
          ]}
        >
          <Input placeholder="e.g. johndoe@gmail.com" />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name={"phoneNumber"}
          rules={[
            {
              required: true,
              message: "Please enter phone number!",
            },
            {
              pattern: /^[\d]{9,11}$/,
              message: "Phone number should contain 9 to 11 numbers",
            },
          ]}
        >
          <Input type="number" placeholder="Phone Number" />
        </Form.Item>
        {/* <Form.Item
          label="User Name"
          name={"userName"}
          rules={[
            {
              required: true,
              message: "Please enter user name!",
            },
          ]}
        >
          <Input placeholder="User Name" />
        </Form.Item>
        <Form.Item
          label="Country"
          name={"country"}
          rules={[
            {
              required: true,
              message: "Please enter your country!",
            },
          ]}
        >
          <Input placeholder="Country" />
        </Form.Item>

        <Form.Item
          label="Company Name"
          name={"companyName"}
          rules={[
            {
              required: true,
              message: "Please enter company name!",
            },
          ]}
        >
          <Input placeholder="Company Name" />
        </Form.Item>
        <Form.Item
          label="Company Registration Number"
          name={"companyRegistrationNumber"}
          rules={[
            {
              required: true,
              message: "Please enter company registration number!",
            },
          ]}
        >
          <Input placeholder="Company Registration Number" />
        </Form.Item>
        <Form.Item
          label="Company Address"
          name={"companyAddress"}
          rules={[
            {
              required: true,
              message: "Please enter company address!",
            },
          ]}
        >
          <Input placeholder="Company Address" />
        </Form.Item> */}
        {/* <Form.Item
          label="Profile Image"
          name={"profileImage"}
          rules={[
            {
              required: true,
              message: "Please upload profile image!",
            },
          ]}
        >
          <input type="file" placeholder="Profile Image" />
        </Form.Item> */}

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Profile
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateProfile;
