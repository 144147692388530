import {
  DownOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SmileOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import khrouchLogo from "./../../Assets/khrouch.png";
import khrouchLogo2 from "./../../Assets/khrouch3.png";
import profile from "./../../Assets/profile.png";
import {
  Alert,
  Badge,
  Divider,
  Dropdown,
  Layout,
  Menu,
  Select,
  Skeleton,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { IoIosArrowDown, IoIosNotificationsOutline } from "react-icons/io";
// import { GoDashboard } from "react-icons/go2";
import {
  AiOutlineClockCircle,
  AiOutlineContacts,
  AiOutlineDatabase,
  AiOutlineKey,
  AiOutlineLogin,
  AiOutlineMail,
  AiOutlinePlus,
  AiOutlineSearch,
  AiOutlineUser,
} from "react-icons/ai";
import { TiFlowChildren } from "react-icons/ti";
import { useIdleTimer } from "react-idle-timer";

import {
  BsBell,
  BsBookmark,
  BsClipboard,
  BsFlag,
  BsPlayCircle,
} from "react-icons/bs";
import {
  FaGoogleDrive,
  FaQuora,
  FaRegBuilding,
  FaSearchLocation,
} from "react-icons/fa";
import { RiTodoLine, RiVisaLine } from "react-icons/ri";
import { SlGraduation } from "react-icons/sl";
import { TbCertificate } from "react-icons/tb";
import { TfiBookmarkAlt } from "react-icons/tfi";
import { BsBuilding } from "react-icons/bs";
import {
  HiOutlineBookOpen,
  HiOutlinePaperAirplane,
  HiOutlineUsers,
} from "react-icons/hi";
import { SiGoogleclassroom } from "react-icons/si";
import { FiSettings } from "react-icons/fi";
import React, { useEffect, useState } from "react";
import classes from "./SiderDemo.module.css";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BiBuildingHouse, BiLayer, BiSupport, BiUser } from "react-icons/bi";
import { GrNotification, GrResources, GrUserAdmin } from "react-icons/gr";
import { VscDebugStart, VscTypeHierarchySuper } from "react-icons/vsc";
import AddLead from "../../Pages/Leads/AddLead";
import AddAppointment from "../../Pages/Appointments/AddAppointment";
import AddClient from "../../Pages/Clients/AddClient";
import AddContacts from "../../Pages/Contacts/AddContacts";
import { MdCalendarViewMonth, MdCampaign } from "react-icons/md";
import AddVisaCases from "../../Pages/Visas/AddVisas";
import axios from "axios";
import moment from "moment";
import ClientDetails from "../../Pages/Clients/ClientDetails";
import ContactDetails from "../../Pages/Contacts/ContactDetails";
import LeadDetails from "../../Pages/Leads/LeadDetails";
import AppointmentDetails from "../../Pages/Appointments/AppointmentDetails";
import VisaCaseDetails from "../../Pages/Visas/VisaCaseDetails";
import ConsultantDetails from "../../Pages/Consultants/ConultantDetails";
import InstituteDetais from "../../Pages/Education/Institute/InstituteDetails";
import CoursesDetail from "../../Pages/Courses/CoursesDetail";
import CompanyDetails from "../../Pages/Companies/CompanyDetails";
import InfiniteScroll from "react-infinite-scroll-component";
import BranchLogin from "../../Pages/Branch/BranchLogin/BranchLogin";
import { setLocalStorage } from "../../Helpers/FrontendHelper";
import Cookies from "js-cookie";
import { IoDocumentOutline } from "react-icons/io5";
const { Header, Sider, Content } = Layout;
const { Option } = Select;
const { Paragraph } = Typography;

const SiderDemo = ({ children, organizationalSettings, dashboard }) => {
  const store = useSelector((state) => {
    return {
      profileState: state.ProfileReducer,
      loginState: state.LoginReducer,
      branchState: state.BranchReducer,
      // mailboxState: state.MailboxReducer,
    };
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  let activeNav = 0;
  const [isAddContactVisible, setIsAddContactVisible] = useState(false);
  const [isAddLeadModelVisible, setIsAddLeadModelVisible] = useState(false);
  const [isAddAppointmentModelVisible, setIsAddAppointmentModelVisible] =
    useState(false);
  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);

  const [isAddClientModalVisible, setIsAddClientModalVisible] = useState(false);
  const [isClientDetailsModelVisible, setIsClientDetailsModelVisible] =
    useState(false);
  const [isContactsDetailsModelVisible, setIsContactsDetailsModelVisible] =
    useState(false);
  const [isLeadsDetailsModelVisible, setIsLeadsDetailsModelVisible] =
    useState(false);
  const [
    isAppointmentDetailsModalVisible,
    setIsAppointmentDetailsModalVisible,
  ] = useState();
  const [isVisaCaseDetailsModalVisible, setIsVisaCaseDetailsModalVisible] =
    useState(false);
  const [isConsultantDetailsModelVisible, setIsConsultantDetailsModelVisible] =
    useState(false);
  const [isInstituteDetailsModelVisible, setIsInstituteDetailsModelVisible] =
    useState(false);
  const [isCourseDetailsModelVisible, setIsCourseDetailsModelVisible] =
    useState(false);
  const [isCompanyDetalisModalVisible, setIsCompanyDetalisModalVisible] =
    useState(false);
  const [isBranchLoginModal, setIsBranchLoginModal] = useState(false);
  const [branchRecord, setBranchRecord] = useState(null);
  const [record, setRecord] = useState(null);
  const [keyword, setKeyword] = useState(null);
  const [permissionList, setPermissionList] = useState([]);
  let keyId = 4;

  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/dashboard"
      : location.pathname
  );
  const [stateChange, setStateChange] = useState({
    hell: "vutvut",
  });
  // const debounce = (func, delay) => {
  //   let timeOutId;
  //   return (...args) => {
  //     clearTimeout(timeOutId);
  //     timeOutId = setTimeout(() => {
  //       func(...args);
  //     }, delay);
  //   };
  // };
  // const handleSearch = debounce(() => {
  //   if (keyword !== "") {
  //     dispatch({
  //       type: "GLOBAL_SEARCH_FROM_DASHBOARD_REQUEST",
  //       payload: { keyword: keyword, page: 1, pageSize: 3 },
  //     });
  //   }
  // }, 3000);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (keyword !== "" && keyword !== null) {
        dispatch({
          type: "GLOBAL_SEARCH_FROM_DASHBOARD_REQUEST",
          payload: {
            branchId: localStorage.getItem("branchId"),
            keyword: keyword,
            page: 1,
            pageSize: 3,
          },
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [keyword]);

  const mailSuccessFunction = (response) => {};
  const branchReloadFn = (response) => {
    if (
      localStorage.getItem("branchName") === null ||
      localStorage.getItem("branchName") === "null" ||
      localStorage.getItem("branchName") === undefined ||
      localStorage.getItem("branchName") === "undefined" ||
      localStorage.getItem("branchName") === "" ||
      localStorage.getItem("branchId") === null ||
      localStorage.getItem("branchId") === "null" ||
      localStorage.getItem("branchId") === undefined ||
      localStorage.getItem("branchId") === "undefined" ||
      localStorage.getItem("branchId") === ""
    ) {
      let headObj = response?.data?.data?.find(
        (dataObj) => dataObj?.isBranch === false
      );
      if (headObj === undefined) {
        headObj = response?.data?.data?.[0];
      }
      localStorage?.setItem("branchName", headObj?.branchName ?? "HEAD BRANCH");
      localStorage?.setItem("branchId", headObj?.id);
      dispatch({
        type: "BRANCH_SELECTED_REQUEST",
        payload: {
          branchRecord: headObj,
        },
      });
      setBranchRecord(headObj);
    }
  };
  const profileSuccessFunction = (response) => {
    const permissionList = Object.entries(
      response?.data?.department?.rolesAndPermissionList
    )?.map((key) => {
      if (key[1] !== null) {
        return key[0];
      }
      return;
    });
    // setPermissionList([...permissionList]);
    setLocalStorage("menuList", permissionList);

    // setLocalStorage(
    //   "permissionList",
    //   response?.data?.department?.rolesAndPermissionList
    // );
  };

  useEffect(() => {
    dispatch({
      type: "FETCH_PROFILE_REQUEST",
      isSuccessFn: true,
      successFunction: profileSuccessFunction,
    });
    dispatch({
      type: "FETCH_BRANCH_REQUEST",
      payload: { isReloadFn: true, reloadFn: branchReloadFn },
    });
  }, []);

  const [idleStateStatus, setIdleSTateStatus] = useState(false);
  // console.log("remaining", remaining, state);

  const onIdle = () => {
    setIdleSTateStatus(false);
  };

  const onActive = () => {
    setIdleSTateStatus(true);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    timeout: 2000,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      // setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  });

  // useEffect(() => {
  //   dispatch({
  //     type: "GLOBAL_SEARCH_FROM_DASHBOARD_REQUEST",
  //     payload: { keyword: keyword, page: 1, pageSize: 1 },
  //   });
  // }, [keyword]);
  // setTimeout(() => {
  //   dispatch({
  //     type: "FETCH_MAILBOX_INBOX_REQUEST",
  //     payload: {
  //       page: 1,
  //       pageSize: 10,
  //       isFromSiderDemo: true,
  //       mailSuccessFunction: mailSuccessFunction,
  //     },
  //   });
  // }, 120000);
  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);
  const [collapsed, setCollapsed] = useState(false);
  const handleClick = (e) => {
    setCurrent(e.key);
  };

  // useEffect(() => {
  //   window.addEventListener("scroll");

  //   return () => {
  //     window.removeEventListener("scroll");
  //   };
  // }, [current]);

  const reminderItems =
    store.profileState?.todayReminders?.data?.length > 0
      ? store.profileState?.todayReminders?.data?.map((dataObj) => {
          return {
            key: dataObj.id,
            label: dataObj.confirmationText,
          };
        })
      : [{ key: 1, label: "No reminders found!" }];
  const notificatonsItems =
    store.profileState?.notifications?.data?.length > 0
      ? store.profileState?.notifications?.data?.map((dataObj) => {
          return {
            key: dataObj.id,
            label: dataObj?.notificationDescription,
          };
        })
      : [{ key: 1, label: "No reminders found!" }];

  //  [
  //   {
  //     key: "1",
  //     label: (
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.antgroup.com"
  //       >
  //         1st menu item
  //       </a>
  //     ),
  //   },
  //   {
  //     key: "2",
  //     label: (
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.aliyun.com"
  //       >
  //         2nd menu item (disabled)
  //       </a>
  //     ),
  //     icon: <SmileOutlined />,
  //     disabled: true,
  //   },
  //   {
  //     key: "3",
  //     label: (
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.luohanacademy.com"
  //       >
  //         3rd menu item (disabled)
  //       </a>
  //     ),
  //     disabled: true,
  //   },
  //   {
  //     key: "4",
  //     danger: true,
  //     label: "a danger item",
  //   },
  // ];
  // const logoutHandler = () => {
  //   dispatch({
  //     type: "LOGOUT_CONSULTANCY_REQUEST",
  //   });
  // };

  const logoutHandler = () => {
    const tempToken = Cookies.get("accessToken");
    axios
      .get("v1/api/customer/logout", {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      })
      .then(() =>
        dispatch({
          type: "LOGOUT_CONSULTANCY_REQUEST",
        })
      )
      .catch((res) => {});
  };
  const menu = (
    <div
      style={{
        background: "white",
        padding: "5px 15px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",

        color: "black",
      }}
    >
      <Link to={"/my-profile"}>
        <p style={{ cursor: "pointer" }}>
          <UserOutlined /> Profile
        </p>
      </Link>
      {/* <Link to={"/change-password"}>
        {" "}
        <p style={{ cursor: "pointer" }}>
          <RiLockPasswordFill /> Change Password
        </p>
      </Link>
      <Link to={"/register-admin"}>
        {" "}
        <p style={{ cursor: "pointer" }}>
          <UserAddOutlined /> Register Admin
        </p>
      </Link>
      <Link to={"/admin-list"}>
        {" "}
        <p style={{ cursor: "pointer" }}>
          <RiAdminFill /> Admin List
        </p>
      </Link> */}
      {/* <Link to={"/roles-and-permission"}>
        <p style={{ cursor: "pointer" }}>
          <AiOutlineKey /> Roles & Permissions
        </p>
      </Link> */}
      {/* <Link to={"/settings"}>
        <p style={{ cursor: "pointer" }}>
          <FiSettings /> Settings
        </p>
      </Link> */}
      <a>
        <p onClick={logoutHandler} style={{ cursor: "pointer" }}>
          <LogoutOutlined /> Log out
        </p>
      </a>
    </div>
  );

  const items = [
    store.profileState?.profile?.department?.rolesAndPermissionList?.CONTACTS?.includes(
      "ADD"
    ) && {
      key: "1",
      label: (
        <a
          onClick={() => {
            setIsAddContactVisible(true);
          }}
        >
          Contact
        </a>
      ),
    },
    store.profileState?.profile?.department?.rolesAndPermissionList?.LEADS?.includes(
      "ADD"
    ) && {
      key: "2",
      label: (
        <a
          onClick={() => {
            setIsAddLeadModelVisible(true);
          }}
        >
          Lead
        </a>
      ),
    },
    store.profileState?.profile?.department?.rolesAndPermissionList?.APPOINTMENTS?.includes(
      "ADD"
    ) && {
      key: "3",
      label: (
        <a
          onClick={() => {
            setIsAddAppointmentModelVisible(true);
          }}
        >
          Appointment
        </a>
      ),
    },
    store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
      "ADD"
    ) && {
      key: "5",
      label: (
        <a
          onClick={() => {
            setIsAddVisasModalVisible(true);
          }}
        >
          Case
        </a>
      ),
    },
    store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
      "ADD"
    ) && {
      key: "4",
      label: (
        <a
          onClick={() => {
            setIsAddClientModalVisible(true);
          }}
        >
          Client
        </a>
      ),
    },
    localStorage.getItem("menuList")?.includes("CHECKIN") && {
      key: "6",
      label: (
        <a
          onClick={() => {
            navigate("/attendance");
          }}
        >
          Check In
        </a>
      ),
    },
  ];

  return (
    <Layout hasSider={true}>
      <Sider
        className="sider"
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          background: "white",
          height: "100vh",

          position: "sticky",
          overflow: "scroll",
          backgroundColor: "#1D1C40",
          overflow: "hidden",
          top: 0,
          left: 0,
        }}
      >
        <div
          className="logo"
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            fontSize: "1.2rem",
            height: "64px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/dashboard")}
        >
          {collapsed ? (
            <img src={khrouchLogo2} style={{ width: "100%" }} />
          ) : (
            <img src={khrouchLogo} style={{ width: "100%" }} />
          )}
        </div>
        <Divider
          style={{
            margin: "0",
            height: "15px",
            borderTop: "1.5px solid rgb(221, 218, 218)",
          }}
        />
        <Menu
          theme="light"
          mode="inline"
          onClick={handleClick}
          selectedKeys={[current]}
          items={[
            localStorage.getItem("menuList")?.includes("DASHBOARD") && {
              key: "1",
              className:
                location.pathname === "/dashboard" ? "style--nabmenu" : "",
              // icon: <GoDashboard />,
              icon: <HomeOutlined />,
              label: (
                <NavLink
                  // className={({ isActive }) =>
                  // }
                  className={({ isActive }) => {
                    if (isActive) {
                      activeNav = 1;
                    }
                  }}
                  to={"/dashboard"}
                >
                  Dashboard
                </NavLink>
              ),
            },
            // localStorage.getItem("menuList")?.includes("LEADS") && {
            //   key: "55",
            //   icon: <AiOutlineUser />,
            //   className:
            //     location.pathname === "/quick-inquiry" ? "style--nabmenu" : "",
            //   label: <Link to={"/quick-inquiry"}>Quick Inquiry</Link>,
            // },
            localStorage.getItem("menuList")?.includes("LEADS") && {
              key: "4",
              icon: <VscDebugStart />,
              className: location.pathname === "/leads" ? "style--nabmenu" : "",
              label: <Link to={"/leads"}>Leads</Link>,
            },
            (localStorage.getItem("menuList")?.includes("CLIENTS") ||
              localStorage.getItem("menuList")?.includes("CONTACTS")) && {
              key: "28",
              icon: <BiUser />,
              className:
                location.pathname === "/clients" ||
                location.pathname === "/contacts"
                  ? "style--nabmenuMain"
                  : "",
              label: "Client Manager",
              children: [
                localStorage.getItem("menuList")?.includes("CLIENTS") && {
                  key: "27",
                  icon: <HiOutlineUsers />,
                  className:
                    location.pathname === "/clients" ? "style--nabmenu" : "",
                  label: <Link to={"/clients"}>Clients</Link>,
                },
                localStorage.getItem("menuList")?.includes("CONTACTS") && {
                  key: "3",
                  icon: <AiOutlineContacts />,
                  className:
                    location.pathname === "/contacts" ? "style--nabmenu" : "",
                  label: <Link to={"/contacts"}>Contacts</Link>,
                },
                ,
              ],
            },
            localStorage.getItem("menuList")?.includes("APPOINTMENTS") && {
              key: "5",
              icon: <AiOutlineClockCircle />,
              className:
                location.pathname === "/appointments" ? "style--nabmenu" : "",
              label: <Link to={"/appointments"}>Appointments</Link>,
            },
            localStorage.getItem("menuList")?.includes("CASES") && {
              key: "21",
              icon: <BsClipboard />,
              className: location.pathname === "/cases" ? "style--nabmenu" : "",
              label: <Link to={"/cases"}>Cases</Link>,
            },
            localStorage.getItem("menuList")?.includes("CASES") && {
              key: "201",
              icon: <BsClipboard />,
              className:
                location.pathname === "/product-services"
                  ? "style--nabmenu"
                  : "",
              label: <Link to={"/product-services"}>Product / Services</Link>,
            },
            localStorage.getItem("menuList")?.includes("QUOTATION") && {
              key: "54",
              icon: <FaQuora />,
              className:
                location.pathname === "/quotation" ? "style--nabmenu" : "",
              label: <Link to={"/quotation"}>Quotation</Link>,
            },
            (localStorage.getItem("menuList")?.includes("CHECKIN") ||
              localStorage.getItem("menuList")?.includes("CHECKIN_REPORT")) && {
              key: "46",
              icon: <AiOutlineLogin />,
              className:
                location.pathname === "/attendance" ||
                location.pathname === "/report"
                  ? "style--nabmenuMain"
                  : "",
              label: "Check In",
              // onClick: { handleButtonClick },
              children: [
                localStorage.getItem("menuList")?.includes("CHECKIN") && {
                  key: "47",
                  icon: <AiOutlineLogin />,
                  className:
                    location.pathname === "/attendance" ? "style--nabmenu" : "",
                  label: <Link to={"/attendance"}>Manage Check In</Link>,
                },
                localStorage
                  .getItem("menuList")
                  ?.includes("CHECKIN_REPORT") && {
                  key: "48",
                  icon: <MdCalendarViewMonth />,
                  className:
                    location.pathname === "/report" ? "style--nabmenu" : "",
                  label: <Link to={"/report"}>Report</Link>,
                },
              ],
            },

            !store.profileState?.profile?.isBranch &&
              localStorage.getItem("menuList")?.includes("BRANCH") && {
                key: "37",
                icon: <VscTypeHierarchySuper />,
                className:
                  location.pathname === "/branch" ? "style--nabmenu" : "",
                label: <Link to={"/branch"}>Branch</Link>,
              },
            (localStorage.getItem("menuList")?.includes("EMPLOYEES") ||
              localStorage.getItem("menuList")?.includes("DEPARTMENT") ||
              localStorage.getItem("menuList")?.includes("ROLES") ||
              localStorage.getItem("menuList")?.includes("CONSULTANTS")) && {
              key: "17",
              icon: <BiUser />,
              className:
                location.pathname === "/manage-employees" ||
                location.pathname === "/manage-department" ||
                location.pathname === "/manage-consultants"
                  ? "style--nabmenuMain"
                  : "",
              label: "Employee Manager",

              children: [
                localStorage.getItem("menuList")?.includes("EMPLOYEES") && {
                  key: "18",
                  icon: <HiOutlineUsers />,
                  className:
                    location.pathname === "/manage-employees"
                      ? "style--nabmenu"
                      : "",
                  label: <Link to={"/manage-employees"}>Manage Employee</Link>,
                },
                localStorage.getItem("menuList")?.includes("DEPARTMENT") && {
                  key: "19",
                  icon: <SiGoogleclassroom />,
                  className:
                    location.pathname === "/manage-department"
                      ? "style--nabmenu"
                      : "",
                  label: (
                    <Link to={"/manage-department"}>Manage User Groups</Link>
                  ),
                },
                // localStorage.getItem("menuList")?.includes("ROLES") && {
                //   key: "52",
                //   icon: <AiOutlineKey />,
                //   label: (
                //     <Link to={"/roles-and-permission"}>
                //       Roles and Permissions
                //     </Link>
                //   ),
                // },
                localStorage.getItem("menuList")?.includes("CONSULTANTS") && {
                  key: "23",
                  icon: <HiOutlineUsers />,
                  className:
                    location.pathname === "/manage-consultants"
                      ? "style--nabmenu"
                      : "",
                  label: (
                    <Link to={"/manage-consultants"}>Manage Consultants</Link>
                  ),
                },
              ],
            },
            localStorage.getItem("menuList")?.includes("CAMPAIGN") && {
              key: "50",
              icon: <MdCampaign />,
              className:
                location.pathname === "/manage-campaign"
                  ? "style--nabmenuMain"
                  : "",
              label: "Campaign Manager",
              children: [
                {
                  key: "51",
                  icon: <MdCampaign />,
                  className:
                    location.pathname === "/manage-campaign"
                      ? "style--nabmenu"
                      : "",
                  label: <Link to={"/manage-campaign"}>Manage Campaign</Link>,
                },
              ],
            },
            localStorage.getItem("menuList")?.includes("RESOURCES") && {
              key: "26",
              icon: <FaGoogleDrive />,
              className:
                location.pathname === "/resources" ? "style--nabmenu" : "",
              label: <Link to={"/resources"}>Khrouch Drive</Link>,
            },

            (localStorage.getItem("menuList")?.includes("COMPANIES") ||
              localStorage.getItem("menuList")?.includes("HEAD_AGENTS") ||
              localStorage.getItem("menuList")?.includes("SUB_AGENTS")) && {
              key: "2",
              icon: <BsPlayCircle />,
              className:
                location.pathname === "/companies" ||
                location.pathname === "/head-agent" ||
                location.pathname === "/sub-agent"
                  ? "style--nabmenuMain"
                  : "",
              label: "Agents",
              children: [
                localStorage.getItem("menuList")?.includes("COMPANIES") && {
                  key: "7",
                  icon: <BiBuildingHouse />,
                  className:
                    location.pathname === "/companies" ? "style--nabmenu" : "",
                  label: <Link to={"/companies"}>Companies</Link>,
                },
                localStorage.getItem("menuList")?.includes("HEAD_AGENTS") && {
                  key: "14",
                  icon: <UserOutlined />,
                  className:
                    location.pathname === "/head-agent" ? "style--nabmenu" : "",
                  label: <Link to={"/head-agent"}>Head Agent</Link>,
                },
                localStorage.getItem("menuList")?.includes("SUB_AGENTS") && {
                  key: "15",
                  icon: <UserOutlined />,
                  className:
                    location.pathname === "/sub-agent" ? "style--nabmenu" : "",
                  label: <Link to={"/sub-agent"}>Sub Agent</Link>,
                },
              ],
            },

            // {
            //   key: "8",
            //   icon: <FaRegBuilding />,
            //   label: "Branch",
            // },
            localStorage.getItem("menuList")?.includes("TASKS") && {
              key: "25",
              icon: <AiOutlineDatabase />,
              className: location.pathname === "/tasks" ? "style--nabmenu" : "",
              label: <Link to={"/tasks"}>Tasks</Link>,
            },
            (localStorage.getItem("menuList")?.includes("EDUCATION_VISAS") ||
              localStorage
                .getItem("menuList")
                ?.includes("MIGRATION_VISAS")) && {
              key: "32",
              icon: <RiVisaLine />,
              className:
                location.pathname === "/education" ||
                location.pathname === "/migration"
                  ? "style--nabmenuMain"
                  : "",
              label: "Visa Type",
              children: [
                localStorage
                  .getItem("menuList")
                  ?.includes("EDUCATION_VISAS") && {
                  key: "33",
                  icon: <HiOutlineBookOpen />,
                  className:
                    location.pathname === "/education" ? "style--nabmenu" : "",
                  label: <Link to={"/education"}>Education</Link>,
                },
                localStorage
                  .getItem("menuList")
                  ?.includes("MIGRATION_VISAS") && {
                  key: "34",
                  icon: <HiOutlinePaperAirplane />,
                  className:
                    location.pathname === "/migration" ? "style--nabmenu" : "",
                  label: <Link to={"/migration"}>Migration</Link>,
                },
                // {
                //   key: "35",
                //   icon: <VscTypeHierarchySuper />,
                //   label: <Link to={"/visaTypeCases"}>Cases</Link>,
                // },
              ],
            },

            (localStorage.getItem("menuList")?.includes("INSTITUTES") ||
              localStorage.getItem("menuList")?.includes("COURSES") ||
              localStorage.getItem("menuList")?.includes("DEGREE")) && {
              key: "10",
              icon: <SlGraduation />,
              className:
                location.pathname === "/institute" ||
                location.pathname === "/courses" ||
                location.pathname === "/degree"
                  ? "style--nabmenuMain"
                  : "",
              label: "Education",
              children: [
                localStorage.getItem("menuList")?.includes("INSTITUTES") && {
                  key: "11",
                  icon: <BsBuilding />,
                  className:
                    location.pathname === "/institute" ? "style--nabmenu" : "",
                  label: <Link to={"/institute"}>Institute/College</Link>,
                },
                localStorage.getItem("menuList")?.includes("COURSES") && {
                  key: "12",
                  icon: <TfiBookmarkAlt />,
                  className:
                    location.pathname === "/courses" ? "style--nabmenu" : "",
                  label: <Link to={"/courses"}>Courses</Link>,
                },
                localStorage.getItem("menuList")?.includes("DEGREE") && {
                  key: "49",
                  icon: <BsBookmark />,
                  className:
                    location.pathname === "/degree" ? "style--nabmenu" : "",
                  label: <Link to={"/degree"}>Degree</Link>,
                },
              ],
            },
            localStorage.getItem("menuList")?.includes("SUPPORT") && {
              key: "16",
              icon: <BiSupport />,
              className:
                location.pathname === "/support" ? "style--nabmenu" : "",
              label: <Link to={"/support"}>Support</Link>,
            },
            // {
            //   key: "29",
            //   icon: <FiSettings />,
            //   label: "Settings",
            //   children: [
            //     {
            //       key: "30",
            //       icon: <TiFlowChildren />,
            //       label: (
            //         <Link
            //           to="/country"
            //           onClick={() =>
            //             setStateChange({ ...stateChange, hell: "tutut" })
            //           }
            //         >
            //           Workflow
            //         </Link>
            //       ),
            //     },
            //     // {
            //     //   key: "40",
            //     //   icon: <TiFlowChildren />,
            //     //   label: <Link to={"/Currency"}>Currency</Link>,
            //     // },
            //   ],
            // },
            localStorage.getItem("menuList")?.includes("COLLEGE_FINDER") && {
              key: "31",
              icon: <FaSearchLocation />,
              className:
                location.pathname === "/collegeFinder" ? "style--nabmenu" : "",
              label: <Link to={"/collegeFinder"}>College Finder</Link>,
            },
            {
              key: "31",
              icon: <IoDocumentOutline />,
              // className:
              //   location.pathname === "/collegeFinder" ? "style--nabmenu" : "",
              label: <Link to={"/document-checklist"}>Document Checklist</Link>,
            },
            localStorage.getItem("menuList")?.includes("TODOS") && {
              key: "36",
              icon: <RiTodoLine />,
              className: location.pathname === "/todo" ? "style--nabmenu" : "",
              label: <Link to={"/todo"}>Todo</Link>,
            },

            // {
            //   key: "45",
            //   icon: <RiTodoLine />,
            //   label: <Link to={"/subscription"}>Subscription</Link>,
            // },
            // {
            //   key: "44",
            //   icon: <RiTodoLine />,
            //   label: (
            //     <Link to={"/stripe-payment-success"}>Payment Success</Link>
            //   ),
            // },
            // {
            //   key: "45",
            //   icon: <RiTodoLine />,
            //   label: <Link to={"/stripe-payment-cancel"}>Payment Failure</Link>,
            // },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: "0 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                style: {
                  fontSize: "20px",
                },
                onClick: () => setCollapsed(!collapsed),
              }
            )}
            <Dropdown
              menu={{
                items,
              }}
              placement="bottom"
            >
              <AiOutlinePlus style={{ fontSize: "20px" }} />
            </Dropdown>
            <Select
              style={{ width: 300 }}
              placeholder="Search here for anything"
              className={"dashboardSearch"}
              suffixIcon={<AiOutlineSearch />}
              prefixIcon={<AiOutlineSearch />}
              value={null}
              showSearch
              loading={store.profileState?.isLoading}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              notFoundContent={null}
              onSearch={(e) => {
                // if (e !== "") {
                //   dispatch({
                //     type: "GLOBAL_SEARCH_FROM_DASHBOARD_REQUEST",
                //     payload: { keyword: e, page: 1, pageSize: 3 },
                //   });
                // }
                // handleSearch();
                setKeyword(e);
              }}
              onChange={() => {}}
            >
              {store.profileState?.globalSearchResult?.customer?.data?.map(
                (dataObj, keyId) => {
                  keyId = keyId + 1;
                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsClientDetailsModelVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {`${dataObj?.firstName ?? ""} ${
                            dataObj?.lastName ?? ""
                          } ${
                            dataObj.email !== null && dataObj.email !== ""
                              ? `(${dataObj.email})`
                              : dataObj.mobile !== null && dataObj.mobile !== ""
                              ? `(${dataObj.mobile})`
                              : ""
                          }`}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          CLIENT
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.contact?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;
                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsContactsDetailsModelVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {`${dataObj?.firstName ?? ""} ${
                            dataObj?.lastName ?? ""
                          } ${
                            dataObj.email !== null &&
                            dataObj.email !== "" &&
                            dataObj.email !== undefined
                              ? `(${dataObj.email ?? ""})`
                              : dataObj.mobile !== null &&
                                dataObj.mobile !== "" &&
                                dataObj.mobile !== undefined
                              ? `(${dataObj.mobile ?? ""})`
                              : ""
                          }`}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          CONTACT
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.leads?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsLeadsDetailsModelVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "83%",
                          }}
                        >
                          {`${dataObj?.firstName ?? ""} ${
                            dataObj?.lastName ?? ""
                          } ${
                            dataObj.email !== null && dataObj.email !== ""
                              ? `(${dataObj.email})`
                              : dataObj.mobile !== null && dataObj.mobile !== ""
                              ? `(${dataObj.mobile})`
                              : ""
                          }`}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          LEAD
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.appointments?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsAppointmentDetailsModalVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {`${dataObj?.firstName ?? ""} ${
                            dataObj?.lastName ?? ""
                          } ${
                            dataObj.email !== null && dataObj.email !== ""
                              ? `(${dataObj.email})`
                              : dataObj.mobile !== null && dataObj.mobile !== ""
                              ? `(${dataObj.mobile})`
                              : ""
                          }`}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          APPOINTMENT
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.cases?.data?.map(
                (dataObj, index) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsVisaCaseDetailsModalVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "83%",
                          }}
                        >
                          {`${dataObj?.customer?.firstName ?? ""} ${
                            dataObj?.customer?.lastName ?? ""
                          } ${
                            dataObj?.customer?.email !== null &&
                            dataObj?.customer?.email !== ""
                              ? `(${dataObj?.customer?.email})`
                              : dataObj?.customer?.mobile !== null &&
                                dataObj?.customer?.mobile !== ""
                              ? `(${dataObj?.customer?.mobile})`
                              : ""
                          }`}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          CASE
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.institute?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsInstituteDetailsModelVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {dataObj.instituteName}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          INSTITUTE
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.course?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsCourseDetailsModelVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {dataObj.name ?? ""}
                          {dataObj.courseCode ? `(${dataObj.courseCode})` : ""}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          COURSE
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.company?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsCompanyDetalisModalVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {dataObj.companyName ?? ""}{" "}
                          {dataObj.email ? `(${dataObj.email})` : ""}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          COMPANY
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.headAgent?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          // setRecord(dataObj);
                          // setIsLeadsDetailsModelVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {`${dataObj?.name ?? ""} ${
                            dataObj.email !== null && dataObj.email !== ""
                              ? `(${dataObj.email})`
                              : dataObj.mobileNumber !== null &&
                                dataObj.mobileNumber !== ""
                              ? `(${dataObj.mobileNumber})`
                              : ""
                          }`}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          HEADAGENT
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.subAgent?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          // setRecord(dataObj);
                          // setIsLeadsDetailsModelVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {`${dataObj?.name ?? ""}  ${
                            dataObj.email !== null && dataObj.email !== ""
                              ? `(${dataObj.email})`
                              : dataObj.mobileNumber !== null &&
                                dataObj.mobileNumber !== ""
                              ? `(${dataObj.mobileNumber})`
                              : ""
                          }`}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          SUBAGENT
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.consultancyEmployees?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsConsultantDetailsModelVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {`${dataObj?.fullName ?? ""} ${
                            dataObj.emailId !== null && dataObj.emailId !== ""
                              ? `(${dataObj.emailId})`
                              : dataObj.phoneNumber !== null &&
                                dataObj.phoneNumber !== ""
                              ? `(${dataObj.phoneNumber})`
                              : ""
                          }`}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          EMPLOYEE
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
            </Select>
          </div>

          {!store.profileState?.profile?.isBranch && (
            <div>
              <Select
                style={{ width: "10rem" }}
                value={localStorage?.getItem("branchName")}
              >
                {/* <div
                  id="scrollableDiv"
                  style={{
                    height: 400,
                    overflow: "auto",
                    padding: "0 16px",
                    border: "1px solid rgba(140, 140, 140, 0.35)",
                  }}
                >
                  <InfiniteScroll
                    dataLength={store.branchState.branches?.data?.length}
                    next={() => {}}
                    hasMore={true}
                    loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                    endMessage={
                      <Divider plain>It is all, nothing more 🤐</Divider>
                    }
                    scrollableTarget="scrollableDiv"
                  > */}

                {store.branchState.branches?.data?.map((dataObj) => {
                  return (
                    <>
                      {(dataObj?.isActive || dataObj?.isBranch === false) && (
                        <Option key={dataObj.id}>
                          <div
                            onClick={() => {
                              // setIsBranchLoginModal(true);
                              setBranchRecord(dataObj);
                              localStorage.setItem(
                                "branchName",
                                dataObj.branchName === null &&
                                  dataObj.isBranch === false
                                  ? "HEAD BRANCH"
                                  : dataObj.branchName
                              );
                              localStorage.setItem("branchId", dataObj.id);
                              dispatch({
                                type: "BRANCH_SELECTED_REQUEST",
                                payload: {
                                  branchRecord: dataObj,
                                },
                              });
                            }}
                            className={"selectOptionDiv"}
                          >
                            {dataObj.branchName === null &&
                            dataObj.isBranch === false
                              ? "HEAD BRANCH"
                              : dataObj.branchName}
                          </div>
                        </Option>
                      )}
                    </>
                  );
                })}
                {/* </InfiniteScroll>
                </div> */}
              </Select>
            </div>
          )}

          <div style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>
            <div style={{ display: "flex", gap: "0.7rem" }}>
              {/* <Tooltip title="Notifications" placement="right">
                <div
                  onClick={() => {
                    dispatch({
                      type: "FETCH_NOTIFICATION_REQUEST",
                    });
                  }}
                >
                  <Dropdown
                    trigger={["click"]}
                    placement={"bottomRight"}
                    menu={{
                      items: notificatonsItems,
                    }}
                  >
                    <Badge
                      count={store.profileState?.notifications?.data?.length}
                    >
                      <IoIosNotificationsOutline className="headerIcons" />
                    </Badge>
                  </Dropdown>
                </div>
              </Tooltip> */}
              {/* <Tooltip title="Today Reminders" placement="right">
                <div
                  onClick={() => {
                    dispatch({
                      type: "FETCH_TODAY_REMINDER_REQUEST",
                      payload: { date: moment().local().format("YYYY-MM-DD") },
                    });
                  }}
                >
                  <Dropdown
                    trigger={["click"]}
                    placement={"bottomRight"}
                    menu={{
                      items: reminderItems,
                    }}
                  >
                    <Badge
                      count={store.profileState?.todayReminders?.data?.length}
                    >
                      <AiOutlineClockCircle className="headerIcons" />
                    </Badge>
                  </Dropdown>
                </div>
              </Tooltip> */}
              {localStorage.getItem("menuList")?.includes("EMAIL") && (
                <Tooltip title="Mailbox">
                  <Link to={"/mailbox"}>
                    <Badge
                      count={store.profileState?.profile?.unseenMailInboxCount}
                    >
                      <AiOutlineMail
                        className="headerIcons"
                        onClick={() => {
                          // dispatch({ type: "MAILBOX_ONCLICK_ZERO_REQUSEST" });
                        }}
                      />
                    </Badge>
                  </Link>
                </Tooltip>
              )}
              {(localStorage
                .getItem("menuList")
                ?.includes("GENERAL_SETTINGS") ||
                localStorage.getItem("menuList")?.includes("IMPORT_SETTINGS") ||
                localStorage
                  .getItem("menuList")
                  ?.includes("TEMPLATE_SETTINGS") ||
                localStorage
                  .getItem("menuList")
                  ?.includes("DASHBOARD_SETTINGS") ||
                localStorage
                  .getItem("menuList")
                  ?.includes("AUTOREMINDER_SETTINGS") ||
                localStorage.getItem("menuList")?.includes("LEAD_SETTINGS") ||
                localStorage
                  .getItem("menuList")
                  ?.includes("APPOINTMENT_SETTINGS") ||
                localStorage.getItem("menuList")?.includes("CASE_SETTINGS") ||
                localStorage
                  .getItem("menuList")
                  ?.includes("QUOTATION_SETTINGS") ||
                localStorage
                  .getItem("menuList")
                  ?.includes("CHECKIN_SETTINGS") ||
                localStorage
                  .getItem("menuList")
                  ?.includes("EMPLOYEE_SETTINGS") ||
                localStorage
                  .getItem("menuList")
                  ?.includes("WORKFLOW_SETTINGS")) && (
                <Tooltip title="Organization Settings">
                  <Link to={"/organizationalSettings/general-settings"}>
                    <FiSettings className="headerIcons" />
                  </Link>
                </Tooltip>
              )}

              <Tooltip title="College Finder">
                <Link to={"/collegeFinder"}>
                  <FaSearchLocation className="headerIcons" />
                </Link>
              </Tooltip>

              <Tooltip title="Khrouch Drive">
                <Link to={"/resources"}>
                  <FaGoogleDrive className="headerIcons" />
                </Link>
              </Tooltip>
            </div>
            <Dropdown overlay={menu}>
              <div
                className=""
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={
                    store.profileState.profile?.profileImage
                      ? store.profileState.profile?.profileImage
                      : profile
                  }
                  alt=""
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    border: "2px solid #999",
                  }}
                />
                <div style={{ color: "black" }}>
                  <span>
                    {store.profileState.profile.firstName ?? ""}{" "}
                    {store.profileState.profile.lastName ?? ""}
                  </span>
                </div>
                <IoIosArrowDown style={{ color: "black" }} />
              </div>
            </Dropdown>
          </div>
        </Header>
        {organizationalSettings === true ? (
          <Content
            className="site-layout-background"
            style={{
              backgroundColor: "#f0f2f5",
            }}
          >
            {children}
          </Content>
        ) : dashboard === true ? (
          <Content
            className="site-layout-background"
            style={{
              margin: "1.5rem 1rem 1rem",
              minHeight: 280,
              backgroundColor: "#f0f2f5",
            }}
          >
            {children}
          </Content>
        ) : (
          <Content
            className="site-layout-background"
            style={{
              margin: "1.5rem 1rem 1rem",
              padding: 24,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        )}
      </Layout>
      {isAddContactVisible && (
        <AddContacts
          fromSiderDemo={true}
          isAddContactVisible={isAddContactVisible}
          setIsAddContactVisible={setIsAddContactVisible}
        />
      )}
      {isAddLeadModelVisible && (
        <AddLead
          fromSiderDemo={true}
          fromContacts={true}
          isAddLeadVisible={isAddLeadModelVisible}
          setIsAddLeadVisible={setIsAddLeadModelVisible}
        />
      )}
      {isAddAppointmentModelVisible && (
        <AddAppointment
          fromSiderDemo={true}
          isAddAppointmentModalVisible={isAddAppointmentModelVisible}
          setIsAddAppointmentModelVisible={setIsAddAppointmentModelVisible}
        />
      )}
      {isAddVisasModalVisible && (
        <AddVisaCases
          navigateCaseList={true}
          isAddVisaCasesModalVisible={isAddVisasModalVisible}
          setIsAddVisaCasesModalVisible={setIsAddVisasModalVisible}
        />
      )}
      {isAddClientModalVisible && (
        <AddClient
          fromSiderDemo={true}
          isAddClientModalVisible={isAddClientModalVisible}
          setIsAddClientModalVisible={setIsAddClientModalVisible}
        />
      )}
      {isClientDetailsModelVisible && (
        <ClientDetails
          Record={record}
          isClientDetailsModelVisible={isClientDetailsModelVisible}
          setIsClientDetailsModelVisible={setIsClientDetailsModelVisible}
        />
      )}

      {isContactsDetailsModelVisible && (
        <ContactDetails
          record={record}
          isContactsDetailsModelVisible={isContactsDetailsModelVisible}
          setIsContactsDetailsModelVisible={setIsContactsDetailsModelVisible}
        />
      )}
      {isLeadsDetailsModelVisible && (
        <LeadDetails
          // leadData={leadData}
          Record={record}
          isLeadsDetailsModelVisible={isLeadsDetailsModelVisible}
          setIsLeadsDetailsModelVisible={setIsLeadsDetailsModelVisible}
        />
      )}
      {isAppointmentDetailsModalVisible && (
        <AppointmentDetails
          // appointmentData={appointmentData}
          appointmentObj={record}
          isAppointmentDetailsModalVisible={isAppointmentDetailsModalVisible}
          setIsAppointmentDetailsModalVisible={
            setIsAppointmentDetailsModalVisible
          }
        />
      )}
      {isVisaCaseDetailsModalVisible && (
        <VisaCaseDetails
          // caseData={caseData}
          visaRecord={record}
          record={record}
          isVisaCaseDetailsModalVisible={isVisaCaseDetailsModalVisible}
          setIsVisaCaseDetailsModalVisible={setIsVisaCaseDetailsModalVisible}
        />
      )}
      {isConsultantDetailsModelVisible && (
        <ConsultantDetails
          record={record}
          consultantDetailsTitle={"Employee Details"}
          isConsultantDetailsModelVisible={isConsultantDetailsModelVisible}
          setIsConsultantDetailsModelVisible={
            setIsConsultantDetailsModelVisible
          }
        />
      )}
      {isInstituteDetailsModelVisible && (
        <InstituteDetais
          Record={record}
          isInstituteDetailsModelVisible={isInstituteDetailsModelVisible}
          setIsInstituteDetailsModelVisible={setIsInstituteDetailsModelVisible}
        />
      )}
      {isCourseDetailsModelVisible && (
        <CoursesDetail
          record={record}
          isCourseDetailsModelVisible={isCourseDetailsModelVisible}
          setIsCourseDetailsModelVisible={setIsCourseDetailsModelVisible}
        />
      )}
      {isCompanyDetalisModalVisible && (
        <CompanyDetails
          record={record}
          isCompanyDetalisModalVisible={isCompanyDetalisModalVisible}
          setIsCompanyDetalisModalVisible={setIsCompanyDetalisModalVisible}
        />
      )}
      {/* {isBranchLoginModal && (
        <BranchLogin
          branchRecord={branchRecord}
          isBranchLoginModalVisible={isBranchLoginModal}
          setIsBranchLoginModalVisible={setIsBranchLoginModal}
        />
      )} */}
    </Layout>
  );
};
export default SiderDemo;
