import React from "react";
import { Button, Carousel, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import logoImage from "./../../Assets/khrouch3.png";
import CarouselComponent from "../Login/CarouselComponent";
import { AiOutlineMail } from "react-icons/ai";

const RegisterEmail = () => {
  const registerEmailState = useSelector((state) => state.SignupReducer);
  const dispatch = useDispatch();
  const registerEmailHandler = (value) => {
    dispatch({ type: "REGISTER_EMAIL_REQUEST", payload: value });
  };
  const contentStyle = {
    height: "100vh",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  return (
    <>
      {registerEmailState.isRegistered ? (
        <Navigate to={"/otpverification"} replace={true} />
      ) : (
        <div style={{ display: "flex" }}>
          <div className="loginContainer">
            <div className="formContainer">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={logoImage} style={{ width: "15%" }} />
              </div>
              <h2 style={{ textAlign: "center" }}>Register Here</h2>
              <p style={{ textAlign: "center", fontSize: "16px" }}>
                Create your account!
              </p>
              <Form
                layout="vertical"
                className="loginForm"
                onFinish={registerEmailHandler}
              >
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter valid company email!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Company Email"
                    size="large"
                    style={{ borderRadius: "25px" }}
                    prefix={
                      <AiOutlineMail
                        style={{ fontSize: "18px", color: "#555555" }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ borderRadius: "2rem" }}
                  >
                    Continue
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className="carousel" style={{ width: "30%" }}>
            <CarouselComponent />
          </div>
        </div>
      )}
    </>
  );
};

export default RegisterEmail;
