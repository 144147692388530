import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  DatePicker,
  Form,
  Popconfirm,
  Segmented,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import { date } from "../../HelperFunction";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import moment from "moment";
const { Option } = Select;

const columns = [
  {
    title: "Employee Name",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "Working Days",
    dataIndex: "workingDays",
    align: "center",
  },
  {
    title: "Actual Working Days",
    dataIndex: "actualWorkingDays",
    align: "center",
  },
  {
    title: "Working Hours",
    dataIndex: "workingHours",
    align: "center",
  },
  {
    title: "Actual Working Hours",
    dataIndex: "actualWorkingHours",
    align: "center",
  },
  {
    title: "Leave Days",
    dataIndex: "leaveDays",
    align: "center",
  },

  //   {
  //     title: "Actions",
  //     dataIndex: "actions",
  //     align: "center",
  //   },
];

const MonthlyReport = () => {
  const monthlyReportState = useSelector((state) => state.AttendanceReducer);
  const [isAddMonthlyReportModalVisible, setIsAddMonthlyReportModalVisible] =
    useState(false);
  const [
    isUpdateMonthlyReportModalVisible,
    setIsUpdateMonthlyReportModalVisible,
  ] = useState(false);
  const [record, setRecord] = useState({});
  const [monthlyReportType, setMonthlyReportType] = useState("ALL");
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  //   useEffect(() => {
  //     if (monthlyReportType === "ACTIVE") {
  //       dispatch({
  //         type: "FETCH_ACTIVE_MONTHLY_REPORT_REQUEST",
  //       });
  //     } else {
  //       dispatch({ type: "FETCH_REPORT_REQUEST" });
  //     }
  //   }, [monthlyReportType]);
  //   useEffect(() => {
  //     if (
  //       isAddMonthlyReportModalVisible === false ||
  //       isUpdateMonthlyReportModalVisible === false
  //     ) {
  //       dispatch({ type: "FETCH_MONTHLY_REPORT_REQUEST" });
  //     }
  //   }, [isAddMonthlyReportModalVisible, isUpdateMonthlyReportModalVisible]);
  const data = [{ ...monthlyReportState?.report, key: 1 }];

  const disabledEndDate = (current) => {
    return (
      current && current < moment(form.getFieldValue("startDate")).endOf("day")
    );
  };

  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          <Form
            layout="horizontal"
            onFinish={(value) => {
              dispatch({
                type: "FETCH_REPORT_REQUEST",
                payload: {
                  startDate: value?.startDate ? date(value?.startDate) : null,
                  endDate: value?.endDate ? date(value?.endDate) : null,
                },
              });
            }}
            colon={true}
            labelAlign="left"
            form={form}
          >
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <Form.Item
                label="Start Date"
                name={"startDate"}
                style={{ width: "100%" }}
                labelAlign="left"
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  disabledDate={(cd) => {
                    const d = new Date();
                    const tempEndDate = form.getFieldValue("endDate");
                    if (tempEndDate) {
                      return cd > moment(tempEndDate).endOf("day");
                    } else {
                      return cd > d;
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label="End Date"
                name={"endDate"}
                style={{ width: "100%" }}
                labelAlign="left"
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  disabledDate={disabledEndDate}
                />
              </Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                style={{ alignSelf: "flex-start" }}
              >
                Search
              </Button>
            </div>
          </Form>
        </div>
        <div style={{ marginTop: "2rem" }}>
          {monthlyReportState?.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table bordered columns={columns} dataSource={data} />
          )}
        </div>
      </div>
      {/* <AddMonthlyReport
        isAddMonthlyReportModalVisible={isAddMonthlyReportModalVisible}
        setIsAddMonthlyReportModalVisible={setIsAddMonthlyReportModalVisible}
      />
      <UpdateMonthlyReport
        record={record}
        isUpdateMonthlyReportModalVisible={isUpdateMonthlyReportModalVisible}
        setIsUpdateMonthlyReportModalVisible={
          setIsUpdateMonthlyReportModalVisible
        }
      /> */}
    </SiderDemo>
  );
};

export default MonthlyReport;
