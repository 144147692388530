import {
  Card,
  Divider,
  Drawer,
  Image,
  Modal,
  Input,
  Tooltip,
  Checkbox,
  Button,
  Tag,
  Form,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Client from "../../Assets/client.png";
import { GoLocation } from "react-icons/go";
import SendComposedMail from "../Mailbox/SendComposedMail";
import ServiceActivitylogComp from "./productServiceDetails/ServiceActivitylogComp";
import ServiceDetailComponent from "./productServiceDetails/ServiceDetailComponent";
import ServiceStatusUpdate from "./productServiceDetails/ServiceStatusUpdate";
import EmailLogDetail from "../../Components/EmailLog/EmailLogDetail";

const ServicePackageDetail = ({
  serviceId,
  setIsServiceDetailModel,
  isServiceDetailModel,
}) => {
  const dispatch = useDispatch();
  const productServiceById = useSelector(
    (store) => store?.QuotationReducer?.productServicebyId
  );
  const [isServiceStatusUpdateVisible, setIsServiceStatusUpdateVisible] =
    useState(false);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [current, setCurrent] = useState("activityLog");

  const fetchEmailLogDetails = () => {
    dispatch({
      type: "FETCH_EMAIL_LOG_DETAILS",
      payload: {
        id: productServiceById?.id,
        forWhat: "SERVICE",
      },
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  useEffect(() => {
    productServiceById && fetchEmailLogDetails();
  }, [productServiceById]);

  useEffect(() => {
    fetchProductServiceById();
    fetchServiceActivityLog();
  }, []);

  const fetchProductServiceById = () => {
    dispatch({
      type: "FETCHH_PRODUCT_SERVICE_BY_ID",
      payload: serviceId,
      isSuccessFn: true,
      successFunction: (res) => {},
    });
  };

  const fetchServiceActivityLog = () => {
    dispatch({
      type: "FETCH_SERVICE_ACTIVITYLOG_REQUEST",
      payload: serviceId,
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  const serviceDetailsTabList = [
    {
      key: "activityLog",
      tab: "Activity Log",
    },
    {
      key: "serviceDetails",
      tab: "Service Details",
    },
    {
      key: "emailLog",
      tab: "Email Log",
    },
  ];

  return (
    <Drawer
      title="Service Details"
      open={isServiceDetailModel}
      onClose={() => {
        setIsServiceDetailModel(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.5rem" }} className="drawerStyle">
        <div className="flexColumnWithoutStyle" style={{}}>
          <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
            {productServiceById?.photo ? (
              <Image
                src={productServiceById?.photo}
                className={"clientImage"}
              />
            ) : (
              <Image src={Client} className={"clientImage"} />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "stretch",
                width: "100%",
                gap: "0.5rem",
                position: "relative",
              }}
            >
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                {productServiceById?.customerId?.fullName}
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.4rem", color: "#aaa" }}
              >
                {productServiceById?.customerId?.mobile && (
                  <span className="flexRowWithGap">
                    <AiOutlinePhone
                      style={{
                        fontSize: "20",
                      }}
                    />{" "}
                    {productServiceById?.customerId?.mobile}
                  </span>
                )}
                {productServiceById?.customerId?.address && (
                  <span className="flexRowWithGap">
                    <GoLocation
                      style={{
                        fontSize: "16",
                      }}
                    />{" "}
                    {productServiceById?.customerId?.address}
                  </span>
                )}
                {productServiceById?.customerId?.email && (
                  <span className="flexRowWithGap">
                    <AiOutlineMail
                      style={{
                        fontSize: "16",
                      }}
                    />{" "}
                    {productServiceById?.customerId?.email}
                  </span>
                )}
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{
                  alignItems: "flex-start",
                  gap: "0.3rem",
                }}
              ></div>

              <div
                className="flexRowWithoutStyle"
                style={{
                  gap: "1rem",
                  position: "absolute",
                  bottom: 0,
                }}
              >
                <Button
                  type="primary"
                  onClick={() => setIsServiceStatusUpdateVisible(true)}
                >
                  Status Update
                </Button>

                <button
                  className="emailAndMessage"
                  onClick={() => {
                    setIsSendComposedEmailModalVisible(true);
                  }}
                >
                  <AiOutlineMail />
                  <span>Email</span>
                </button>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>

        <Card
          bordered={false}
          tabList={serviceDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "activityLog" && (
            <ServiceActivitylogComp
              serviceId={serviceId}
              record={productServiceById}
            />
          )}
          {current === "serviceDetails" && (
            <ServiceDetailComponent record={productServiceById} />
          )}
          {current === "emailLog" && <EmailLogDetail />}
        </Card>
      </Card>
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          mailTemplateType="SERVICE"
          composeMailRecord={{
            toUser: [productServiceById?.customerId?.email],
          }}
          fetchEmailLogDetails={fetchEmailLogDetails}
          forWhatId={productServiceById?.id}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
      {isServiceStatusUpdateVisible && (
        <ServiceStatusUpdate
          fetchProductServiceById={fetchProductServiceById}
          fetchServiceActivityLog={fetchServiceActivityLog}
          serviceData={{
            id: productServiceById?.id,
            status: productServiceById?.serviceStatus,
          }}
          isServiceStatusUpdateVisible={isServiceStatusUpdateVisible}
          setIsServiceStatusUpdateVisible={setIsServiceStatusUpdateVisible}
        />
      )}
    </Drawer>
  );
};

export default ServicePackageDetail;
