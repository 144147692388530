import { Button, Card, Form, Input } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import OrganizationalSettings from "../OrganizationalSettings";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const CheckInSettings = () => {
  const store = useSelector((state) => {
    return {
      profileState: state.ProfileReducer,
    };
  });
  const [checkInSettingsData, setCheckInSettingsData] = useState({
    checkInPrefix: null,
    additionalEmails: [],
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  return (
    <OrganizationalSettings>
      <div style={{ margin: "2rem 0 0 2rem" }}>
        <h3>Check In Settings</h3>
        <Card>
          <Form.Item label="Prefixes">
            <Input
              placeholder="Enter the prefix you want to use"
              onChange={(e) => {
                setCheckInSettingsData((previousData) => {
                  return {
                    checkInPrefix: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              // const filteredEmail = values?.users?.map((dataObj) => {
              //   const emails = checkInSettingsData?.additionalEmails.filter(
              //     (item) => {
              //       console.log("dataObj.email", dataObj.email);
              //       console.log("item.email", item.email);
              //       if (dataObj.email !== item.email) {
              //         return dataObj.email;
              //       }
              //     }
              //   );
              //   console.log("filtered emails case", emails);
              // });
              // console.log("filteredEmail", filteredEmail);

              const formEmail = values?.users?.map((item) => {
                return {
                  email: item.email,
                  isLeads: false,
                  isCase: true,
                  isAppointment: false,
                  isCheckIn: false,
                };
              });
              // dispatch({
              //   type: "ADDITIONAL_EMAILS_FROM_GENERAL_SETTINGS_REQUEST",
              //   payload: { additionalEmails: [...formEmail] },
              // });
            }}
            style={{
              maxWidth: "100%",
            }}
            autoComplete="off"
            fields={checkInSettingsData.additionalEmails?.map((item, index) => {
              return { name: ["users", index, "email"], value: item.email };
            })}

            // fields={[
            //   {
            //     name: ["users", 0, "email"],
            //     value: "anksdfjaksj@saklfd.com",
            //   },
            // ]}
          >
            {/* <div
              style={{
                backgroundColor: "#eeeeee55",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                marginBottom: "0.4rem",
              }}
            >
              <h4>Additional Emails:</h4>
              <Form.List
                fields={[
                  {
                    name: "email",
                    value: "anksdfjaksj@saklfd.com",
                  },
                ]}
                name="users"
                initialValue={checkInSettingsData.additionalEmails?.map(
                  (item, index) => {
                    return {
                      fieldKey: index,
                      isListField: true,
                      key: index,
                    };
                  }
                )}
              >
                {(fields, { add, remove }) => {
                  console.log("fieldsaddEmail", fields);

                  return (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                            width: "100%",
                            alignItems: "center",
                            gap: "0.3rem",
                          }}
                        >
                          <Form.Item
                            label="Additional Email"
                            labelCol={{ span: 4 }}
                            {...restField}
                            name={[name, "email"]}
                            style={{
                              width: "100%",
                              margin: 0,
                              padding: "0",
                            }}
                            rules={[
                              {
                                type: "email",
                                message: "Please enter a valid email",
                              },
                            ]}
                          >
                            <Input
                              type="email"
                              placeholder="e.g. username@gmail.com"
                            />
                          </Form.Item>

                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Additional Email
                        </Button>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
            </div> */}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CHECKIN_SETTINGS?.includes(
              "UPDATE"
            ) && (
              <Form.Item
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    // dispatch({
                    //   type: "UPDATE_PREFIX_REQUEST",
                    //   payload: {
                    //     checkIn: checkInSettingsData?.checkInPrefix,
                    //   },
                    // });
                  }}
                >
                  Save Changes
                </Button>
              </Form.Item>
            )}
          </Form>
        </Card>
      </div>
    </OrganizationalSettings>
  );
};

export default CheckInSettings;
