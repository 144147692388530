import { Result } from "antd";
import React from "react";

const SuccessPage = () => {
  return (
    <div className="campaignForm">
      <div className="campaignActualForm">
        <Result
          status="success"
          title="You have successfully submitted the form!"
        />
      </div>
    </div>
  );
};

export default SuccessPage;
