import {
  Card,
  Divider,
  Drawer,
  Image,
  Modal,
  Input,
  Tooltip,
  Checkbox,
  Button,
  Tag,
  Form,
  Select,
  Empty,
} from "antd";
import React, { useEffect, useState } from "react";
import { FcDocument } from "react-icons/fc";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import visa from "../../../Assets/visa.jpg";
import { AddSubclassDocument } from "./AddSubclassDocument";

const EducationVisaDetails = ({
  record,
  isVisaDetailModelVisible,
  setIsVisaDetailModelVisible,
}) => {
  const dispatch = useDispatch();
  const educationVisasById = useSelector(
    (store) => store?.VisaClassReducer?.educationVisasById
  );

  const [current, setCurrent] = useState("visaDetails");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    fetchEducationSubClassById();
    fetchEducationSubClassDocumentById();
  }, []);

  const fetchEducationSubClassById = () => {
    dispatch({
      type: "FETCH_EDUCATION_VISAS_BYID_REQUEST",
      payload: record?.id,
      isSuccessFn: true,
      successFunction: (res) => {},
    });
  };

  const fetchEducationSubClassDocumentById = () => {
    dispatch({
      type: "FETCH_VISAS_DOCUMENT_BYID_REQUEST",
      payload: record?.id,
      isSuccessFn: true,
      successFunction: (res) => {
        setDocumentList(res?.data);
      },
    });
  };
  const visaDetials = [
    {
      key: "visaDetails",
      tab: "Visa Details",
    },
  ];

  return (
    <Drawer
      title="Visa Details"
      open={isVisaDetailModelVisible}
      onClose={() => {
        setIsVisaDetailModelVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.5rem" }} className="drawerStyle">
        <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <Image
            src={visa}
            alt=""
            className={"clientImage"}
            style={{ background: "#e4edfb" }}
          />

          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              display: "flex",
              gap: "1rem",
            }}
          >
            {educationVisasById?.country}
          </div>
        </div>

        <Card
          bordered={false}
          tabList={visaDetials}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "visaDetails" && (
            <div>
              <Card className="clientCard" style={{ width: "100%" }}>
                <div
                  className="flexColumnWithoutStyle"
                  style={{ width: "100%" }}
                >
                  <div className="flexRowSpaceBetween">
                    Country:
                    <span>
                      {educationVisasById?.country
                        ? educationVisasById?.country
                        : "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                  <div className="flexRowSpaceBetween">
                    Visa Subclass:
                    <span>
                      {educationVisasById?.subClass
                        ? educationVisasById?.subClass
                        : "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "3fr 2fr",
                      gap: "1rem",
                    }}
                  >
                    Descriptioin:
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: educationVisasById?.description,
                        }}
                      ></span>
                    </div>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                  <h2 className="cardHeader">Documents </h2>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      margin: "0.5rem 0",
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={() => {
                        setIsModalVisible(true);
                      }}
                    >
                      Add Document
                    </Button>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.3rem",
                    }}
                  >
                    {documentList?.length === 0 ? (
                      <Empty />
                    ) : (
                      documentList?.map((data) => (
                        <div className="flexRowSpaceBetween">
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <FcDocument style={{ fontSize: "30px" }} />
                            {data?.documentName}
                          </span>
                          <Tooltip>
                            <a href={data?.documentUrl}>
                              <div
                                className="bordered"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Button danger type="primary">
                                  Download
                                </Button>
                              </div>
                            </a>
                          </Tooltip>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </Card>
            </div>
          )}
        </Card>
      </Card>
      {isModalVisible && (
        <AddSubclassDocument
          subclassId={record?.id}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          fetchEducationSubClassDocumentById={
            fetchEducationSubClassDocumentById
          }
        />
      )}
    </Drawer>
  );
};

export default EducationVisaDetails;
