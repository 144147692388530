import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import { AiOutlineCopy, AiOutlineEye, AiOutlineMail } from "react-icons/ai";
import { CiMobile3 } from "react-icons/ci";
import { FiFlag } from "react-icons/fi";
import { threeTagResponses } from "../../HelperFunction";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
const columns = [
  {
    title: "Enquiry Id",
    dataIndex: "enquiryId",
    align: "center",
  },
  {
    title: "Name",
    dataIndex: "Name",
    align: "center",
  },
  {
    title: "Contact",
    dataIndex: "contact",
    align: "center",
  },
  {
    title: "Purpose/Country",
    dataIndex: "purpose",
    align: "center",
  },
  {
    title: "Current Location",
    dataIndex: "currentLocation",
    align: "center",
  },
  {
    title: "Destination Location",
    dataIndex: "destinationLocation",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "Assigned Status",
    dataIndex: "assignedStatus",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const QuickInquiry = () => {
  const store = useSelector((state) => {
    return { quickInquiryState: state.QuickInquiryReducer };
  });
  const [
    isStudentEnquiryDetailsModalVisible,
    setIsStudentEnquiryDetailsModalVisible,
  ] = useState(false);
  const [record, setRecord] = useState({});
  const [quickInquiryType, setQuickInquiryType] = useState("ALL");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    // if (quickInquiryType === "PENDING") {
    //   dispatch({
    //     type: "FETCH_QUICK_INQUIRY_WITH_STATUS_REQUEST",
    //   });
    // } else {
    //   dispatch({ type: "FETCH_ALL_QUICK_INQUIRY_REQUEST" });
    // }
    if (quickInquiryType === "ALL") {
      dispatch({
        type: "FETCH_ALL_QUICK_INQUIRY_REQUEST",
        payload: {
          page,
          pageSize,
          branchId: localStorage.getItem("branchId"),
        },
      });
    } else if (quickInquiryType === "PENDING") {
      dispatch({
        type: "FETCH_PENDING_QUICK_INQUIRY_REQUEST",
        payload: {
          page,
          pageSize,
          branchId: localStorage.getItem("branchId"),
        },
      });
    } else if (quickInquiryType === "ACCEPTED") {
      dispatch({
        type: "FETCH_ACCEPTED_QUICK_INQUIRY_REQUEST",
        payload: {
          page,
          pageSize,
          branchId: localStorage.getItem("branchId"),
        },
      });
    } else if (quickInquiryType === "DECLINED") {
      dispatch({
        type: "FETCH_DECLINED_QUICK_INQUIRY_REQUEST",
        payload: {
          page,
          pageSize,
          branchId: localStorage.getItem("branchId"),
        },
      });
    }
  }, [quickInquiryType]);

  let data;
  if (quickInquiryType === "ALL") {
    data = store.quickInquiryState?.allQuickInquiry?.data?.map((dataObj) => {
      if (dataObj?.enquiryIdIfNotAccepted) {
        return {
          key: dataObj?.enquiryIdIfNotAccepted.id,
          id: dataObj?.enquiryIdIfNotAccepted.id,
          enquiryId: dataObj?.enquiryIdIfNotAccepted?.enquiryId,
          Name: (
            <Tooltip title="View Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsStudentEnquiryDetailsModalVisible(true);
                    setRecord(dataObj?.enquiryIdIfNotAccepted);
                  }}
                >
                  XXXX XXXX
                </div>
              </a>
            </Tooltip>
          ),
          contact: (
            <div className="flexColumnWithoutStyle">
              {dataObj?.enquiryIdIfNotAccepted?.student?.emailId !== "" &&
              dataObj?.enquiryIdIfNotAccepted?.student?.emailId !== null &&
              dataObj?.enquiryIdIfNotAccepted?.student?.emailId !==
                undefined ? (
                <Tooltip title="Send Email">
                  <a>
                    <div className="bordered">
                      <div
                        className="flexRowWithoutStyle"
                        style={{ gap: "2px", color: "000", color: "000" }}
                      >
                        <AiOutlineMail />
                        <a
                          href={`mailto:${dataObj?.enquiryIdIfNotAccepted?.student?.email}`}
                        >
                          {dataObj?.enquiryIdIfNotAccepted?.student?.emailId ??
                            "N/A"}
                        </a>
                      </div>
                    </div>
                  </a>
                </Tooltip>
              ) : null}
              {dataObj?.enquiryIdIfNotAccepted?.student?.phoneNumber !== "" &&
              dataObj?.enquiryIdIfNotAccepted?.student?.phoneNumber !== null &&
              dataObj?.enquiryIdIfNotAccepted?.student?.phoneNumber !==
                undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000" }}
                >
                  <CiMobile3 />
                  <a
                    href={`tel:${dataObj?.enquiryIdIfNotAccepted?.student?.phoneNumber}`}
                  >
                    {dataObj?.enquiryIdIfNotAccepted?.student?.phoneNumber ??
                      "N/A"}
                  </a>
                </div>
              ) : null}
            </div>
          ),
          purpose: (
            <div className="flexColumnWithoutStyle">
              {dataObj?.enquiryIdIfNotAccepted?.purpose !== "" &&
              dataObj?.enquiryIdIfNotAccepted?.purpose !== null &&
              dataObj?.enquiryIdIfNotAccepted?.purpose !== undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000", color: "000" }}
                >
                  <AiOutlineCopy />
                  <span>
                    {dataObj?.enquiryIdIfNotAccepted?.purpose ?? "N/A"}
                  </span>
                </div>
              ) : null}
              {dataObj?.enquiryIdIfNotAccepted?.country !== "" &&
              dataObj?.enquiryIdIfNotAccepted?.country !== null &&
              dataObj?.enquiryIdIfNotAccepted?.country !== undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000" }}
                >
                  <FiFlag />
                  <span>
                    {dataObj?.enquiryIdIfNotAccepted?.country ?? "N/A"}
                  </span>
                </div>
              ) : null}
            </div>
          ),
          currentLocation:
            dataObj?.enquiryIdIfNotAccepted?.currentLocation ?? "N/A",
          destinationLocation:
            dataObj?.enquiryIdIfNotAccepted?.destinationLocation ?? "N/A",
          status: dataObj?.enquiryIdIfNotAccepted?.status
            ? threeTagResponses(
                dataObj?.enquiryIdIfNotAccepted?.status,
                "PENDING",
                "IN_PROGRESS",
                "COMPLETED"
              )
            : "N/A",
          assignedStatus: dataObj?.enquiryIdIfNotAccepted?.assignedStatus
            ? threeTagResponses(
                dataObj?.enquiryIdIfNotAccepted?.assignedStatus === "CANCELLED"
                  ? "CANCELLED"
                  : dataObj?.enquiryIdIfNotAccepted?.lists?.length > 0
                  ? "ASSIGNED"
                  : "UNASSIGNED",
                "CANCELLED",
                "ASSIGNED",
                "UNASSIGNED"
              )
            : "N/A",
          actions: (
            <Space size="middle">
              <Tooltip title="View Details">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStudentEnquiryDetailsModalVisible(true);
                      setRecord(dataObj?.enquiryIdIfNotAccepted);
                    }}
                  >
                    <AiOutlineEye />
                  </div>
                </a>
              </Tooltip>
            </Space>
          ),
        };
      } else {
        return {
          key: dataObj?.enquiryIdIfAccepted.id,
          id: dataObj?.enquiryIdIfAccepted.id,
          enquiryId: dataObj?.enquiryIdIfAccepted?.enquiryId,
          Name: (
            <Tooltip title="View Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsStudentEnquiryDetailsModalVisible(true);
                    setRecord(dataObj?.enquiryIdIfAccepted);
                  }}
                >
                  {dataObj?.enquiryIdIfAccepted?.student?.fullName}
                </div>
              </a>
            </Tooltip>
          ),
          contact: (
            <div className="flexColumnWithoutStyle">
              {dataObj?.enquiryIdIfAccepted?.student?.emailId !== "" &&
              dataObj?.enquiryIdIfAccepted?.student?.emailId !== null &&
              dataObj?.enquiryIdIfAccepted?.student?.emailId !== undefined ? (
                <Tooltip title="Send Email">
                  <a>
                    <div className="bordered">
                      <div
                        className="flexRowWithoutStyle"
                        style={{ gap: "2px", color: "000", color: "000" }}
                      >
                        <AiOutlineMail />
                        <a
                          href={`mailto:${dataObj?.enquiryIdIfAccepted?.student?.email}`}
                        >
                          {dataObj?.enquiryIdIfAccepted?.student?.emailId ??
                            "N/A"}
                        </a>
                      </div>
                    </div>
                  </a>
                </Tooltip>
              ) : null}
              {dataObj?.enquiryIdIfAccepted?.student?.phoneNumber !== "" &&
              dataObj?.enquiryIdIfAccepted?.student?.phoneNumber !== null &&
              dataObj?.enquiryIdIfAccepted?.student?.phoneNumber !==
                undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000" }}
                >
                  <CiMobile3 />
                  <a
                    href={`tel:${dataObj?.enquiryIdIfAccepted?.student?.phoneNumber}`}
                  >
                    {dataObj?.enquiryIdIfAccepted?.student?.phoneNumber ??
                      "N/A"}
                  </a>
                </div>
              ) : null}
            </div>
          ),
          purpose: (
            <div className="flexColumnWithoutStyle">
              {dataObj?.enquiryIdIfAccepted?.purpose !== "" &&
              dataObj?.enquiryIdIfAccepted?.purpose !== null &&
              dataObj?.enquiryIdIfAccepted?.purpose !== undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000", color: "000" }}
                >
                  <AiOutlineCopy />
                  <span>{dataObj?.enquiryIdIfAccepted?.purpose ?? "N/A"}</span>
                </div>
              ) : null}
              {dataObj?.enquiryIdIfAccepted?.country !== "" &&
              dataObj?.enquiryIdIfAccepted?.country !== null &&
              dataObj?.enquiryIdIfAccepted?.country !== undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000" }}
                >
                  <FiFlag />
                  <span>{dataObj?.enquiryIdIfAccepted?.country ?? "N/A"}</span>
                </div>
              ) : null}
            </div>
          ),
          currentLocation:
            dataObj?.enquiryIdIfAccepted?.currentLocation ?? "N/A",
          destinationLocation:
            dataObj?.enquiryIdIfAccepted?.destinationLocation ?? "N/A",
          status: dataObj?.enquiryIdIfAccepted?.status
            ? threeTagResponses(
                dataObj?.enquiryIdIfAccepted?.status,
                "PENDING",
                "IN_PROGRESS",
                "COMPLETED"
              )
            : "N/A",
          assignedStatus: dataObj?.enquiryIdIfAccepted?.assignedStatus
            ? threeTagResponses(
                dataObj?.enquiryIdIfAccepted?.assignedStatus === "CANCELLED"
                  ? "CANCELLED"
                  : dataObj?.enquiryIdIfAccepted?.lists?.length > 0
                  ? "ASSIGNED"
                  : "UNASSIGNED",
                "CANCELLED",
                "ASSIGNED",
                "UNASSIGNED"
              )
            : "N/A",
          actions: (
            <Space size="middle">
              <Tooltip title="View Details">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStudentEnquiryDetailsModalVisible(true);
                      setRecord(dataObj?.enquiryIdIfAccepted);
                    }}
                  >
                    <AiOutlineEye />
                  </div>
                </a>
              </Tooltip>
            </Space>
          ),
        };
      }
    });
  } else if (quickInquiryType === "PENDING") {
    data = store.quickInquiryState?.quickInquiryWithStatus?.data?.map(
      (dataObj) => {
        return {
          key: dataObj.id,
          id: dataObj.id,
          enquiryId: dataObj?.enquiryId,
          Name: dataObj?.student?.fullName,
          contact: (
            <div className="flexColumnWithoutStyle">
              {dataObj?.student?.emailId !== "" &&
              dataObj?.student?.emailId !== null &&
              dataObj?.student?.emailId !== undefined ? (
                <Tooltip title="Send Email">
                  <a>
                    <div className="bordered">
                      <div
                        className="flexRowWithoutStyle"
                        style={{ gap: "2px", color: "000", color: "000" }}
                      >
                        <AiOutlineMail />
                        <a href={`mailto:${dataObj?.student?.email}`}>
                          {dataObj?.student?.emailId ?? "N/A"}
                        </a>
                      </div>
                    </div>
                  </a>
                </Tooltip>
              ) : null}
              {dataObj?.student?.phoneNumber !== "" &&
              dataObj?.student?.phoneNumber !== null &&
              dataObj?.student?.phoneNumber !== undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000" }}
                >
                  <CiMobile3 />
                  <a href={`tel:${dataObj?.student?.phoneNumber}`}>
                    {dataObj?.student?.phoneNumber ?? "N/A"}
                  </a>
                </div>
              ) : null}
            </div>
          ),
          purpose: (
            <div className="flexColumnWithoutStyle">
              {dataObj?.purpose !== "" &&
              dataObj?.purpose !== null &&
              dataObj?.purpose !== undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000", color: "000" }}
                >
                  <AiOutlineCopy />
                  <span>{dataObj?.purpose ?? "N/A"}</span>
                </div>
              ) : null}
              {dataObj?.country !== "" &&
              dataObj?.country !== null &&
              dataObj?.country !== undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000" }}
                >
                  <FiFlag />
                  <span>{dataObj?.country ?? "N/A"}</span>
                </div>
              ) : null}
            </div>
          ),
          currentLocation: dataObj?.currentLocation ?? "N/A",
          destinationLocation: dataObj?.destinationLocation ?? "N/A",
          status: dataObj?.status
            ? threeTagResponses(
                dataObj?.status,
                "PENDING",
                "UNASSIGNED",
                "CANCELLED"
              )
            : "N/A",
          assignedStatus: dataObj?.assignedStatus
            ? threeTagResponses(
                dataObj?.assignedStatus === "CANCELLED"
                  ? "CANCELLED"
                  : dataObj?.lists?.length > 0
                  ? "ASSIGNED"
                  : "UNASSIGNED",
                "CANCELLED",
                "ASSIGNED",
                "UNASSIGNED"
              )
            : "N/A",
          actions: (
            <Space size="middle">
              <Tooltip title="View Details">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStudentEnquiryDetailsModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineEye />
                  </div>
                </a>
              </Tooltip>
            </Space>
          ),
        };
      }
    );
  } else if (quickInquiryType === "ACCEPTED") {
    data = store.quickInquiryState?.quickInquiryWithStatus?.data?.map(
      (dataObj) => {
        return {
          key: dataObj.id,
          id: dataObj.id,
          enquiryId: dataObj?.enquiryId,
          Name: dataObj?.student?.fullName,
          contact: (
            <div className="flexColumnWithoutStyle">
              {dataObj?.student?.emailId !== "" &&
              dataObj?.student?.emailId !== null &&
              dataObj?.student?.emailId !== undefined ? (
                <Tooltip title="Send Email">
                  <a>
                    <div className="bordered">
                      <div
                        className="flexRowWithoutStyle"
                        style={{ gap: "2px", color: "000", color: "000" }}
                      >
                        <AiOutlineMail />
                        <a href={`mailto:${dataObj?.student?.email}`}>
                          {dataObj?.student?.emailId ?? "N/A"}
                        </a>
                      </div>
                    </div>
                  </a>
                </Tooltip>
              ) : null}
              {dataObj?.student?.phoneNumber !== "" &&
              dataObj?.student?.phoneNumber !== null &&
              dataObj?.student?.phoneNumber !== undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000" }}
                >
                  <CiMobile3 />
                  <a href={`tel:${dataObj?.student?.phoneNumber}`}>
                    {dataObj?.student?.phoneNumber ?? "N/A"}
                  </a>
                </div>
              ) : null}
            </div>
          ),
          purpose: (
            <div className="flexColumnWithoutStyle">
              {dataObj?.purpose !== "" &&
              dataObj?.purpose !== null &&
              dataObj?.purpose !== undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000", color: "000" }}
                >
                  <AiOutlineCopy />
                  <span>{dataObj?.purpose ?? "N/A"}</span>
                </div>
              ) : null}
              {dataObj?.country !== "" &&
              dataObj?.country !== null &&
              dataObj?.country !== undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000" }}
                >
                  <FiFlag />
                  <span>{dataObj?.country ?? "N/A"}</span>
                </div>
              ) : null}
            </div>
          ),
          currentLocation: dataObj?.currentLocation ?? "N/A",
          destinationLocation: dataObj?.destinationLocation ?? "N/A",
          status: dataObj?.status
            ? threeTagResponses(
                dataObj?.status,
                "PENDING",
                "UNASSIGNED",
                "CANCELLED"
              )
            : "N/A",
          assignedStatus: dataObj?.assignedStatus
            ? threeTagResponses(
                dataObj?.assignedStatus === "CANCELLED"
                  ? "CANCELLED"
                  : dataObj?.lists?.length > 0
                  ? "ASSIGNED"
                  : "UNASSIGNED",
                "CANCELLED",
                "ASSIGNED",
                "UNASSIGNED"
              )
            : "N/A",
          actions: (
            <Space size="middle">
              <Tooltip title="View Details">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStudentEnquiryDetailsModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineEye />
                  </div>
                </a>
              </Tooltip>
            </Space>
          ),
        };
      }
    );
  } else if (quickInquiryType === "DECLINED") {
    data = store.quickInquiryState?.quickInquiryWithStatus?.data?.map(
      (dataObj) => {
        return {
          key: dataObj.id,
          id: dataObj.id,
          enquiryId: dataObj?.enquiryId,
          Name: dataObj?.student?.fullName,
          contact: (
            <div className="flexColumnWithoutStyle">
              {dataObj?.student?.emailId !== "" &&
              dataObj?.student?.emailId !== null &&
              dataObj?.student?.emailId !== undefined ? (
                <Tooltip title="Send Email">
                  <a>
                    <div className="bordered">
                      <div
                        className="flexRowWithoutStyle"
                        style={{ gap: "2px", color: "000", color: "000" }}
                      >
                        <AiOutlineMail />
                        <a href={`mailto:${dataObj?.student?.email}`}>
                          {dataObj?.student?.emailId ?? "N/A"}
                        </a>
                      </div>
                    </div>
                  </a>
                </Tooltip>
              ) : null}
              {dataObj?.student?.phoneNumber !== "" &&
              dataObj?.student?.phoneNumber !== null &&
              dataObj?.student?.phoneNumber !== undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000" }}
                >
                  <CiMobile3 />
                  <a href={`tel:${dataObj?.student?.phoneNumber}`}>
                    {dataObj?.student?.phoneNumber ?? "N/A"}
                  </a>
                </div>
              ) : null}
            </div>
          ),
          purpose: (
            <div className="flexColumnWithoutStyle">
              {dataObj?.purpose !== "" &&
              dataObj?.purpose !== null &&
              dataObj?.purpose !== undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000", color: "000" }}
                >
                  <AiOutlineCopy />
                  <span>{dataObj?.purpose ?? "N/A"}</span>
                </div>
              ) : null}
              {dataObj?.country !== "" &&
              dataObj?.country !== null &&
              dataObj?.country !== undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000" }}
                >
                  <FiFlag />
                  <span>{dataObj?.country ?? "N/A"}</span>
                </div>
              ) : null}
            </div>
          ),
          currentLocation: dataObj?.currentLocation ?? "N/A",
          destinationLocation: dataObj?.destinationLocation ?? "N/A",
          status: dataObj?.status
            ? threeTagResponses(
                dataObj?.status,
                "PENDING",
                "UNASSIGNED",
                "CANCELLED"
              )
            : "N/A",
          assignedStatus: dataObj?.assignedStatus
            ? threeTagResponses(
                dataObj?.assignedStatus === "CANCELLED"
                  ? "CANCELLED"
                  : dataObj?.lists?.length > 0
                  ? "ASSIGNED"
                  : "UNASSIGNED",
                "CANCELLED",
                "ASSIGNED",
                "UNASSIGNED"
              )
            : "N/A",
          actions: (
            <Space size="middle">
              <Tooltip title="View Details">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStudentEnquiryDetailsModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineEye />
                  </div>
                </a>
              </Tooltip>
            </Space>
          ),
        };
      }
    );
  }
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div
          className="flexRow"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>ALL</div>
                  </div>
                ),
                value: "ALL",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>PENDING</div>
                  </div>
                ),
                value: "PENDING",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>ACCEPTED</div>
                  </div>
                ),
                value: "ACCEPTED",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>DECLINED</div>
                  </div>
                ),
                value: "DECLINED",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setQuickInquiryType(value);
              console.log(value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {store.quickInquiryState?.isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <Table bordered columns={columns} dataSource={data} />
              {quickInquiryType === "ALL" &&
              store.quickInquiryState?.allQuickInquiry?.totalData ? (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={page}
                  defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={
                    store.quickInquiryState?.allQuickInquiry?.totalPage * 10
                  }
                />
              ) : (
                ""
              )}
              {quickInquiryType !== "ALL" &&
              store.quickInquiryState?.quickInquiryWithStatus?.totalData ? (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={page}
                  defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={
                    store.quickInquiryState?.quickInquiryWithStatus?.totalPage *
                    10
                  }
                />
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
    </SiderDemo>
  );
};

export default QuickInquiry;
