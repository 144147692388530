import React from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import logoImage from "./../../Assets/khrouch3.png";
import CarouselComponent from "../Login/CarouselComponent";

const ChangePassword = () => {
  const changePasswordState = useSelector(
    (state) => state.PasswordResetReducer
  );

  const dispatch = useDispatch();
  const changePasswordSubmitHandler = (values) => {
    dispatch({
      type: "CHANGE_PASSWORD_REQUEST",
      payload: values,
    });
  };
  return (
    <>
      {changePasswordState.isPasswordChanged ? (
        <Navigate
          to={`/${localStorage.getItem("domainName")}`}
          replace={true}
        />
      ) : (
        <div style={{ display: "flex" }}>
          <div className="loginContainer">
            <div className="formContainer">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={logoImage} style={{ width: "15%" }} />
              </div>
              <h2 style={{ textAlign: "center" }}>Reset Password!</h2>
              <p style={{ textAlign: "center", fontSize: "16px" }}>
                Your new password must be different from the previously used
                one.
              </p>
              <Form
                layout="vertical"
                className="loginForm"
                onFinish={changePasswordSubmitHandler}
              >
                <Form.Item
                  label="New Password"
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter new password!",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Enter New Password"
                    size="large"
                    style={{ borderRadius: "25px" }}
                  />
                </Form.Item>
                <Form.Item
                  label="Confirm New Password"
                  name={"confirmPassword"}
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm new password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "New Password and confirm new password do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    placeholder="Confirm New Password"
                    size="large"
                    style={{ borderRadius: "25px" }}
                  />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 9 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ borderRadius: "2rem" }}
                  >
                    Continue
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className="carousel" style={{ width: "30%" }}>
            <CarouselComponent />
          </div>
        </div>
      )}
    </>
  );
};

export default ChangePassword;
