import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Input,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import headAgent from "./../../Assets/account.png";
import activeHeadAgent from "./../../Assets/add-contact.png";
import SiderDemo from "../../Components/Layout/SiderDemo";
import AddHeadAgent from "./AddHeadAgent";
import UpdateHeadAgent from "./UpdateHeadAgent";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import { AiOutlinePoweroff } from "react-icons/ai";
import { BiBlock } from "react-icons/bi";
import SendComposedMail from "../Mailbox/SendComposedMail";
const { Search } = Input;

const columns = [
  {
    title: "Head Agent",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    align: "center",
  },
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
    align: "center",
  },
  {
    title: "Mobile Number",
    dataIndex: "mobileNumber",
    align: "center",
  },
  {
    title: "Commission Share",
    dataIndex: "commissionShare",
    align: "center",
  },
  {
    title: "Is Percentage",
    dataIndex: "isPercentage",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
    fixed: "right",
  },
];

const HeadAgent = () => {
  const store = useSelector((state) => {
    return {
      HeadAgentState: state.HeadAgentReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const [isAddHeadAgentModalVisible, setIsAddHeadAgentModalVisible] =
    useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [isUpdateHeadAgentModalVisible, setIsUpdateHeadAgentModalVisible] =
    useState(false);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [headAgentType, setHeadAgentType] = useState("get-all-head-agent");
  const [record, setRecord] = useState({});
  const dispatch = useDispatch();
  const onSearch = (e) => {
    if (headAgentType === "get-all-active-head-agent") {
      dispatch({
        type: "SEARCH_ACTIVE_HEAD_AGENT_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else if (headAgentType === "get-all-head-agent") {
      dispatch({
        type: "SEARCH_HEAD_AGENT_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    }
  };
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  useEffect(() => {
    if (headAgentType === "get-all-active-head-agent") {
      dispatch({
        type: "FETCH_ACTIVE_HEADAGENT_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else {
      dispatch({
        type: "FETCH_HEADAGENT_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    }
  }, [headAgentType, store.branchState?.branchRecord]);

  let data;
  if (headAgentType === "get-all-active-head-agent") {
    data = store?.HeadAgentState.activeHeadAgent?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        name: dataObj.name,
        email: dataObj.email ? (
          <Tooltip title="Send Email">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                {dataObj.email}
              </div>
            </a>
          </Tooltip>
        ) : (
          "N/A"
        ),
        phoneNumber: dataObj.phoneNumber,
        mobileNumber: dataObj.mobileNumber,
        commissionShare: dataObj.commissionShare,
        isPercentage: dataObj.isPercentage ? (
          <Tag color="#00ff00aa">YES</Tag>
        ) : (
          <Tag color="#ff0000aa">NO</Tag>
        ),
        status:
          dataObj.status === "ACTIVE" ? (
            <p className="greenTag">{dataObj.status}</p>
          ) : (
            <p className="redTag">{dataObj.status}</p>
          ),
        createdBy: dataObj.createdBy?.emailId,
        actions: (
          <Space size="middle">
            {store.profileState?.profile?.department?.rolesAndPermissionList?.HEAD_AGENTS?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update HeadAgent">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateHeadAgentModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.HEAD_AGENTS?.includes(
              "DELETE"
            ) && (
              <Tooltip title="Delete HeadAgent">
                <Popconfirm
                  title="Are you sure to delete this HeadAgent?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_HEADAGENT_REQUEST",
                      payload: { id: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip>
            )}
            {dataObj.status === "INACTIVE" &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.HEAD_AGENTS?.includes(
                "CHANGE_STATUS"
              ) && (
                <Tooltip title="Activate Head Agent">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        const actionStatus =
                          dataObj.status === "INACTIVE" ? "active" : "inactive";
                        dispatch({
                          type: "TOGGLE_HEADAGENT_DISABLE_STATUS_REQUEST",
                          payload: { id: dataObj.id, actionStatus },
                        });
                      }}
                    >
                      <AiOutlinePoweroff />
                    </div>
                  </a>
                </Tooltip>
              )}
            {dataObj.status === "ACTIVE" &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.HEAD_AGENTS?.includes(
                "CHANGE_STATUS"
              ) && (
                <Tooltip title="Deactivate Head Agent">
                  <a style={{ color: "red" }}>
                    <div
                      className="bordered"
                      onClick={() => {
                        const actionStatus =
                          dataObj.status === "INACTIVE" ? "active" : "inactive";
                        dispatch({
                          type: "TOGGLE_HEADAGENT_DISABLE_STATUS_REQUEST",
                          payload: { id: dataObj.id, actionStatus },
                        });
                      }}
                    >
                      <BiBlock />
                    </div>
                  </a>
                </Tooltip>
              )}
          </Space>
        ),
      };
    });
  } else {
    data = store?.HeadAgentState.headAgent?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        name: dataObj.name,
        email: dataObj.email ? (
          <Tooltip title="Send Email">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                {dataObj.email}
              </div>
            </a>
          </Tooltip>
        ) : (
          "N/A"
        ),
        phoneNumber: dataObj.phoneNumber,
        mobileNumber: dataObj.mobileNumber,
        commissionShare: dataObj.commissionShare,
        isPercentage: dataObj.isPercentage ? (
          <Tag color="#00ff00aa">YES</Tag>
        ) : (
          <Tag color="#ff0000aa">NO</Tag>
        ),
        status:
          dataObj.status === "ACTIVE" ? (
            <p className="greenTag">{dataObj.status}</p>
          ) : (
            <p className="redTag">{dataObj.status}</p>
          ),
        createdBy: dataObj.createdBy?.emailId,
        actions: (
          <Space size="middle">
            {store.profileState?.profile?.department?.rolesAndPermissionList?.HEAD_AGENTS?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update HeadAgent">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateHeadAgentModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.HEAD_AGENTS?.includes(
              "DELETE"
            ) && (
              <Tooltip title="Delete HeadAgent">
                <Popconfirm
                  title="Are you sure to delete this HeadAgent?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_HEADAGENT_REQUEST",
                      payload: { id: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip>
            )}
            {dataObj.status === "INACTIVE" &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.HEAD_AGENTS?.includes(
                "CHANGE_STATUS"
              ) && (
                <Tooltip title="Activate Head Agent">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        const actionStatus =
                          dataObj.status === "INACTIVE" ? "active" : "inactive";
                        dispatch({
                          type: "TOGGLE_HEADAGENT_DISABLE_STATUS_REQUEST",
                          payload: { id: dataObj.id, actionStatus },
                        });
                      }}
                    >
                      <AiOutlinePoweroff />
                    </div>
                  </a>
                </Tooltip>
              )}
            {dataObj.status === "ACTIVE" &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.HEAD_AGENTS?.includes(
                "CHANGE_STATUS"
              ) && (
                <Tooltip title="Deactivate Head Agent">
                  <a style={{ color: "red" }}>
                    <div
                      className="bordered"
                      onClick={() => {
                        const actionStatus =
                          dataObj.status === "INACTIVE" ? "active" : "inactive";
                        dispatch({
                          type: "TOGGLE_HEADAGENT_DISABLE_STATUS_REQUEST",
                          payload: { id: dataObj.id, actionStatus },
                        });
                      }}
                    >
                      <BiBlock />
                    </div>
                  </a>
                </Tooltip>
              )}
          </Space>
        ),
      };
    });
  }

  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="leadFlexRow">
          <div style={{ width: "30%" }}>
            {store.profileState?.profile?.department?.rolesAndPermissionList?.HEAD_AGENTS?.includes(
              "ADD"
            ) && (
              <button
                className="button"
                onClick={() => {
                  setIsAddHeadAgentModalVisible(true);
                }}
              >
                <span>Add Head Agent</span>
              </button>
            )}
          </div>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={headAgent} size={20} shape="square" />
                    <div>All Head Agent</div>
                  </div>
                ),
                value: "get-all-head-agent",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={activeHeadAgent} size={20} shape="square" />
                    <div>Active Head Agent</div>
                  </div>
                ),
                value: "get-all-active-head-agent",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setHeadAgentType(value);
            }}
          />
          <Search
            className="search"
            style={{
              width: "30%",
              borderRadius: "10px ! important",
            }}
            placeholder="Search for head agent"
            allowClear
            size="large"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              onSearch(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {store?.HeadAgentState.isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <Table
                bordered
                columns={columns}
                dataSource={data}
                pagination={false}
              />

              {headAgentType === "get-all-head-agent" &&
              store?.HeadAgentState?.activeHeadAgent?.totalData ? (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={page}
                  defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={store?.HeadAgentState?.activeHeadAgent?.totalPage * 10}
                />
              ) : null}
              {headAgentType === "get-all-active-head-agent" &&
              store?.HeadAgentState?.activeHeadAgent?.totalData ? (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={page}
                  defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={store?.HeadAgentState?.activeHeadAgent?.totalPage * 10}
                />
              ) : null}
            </>
          )}
        </div>
      </div>
      {isAddHeadAgentModalVisible && (
        <AddHeadAgent
          isAddHeadAgentModalVisible={isAddHeadAgentModalVisible}
          setIsAddHeadAgentModalVisible={setIsAddHeadAgentModalVisible}
        />
      )}
      {isUpdateHeadAgentModalVisible && (
        <UpdateHeadAgent
          record={record}
          isUpdateHeadAgentModalVisible={isUpdateHeadAgentModalVisible}
          setIsUpdateHeadAgentModalVisible={setIsUpdateHeadAgentModalVisible}
        />
      )}
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          composeMailRecord={{
            toUser: [record?.email],
          }}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
    </SiderDemo>
  );
};

export default HeadAgent;
