import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const UpdateCampaign = ({
  record,
  isUpdateCampaignModalVisible,
  setIsUpdateCampaignModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const updateCampaignSubmitHandler = (value) => {
    const formData = {
      //   branchId: branchRecord?.id,
      //   isHeadBranch: isHeadBranch,
      campaignName: value.campaignName,
      firstName:
        value.firstName === undefined || value.firstName === null
          ? null
          : value.firstName,
      lastName:
        value.lastName === undefined || value.lastName === null
          ? null
          : value.lastName,
      email:
        value.email === undefined || value.email === null ? null : value.email,
      countryOfResidence:
        value.countryOfResidence === undefined ||
        value.countryOfResidence === null
          ? null
          : value.countryOfResidence,
      mobile:
        value.mobile === undefined || value.mobile === null
          ? null
          : value.mobile,
      purposeId:
        value.purposeId === undefined || value.purposeId === null
          ? null
          : value.purposeId,
      aboutUsId:
        value.aboutUsId === undefined || value.aboutUsId === null
          ? null
          : value.aboutUsId,

      countryId:
        value.countryId === undefined || value.countryId === null
          ? null
          : value.countryId,
      description:
        value.description === undefined || value.description === null
          ? null
          : value.description,
      leadSourceId:
        value.leadSourceId === undefined || value.leadSourceId === null
          ? null
          : value.leadSourceId,
      referral:
        value.referral === undefined || value.referral === null
          ? null
          : value.referral,

      reminderDateAndTime:
        value.reminderDateAndTime === undefined ||
        value.reminderDateAndTime === null
          ? null
          : value.reminderDateAndTime,
      sendByEmail:
        value.sendByEmail === undefined || value.sendByEmail === null
          ? null
          : value.sendByEmail,
      referredById:
        value.referredById === undefined || value.referredById === null
          ? null
          : value.referredById,
      todayDescription:
        value.todayDescription === undefined || value.todayDescription === null
          ? null
          : value.todayDescription,
      leadType:
        value.leadType === undefined || value.leadType === null
          ? null
          : value.leadType, // branchId: isHeadBranch === false && branchRecord.id,
    };

    dispatch({
      type: "UPDATE_CAMPAIGN_REQUEST",
      payload: { campaignId: record.id, formData },
    });
    form.resetFields();
    setIsUpdateCampaignModalVisible(false);
  };
  return (
    <Modal
      title="Update Campaign"
      open={isUpdateCampaignModalVisible}
      onCancel={() => {
        setIsUpdateCampaignModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateCampaignSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["campaignName"],
            value: record.campaignName,
          },
          {
            name: ["firstName"],
            value: record?.formDetails?.firstName,
          },
          {
            name: ["lastName"],
            value: record?.formDetails?.lastName,
          },
          {
            name: ["email"],
            value: record?.formDetails?.email,
          },
          {
            name: ["countryOfResidence"],
            value: record?.formDetails?.countryOfResidence,
          },
          {
            name: ["purposeId"],
            value: record?.formDetails?.purposeId,
          },
          {
            name: ["aboutUsId"],
            value: record?.formDetails?.aboutUsId,
          },
          {
            name: ["contactId"],
            value: record?.formDetails?.contactId,
          },
          {
            name: ["countryId"],
            value: record?.formDetails?.countryId,
          },
          {
            name: ["mobile"],
            value: record?.formDetails?.mobile,
          },
          {
            name: ["leadSourceId"],
            value: record?.formDetails?.leadSourceId,
          },
          {
            name: ["referral"],
            value: record?.formDetails?.referral,
          },
          {
            name: ["leadStatusId"],
            value: record?.formDetails?.leadStatusId,
          },
          {
            name: ["reminderDateAndTime"],
            value: record?.formDetails?.reminderDateAndTime,
          },
          {
            name: ["sendByEmail"],
            value: record?.formDetails?.sendByEmail,
          },
          {
            name: ["todayDescription"],
            value: record?.formDetails?.todayDescription,
          },
          {
            name: ["referredById"],
            value: record?.formDetails?.referredById,
          },
          {
            name: ["leadType"],
            value: record?.formDetails?.leadType,
          },
          {
            name: ["leadStatusId"],
            value: record?.formDetails?.leadStatusId,
          },
          {
            name: ["description"],
            value: record?.formDetails?.description,
          },
        ]}
      >
        <Form.Item
          label="Campaign Name"
          name={"campaignName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter campaign name!",
            },
          ]}
        >
          <Input placeholder="Campaign name" />
        </Form.Item>

        {/* <Checkbox.Group
            onChange={(e) => {
            }}
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          > */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Form.Item
            name={"firstName"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"firstName"}>Firstname</Checkbox>
          </Form.Item>
          <Form.Item
            name={"lastName"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"lastName"}>Lastname</Checkbox>
          </Form.Item>
          <Form.Item
            name={"email"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"email"}>Email</Checkbox>
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Form.Item
            name={"mobile"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"mobile"}>Mobile</Checkbox>
          </Form.Item>
          <Form.Item
            name={"purposeId"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"purposeId"}>Purpose</Checkbox>
          </Form.Item>
          <Form.Item
            name={"aboutUsId"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"aboutUsId"}>About Us</Checkbox>
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Form.Item
            name={"countryId"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"countryId"}>Country</Checkbox>
          </Form.Item>

          <Form.Item
            name={"leadSourceId"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"leadSourceId"}>Lead Source</Checkbox>
          </Form.Item>
          <Form.Item
            name={"referral"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"referral"}>Referral</Checkbox>
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Form.Item
            name={"reminderDateAndTime"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"reminderDateAndTime"}>
              Reminder Date/Time
            </Checkbox>
          </Form.Item>
          <Form.Item
            name={"sendByEmail"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"sendByEmail"}>Send by Email</Checkbox>
          </Form.Item>
          <Form.Item
            name={"referredById"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"referredById"}>Referred By</Checkbox>
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Form.Item
            name={"leadType"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"leadType"}>Lead Type</Checkbox>
          </Form.Item>

          <Form.Item
            name={"todayDescription"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"todayDescription"}>Today Description</Checkbox>
          </Form.Item>

          <Form.Item
            name={"description"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"description"}>Description</Checkbox>
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Form.Item
            name={"countryOfResidence"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"countryOfResidence"}>
              Country of Residence
            </Checkbox>
          </Form.Item>
        </div>
        {/* </Checkbox.Group> */}

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Campaign
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateCampaign;

// style={{
//   width: "100%",
//   whiteSpace: "nowrap",
//   textOverflow: "ellipsis",
//   overflow: "hidden",
// }}
