import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  roles: {},
  activeRoles: {
    data: [],
    pageNumber: 0,
    totalData: 0,
    totalPage: 0,
  },
  permissions: [],
};

const RoleAndPermissionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_ROLES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ROLES_REQUEST_SUCCESS": {
      return {
        ...state,
        roles: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ROLES_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add RoleAndPermission.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_PERMISSIONS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_PERMISSIONS_REQUEST_SUCCESS": {
      return {
        ...state,
        permissions: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_PERMISSIONS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add RoleAndPermission.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_ROLES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_ACTIVE_ROLES_REQUEST_SUCCESS": {
      return {
        ...state,
        activeRoles: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_ROLES_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_ROLES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_ROLES_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! RoleAndPermission added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_ROLES_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add RoleAndPermission.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_ROLE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_ROLE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Role Updated.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_ROLE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update role.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_PERMISSION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_PERMISSION_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! RoleAndPermission updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_PERMISSION_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update RoleAndPermission.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_ROLES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_ROLES_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! RoleAndPermission deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_ROLES_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete RoleAndPermission.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_ROLES_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_ROLES_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! RoleAndPermission status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_ROLES_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change RoleAndPermission status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default RoleAndPermissionReducer;
