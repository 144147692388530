import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import AddDegree from "../Visas/AddDegree";
import { BiBlock } from "react-icons/bi";
import { AiOutlinePoweroff } from "react-icons/ai";
import UpdateDegree from "./UpdateDegree";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Degree",
    dataIndex: "degreeName",
    align: "center",
  },

  {
    title: "Status",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const Degree = () => {
  const store = useSelector((state) => {
    return {
      degreeState: state.DegreeReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const [isAddDegreeModalVisible, setIsAddDegreeModalVisible] = useState(false);
  const [isUpdateDegreeModalVisible, setIsUpdateDegreeModalVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const [degreeType, setDegreeType] = useState("degree");

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (degreeType === "activeDegree") {
      dispatch({
        type: "FETCH_ACTIVE_DEGREE_REQUEST",
        payload: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else {
      dispatch({
        type: "FETCH_DEGREE_REQUEST",
        payload: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    }
  }, [degreeType, page, pageSize, store.branchState?.branchRecord]);

  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  let data = [];
  if (degreeType === "degree") {
    data = store?.degreeState.degree?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: dataObj.id,
        degreeName: dataObj.degreeName,
        status: dataObj.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {store.profileState?.profile?.department?.rolesAndPermissionList?.DEGREE?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update degree">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateDegreeModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.DEGREE?.includes(
              "DELETE"
            ) && (
              <Tooltip title="Delete degree">
                <Popconfirm
                  title="Are you sure to delete this degree?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_DEGREE_REQUEST",
                      payload: { degreeId: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip>
            )}
            {!dataObj.isActive &&
            store.profileState?.profile?.department?.rolesAndPermissionList?.DEGREE?.includes(
              "CHANGE_STATUS"
            ) ? (
              <Tooltip title="Activate degree">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus = !dataObj.isActive
                        ? "active"
                        : "inactive";
                      dispatch({
                        type: "TOGGLE_DEGREE_STATUS_REQUEST",
                        payload: { degreeId: dataObj.id, status: actionStatus },
                      });
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            ) : store.profileState?.profile?.department?.rolesAndPermissionList?.DEGREE?.includes(
                "CHANGE_STATUS"
              ) ? (
              <>
                <Tooltip title="Deactivate degree">
                  <a style={{ color: "red" }}>
                    <div
                      className="bordered"
                      onClick={() => {
                        const actionStatus = !dataObj.isActive
                          ? "active"
                          : "inactive";
                        dispatch({
                          type: "TOGGLE_DEGREE_STATUS_REQUEST",
                          payload: {
                            degreeId: dataObj.id,
                            status: actionStatus,
                          },
                        });
                      }}
                    >
                      <BiBlock />
                    </div>
                  </a>
                </Tooltip>
              </>
            ) : null}
          </Space>
        ),
      };
    });
  } else {
    data = store?.degreeState.activeDegree?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: dataObj.id,
        degreeName: dataObj.degreeName,
        status: dataObj.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {store.profileState?.profile?.department?.rolesAndPermissionList?.DEGREE?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update degree">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateDegreeModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.DEGREE?.includes(
              "DELETE"
            ) && (
              <Tooltip title="Delete degree">
                <Popconfirm
                  title="Are you sure to delete this degree?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_DEGREE_REQUEST",
                      payload: { degreeId: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip>
            )}
            {dataObj.isActive &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.DEGREE?.includes(
                "CHANGE_STATUS"
              ) && (
                <>
                  <Tooltip title="Deactivate degree">
                    <a style={{ color: "red" }}>
                      <div
                        className="bordered"
                        onClick={() => {
                          const actionStatus = !dataObj.isActive
                            ? "active"
                            : "inactive";
                          dispatch({
                            type: "TOGGLE_DEGREE_STATUS_REQUEST",
                            payload: {
                              degreeId: dataObj.id,
                              status: actionStatus,
                            },
                          });
                        }}
                      >
                        <BiBlock />
                      </div>
                    </a>
                  </Tooltip>
                </>
              )}
          </Space>
        ),
      };
    });
  }
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          {store.profileState?.profile?.department?.rolesAndPermissionList?.DEGREE?.includes(
            "ADD"
          ) && (
            <button
              className="button"
              onClick={() => {
                setIsAddDegreeModalVisible(true);
              }}
            >
              <span>Add Degree</span>
            </button>
          )}
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>All Degree</div>
                  </div>
                ),
                value: "degree",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Active Degree</div>
                  </div>
                ),
                value: "activeDegree",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setDegreeType(value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1rem",
          }}
        >
          <Table
            bordered
            columns={columns}
            dataSource={data}
            pagination={false}
          />

          {store?.degreeState?.degree?.totalData && degreeType === "degree" ? (
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              defaultPageSize={pageSize}
              onChange={onShowSizeChange}
              total={store?.degreeState.degree?.totalPage * 10}
            />
          ) : null}
          {store?.degreeState?.activeDegree?.totalData &&
          degreeType === "activeDegree" ? (
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              defaultPageSize={pageSize}
              onChange={onShowSizeChange}
              total={store?.degreeState.activeDegree?.totalPage * 10}
            />
          ) : null}
        </div>
      </div>
      {isAddDegreeModalVisible && (
        <AddDegree
          isAddDegreeModalVisible={isAddDegreeModalVisible}
          setIsAddDegreeModalVisible={setIsAddDegreeModalVisible}
        />
      )}
      {isUpdateDegreeModalVisible && (
        <UpdateDegree
          record={record}
          isUpdateDegreeModalVisible={isUpdateDegreeModalVisible}
          setIsUpdateDegreeModalVisible={setIsUpdateDegreeModalVisible}
        />
      )}
    </SiderDemo>
  );
};

export default Degree;
