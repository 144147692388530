import { Result } from "antd";
import React from "react";

const ErrorPage = () => {
  return (
    <div className="campaignForm">
      <div className="campaignActualForm">
        <Result
          status="error"
          title="Submission Failed!"
          //   subTitle="Please check and modify the following information before resubmitting."
        />
      </div>
    </div>
  );
};

export default ErrorPage;
