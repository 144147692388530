import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Checkbox,
  Input,
  List,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import { AiOutlinePlus } from "react-icons/ai";
import Confirmation from "../EmployeeManager/Department/Confirmation";
import TodoConfirmation from "./TodoConfirmation";

const Todo = () => {
  const store = useSelector((state) => {
    return {
      todoState: state.TodoReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const [isAddTodoModalVisible, setIsAddTodoModalVisible] = useState(false);
  const [isUpdateTodoModalVisible, setIsUpdateTodoModalVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const [todoType, setTodoType] = useState("todo");
  const [todo, setTodo] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "FETCH_TODO_REQUEST",
      payload: {
        page: 1,
        pageSize: 100,
        branchId:
          store.branchState?.branchRecord.id ??
          localStorage.getItem("branchId"),
      },
    });
  }, [store.branchState?.branchRecord]);
  const [isConfirmationTextModalVisible, setIsConfirmationTextModalVisible] =
    useState(false);

  return (
    <SiderDemo>
      <div
        className="flexColumnwithoutStyle drawerStyle"
        style={{ gap: "2rem" }}
      >
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Input
              type="text"
              size="large"
              placeholder="Write tasks for today..."
              value={todo}
              style={{ borderRadius: "2rem" }}
              onChange={(e) => {
                setTodo(e.target.value);
              }}
            />
            {store.profileState?.profile?.department?.rolesAndPermissionList?.TODOS?.includes(
              "ADD"
            ) && (
              <Tooltip title="Add Todo">
                <a
                  className="plusButton"
                  style={{ height: "2.2rem", width: "2.2rem" }}
                  onClick={() => {
                    todo &&
                      dispatch({
                        type: "ADD_TODO_REQUEST",
                        payload: {
                          toDoTask: todo?.trim(),
                          branchId:
                            store.branchState?.branchRecord.id ??
                            localStorage.getItem("branchId"),
                        },
                      });
                    setTodo(null);
                  }}
                >
                  <AiOutlinePlus
                    className="iconColor"
                    style={{ fontSize: "35px" }}
                  />
                </a>
              </Tooltip>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <List
            loading={store?.todoState.isLoading}
            style={{
              ...styles.todoCard,
            }}
            size="large"
            header={<h2>Remaining Todos</h2>}
            //   footer={<div>Footer</div>}
            bordered
            dataSource={store?.todoState.todoList?.data?.filter(
              (x) => x.isCompleted === false
            )}
            renderItem={(item) => (
              <List.Item
                // onClick={() => {
                //   setIsConfirmationTextModalVisible(true);
                //   setRecord(item);
                // }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {store.profileState?.profile?.department?.rolesAndPermissionList?.TODOS?.includes(
                  "CHANGE_STATUS"
                ) && (
                  <Checkbox
                    checked={false}
                    onClick={() => {
                      dispatch({
                        type: "CHANGE_TODO_STATUS_REQUEST",
                        payload: {
                          id: item.id,
                          value: { confirmationText: "confirm" },
                        },
                      });
                    }}
                  >
                    <div
                      style={{
                        textDecorationLine: item.isCompleted
                          ? "line-through"
                          : "none",
                        color: item.isCompleted ? "red" : "black",
                      }}
                    >
                      {item?.todoTask}
                    </div>
                  </Checkbox>
                )}
              </List.Item>
            )}
          />
          <List
            loading={store?.todoState.isLoading}
            style={styles.todoCard}
            size="large"
            header={<h2>Completed Todos</h2>}
            //   footer={<div>Footer</div>}
            bordered
            dataSource={store?.todoState.todoList?.data?.filter(
              (x) => x.isCompleted === true
            )}
            renderItem={(item) => (
              <List.Item
                // onClick={() => {
                //   setIsConfirmationTextModalVisible(true);
                //   setRecord(item);
                // }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {store.profileState?.profile?.department?.rolesAndPermissionList?.TODOS?.includes(
                  "CHANGE_STATUS"
                ) && (
                  <Checkbox
                    checked={true}
                    onClick={() => {
                      dispatch({
                        type: "CHANGE_TODO_STATUS_REQUEST",
                        payload: {
                          id: item.id,
                          value: { confirmationText: "confirm" },
                        },
                      });
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        textDecorationLine: item.isCompleted
                          ? "line-through"
                          : "none",
                        color: item.isCompleted ? "red" : "black",
                      }}
                    >
                      {item?.todoTask}
                    </div>
                  </Checkbox>
                )}
              </List.Item>
            )}
          />
        </div>
      </div>

      {isConfirmationTextModalVisible && (
        <TodoConfirmation
          onSubmit={(value) => {
            dispatch({
              type: "CHANGE_TODO_STATUS_REQUEST",
              payload: { id: record.id, value },
            });
            setIsConfirmationTextModalVisible(false);
          }}
          labelText={
            record?.isCompleted
              ? "Are you sure you undo this task?"
              : "Are you sure you completed this task?"
          }
          isconfirmationModalVisible={isConfirmationTextModalVisible}
          setIsconfirmationModalVisible={setIsConfirmationTextModalVisible}
        />
      )}
    </SiderDemo>
  );
};

export default Todo;

const styles = {
  todoCard: { borderRadius: "2rem", width: "48%" },
};
