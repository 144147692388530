import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  TimePicker,
} from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;
const { TextArea } = Input;

const BookAppointment = ({
  isBookAppointmentModalVisible,
  setIsBookAppointmentModalVisible,
}) => {
  const bookAppointmentState = useSelector((state) => state.LeadReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isBookAppointmentModalVisible === true) {
      dispatch({
        type: "FETCH_LEADS_DROPDOWN_REQUEST",
      });
    }
  }, [isBookAppointmentModalVisible]);
  const [form] = Form.useForm();
  const bookAppointmentSubmitHandler = (value) => {
    const formData = {
      ...value,
    };
    dispatch({ type: "BOOK_APPOINTMENT_REQUEST", payload: formData });
    // form.resetFields();
    setIsBookAppointmentModalVisible(false);
  };
  return (
    <Modal
      title="Book Appointment"
      open={isBookAppointmentModalVisible}
      onCancel={() => {
        setIsBookAppointmentModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={bookAppointmentSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Booking Date"
            name={""}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a booking date!",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>
          <Form.Item
            label="Booking Time"
            name={""}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter !",
              },
            ]}
          >
            <TimePicker />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Consultant"
            name={""}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter",
              },
            ]}
          >
            <Select>
              {bookAppointmentState.leadsDropdown?.consultantList?.map(
                (dataObj) => {
                  return (
                    <Option key={dataObj.id}>
                      {dataObj.firstName} {dataObj.lastName}
                    </Option>
                  );
                }
              )}
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          label="Today's Update"
          name={""}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter",
            },
          ]}
        >
          <TextArea placeholder="Something about update" />
        </Form.Item>
        <Form.Item
          label="Status"
          name={""}
          rules={[
            {
              required: true,
              message: "Please enter a status!",
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Book an Appointment
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BookAppointment;
