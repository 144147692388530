import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;
const { TextArea } = Input;

const AddConsultancy = ({
  isAddConsultancyModalVisible,
  setIsAddConsultancyModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addConsultancySubmitHandler = (value) => {
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_CONSULTANCY_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddConsultancyModalVisible(false);
  };
  return (
    <Drawer
      title="Add Consultancy"
      open={isAddConsultancyModalVisible}
      onClose={() => {
        setIsAddConsultancyModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={addConsultancySubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="First Name"
            name={"firstName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter first name!",
              },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name={"lastName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter last name!",
              },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Email"
            name={"email"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter valid email",
              },
            ]}
          >
            <Input placeholder="e.g. name@domainname.com" />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name={"phoneNumber"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter phone number",
              },
            ]}
          >
            <Input type="number" placeholder="phoneNumber" />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Company"
            name={"company"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter company!",
              },
            ]}
          >
            <Input placeholder="company" />
          </Form.Item>
          <Form.Item
            label="Competency"
            name={"competency"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter Competency!",
              },
            ]}
          >
            <Input placeholder="competency" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Position"
            name={"position"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a position!",
              },
            ]}
          >
            <Input placeholder="Position" />
          </Form.Item>
          <Form.Item
            label="Fuel Allowance"
            name={"fuelAllowance"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter fuel allowance!",
              },
            ]}
          >
            <Input type="number" placeholder="Fuel Allowance" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Total Allowance"
            name={"totalAllowance"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter total tllowance!",
              },
            ]}
          >
            <Input type="number" placeholder="Total Allowance" />
          </Form.Item>
          <Form.Item
            label="ABN"
            name={"abn"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter abn!",
              },
            ]}
          >
            <Input type="number" placeholder="abn" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Subhub"
            name={"subrub"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter Subrub!",
              },
            ]}
          >
            <Input placeholder="subrub" />
          </Form.Item>
          <Form.Item
            label="Transport"
            name={"transport"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a transport!",
              },
            ]}
          >
            <Input placeholder="Transport" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Wage"
            name={"wage"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter wage!",
              },
            ]}
          >
            <Input type="number" placeholder="Wage" />
          </Form.Item>
          <Form.Item
            label="Productivity Allowance"
            name={"productivityAllowance"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter productivity allowance!",
              },
            ]}
          >
            <Input type="number" placeholder="Productivity Allowance" />
          </Form.Item>
        </div>
        <Form.Item
          label="Comment"
          name={"comment"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter comment!",
            },
          ]}
        >
          <TextArea type="number" placeholder="Write a comment" />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Consultancy
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddConsultancy;
