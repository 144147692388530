import { Button, DatePicker, Divider, Form, Input, Select } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { scrollToTopFormError } from "../../../HelperFunction";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

export default function UpdateClientInformation({
  setCurrent,
  caseFormData,
  setCaseFormData,
}) {
  const { Option } = Select;
  const dispatch = useDispatch();
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
    };
  });

  const [activeEmployees, setActiceEmployees] = useState([]);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    fetchActiveEmployeList();
  }, []);

  const fetchActiveEmployeList = async () => {
    const tempToken = Cookies.get("accessToken");
    await axios
      .get(`v1/api/client/get-active-user-list`, {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      })
      .then((res) => {
        setActiceEmployees(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: "FETCH_LEADS_DROPDOWN_REQUEST",
      payload: {
        branchId: localStorage.getItem("branchId"),
        fromInstituteVisaCase: true,
        visaSuccessFunction: () => {},
      },
    });
    dispatch({
      type: "FETCH_CASES_DROPDOWN_REQUEST",
      payload: {
        branchId: localStorage.getItem("branchId"),
      },
    });
  }, []);

  const handleUpdateCustomerInfo = (values) => {
    setCaseFormData((prev) => {
      return {
        ...prev,
        ...values,
      };
    });
    setCurrent((prev) => prev + 1);
  };

  return (
    <div>
      <Form
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
          marginTop: "1rem",
        }}
        layout="vertical"
        initialValues={{
          firstName: caseFormData?.firstName,
          lastName: caseFormData?.lastName,
          email: caseFormData?.email,
          mobile: caseFormData?.mobile,
          dob: caseFormData?.dob,
          phone: caseFormData?.phone,
          address: caseFormData?.address,
          passportNumber: caseFormData?.passportNumber,
          maritalStatus: caseFormData?.maritalStatus,
          sexStatus: caseFormData?.sexStatus,
          countryOfResidence: caseFormData?.countryOfResidence,
          leadType: caseFormData?.leadType,
          salesRepresentative: caseFormData?.salesRepresentative,
          assignedToEmail: caseFormData?.assignedToEmail,
        }}
        onFinish={handleUpdateCustomerInfo}
        onFinishFailed={scrollToTopFormError}
      >
        <Form.Item name="firstName" label="First Name">
          <Input readOnly placeholder="Firstname" />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name">
          <Input readOnly placeholder="Lastname" />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input readOnly placeholder="Email" />
        </Form.Item>
        <Form.Item name="mobile" label="Mobile">
          <Input type="number" readOnly placeholder="Mobile Number" />
        </Form.Item>

        {!showMore && (
          <div style={{ margin: "0 0 1rem", gridColumn: "span 2" }}>
            <Divider style={{ margin: 0 }} />
            <Button
              type="outlined"
              size="small"
              icon={<DownOutlined />}
              onClick={() => {
                setShowMore(true);
              }}
            >
              View More
            </Button>
          </div>
        )}

        {showMore && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
              gridColumn: "span 2",
            }}
          >
            <Form.Item name="dob" label="Date of Birth">
              <DatePicker format="DD-MM-YYYY" readOnly placeholder="DOB" />
            </Form.Item>
            <Form.Item name="phone" label="Phone">
              <Input type="number" readOnly placeholder="Phone Number" />
            </Form.Item>
            <Form.Item name="address" label="Address">
              <Input placeholder="Address" />
            </Form.Item>
            <Form.Item name="passportNumber" label="Passport Number">
              <Input type="number" placeholder="Passport Number" />
            </Form.Item>
            <Form.Item name="maritalStatus" label="Marital Status">
              <Select placeholder="Select Marital Status">
                <Option value="SINGLE">Single</Option>
                <Option value="MARRIED">Married</Option>
                <Option value="DIVORCED">Divorced</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Gender" name="sexStatus">
              <Select placeholder="Select Gender">
                <Option value={"MALE"}>Male</Option>
                <Option value={"FEMALE"}>Female</Option>
                <Option value={"OTHERS"}>Others</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Country of Residence"
              name="countryOfResidence"
              style={{ gridColumn: "span 2" }}
            >
              <Select showSearch>
                {store.leadState?.leadsDropdown?.countries?.map((dataObj) => {
                  return (
                    <Option key={dataObj.id} value={dataObj.countryName}>
                      {dataObj.countryName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Lead Type" name="leadType">
              <Select>
                <Option value={"SELF ASSIGNED"}>Self Assigned</Option>
                <Option value={"COMPANY ASSIGNED"}>Company Assigned</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Sales Representative" name="salesRepresentative">
              <Select
                onChange={(value, option) => {
                  setCaseFormData((prev) => {
                    return {
                      ...prev,
                      salesRepresentativeId: option?.key,
                    };
                  });
                }}
                placeholder="Select Sales Representative"
              >
                {store.caseState.caseDropdown?.salesRepresentative?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id} value={dataObj.salesRepName}>
                        {dataObj.salesRepName}
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <div
              style={{
                gridColumn: "span 2",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Divider style={{ margin: "0 0 1.5rem" }} />
              <Form.Item
                label="Case Assign to"
                name={"assignedToEmail"}
                style={{ width: "100%" }}
              >
                <Select placeholder="Select Case Assign to">
                  {activeEmployees?.map((emp) => (
                    <Select.Option value={emp.emailId}>
                      {emp.fullName}({emp.emailId})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <Button
                type="outlined"
                size="small"
                icon={<UpOutlined />}
                onClick={() => {
                  setShowMore(false);
                }}
              >
                View Less
              </Button>

              <Divider style={{ margin: "0" }} />
            </div>
          </div>
        )}
        <div
          style={{
            gridColumn: "span 2",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              width: "fit-content",
            }}
            type="primary"
            htmlType="submit"
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
}
