import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  companies: [],
  activeCompanies: [],
};

const CompaniesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ADD_COMPANY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_COMPANY_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Company added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_COMPANY_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add company.");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_COMPANIES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_COMPANIES_REQUEST_SUCCESS": {
      return {
        ...state,
        companies: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_COMPANIES_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add company.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_COMPANIES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_ACTIVE_COMPANIES_REQUEST_SUCCESS": {
      return {
        ...state,
        activeCompanies: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_COMPANIES_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "UPDATE_COMPANY_PACKAGE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_COMPANY_PACKAGE_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_COMPANY_PACKAGE_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_COMPANY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_COMPANY_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Company updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_COMPANY_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update company.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_COMPANY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_COMPANY_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Company deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_COMPANY_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to delete company.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_COMPANY_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_COMPANY_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Company status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_COMPANY_STATUS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to change company status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default CompaniesReducer;
