import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { saveSvgAsPng } from "save-svg-as-png";
import { QRCodeCanvas } from "qrcode.react";
// import { QRCode } from "antd";

const QRcode = ({ record, isQRcodeModalVisible, setIsQRcodeModalVisible }) => {
  const store = useSelector((state) => {
    return {
      profileState: state.LoginReducer,
      loginState: state.ProfileReducer,
      campaignState: state.CampaignReducer,
    };
  });
  const qrCodeRef = useRef(null);
  const dispatch = useDispatch();
  const [downloadClicked, setDownloadClicked] = useState(true);
  useEffect(() => {
    dispatch({
      type: "FETCH_QR_CODE_OF_CAMPAIGN_REQUEST",
      payload: { campaignId: record.id },
    });
  }, []);
  //   useEffect(() => {
  //     if (downloadClicked) {
  //       const dataUrl = qrCodeRef?.current?.toDataURL();
  //       const downloadLink = document.createElement("a");
  //       downloadLink.href = dataUrl;
  //       downloadLink.download = "my-qrcode.png";
  //       downloadLink.click();
  //     }
  //   }, [downloadClicked]);

  const handleDownload = () => {};

  const downloadQRCode = () => {
    const canvas = document
      .querySelector(".ant-modal-body")
      ?.querySelector("div")
      ?.querySelector("canvas");

    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = "QRCode.png";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <Modal
      title={"QR Code"}
      open={isQRcodeModalVisible}
      onCancel={() => {
        setIsQRcodeModalVisible(false);
      }}
      footer={null}
    >
      <div
        className="flexCenter"
        style={{
          flexDirection: "column",
          height: "auto",
          gap: "1rem",
          padding: 0,
          margin: 0,
        }}
      >
        <QRCodeCanvas
          size={256}
          value={store.campaignState?.qrUrlLink?.longUrl}
          ref={qrCodeRef}
        />
        {/* <QRCode value={store.campaignState?.qrUrlLink} ref={qrCodeRef} /> */}
        <Button
          type="primary"
          onClick={downloadQRCode}
          style={{ borderRadius: "1rem" }}
        >
          Download as PNG
        </Button>
      </div>
    </Modal>
  );
};

export default QRcode;
