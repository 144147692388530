import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  description: {
    width: "85%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    fontSize: 9,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
    fontSize: 9,
  },
});

const PackageTableFooter = ({ name, record, packages }) => {
  let total = 0;
  if (name === "Regular Price") {
    total = packages
      .map((item) => 1 * item.unitPrice)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    console.log(
      "total ampint from the pdf Regular Price name === Regular Price",
      total
    );
  } else if (name === "Sub Total") {
    if (record?.percentage) {
      total = packages
        .map((item) => 1 * item.unitPrice)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      total = total - +record?.discount * 0.01 * total;
      console.log(
        "total ampint from the pdf Sub Total record?.percentage",
        total
      );
    } else if (record?.percentage === false) {
      total = packages
        .map((item) => 1 * item.unitPrice)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      total = total - +record?.discount;
      console.log(
        "total ampint from the pdf Sub Total record?.percentage false",
        total
      );
    }
  } else if (name === "Total Price") {
    if (record?.percentage) {
      total = packages
        .map((item) => 1 * item.unitPrice)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      total = total - +record?.discount * total;
      total = total + +record?.gstAmount * 0.01 * total;
      total = 0;
      console.log("total ampint from the pdf name === Total Price", total);
    } else if (record?.percentage === false) {
      total = packages
        .map((item) => 1 * item.unitPrice)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      total = total - +record?.discount;
      total = total + +record?.gstAmount * 0.01 * total;
      console.log(
        "total ampint from the pdf Total Price record?.percentage === false",
        total
      );
    }
  }

  console.log("total ampint from the pdf overall", total);
  return (
    <View style={styles.row}>
      <Text style={styles.description}>{name}</Text>
      <Text style={styles.total}>{Number.parseFloat(total).toFixed(2)}</Text>
    </View>
  );
};

export default PackageTableFooter;
