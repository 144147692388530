import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { VscLock } from "react-icons/vsc";
import { setLocalStorage } from "../../../Helpers/FrontendHelper";
const { Option } = Select;

const BranchLogin = ({
  branchRecord,
  isBranchLoginModalVisible,
  setIsBranchLoginModalVisible,
}) => {
  const loginState = useSelector((state) => state.LoginReducer);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const branchLoginSubmitHandler = (value) => {
    if (branchRecord?.loginEmail) {
      dispatch({
        type: "COMPANY_EMAIL_AUTHENTICATION_REQUEST",
        payload: {
          companyEmail: branchRecord?.loginEmail,
          values: {
            userName: branchRecord?.loginEmail,
            password: value?.password,
          },
          isFromBranchLogin: true,
        },
      });
      setLocalStorage("isHeadOfficeLogin", true);
    }
    form.resetFields();
    setIsBranchLoginModalVisible(false);
  };
  return (
    <Modal
      open={isBranchLoginModalVisible}
      onCancel={() => {
        setIsBranchLoginModalVisible(false);
      }}
      footer={null}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{ margin: "0 auto", padding: "1rem", borderRadius: "20px" }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            {loginState?.companyLogoDetails?.companyLogo ? (
              <img
                src={loginState?.companyLogoDetails?.companyLogo}
                style={{ width: "25%", borderRadius: "0.5rem" }}
              />
            ) : (
              <h1>{loginState?.companyLogoDetails?.companyName}</h1>
            )}
          </div>
          <p style={{ textAlign: "center", fontSize: "16px" }}>
            Login to your branch!
          </p>
          <Form
            layout="vertical"
            className="loginForm"
            onFinish={branchLoginSubmitHandler}
          >
            <div className="emailAndPassword">
              <Form.Item
                name={"password"}
                rules={[
                  {
                    required: true,
                    message: "Please enter Password!",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  size="large"
                  prefix={<VscLock />}
                  style={{ borderRadius: "25px" }}
                />
              </Form.Item>

              <Form.Item
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ borderRadius: "2rem" }}
                >
                  SIGN IN
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default BranchLogin;
