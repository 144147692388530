import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../../Components/Layout/SiderDemo";
import AddMigrationVisa from "./AddMigrationVisa";
import UpdateMigrationVisa from "./UpdateMigrationVisa";
import Cookies from "js-cookie";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import EducationVisaDetails from "../Education/EducationVisaDetails";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Country",
    dataIndex: "country",
    align: "center",
  },
  {
    title: "Sub Class",
    dataIndex: "subClass",
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
    fixed: "right",
  },
];

const MigrationVisas = () => {
  const store = useSelector((state) => {
    return {
      migrationState: state.VisaClassReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const [isAddMigrationVisasModalVisible, setIsAddMigrationVisasModalVisible] =
    useState(false);
  const [
    isUpdateMigrationVisasModalVisible,
    setIsUpdateMigrationVisasModalVisible,
  ] = useState(false);
  const [isVisaDetailModelVisible, setIsVisaDetailModelVisible] =
    useState(false);
  const [record, setRecord] = useState({});

  const dispatch = useDispatch();
  const [countryList, setCountryList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [country, setCountry] = useState("");
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  useEffect(() => {
    dispatch({
      type: "FETCH_MIGRATION_VISAS_REQUEST",
      payload: {
        page,
        pageSize,
        branchId:
          store.branchState?.branchRecord.id ??
          localStorage.getItem("branchId"),
      },
    });
  }, [page, pageSize, store.branchState?.branchRecord]);

  useEffect(() => {
    fetchCountry();
    setCountry(store.profileState.profile?.country);
  }, []);

  const fetchCountry = () => {
    const tempToken = Cookies.get("accessToken");
    axios
      .get(`v1/api/country/getAllCountry`, {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      })
      .then((res) => {
        setCountryList(res.data.data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  //if (migrationType === "migrationVisas") {
  const data = store?.migrationState.migrationVisas?.data?.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      subClass: dataObj.subClass,
      country: dataObj.country,

      actions: (
        <Space size="middle">
          <Tooltip title="Subclass Details">
            <a>
              <div
                className="bordered"
                // onClick={() => {
                //   setRecord(dataObj);
                //   setIsEditServicesVisible(true);
                //   // setIsQuotationDetailsModelVisible(true);
                // }}
                onClick={() => {
                  setIsVisaDetailModelVisible(true);
                  setRecord(dataObj);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
          {store.profileState?.profile?.department?.rolesAndPermissionList?.MIGRATION_VISAS?.includes(
            "UPDATE"
          ) && (
            <Tooltip title="Update migration">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateMigrationVisasModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
          )}
          {store.profileState?.profile?.department?.rolesAndPermissionList?.MIGRATION_VISAS?.includes(
            "DELETE"
          ) && (
            <Popconfirm
              placement="topRight"
              title="Are you sure to delete this migration?"
              onConfirm={() => {
                dispatch({
                  type: "DELETE_MIGRATION_VISAS_REQUEST",
                  payload: { id: dataObj.id },
                });
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <div className="bordered">
                  <DeleteOutlined style={{ color: "red" }} />
                </div>
              </a>{" "}
            </Popconfirm>
          )}
          {/* <Tooltip title="Toggle migration status">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  const actionStatus =
                    dataObj.status === "INACTIVE" ? "active" : "inactive";
                  dispatch({
                    type: "TOGGLE_MIGRATION_VISAS_STATUS_REQUEST",
                    payload: { id: dataObj.id, actionStatus },
                  });
                }}
              >
                <MdOutlineDisabledVisible />
              </div>
            </a>
          </Tooltip> */}
        </Space>
      ),
    };
  });

  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          {store.profileState?.profile?.department?.rolesAndPermissionList?.MIGRATION_VISAS?.includes(
            "ADD"
          ) && (
            <button
              className="button"
              onClick={() => {
                setIsAddMigrationVisasModalVisible(true);
              }}
            >
              <span>Add Migration Visa</span>
            </button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          <Table
            bordered
            columns={columns}
            dataSource={data}
            pagination={false}
          />
          {store?.migrationState?.migrationVisas?.totalData ? (
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              defaultPageSize={pageSize}
              onChange={onShowSizeChange}
              total={store?.migrationState?.migrationVisas?.totalPage * 10}
            />
          ) : null}
        </div>
      </div>
      {isAddMigrationVisasModalVisible && (
        <AddMigrationVisa
          country={country}
          countryList={countryList}
          isAddMigrationVisasModalVisible={isAddMigrationVisasModalVisible}
          setIsAddMigrationVisasModalVisible={
            setIsAddMigrationVisasModalVisible
          }
        />
      )}
      {isUpdateMigrationVisasModalVisible && (
        <UpdateMigrationVisa
          countryList={countryList}
          record={record}
          isUpdateMigrationVisaModalVisible={isUpdateMigrationVisasModalVisible}
          setIsUpdateMigrationVisaModalVisible={
            setIsUpdateMigrationVisasModalVisible
          }
        />
      )}
      {isVisaDetailModelVisible && (
        <EducationVisaDetails
          record={record}
          isVisaDetailModelVisible={isVisaDetailModelVisible}
          setIsVisaDetailModelVisible={setIsVisaDetailModelVisible}
        />
      )}
    </SiderDemo>
  );
};

export default MigrationVisas;
