import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import DocumentChecklist from "./DocumentChecklists";
import CaseLog from "./CaseLog";
import UpdateCaseStatusCaseLog from "./UpdateCaseStatusCaseLog";
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";
import { GrActions } from "react-icons/gr";
const { Option } = Select;

const CaseStatusHistory = ({
  record,
  isUpdateCaseStatusModalVisible,
  setIsUpdateCaseStatusModalVisible,
}) => {
  const updateCaseStatusState = useSelector((state) => state.VisasReducer);
  const dispatch = useDispatch();
  useEffect(() => {}, []);
  useEffect(() => {
    if (isUpdateCaseStatusModalVisible) {
      dispatch({
        type: "FETCH_WORKFLOW_STATUSES_BY_WORKFLOW_ID_REQUEST",
        payload: { id: record?.workFlowDto?.id },
      });
      dispatch({
        type: "UPDATE_CURRENT_WORKFLOW_STATUS_REQUEST",
        payload: record?.currentWorkFlowStatus,
      });
    }
  }, [isUpdateCaseStatusModalVisible]);
  const [isCaseLogModalVisible, setIsCaseLogModalVisible] = useState(false);
  const [isDocumentChecklistModalVisible, setIsDocumentChecklistModalVisible] =
    useState(false);
  const [isStatusUpdateModalVisible, setIsStatusUpdateModalVisible] =
    useState(false);
  const [form] = Form.useForm();

  return (
    <Drawer
      title="Status Update"
      open={isUpdateCaseStatusModalVisible}
      onClose={() => {
        setIsUpdateCaseStatusModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <div
        className="drawerStyle"
        style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        <Card>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <div>
              <span>Case No. </span>
              <span>{record.id}</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="flexColumnWithoutStyle">
                <div style={{ fontWeight: "650", color: "blue" }}>
                  Visa Type:
                </div>
                <span>{record.visaCategory}</span>
              </div>
              <button
                className="buttonwithoutAnimation"
                onClick={() => {
                  setIsCaseLogModalVisible(true);
                }}
              >
                <span>Case Log</span>
              </button>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between" }}
            >
              <button
                className="buttonwithoutAnimation"
                onClick={() => {
                  dispatch({
                    type: "ADD_DOCUMENT_CHECKLIST",
                    payload: {
                      requiredWorkflowCheckList: [
                        ...record.requiredWorkflowCheckList,
                      ],
                    },
                  });
                  dispatch({
                    type: "ADD_COURSE_CHECKLIST",
                    payload: {
                      requiredCoursesCheckList: [
                        ...record.requiredCoursesCheckList,
                      ],
                    },
                  });
                  setIsDocumentChecklistModalVisible(true);
                }}
              >
                <span>Document Checklist</span>
              </button>
              <button
                className="buttonwithoutAnimation"
                onClick={() => {
                  setIsStatusUpdateModalVisible(true);
                }}
              >
                <span>Status Update</span>
              </button>
            </div>
          </div>
        </Card>
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {updateCaseStatusState?.currentWorkflowStatus?.map(
              (dataObj, index) => {
                return (
                  <div>
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        borderRadius: "20rem",
                        padding: "2px 4px",
                        margin: "0.5rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "1rem",
                          alignItems: "center",
                          padding: "0.5rem",
                          backgroundColor: "",
                        }}
                      >
                        {dataObj?.visitedStatus === "VISITED" ? (
                          <ImCheckboxChecked color="green" />
                        ) : dataObj?.visitedStatus === "CURRENT" ? (
                          <ImCheckboxUnchecked
                            color="orange"
                            style={{ backgroundColor: "orange" }}
                          />
                        ) : (
                          <ImCheckboxUnchecked color="gray" />
                        )}
                        {dataObj?.statusName}
                        <div>
                          {dataObj?.visitedStatus === "VISITED" ? "" : ""}
                        </div>
                      </div>
                    </div>
                    {dataObj?.visitedStatus === "CURRENT" &&
                    dataObj?.isLastState === true ? (
                      <div>
                        <Form layout="vertical" colon={true} form={form}>
                          <Form.Item
                            name={"Outcome"}
                            label={"Visa Outcome"}
                            style={{ width: "100%" }}
                          >
                            <Select
                              label="Visa Outcome"
                              onChange={(value) => {
                                dispatch({
                                  type: "UPDATE_VISA_OUTCOME_REQUEST",
                                  payload: {
                                    id: record.id,
                                    data: { visaOutcome: value },
                                  },
                                });
                              }}
                            >
                              <Option value="HOLD">Hold</Option>
                              <Option value="WITHDRAWN">Withdrawn</Option>
                              <Option value="GRANTED">Granted</Option>
                              <Option value="REFUSED">Refused</Option>
                              <Option value="NOT_ASSIGNED">Not Assigned</Option>
                            </Select>
                          </Form.Item>
                        </Form>
                      </div>
                    ) : null}
                  </div>
                );
              }
            )}
          </div>
        </Card>
      </div>
      {isDocumentChecklistModalVisible && (
        <DocumentChecklist
          record={record}
          isDocumentChecklistModalVisible={isDocumentChecklistModalVisible}
          setIsDocumentChecklistModalVisible={
            setIsDocumentChecklistModalVisible
          }
        />
      )}
      {isCaseLogModalVisible && (
        <CaseLog
          record={record}
          isCaseLogModalVisible={isCaseLogModalVisible}
          setIsCaseLogModalVisible={setIsCaseLogModalVisible}
        />
      )}
      {isStatusUpdateModalVisible && (
        <UpdateCaseStatusCaseLog
          record={record}
          isUpdateCaseStatusCaseLogModalVisible={isStatusUpdateModalVisible}
          setIsUpdateCaseStatusCaseLogModalVisible={
            setIsStatusUpdateModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default CaseStatusHistory;
