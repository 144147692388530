import {
  Card,
  Divider,
  Drawer,
  Image,
  Modal,
  Input,
  Tooltip,
  Checkbox,
  Button,
  Tag,
  Space,
  Table,
} from "antd";
import React, { useEffect } from "react";
import client from "../../../Assets/client.png";
import {
  AiOutlineEye,
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineSend,
} from "react-icons/ai";
import { FcDocument } from "react-icons/fc";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ImCompass } from "react-icons/im";
import CoursesDetail from "../../Courses/CoursesDetail";
import AddVisaCases from "../../Visas/AddVisas";
import SendComposedMail from "../../Mailbox/SendComposedMail";
import moment from "moment";
import AddAgreementInstitute from "./AddAgreementInstitute";
import { BsChatLeftText } from "react-icons/bs";
import InstituteBranchDetails from "./InstituteBranchDetails";
import TaskTable from "../../../Components/TaskTableComponent/TaskTable";
import AddTask from "../../Tasks/AddTask";
import AddEditInstituteDocument from "../AddEditInstituteDocument";
const { Meta } = Card;
const columns = [
  {
    title: "Course Name",
    dataIndex: "name",
    width: 100,
    align: "center",
  },
  {
    title: "Degree",
    dataIndex: "degree",
    width: 100,
    align: "center",
  },
  {
    title: "Course Duration",
    dataIndex: "courseDuration",
    width: 100,
    align: "center",
  },

  {
    title: "Institute Name",
    dataIndex: "institute",
    width: 250,
    align: "center",
  },
  {
    title: "Onshore Fee",
    dataIndex: "onshoreFee",
    width: 250,
    align: "center",
  },
  {
    title: "Offshore Fee",
    dataIndex: "offshoreFee",
    width: 250,
    align: "center",
  },
  {
    title: "Intake/s",
    dataIndex: "intake",
    width: 250,
    align: "center",
  },

  {
    title: "Status",
    dataIndex: "isActive",
    width: 250,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const InstituteDetais = ({
  Record,
  isInstituteDetailsModelVisible,
  setIsInstituteDetailsModelVisible,
}) => {
  const dispatch = useDispatch();

  const instituteState = useSelector((state) => state.InstituteReducer);
  const [record, setRecord] = useState(Record);
  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    dispatch({
      type: "FETCH_COURSES_FOR_INSTITUTE_ID_REQUEST",
      payload: {
        instituteId: record?.id,
      },
    });
  }, []);
  const [courseRecord, setCourseRecord] = useState({});
  const [isCourseDetailsModelVisible, setIsCourseDetailsModelVisible] =
    useState(false);
  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);
  const [enrollType, setEnrollType] = useState("ONSHORE");
  const [current, setCurrent] = useState("document");
  const [emailRecord, setEmailRecord] = useState({});
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [isAsignModelVisible, setIsAsignModelVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    record?.agreementStartDate && setIsUpdate(true);
    fetchInstituteById();
  }, []);

  const fetchInstituteById = () => {
    dispatch({
      type: "FETCH_INSTITUTE_BY_ID",
      payload: record?.id,
      isSuccessFn: true,
      successFunction: (res) => {
        setRecord(res?.data);
      },
    });
  };

  const clientDetailsTabList = [
    {
      key: "document",
      tab: "Form to download",
    },
    {
      key: "courses",
      tab: "Courses",
    },
    {
      key: "bonuses",
      tab: "Bonuses",
    },
    {
      key: "description",
      tab: "Description",
    },
    {
      key: "packages",
      tab: "Packages",
    },
    {
      key: "agreement",
      tab: "Agreement Details",
    },
    {
      key: "instituteBranch",
      tab: "Institute Branch",
    },
    {
      key: "task",
      tab: "Task",
    },
  ];
  const data = instituteState?.coursesForInstitute?.data?.map((dataObj) => {
    return {
      key: dataObj.id,
      name: (
        <Tooltip title="Course Details">
          <a>
            <div
              className="bordered"
              onClick={() => {
                setCourseRecord(dataObj);
                setIsCourseDetailsModelVisible(true);
              }}
            >
              {dataObj.name ?? "N/A"}
            </div>
          </a>
        </Tooltip>
      ),
      degree: dataObj?.name?.degree,
      courseDuration: dataObj.courseDuration + " " + dataObj.courseAccordingTo,
      institute: dataObj.institute?.name ?? "N/A",
      onshoreFee: dataObj.onShoreFee ?? "N/A",
      offshoreFee: dataObj.offShoreFee ?? "N/A",
      intake: dataObj?.collegeIntakeResponseList?.map((dataObj) => {
        return (
          <Tag key={dataObj.id} color="blue">
            {dataObj.intakeSessionName}
          </Tag>
        );
      }),
      degree: dataObj.degree?.name,
      isActive: dataObj.isActive ? (
        <p className="greenTag">ACTIVE</p>
      ) : (
        <p className="redTag">INACTIVE</p>
      ),
      actions: (
        <Space size="middle">
          <Tooltip title="Course Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setCourseRecord(dataObj);
                  setIsCourseDetailsModelVisible(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>

          {dataObj.both && (
            <>
              <Tooltip title="Onshore Enroll">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setEnrollType("ONSHORE");
                      setCourseRecord(dataObj);
                      setIsAddVisasModalVisible(true);
                    }}
                  >
                    <AiOutlineSend />
                  </div>
                </a>
              </Tooltip>
              <Tooltip title="Offshore Enroll">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setEnrollType("OFFSHORE");

                      setCourseRecord(dataObj);
                      setIsAddVisasModalVisible(true);
                    }}
                  >
                    <ImCompass />
                  </div>
                </a>
              </Tooltip>
            </>
          )}
          {dataObj.onshore && (
            <>
              <Tooltip title="Onshore Enroll">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setEnrollType("ONSHORE");

                      setCourseRecord(dataObj);
                      setIsAddVisasModalVisible(true);
                    }}
                  >
                    <AiOutlineSend />
                  </div>
                </a>
              </Tooltip>
            </>
          )}
          {dataObj.offshore && (
            <>
              <Tooltip title="Offshore Enroll">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setEnrollType("OFFSHORE");

                      setCourseRecord(dataObj);
                      setIsAddVisasModalVisible(true);
                    }}
                  >
                    <ImCompass />
                  </div>
                </a>
              </Tooltip>
            </>
          )}
        </Space>
      ),
    };
  });
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };

  return (
    <Drawer
      title="Institute Details"
      open={isInstituteDetailsModelVisible}
      onClose={() => {
        setIsInstituteDetailsModelVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.5rem" }} className="drawerStyle">
        <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
          {record?.image ? (
            <Image src={record?.image} className={"clientImage"} />
          ) : (
            <Image src={client} className={"clientImage"} />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              minHeight: "10rem",
              gap: "0.5rem",
            }}
          >
            <div
              className="flexColumnWithoutStyle"
              style={{
                justifyContent: "flex-start",
                width: "100%",
                gap: "0.5rem",
                position: "relative",
              }}
            >
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                {record?.instituteName ? record?.instituteName : "N/A"}
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.4rem", color: "#aaa" }}
              >
                {record?.contact && (
                  <span className="flexRowWithGap">
                    <AiOutlinePhone
                      style={{
                        fontSize: "20",
                      }}
                    />{" "}
                    <a href={`tel:${record?.contact}`}> {record?.contact}</a>
                  </span>
                )}
                {record?.location && (
                  <span
                    className="flexRowWithGap"
                    onClick={() =>
                      window.open(
                        `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                          record?.location
                        )}`
                      )
                    }
                  >
                    <GoLocation
                      style={{
                        fontSize: "16",
                      }}
                    />{" "}
                    <a>{record?.location}</a>
                  </span>
                )}
                {record?.email && (
                  <span className="flexRowWithGap">
                    <AiOutlineMail
                      style={{
                        fontSize: "16",
                      }}
                    />{" "}
                    <a>
                      <div
                        className="bordered"
                        onClick={() => {
                          setEmailRecord(record);
                          setIsSendComposedEmailModalVisible(true);
                        }}
                      >
                        {record?.email}
                      </div>
                    </a>
                  </span>
                )}
              </div>

              <div className="flexColumnWithoutStyle">
                <h4>{record?.country?.name}</h4>
                <div>{record?.levelOfCollege?.name}</div>
                <div>CRICOS: {record?.crisco}</div>
                <div>RTO: {record?.rtoNumber}</div>
              </div>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.5rem",
                alignSelf: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <button className="emailAndMessage">
                <BsChatLeftText style={{ fontSize: "10px" }} />
                <span>Message</span>
              </button>
              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
                <span>Email</span>
              </button>
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "document" && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  margin: "0 0 1rem",
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                >
                  Add Document Form
                </Button>
              </div>
              {record?.documents?.length > 0 ? (
                record?.documents?.map((dataObj) => {
                  return (
                    <div key={dataObj.id}>
                      <a
                        href={dataObj?.documentUrl}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "1px",
                          flexWrap: "wrap",
                        }}
                      >
                        <FcDocument style={{ fontSize: "30px" }} />
                        <span>{dataObj?.documentName}</span>
                      </a>
                      <Divider
                        orientationMargin="0"
                        style={{ margin: "0.5rem" }}
                      />
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  No Forms Available to download.
                </div>
              )}
            </div>
          )}
          {current === "description" &&
            (record?.description === "" ||
            record?.description === null ||
            record?.description === undefined ? (
              <div style={{ textAlign: "center" }}>
                No description Available.
              </div>
            ) : (
              <div>{record?.description}</div>
            ))}
          {current === "formToDownload" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1px",
                flexWrap: "wrap",
              }}
            >
              {record?.customer?.experiences?.map((qualification) => {
                return (
                  <>
                    <Card
                      title={qualification?.experience}
                      key={qualification.id}
                      style={{ width: 300 }}
                    ></Card>
                  </>
                );
              })}
            </div>
          )}
          {current === "bonuses" && (
            <>
              {record?.bonus?.length > 0 ? (
                record?.bonus?.map((dataObj) => {
                  return (
                    <div
                      key={dataObj.id}
                      style={{
                        backgroundColor: "#eeeeee55",
                        borderRadius: "0.5rem",
                        padding: "0.5rem",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <div>
                        {detailFun(
                          "Onshore From",
                          `${
                            dataObj?.onshoreFromWhen
                              ? dataObj?.onshoreFromWhen
                              : "N/A"
                          }`
                        )}
                        {detailFun(
                          "Onshore To",
                          `${
                            dataObj?.onshoreToWhen
                              ? dataObj?.onshoreToWhen
                              : "N/A"
                          }`
                        )}
                        {detailFun(
                          "Onshore Price",
                          `${dataObj?.isOnshoreFlat ? "FLAT" : ""} ${
                            dataObj?.onshorePrice
                              ? dataObj?.onshorePrice
                              : "N/A"
                          } ${dataObj?.isOnshorePercentage ? "%" : ""}`
                        )}
                        {detailFun(
                          "Offshore From",
                          `${
                            dataObj?.offshoreFromWhen
                              ? dataObj?.offshoreFromWhen
                              : "N/A"
                          }`
                        )}
                        {detailFun(
                          "Offshore To",
                          `${
                            dataObj?.offshoreToWhen
                              ? dataObj?.offshoreToWhen
                              : "N/A"
                          }`
                        )}
                        {detailFun(
                          "Offshore Price",
                          `${dataObj?.isOffshoreFlat ? "FLAT" : ""} ${
                            dataObj?.offshorePrice
                              ? dataObj?.offshorePrice
                              : "N/A"
                          } ${dataObj?.isOffshorePercentage ? "%" : ""}`
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  No Bonuses Available.
                </div>
              )}
            </>
          )}
          {current === "packages" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1px",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                {record?.casePackages?.length > 0 ? (
                  record?.casePackages?.map((dataObj) => {
                    return (
                      <div
                        key={dataObj?.id}
                        style={{
                          backgroundColor: "#eeeeee55",
                          width: "48%",
                          borderRadius: "0.5rem",
                          padding: "0.5rem",
                          marginBottom: "0.5rem",
                        }}
                      >
                        {dataObj?.company?.name !== undefined && (
                          <>
                            {detailFun(
                              "Company",
                              `${
                                dataObj?.company?.name
                                  ? dataObj?.company?.name
                                  : "N/A"
                              }`
                            )}
                          </>
                        )}
                        {dataObj?.institute?.name !== undefined && (
                          <>
                            {detailFun(
                              "Company",
                              `${
                                dataObj?.institute?.name
                                  ? dataObj?.institute?.name
                                  : "N/A"
                              }`
                            )}
                          </>
                        )}
                        {detailFun(
                          "Package Name",
                          `${
                            dataObj?.packageName ? dataObj?.packageName : "N/A"
                          }`
                        )}

                        {detailFun(
                          "Unit Price",
                          `${
                            dataObj?.unitPrice
                              ? `${dataObj?.unitPrice} ${
                                  dataObj?.currency ? dataObj?.currency : ""
                                }`
                              : "N/A"
                          }`
                        )}
                        {dataObj?.gst !== "" &&
                          dataObj?.gst !== null &&
                          dataObj?.gst !== undefined && (
                            <>
                              {detailFun(
                                "GST/VAT",
                                `${
                                  dataObj?.gst
                                    ? `${dataObj?.gst} ${
                                        dataObj?.isGstPercentage === true
                                          ? `% `
                                          : dataObj?.isGstFlat
                                          ? `${
                                              dataObj?.currency
                                                ? dataObj?.currency
                                                : ""
                                            } FLAT`
                                          : ""
                                      }`
                                    : "N/A"
                                }`
                              )}
                            </>
                          )}
                        {dataObj?.commission !== "" &&
                          dataObj?.commission !== null &&
                          dataObj?.commission !== undefined && (
                            <>
                              {detailFun(
                                "Commission",
                                `${
                                  dataObj?.commission
                                    ? `${dataObj?.commission} ${
                                        dataObj?.isCommissionPercentage === true
                                          ? `% `
                                          : dataObj?.isCommissionFlat
                                          ? `${
                                              dataObj?.currency
                                                ? dataObj?.currency
                                                : ""
                                            } FLAT`
                                          : ""
                                      }`
                                    : "N/A"
                                }`
                              )}
                            </>
                          )}
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    No Institute Packages Available.
                  </div>
                )}
              </div>
            </div>
          )}
          {current === "agreement" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1px",
              }}
            >
              <div
                style={{
                  marginTop: "0rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  type="primary"
                  style={{ width: "fit-content" }}
                  onClick={() => setIsAsignModelVisible(true)}
                >
                  {isUpdate ? "Edit" : "Add"} Agreement Date
                </Button>
                <Card
                  style={{
                    border: "1px solid #f5f5f5",
                    marginTop: "1rem",
                    width: "100%",
                  }}
                >
                  {detailFun(
                    "Agreement Start Date",
                    record?.agreementStartDate
                      ? `${moment(record?.agreementStartDate).format(
                          "DD-MM-YYYY"
                        )}`
                      : "N/A"
                  )}
                  {detailFun(
                    "Agreement End Date",
                    record?.agreementExpiryDate
                      ? `${moment(record?.agreementExpiryDate).format(
                          "DD-MM-YYYY"
                        )}`
                      : "N/A"
                  )}
                  {detailFun(
                    "Agreement Type",
                    record?.agreementType ? record?.agreementType : "N/A"
                  )}
                </Card>
              </div>
            </div>
          )}
          {current === "task" && (
            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "0 0 1rem",
                  }}
                >
                  <Button
                    onClick={() => {
                      setIsAddTaskModalVisible(true);
                    }}
                    type="primary"
                  >
                    Add Task
                  </Button>
                </div>
                <TaskTable
                  record={record?.taskRelatedToInstitute}
                  updateType={"institute"}
                  fetchInstituteById={fetchInstituteById}
                />
              </div>
            </div>
          )}

          {current === "instituteBranch" && (
            <InstituteBranchDetails record={record} />
          )}
          {current === "courses" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1px",
              }}
            >
              <div style={{ marginTop: "0rem" }}>
                <Table bordered columns={columns} dataSource={data} />
              </div>
            </div>
          )}
        </Card>
      </Card>
      {isCourseDetailsModelVisible && (
        <CoursesDetail
          record={courseRecord}
          // fromCounselling={fromCounselling}
          // recordData={recordData}
          instituteRecordForCountry={record}
          isCourseDetailsModelVisible={isCourseDetailsModelVisible}
          setIsCourseDetailsModelVisible={setIsCourseDetailsModelVisible}
          // setIsStartCounsellingModalVisible={setIsStartCounsellingModalVisible}
        />
      )}
      {isAddVisasModalVisible && (
        <AddVisaCases
          navigateCaseList={true}
          // fromCounselling={fromCounselling}
          // recordData={recordData}
          courseRecord={courseRecord}
          instituteRecordForCountry={record}
          enrollType={enrollType}
          isAddVisaCasesModalVisible={isAddVisasModalVisible}
          setIsAddVisaCasesModalVisible={setIsAddVisasModalVisible}
          // setIsStartCounsellingModalVisible={setIsStartCounsellingModalVisible}
        />
      )}
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          composeMailRecord={{
            toUser: [record?.email],
          }}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
      {isAsignModelVisible && (
        <AddAgreementInstitute
          record={record}
          instituteId={record?.id}
          isUpdate={isUpdate}
          isAsignModelVisible={isAsignModelVisible}
          fetchInstituteById={fetchInstituteById}
          setIsAsignModelVisible={setIsAsignModelVisible}
        />
      )}

      {isAddTaskModalVisible && (
        <AddTask
          taskId={record?.id}
          taskType="Institute"
          isAddTaskModalVisible={isAddTaskModalVisible}
          setIsAddTaskModalVisible={setIsAddTaskModalVisible}
          fetchInstituteById={fetchInstituteById}
        />
      )}

      {isModalVisible && (
        <AddEditInstituteDocument
          fetchInstituteById={fetchInstituteById}
          record={record}
          isUpdate={false}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </Drawer>
  );
};

export default InstituteDetais;
