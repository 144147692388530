import { LikeOutlined, MessageOutlined, StarOutlined } from "@ant-design/icons";
import { Avatar, List, Skeleton, Switch } from "antd";
import React, { useState } from "react";

const DashboardSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.8rem",
      }}
    >
      <div className="new-dashboardcard-container">
        <div className="dashboardCounter">
          <div className="flexColumnwithoutStyle" style={{ gap: "0.5rem" }}>
            <Skeleton.Input size="small" active />
            <Skeleton.Button size="small" active />
          </div>
          <Skeleton.Avatar active />
        </div>
        <div className="dashboardCounter">
          <div className="flexColumnwithoutStyle" style={{ gap: "0.5rem" }}>
            <Skeleton.Input size="small" active />
            <Skeleton.Button size="small" active />
          </div>
          <Skeleton.Avatar active />
        </div>
        <div className="dashboardCounter">
          <div className="flexColumnwithoutStyle" style={{ gap: "0.5rem" }}>
            <Skeleton.Input size="small" active />
            <Skeleton.Button size="small" active />
          </div>
          <Skeleton.Avatar active />
        </div>
        <div className="dashboardCounter">
          <div className="flexColumnwithoutStyle" style={{ gap: "0.5rem" }}>
            <Skeleton.Input size="small" active />
            <Skeleton.Button size="small" active />
          </div>
          <Skeleton.Avatar active />
        </div>
      </div>
      <div className="new-dashboardcard-container">
        <div
          style={{
            border: "1px solid #cccccc",
            padding: "1rem",
            backgroundColor: "white",
            minWidth: "14rem",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
            height: "280px",
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="flexRowSpaceBetween">
            <Skeleton.Button
              size="small"
              active
              style={{ position: "absolute", top: "1rem", left: "1rem" }}
            />
            <Skeleton.Button
              size="small"
              active
              style={{ position: "absolute", top: "1rem", right: "1rem" }}
            />
          </div>
          <Skeleton.Avatar
            active
            size={"large"}
            shape="circle"
            style={{
              width: "8rem",
              height: "8rem",
            }}
          />
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Skeleton.Button
              size="small"
              active
              style={{ position: "absolute", bottom: "1rem" }}
            />
            <Skeleton.Button
              size="small"
              active
              style={{ position: "absolute", bottom: "1rem", right: "1rem" }}
            />
          </div>
        </div>
        <div
          style={{
            border: "1px solid #cccccc",
            padding: "1rem",
            backgroundColor: "white",
            minWidth: "14rem",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
            height: "280px",
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="flexRowSpaceBetween">
            <Skeleton.Button
              size="small"
              active
              style={{ position: "absolute", top: "1rem", left: "1rem" }}
            />
            <Skeleton.Button
              size="small"
              active
              style={{ position: "absolute", top: "1rem", right: "1rem" }}
            />
          </div>
          <Skeleton.Avatar
            active
            size={"large"}
            shape="circle"
            style={{
              width: "8rem",
              height: "8rem",
            }}
          />
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Skeleton.Button
              size="small"
              active
              style={{ position: "absolute", bottom: "1rem" }}
            />
            <Skeleton.Button
              size="small"
              active
              style={{ position: "absolute", bottom: "1rem", right: "1rem" }}
            />
          </div>
        </div>
        <div
          style={{
            border: "1px solid #cccccc",
            padding: "1rem",
            backgroundColor: "white",
            minWidth: "14rem",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
            height: "280px",
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="flexRowSpaceBetween">
            <Skeleton.Button
              size="small"
              active
              style={{ position: "absolute", top: "1rem", left: "1rem" }}
            />
            <Skeleton.Button
              size="small"
              active
              style={{ position: "absolute", top: "1rem", right: "1rem" }}
            />
          </div>
          <Skeleton.Avatar
            active
            size={"large"}
            shape="circle"
            style={{
              width: "8rem",
              height: "8rem",
            }}
          />
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Skeleton.Button
              size="small"
              active
              style={{ position: "absolute", bottom: "1rem" }}
            />
            <Skeleton.Button
              size="small"
              active
              style={{ position: "absolute", bottom: "1rem", right: "1rem" }}
            />
          </div>
        </div>
        <div
          style={{
            border: "1px solid #cccccc",
            padding: "1rem",
            backgroundColor: "white",
            minWidth: "14rem",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
            height: "280px",
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="flexRowSpaceBetween">
            <Skeleton.Button
              size="small"
              active
              style={{ position: "absolute", top: "1rem", left: "1rem" }}
            />
            <Skeleton.Button
              size="small"
              active
              style={{ position: "absolute", top: "1rem", right: "1rem" }}
            />
          </div>
          <Skeleton.Avatar
            active
            size={"large"}
            shape="circle"
            style={{
              width: "8rem",
              height: "8rem",
            }}
          />
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Skeleton.Button
              size="small"
              active
              style={{ position: "absolute", bottom: "1rem" }}
            />
            <Skeleton.Button
              size="small"
              active
              style={{ position: "absolute", bottom: "1rem", right: "1rem" }}
            />
          </div>
        </div>
      </div>
      <div className="new-dashboardcard-datacontainer">
        <div
          style={{
            border: "1px solid #cccccc",
            padding: "1rem",
            backgroundColor: "white",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
            gap: "1rem",
            height: "fit-content",
          }}
        >
          <div className="flexRowSpaceBetween">
            <Skeleton.Avatar active size="default" />
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
            <Skeleton.Button active size="small" />
          </div>
          <div className="flexRowSpaceBetween">
            <Skeleton.Avatar active size="default" />
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
            <Skeleton.Button active size="small" />
          </div>
          <div className="flexRowSpaceBetween">
            <Skeleton.Avatar active size="default" />
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
            <Skeleton.Button active size="small" />
          </div>
        </div>
        <div
          style={{
            border: "1px solid #cccccc",
            padding: "1rem",
            backgroundColor: "white",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
            gap: "1rem",
            height: "fit-content",
          }}
        >
          <div className="flexRowSpaceBetween">
            <Skeleton.Avatar active size="default" />
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
            <Skeleton.Button active size="small" />
          </div>
          <div className="flexRowSpaceBetween">
            <Skeleton.Avatar active size="default" />
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
            <Skeleton.Button active size="small" />
          </div>
          <div className="flexRowSpaceBetween">
            <Skeleton.Avatar active size="default" />
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
            <Skeleton.Button active size="small" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSkeleton;
