import React, { useEffect } from "react";
import { Button, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Option } from "antd/lib/mentions";
import logoImage from "./../../Assets/khrouch3.png";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

import CarouselComponent from "../Login/CarouselComponent";
import { useState } from "react";
import axios from "axios";
import cookie from "js-cookie";

const Signup = () => {
  const [form] = Form.useForm();
  const signupState = useSelector((state) => state.SignupReducer);
  const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [countryList, setCountryList] = useState([]);

  const dispatch = useDispatch();
  const signUpHandler = (value) => {
    dispatch({
      type: "SIGN_UP_REQUEST",
      payload: {
        subscriptionId: "1",
        loginCompanyUrl:
          value?.loginCompanyUrl !== undefined &&
          value?.loginCompanyUrl !== "" &&
          value?.loginCompanyUrl !== null
            ? `${value?.loginCompanyUrl}`
            : null,
        ...value,
      },
    });
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  const fetchCountry = () => {
    const tempToken = cookie.get("accessToken");
    axios
      .get(`v1/api/country/getAllCountry/by-pass`, {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      })
      .then((res) => {
        setCountryList(res.data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleChangeCountry = (value, option) => {
    form.setFieldsValue({ callingCode: option.key });
  };

  const NoWhitespaceValidator = (rule, value, callback) => {
    if (/\s/.test(value)) {
      callback("Whitespace is not allowed");
    } else {
      callback();
    }
  };

  return (
    <>
      {signupState.isSignedUp ? (
        <Navigate to={"/"} replace={true} />
      ) : (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="signUpContainer">
            <div className="formContainer">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={logoImage} style={{ width: "15%" }} />
              </div>
              <h2 style={{ textAlign: "center" }}>Register Here!</h2>
              <p style={{ textAlign: "center", fontSize: "16px" }}>
                Add your information here.
              </p>
              <Form
                form={form}
                layout="vertical"
                className="loginForm"
                onFinish={signUpHandler}
                initialValues={{
                  subscriptionName: "FREE",
                }}
              >
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <Form.Item
                    label="First Name"
                    name={"firstName"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your first name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="First Name"
                      size="medium"
                      style={{ borderRadius: "25px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Last Name"
                    name={"lastName"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your last name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Last Name"
                      size="medium"
                      style={{ borderRadius: "25px" }}
                    />
                  </Form.Item>
                </div>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  {/* <Form.Item
                    label="Country"
                    name={"country"}
                    style={{ width: "100%" }}
                  >
                    <Input
                      placeholder="Country"
                      size="medium"
                      style={{ borderRadius: "25px" }}
                    />
                  </Form.Item> */}
                  <Form.Item
                    label="Country"
                    name={"country"}
                    style={{ width: "100%" }}
                  >
                    <Select
                      className="signup--selector"
                      onChange={handleChangeCountry}
                      placeholder="Select Country"
                      size="medium"
                    >
                      {countryList?.map((country) => (
                        <Option
                          key={country.callingCode}
                          value={country.countryName}
                        >
                          {country.countryName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Country Code"
                    name={"callingCode"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter calling code!",
                      },
                    ]}
                  >
                    <Input
                      readOnly
                      placeholder="E.g +61"
                      size="medium"
                      style={{ borderRadius: "25px" }}
                    />
                  </Form.Item>
                </div>

                <Form.Item
                  label="Phone Number"
                  name={"phoneNumber"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number!",
                    },
                    {
                      pattern: /^[\d]{9,11}$/,
                      message: "Phone number should contain 9 to 11 numbers",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Phone Number"
                    size="medium"
                    style={{ borderRadius: "25px" }}
                  />
                </Form.Item>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <Form.Item
                    label="Password"
                    name={"password"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your password!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder="Password"
                      size="medium"
                      style={{ borderRadius: "25px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Confirm Passoword"
                    name={"confirmPassword"}
                    dependencies={["password"]}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Confirm your password!",
                      },

                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Password and confirm password do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder="Confirm Password"
                      size="medium"
                      style={{ borderRadius: "25px" }}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  label="Domain"
                  name="loginCompanyUrl"
                  style={{ width: "100%" }}
                  rules={[
                    { validator: NoWhitespaceValidator },

                    {
                      validator: async (_, value) => {
                        const response = await axios.get(
                          `v1/api/customer/validate-company-url?loginCompanyUrl=${value}`,
                          {
                            headers: {
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        if (
                          response?.data === false ||
                          value === "" ||
                          value === null ||
                          value === undefined
                        ) {
                          return Promise.resolve();
                        } else if (response?.data === true) {
                          return Promise.reject(
                            new Error(
                              "Entered domain already exists! Try another one."
                            )
                          );
                        }
                      },
                    },
                  ]}
                  validateTrigger={["onBlur"]}
                >
                  <Input
                    className="companyname--input"
                    placeholder="companyName"
                    addonAfter=".khrouch.com"
                    size="medium"
                    style={{ borderRadius: "25px" }}
                  />
                </Form.Item>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <Form.Item
                    label="Company Name"
                    name={"companyName"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your company name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Company Name"
                      size="medium"
                      style={{ borderRadius: "25px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Company Registration Number"
                    name={"companyRegistrationNumber"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message:
                          "Please enter your company registration number!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="ABN/ACN"
                      size="medium"
                      style={{ borderRadius: "25px" }}
                    />
                  </Form.Item>
                </div>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <Form.Item
                    label="Company Address"
                    name={"companyAddress"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your company address!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="company address"
                      size="medium"
                      style={{ borderRadius: "25px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Subscription Type"
                    name={"subscriptionName"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message:
                          "Please enter your preferred subscription type!",
                      },
                    ]}
                  >
                    <Input
                      readOnly
                      style={{ borderRadius: "25px" }}
                      placeholder="Subscription Type"
                    />
                    {/* <Select
                      className="caseSearch"
                      size="medium"
                      style={{ borderRadius: 8 }}
                    >
                      <Option value="FREE">Free</Option>
                    </Select> */}
                  </Form.Item>
                </div>
                <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
                  <GoogleReCaptcha
                    onVerify={(e) => {
                      setRecaptchaToken(e);
                    }}
                  />
                </GoogleReCaptchaProvider>
                <Form.Item wrapperCol={{ offset: 10 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ borderRadius: "2rem" }}
                  >
                    Register
                  </Button>
                </Form.Item>
              </Form>
              <div style={{ textAlign: "center", fontSize: "14px" }}>
                By clicking Register, you agree to our{" "}
                <Link to={"/terms-of-service"} style={{ fontSize: "14px" }}>
                  terms of services
                </Link>{" "}
                and{" "}
                <Link to={"/privacy-policy"} style={{ fontSize: "14px" }}>
                  privacy policy
                </Link>
              </div>
            </div>
          </div>
          <div
            className="carousel"
            style={{ width: "30%", position: "fixed", right: 0 }}
          >
            <CarouselComponent />
          </div>
        </div>
      )}
    </>
  );
};

export default Signup;
