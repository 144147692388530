import {
  Card,
  Divider,
  Drawer,
  Image,
  Modal,
  Input,
  Tooltip,
  Checkbox,
  Button,
  Tag,
  Form,
  Select,
} from "antd";
import React, { useEffect } from "react";
import { formattedDate, formattedDateTime } from "../../HelperFunction";
import client from "../../Assets/client.png";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { FcDocument } from "react-icons/fc";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CaseStatusHistory from "./CaseStatusHistory";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import UpdateCaseStatusCaseLog from "./UpdateCaseStatusCaseLog";
import CaseLog from "./CaseLog";
import CaseDetails from "./CaseDetails";
import { BsChatLeftText } from "react-icons/bs";
import SendComposedMail from "../Mailbox/SendComposedMail";
import {
  CheckCircleOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import AsignEmailToCase from "./AsignEmailToCase";
import TaskTable from "../../Components/TaskTableComponent/TaskTable";
import AddTask from "../Tasks/AddTask";
import CasePaymentPlan from "./CasePaymentPlan";
import EmailLogDetail from "../../Components/EmailLog/EmailLogDetail";
const { Meta } = Card;
const { Option } = Select;
const { confirm } = Modal;

const VisaCaseDetails = ({
  caseData,
  record,
  // visaRecord,
  isVisaCaseDetailsModalVisible,
  setIsVisaCaseDetailsModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      visaState: state.VisasReducer,
      profileState: state.ProfileReducer,
    };
  });
  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false);
  const [isAsignModelVisible, setIsAsignModelVisible] = useState(false);
  const [documentRecord, setDocumentRecord] = useState();
  const [form] = Form.useForm();
  const visaRecord = store?.visaState?.caseById;
  const dispatch = useDispatch();
  const [isStatusHistoryModelVisible, setIsStatusHistoryModelVisible] =
    useState(false);
  const [isStatusUpdateModalVisible, setIsStatusUpdateModalVisible] =
    useState(false);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [assigneEmail, setAssigneEmail] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [file, setFiles] = useState(null);
  const [fileIndex, setFileIndex] = useState(null);
  const [courseFile, setCourseFile] = useState(null);
  const [courseIndex, setCourseIndex] = useState(null);

  console.log("loading", store?.visaState?.isUploadLoading);

  useEffect(() => {
    fetchCaseDetail();
    fetchCasePaymentPlanDetail();
  }, []);

  const fetchEmailLogDetails = () => {
    dispatch({
      type: "FETCH_EMAIL_LOG_DETAILS",
      payload: {
        id: record?.id,
        forWhat: "CASE",
      },
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  useEffect(() => {
    fetchEmailLogDetails();
  }, []);

  useEffect(() => {
    if (isVisaCaseDetailsModalVisible === true) {
      fetchCaseDetail();

      dispatch({
        type: "FETCH_WORKFLOW_STATUSES_BY_WORKFLOW_ID_REQUEST",
        payload: { id: record?.workFlowDto?.id },
      });
      dispatch({
        type: "UPDATE_CURRENT_WORKFLOW_STATUS_REQUEST",
        payload: record?.currentWorkFlowStatus,
      });
      dispatch({
        type: "ADD_DOCUMENT_CHECKLIST",
        payload: {
          requiredWorkflowCheckList: record?.requiredWorkflowCheckList
            ? [...record?.requiredWorkflowCheckList]
            : [],
        },
      });
      dispatch({
        type: "ADD_COURSE_CHECKLIST",
        payload: {
          requiredCoursesCheckList: record?.requiredCoursesCheckList
            ? [...record?.requiredCoursesCheckList]
            : [],
        },
      });
    }
  }, [isVisaCaseDetailsModalVisible]);

  const fetchCaseDetail = () => {
    dispatch({
      type: "FETCH_CASE_BY_ID_REQUEST",
      payload: {
        caseId: record?.id,
      },
    });
  };

  const fetchCasePaymentPlanDetail = () => {
    dispatch({
      type: "FETCH_CASE_PAYMENT_PLAN_BY_ID_REQUEST",
      payload: record?.id,
      isSuccessFn: true,
      successFunction: (res) => {},
    });
  };

  const [current, setCurrent] = useState("activityLog");
  const clientDetailsTabList = [
    {
      key: "activityLog",
      tab: "Activity Log",
    },
    {
      key: "caseDetails",
      tab: "Case Details",
    },
    {
      key: "documentChecklist",
      tab: "Documents Checklist",
    },
    {
      key: "formToDownload",
      tab: "Form to Download",
    },
    {
      key: "task",
      tab: "Task",
    },
    {
      key: "paymentPlan",
      tab: "Payment Plan",
    },
    {
      key: "emailLog",
      tab: "Email Log",
    },
  ];
  const successFn = (response) => {
    dispatch({
      type: "FETCH_CASE_BY_ID_REQUEST",
      payload: {
        caseId: record?.id,
      },
    });
  };

  return (
    <Drawer
      title="Case Details"
      open={isVisaCaseDetailsModalVisible}
      onClose={() => {
        setIsVisaCaseDetailsModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.5rem" }} className="drawerStyle">
        <div className="flexColumnWithoutStyle" style={{}}>
          <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
            {visaRecord?.customer?.photo ? (
              <Image
                src={visaRecord?.customer?.photo}
                className={"clientImage"}
              />
            ) : (
              <Image src={client} className={"clientImage"} />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minHeight: "10rem",
                width: "100%",
              }}
            >
              <div
                // className="flexColumnWithoutStyle"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "stretch",
                  width: "100%",
                  gap: "0.5rem",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                    {visaRecord?.customer?.firstName +
                      " " +
                      visaRecord?.customer?.lastName}{" "}
                    (Case ID: {caseData?.casePerfix ?? ""}
                    {visaRecord?.id})
                  </div>
                  <span
                    style={{
                      color: "#ff4d4f",
                      borderRadius: "1rem",
                      border: "1px solid #ff4d4f",
                      padding: "0.3rem 1rem",
                      fontWeight: "bolder",
                    }}
                  >
                    {visaRecord?.workFlowStatusDto?.statusName}
                    {visaRecord?.visaOutcome &&
                      visaRecord?.workFlowStatusDto?.statusName ===
                        "Visa Outcome" && (
                        <span> / {visaRecord?.visaOutcome}</span>
                      )}
                  </span>
                </div>
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.4rem", color: "#aaa" }}
                >
                  {visaRecord?.customer?.mobile && (
                    <span className="flexRowWithGap">
                      <AiOutlinePhone
                        style={{
                          fontSize: "20",
                        }}
                      />{" "}
                      {visaRecord?.customer?.mobile}
                    </span>
                  )}
                  {visaRecord?.customer?.address && (
                    <span className="flexRowWithGap">
                      <GoLocation
                        style={{
                          fontSize: "16",
                        }}
                      />{" "}
                      {visaRecord?.customer?.address}
                    </span>
                  )}
                  {visaRecord?.customer?.email && (
                    <span className="flexRowWithGap">
                      <AiOutlineMail
                        style={{
                          fontSize: "16",
                        }}
                      />{" "}
                      {visaRecord?.customer?.email}
                    </span>
                  )}
                </div>
                <div
                  className="flexRowWithoutStyle"
                  style={{
                    alignItems: "flex-start",
                    gap: "0.3rem",
                  }}
                >
                  <div>
                    {visaRecord?.salesRepresentativeResponse?.salesRepName !==
                      undefined &&
                      visaRecord?.salesRepresentativeResponse?.salesRepName !==
                        null && (
                        <Tooltip
                          title={
                            visaRecord?.salesRepresentativeResponse
                              ?.salesRepEmail
                          }
                          showArrow={false}
                        >
                          <div>
                            <span className="profileTitle">Sales Rep:</span>
                            <span>
                              {
                                visaRecord?.salesRepresentativeResponse
                                  ?.salesRepName
                              }
                            </span>
                          </div>
                        </Tooltip>
                      )}
                    {visaRecord?.leadType && (
                      <div>
                        <span className="profileTitle">Lead Type:</span>
                        <span>{visaRecord?.leadType}</span>
                      </div>
                    )}
                  </div>
                  {/* <Divider type="vertical" style={{ margin: 0, width: "2rem" }} /> */}
                  <div
                    className="flexColumnWithoutStyle"
                    style={{ alignItems: "flex-start" }}
                  >
                    <div>
                      <span className="profileTitle">Campaign:</span>
                      <span>
                        {visaRecord?.customer?.campaign?.name ?? "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
                {visaRecord && (
                  <div
                    style={{
                      color: "grey",
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.5rem",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <span> Case Assign To:</span>
                    {visaRecord?.caseAssignedTo ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "0.5rem",
                          color: "black",
                        }}
                      >
                        <span>{visaRecord?.caseAssignedTo}</span>
                        <Button
                          size="small"
                          style={{ borderRadius: "0.4rem" }}
                          type="danger"
                          onClick={() => {
                            setAssigneEmail(visaRecord?.caseAssignedTo);
                            setIsUpdate(true);
                            setIsAsignModelVisible(true);
                          }}
                        >
                          Assign Case Update
                        </Button>
                      </div>
                    ) : (
                      <Button
                        size="small"
                        style={{ borderRadius: "0.4rem" }}
                        type="danger"
                        onClick={() => {
                          setIsAsignModelVisible(true);
                          setIsUpdate(false);
                          setAssigneEmail(null);
                        }}
                      >
                        Assign Case
                      </Button>
                    )}
                  </div>
                )}
              </div>

              <div
                className="flexRowWithoutStyle"
                style={{
                  gap: "1rem",
                  // position: "absolute",
                  // bottom: 0,
                }}
              >
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
                  "CHANGE_STATUS"
                ) && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsStatusUpdateModalVisible(true);
                    }}
                  >
                    Status Update
                  </Button>
                )}
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
                  "SEND_EMAIL"
                ) && (
                  <button
                    className="emailAndMessage"
                    onClick={() => {
                      setIsSendComposedEmailModalVisible(true);
                    }}
                  >
                    <AiOutlineMail />
                    <span>Email</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "emailLog" && <EmailLogDetail />}
          {current === "caseDetails" && <CaseDetails visaRecord={visaRecord} />}
          {current === "documentChecklist" && (
            <div>
              <div style={{ width: "100%" }}>
                <h2>Document Attachment:</h2>
                <div style={{ marginTop: "1rem" }}>
                  {store?.visaState?.caseById?.requiredWorkflowCheckList
                    ?.length > 0 ? (
                    store?.visaState?.caseById?.requiredWorkflowCheckList.map(
                      (dataObj, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "1px solid #ccc",
                              borderRadius: "20rem",
                              padding: "2px 4px",
                              margin: "0.5rem",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                gap: "1rem",
                                alignItems: "center",
                                padding: "0.5rem",
                              }}
                            >
                              {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
                                "CHANGE_STATUS"
                              ) && (
                                // <Checkbox
                                //   defaultChecked={dataObj?.isChecked}
                                //   onChange={(e) => {
                                //     dispatch({
                                //       type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
                                //       payload: {
                                //         caseId: visaRecord.id,
                                //         documentChecklistId:
                                //           dataObj?.documentCheckListResponse
                                //             ?.id,
                                //         courseCheckListId: null,
                                //         uploadAttachment: null,
                                //         isChecked: e.target.checked,
                                //         isSuccessFn: true,
                                //         successFn: successFn,
                                //       },
                                //     });
                                //   }}
                                // >
                                //   {dataObj?.documentCheckListResponse?.item}
                                // </Checkbox>
                                <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "0.5rem",
                                    alignItems: "center",
                                  }}
                                >
                                  {dataObj.uploadedAttachment && (
                                    <CheckCircleOutlined
                                      style={{
                                        fontSize: "1.5rem",
                                        color: "green",
                                      }}
                                    />
                                  )}
                                  {dataObj?.documentCheckListResponse?.item}
                                </span>
                              )}
                              <div>
                                {
                                  dataObj?.documentCheckListResponse
                                    ?.documentSize
                                }{" "}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "0.5rem",
                                marginRight: "1.5rem",
                              }}
                            >
                              {dataObj.uploadedAttachment ? (
                                <Tooltip title="Download">
                                  <a href={dataObj.uploadedAttachment}>
                                    <div
                                      className="bordered"
                                      //   onClick={() => {
                                      //     setDocumentRecord(dataObj);
                                      //     setIsUpdateDocumentModalVisible(true);
                                      //   }}
                                    >
                                      <FcDocument
                                        style={{ fontSize: "30px" }}
                                      />
                                    </div>
                                  </a>
                                </Tooltip>
                              ) : null}

                              {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
                                "ATTACH_DOCUMENT"
                              ) && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <Input
                                    type="file"
                                    onChange={(e) => {
                                      // const uploadUrlFromUploadResponse = (
                                      //   response
                                      // ) => {
                                      //   console.log(response, "response");
                                      //   dispatch({
                                      //     type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
                                      //     payload: {
                                      //       caseId: visaRecord.id,
                                      //       documentChecklistId:
                                      //         dataObj?.documentCheckListResponse
                                      //           ?.id,
                                      //       courseCheckListId: null,
                                      //       uploadAttachment:
                                      //         response?.data?.imageUploadPath,
                                      //       isChecked: false,
                                      //       isSuccessFn: true,
                                      //       successFn: successFn,
                                      //     },
                                      //   });
                                      //   dispatch({
                                      //     type: "DOCUMENT_CHECKLIST_VISIBLE",
                                      //     payload: {
                                      //       index,
                                      //       documentChecklist: {
                                      //         ...response.data,
                                      //       },
                                      //     },
                                      //   });
                                      // }
                                      setDocumentRecord(dataObj);
                                      setFiles(e.target.files[0]);
                                      setFileIndex(index);
                                      // let formData = new FormData();
                                      // formData.append(
                                      //   "uploadAttachment",
                                      //   e.target.files[0]
                                      // );
                                      // dispatch({
                                      //   type: "UPLOAD_CASE_DOCUMENTS_REQUEST",
                                      //   payload: formData,
                                      //   payload2: uploadUrlFromUploadResponse,
                                      // });
                                    }}
                                  />
                                  <Button
                                    type="primary"
                                    loading={
                                      fileIndex === index &&
                                      store?.visaState?.isUploadLoading
                                    }
                                    ghost
                                    style={{ height: "100%" }}
                                    onClick={() => {
                                      let formData = new FormData();
                                      formData.append("uploadAttachment", file);
                                      fileIndex === index &&
                                        file &&
                                        dispatch({
                                          type: "UPLOAD_CASE_DOCUMENTS_REQUEST",
                                          payload: formData,
                                          // payload2: uploadUrlFromUploadResponse,
                                          isSuccessFn: true,
                                          successFunction: (res) => {
                                            setTimeout(() => {
                                              uploadUrlFromUploadResponse(res);
                                            }, 2000);
                                          },
                                        });

                                      const uploadUrlFromUploadResponse = (
                                        response
                                      ) => {
                                        dispatch({
                                          type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
                                          payload: {
                                            caseId: visaRecord.id,
                                            documentChecklistId:
                                              dataObj?.documentCheckListResponse
                                                ?.id,
                                            courseCheckListId: null,
                                            uploadAttachment:
                                              response?.data?.imageUploadPath,
                                            // isChecked: false,
                                            isChecked: null,
                                            isSuccessFn: true,
                                            successFn: successFn,
                                          },
                                          isSuccessFn: true,
                                          successFunction: () => {
                                            setFiles(null);
                                            setFileIndex(null);
                                          },
                                        });
                                        dispatch({
                                          type: "DOCUMENT_CHECKLIST_VISIBLE",
                                          payload: {
                                            index,
                                            documentChecklist: {
                                              ...response.data,
                                            },
                                          },
                                        });
                                      };
                                    }}
                                  >
                                    Approve & Save
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      No Workflow Document Checklist Available
                    </div>
                  )}
                </div>
                {record?.visaCategory !== "MIGRATION" && (
                  <div>
                    <h2>Course Attachment:</h2>
                    <div style={{ marginTop: "1rem" }}>
                      {store?.visaState?.caseById?.requiredCoursesCheckList
                        ?.length > 0 ? (
                        store?.visaState?.caseById?.requiredCoursesCheckList.map(
                          (dataObj, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  border: "1px solid #ccc",
                                  borderRadius: "20rem",
                                  padding: "2px 4px",
                                  margin: "0.5rem",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: "1rem",
                                    alignItems: "center",
                                    padding: "0.5rem",
                                  }}
                                >
                                  {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
                                    "ATTACH_DOCUMENT"
                                  ) && (
                                    // <Checkbox
                                    //   defaultChecked={dataObj?.isChecked}
                                    //   onChange={(e) => {
                                    //     dispatch({
                                    //       type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
                                    //       payload: {
                                    //         caseId: visaRecord.id,
                                    //         documentChecklistId: null,
                                    //         courseCheckListId:
                                    //           dataObj?.courseCheckListResponse?.id,
                                    //         uploadAttachment: null,
                                    //         isChecked: e.target.checked,
                                    //         isSuccessFn: true,
                                    //         successFn: successFn,
                                    //       },
                                    //     });
                                    //   }}
                                    // >
                                    //   {dataObj?.courseCheckListResponse?.item}
                                    // </Checkbox>
                                    <span
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "0.5rem",
                                        alignItems: "center",
                                      }}
                                    >
                                      {dataObj.uploadedAttachment && (
                                        <CheckCircleOutlined
                                          style={{
                                            fontSize: "1.5rem",
                                            color: "green",
                                          }}
                                        />
                                      )}
                                      {dataObj?.courseCheckListResponse?.item}
                                    </span>
                                  )}

                                  <div>
                                    {
                                      dataObj?.courseCheckListResponse
                                        ?.documentSize
                                    }
                                  </div>
                                  {/* <div>{dataObj?.courseCheckListResponse?.documentType}</div> */}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "0.5rem",
                                    marginRight: "1.5rem",
                                  }}
                                >
                                  {dataObj.uploadedAttachment ? (
                                    <Tooltip title="Download">
                                      <a href={dataObj.uploadedAttachment}>
                                        <div
                                          className="bordered"
                                          //   onClick={() => {
                                          //     setDocumentRecord(dataObj);
                                          //     setIsUpdateDocumentModalVisible(true);
                                          //   }}
                                        >
                                          <FcDocument
                                            style={{ fontSize: "30px" }}
                                          />
                                        </div>
                                      </a>
                                    </Tooltip>
                                  ) : null}
                                  {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
                                    "ATTACH_DOCUMENT"
                                  ) && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Input
                                        type="file"
                                        onChange={(e) => {
                                          // const uploadUrlFromUploadResponse = (
                                          //   response
                                          // ) => {
                                          //   dispatch({
                                          //     type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
                                          //     payload: {
                                          //       caseId: visaRecord.id,
                                          //       documentChecklistId: null,
                                          //       courseCheckListId:
                                          //         dataObj?.courseCheckListResponse
                                          //           ?.id,
                                          //       uploadAttachment:
                                          //         response?.data?.imageUploadPath,
                                          //       isChecked: false,
                                          //       isSuccessFn: true,
                                          //       successFn: successFn,
                                          //     },
                                          //   });
                                          //   dispatch({
                                          //     type: "COURSE_CHECKLIST_VISIBLE",
                                          //     payload: {
                                          //       index,
                                          //       courseCheckList: {
                                          //         ...response.data,
                                          //       },
                                          //     },
                                          //   });
                                          // };
                                          // let formData = new FormData();
                                          // formData.append(
                                          //   "uploadAttachment",
                                          //   e.target.files[0]
                                          // );
                                          // dispatch({
                                          //   type: "UPLOAD_CASE_DOCUMENTS_REQUEST",
                                          //   payload: formData,
                                          //   payload2: uploadUrlFromUploadResponse,
                                          // });
                                          setCourseFile(e.target.files[0]);
                                          setCourseIndex(index);
                                        }}
                                      />
                                      <Button
                                        type="primary"
                                        ghost
                                        loading={
                                          courseIndex === index &&
                                          store?.visaState?.isUploadLoading
                                        }
                                        style={{ height: "100%" }}
                                        onClick={() => {
                                          let formData = new FormData();
                                          formData.append(
                                            "uploadAttachment",
                                            courseFile
                                          );
                                          courseIndex === index &&
                                            courseFile &&
                                            dispatch({
                                              type: "UPLOAD_CASE_DOCUMENTS_REQUEST",
                                              payload: formData,
                                              // payload2:
                                              //   uploadUrlFromUploadResponse,
                                              isSuccessFn: true,
                                              successFunction: (res) => {
                                                setTimeout(() => {
                                                  uploadUrlFromUploadResponse(
                                                    res
                                                  );
                                                }, 2000);
                                              },
                                            });

                                          const uploadUrlFromUploadResponse = (
                                            response
                                          ) => {
                                            dispatch({
                                              type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
                                              payload: {
                                                caseId: visaRecord.id,
                                                documentChecklistId: null,
                                                courseCheckListId:
                                                  dataObj
                                                    ?.courseCheckListResponse
                                                    ?.id,
                                                uploadAttachment:
                                                  response?.data
                                                    ?.imageUploadPath,
                                                isChecked: false,
                                                isSuccessFn: true,
                                                successFn: successFn,
                                              },
                                              isSuccessFn: true,
                                              successFunction: () => {
                                                setCourseFile(null);
                                                setCourseIndex(null);
                                              },
                                            });
                                            dispatch({
                                              type: "COURSE_CHECKLIST_VISIBLE",
                                              payload: {
                                                index,
                                                courseCheckList: {
                                                  ...response.data,
                                                },
                                              },
                                            });
                                          };
                                        }}
                                      >
                                        Approve & Save
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          No Courses Document Checklist Available
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {current === "formToDownload" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1px",
                flexWrap: "wrap",
              }}
            ></div>
          )}

          {current === "task" && (
            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "0 0 1rem",
                  }}
                >
                  <Button
                    onClick={() => {
                      setIsAddTaskModalVisible(true);
                    }}
                    type="primary"
                  >
                    Add Task
                  </Button>
                </div>
                <TaskTable
                  record={visaRecord?.taskRelatedToCase}
                  updateType="case"
                  fetchCaseDetail={fetchCaseDetail}
                />
              </div>
            </div>
          )}

          {current === "paymentPlan" && (
            <CasePaymentPlan
              visaRecord={visaRecord}
              fetchCasePaymentPlanDetail={fetchCasePaymentPlanDetail}
            />
          )}
          {current === "activityLog" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "1px",
                flexWrap: "wrap",
              }}
            >
              <CaseLog record={record} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                {store?.visaState?.currentWorkflowStatus?.map(
                  (dataObj, index) => {
                    return (
                      <div>
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            border: "1px solid #ccc",
                            borderRadius: "20rem",
                            padding: "2px 4px",
                            margin: "0.5rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              gap: "1rem",
                              alignItems: "center",
                              padding: "0.5rem",
                            }}
                          >
                            {dataObj?.visitedStatus === "VISITED" ? (
                              <ImCheckboxChecked color="green" />
                            ) : dataObj?.visitedStatus === "CURRENT" ? (
                              <ImCheckboxUnchecked
                                color="green"
                                style={{ backgroundColor: "green" }}
                              />
                            ) : (
                              <ImCheckboxUnchecked color="gray" />
                            )}
                            {dataObj?.statusName}
                            <div>
                              {dataObj?.visitedStatus === "VISITED" ? "" : ""}
                            </div>
                          </div>
                        </div>
                        {dataObj?.visitedStatus === "CURRENT" &&
                        dataObj?.isLastState === true &&
                        store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
                          "CHANGE_STATUS"
                        ) ? (
                          <div>
                            <Form
                              layout="vertical"
                              colon={true}
                              form={form}
                              initialValues={{
                                Outcome: visaRecord?.visaOutcome,
                              }}
                            >
                              <Form.Item
                                name={"Outcome"}
                                label={"Visa Outcome"}
                                style={{ width: "100%" }}
                              >
                                <Select
                                  label="Visa Outcome"
                                  onChange={(value) => {
                                    dispatch({
                                      type: "UPDATE_VISA_OUTCOME_REQUEST",
                                      payload: {
                                        id: visaRecord.id,
                                        data: { visaOutcome: value },
                                      },
                                      isSuccessFn: true,
                                      successFunction: () => {
                                        fetchCaseDetail();
                                      },
                                    });
                                  }}
                                >
                                  <Option value="HOLD">Hold</Option>
                                  <Option value="WITHDRAWN">Withdrawn</Option>
                                  <Option value="GRANTED">Granted</Option>
                                  <Option value="REFUSED">Refused</Option>
                                  <Option value="NOT_ASSIGNED">
                                    Not Assigned
                                  </Option>
                                </Select>
                              </Form.Item>
                            </Form>
                          </div>
                        ) : null}
                      </div>
                    );
                  }
                )}
              </div>

              {/* <Card style={{ width: 300 }}>
                <div className="flexRowSpaceBetween">
                  IELTS score: <span>{visaRecord?.customer?.ieltsScore}</span>
                </div>
                <div className="flexRowSpaceBetween">
                  SAT score: <span>{visaRecord?.customer?.satIbtScore}</span>
                </div>
                <div className="flexRowSpaceBetween">
                  PTE score: <span>{visaRecord?.customer?.pteScore}</span>
                </div>
                <div className="flexRowSpaceBetween">
                  GRE score: <span>{visaRecord?.customer?.greIbtScore}</span>
                </div>
                <div className="flexRowSpaceBetween">
                  Tofel score:{" "}
                  <span>{visaRecord?.customer?.yearsOfCommencement}</span>
                </div>
                <div className="flexRowSpaceBetween">
                  GMAT Score: <span>{visaRecord?.customer?.gmatIbtScore}</span>
                </div>
              </Card> */}
            </div>
          )}
        </Card>
      </Card>
      {isStatusHistoryModelVisible && (
        <CaseStatusHistory
          record={visaRecord}
          isUpdateCaseStatusModalVisible={isStatusHistoryModelVisible}
          setIsUpdateCaseStatusModalVisible={setIsStatusHistoryModelVisible}
        />
      )}
      {isStatusUpdateModalVisible && (
        <UpdateCaseStatusCaseLog
          record={visaRecord}
          fetchCaseDetail={fetchCaseDetail}
          isUpdateCaseStatusCaseLogModalVisible={isStatusUpdateModalVisible}
          setIsUpdateCaseStatusCaseLogModalVisible={
            setIsStatusUpdateModalVisible
          }
        />
      )}
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          mailTemplateType="CASE"
          composeMailRecord={{
            toUser: [visaRecord?.customer?.email],
          }}
          forWhatId={visaRecord?.id}
          fetchEmailLogDetails={fetchEmailLogDetails}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
      {isAsignModelVisible && (
        <AsignEmailToCase
          caseId={visaRecord?.id}
          isUpdate={isUpdate}
          assigneEmail={assigneEmail}
          successFn={successFn}
          isAsignModelVisible={isAsignModelVisible}
          setIsAsignModelVisible={setIsAsignModelVisible}
        />
      )}
      {isAddTaskModalVisible && (
        <AddTask
          taskType="Case"
          taskId={visaRecord?.id}
          isAddTaskModalVisible={isAddTaskModalVisible}
          setIsAddTaskModalVisible={setIsAddTaskModalVisible}
          fetchCaseDetail={fetchCaseDetail}
        />
      )}
    </Drawer>
  );
};

export default VisaCaseDetails;
