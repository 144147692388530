import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { IoGridOutline } from "react-icons/io5";
import { FaFolder, FaList } from "react-icons/fa";
import { IoMdMore } from "react-icons/io";
import { FaCaretRight } from "react-icons/fa";

import {
  Avatar,
  Card,
  Divider,
  Input,
  List,
  Pagination,
  Popconfirm,
  Popover,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import publicDrive from "./../../Assets/publicDrive.png";
import privateDrive from "./../../Assets/privateDrive.png";
import { BsFolder, BsUpload } from "react-icons/bs";
import { GrActions } from "react-icons/gr";
import { BiArrowBack } from "react-icons/bi";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFile,
  AiOutlinePlus,
} from "react-icons/ai";
import AddNewFolder from "./AddNewFolder";
import AddPrivateNewFolder from "./AddPrivateNewFolder";
import RenameFolder from "./RenameFolder";
import FileUpload from "./FiileUpload";
import { FiUpload } from "react-icons/fi";
import PublicFileUpload from "./PublicFileUpload";
import moment from "moment";
import { debounce, isSet } from "lodash";

const columns = [
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const Resources = () => {
  const store = useSelector((state) => {
    return {
      driveState: state.ResourcesReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });

  const [fileFolderList, setFileFolderList] = useState([]);
  const [fileFolderPrivateList, setFileFolderPrivateList] = useState([]);
  const [breadcrumbList, setBreadcrumbList] = useState([]);
  const [openPopover, setOpenPopover] = useState(false);
  const [isGridView, setIsGridView] = useState(false);
  const [isAddResourcesModalVisible, setIsAddResourcesModalVisible] =
    useState(false);
  const [isUpdateResourcesModalVisible, setIsUpdateResourcesModalVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const [driveType, setResourcesType] = useState("PUBLIC");
  const [item, setItem] = useState();
  const [isAddNewFolderModalVisible, setIsAddNewFolderModalVisible] =
    useState(false);
  const [
    isAddPrivateNewFolderModalVisible,
    setIsAddPrivateNewFolderModalVisible,
  ] = useState(false);
  const [isRenameFolderModalVisible, setIsRenameFolderModalVisible] =
    useState(false);
  const [isUploadFileModalVisible, setIsUploadFileModalVisible] =
    useState(false);
  const [isUploadPublicFileModalVisible, setIsUploadPublicFileModalVisible] =
    useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };

  console.log("testing data", store?.driveState?.privateDrive?.data);

  useEffect(() => {
    setFileFolderList(store?.driveState.publicDrive?.data);
    setFileFolderPrivateList(store?.driveState?.privateDrive?.data);
  }, [store?.driveState.publicDrive, store?.driveState.privateDrive]);

  useEffect(() => {
    if (driveType === "PRIVATE") {
      dispatch({
        type: "FETCH_PRIVATE_DRIVE_REQUEST",
        payload: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else {
      dispatch({
        type: "FETCH_PUBLIC_DRIVE_REQUEST",
        payload: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    }
  }, [driveType, page, pageSize, store.branchState?.branchRecord]);

  const onChange = (e) => {
    const searchTerm = e.target.value;
    searchTerm
      ? setFileFolderPrivateList(
          store?.driveState?.privateDrive?.data?.filter((item) =>
            item?.contentName?.toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
      : setFileFolderPrivateList(store?.driveState?.privateDrive?.data);
  };
  const debouncedOnChange = debounce(onChange, 1500);

  const onChangePublic = (e) => {
    const searchTerm = e.target.value;
    searchTerm
      ? setFileFolderList(
          store?.driveState?.publicDrive?.data?.filter((item) =>
            item?.contentName?.toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
      : setFileFolderList(store?.driveState?.publicDrive?.data);
  };
  const debouncedOnChangePublic = debounce(onChangePublic, 1500);

  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow" style={{ justifyContent: "center" }}>
          <Segmented
            options={[
              {
                label: (
                  <div
                    style={{ padding: 4 }}
                    onClick={() => {
                      setBreadcrumbList([]);
                    }}
                  >
                    <Avatar src={publicDrive} size={20} shape="square" />
                    <div>Public Drive </div>
                  </div>
                ),
                value: "PUBLIC",
              },
              {
                label: (
                  <div
                    style={{ padding: 4 }}
                    onClick={() => {
                      setBreadcrumbList([]);
                    }}
                  >
                    <Avatar src={privateDrive} size={20} shape="square" />
                    <div>Private Drive</div>
                  </div>
                ),
                value: "PRIVATE",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setResourcesType(value);
              console.log(value);
            }}
          />
        </div>
        {driveType === "PUBLIC" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                margin: "0 0 1rem",
              }}
            >
              <Input.Search
                placeholder="Search"
                size="large"
                style={{ width: "20rem" }}
                onChange={debouncedOnChangePublic}
              />
            </div>{" "}
            <div>
              {store?.driveState.publicDrive.totalData === null && (
                <div
                  onClick={() => {
                    if (store?.driveState.publicDrive.parentId === null) {
                      dispatch({
                        type: "FETCH_PUBLIC_DRIVE_REQUEST",
                        payload: {
                          page,
                          pageSize,
                          branchId:
                            store.branchState?.branchRecord.id ??
                            localStorage.getItem("branchId"),
                        },
                      });
                      return;
                    }

                    dispatch({
                      type: "FETCH_PUBLICBY_ID_DRIVE_REQUEST",
                      payload: store?.driveState.publicDrive.parentId,
                    });
                  }}
                >
                  <Tooltip title="Back">
                    <BiArrowBack
                      onClick={() => {
                        const tempdata = breadcrumbList;
                        tempdata.pop();
                        setBreadcrumbList(() => [...tempdata]);
                      }}
                      className="iconStyle"
                    />
                  </Tooltip>
                </div>
              )}
              <List
                grid={{ column: isGridView ? 6 : 1 }}
                header={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: "0.5rem",
                      }}
                    >
                      {" "}
                      Public Drive{" "}
                      {breadcrumbList?.length > 0 &&
                        breadcrumbList?.map((item) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              {" "}
                              <FaCaretRight />
                              {item}
                            </div>
                          );
                        })}
                    </div>
                    <div
                      className="flexRowWithoutStyle"
                      style={{ gap: "0.5rem" }}
                    >
                      {isGridView ? (
                        <Tooltip title="Make List View">
                          <FaList
                            className="iconStyle"
                            onClick={() => setIsGridView(false)}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Make Grid View">
                          <IoGridOutline
                            className="iconStyle"
                            onClick={() => setIsGridView(true)}
                          />
                        </Tooltip>
                      )}
                      {store.profileState?.profile?.department?.rolesAndPermissionList?.RESOURCES?.includes(
                        "UPLOAD_FILE"
                      ) && (
                        <Tooltip title="Upload File">
                          <FiUpload
                            className="iconStyle"
                            onClick={() => {
                              setItem(item);
                              setIsUploadPublicFileModalVisible(true);
                            }}
                          />
                        </Tooltip>
                      )}
                      {store.profileState?.profile?.department?.rolesAndPermissionList?.RESOURCES?.includes(
                        "ADD_FOLDER"
                      ) && (
                        <Tooltip title="Add Folder">
                          <AiOutlinePlus
                            className="iconStyle"
                            onClick={() => {
                              setIsAddNewFolderModalVisible(true);
                            }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                }
                bordered
                // dataSource={store?.driveState.publicDrive?.data}
                dataSource={fileFolderList}
                renderItem={(item) => {
                  return isGridView ? (
                    <List.Item
                      className="flexRowWithoutStyle"
                      style={{
                        justifyContent: "space-between",
                        marginTop: "1rem",
                        cursor: "pointer",
                        height: "100%",
                      }}
                    >
                      {item.idType === "FOLDER" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            border: "1px solid #f5f5f5",
                            padding: "0 0.5rem",
                            borderRadius: "0.5rem",
                            height: "7rem",
                          }}
                        >
                          <Popover
                            placement="rightTop"
                            content={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                  cursor: "pointer",
                                }}
                              >
                                <p
                                  onClick={() => {
                                    setItem(item);
                                    setIsRenameFolderModalVisible(true);
                                  }}
                                  style={{ margin: "0" }}
                                >
                                  Rename
                                </p>
                                <Divider style={{ margin: 0 }} />
                                <p
                                  onClick={() => {
                                    dispatch({
                                      type: "DELETE_FOLDER_REQUEST",
                                      payload: item.id,
                                    });
                                  }}
                                  style={{ color: "red", margin: "0" }}
                                >
                                  Delete
                                </p>
                              </div>
                            }
                          >
                            <IoMdMore
                              style={{
                                position: "absolute",
                                top: "0.3rem",
                                right: 0,
                                fontSize: "1.2rem",
                                color: "gray",
                              }}
                            />
                          </Popover>

                          <FaFolder
                            onClick={() => {
                              setBreadcrumbList((prev) => [
                                ...prev,
                                item?.contentName,
                              ]);
                              dispatch({
                                type: "FETCH_PUBLICBY_ID_DRIVE_REQUEST",
                                payload: item.id,
                              });
                            }}
                            style={{
                              fontSize: "3rem",
                              color: "#f8d172",
                            }}
                          />
                          <div
                            onClick={() => {
                              setBreadcrumbList((prev) => [
                                ...prev,
                                item?.contentName,
                              ]);
                              dispatch({
                                type: "FETCH_PUBLICBY_ID_DRIVE_REQUEST",
                                payload: item.id,
                              });
                            }}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span>
                              {item?.contentName.substring(
                                0,
                                item?.contentName.lastIndexOf("/")
                              )
                                ? item?.contentName.substring(
                                    0,
                                    item?.contentName.lastIndexOf("/")
                                  )
                                : item?.contentName}
                            </span>
                            {/* <span>
                              {moment
                                .utc(
                                  item?.contentName.substring(
                                    item?.contentName.lastIndexOf("/") + 1
                                  )
                                )
                                .local()
                                .format("YYYY-MM-DD HH:mm:ss")}
                            </span> */}
                          </div>
                        </div>
                      ) : (
                        <a href={item.fileContent}>
                          <Card
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <AiOutlineFile size={30} color={"#f8d172"} />
                            <span> {item?.contentName}</span>
                          </Card>
                        </a>
                      )}
                    </List.Item>
                  ) : (
                    <List.Item
                      className="flexRowWithoutStyle"
                      style={{
                        justifyContent: "space-between",
                        width: "100%",
                        cursor: "pointer",
                        marginTop: "0.5rem",
                      }}
                    >
                      <div
                        className="flexRowWithoutStyle"
                        style={{
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        {item.idType === "FOLDER" ? (
                          <div
                            className="folderFlex"
                            onClick={() => {
                              setBreadcrumbList((prev) => [
                                ...prev,
                                item?.contentName,
                              ]);
                              dispatch({
                                type: "FETCH_PUBLICBY_ID_DRIVE_REQUEST",
                                payload: item.id,
                              });
                            }}
                          >
                            <BsFolder size={30} color={"#f8d172"} />
                            {item?.contentName}
                          </div>
                        ) : (
                          <a href={item.fileContent}>
                            <div className="folderFlex">
                              <AiOutlineFile size={30} color={"#f8d172"} />
                              {item?.contentName}
                            </div>{" "}
                          </a>
                        )}

                        <div
                          style={{
                            display: "flex",

                            gap: "0.5rem",
                            justifyContent: "space-between",
                            width: "7%",
                          }}
                        >
                          {store.profileState?.profile?.department?.rolesAndPermissionList?.RESOURCES?.includes(
                            "RENAME"
                          ) && (
                            <Tooltip title="Rename">
                              <AiOutlineEdit
                                className="iconStyle"
                                onClick={() => {
                                  setItem(item);
                                  setIsRenameFolderModalVisible(true);
                                }}
                              />
                            </Tooltip>
                          )}
                          {store.profileState?.profile?.department?.rolesAndPermissionList?.RESOURCES?.includes(
                            "DELETE"
                          ) && (
                            <Popconfirm
                              title="Are you sure to delete this file/folder?"
                              onConfirm={() => {
                                dispatch({
                                  type: "DELETE_FOLDER_REQUEST",
                                  payload: item.id,
                                });
                              }}
                              onCancel={() => {}}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Tooltip title="Delete">
                                <AiOutlineDelete className="iconStyle" />
                              </Tooltip>
                            </Popconfirm>
                          )}
                        </div>
                      </div>
                    </List.Item>
                  );
                }}
              />
            </div>
            {store.driveState?.publicDrive?.totalData ? (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                current={page}
                defaultPageSize={pageSize}
                onChange={onShowSizeChange}
                total={store?.driveState?.publicDrive?.totalPage * 10}
              />
            ) : null}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                margin: "0 0 1rem",
              }}
            >
              <Input.Search
                placeholder="Search"
                size="large"
                style={{ width: "20rem" }}
                onChange={debouncedOnChange}
              />
            </div>{" "}
            <div>
              {store?.driveState.privateDrive.totalData === null && (
                <div
                  onClick={() => {
                    if (store?.driveState.privateDrive.parentId === null) {
                      dispatch({
                        type: "FETCH_PRIVATE_DRIVE_REQUEST",
                        payload: {
                          page,
                          pageSize,
                          branchId:
                            store.branchState?.branchRecord.id ??
                            localStorage.getItem("branchId"),
                        },
                      });
                      return;
                    }

                    dispatch({
                      type: "FETCH_PRIVATEBY_ID_DRIVE_REQUEST",
                      payload: store?.driveState.privateDrive.parentId,
                    });
                  }}
                >
                  <Tooltip title="Back">
                    <BiArrowBack
                      onClick={() => {
                        const tempdata = breadcrumbList;
                        tempdata.pop();
                        setBreadcrumbList(() => [...tempdata]);
                      }}
                      className="iconStyle"
                    />
                  </Tooltip>
                </div>
              )}
              <List
                grid={{ column: isGridView ? 6 : 1 }}
                header={
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: "0.5rem",
                      }}
                    >
                      {" "}
                      Private Drive{" "}
                      {breadcrumbList?.length > 0 &&
                        breadcrumbList?.map((item) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              {" "}
                              <FaCaretRight />
                              {item}
                            </div>
                          );
                        })}
                    </div>{" "}
                    <div
                      className="flexRowWithoutStyle"
                      style={{ gap: "0.5rem" }}
                    >
                      {isGridView ? (
                        <Tooltip title="Make List View">
                          <FaList
                            className="iconStyle"
                            onClick={() => setIsGridView(false)}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Make Grid View">
                          <IoGridOutline
                            className="iconStyle"
                            onClick={() => setIsGridView(true)}
                          />
                        </Tooltip>
                      )}
                      {store.profileState?.profile?.department?.rolesAndPermissionList?.RESOURCES?.includes(
                        "UPLOAD_FILE"
                      ) && (
                        <Tooltip title="Upload File">
                          <FiUpload
                            className="iconStyle"
                            onClick={() => {
                              setItem(item);
                              setIsUploadFileModalVisible(true);
                            }}
                          />
                        </Tooltip>
                      )}
                      {store.profileState?.profile?.department?.rolesAndPermissionList?.RESOURCES?.includes(
                        "ADD_FOLDER"
                      ) && (
                        <Tooltip title="Add Folder">
                          <AiOutlinePlus
                            className="iconStyle"
                            onClick={() => {
                              setIsAddPrivateNewFolderModalVisible(true);
                            }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                }
                //   footer={<div>Footer</div>}
                bordered
                // dataSource={store?.driveState.privateDrive?.data}
                dataSource={fileFolderPrivateList}
                renderItem={(item) => {
                  return isGridView ? (
                    <List.Item
                      className="flexRowWithoutStyle"
                      style={{
                        justifyContent: "space-between",
                        marginTop: "1rem",
                        cursor: "pointer",
                        height: "100%",
                      }}
                    >
                      {item.idType === "FOLDER" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            border: "1px solid #f5f5f5",
                            padding: "0 0.5rem",
                            borderRadius: "0.5rem",
                            height: "7rem",
                          }}
                        >
                          <Popover
                            placement="rightTop"
                            content={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                  cursor: "pointer",
                                }}
                              >
                                <p
                                  onClick={() => {
                                    setItem(item);
                                    setIsRenameFolderModalVisible(true);
                                  }}
                                  style={{ margin: "0" }}
                                >
                                  Rename
                                </p>
                                <Divider style={{ margin: 0 }} />
                                <p
                                  onClick={() => {
                                    dispatch({
                                      type: "DELETE_FOLDER_REQUEST",
                                      payload: item.id,
                                    });
                                  }}
                                  style={{ color: "red", margin: "0" }}
                                >
                                  Delete
                                </p>
                              </div>
                            }
                          >
                            <IoMdMore
                              style={{
                                position: "absolute",
                                top: "0.3rem",
                                right: 0,
                                fontSize: "1.2rem",
                                color: "gray",
                              }}
                            />
                          </Popover>

                          <FaFolder
                            onClick={() => {
                              dispatch({
                                type: "FETCH_PUBLICBY_ID_DRIVE_REQUEST",
                                payload: item.id,
                              });
                              setBreadcrumbList((prev) => [
                                ...prev,
                                item?.contentName,
                              ]);
                            }}
                            style={{
                              fontSize: "3rem",
                              color: "#f8d172",
                            }}
                          />
                          <div
                            onClick={() => {
                              dispatch({
                                type: "FETCH_PUBLICBY_ID_DRIVE_REQUEST",
                                payload: item.id,
                              });
                              setBreadcrumbList((prev) => [
                                ...prev,
                                item?.contentName,
                              ]);
                            }}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span>
                              {item?.contentName.substring(
                                0,
                                item?.contentName.lastIndexOf("/")
                              )
                                ? item?.contentName.substring(
                                    0,
                                    item?.contentName.lastIndexOf("/")
                                  )
                                : item?.contentName}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <a href={item.fileContent}>
                          <Card
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <AiOutlineFile size={30} color={"#f8d172"} />
                            <span> {item?.contentName}selectedFile</span>{" "}
                          </Card>
                        </a>
                      )}
                    </List.Item>
                  ) : (
                    <List.Item>
                      <div
                        className="flexRowWithoutStyle"
                        style={{
                          justifyContent: "space-between",
                          width: "100%",
                          cursor: "pointer",
                          marginTop: "0.5rem",
                        }}
                      >
                        {item.idType === "FOLDER" ? (
                          <div
                            className="folderFlex"
                            onClick={() => {
                              dispatch({
                                type: "FETCH_PRIVATEBY_ID_DRIVE_REQUEST",
                                payload: item.id,
                              });
                              setBreadcrumbList((prev) => [
                                ...prev,
                                item?.contentName,
                              ]);
                            }}
                          >
                            <BsFolder size={30} color={"#f8d172"} />
                            {item?.contentName}
                          </div>
                        ) : (
                          <a href={item.fileContent}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <AiOutlineFile size={30} color={"#f8d172"} />
                              <span> {item?.contentName}</span>{" "}
                            </div>{" "}
                          </a>
                        )}

                        <div
                          style={{
                            display: "flex",

                            gap: "0.5rem",
                            justifyContent: "space-between",
                            width: "7%",
                          }}
                        >
                          {store.profileState?.profile?.department?.rolesAndPermissionList?.RESOURCES?.includes(
                            "RENAME"
                          ) && (
                            <Tooltip title="Rename">
                              <AiOutlineEdit
                                className="iconStyle"
                                onClick={() => {
                                  setItem(item);
                                  setIsRenameFolderModalVisible(true);
                                }}
                              />
                            </Tooltip>
                          )}
                          {store.profileState?.profile?.department?.rolesAndPermissionList?.RESOURCES?.includes(
                            "DELETE"
                          ) && (
                            <Popconfirm
                              title="Are you sure to delete this file/folder?"
                              onConfirm={() => {
                                dispatch({
                                  type: "DELETE_FOLDER_REQUEST",
                                  payload: item.id,
                                });
                              }}
                              onCancel={() => {}}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Tooltip title="Delete">
                                <AiOutlineDelete className="iconStyle" />
                              </Tooltip>
                            </Popconfirm>
                          )}
                        </div>
                      </div>
                    </List.Item>
                  );
                }}
              />
            </div>
            {store.driveState?.privateDrive?.totalData ? (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                  cursor: "pointer",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                current={page}
                defaultPageSize={pageSize}
                onChange={onShowSizeChange}
                total={store?.driveState?.privateDrive?.totalPage * 10}
              />
            ) : null}
          </div>
        )}
      </div>
      {isAddNewFolderModalVisible && (
        <AddNewFolder
          driveType={driveType}
          parentId={store?.driveState.publicDrive.id}
          isAddNewFolderModalVisible={isAddNewFolderModalVisible}
          setIsAddNewFolderModalVisible={setIsAddNewFolderModalVisible}
        />
      )}
      {isAddPrivateNewFolderModalVisible && (
        <AddPrivateNewFolder
          driveType={driveType}
          parentId={store?.driveState.privateDrive.id}
          isAddNewFolderModalVisible={isAddPrivateNewFolderModalVisible}
          setIsAddNewFolderModalVisible={setIsAddPrivateNewFolderModalVisible}
        />
      )}
      {isRenameFolderModalVisible && (
        <RenameFolder
          item={item}
          isRenameFolderModalVisible={isRenameFolderModalVisible}
          setIsRenameFolderModalVisible={setIsRenameFolderModalVisible}
        />
      )}
      {isUploadFileModalVisible && (
        <FileUpload
          item={store?.driveState.privateDrive}
          driveType={driveType}
          parentId={store?.driveState.privateDrive.id}
          isFileUploadModalVisible={isUploadFileModalVisible}
          setIsFileUploadModalVisible={setIsUploadFileModalVisible}
        />
      )}
      {isUploadPublicFileModalVisible && (
        <PublicFileUpload
          item={store?.driveState.publicDrive}
          driveType={driveType}
          parentId={store?.driveState.publicDrive.id}
          isFileUploadModalVisible={isUploadPublicFileModalVisible}
          setIsFileUploadModalVisible={setIsUploadPublicFileModalVisible}
        />
      )}
      {/* <UpdateResources
        record={record}
        isUpdateResourcesModalVisible={isUpdateResourcesModalVisible}
        setIsUpdateResourcesModalVisible={setIsUpdateResourcesModalVisible}
      /> */}
    </SiderDemo>
  );
};

export default Resources;
