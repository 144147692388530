import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Upload,
  message,
} from "antd";
import { useDispatch } from "react-redux";

const UpdateCompanyPackage = ({
  record,
  isUpdateModalVisible,
  setIsUpdateModalVisible,
  fetchCompanyByID,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isGst, setIsGst] = useState(record?.gst ? true : false);

  console.log("record", record);

  const handleUpdatePackage = (values) => {
    dispatch({
      type: "UPDATE_COMPANY_PACKAGE_REQUEST",
      payload: {
        ...values,
        id: record?.id,
        isGstFlat: isGst ? values?.isGstFlat : false,
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success("Package update success");
        setIsUpdateModalVisible(false);
        form.resetFields();
        fetchCompanyByID();
      },
    });
  };
  return (
    <Modal
      title="Update Company Package"
      open={isUpdateModalVisible}
      onCancel={() => {
        setIsUpdateModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={handleUpdatePackage}
        colon={true}
        form={form}
        initialValues={{
          packageType: record?.packageType,
          casePackageName: record?.packageName,
          unitPrice: record?.unitPrice,
          commission: record?.commission,
          gstStatus: record?.gst ? true : false,
          gst: record?.gst,
          isGstFlat: record?.isGstFlat,
        }}
      >
        <Form.Item
          label="Package Type"
          name={"packageType"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter case package type!",
            },
          ]}
        >
          <Radio.Group>
            <Radio value={"IN_HOUSE"}>Inhouse</Radio>
            <Radio value={"COMPANY"}>Company</Radio>
            <Radio value={"INSTITUTE"}>Institute</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Package Name"
          name={"casePackageName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter case package name!",
            },
          ]}
        >
          <Input placeholder="Package Name" />
        </Form.Item>
        <div style={{ display: "flex", width: "100%", gap: "0.5rem" }}>
          <Form.Item
            label="Unit Price"
            name={"unitPrice"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter unit price!",
              },
            ]}
          >
            <Input type="number" placeholder="Unit Price" />
          </Form.Item>
          <Form.Item
            label="Commission"
            name={"commission"}
            style={{ width: "100%" }}
          >
            <Input type="number" placeholder="Commission" />
          </Form.Item>
        </div>

        <Form.Item
          name={"gstStatus"}
          valuePropName="checked"
          style={{ width: "100%" }}
        >
          <Checkbox
            onChange={(e) => {
              setIsGst(e.target.checked);
            }}
          >
            Is GST/VAT included?
          </Checkbox>
        </Form.Item>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {isGst && (
            <div style={{ display: "flex", width: "100%", gap: "0.5rem" }}>
              <Form.Item
                label="GST/VAT amount"
                name={"gst"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: isGst ? true : false,
                    message: "Please enter GST!",
                  },
                ]}
              >
                <Input type="number" placeholder="GST" />
              </Form.Item>
              <Form.Item
                label={"GST/VAT Type"}
                name={"isGstFlat"}
                style={{ width: "100%" }}
              >
                <Radio.Group>
                  <Radio value={true}>is Flat</Radio>
                  <Radio value={false}>is Percentage</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          )}
        </div>
        <Button
          style={{ width: "100%", marginTop: "1rem" }}
          type="primary"
          htmlType="submit"
        >
          Update Package
        </Button>
      </Form>
    </Modal>
  );
};

export default UpdateCompanyPackage;
