import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

const AddReferral = ({
  isAddReferralModalVisible,
  setIsAddReferralModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addReferralSubmitHandler = (value) => {
    dispatch({
      type: "ADD_REFERRAL_REQUEST",
      payload: { ...value, branchId: localStorage.getItem("branchId") },
    });
    form.resetFields();
    setIsAddReferralModalVisible(false);
  };
  return (
    <Modal
      title="Add Referee"
      open={isAddReferralModalVisible}
      onCancel={() => {
        setIsAddReferralModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addReferralSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="First Name"
          name={"firstName"}
          rules={[
            {
              required: true,
              message: "Please enter first name!",
            },
          ]}
        >
          <Input placeholder="Please enter first name!" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name={"lastName"}
          rules={[
            {
              required: true,
              message: "Please enter last name!",
            },
          ]}
        >
          <Input placeholder="Please enter last name!" />
        </Form.Item>
        <Form.Item
          label="Email"
          name={"email"}
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter valid email!",
            },
          ]}
        >
          <Input type="email" placeholder="e.g, name@domainname.com" />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name={"phone"}
          rules={[
            {
              required: true,
              message: "Please enter Phone Number!",
            },
            {
              pattern: /^[\d]{9,11}$/,
              message: "Phone number should contain 9 to 11 numbers",
            },
          ]}
        >
          <Input type="number" placeholder="Please enter Phone Number!" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Referral
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddReferral;
