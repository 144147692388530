import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import AddDocumentTypes from "./AddDocumentTypes";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
const { Option } = Select;
const { TextArea } = Input;

const UpdateCustomerDocuments = ({
  record,
  isAddCustomerDocumentModalVisible,
  setIsAddCustomerDocumentModalVisible,
  setIsClientDetailsModelVisible,
}) => {
  const documentState = useSelector((state) => state.ClientsReducer);
  const documentChecklistState = useSelector(
    (state) => state?.DocumentCheckListReducer
  );

  console.log("testing docs", documentChecklistState);
  useEffect(() => {
    dispatch({ type: "FETCH_DOCUMENT_TYPES_REQUEST" });
  }, []);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedDocument, setSelectedDocument] = useState();

  const [isAddDocumentTypeModalVisible, setIsAddDocumentTypeModalVisible] =
    useState(false);

  useEffect(() => {
    handleGetDocumentChecklist();
  }, []);

  const handleGetDocumentChecklist = () => {
    dispatch({
      type: "FETCH_DOCUMENT_CHECKLIST_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  const addCustomerDocumentSubmitHandler = (value) => {
    const documentFormData = new FormData();
    documentFormData.append("documentType", value.documentType);
    documentFormData.append(
      "description",
      value.description ? value.description : null
    );
    documentFormData.append("document", selectedDocument);

    dispatch({
      type: "ADD_CUSTOMER_DOCUMENT_REQUEST",
      payload: { id: record.id, documentFormData },
    });
    form.resetFields();
    form.resetFields(["file"]);
    setSelectedDocument(null);
    setIsAddCustomerDocumentModalVisible(false);
  };
  return (
    <Drawer
      title="Add Customer Document"
      open={isAddCustomerDocumentModalVisible}
      onClose={() => {
        setIsAddCustomerDocumentModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={addCustomerDocumentSubmitHandler}
        colon={true}
        form={form}
        className={"drawerStyle"}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                gap: "1rem",
                width: "100%",
              }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Document Type"
                  name={"documentType"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please pick documents type!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Document Type"

                    // value={singleDocument[index].documentType}
                  >
                    {/* {documentState.documentTypes?.data?.map((dataObj) => {
                      return (
                        <Option key={dataObj.id}>{dataObj.documentType}</Option>
                      );
                    })} */}
                    {documentChecklistState?.documentCheclistData?.data?.map(
                      (dataObj) => {
                        return <Option key={dataObj.id}>{dataObj.name}</Option>;
                      }
                    )}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddDocumentTypeModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
              <Form.Item
                label="Document"
                name={"documents"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please upload the document!",
                  },
                ]}
              >
                <input
                  type="file"
                  placeholder="document"
                  onChange={(e) => {
                    setSelectedDocument(e.target.files[0]);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Description"
                name={"description"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     type: "number",
                //     message: "Please pick documents!",
                //   },
                // ]}
              >
                <TextArea
                  onChange={(e) => {}}
                  // value={singleDocument[index].description}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Upload
          </Button>
        </Form.Item>
      </Form>
      <AddDocumentTypes
        isAddDocumentTypesModalVisible={isAddDocumentTypeModalVisible}
        setIsAddDocumentTypesModalVisible={setIsAddDocumentTypeModalVisible}
      />
    </Drawer>
  );
};

export default UpdateCustomerDocuments;
