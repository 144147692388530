import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  message,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const AddVisaSubCategory = ({
  country = "",
  isAddVisaSubCategoryModalVisible,
  setIsAddVisaSubCategoryModalVisible,
  fetchEducationVisaSubClassRecord,
  fetchMigrationVisaSubclassRecord,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addVisaSubCategorySubmitHandler = (values) => {
    const formData = new FormData();
    formData.append("subClass", values?.subClass);
    formData.append("country", country);
    formData.append("description", null);
    formData.append("visaCategory", values?.visaCategory);

    dispatch({
      type: "ADD_EDUCATION_VISA_REQUEST",
      payload: { branchId: localStorage.getItem("branchId"), formData },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success("Success! visa subclass added");
        setIsAddVisaSubCategoryModalVisible(false);
        values?.visaCategory === "EDUCATION"
          ? fetchEducationVisaSubClassRecord()
          : fetchMigrationVisaSubclassRecord();
      },
    });

    // const formData = {
    //   ...value,
    // };
    // dispatch({
    //   type: "ADD_VISA_SUBCATEGORY_REQUEST",
    //   payload: {
    //     branchId: localStorage.getItem("branchId"),
    //     formData,
    //   },
    // });
    // // form.resetFields();
    // setIsAddVisaSubCategoryModalVisible(false);
  };
  return (
    <Modal
      title="Add Visa Subcategory"
      open={isAddVisaSubCategoryModalVisible}
      onCancel={() => {
        setIsAddVisaSubCategoryModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addVisaSubCategorySubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Name"
          name={"subClass"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter visa subcategory!",
            },
          ]}
        >
          <Input placeholder="Visa Sub Category" />
        </Form.Item>
        <Form.Item
          label="Visa Category"
          name={"visaCategory"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select visa category!",
            },
          ]}
        >
          <Select>
            <Option value="EDUCATION">Education</Option>
            <Option value="MIGRATION">Migration</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Visa Subcategory
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddVisaSubCategory;
