import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  documentCheclistData: [],
};

const DocumentCheckListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_DOCUMENT_CHECKLIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_DOCUMENT_CHECKLIST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        documentCheclistData: action.payload,
      };
    }
    case "FETCH_DOCUMENT_CHECKLIST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_QOUTEPDFFILE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_QOUTEPDFFILE_SUCCESS": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_QOUTEPDFFILE_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "IMPORT_DOCUMENT_CHECKLIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "IMPORT_DOCUMENT_CHECKLIST_SUCCESS": {
      message.success(action.payload?.message);

      return {
        ...state,
        isLoading: false,
        // documentCheclistData: action.payload,
      };
    }
    case "IMPORT_DOCUMENT_CHECKLIST_FAILURE": {
      message.error(action.payload?.response?.data?.message);

      return {
        ...state,
        isLoading: false,
      };
    }

    case "IMPORT_VISASUBCLASS_CHECKLIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "IMPORT_VISASUBCLASS_CHECKLIST_SUCCESS": {
      message.success(action.payload?.message);

      return {
        ...state,
        isLoading: false,
        // documentCheclistData: action.payload,
      };
    }
    case "IMPORT_VISASUBCLASS_CHECKLIST_FAILURE": {
      message.error(action.payload?.response?.data?.message);

      return {
        ...state,
        isLoading: false,
      };
    }

    case "ADD_DOCUMENT_CHECKLIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_DOCUMENT_CHECKLIST_SUCCESS": {
      message.success("Successfully added document checklist");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_DOCUMENT_CHECKLIST_FAILURE": {
      message.error(action.payload?.response?.data?.message);
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_DOCUMENT_CHECKLIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "UPDATE_DOCUMENT_CHECKLIST_SUCCESS": {
      message.success("Document updated successfully");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_DOCUMENT_CHECKLIST_FAILURE": {
      message.error(action.payload?.response?.data?.message);
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default DocumentCheckListReducer;
