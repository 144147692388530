import React, { useState } from "react";
import { Button, Form, Input, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;
const { TextArea } = Input;

const ServiceStatusUpdate = ({
  serviceData,
  isServiceStatusUpdateVisible,
  setIsServiceStatusUpdateVisible,
  fetchProductServiceById,
  fetchServiceActivityLog,
}) => {
  const dispatch = useDispatch();
  const handleUpdateServiceStatus = (values) => {
    dispatch({
      type: "UPDATE_SERVICE_STATUS_REQUEST",
      payload: {
        id: serviceData?.id,
        status: values?.serviceStatus,
      },
      isSuccessFn: true,
      successFunction: () => {
        setIsServiceStatusUpdateVisible(false);
        fetchProductServiceById();
        fetchServiceActivityLog();
      },
    });
  };
  return (
    <Modal
      title="Update Service Status"
      open={isServiceStatusUpdateVisible}
      onCancel={() => {
        setIsServiceStatusUpdateVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        initialValues={{ serviceStatus: serviceData?.status }}
        onFinish={handleUpdateServiceStatus}
        colon={true}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ gap: "0.3rem", width: "100%" }}
        >
          <Form.Item
            label="Service Status"
            name={"serviceStatus"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter your service status!",
              },
            ]}
          >
            <Select placeholder="Select Service Status">
              <Option value="PENDING">Pending</Option>
              <Option value="IN_PROGRESS">In Progress</Option>
              <Option value="COMPLETED">Completed</Option>
              <Option value="CANCELLED">Cancelled</Option>
              <Option value="HOLD">Hold</Option>{" "}
            </Select>
          </Form.Item>
        </div>

        <Button block type="primary" htmlType="submit">
          Update Service Status
        </Button>
      </Form>
    </Modal>
  );
};

export default ServiceStatusUpdate;
