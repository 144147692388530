import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

export default function EditDocumentChecklist({
  setDocumentActive,
  handleGetDocumentChecklist,
  documentActive,
  isEditModalVisible,
  setIsEditModalVisible,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const handleOk = () => {
    setIsEditModalVisible(false);
    form.resetFields();
    setDocumentActive("");
  };

  const handleCancel = () => {
    setIsEditModalVisible(false);
    form.resetFields();
    setDocumentActive("");
  };

  const handleEditDocumentChecklist = (values) => {
    dispatch({
      type: "UPDATE_DOCUMENT_CHECKLIST_REQUEST",
      payload: { id: documentActive?.id, name: values?.name },
      isSuccessFn: true,
      successFunction: successFunction,
    });
  };

  const successFunction = () => {
    form.resetFields();
    handleGetDocumentChecklist();
    setDocumentActive("");
    setIsEditModalVisible(false);
  };

  // localhost:8080/khrouch/v1/api/consultancy/employee/update-document-checkList/3

  http: return (
    <Modal
      title="Edit Document Checklist"
      open={isEditModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <Form
        form={form}
        initialValues={{
          name: documentActive?.name,
        }}
        layout="vertical"
        onFinish={handleEditDocumentChecklist}
      >
        <Form.Item
          name="name"
          label="Document Name"
          rules={[
            {
              required: true,
              message: "Document name is required",
            },
          ]}
        >
          <Input placeholder="Document name" />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Edit Document Checklist
        </Button>
      </Form>
    </Modal>
  );
}
