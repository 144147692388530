import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Select, Tooltip } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AddEmailTemplate from "./AddEmailTemplate";
const { Option } = Select;

const EmailTemplate = () => {
  const store = useSelector((state) => {
    return {
      emailTemplateState: state.OrganizationSettingsReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const dispatch = useDispatch();
  const [isAddEmailTemplateVisible, setIsAddEmailTemplateVisible] =
    useState(false);
  const [templateType, setTemplateType] = useState("");
  const [templateText, setTemplateText] = useState("");
  const [templateSubject, setTemplateSubject] = useState("");
  const successPayloadFn = (data) => {
    // setQuillValue(data?.data?.templateText);
  };
  useEffect(() => {
    dispatch({
      type: "FETCH_LEAD_EMAIL_TEMPLATE_REQUEST",
      payload: "LEAD",
      isSuccessFn: true,
      successPayload: successPayloadFn,
    });
    dispatch({
      type: "FETCH_CASE_EMAIL_TEMPLATE_REQUEST",
      payload: "CASE",
      successPayload: successPayloadFn,
    });
    dispatch({
      type: "FETCH_APPOINTMENT_EMAIL_TEMPLATE_REQUEST",
      payload: "APPOINTMENT",
      isSuccessFn: true,

      successPayload: successPayloadFn,
    });
    dispatch({
      type: "FETCH_CLIENT_EMAIL_TEMPLATE_REQUEST",
      payload: "CLIENT",
      isSuccessFn: true,
      successPayload: successPayloadFn,
    });

    dispatch({
      type: "FETCH_QUOTATION_EMAIL_TEMPLATE_REQUEST",
      payload: "QUOTATION",
      isSuccessFn: true,
      successPayload: successPayloadFn,
    });

    dispatch({
      type: "FETCH_SERVICE_EMAIL_TEMPLATE_REQUEST",
      payload: "SERVICE",
      isSuccessFn: true,
      successPayload: successPayloadFn,
    });
  }, [store.branchState?.branchRecord]);

  return (
    <Card bordered={false} loading={store.emailTemplateState?.isLoading}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        <Card
          style={{
            width: "30%",
            borderRadius: "0.5rem",
            marginTop: 16,
          }}
          title={
            store.emailTemplateState?.leadEmailTemplates?.templateType ?? "LEAD"
          }
          extra={
            <Tooltip title="Edit" placement="bottom">
              {store.profileState?.profile?.department?.rolesAndPermissionList?.TEMPLATE_SETTINGS?.includes(
                "UPDATE"
              ) && (
                <a>
                  <EditOutlined
                    key="edit"
                    onClick={() => {
                      setTemplateType("LEAD");
                      setTemplateText(
                        store.emailTemplateState?.leadEmailTemplates
                          ?.templateText
                      );
                      setTemplateSubject(
                        store.emailTemplateState?.leadEmailTemplates
                          ?.templateSubject
                      );
                      setIsAddEmailTemplateVisible(true);
                    }}
                  />
                </a>
              )}
            </Tooltip>
          }
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <div style={{ fontSize: "12px" }}>
              <strong>Subject:</strong>
              {store.emailTemplateState?.leadEmailTemplates?.templateSubject}
            </div>
            <div style={{ fontSize: "12px" }}>
              <strong>Body:</strong>
              {store.emailTemplateState?.leadEmailTemplates?.templateText?.replace(
                /(<([^>]+)>)/gi,
                ""
              )}
            </div>
          </div>
        </Card>
        <Card
          style={{
            width: "30%",
            borderRadius: "0.5rem",
            marginTop: 16,
          }}
          title={
            store.emailTemplateState?.caseEmailTemplates?.templateType ?? "CASE"
          }
          extra={
            <Tooltip title="Edit" placement="bottom">
              {store.profileState?.profile?.department?.rolesAndPermissionList?.TEMPLATE_SETTINGS?.includes(
                "UPDATE"
              ) && (
                <a>
                  <EditOutlined
                    key="edit"
                    onClick={() => {
                      setTemplateType("CASE");
                      setTemplateText(
                        store.emailTemplateState?.caseEmailTemplates
                          ?.templateText
                      );
                      setTemplateSubject(
                        store.emailTemplateState?.caseEmailTemplates
                          ?.templateSubject
                      );
                      setIsAddEmailTemplateVisible(true);
                    }}
                  />
                </a>
              )}
            </Tooltip>
          }
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <div style={{ fontSize: "12px" }}>
              <strong>Subject:</strong>
              {store.emailTemplateState?.caseEmailTemplates?.templateSubject}
            </div>
            <div style={{ fontSize: "12px" }}>
              <strong>Body:</strong>
              {store.emailTemplateState?.caseEmailTemplates?.templateText?.replace(
                /(<([^>]+)>)/gi,
                ""
              )}
            </div>
          </div>
        </Card>
        <Card
          style={{
            width: "30%",
            borderRadius: "0.5rem",
            marginTop: 16,
          }}
          title={
            store.emailTemplateState?.appointmentEmailTemplates?.templateType ??
            "APPOINTMENT"
          }
          extra={
            <Tooltip title="Edit" placement="bottom">
              {store.profileState?.profile?.department?.rolesAndPermissionList?.TEMPLATE_SETTINGS?.includes(
                "UPDATE"
              ) && (
                <a>
                  <EditOutlined
                    key="edit"
                    onClick={() => {
                      setTemplateType("APPOINTMENT");
                      setTemplateText(
                        store.emailTemplateState?.appointmentEmailTemplates
                          ?.templateText
                      );
                      setTemplateSubject(
                        store.emailTemplateState?.appointmentEmailTemplates
                          ?.templateSubject
                      );
                      setIsAddEmailTemplateVisible(true);
                    }}
                  />
                </a>
              )}
            </Tooltip>
          }
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <div style={{ fontSize: "12px" }}>
              <strong>Subject:</strong>
              {
                store.emailTemplateState?.appointmentEmailTemplates
                  ?.templateSubject
              }
            </div>
            <div style={{ fontSize: "12px" }}>
              <strong>Body:</strong>
              {store.emailTemplateState?.appointmentEmailTemplates?.templateText?.replace(
                /(<([^>]+)>)/gi,
                ""
              )}
            </div>
          </div>
        </Card>
        <Card
          style={{
            width: "30%",
            borderRadius: "0.5rem",
            marginTop: 16,
          }}
          title={
            store.emailTemplateState?.clientEmailTemplates?.templateType ??
            "CLIENT"
          }
          extra={
            <Tooltip title="Edit" placement="bottom">
              {store.profileState?.profile?.department?.rolesAndPermissionList?.TEMPLATE_SETTINGS?.includes(
                "UPDATE"
              ) && (
                <a>
                  <EditOutlined
                    key="edit"
                    onClick={() => {
                      setTemplateType("CLIENT");
                      setTemplateText(
                        store.emailTemplateState?.clientEmailTemplates
                          ?.templateText
                      );
                      setTemplateSubject(
                        store.emailTemplateState?.clientEmailTemplates
                          ?.templateSubject
                      );
                      setIsAddEmailTemplateVisible(true);
                    }}
                  />
                </a>
              )}
            </Tooltip>
          }
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <div style={{ fontSize: "12px" }}>
              <strong>Subject:</strong>
              {store.emailTemplateState?.clientEmailTemplates?.templateSubject}
            </div>
            <div style={{ fontSize: "12px" }}>
              <strong>Body:</strong>

              {store.emailTemplateState?.clientEmailTemplates?.templateText?.replace(
                /(<([^>]+)>)/gi,
                ""
              )}
            </div>
          </div>
        </Card>

        <Card
          style={{
            width: "30%",
            borderRadius: "0.5rem",
            marginTop: 16,
          }}
          title={
            store.emailTemplateState?.quotationEmailTemplates?.templateType ??
            "QUOTATION"
          }
          extra={
            <Tooltip title="Edit" placement="bottom">
              {store.profileState?.profile?.department?.rolesAndPermissionList?.TEMPLATE_SETTINGS?.includes(
                "UPDATE"
              ) && (
                <a>
                  <EditOutlined
                    key="edit"
                    onClick={() => {
                      setTemplateType("QUOTATION");
                      setTemplateText(
                        store.emailTemplateState?.quotationEmailTemplates
                          ?.templateText
                      );
                      setTemplateSubject(
                        store.emailTemplateState?.quotationEmailTemplates
                          ?.templateSubject
                      );
                      setIsAddEmailTemplateVisible(true);
                    }}
                  />
                </a>
              )}
            </Tooltip>
          }
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <div style={{ fontSize: "12px" }}>
              <strong>Subject:</strong>
              {
                store.emailTemplateState?.quotationEmailTemplates
                  ?.templateSubject
              }
            </div>
            <div style={{ fontSize: "12px" }}>
              <strong>Body:</strong>

              {store.emailTemplateState?.quotationEmailTemplates?.templateText?.replace(
                /(<([^>]+)>)/gi,
                ""
              )}
            </div>
          </div>
        </Card>

        <Card
          style={{
            width: "30%",
            borderRadius: "0.5rem",
            marginTop: 16,
          }}
          title={
            store.emailTemplateState?.serviceEmailTemplates?.templateType ??
            "SERVICE"
          }
          extra={
            <Tooltip title="Edit" placement="bottom">
              {store.profileState?.profile?.department?.rolesAndPermissionList?.TEMPLATE_SETTINGS?.includes(
                "UPDATE"
              ) && (
                <a>
                  <EditOutlined
                    key="edit"
                    onClick={() => {
                      setTemplateType("SERVICE");
                      setTemplateText(
                        store.emailTemplateState?.serviceEmailTemplates
                          ?.templateText
                      );
                      setTemplateSubject(
                        store.emailTemplateState?.serviceEmailTemplates
                          ?.templateSubject
                      );
                      setIsAddEmailTemplateVisible(true);
                    }}
                  />
                </a>
              )}
            </Tooltip>
          }
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <div style={{ fontSize: "12px" }}>
              <strong>Subject:</strong>{" "}
              {
                store.emailTemplateState?.quotationEmailTemplates
                  ?.templateSubject
              }
            </div>
            <div style={{ fontSize: "12px" }}>
              <strong>Body:</strong>{" "}
              {store.emailTemplateState?.serviceEmailTemplates?.templateText?.replace(
                /(<([^>]+)>)/gi,
                ""
              )}
            </div>
          </div>
        </Card>
      </div>
      {isAddEmailTemplateVisible && (
        <AddEmailTemplate
          templateSubject={templateSubject}
          emailTemplateType={templateType}
          templateText={templateText}
          isAddEmailTemplateModalVisible={isAddEmailTemplateVisible}
          setIsAddEmailTemplateModalVisible={setIsAddEmailTemplateVisible}
        />
      )}
    </Card>
  );
};

export default EmailTemplate;
