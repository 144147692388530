import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AddCasePackage from "../AddCasePackage";
import { scrollToTopFormError } from "../../../HelperFunction";

export default function UpdateClientPacakgeInfo({
  setCurrent,
  caseFormData,
  setCaseFormData,
}) {
  const { Option } = Select;
  const { TextArea } = Input;
  const [form] = Form?.useForm();
  const dispatch = useDispatch();
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
      documentChecklistState: state?.DocumentCheckListReducer,
    };
  });
  const [traverseStatus, setTraverseStatus] = useState("");
  const [isAddPackageModalVisible, setIsAddPackageModalVisible] =
    useState(false);

  useEffect(() => {
    handleGetDocumentChecklist();
    handleFetchActiveCasePackage();
  }, []);

  const handleFetchActiveCasePackage = () => {
    dispatch({
      type: "FETCH_ACTIVE_CASE_PACKAGE_REQUEST",
      payload: {
        branchId: localStorage.getItem("branchId"),
      },
    });
  };

  const handleGetDocumentChecklist = () => {
    dispatch({
      type: "FETCH_DOCUMENT_CHECKLIST_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  const handleSubmitPackageInfo = (values) => {
    delete values?.isAddPackage;

    setCaseFormData((prev) => {
      return {
        ...prev,
        ...values,
      };
    });
    traverseStatus === "next" && setCurrent((prev) => prev + 1);
    traverseStatus === "back" && setCurrent((prev) => prev - 1);
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmitPackageInfo}
        onFinishFailed={scrollToTopFormError}
        layout="vertical"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
          marginTop: "1.5rem",
        }}
        initialValues={{
          isAddPackage: caseFormData?.isAddPackage,
          casePackages: caseFormData?.casePackages,
          isDiscountAllowed: caseFormData?.isDiscountAllowed,
          discount: caseFormData?.discount,
          packageDiscountType: caseFormData?.packageDiscountType,
          currency: caseFormData?.currency,
          description: caseFormData?.description,
          // priorNote: caseFormData?.priorNote,
          // deadlineDate: caseFormData?.deadlineDate,
          // consultantId: caseFormData?.consultantId,
          // superAgentId: caseFormData?.superAgentId,
          // workflowDocumentChecklist: caseFormData?.workflowDocumentChecklist,
          // coursesDocumentChecklist: caseFormData?.coursesDocumentChecklist,
        }}
      >
        <Form.Item name="isAddPackage">
          <Checkbox
            defaultChecked={caseFormData?.isAddPackage}
            onChange={(e) => {
              setCaseFormData((prev) => {
                return {
                  ...prev,
                  isAddPackage: e.target.checked,
                };
              });
            }}
          >
            Add Package
          </Checkbox>
        </Form.Item>
        {caseFormData?.isAddPackage && (
          <Form.List name="casePackages" style={{ marign: "1rem" }}>
            {(fields, { add, remove }) => (
              <div style={{ gridColumn: "span 2", background: "#f9f9f9" }}>
                {fields.map(({ key, name, ...restField }, index) => (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      gap: "1rem",
                      position: "relative",
                      padding: "1rem 3rem 0 1rem",
                    }}
                    key={key}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "packageName"]}
                        label="Package"
                        style={{ width: "100%" }}
                      >
                        <Select
                          placeholder="Select Package"
                          onChange={(value, option) => {
                            const dataObj = JSON.parse(option?.key);
                            const data = form.getFieldValue("casePackages");
                            const tempObj = {
                              packageName: dataObj?.packageName,
                              unitPrice: dataObj?.unitPrice,
                              quantity: 1,
                              totalPrice: dataObj?.unitPrice,
                            };
                            data.splice(key, 1, tempObj);
                            form.setFieldValue("casePackages", data);
                          }}
                        >
                          {store.caseState.activeCasePackage?.data?.map(
                            (dataObj) => {
                              return (
                                <Option
                                  key={JSON.stringify(dataObj)}
                                  value={dataObj?.packageName}
                                >
                                  {dataObj.packageName}
                                  {dataObj.packageType === "IN_HOUSE" && (
                                    <Tag
                                      color="#2db7f5"
                                      style={{
                                        borderRadius: "10px",
                                        margin: "3px",
                                      }}
                                    >
                                      IH
                                    </Tag>
                                  )}
                                  {dataObj.packageType === "INSTITUTE" && (
                                    <Tag
                                      color="#2db7f5"
                                      style={{
                                        borderRadius: "10px",
                                        margin: "3px",
                                      }}
                                    >
                                      IN
                                    </Tag>
                                  )}
                                  {dataObj.packageType === "COMPANY" && (
                                    <Tag
                                      color="#2db7f5"
                                      style={{
                                        borderRadius: "10px",
                                        margin: "3px",
                                      }}
                                    >
                                      CO
                                    </Tag>
                                  )}
                                </Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                      <a
                        className="plusButton"
                        onClick={() => {
                          setIsAddPackageModalVisible(true);
                        }}
                      >
                        <AiOutlinePlus className="iconColor" />
                      </a>
                    </div>
                    <Form.Item
                      {...restField}
                      name={[name, "quantity"]}
                      label="Quantity"
                      onChange={(e) => {
                        const data = form.getFieldValue("casePackages");
                        const tempObj = data[key];
                        tempObj.totalPrice = e.target.value * tempObj.unitPrice;
                        data.splice(key, 1, tempObj);
                        form.setFieldValue("casePackages", data);
                      }}
                    >
                      <Input type="number" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "unitPrice"]}
                      label="Unit Price"
                      onChange={(e) => {
                        const data = form.getFieldValue("casePackages");
                        const tempObj = data[key];
                        tempObj.totalPrice = e.target.value * tempObj.quantity;
                        data.splice(key, 1, tempObj);
                        form.setFieldValue("casePackages", data);
                      }}
                    >
                      <Input type="number" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "totalPrice"]}
                      label="Price"
                    >
                      <Input readOnly type="number" />
                    </Form.Item>
                    <Button
                      type="danger"
                      onClick={() => {
                        remove(name);
                      }}
                      style={{
                        padding: "0",
                        borderRadius: "50%",
                        height: "1.5rem",
                        width: "1.5rem",
                        position: "absolute",
                        top: "0.5rem",
                        right: "0.5rem",
                      }}
                    >
                      <CloseOutlined />
                    </Button>
                  </div>
                ))}
                <Button block type="dashed" onClick={() => add()}>
                  <PlusOutlined />
                  Add Package
                </Button>
              </div>
            )}
          </Form.List>
        )}
        {caseFormData?.isAddPackage && (
          <div
            style={{
              gridColumn: "span 2",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <Form.Item name={"isDiscountAllowed"} valuePropName="checked">
              <Checkbox
                defaultChecked={caseFormData?.isDiscountAllowed}
                onChange={(e) => {
                  setCaseFormData((prev) => {
                    return {
                      ...prev,
                      isDiscountAllowed: e.target?.checked,
                    };
                  });
                }}
              >
                is Discount Allowed?
              </Checkbox>
            </Form.Item>

            {caseFormData?.isDiscountAllowed && (
              <>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    gap: "0.5rem",
                  }}
                >
                  <Form.Item label="Discount amount" name="discount">
                    <Input type="number" placeholder="Discount" />
                  </Form.Item>
                  <Form.Item label={"Type"} name="packageDiscountType">
                    <Radio.Group>
                      <Radio value={"FLAT"}>is Flat</Radio>
                      <Radio value={"PERCENTAGE"}>is Percentage</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </>
            )}
          </div>
        )}
        <Form.Item
          label="Currency"
          name="currency"
          style={{ gridColumn: "span 2" }}
        >
          <Select placeholder="Select Currency">
            <Option
              value={store.leadState.leadsDropdown?.defaultSelectedCurrency}
            >
              {store.leadState.leadsDropdown?.defaultSelectedCurrency}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          style={{ gridColumn: "span 2" }}
          label="Description"
          name="description"
        >
          <TextArea rows={5} placeholder="Write something..." />
        </Form.Item>
        {/* <Form.Item label="Prior Note" name="priorNote">
          <TextArea placeholder="Write something..." />
        </Form.Item>

        <Form.Item
          label="Deadline"
          name="deadlineDate"
          style={{ gridColumn: "span 2" }}
        >
          <DatePicker format={"DD-MM-YYYY"} />
        </Form.Item>

        <Form.Item name="consultantId" label="Assign Consultants">
          <Checkbox.Group
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "0.5rem",
            }}
          >
            {store.caseState?.caseDropdown?.consultant?.map((dataObj) => {
              return (
                <Checkbox
                  className="checkboxEllipsis"
                  key={dataObj.id}
                  value={dataObj.id}
                  style={{
                    width: "100%",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  <Tooltip title={dataObj.emailId}>{dataObj.emailId}</Tooltip>
                </Checkbox>
              );
            })}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item label="Assign Super Agent" name="superAgentId">
          <Checkbox.Group
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "0.5rem",
            }}
          >
            {store.caseState?.caseDropdown?.headAgent?.map((dataObj) => {
              return (
                <Checkbox key={dataObj.id} value={dataObj.id}>
                  {dataObj.name}
                </Checkbox>
              );
            })}
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          label="Workflow Document Checklist"
          name="workflowDocumentChecklist"
        >
          <Checkbox.Group
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            {store?.documentChecklistState?.documentCheclistData?.data?.map(
              (dataObj) => {
                return (
                  <Checkbox key={dataObj.id} value={dataObj.id}>
                    {dataObj.name}
                  </Checkbox>
                );
              }
            )}
          </Checkbox.Group>
        </Form.Item>

        {caseFormData?.visaType === "EDUCATION" && (
          <Form.Item
            label={`Courses Document Checklist ${
              caseFormData?.currentStatus ? "(Offshore)" : "(Onshore)"
            }`}
            name="coursesDocumentChecklist"
          >
            {caseFormData.currentStatus ? (
              <Checkbox.Group
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                {store?.documentChecklistState?.documentCheclistData?.data?.map(
                  (dataObj) => {
                    return (
                      <Checkbox
                        key={dataObj.id}
                        value={dataObj.id}
                        style={{ margin: 0 }}
                      >
                        {dataObj.name}
                      </Checkbox>
                    );
                  }
                )}
              </Checkbox.Group>
            ) : (
              <Checkbox.Group
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                {store?.documentChecklistState?.documentCheclistData?.data?.map(
                  (dataObj) => {
                    return (
                      <Checkbox
                        key={dataObj.id}
                        value={dataObj.id}
                        style={{ margin: 0 }}
                      >
                        {dataObj.name}
                      </Checkbox>
                    );
                  }
                )}
              </Checkbox.Group>
            )}
          </Form.Item>
        )} */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gridColumn: "span 2",
          }}
        >
          <Button
            type="outline"
            onClick={() => {
              // setCurrent((prev) => prev - 1);
              setTraverseStatus("back");
            }}
            htmlType="submit"
          >
            Back
          </Button>
          <Button
            type="primary"
            onClick={() => {
              // setCurrent((prev) => prev + 1);
              setTraverseStatus("next");
            }}
            htmlType="submit"
          >
            Next
          </Button>
        </div>
      </Form>
      {isAddPackageModalVisible && (
        <AddCasePackage
          from={"CASE"}
          isAddPackageModalVisible={isAddPackageModalVisible}
          setIsAddPackageModalVisible={setIsAddPackageModalVisible}
        />
      )}
    </div>
  );
}
