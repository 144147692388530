import { Button, Card, Form, Input, List, Tag, Tooltip } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FiSettings } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import OrganizationalSettings from "../OrganizationalSettings";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const LeadSetttings = () => {
  const store = useSelector((state) => {
    return {
      leadSettingsState: state.OrganizationSettingsReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
    dispatch({
      type: "FETCH_ALL_LEAD_STATUSES_REQUEST",
      // isSuccessFn: true,
      // successFunction: successFunction,
    });
  }, [store.branchState?.branchRecord]);
  const successFunction = (response) => {
    setLeadSettingsData((previousData) => {
      return {
        ...previousData,
        leadPrefix: response?.data?.prefix?.leadPrefix,
        leadReminder: response?.data?.expiryDates?.leadReminderDateAfter,
        leadAdditionalEmails: response?.data?.leadAdditionalEmails,
      };
    });
  };
  const [leadSettingsData, setLeadSettingsData] = useState({
    leadPrefix: "",
    leadReminder: "",
    leadAdditionalEmails: [],
  });

  return (
    <OrganizationalSettings>
      <div>
        <h3>Lead Settings</h3>
        <Card>
          <Form
            labelCol={{ span: 3 }}
            fields={[
              {
                name: ["leadPrefix"],
                value: leadSettingsData.leadPrefix,
              },
              {
                name: ["leadReminder"],
                value: leadSettingsData.leadReminder,
              },
            ]}
          >
            <Form.Item name={"leadPrefix"} label="Lead Prefix">
              <Input
                placeholder="Enter the prefix you want to use"
                value={leadSettingsData.leadPrefix}
                onChange={(e) => {
                  setLeadSettingsData((previousData) => {
                    return {
                      ...previousData,
                      leadPrefix: e.target.value,
                    };
                  });
                  // setLeadPrefix(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item name={"leadReminder"} label="Lead Reminder">
              <Input
                type="number"
                value={leadSettingsData.leadReminder}
                //   placeholder="reminder days after today"
                onChange={(e) => {
                  setLeadSettingsData((previousData) => {
                    return {
                      ...previousData,
                      leadReminder: e.target.value,
                    };
                  });
                }}
                style={{ width: "4rem" }}
              />{" "}
              days after.
            </Form.Item>
            {/* <Form.Item name={"replyEmail"} label="Reply Email">
              <Input
                value={leadSettingsData.leadReminder}
                onChange={(e) => {
                  setLeadSettingsData((previousData) => {
                    return {
                      ...previousData,
                      replyEmail: e.target.value,
                    };
                  });
                }}
                style={{ width: "4rem" }}
              />{" "}
            </Form.Item> */}
          </Form>
          <List
            header={<h3>Lead Status</h3>}
            bordered
            style={{ borderRadius: "0.4rem", margin: "0 0 1rem 0" }}
            dataSource={store.leadSettingsState?.leadStatuses?.data}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                actions={[
                  <Tooltip title="Set as Default">
                    {!item.isDefault ? (
                      <a key="list-loadmore-edit">
                        {store.profileState?.profile?.department?.rolesAndPermissionList?.LEAD_SETTINGS?.includes(
                          "UPDATE"
                        ) && (
                          <div
                            className="bordered"
                            onClick={() => {
                              dispatch({
                                type: "SET_LEAD_STATUS_AS_DEFAULT_REQUEST",
                                payload: {
                                  leadStatusId: item.id,
                                  branchId:
                                    store.branchState?.branchRecord.id ??
                                    localStorage.getItem("branchId"),
                                },
                              });
                            }}
                          >
                            <FiSettings style={{ fontSize: "18px" }} />
                          </div>
                        )}
                      </a>
                    ) : (
                      ""
                    )}
                  </Tooltip>,
                ]}
              >
                <List.Item.Meta
                  title={
                    <div>
                      {item.statusName}{" "}
                      {item.isDefault ? (
                        <p
                          className="greenTag"
                          style={{
                            borderRadius: "20px",
                            fontSize: "12px",
                            paddingTop: "1px",
                            marginLeft: "1rem",
                          }}
                        >
                          Default
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  }
                />
                <Tag color={item?.statusColorCode ?? "blue"}>
                  {item.statusName}
                </Tag>
              </List.Item>
            )}
          />
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              console.log("valuesGS", values);
              // const filteredEmail = values?.users?.map((dataObj) => {
              //   const emails = caseSettingsData?.additionalEmails.filter(
              //     (item) => {
              //       console.log("dataObj.email", dataObj.email);
              //       console.log("item.email", item.email);
              //       if (dataObj.email !== item.email) {
              //         return dataObj.email;
              //       }
              //     }
              //   );
              //   console.log("filtered emails case", emails);
              // });
              // console.log("filteredEmail", filteredEmail);
              // const existingEails = leadSettingsData?.leadAdditionalEmails.map(
              //   (emailObj) => emailObj.email
              // );
              // const formEmail = values?.users?.filter((item) => {
              //   if (!existingEails.includes(item.email)) {
              //     return {
              //       email: item.email,
              //       isLeads: true,
              //       isCase: false,
              //       isAppointment: false,
              //       isCheckIn: false,
              //     };
              //   } else {
              //     return false;
              //   }
              // });
              // console.log("existing email", existingEails);
              const formEmail = values?.users?.map((item) => {
                return {
                  email: item.email,
                  isLeads: true,
                  isCase: false,
                  isAppointment: false,
                  isCheckIn: false,
                };
              });
              console.log("formEmail email", formEmail);
              dispatch({
                type: "ADDITIONAL_EMAILS_FROM_GENERAL_SETTINGS_REQUEST",
                payload: { additionalEmails: [...formEmail] },
              });
            }}
            style={{
              maxWidth: "100%",
            }}
            autoComplete="off"
            fields={leadSettingsData.leadAdditionalEmails?.map(
              (item, index) => {
                return { name: ["users", index, "email"], value: item.email };
              }
            )}

            // fields={[
            //   {
            //     name: ["users", 0, "email"],
            //     value: "anksdfjaksj@saklfd.com",
            //   },
            // ]}
          >
            <div
              style={{
                backgroundColor: "#eeeeee55",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                marginBottom: "0.4rem",
              }}
            >
              <h4>Additional Emails:</h4>
              <Form.List
                fields={[
                  {
                    name: "email",
                    value: "anksdfjaksj@saklfd.com",
                  },
                ]}
                name="users"
                initialValue={leadSettingsData.additionalEmails?.map(
                  (item, index) => {
                    return {
                      fieldKey: index,
                      isListField: true,
                      key: index,
                    };
                  }
                )}
              >
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                            width: "100%",
                            alignItems: "center",
                            gap: "0.3rem",
                          }}
                        >
                          <Form.Item
                            label="Additional Email"
                            labelCol={{ span: 4 }}
                            {...restField}
                            name={[name, "email"]}
                            style={{
                              width: "100%",
                              margin: 0,
                              padding: "0",
                            }}
                            rules={[
                              {
                                type: "email",
                                message: "Please enter a valid email",
                              },
                            ]}
                          >
                            <Input
                              type="email"
                              placeholder="e.g. username@gmail.com"
                            />
                          </Form.Item>

                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Additional Email
                        </Button>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
            </div>

            {store.profileState?.profile?.department?.rolesAndPermissionList?.LEAD_SETTINGS?.includes(
              "UPDATE"
            ) && (
              <Form.Item
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    dispatch({
                      type: "UPDATE_PREFIX_REQUEST",
                      payload: {
                        branchId:
                          store.branchState?.branchRecord.id ??
                          localStorage.getItem("branchId"),
                        leadPrefix: leadSettingsData?.leadPrefix,
                      },
                    });
                    dispatch({
                      type: "UPDATE_EXPIRY_REMINDER_WORKING_HOUR_REQUEST",
                      payload: {
                        branchId:
                          store.branchState?.branchRecord.id ??
                          localStorage.getItem("branchId"),
                        leadReminderDateAfter: leadSettingsData.leadReminder,
                      },
                    });
                  }}
                >
                  Save Changes
                </Button>
              </Form.Item>
            )}
          </Form>
        </Card>
      </div>
    </OrganizationalSettings>
  );
};

export default LeadSetttings;
