import React, { useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

const AssignRoles = ({
  record,
  isAssignRolesModalVisible,
  setIsAssignRolesModalVisible,
}) => {
  const assgnRolesState = useSelector(
    (state) => state.RoleAndPermissionReducer
  );
  useEffect(() => {
    if (isAssignRolesModalVisible) {
      dispatch({ type: "FETCH_ROLES_REQUEST" });
    }
  }, [isAssignRolesModalVisible]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [roles, setRoles] = useState();
  const assignRolesSubmitHandler = (value) => {
    const formData = {
      ...value,
    };
    dispatch({
      type: "ASSIGN_ROLES_REQUEST",
      payload: { id: record.id, values: { roleId: roles } },
    });
    form.resetFields();
    setIsAssignRolesModalVisible(false);
  };
  const onAssignRoleChange = (e) => {
    setRoles(e);
  };
  return (
    <Modal
      title="Assign Roles"
      open={isAssignRolesModalVisible}
      onCancel={() => {
        setIsAssignRolesModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={assignRolesSubmitHandler}
        colon={true}
        form={form}
      >
        <div className="flexColumnwithoutStyle">
          <Checkbox.Group
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "0.4rem",
              marginBottom: "1rem",
            }}
            onChange={onAssignRoleChange}
          >
            {assgnRolesState.roles?.data?.map((dataObj) => {
              return (
                <Checkbox
                  className="checkboxEllipsis"
                  key={dataObj.id}
                  value={dataObj.id}
                  style={{ width: "30%", margin: 0 }}
                >
                  {dataObj.roleName}
                </Checkbox>
              );
            })}
          </Checkbox.Group>
        </div>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Assign Roles
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignRoles;
