import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import PackageTableHeader from "./PDFtable/PackageTableHeader";
import PackageTableRow from "./PDFtable/PackageTableRow";
import PackageTableBlankSpace from "./PDFtable/PackageTableBlankSpace";
import PackageTableFooter from "./PDFtable/PackageTableFooter";
import PackageTableFooterStatic from "./PDFtable/PackageTableFooterStatic";

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
});

const PdfTable = ({ record, packages }) => {
  return (
    <View style={styles.tableContainer}>
      <PackageTableHeader />
      <PackageTableRow packages={packages} />
      {/* <PackageTableBlankSpace rowsCount={packages?.length} /> */}
      <PackageTableFooter
        name="Regular Price"
        record={record}
        packages={packages}
      />
      <PackageTableFooterStatic name={`Discount`} value={record} />
      <PackageTableFooter
        name="Sub Total"
        record={record}
        packages={packages}
      />
      <PackageTableFooterStatic name={`GST/TAX`} value={record} />
      <PackageTableFooter
        name="Total Price"
        record={record}
        packages={packages}
      />
    </View>
  );
};

export default PdfTable;
