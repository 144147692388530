import React, { useState, useEffect } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

const UpdateDocumentCheckList = ({
  record,
  documentRecord,
  isOnshore,
  isUpdateDocumentChecklistModalVisible,
  setIsUpdateDocumentChecklistModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const store = useSelector((state) => {
    return { documentChecklistState: state?.DocumentCheckListReducer };
  });

  useEffect(() => {
    handleGetDocumentChecklist();
  }, []);

  const handleGetDocumentChecklist = () => {
    dispatch({
      type: "FETCH_DOCUMENT_CHECKLIST_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  const updateDocumentCheckListSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
      documentType: value.item,
    };
    const type = isOnshore === true ? "onshore" : "offshore";

    dispatch({
      type: "UPDATE_COURSES_DOCUMENT_CHECKLIST_REQUEST",
      payload: {
        courseIdtoFetch: record.id,
        id: documentRecord.id,
        type,
        formData: { ...formData, isOnshore },
      },
    });
    form.resetFields();
    setIsUpdateDocumentChecklistModalVisible(false);
  };
  return (
    <Modal
      title="Update Document Checklist"
      open={isUpdateDocumentChecklistModalVisible}
      onCancel={() => {
        setIsUpdateDocumentChecklistModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateDocumentCheckListSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["item"],
            value: documentRecord.item,
          },
        ]}
      >
        <Form.Item
          label="Document Name"
          name={"item"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a item!",
            },
          ]}
        >
          <Select showSearch placeholder="Select Document">
            {store?.documentChecklistState?.documentCheclistData?.data?.map(
              (item) => (
                <Select.Option value={item?.name}>{item?.name}</Select.Option>
              )
            )}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Document Checklist
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateDocumentCheckList;
