import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Tooltip,
} from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { FiSettings } from "react-icons/fi";
import { IoMdCheckboxOutline } from "react-icons/io";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import AddEducationWorkflow from "./AddEducationWorkflow";
import UpdateMigrationWorkflow from "./UpdateMigrationWorkflow";
import AddMigrationDocumentList from "./AddMigrationDocumentList";
import ManageEducationWorkflowStaus from "./ManageEducationWorkflowStatus";
import { AiOutlinePlus } from "react-icons/ai";
import AddDocuments from "./AddDocuments";
const { Option } = Select;

const ManageEducationWorkflow = ({
  countryRecord,
  isManageEducationWorkflowModalVisible,
  setIsManageEducationWorkflowModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      manageEducationflowState: state.SettingsReducer,
      profileState: state.ProfileReducer,
      addDocumentChecklistState: state.SettingsReducer,
    };
  });

  const [isAddDocumentsModalVisible, setIsAddDocumentsModalVisible] =
    useState(false);
  const [
    isAddEducationWorkflModalVisible,
    setIsAddEducationWorkflModalVisible,
  ] = useState(false);
  const [
    isAddEducationDocumentListModalVisible,
    setIsAddEducationDocumentListModalVisible,
  ] = useState(false);
  const [
    isUpdateEducationWorflowModalVisible,
    setIsUpdateEducationWorflowModalVisible,
  ] = useState(false);
  const [
    isManageEducationWorkflowStatusModalVisible,
    setIsManageEducationWorkflowStatusModalVisible,
  ] = useState(false);
  const [workflowRecord, setWorkflowRecord] = useState({});
  // useEffect(() => {
  //   if (
  //     isManageEducationWorkflowModalVisible === true ||
  //     isAddEducationWorkflModalVisible === false
  //   ) {
  //     fetchEducationWorkflow();
  //   }
  // }, [isManageEducationWorkflowModalVisible, isAddEducationWorkflModalVisible]);
  useEffect(() => {
    fetchEducationWorkflow();
  }, []);

  const fetchEducationWorkflow = () => {
    dispatch({
      type: "FETCH_EDUCATION_WORKFLOW_REQUEST",
      payload: { id: countryRecord.id },
      isSuccessFn: true,
      successFunction: successFunction,
    });
  };

  const successFunction = (res) => {
    setWorkflowRecord(res?.data?.data[0]);
  };


  const dispatch = useDispatch();

  return (
    <Drawer
      title="Education Workflow"
      open={isManageEducationWorkflowModalVisible}
      onClose={() => {
        setIsManageEducationWorkflowModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          {store.profileState?.profile?.department?.rolesAndPermissionList?.WORKFLOW_SETTINGS?.includes(
            "ADD"
          ) && (
            <button
              className="button"
              onClick={() => {
                setIsAddEducationWorkflModalVisible(true);
              }}
            >
              <span>Add Workflow</span>
            </button>
          )}
        </div>
        <div style={{ marginTop: "4rem" }}>
          {store?.manageEducationflowState.educationWorkflow?.data?.map(
            (dataObj, index) => {
              return (
                <div
                  key={dataObj.id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: "20rem",
                    padding: "2px 4px",
                    margin: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "1rem",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setWorkflowRecord(dataObj);

                      setIsManageEducationWorkflowStatusModalVisible(true);
                    }}
                  >
                    <Avatar
                      style={{
                        backgroundColor: "blue",
                        verticalAlign: "middle",
                      }}
                      size="large"
                    >
                      {index + 1}
                    </Avatar>
                    <h3>
                      <Tooltip title="Click to add workflow status">
                        {dataObj.statusName}{" "}
                        {dataObj.isDefault ? (
                          <p
                            className="greenTag"
                            style={{
                              borderRadius: "20px",
                              fontSize: "12px",
                              paddingTop: "1px",
                              marginLeft: "1rem",
                            }}
                          >
                            Default
                          </p>
                        ) : (
                          ""
                        )}
                      </Tooltip>
                    </h3>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      marginRight: "1.5rem",
                    }}
                  >
                    {store.profileState?.profile?.department?.rolesAndPermissionList?.WORKFLOW_SETTINGS?.includes(
                      "ADD_UPDATE_WORKFLOW_STATUS"
                    ) && (
                      <Tooltip title="Add workflow status">
                        <a>
                          <div
                            className="bordered"
                            onClick={() => {
                              setWorkflowRecord(dataObj);

                              setIsManageEducationWorkflowStatusModalVisible(
                                true
                              );
                            }}
                          >
                            <AiOutlinePlus />
                          </div>
                        </a>
                      </Tooltip>
                    )}
                    {store.profileState?.profile?.department?.rolesAndPermissionList?.WORKFLOW_SETTINGS?.includes(
                      "UPDATE"
                    ) && (
                      <Tooltip title="Update">
                        <a>
                          <div
                            className="bordered"
                            onClick={() => {
                              setWorkflowRecord(dataObj);
                              setIsUpdateEducationWorflowModalVisible(true);
                            }}
                          >
                            <EditOutlined style={{ fontSize: "18px" }} />
                          </div>
                        </a>
                      </Tooltip>
                    )}

                    {store.profileState?.profile?.department?.rolesAndPermissionList?.WORKFLOW_SETTINGS?.includes(
                      "DELETE"
                    ) && (
                      <Tooltip title="Delete">
                        <Popconfirm
                          title="Are you sure to delete this workflow?"
                          onConfirm={() => {
                            dispatch({
                              type: "DELETE_MIGRATION_WORKFLOW_REQUEST",
                              payload: {
                                id: dataObj.id,
                                countryId: countryRecord.id,
                              },
                            });
                          }}
                          onCancel={() => {}}
                          okText="Yes"
                          cancelText="No"
                        >
                          <a>
                            <div className="bordered">
                              <DeleteOutlined style={{ fontSize: "18px" }} />
                            </div>
                          </a>{" "}
                        </Popconfirm>
                      </Tooltip>
                    )}
                    {store.profileState?.profile?.department?.rolesAndPermissionList?.WORKFLOW_SETTINGS?.includes(
                      "CHANGE_STATUS"
                    ) && (
                      <Tooltip title="Set as Default">
                        <a>
                          <div
                            className="bordered"
                            onClick={() => {
                              dispatch({
                                type: "SET_EDUCATION_WORKFLOW_AS_DEFAULT_REQUEST",
                                payload: {
                                  countryId: countryRecord.id,
                                  workflowId: dataObj.id,
                                },
                              });
                            }}
                          >
                            <FiSettings style={{ fontSize: "18px" }} />
                          </div>
                        </a>
                      </Tooltip>
                    )}
                    <Tooltip title="Manage Document Checklist">
                      <a>
                        <div
                          className="bordered"
                          onClick={() => {
                            setWorkflowRecord(dataObj);
                            // setIsAddEducationDocumentListModalVisible(true);
                            dispatch({
                              type: "FETCH_DOCUMENTS_REQUEST",
                              payload: { id: dataObj.id },
                              isSuccessFn: true,
                              successFunction: () => {},
                            });
                            setIsAddDocumentsModalVisible(true);
                          }}
                        >
                          <IoMdCheckboxOutline style={{ fontSize: "18px" }} />
                        </div>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>

      {isAddDocumentsModalVisible && (
        <AddDocuments
          document={workflowRecord?.documentCheckList?.map(
            (data) => data?.item
          )}
          fetchEducationWorkflow={fetchEducationWorkflow}
          workflowRecord={workflowRecord}
          isAddDocumentsModalVisible={isAddDocumentsModalVisible}
          setIsAddDocumentsModalVisible={setIsAddDocumentsModalVisible}
        />
      )}
      {isAddEducationWorkflModalVisible && (
        <AddEducationWorkflow
          countryRecord={countryRecord}
          isAddEducationWorkflowModalVisible={isAddEducationWorkflModalVisible}
          setIsAddEducationWorkflowModalVisible={
            setIsAddEducationWorkflModalVisible
          }
        />
      )}
      {isManageEducationWorkflowStatusModalVisible && (
        <ManageEducationWorkflowStaus
          workflowRecord={workflowRecord}
          isManageEducationWorkflowStatusModalVisible={
            isManageEducationWorkflowStatusModalVisible
          }
          setIsManageEducationWorkflowStatusModalVisible={
            setIsManageEducationWorkflowStatusModalVisible
          }
        />
      )}
      {isUpdateEducationWorflowModalVisible && (
        <UpdateMigrationWorkflow
          workflowRecord={workflowRecord}
          countryRecord={countryRecord}
          isUpdateMigrationWorkflowModalVisible={
            isUpdateEducationWorflowModalVisible
          }
          setIsUpdateMigrationWorkflowModalVisible={
            setIsUpdateEducationWorflowModalVisible
          }
        />
      )}
      {isAddEducationDocumentListModalVisible && (
        <AddMigrationDocumentList
          workflowRecord={workflowRecord}
          isAddMigrationDocumentListModalVisible={
            isAddEducationDocumentListModalVisible
          }
          setIsAddMigrationDocumentListModalVisible={
            setIsAddEducationDocumentListModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default ManageEducationWorkflow;
