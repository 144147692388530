import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  branches: {},
  activeBranches: {},
  isHeadBranch: true,
  branchRecord: {},
};

const BranchReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "BRANCH_SELECTED_REQUEST": {
      return {
        ...state,
        isHeadBranch: action.payload.isHeadBranch,
        branchRecord: action.payload.branchRecord,
      };
    }
    case "FETCH_BRANCH_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_BRANCH_REQUEST_SUCCESS": {
      return {
        ...state,
        branches: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_BRANCH_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_BRANCH_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_BRANCH_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Branch added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_BRANCH_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add Branch.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_BRANCH_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_BRANCH_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Branch updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_BRANCH_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update Branch.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_BRANCH_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_BRANCH_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Branch deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_BRANCH_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete Branch.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_BRANCH_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_BRANCH_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Branch status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_BRANCH_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change Branch status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_BRANCH_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_ACTIVE_BRANCH_REQUEST_SUCCESS": {
      return {
        ...state,
        activeBranches: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_BRANCH_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load active branches.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_BRANCH_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_BRANCH_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Branch status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_BRANCH_STATUS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to change brach status.");

      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default BranchReducer;
