import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Tag,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import client from "./../../Assets/client.png";

import { FcDocument } from "react-icons/fc";
import { GoLocation } from "react-icons/go";
import {
  formattedDate,
  formattedDateTime,
  fourTagResponses,
  threeTagResponses,
  twoTagResponses,
} from "../../HelperFunction";
import { useSelector } from "react-redux";
import QuotationCard from "./QuotationCard";
import { BsChatLeftText } from "react-icons/bs";
import SendComposedMail from "../Mailbox/SendComposedMail";
import AddVisaCases from "../Visas/AddVisas";
import StatusHistory from "../Leads/StatusHistory";
import ClientDetails from "../Clients/ClientDetails";
import PDFFile from "./PDF/PDFFile";
import UpdateQuotationiStatus from "./UpdateQuotationStatus";
import EmailLogDetail from "../../Components/EmailLog/EmailLogDetail";
const { Option } = Select;

const QuotationDetails = ({
  record,
  isQuotationDetailsModelVisible,
  setIsQuotationDetailsModelVisible,
}) => {
  const store = useSelector((state) => {
    return {
      quoteState: state.QuotationReducer,
      leadState: state.LeadReducer,
      profileState: state.ProfileReducer,
      clientState: state.ClientsReducer,
    };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    fetchQuotationById();
  }, []);

  const fetchQuotationById = () => {
    dispatch({
      type: "FETCH_QUOTAtION_BY_ID_REQUEST",
      payload: {
        quoteId: record?.id,
      },
    });
  };
  const [form] = Form.useForm();
  // const [current, setCurrent] = useState("quotationDetails");
  const [current, setCurrent] = useState("quotation");

  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);
  const [isClientDetailsModelVisible, setIsClientDetailsModelVisible] =
    useState(false);
  const [isAddClientModalVisible, setIsAddClientModalVisible] = useState(false);
  const [isStatusHistoryModelVisible, setIsStatusHistoryModelVisible] =
    useState(false);
  const [caseAppointmentLeadData, setCaseAppointmentLeadData] = useState({
    leadPrefix: "",
    leadReminder: "",
    appointmentPerfix: "",
    appointmentReminder: "",
    casePerfix: "",
  });
  const successFunction = (response) => {
    setCaseAppointmentLeadData((previousData) => {
      return {
        ...previousData,
        leadPrefix: response?.data?.prefix?.leadPrefix,
        leadReminder: response?.data?.expiryDates?.leadReminderDateAfter,
        appointmentPerfix: response?.data?.prefix?.appointmentPrefix,
        appointmentReminder:
          response?.data?.expiryDates?.appointmentReminderDateAfter,
        casePerfix: response?.data?.prefix?.casePrefix,
        quoteExpiryDateAfter: response?.data?.expiryDates?.quoteExpiryDateAfter,
      };
    });
  };

  useEffect(() => {
    dispatch({
      type: "FETCH_CLIENT_BY_ID_REQUEST",
      payload: record?.customerId?.id,
    });

    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
  }, []);

  const clientDetailsTabList = [
    // {
    //   key: "quotationDetails",
    //   tab: "Quotation Details",
    // },
    {
      key: "quotation",
      tab: "Quotation ",
    },
    {
      key: "packages",
      tab: "Packages",
    },
    {
      key: "emailLog",
      tab: "Email Log",
    },
  ];
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };

  const fetchEmailLogDetails = () => {
    dispatch({
      type: "FETCH_EMAIL_LOG_DETAILS",
      payload: {
        id: record?.id,
        forWhat: "QUOTATION",
      },
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  useEffect(() => {
    fetchEmailLogDetails();
  }, []);

  return (
    <Drawer
      title="Quote Details"
      open={isQuotationDetailsModelVisible}
      onClose={() => {
        setIsQuotationDetailsModelVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card
        loading={store?.quoteState?.isLoading}
        style={{ borderRadius: "0.5rem" }}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ gap: "1rem", alignItems: "flex-start" }}
        >
          {store?.quoteState?.quotationById?.customerId?.photo ? (
            <Image
              src={store?.quoteState?.quotationById?.customerId?.photo}
              className={"clientImage"}
            />
          ) : (
            <Image src={client} className={"clientImage"} />
          )}
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "space-between",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              height: "10rem",
              width: "100%",
              gap: "0.5rem",
            }}
          >
            <div>
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                {`${
                  store?.quoteState?.quotationById?.customerId?.fullName ?? ""
                }`}
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.4rem", color: "#aaa" }}
              >
                {store?.quoteState?.quotationById?.customerId?.phoneNumber && (
                  <span className="flexRowWithGap">
                    <AiOutlinePhone
                      style={{
                        fontSize: "20",
                      }}
                    />
                    {store?.quoteState?.quotationById?.customerId?.phoneNumber
                      ? store?.quoteState?.quotationById?.customerId
                          ?.phoneNumber
                      : "N/A"}
                  </span>
                )}
                {store?.quoteState?.quotationById?.customerId?.address && (
                  <span className="flexRowWithGap">
                    <GoLocation
                      style={{
                        fontSize: "16",
                      }}
                    />{" "}
                    {store?.quoteState?.quotationById?.customerId?.address
                      ? store?.quoteState?.quotationById?.customerId?.address
                      : "N/A"}
                  </span>
                )}
                {store?.quoteState?.quotationById?.customerId?.email && (
                  <span className="flexRowWithGap">
                    <AiOutlineMail
                      style={{
                        fontSize: "16",
                      }}
                    />{" "}
                    {store?.quoteState?.quotationById?.customerId?.email
                      ? store?.quoteState?.quotationById?.customerId?.email
                      : "N/A"}
                  </span>
                )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Button
                    type="danger"
                    onClick={() => setIsClientDetailsModelVisible(true)}
                  >
                    View Client
                  </Button>
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
              >
                {store?.quoteState?.quotationById?.quoteStatus ===
                "QUOTE_DRAFT" ? (
                  <p className="orangeTag">QUOTE_DRAFT</p>
                ) : store?.quoteState?.quotationById?.quoteStatus === "WIN" ? (
                  <p className="greenTag">WIN</p>
                ) : store?.quoteState?.quotationById?.quoteStatus === "LOSE" ? (
                  <p className="redTag">LOSE</p>
                ) : store?.quoteState?.quotationById?.quoteStatus ===
                  "QUOTE_SENT" ? (
                  <p className="blueTag">QUOTE_SENT</p>
                ) : (
                  "N/A"
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
              }}
            >
              <button
                className="emailAndMessage"
                onClick={() => {
                  // setIsSendComposedEmailModalVisible(true);
                }}
              >
                <BsChatLeftText style={{ fontSize: "10px" }} />
                <span>Message</span>
              </button>
              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
                <span>Email</span>
              </button>
              {store.leadState.leadById?.leadStatus?.statusName !== "WIN" &&
                store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
                  "ADD"
                ) && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsAddVisasModalVisible(true);
                    }}
                  >
                    Add Case
                  </Button>
                )}
              {store.profileState?.profile?.department?.rolesAndPermissionList?.APPOINTMENTS?.includes(
                "CHANGE_STATUS"
              ) && (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsStatusHistoryModelVisible(true);
                  }}
                >
                  Status Update
                </Button>
              )}{" "}
            </div>

            {/* <div>
              <div>
                <span className="profileTitle">Campaign:</span>
                <span>
                  {store?.quoteState?.quotationById?.customerId??.leadResponse?.customerResponse?.campaign?.name ??
                    "N/A"}
                </span>
              </div>
            </div> */}

            {/* <div className="flexColumnWithoutStyle">
              <h4>{store?.quoteState?.quotationById?.customerId??.country?.name}</h4>
              <div>{store?.quoteState?.quotationById?.customerId??.levelOfCollege?.name}</div>
              <div>{store?.quoteState?.quotationById?.customerId??.crisco}</div>
            </div> */}
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "emailLog" && <EmailLogDetail />}
          {current === "quotationDetails" && (
            <>
              <div
                style={{
                  borderRadius: "1.5rem",
                  padding: "0.8rem",
                  display: "flex",
                  gap: "0.5rem",
                  flexDirection: "column",
                }}
              >
                {detailFun(
                  "Campaign",
                  `${
                    store?.quoteState?.quotationById?.customerId?.campaign?.name
                      ? store?.quoteState?.quotationById?.customerId?.campaign
                          ?.name
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Source",
                  `${
                    store?.quoteState?.quotationById?.customerId?.source?.name
                      ? store?.quoteState?.quotationById?.customerId?.source
                          ?.name
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Country of  Residence",
                  `${
                    store?.quoteState?.quotationById?.customerId
                      ?.countryOfResidence
                      ? store?.quoteState?.quotationById?.customerId
                          ?.countryOfResidence
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Nature Of Project",
                  `${
                    store?.quoteState?.quotationById?.natureOfProject
                      ? store?.quoteState?.quotationById?.natureOfProject
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Gross Price",
                  `${
                    store?.quoteState?.quotationById?.grossPrice
                      ? store?.quoteState?.quotationById?.grossPrice
                      : "N/A"
                  }`
                )}

                {store?.quoteState?.quotationById?.discount !== "" &&
                  store?.quoteState?.quotationById?.discount !== null &&
                  store?.quoteState?.quotationById?.discount !== undefined &&
                  store?.quoteState?.quotationById?.percentage !== "" &&
                  store?.quoteState?.quotationById?.percentage !== null &&
                  store?.quoteState?.quotationById?.percentage !==
                    undefined && (
                    <>
                      {detailFun(
                        "Discount",
                        `${store?.quoteState?.quotationById?.discount} ${
                          store?.quoteState?.quotationById?.percentage === false
                            ? "FLAT"
                            : store?.quoteState?.quotationById?.percentage ===
                              true
                            ? "%"
                            : null
                        }
                      `
                      )}
                    </>
                  )}
                {detailFun(
                  "Is GST applicable",
                  twoTagResponses(
                    store?.quoteState?.quotationById?.gstApplicable,
                    true,
                    false
                  )
                )}
                {store?.quoteState?.quotationById?.gstApplicable &&
                  detailFun(
                    "GST amount",
                    `${
                      store?.quoteState?.quotationById?.gstAmount
                        ? store?.quoteState?.quotationById?.gstAmount
                        : "N/A"
                    }`
                  )}
                {detailFun(
                  "Total amount",
                  `${
                    store?.quoteState?.quotationById?.totalAmount
                      ? store?.quoteState?.quotationById?.totalAmount
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Payment terms (in months)",
                  `${
                    store?.quoteState?.quotationById?.paymentTermsInMonths
                      ? store?.quoteState?.quotationById?.paymentTermsInMonths
                      : "N/A"
                  }`
                )}

                {detailFun(
                  "Timeline",
                  `${
                    store?.quoteState?.quotationById?.quoteSource
                      ? store?.quoteState?.quotationById?.associatedTimelineId
                          ?.name
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "quoteSource",
                  `${
                    store?.quoteState?.quotationById?.quoteSource
                      ? store?.quoteState?.quotationById?.quoteSource
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "About us",
                  `${
                    store?.quoteState?.quotationById?.aboutUsId?.name
                      ? store?.quoteState?.quotationById?.aboutUsId?.name
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Quote date",
                  `${
                    store?.quoteState?.quotationById?.addedDateTime
                      ? formattedDate(
                          store?.quoteState?.quotationById?.addedDateTime
                        )
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Quote expiry date",
                  `${
                    store?.quoteState?.quotationById?.quoteExpiryDate
                      ? formattedDate(
                          store?.quoteState?.quotationById?.quoteExpiryDate
                        )
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Mode of contact",
                  `${
                    store?.quoteState?.quotationById?.modeOfContact
                      ? store?.quoteState?.quotationById?.modeOfContact
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Finance options?",
                  twoTagResponses(
                    store?.quoteState?.quotationById?.financeOptions,
                    true,
                    false
                  )
                )}
                {detailFun(
                  "Description",
                  `${
                    store?.quoteState?.quotationById?.description
                      ? store?.quoteState?.quotationById?.description
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Special note",
                  `${
                    store?.quoteState?.quotationById?.specialNote
                      ? store?.quoteState?.quotationById?.specialNote
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Quote Update",
                  `${
                    store?.quoteState?.quotationById?.quotesUpdate
                      ? store?.quoteState?.quotationById?.quotesUpdate
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Display quote update in quote confirmation?",
                  twoTagResponses(
                    store?.quoteState?.quotationById
                      ?.displayQuotesUpdateInQuoteConfirmation,
                    true,
                    false
                  )
                )}
                {detailFun(
                  "Payment terms information",
                  `${
                    store?.quoteState?.quotationById?.paymentTermsInfo
                      ? store?.quoteState?.quotationById?.paymentTermsInfo
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Display payment terms info in quote confirmation?",
                  twoTagResponses(
                    store?.quoteState?.quotationById
                      ?.displayPaymentTermsInfoInQuoteConfirmation,
                    true,
                    false
                  )
                )}
              </div>
            </>
          )}

          {current === "quotation" && <QuotationCard record={record} />}
        </Card>
      </Card>
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          mailTemplateType="QUOTATION"
          composeMailRecord={{
            toUser: [store?.quoteState?.quotationById?.customerId?.email],
          }}
          forWhatId={record?.id}
          fetchEmailLogDetails={fetchEmailLogDetails}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
      {isAddVisasModalVisible && (
        <AddVisaCases
          navigateCaseList={true}
          fromLead={true}
          recordData={{ leadResponse: store?.leadState?.leadById }}
          isAddVisaCasesModalVisible={isAddVisasModalVisible}
          setIsAddVisaCasesModalVisible={setIsAddVisasModalVisible}
        />
      )}
      {isStatusHistoryModelVisible && (
        // <StatusHistory
        //   type={"LEAD"}
        //   leadData={caseAppointmentLeadData}
        //   record={store?.leadState?.leadById}
        //   isStatusHistoryModalVisible={isStatusHistoryModelVisible}
        //   setIsStatusHistoryModalVisible={setIsStatusHistoryModelVisible}
        // />
        <UpdateQuotationiStatus
          quoteStatus={store?.quoteState?.quotationById?.quoteStatus}
          quoteId={store?.quoteState?.quotationById?.id}
          isStatusHistoryModalVisible={isStatusHistoryModelVisible}
          setIsStatusHistoryModalVisible={setIsStatusHistoryModelVisible}
          fetchQuotationById={fetchQuotationById}
        />
      )}

      {isClientDetailsModelVisible && record?.customerId?.id && (
        <ClientDetails
          Record={store.clientState.clientById}
          isClientDetailsModelVisible={isClientDetailsModelVisible}
          setIsClientDetailsModelVisible={setIsClientDetailsModelVisible}
        />
      )}
    </Drawer>
  );
};

export default QuotationDetails;
