import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { CgAssign } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import { formattedDate } from "../../HelperFunction";
import { AiOutlineEye, AiOutlineHistory } from "react-icons/ai";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import AssignAgents from "../Visas/AssignAgents";
import VisaCaseDetails from "../Visas/VisaCaseDetails";
import CaseStatusHistory from "../Visas/CaseStatusHistory";
import UpdateCaseStatus from "../Visas/UpdateCaseStatus";
const columns = [
  {
    title: "Case No.",
    dataIndex: "caseNo",
  },
  {
    title: "Client",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "Visa Type",
    dataIndex: "visaCategory",
    align: "center",
  },
  {
    title: "Sales Rep",
    dataIndex: "salesRepName",
    align: "center",
  },
  {
    title: "Grand Total",
    dataIndex: "totalAmount",
    align: "center",
  },

  {
    title: "Added Date",
    dataIndex: "addedOn",
    align: "center",
  },

  {
    title: "Lead Type",
    dataIndex: "leadType",
    align: "center",
  },
  {
    title: "Visa Outcome",
    dataIndex: "visaOutcome",
    align: "center",
  },
  {
    title: "Case Status",
    dataIndex: "status",
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const MyCases = () => {
  const VisasState = useSelector((state) => state.MyCounsellingAndCasesReducer);
  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);
  const [isUpdateVisasModalVisible, setIsUpdateVisasModalVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const [VisasType, setVisasType] = useState("IN_PROGRESS");
  const [isUpdateCaseStatusModalVisible, setIsUpdateCaseStatusModalVisible] =
    useState(false);
  const [isStatusHistoryModelVisible, setIsStatusHistoryModelVisible] =
    useState(false);
  const [isVisaCaseDetailsModalVisible, setIsVisaCaseDetailsModalVisible] =
    useState(false);
  const [isAssignAgentsModallVisible, setIsAssignAgentsModallVisible] =
    useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (VisasType === "IN_PROGRESS") {
      dispatch({
        type: "FETCH_MY_CASES_IN_PROGRESS_REQUEST",
        payload: "IN_PROGRESS",
      });
    } else if (VisasType === "COMPLETED") {
      dispatch({
        type: "FETCH_MY_CASES_COMPLETED_REQUEST",
        payload: "COMPLETED",
      });
    }
  }, [VisasType]);
  useEffect(() => {
    if (
      isAddVisasModalVisible === false ||
      isUpdateVisasModalVisible === false
    ) {
      dispatch({ type: "FETCH_VISA_CASES_REQUEST" });
    }
  }, [isAddVisasModalVisible, isUpdateVisasModalVisible]);

  let data = [];
  if (VisasType === "IN_PROGRESS") {
    data = VisasState.myInProgressCases?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        caseNo: dataObj.id,
        caseStatus: dataObj.caseStatus?.caseStatus,
        visaOutcome: dataObj.visaOutcome ? (
          <Tag
            color={
              dataObj.visaOutcomeColorCode
                ? dataObj.visaOutcomeColorCode
                : "White"
            }
          >
            {dataObj.visaOutcome}
          </Tag>
        ) : (
          "N/A"
        ),
        name: dataObj.customer.firstName + " " + dataObj.customer.lastName,
        visaCategory: dataObj.visaCategory,
        salesRepName: dataObj.salesRepresentativeResponse?.salesRepName,
        totalAmount: dataObj.totalAmount ?? 0,
        percentage: dataObj.institute?.percentage,
        commission: dataObj.institute?.commission,
        addedOn: formattedDate(dataObj.addedOn),
        leadType: dataObj.leadType,
        status: dataObj.workFlowStatusDto?.statusName ? (
          <Tag
            color={
              dataObj.workFlowStatusDto?.colorCode
                ? dataObj.workFlowStatusDto?.colorCode
                : "green"
            }
          >
            {dataObj.workFlowStatusDto?.statusName}
          </Tag>
        ) : null,

        statusUpdate: (
          <Space>
            <Tooltip title="Case Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsVisaCaseDetailsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="View status History">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsStatusHistoryModelVisible(true);
                  }}
                >
                  <AiOutlineHistory />
                </div>
              </a>
            </Tooltip>
          </Space>
        ),

        actions: (
          <Space size="middle">
            {/* <Tooltip title="Update Case Status">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateCaseStatusModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip> */}
            {/* <Tooltip title="Delete Visas">
              <Popconfirm
                title="Are you sure to delete this Visas?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_VISAS_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip> */}

            <Tooltip title="Case Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsVisaCaseDetailsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="View status History">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsStatusHistoryModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineHistory />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Assign Agents">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsAssignAgentsModallVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <CgAssign />
                </div>
              </a>
            </Tooltip>
          </Space>
        ),
      };
    });
  } else if (VisasType === "COMPLETED") {
    data = VisasState.myCompletedCases?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        caseNo: dataObj.id,
        visaOutcome: dataObj.visaOutcome ? (
          <Tag
            color={
              dataObj.visaOutcomeColorCode
                ? dataObj.visaOutcomeColorCode
                : "White"
            }
          >
            {dataObj.visaOutcome}
          </Tag>
        ) : (
          "N/A"
        ),
        caseStatus: dataObj.caseStatus?.caseStatus,
        name: dataObj.customer.firstName + " " + dataObj.customer.lastName,
        visaCategory: dataObj.visaCategory,
        salesRepName: dataObj.salesRepresentativeResponse?.salesRepName,
        totalAmount: dataObj.totalAmount,
        percentage: dataObj.institute?.percentage,
        commission: dataObj.institute?.commission,
        addedOn: formattedDate(dataObj.addedOn),
        leadType: dataObj.leadType,
        status: dataObj.workFlowStatusDto?.statusName ? (
          <Tag
            color={
              dataObj.workFlowStatusDto?.colorCode
                ? dataObj.workFlowStatusDto?.colorCode
                : "green"
            }
          >
            {dataObj.workFlowStatusDto?.statusName}
          </Tag>
        ) : null,

        actions: (
          <Space size="middle">
            {/* <Tooltip title="Update Case Status">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateCaseStatusModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip> */}
            {/* <Tooltip title="Delete Visas">
                <Popconfirm
                  title="Are you sure to delete this Visas?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_VISAS_REQUEST",
                      payload: { id: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip> */}
            <Tooltip title="Case Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    const actionStatus =
                      dataObj.status === "INACTIVE" ? "active" : "inactive";
                    dispatch({
                      type: "TOGGLE_VISAS_STATUS_REQUEST",
                      payload: { id: dataObj.id, actionStatus },
                    });
                  }}
                >
                  <MdOutlineDisabledVisible />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="View status History">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsStatusHistoryModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineHistory />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Assign Agents">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsAssignAgentsModallVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <CgAssign />
                </div>
              </a>
            </Tooltip>
          </Space>
        ),
      };
    });
  }
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div
          className="flexRow"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>IN PROGRESS</div>
                  </div>
                ),
                value: "IN_PROGRESS",
              },

              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>COMPLETED</div>
                  </div>
                ),
                value: "COMPLETED",
              },
            ]}
            onChange={(value) => {
              setVisasType(value);
              console.log(value);
            }}
          />
        </div>
        <div style={{ marginTop: "4rem" }}>
          {VisasState.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table bordered columns={columns} dataSource={data} />
          )}
        </div>
      </div>

      {isUpdateCaseStatusModalVisible && (
        <UpdateCaseStatus
          record={record}
          isUpdateCaseStatusModalVisible={isUpdateCaseStatusModalVisible}
          setIsUpdateCaseStatusModalVisible={setIsUpdateCaseStatusModalVisible}
        />
      )}
      {isStatusHistoryModelVisible && (
        <CaseStatusHistory
          record={record}
          isUpdateCaseStatusModalVisible={isStatusHistoryModelVisible}
          setIsUpdateCaseStatusModalVisible={setIsStatusHistoryModelVisible}
        />
      )}
      {isVisaCaseDetailsModalVisible && (
        <VisaCaseDetails
          visaRecord={record}
          record={record}
          isVisaCaseDetailsModalVisible={isVisaCaseDetailsModalVisible}
          setIsVisaCaseDetailsModalVisible={setIsVisaCaseDetailsModalVisible}
        />
      )}
      {isAssignAgentsModallVisible && (
        <AssignAgents
          record={record}
          isAssignAgentsModalVisible={isAssignAgentsModallVisible}
          setIsAssignAgentsModalVisible={setIsAssignAgentsModallVisible}
        />
      )}
    </SiderDemo>
  );
};
export default MyCases;
