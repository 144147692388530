import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
const { Option } = Select;

const EditCustomerQualification = ({
  clientRecord,
  record,
  isUpdateCustomerQualificationModalVisible,
  setIsUpdateCustomerQualificationModalVisible,
  setIsClientDetailsModelVisible,
}) => {
  const leadState = useSelector((state) => state.LeadReducer);
  useEffect(() => {
    dispatch({ type: "FETCH_LEADS_DROPDOWN_REQUEST" });
  }, []);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const updateCustomerQualificationSubmitHandler = (value) => {
    const yearsOfCommencement = moment(value.yearsOfCommencement).format(
      "YYYY"
    );
    const yearsOfCompletion = moment(value.yearsOfCompletion).format("YYYY");
    const formData = {
      qualification: value.qualification,
      institution: value.institution,
      country: value.country,
      percentage: value.percentage,

      yearsOfCommencement,
      yearsOfCompletion,
      documentsAttached: value.documentsAttached,
    };
    dispatch({
      type: "EDIT_CUSTOMER_QUALIFICATIONS_REQUEST",
      payload: { id: record.id, formData, clientId: clientRecord.id },
    });
    // form.resetFields();
    setIsUpdateCustomerQualificationModalVisible(false);
  };

  return (
    <Drawer
      title="Edit Qualification"
      open={isUpdateCustomerQualificationModalVisible}
      onClose={() => {
        setIsUpdateCustomerQualificationModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={updateCustomerQualificationSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["qualification"],
            value: record?.qualification,
          },
          {
            name: ["institution"],
            value: record?.institution,
          },
          {
            name: ["country"],
            value: record?.country,
          },
          {
            name: ["yearsOfCommencement"],
            value: record?.yearsOfCommencement
              ? moment(`${record?.yearsOfCommencement}`)
              : "",
          },
          {
            name: ["yearsOfCompletion"],
            value: record?.yearsOfCompletion
              ? moment(`${record?.yearsOfCompletion}`)
              : "",
          },
          {
            name: ["percentage"],
            value: record?.percentage,
          },
        ]}
        // initialValues={{
        //   yearsOfCompletion: moment(record?.yearsOfCompletion),
        //   yearsOfCommencement: moment(record?.yearsOfCommencement),
        //   country: record?.country?.name,
        //   institution: record?.institution,
        //   qualification: record?.qualification,
        //   percentage: record?.percentage,
        // }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                gap: "0.5rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Form.Item
                label="Qualification"
                name={"qualification"}
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: "Please enter qualification!",
                  },
                ]}
              >
                <Input placeholder="qualification" />
              </Form.Item>
              <Form.Item
                label="Institution"
                name={"institution"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter institution!",
                  },
                ]}
              >
                <Input placeholder="institution" />
              </Form.Item>
              <Form.Item
                label="Country"
                name={"country"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter country!",
                  },
                ]}
              >
                <Input placeholder="Country" />
              </Form.Item>
              <Form.Item
                label="Commencement Year"
                name={"yearsOfCommencement"}
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: "Please enter commencement year!",
                  },
                ]}
              >
                <DatePicker picker="year" placeholder="Commencement year" />
              </Form.Item>
              <Form.Item
                label="Completion Year"
                style={{ width: "100%" }}
                name={"yearsOfCompletion"}
                rules={[
                  {
                    required: true,
                    message: "Please enter Completion year!",
                  },
                ]}
              >
                <DatePicker picker="year" placeholder="Completion Year" />
              </Form.Item>
              <Form.Item
                label="Percentage/Grade"
                name={"percentage"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter percentage!",
                  },
                ]}
              >
                <Input type="number" placeholder="percentage" />
              </Form.Item>
            </div>
          </div>
        </div>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Qualification
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EditCustomerQualification;
