import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  myCounselling: {},

  myInProgressCases: {},
  myCompletedCases: {},
};

const MyCounsellingAndCasesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_MY_COUNSELLING_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_MY_COUNSELLING_REQUEST_SUCCESS": {
      return {
        ...state,
        myCounselling: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_MY_COUNSELLING_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_MY_CASES_IN_PROGRESS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_MY_CASES_IN_PROGRESS_REQUEST_SUCCESS": {
      return {
        ...state,
        myInProgressCases: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_MY_CASES_IN_PROGRESS_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_MY_CASES_COMPLETED_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_MY_CASES_COMPLETED_REQUEST_SUCCESS": {
      return {
        ...state,
        myCompletedCases: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_MY_CASES_COMPLETED_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default MyCounsellingAndCasesReducer;
