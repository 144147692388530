import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Steps,
} from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import AddAboutUs from "../Leads/AboutUs/AddAboutUs";
import AddLeadType from "./AddLeadType";
import AddIntake from "./AddIntake";
import AddInstitute from "../Education/Institute/AddInstitute";
import AddSalesRep from "./AddSalesRep";
import AddDegree from "./AddDegree";
import AddCourse from "../Courses/AddCourse";
import AddCasePackage from "./AddCasePackage";
import AddVisaSubCategory from "./AddVisaSubCategory";
import AddOrderStatus from "./AddOrderStatus";
import { date, formattedDate, YYYYMMDD } from "../../HelperFunction";
import AddEmployee from "../EmployeeManager/AddEmployee";
import AddHeadAgent from "../HeadAgent/AddHeadAgent";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import VisaUserInfo from "./VisaForms/VisaUserInfo";
import VisaInstituteInfo from "./VisaForms/VisaInstituteInfo";
import VisaPackageInfo from "./VisaForms/VisaPackageInfo";
import VisaPreviewAndSubmit from "./VisaForms/VisaPreviewAndSubmit";
import AdditionalInformation from "./VisaForms/AdditionalInformation";
const { Option } = Select;
const { TextArea } = Input;

const AddVisaCases = ({
  fromSiderDemo,
  navigateCaseList,
  fromClientsDetails,
  fromCounselling,
  fromLead,
  recordData,
  courseRecord,
  enrollType,
  instituteRecordForCountry,
  isAddVisaCasesModalVisible,
  setIsAddVisaCasesModalVisible,
  setIsStartCounsellingModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [visaCaseData, setVisaCaseData] = useState({
    assignedToEmail: "",
    startDate: moment(),
    leadType: "COMPANY ASSIGNED",
    salesRepresentativeId: recordData?.leadResponse?.salesRepId,
    salesRepresentativeName: recordData?.leadResponse?.salesRepName,
    clientType: "EXISTING",
    commenceDate: "",
    customerId: recordData?.leadResponse?.customerResponse?.id,
    customer: `${recordData?.leadResponse?.customerResponse?.firstName ?? ""} ${
      recordData?.leadResponse?.customerResponse?.lastName ?? ""
    } ${
      recordData?.leadResponse?.customerResponse?.email !== null &&
      recordData?.leadResponse?.customerResponse?.email !== "" &&
      recordData?.leadResponse?.customerResponse?.email !== undefined
        ? `(${recordData?.leadResponse?.customerResponse?.email})`
        : recordData?.leadResponse?.customerResponse?.mobile !== null &&
          recordData?.leadResponse?.customerResponse?.mobile !== "" &&
          recordData?.leadResponse?.customerResponse?.mobile !== undefined
        ? `(${recordData?.leadResponse?.customerResponse?.mobile})`
        : ""
    }`,
    firstName: recordData?.leadResponse?.customerResponse?.firstName,
    lastName: recordData?.leadResponse?.customerResponse?.lastName,
    mobile: recordData?.leadResponse?.customerResponse?.mobile,
    email: recordData?.leadResponse?.customerResponse?.email,
    dob: recordData?.leadResponse?.customerResponse?.dob,
    phone: recordData?.leadResponse?.customerResponse?.phone,
    address: recordData?.leadResponse?.customerResponse?.address,
    passportNumber: recordData?.leadResponse?.customerResponse?.passportNumber,
    maritalStatus: recordData?.leadResponse?.customerResponse?.maritalStatus,
    sexStatus: recordData?.leadResponse?.customerResponse?.sexStatus,
    countryOfResidence:
      recordData?.leadResponse?.customerResponse?.countryOfResidence,
    visaType: "EDUCATION",
    visaSubClassId: "",
    visaSubClass: "",
    country:
      instituteRecordForCountry !== undefined
        ? instituteRecordForCountry?.country?.name
        : "",
    countryId:
      instituteRecordForCountry !== undefined
        ? instituteRecordForCountry?.country?.id
        : "",
    currentStatus:
      enrollType === "ONSHORE"
        ? "ONSHORE"
        : enrollType === "OFFSHORE"
        ? "OFFSHORE"
        : "ONSHORE",
    searchInstitute: null,
    workFlowId: "",
    workFlow: "",
    workflowDocumentCheckList: [],
    workFlowStatusId: "",
    workFlowStatus: "",
    instituteId: courseRecord?.institute?.id,
    instituteName: courseRecord?.institute?.name,
    degreeId: courseRecord?.degree?.id,
    degreeName: courseRecord?.degree?.name,
    courseId: courseRecord?.id,
    courseName: courseRecord?.name,
    courseFee:
      enrollType === "ONSHORE"
        ? courseRecord?.onShoreFee
        : enrollType === "OFFSHORE"
        ? courseRecord?.offShoreFee
        : null,
    courseIntakes: courseRecord?.collegeIntakeResponseList,
    course: courseRecord,
    wantToPayFor: "",
    wantToPayDuration: null,
    IntakeId: "",
    Intake: "",
    wantToPayFee: null,
    fee: 0,
    feeWithoutPackage: 0,
    isAddPackage: false,
    isDiscountAllowed: false,
    isScholarshipAllowed: false,
    scholarshipType: null,
    scholorship: null,
    packageDiscount: null,
    packageDiscountType: "",
    packageTotal: 0,
    grandTotal: 0,
    currency: "",
    description: "",
    priorNote: "",
    deadlineDate: "",
    consultantId: [],
    superAgentId: null,
    subAgentId: null,
    documentCheckList: [],
    coursesChecklist: [],
    netTotalFee: null,
  });
  const [packages, setPackages] = useState([
    {
      id: null,
      packageName: "",
      quantity: 0,
      totalPrice: 0,
      unitPrice: 0,
      hasGst: null,
      gst: null,
      isGstFlat: null,
      isGstPercentage: null,
    },
  ]);
  const [current, setCurrent] = useState(0);

  // useEffect(() => {
  //   const courseOnshoreChecklist =
  //   visaCaseData?.course?.onShoreCheckList?.map((dataObj) => {
  //     return dataObj.documentCheckListId;
  //   });
  //   setVisaCaseData((previousData) => {
  //     return {
  //       ...previousData,
  //       coursesChecklist: courseOnshoreChecklist,
  //     };
  // },[])
  const addCaseSubmitHandler = () => {
    const formData = {
      commenceDate: visaCaseData?.commenceDate,
      assignedToEmail: visaCaseData?.assignedToEmail,
      isScholarshipAllowed: visaCaseData?.isScholarshipAllowed,
      scholarshipType: visaCaseData?.scholarshipType,
      scholarShipAmount: visaCaseData?.scholorship,
      leadId: fromLead ? recordData?.leadResponse?.id : null,
      counsellingId: fromCounselling ? recordData?.id : null,
      isFromCounselling: fromCounselling ? fromCounselling : null,
      startDate: visaCaseData.startDate ? date(visaCaseData.startDate) : null,
      deadlineDate: visaCaseData.deadlineDate
        ? date(visaCaseData.deadlineDate)
        : null,
      description: visaCaseData.description ? visaCaseData.description : null,
      consultantId: visaCaseData.consultantId ? visaCaseData.consultantId : [],
      // superAgentId: visaCaseData.superAgentId ? visaCaseData.superAgentId : [],
      superAgentId: visaCaseData.superAgentId
        ? [visaCaseData.superAgentId]
        : [],
      leadType: visaCaseData.leadType ? visaCaseData.leadType : null,
      salesRepresentativeId: visaCaseData.salesRepresentativeId
        ? visaCaseData.salesRepresentativeId
        : null,
      customerId: visaCaseData.customerId ? visaCaseData.customerId : null,
      visaCategory: visaCaseData.visaType ? visaCaseData.visaType : null,
      visaSubClassId: visaCaseData.visaSubClassId
        ? visaCaseData.visaSubClassId
        : null,
      currency: visaCaseData.currency ? visaCaseData.currency : null,
      priorNote: visaCaseData.priorNote ? visaCaseData.priorNote : null,
      collegeIntake: visaCaseData.IntakeId ? visaCaseData.IntakeId : null,
      instituteId: visaCaseData.instituteId ? visaCaseData.instituteId : null,
      courseId: visaCaseData.courseId ? visaCaseData.courseId : null,
      degree: visaCaseData.degreeId ? visaCaseData.degreeId : null,
      wantToPayFor:
        visaCaseData.wantToPayFor === "OTHERS"
          ? visaCaseData.wantToPayDuration
          : visaCaseData.wantToPayFor,
      fee: visaCaseData.courseFee ? visaCaseData.courseFee : null,
      discount: visaCaseData.packageDiscount
        ? visaCaseData.packageDiscount
        : null,
      isDiscountAllowed: visaCaseData.isDiscountAllowed
        ? visaCaseData.isDiscountAllowed
        : null,
      totalAmount: visaCaseData.fee + visaCaseData.packageTotal,
      currentPayAmount: visaCaseData.wantToPayFee,

      isOffShore:
        visaCaseData.currentStatus === "OFFSHORE"
          ? true
          : visaCaseData.currentStatus === "ONSHORE"
          ? false
          : null,
      requiredWorkflowChecklist: visaCaseData.documentCheckList
        ? visaCaseData.documentCheckList
        : null,
      requiredCoursesChecklist: visaCaseData.coursesChecklist
        ? visaCaseData.coursesChecklist
        : null,
      workFlowStatusId: visaCaseData.workFlowStatusId
        ? visaCaseData.workFlowStatusId
        : null,
      workFlowId: visaCaseData.workFlowId ? visaCaseData.workFlowId : null,
      subAgentId: visaCaseData?.subAgentId,
      netTotalFee: visaCaseData?.netTotalFee,
      newCustomerRequest: {
        contact: visaCaseData.customerId ? visaCaseData.customerId : null,
        firstName: visaCaseData.firstName ? visaCaseData.firstName : null,
        lastName: visaCaseData.lastName ? visaCaseData.lastName : null,
        email: visaCaseData.email ? visaCaseData.email : null,
        mobile: visaCaseData.mobile ? visaCaseData.mobile : null,
        dob: visaCaseData.dob ? date(visaCaseData.dob) : null,
        passportNumber: visaCaseData.passportNumber
          ? visaCaseData.passportNumber
          : null,
        address: visaCaseData.address ? visaCaseData.address : null,
        maritalStatus: visaCaseData.maritalStatus
          ? visaCaseData.maritalStatus
          : null,
        sexStatus: visaCaseData.sexStatus ? visaCaseData.sexStatus : null,
        countryOfResidence: visaCaseData.countryOfResidence
          ? visaCaseData.countryOfResidence
          : null,
      },
    };

    dispatch({
      type: "ADD_VISA_CASE_REQUEST",
      payload: {
        ...formData,
        branchId: localStorage.getItem("branchId"),
        casePackagesId: [...casePackagesIds()],
        casePackagesDetailsList:
          visaCaseData.isAddPackage === false
            ? []
            : [...casePackagesIdsAndQuantities()],
      },
      payload2: {
        fromClientsDetails: fromClientsDetails ? true : false,
        contactRecordID: recordData?.leadResponse?.customerResponse?.id,
        // successFunction: successFunction,
      },
      successFunction: successFunction,
    });
    // if (navigateCaseList) {
    //   navigate("/cases");
    // }
  };
  const successFunction = (response) => {
    dispatch({
      type: "FETCH_CASE_CUSTOMER_BY_ID_REQUEST",
      payload: recordData?.leadResponse?.customerResponse?.id,
    });
    setIsAddVisaCasesModalVisible(false);
  };
  const casePackagesIds = () => {
    let ids = [];
    if (packages[0].id !== null) {
      ids = packages.map((item) => {
        if (item.id !== null) {
          return item.id;
        } else {
          return;
        }
      });
    }
    return ids;
  };
  const casePackagesIdsAndQuantities = () => {
    let IdsAndQuantities = [];
    if (packages[0].id !== null) {
      IdsAndQuantities = packages.map((item) => {
        if (item.id !== null) {
          return {
            id: item.id,
            packageName: item.packageName,
            quantity: item.quantity,
            totalPrice: item.totalPrice,
            unitPrice: item.unitPrice,
            hasGst: item.hasGst,
            gst: item.gst,
            gstType: item.isGstFlat
              ? "FLAT"
              : item.isGstPercentage
              ? "PERCENTAGE"
              : null,
          };
        } else {
          return;
        }
      });
    }
    return IdsAndQuantities;
  };
  const forms = [
    <VisaUserInfo
      visaCaseData={visaCaseData}
      setVisaCaseData={setVisaCaseData}
      current={current}
      setCurrent={setCurrent}
      recordData={recordData}
    />,
    <VisaInstituteInfo
      visaCaseData={visaCaseData}
      setVisaCaseData={setVisaCaseData}
      current={current}
      setCurrent={setCurrent}
    />,
    <VisaPackageInfo
      visaCaseData={visaCaseData}
      setVisaCaseData={setVisaCaseData}
      packages={packages}
      setPackages={setPackages}
      current={current}
      setCurrent={setCurrent}
      addCaseSubmitHandler={addCaseSubmitHandler}
    />,
    <AdditionalInformation
      visaCaseData={visaCaseData}
      setVisaCaseData={setVisaCaseData}
      packages={packages}
      setPackages={setPackages}
      current={current}
      setCurrent={setCurrent}
      addCaseSubmitHandler={addCaseSubmitHandler}
    />,
    <VisaPreviewAndSubmit
      visaCaseData={visaCaseData}
      setVisaCaseData={setVisaCaseData}
      packages={packages}
      setPackages={setPackages}
      current={current}
      setCurrent={setCurrent}
      addCaseSubmitHandler={addCaseSubmitHandler}
      setIsAddVisaCasesModalVisible={setIsAddVisaCasesModalVisible}
      setIsStartCounsellingModalVisible={setIsStartCounsellingModalVisible}
    />,
  ];

  return (
    <Drawer
      title="Add Case"
      open={isAddVisaCasesModalVisible}
      onClose={() => {
        form.resetFields();
        setIsAddVisaCasesModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <div className="ClientDrawerStyle">
        <Steps
          current={current}
          onChange={(e) => {
            setCurrent(e);
          }}
          items={[
            {
              title: "Step 1",
              description: "CLIENT INFORMATION",
            },
            {
              title: "Step 2",
              description: "VISA INFORMATION",
            },
            {
              title: "Step 3",
              description: "PACKAGE INFORMATION",
            },
            {
              title: "Step 4",
              description: "ADDITIONAL INFORMATION",
            },
            {
              title: "Step 5",
              description: "PREVIEW AND SUBMIT",
            },
          ]}
        />
      </div>
      {forms[current]}
    </Drawer>
  );
};

export default AddVisaCases;
