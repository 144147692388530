import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  message,
} from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { AiOutlinePlus } from "react-icons/ai";

const AddEditInstituteDocument = ({
  fetchInstituteById,
  record,
  isUpdate,
  isModalVisible,
  setIsModalVisible,
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [form] = Form.useForm();
  const [indexList, setIndexList] = useState([]);
  const [fileList, setFileList] = useState([]);

  console.log("file List", fileList);

  const handleUploadFormDocument = (index) => {
    const dataObj = fileList?.find((obj) => obj?.fileId === index);
    if (dataObj) {
      const data = form.getFieldValue("documentForm")[index];

      const documentsFormData = new FormData();
      documentsFormData.append("documentType", data?.formType);
      documentsFormData.append("document", dataObj?.file);
      dispatch({
        type: "ADD_INSTITUTE_FORM_REQUEST",
        payload: {
          id: record?.id,
          formData: documentsFormData,
        },
        isSuccessFn: true,
        successFunction: (res) => {
          message.success("Form has been uploaded");
        },
      });
    }
  };

  return (
    <Modal
      title={`${isUpdate ? "Edit" : "Add"} Institute Form`}
      open={isModalVisible}
      onCancel={() => {
        setIsModalVisible(false);
        fetchInstituteById();
      }}
      footer={null}
      width={767}
    >
      <Form form={form} layout="vertical">
        <Form.List name="documentForm" style={{ marign: "1rem" }}>
          {(fields, { add, remove }) => (
            <div
              style={{
                gridColumn: "span 2",
                gap: "0.5rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {fields.map(({ key, name, ...restField }, index) => (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr 1fr",
                    gap: "0 1rem",
                    position: "relative",
                    padding: "1rem 3rem 0 1rem",
                    background: "#f9f9f9",
                    borderRadius: "1rem",
                  }}
                  key={key}
                >
                  {indexList?.includes(index) && (
                    <Form.Item
                      {...restField}
                      style={{ gridColumn: "span 3" }}
                      label="Formtype Name"
                      name={[name, "FormtypeName"]}
                    >
                      <Input placeholder="Formtype Name" />
                    </Form.Item>
                  )}
                  <Form.Item
                    {...restField}
                    name={[name, "formType"]}
                    label="Form Type"
                    style={{ width: "100%" }}
                  >
                    <Select
                      placeholder="Select Formtype"
                      onChange={(value) => {
                        value === "Other"
                          ? setIndexList((prev) => [...prev, index])
                          : setIndexList((prev) => [
                              ...prev?.filter((value) => value !== index),
                            ]);
                        console.log(
                          "change value",
                          value,
                          form.getFieldValue("documentForm")[index]?.formType
                        );
                      }}
                    >
                      <Option value="GTE Form"> GTE Form</Option>
                      <Option value="Application Form">Application Form</Option>
                      <Option value="Credit Application">
                        Credit Application
                      </Option>
                      <Option value="Scholoarship Form">
                        Scholoarship Form
                      </Option>
                      <Option value="Other">Other</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label="Document Form File"
                    name={[name, "documentForm"]}
                    onChange={(e) => {
                      console.log(
                        "data change",
                        JSON.stringify(e.target.files[0])
                      );

                      if (fileList?.length === 0) {
                        setFileList([
                          { fileId: index, file: e.target.files[0] },
                        ]);
                      } else {
                        setFileList((prev) =>
                          fileList?.find((Obj) => Obj?.fileId === index)
                            ? [
                                ...prev?.map((data) => {
                                  if (index === data?.id) {
                                    return {
                                      fileId: index,
                                      file: e.target.files[0],
                                    };
                                  } else {
                                    return data;
                                  }
                                }),
                              ]
                            : [
                                ...prev,
                                { fileId: index, file: e.target.files[0] },
                              ]
                        );
                      }
                    }}
                  >
                    <Input placeholder="Upload Document Form" type="file" />
                  </Form.Item>

                  <Button
                    style={{ margin: "2rem 0 0" }}
                    block
                    type="primary"
                    onClick={() => handleUploadFormDocument(index)}
                  >
                    Upload
                  </Button>

                  <Button
                    type="danger"
                    onClick={() => {
                      remove(name);
                      const tempIndex = index;
                      const tempList = indexList?.filter(
                        (id) => id !== tempIndex
                      );
                      const valueList = tempList?.map((id) => {
                        if (id < tempIndex) {
                          return id;
                        } else {
                          return id - 1;
                        }
                      });
                      setIndexList([...valueList]);
                      const tempFileIndex = index;
                      setFileList((prev) => [
                        ...prev
                          ?.filter((data) => data?.fileId !== tempFileIndex)
                          ?.map((data) => {
                            if (data?.fileId < tempFileIndex) {
                              return data;
                            } else {
                              return {
                                ...data,
                                fileId: data?.fileId - 1,
                              };
                            }
                          }),
                      ]);
                    }}
                    style={{
                      padding: "0",
                      borderRadius: "50%",
                      height: "1.5rem",
                      width: "1.5rem",
                      position: "absolute",
                      top: "0.5rem",
                      right: "0.5rem",
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                </div>
              ))}
              <Button
                block
                type="dashed"
                onClick={() => {
                  add();
                }}
              >
                <PlusOutlined />
                Add Form
              </Button>
            </div>
          )}
        </Form.List>
        {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            block
            type="primary"
            htmlType="submit"
            style={{ width: "fit-content", marginTop: "1rem" }}
          >
            {`${isUpdate ? "Edit" : "Add"} Document Form`}
          </Button>
        </div> */}
      </Form>
    </Modal>
  );
};

export default AddEditInstituteDocument;
