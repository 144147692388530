import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const AddSalesRep = ({
  isAddSalesRepModalVisible,
  setIsAddSalesRepModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addSalesRepSubmitHandler = (value) => {
    const formData = {
      ...value,
      branchId: localStorage.getItem("branchId"),
    };
    dispatch({ type: "ADD_SALES_REP_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddSalesRepModalVisible(false);
  };
  return (
    <Modal
      title="Add Sales Rep"
      open={isAddSalesRepModalVisible}
      onCancel={() => {
        setIsAddSalesRepModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addSalesRepSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Sales Representative Name"
          name={"salesRepresentativeName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter sales representative name!",
            },
          ]}
        >
          <Input placeholder="Sales Representative Name" />
        </Form.Item>
        <Form.Item
          label="Email"
          name={"email"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter valid email!",
            },
          ]}
        >
          <Input placeholder="email" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Sales Rep
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddSalesRep;
