import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  Popconfirm,
  Segmented,
  Skeleton,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdDeleteOutline, MdOutlineDisabledVisible } from "react-icons/md";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import AddCountry from "../Leads/Country/AddCountry";
import ManageWorkflow from "./MangeWorkflow";
import ManageEducationWorkflow from "./ManageEducationWorkflow";
import { HiOutlineBookOpen, HiOutlinePaperAirplane } from "react-icons/hi";
import ManageMigrationWorkflow from "./MangeWorkflow";
import Confirmation from "../EmployeeManager/Department/Confirmation";
import { FiSettings } from "react-icons/fi";
import OrganizationalSettings from "../OrganizationalSettings/OrganizationalSettings";
import { AiOutlineAlignCenter } from "react-icons/ai";
import ManageOthersWorkflo from "./Others/ManageOthersWorkflow";
import ManageOthersWorkflow from "./Others/ManageOthersWorkflow";
const { Meta } = Card;

const Country = () => {
  const store = useSelector((state) => {
    return {
      countryState: state.SettingsReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [
    isAddEducationWorkflowModalVisible,
    setIsAddEducationWorkflowModalVisible,
  ] = useState(false);
  const [
    isAddMigrationWorkflowModalVisible,
    setIsAddMigrationWorkflowModalVisible,
  ] = useState(false);
  const [isAddOthersWorkflowModalVisible, setIsAddOthersWorkflowModalVisible] =
    useState(false);
  const [isconfirmationModalVisible, setIsconfirmationModalVisible] =
    useState(false);
  const [countryRecord, setCountryRecord] = useState({});
  const [countryType, setCountryType] = useState("getAllCountry");

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (countryType === "active") {
      dispatch({
        type: "FETCH_ACTIVE_COUNTRY_REQUEST",
        payload: {
          page: 1,
          pageSize: 100,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else {
      dispatch({
        type: "FETCH_COUNTRY_REQUEST",
        payload: {
          page: 1,
          pageSize: 100,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    }
  }, [countryType, store.branchState?.branchRecord]);
  useEffect(() => {
    if (isAddCountryModalVisible === false) {
      dispatch({ type: "FETCH_COUNTRY_REQUEST" });
    }
  }, [isAddCountryModalVisible]);

  return (
    <OrganizationalSettings>
      <div>
        <h3>Workflow Settings</h3>
        <Card>
          <div className="flexColumnwithoutStyle">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {store.profileState?.profile?.department?.rolesAndPermissionList?.WORKFLOW_SETTINGS?.includes(
                "ADD_COUNTRY"
              ) && (
                <button
                  className="button"
                  onClick={() => {
                    setIsAddCountryModalVisible(true);
                  }}
                >
                  <span>Add Country</span>
                </button>
              )}
              <Segmented
                style={{ height: "fit-content" }}
                options={[
                  {
                    label: (
                      <div style={{ padding: 4 }}>
                        <div>All Country</div>
                      </div>
                    ),
                    value: "getAllCountry",
                  },
                  {
                    label: (
                      <div style={{ padding: 4 }}>
                        <div>Active Country</div>
                      </div>
                    ),
                    value: "active",
                  },
                ]}
                onChange={(value) => {
                  setCountryType(value);
                }}
              />
            </div>
            {countryType === "getAllCountry" && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4,1fr)",
                  gap: "0.3rem",
                  margin: "1rem 0 0",
                }}
              >
                {store?.countryState.countries?.data?.map((dataObj) => {
                  return (
                    <Card
                      key={dataObj.id}
                      style={{
                        width: 198,
                        marginTop: 16,
                        borderRadius: "10px",
                      }}
                      actions={[
                        <Tooltip title={"Manage Education Workflow"}>
                          <HiOutlineBookOpen
                            key="education"
                            size={20}
                            onClick={() => {
                              setCountryRecord(dataObj);
                              setIsAddEducationWorkflowModalVisible(true);
                            }}
                          />
                        </Tooltip>,
                        <Tooltip title={"Manage Migration Workflow"}>
                          <HiOutlinePaperAirplane
                            key="migration"
                            size={20}
                            onClick={() => {
                              setCountryRecord(dataObj);
                              setIsAddMigrationWorkflowModalVisible(true);
                            }}
                          />
                        </Tooltip>,
                        <Tooltip title={"Manage Other Workflows"}>
                          <AiOutlineAlignCenter
                            key="others"
                            size={20}
                            onClick={() => {
                              setCountryRecord(dataObj);
                              setIsAddOthersWorkflowModalVisible(true);
                            }}
                          />
                        </Tooltip>,
                        <Popconfirm
                          placement="topLeft"
                          title="Do you want to change country Status"
                          onConfirm={() => {
                            dispatch({
                              type: "CHANGE_COUNTRY_STATUS_REQUEST",
                              payload: {
                                id: countryRecord.id,
                                formData: {
                                  statusMessage: "",
                                },
                              },
                            });
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip title={"Change Country Status"}>
                            <MdOutlineDisabledVisible
                              key="migration"
                              size={20}
                              onClick={() => {
                                setCountryRecord(dataObj);
                                // setIsconfirmationModalVisible(true);
                              }}
                            />
                          </Tooltip>
                        </Popconfirm>,
                        <Tooltip title={"Mark country as Defalult"}>
                          <FiSettings
                            size={20}
                            onClick={() => {
                              dispatch({
                                type: "MARK_COUNTRY_AS_DEFAULT_REQUEST",
                                payload: dataObj.id,
                              });
                            }}
                          />
                        </Tooltip>,
                      ]}
                    >
                      <Meta
                        style={{ height: 100 }}
                        avatar={
                          <Avatar
                            src={dataObj.countryImage}
                            style={{
                              backgroundColor: "#1d63a9",
                              verticalAlign: "middle",
                            }}
                            size="large"
                          >
                            {dataObj?.countryName.charAt(0).toUpperCase()}
                          </Avatar>
                        }
                        title={
                          dataObj.isDefault ? (
                            <>
                              <div className="flexColumnwithoutStyle">
                                {dataObj?.countryName}
                                <Tooltip title="default country">
                                  <span
                                    style={{
                                      borderRadius: "20px",
                                      fontSize: "12px",
                                      padding: "2px 8px",
                                      color: "white",
                                      background: "green",
                                      width: "fit-content",
                                    }}
                                  >
                                    Default
                                  </span>
                                </Tooltip>
                              </div>
                            </>
                          ) : (
                            dataObj?.countryName
                          )
                        }
                        description={
                          dataObj.status === "ACTIVE" ? (
                            <p className="greenTag">ACTIVE</p>
                          ) : (
                            <p className="redTag">INACTIVE</p>
                          )
                        }
                      />
                    </Card>
                  );
                })}
              </div>
            )}
            {countryType === "active" && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4,1fr)",
                  gap: "0.3rem",
                  margin: "1rem 0 0",
                }}
              >
                {store?.countryState.activeCountries?.data?.map((dataObj) => {
                  return (
                    <Card
                      key={dataObj.id}
                      style={{
                        width: 200,
                        marginTop: 16,
                        borderRadius: "10px",
                      }}
                      className="new--active--countrycard"
                      actions={[
                        <Tooltip title={"Manage Education Workflows"}>
                          <HiOutlineBookOpen
                            key="education"
                            size={20}
                            onClick={() => {
                              setCountryRecord(dataObj);
                              setIsAddEducationWorkflowModalVisible(true);
                            }}
                          />
                        </Tooltip>,
                        <Tooltip title={"Manage Migration Workflows"}>
                          <HiOutlinePaperAirplane
                            key="migration"
                            size={20}
                            onClick={() => {
                              setCountryRecord(dataObj);
                              setIsAddMigrationWorkflowModalVisible(true);
                            }}
                          />
                        </Tooltip>,
                        <Tooltip title={"Manage Other Workflows"}>
                          <AiOutlineAlignCenter
                            key="others"
                            size={20}
                            onClick={() => {
                              setCountryRecord(dataObj);
                              setIsAddOthersWorkflowModalVisible(true);
                            }}
                          />
                        </Tooltip>,
                        <Popconfirm
                          placement="topLeft"
                          title="Do you want to change country Status"
                          onConfirm={() => {
                            dispatch({
                              type: "CHANGE_COUNTRY_STATUS_REQUEST",
                              payload: {
                                id: countryRecord.id,
                                formData: {
                                  statusMessage: "",
                                },
                              },
                            });
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip title={"Change Country Status"}>
                            <MdOutlineDisabledVisible
                              key="migration"
                              size={20}
                              onClick={() => {
                                setCountryRecord(dataObj);
                                // setIsconfirmationModalVisible(true);
                              }}
                            />
                          </Tooltip>{" "}
                        </Popconfirm>,
                        <Tooltip title={"Mark country as Defalult"}>
                          <FiSettings
                            size={20}
                            onClick={() => {
                              dispatch({
                                type: "MARK_COUNTRY_AS_DEFAULT_REQUEST",
                                payload: dataObj.id,
                              });
                            }}
                          />
                        </Tooltip>,
                      ]}
                    >
                      <Meta
                        style={{ height: 100 }}
                        avatar={
                          <Avatar
                            src={dataObj.countryImage}
                            style={{
                              backgroundColor: "#1d63a9",
                              verticalAlign: "middle",
                            }}
                            size="large"
                          >
                            {dataObj?.countryName.charAt(0).toUpperCase()}
                          </Avatar>
                        }
                        title={
                          dataObj.isDefault ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {dataObj?.countryName}
                              <Tooltip title="default country">
                                <span
                                  style={{
                                    borderRadius: "20px",
                                    fontSize: "12px",
                                    padding: "2px 8px",
                                    color: "white",
                                    background: "green",
                                    width: "fit-content",
                                  }}
                                >
                                  Default
                                </span>
                              </Tooltip>
                            </div>
                          ) : (
                            dataObj?.countryName
                          )
                        }
                        description={
                          dataObj.status === "ACTIVE" ? (
                            <p className="greenTag">ACTIVE</p>
                          ) : (
                            <p className="redTag">INACTIVE</p>
                          )
                        }
                      />
                    </Card>
                  );
                })}
              </div>
            )}
          </div>
        </Card>
      </div>
      {isAddCountryModalVisible && (
        <AddCountry
          isAddCountryModalVisible={isAddCountryModalVisible}
          setIsAddCountryModalVisible={setIsAddCountryModalVisible}
        />
      )}
      {isAddMigrationWorkflowModalVisible && (
        <ManageMigrationWorkflow
          countryRecord={countryRecord}
          isManageMigrationWorkflowModalVisible={
            isAddMigrationWorkflowModalVisible
          }
          setIsManageMigrationWorkflowModalVisible={
            setIsAddMigrationWorkflowModalVisible
          }
        />
      )}
      {isAddEducationWorkflowModalVisible && (
        <ManageEducationWorkflow
          countryRecord={countryRecord}
          isManageEducationWorkflowModalVisible={
            isAddEducationWorkflowModalVisible
          }
          setIsManageEducationWorkflowModalVisible={
            setIsAddEducationWorkflowModalVisible
          }
        />
      )}
      {isAddOthersWorkflowModalVisible && (
        <ManageOthersWorkflow
          countryRecord={countryRecord}
          isManageOthersWorkflowModalVisible={isAddOthersWorkflowModalVisible}
          setIsManageOthersWorkflowModalVisible={
            setIsAddOthersWorkflowModalVisible
          }
        />
      )}
      {isconfirmationModalVisible && (
        <Confirmation
          onSubmit={(value) => {
            console.warn(value, "value from teh confirmation box");
            setIsconfirmationModalVisible(false);
            dispatch({
              type: "CHANGE_COUNTRY_STATUS_REQUEST",
              payload: {
                id: countryRecord.id,
                formData: { statusCode: value.confirmationText },
              },
            });
          }}
          isconfirmationModalVisible={isconfirmationModalVisible}
          setIsconfirmationModalVisible={setIsconfirmationModalVisible}
        />
      )}
    </OrganizationalSettings>
  );
};

export default Country;
