import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import firebase from "firebase";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB-z10HEjGMOtb7H63gvtVA7R5juGoAMuE",
  authDomain: "khrouch-crm.firebaseapp.com",
  projectId: "khrouch-crm",
  storageBucket: "khrouch-crm.appspot.com",
  messagingSenderId: "1019531795805",
  appId: "1:1019531795805:web:411cc1d65c48af52a39cb4",
  measurementId: "G-QL3WHPSB0T",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default app;
