import { Button, Card, Form, Input } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import OrganizationalSettings from "../OrganizationalSettings";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const CaseSettings = () => {
  const store = useSelector((state) => {
    return {
      profileState: state.ProfileReducer,
    };
  });
  useEffect(() => {
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
  }, []);
  const [form] = Form.useForm();

  const successFunction = (response) => {
    setCaseSettingsData((previousData) => {
      return {
        ...previousData,
        casePerfix: response?.data?.prefix?.casePrefix,
        caseAdditionalEmails: response?.data?.caseAdditionalEmails,
      };
    });
  };
  const [caseSettingsData, setCaseSettingsData] = useState({
    casePerfix: "",
    caseAdditionalEmails: [],
  });
  const dispatch = useDispatch();
  return (
    <OrganizationalSettings>
      <div>
        <h3>Case Settings</h3>
        <Card>
          <Form labelCol={{ span: 3 }}>
            <Form.Item label="Case Prefix">
              <Input
                value={caseSettingsData.casePerfix}
                placeholder="Enter the prefix you want to use"
                onChange={(e) => {
                  setCaseSettingsData((previousData) => {
                    return {
                      ...previousData,
                      casePerfix: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          </Form>
          {/* additional email start */}

          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              const formEmail = values?.users?.map((item) => {
                return {
                  email: item.email,
                  isLeads: false,
                  isCase: true,
                  isAppointment: false,
                  isCheckIn: false,
                };
              });
              dispatch({
                type: "ADDITIONAL_EMAILS_FROM_GENERAL_SETTINGS_REQUEST",
                payload: { additionalEmails: [...formEmail] },
              });
            }}
            style={{
              maxWidth: "100%",
            }}
            autoComplete="off"
            fields={caseSettingsData.caseAdditionalEmails?.map(
              (item, index) => {
                return { name: ["users", index, "email"], value: item.email };
              }
            )}

            // fields={[
            //   {
            //     name: ["users", 0, "email"],
            //     value: "anksdfjaksj@saklfd.com",
            //   },
            // ]}
          >
            <div
              style={{
                backgroundColor: "#eeeeee55",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                marginBottom: "0.4rem",
              }}
            >
              <h4>Additional Emails:</h4>
              <Form.List
                fields={[
                  {
                    name: "email",
                    value: "anksdfjaksj@saklfd.com",
                  },
                ]}
                name="users"
                initialValue={caseSettingsData.additionalEmails?.map(
                  (item, index) => {
                    return {
                      fieldKey: index,
                      isListField: true,
                      key: index,
                    };
                  }
                )}
              >
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                            width: "100%",
                            alignItems: "center",
                            gap: "0.3rem",
                          }}
                        >
                          <Form.Item
                            label="Additional Email"
                            labelCol={{ span: 4 }}
                            {...restField}
                            name={[name, "email"]}
                            style={{
                              width: "100%",
                              margin: 0,
                              padding: "0",
                            }}
                            rules={[
                              {
                                type: "email",
                                message: "Please enter a valid email",
                              },
                            ]}
                          >
                            <Input
                              type="email"
                              placeholder="e.g. username@gmail.com"
                            />
                          </Form.Item>

                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Additional Email
                        </Button>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
            </div>

            {/* additional email end */}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CASE_SETTINGS?.includes(
              "UPDATE"
            ) && (
              <Form.Item
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    dispatch({
                      type: "UPDATE_PREFIX_REQUEST",
                      payload: {
                        branchId:
                          store.branchState?.branchRecord.id ??
                          localStorage.getItem("branchId"),
                        casePrefix: caseSettingsData.casePerfix,
                      },
                    });
                  }}
                >
                  Save Changes
                </Button>
              </Form.Item>
            )}
          </Form>
        </Card>
      </div>
    </OrganizationalSettings>
  );
};

export default CaseSettings;
