import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  allQuickInquiry: {
    data: [],
    totalPage: 0,
    totalData: 0,
    pageNumber: 0,
    hasNext: 0,
  },
  pendingQuickInquiry: {
    data: [],
    totalPage: 0,
    totalData: 0,
    pageNumber: 0,
    hasNext: 0,
  },
  acceptedQuickInquiry: {
    data: [],
    totalPage: 0,
    totalData: 0,
    pageNumber: 0,
    hasNext: 0,
  },
  declinedQuickInquiry: {
    data: [],
    totalPage: 0,
    totalData: 0,
    pageNumber: 0,
    hasNext: 0,
  },
  quickInquiryById: {},
};

const QuickInquiryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_ALL_QUICK_INQUIRY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ALL_QUICK_INQUIRY_REQUEST_SUCCESS": {
      return {
        ...state,
        allQuickInquiry: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ALL_QUICK_INQUIRY_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_QUICK_INQUIRY_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_QUICK_INQUIRY_BY_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        quickInquiryById: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_QUICK_INQUIRY_BY_ID_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_PENDING_QUICK_INQUIRY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_PENDING_QUICK_INQUIRY_REQUEST_SUCCESS": {
      return {
        ...state,
        pendingQuickInquiry: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_PENDING_QUICK_INQUIRY_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ACCEPTED_QUICK_INQUIRY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ACCEPTED_QUICK_INQUIRY_REQUEST_SUCCESS": {
      return {
        ...state,
        acceptedQuickInquiry: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ACCEPTED_QUICK_INQUIRY_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_DECLINED_QUICK_INQUIRY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_DECLINED_QUICK_INQUIRY_REQUEST_SUCCESS": {
      return {
        ...state,
        declinedQuickInquiry: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_DECLINED_QUICK_INQUIRY_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ACCEPT_QUICK_INQUIRY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ACCEPT_QUICK_INQUIRY_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Inquiry accepted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ACCEPT_QUICK_INQUIRY_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to accept inquiry.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DECLINE_QUICK_INQUIRY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DECLINE_QUICK_INQUIRY_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Inquiry declined.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DECLINE_QUICK_INQUIRY_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to decline inquiry.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE__REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE__REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success!  deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE__REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete .");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE__STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE__STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success!  status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE__STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change  status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default QuickInquiryReducer;
