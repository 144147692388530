import { Button, Form, Input, Modal } from "antd";
import { debounce } from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getCookie } from "../../Helpers/FrontendHelper";
import axios from "axios";

export default function AddDocumentChecklist({
  handleGetDocumentChecklist,
  isAddModalVisible,
  setIsAddModalVisible,
}) {
  const dispatch = useDispatch();
  const [documentStatus, setDocumentStatus] = useState(false);
  const [form] = Form.useForm();

  const handleOk = () => {
    setIsAddModalVisible(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsAddModalVisible(false);
    form.resetFields();
  };

  const handleAddDocumentChecklist = (values) => {
    dispatch({
      type: "ADD_DOCUMENT_CHECKLIST_REQUEST",
      payload: { name: values?.documentName },
      isSuccessFn: true,
      successFunction: successFunction,
    });
  };

  const successFunction = () => {
    setIsAddModalVisible(false);
    form.resetFields();
    handleGetDocumentChecklist();
  };

  const handleOnChangeValue = (e) => {
    const tempData = e.target.value;
    tempData
      ? dispatch({
          type: "FETCH_CHECK_DOCUMENT_NAME_REQUEST",
          payload: tempData ? tempData.toUpperCase() : null,
          isSuccessFn: true,
          successFunction: (res) => {
            setDocumentStatus(res?.data);
          },
        })
      : setDocumentStatus(false);
  };
  const handleDebounceChange = debounce(handleOnChangeValue, 1000);

  return (
    <Modal
      title="Add Document Checklist"
      open={isAddModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <Form form={form} layout="vertical" onFinish={handleAddDocumentChecklist}>
        <Form.Item
          name="documentName"
          label="Document Name"
          rules={[
            {
              required: true,
              message: "Document name is required",
            },
            {
              validator: async (_, value) => {
                const token = getCookie("accessToken");
                const response = await axios.get(
                  `/v1/api/consultancy/employee/ifExists-document-checkList?documentName=${value?.toUpperCase()}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + token,
                    },
                  }
                );
                if (response?.data === true) {
                  return Promise.reject(
                    new Error("Entered document name already exists")
                  );
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input placeholder="Document name" />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Add Document Checklist
        </Button>
      </Form>
    </Modal>
  );
}
