import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

const AddLeadStatus = ({
  isAddLeadStatusModalVisible,
  setIsAddLeadStatusModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addLeadStatusSubmitHandler = (value) => {
    dispatch({
      type: "ADD_LEAD_STATUS_REQUEST",
      payload: { ...value, branchId: localStorage.getItem("branchId") },
    });
    form.resetFields();
    setIsAddLeadStatusModalVisible(false);
  };
  return (
    <Modal
      title="Add Lead Status"
      open={isAddLeadStatusModalVisible}
      onCancel={() => {
        setIsAddLeadStatusModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addLeadStatusSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Status"
          name={"statusName"}
          rules={[
            {
              required: true,
              message: "Please enter status!",
            },
          ]}
        >
          <Input placeholder="Status" />
        </Form.Item>
        <Form.Item
          label="Status Color Code"
          name={"statusColorCode"}
          rules={[
            {
              required: true,
              message: "Please enter status color code!",
            },
          ]}
        >
          <Input type="color" placeholder="Color code" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Lead Status
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddLeadStatus;
