import { Menu, Layout, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { Children } from "react";
import { GoDashboard } from "react-icons/go";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OrganizationalSettings from "../OrganizationalSettings";
const { Header, Sider, Content } = Layout;

const Email = () => {
  const orgnizationalSettingsState = useSelector(
    (state) => state.OrganizationalSettingsReducer
  );

  const [record, setRecord] = useState({});

  const dispatch = useDispatch();

  return (
    <OrganizationalSettings>
      <div>Coming Soon...</div>
    </OrganizationalSettings>
  );
};

export default Email;
