import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const AddDocumentTypes = ({
  isAddDocumentTypesModalVisible,
  setIsAddDocumentTypesModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addDocuementTypesSubmitHandler = (value) => {
    const formData = {
      ...value,
    };
    dispatch({
      type: "ADD_DOCUMENT_TYPE_REQUEST",
      payload: formData,
      isSuccessFn: true,
      successFunction: () => {
        dispatch({
          type: "FETCH_DOCUMENT_CHECKLIST_REQUEST",
          payload: {},
          isSuccessFn: true,
          successFunction: () => {},
        });
      },
    });
    // form.resetFields();
    setIsAddDocumentTypesModalVisible(false);
  };
  return (
    <Modal
      title="Add Document Type"
      open={isAddDocumentTypesModalVisible}
      onCancel={() => {
        setIsAddDocumentTypesModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addDocuementTypesSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Document Type"
          name={"documentType"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter document type!",
            },
          ]}
        >
          <Input placeholder="Document Type" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Document Type
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddDocumentTypes;
