import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  appointments: {},
  pendingAppointments: {},
  confirmedAppointments: {},
  completedAppointments: {},
  activeAppointments: {},
  timingDropdown: [],
  appointmentById: {},
  counsellorForAppointmentDate: [],
  workingDaysOfCounsellor: [],
};

const AppointmentsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_APPOINTMENTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_APPOINTMENTS_REQUEST_SUCCESS": {
      return {
        ...state,
        appointments: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_APPOINTMENTS_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_APPOINTMENT_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_APPOINTMENT_BY_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        appointmentById: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_APPOINTMENT_BY_ID_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_PENDING_APPOINTMENTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_PENDING_APPOINTMENTS_REQUEST_SUCCESS": {
      return {
        ...state,
        pendingAppointments: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_PENDING_APPOINTMENTS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load pending appointments.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_CONFIRMED_APPOINTMENTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_CONFIRMED_APPOINTMENTS_REQUEST_SUCCESS": {
      return {
        ...state,
        confirmedAppointments: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CONFIRMED_APPOINTMENTS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load confirmed appointments.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_COMPLETED_APPOINTMENTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_COMPLETED_APPOINTMENTS_REQUEST_SUCCESS": {
      return {
        ...state,
        completedAppointments: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_COMPLETED_APPOINTMENTS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load completed appointments.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_APPOINTMENTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ACTIVE_APPOINTMENTS_REQUEST_SUCCESS": {
      return {
        ...state,
        activeAppointments: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_APPOINTMENTS_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_APPOINTMENTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
        timingDropdown: [],
      };
    }
    case "ADD_APPOINTMENTS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! appointment added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_APPOINTMENTS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add appointment.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_APPOINTMENTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_APPOINTMENTS_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Appointment updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_APPOINTMENTS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update appointment.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_APPOINTMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_APPOINTMENT_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Appointment deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_APPOINTMENT_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to delete appointment.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_APPOINTMENT_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "CHANGE_APPOINTMENT_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Appointment status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_APPOINTMENT_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change appointment status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_TIMING_DROPDOWN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_TIMING_DROPDOWN_REQUEST_SUCCESS": {
      return {
        ...state,
        timingDropdown: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_TIMING_DROPDOWN_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Consultant is not available on this date!");
      return {
        ...state,
        timingDropdown: [],
        isLoading: false,
      };
    }
    case "FETCH_CONSULTANTS_BY_APPOINTMENT_DATE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_CONSULTANTS_BY_APPOINTMENT_DATE_REQUEST_SUCCESS": {
      action.payload?.length > 0
        ? message.success("Success! Consellor found for the appointment date.")
        : message.warning("Unable to find counsellor for the appointment date");

      return {
        ...state,
        counsellorForAppointmentDate: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CONSULTANTS_BY_APPOINTMENT_DATE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to Find counsellor.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CLEAR_AVAILABLE_DATE_AND_TIME": {
      return {
        ...state,
        timingDropdown: [],
        workingDaysOfCounsellor: [],
      };
    }
    case "ADD_APPOINTMENTS_REQUEST": {
      return {
        timingDropdown: [],
      };
    }

    case "FETCH_WORKING_DAYS_OF_COUNSELLOR_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_WORKING_DAYS_OF_COUNSELLOR_REQUEST_SUCCESS": {
      const allDays = [
        "SUNDAY",
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
      ];
      const nonWorkingDays = allDays?.filter((data) => {
        return !action?.payload?.includes(data);
      });
      return {
        ...state,
        workingDaysOfCounsellor: nonWorkingDays.map((data) => {
          if (data === "SUNDAY") {
            return 0;
          } else if (data === "MONDAY") {
            return 1;
          } else if (data === "TUESDAY") {
            return 2;
          } else if (data === "WEDNESDAY") {
            return 3;
          } else if (data === "THURSDAY") {
            return 4;
          } else if (data === "FRIDAY") {
            return 5;
          } else if (data === "SATURDAY") {
            return 6;
          }
        }),
        isLoading: false,
      };
    }
    case "FETCH_WORKING_DAYS_OF_COUNSELLOR_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default AppointmentsReducer;
