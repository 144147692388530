import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, Input, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import AddLeadSource from "../Leads/LeadSource/AddLeadSource";
import AddCampaign from "./AddCampaign";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../Helpers/FrontendHelper";
import axios from "axios";
import AddCountry from "../Leads/Country/AddCountry";
const { Option } = Select;

const AddContacts = ({
  fromSiderDemo,
  isAddContactVisible,
  setIsAddContactVisible,
  isHeadBranch,
  branchRecord,
  fromClient,
}) => {
  const addContactsState = useSelector((state) => state.LeadReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isAddSourceModalVisible, setIsAddSourceModalVisible] = useState(false);
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [isAddCampaignModalVisible, setIsAddCampaignModalVisible] =
    useState(false);
  const [contactData, setContactData] = useState({
    countryOfResidence: null,
  });
  const [contactImage, setContactImage] = useState(null);
  const fetchLeadsAfterAddCampaign = () => {
    dispatch({
      type: "FETCH_LEADS_DROPDOWN_REQUEST",
      payload: {
        fromInstituteVisaCase: true,
        visaSuccessFunction: contactSuccessFunction,
        branchId: branchRecord?.id ?? localStorage.getItem("branchId"),
      },
    });
  };

  const [form] = Form.useForm();
  useEffect(() => {
    fetchLeadsAfterAddCampaign();
  }, []);
  const uploadPhotoFn = (response) => {
    const contactFormData = new FormData();
    contactFormData.append("image", contactImage);
    contactImage !== undefined &&
      contactImage !== null &&
      dispatch({
        type: "UPLOAD_CONTACT_IMAGE_REQUEST",
        payload: {
          id: response.id,
          contactFormData,
          branchId: branchRecord?.id ?? localStorage.getItem("branchId"),
        },
      });
    setContactImage(null);
  };
  const contactSuccessFunction = (response) => {
    setContactData((previousData) => {
      return {
        ...previousData,

        countryOfResidence: response?.data?.defaultCountry?.name,
      };
    });
  };
  const addContactSubmitHandler = (values) => {
    if (fromSiderDemo) {
    }
    dispatch({
      type: "ADD_CONTACT_REQUEST",
      payload: {
        branchId: branchRecord?.id ?? localStorage.getItem("branchId"),
        formData: {
          ...values,
          email: values.email === "" ? null : values.email,
          phoneNumber: values.phoneNumber === "" ? null : values.phoneNumber,
          branchId: branchRecord?.id ?? localStorage.getItem("branchId"),
        },
      },
      payload2: uploadPhotoFn,
    });
    form.resetFields();
    setIsAddContactVisible(false);
    fetchLeadsAfterAddCampaign();
    if (fromSiderDemo) {
      navigate("/contacts");
    }
  };
  return (
    <Drawer
      title="Add Contact"
      open={isAddContactVisible}
      onClose={() => {
        setIsAddContactVisible(false);
        form.resetFields();
      }}
      width={1163}
      footer={null}
    >
      <>
        <Form
          layout="vertical"
          onFinish={addContactSubmitHandler}
          colon={true}
          form={form}
          className="drawerStyle"
          fields={[
            {
              name: ["countryOfResidence"],
              value: contactData.countryOfResidence,
            },
          ]}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="First Name"
              name={"firstName"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter first name!",
                },
              ]}
            >
              <Input placeholder="Please enter first name!" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name={"lastName"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter last name!",
                },
              ]}
            >
              <Input placeholder="Please enter last name!" />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Email"
              name={"email"}
              style={{ width: "100%" }}
              rules={[
                {
                  type: "email",
                  message: "Please enter email!",
                },
                {
                  validator: async (_, value) => {
                    const token = getCookie("accessToken");
                    const response = await axios.get(
                      // `${
                      //   process.env.REACT_APP_TEST_URL
                      // }v2/api/client/branch/customer/validate-email/${localStorage.getItem(
                      //   "branchId"
                      // )}?email=${value}`,
                      `v2/api/client/branch/customer/validate-email/${localStorage.getItem(
                        "branchId"
                      )}?email=${value}`,
                      {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + token,
                        },
                      }
                    );
                    if (
                      response?.data === false ||
                      value === "" ||
                      value === null ||
                      value === undefined
                    ) {
                      return Promise.resolve();
                    } else if (response?.data === true) {
                      return Promise.reject(
                        new Error("Entered email already exists")
                      );
                    }
                  },
                },
              ]}
              validateTrigger={["onBlur"]}
            >
              <Input type="email" placeholder="Please enter Email!" />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name={"phoneNumber"}
              style={{ width: "100%" }}
              rules={[
                {
                  pattern: /^[\d]{9,11}$/,
                  message: "Phone number should contain 9 to 11 numbers",
                },
                {
                  validator: async (_, value) => {
                    const token = getCookie("accessToken");
                    const response = await axios.get(
                      // `${
                      //   process.env.REACT_APP_TEST_URL
                      // }v2/api/client/branch/customer/validate-mobile/${localStorage.getItem(
                      //   "branchId"
                      // )}?mobile=${value}`,
                      `v2/api/client/branch/customer/validate-mobile/${localStorage.getItem(
                        "branchId"
                      )}?mobile=${value}`,
                      {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + token,
                        },
                      }
                    );
                    if (
                      response?.data === false ||
                      value === "" ||
                      value === null ||
                      value === undefined
                    ) {
                      return Promise.resolve();
                    } else if (response?.data === true) {
                      return Promise.reject(
                        new Error("Entered mobile number already exists")
                      );
                    }
                  },
                },
              ]}
              validateTrigger={["onBlur"]}
            >
              <Input type="number" placeholder="Please enter Phone Number!" />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                label="Source"
                name={"source"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select a source!",
                  },
                ]}
              >
                <Select>
                  {addContactsState.leadsDropdown?.leadSource?.map(
                    (dataObj) => {
                      return (
                        <Option key={dataObj.id}>{dataObj.leadSource}</Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
              <a
                className="plusButton"
                onClick={() => {
                  setIsAddSourceModalVisible(true);
                }}
              >
                <AiOutlinePlus className="iconColor" />
              </a>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                label="Campaign"
                name={"campaign"}
                style={{ width: "100%" }}
                getValueProps={(e) => {}}
                rules={[
                  {
                    required: fromClient !== true,
                    message: "Please enter campaign!",
                  },
                ]}
              >
                <Select>
                  {addContactsState.leadsDropdown?.campaign?.map((dataObj) => {
                    return (
                      <Option key={dataObj.id}>{dataObj.campaignName}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <a
                className="plusButton"
                onClick={() => {
                  setIsAddCampaignModalVisible(true);
                }}
              >
                <AiOutlinePlus className="iconColor" />
              </a>
            </div>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                label="Country of Residence"
                name={"countryOfResidence"}
                style={{ width: "100%" }}
              >
                <Select
                  optionFilterProp="children"
                  showSearch
                  filterOption={(input, option) => {
                    return (option?.children?.props?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {addContactsState?.leadsDropdown?.countries?.map(
                    (dataObj) => {
                      return (
                        <Option key={dataObj.id} value={dataObj.countryName}>
                          <div
                            onClick={() => {
                              setContactData((previousData) => {
                                return {
                                  ...previousData,
                                  countryOfResidence: dataObj.countryName,
                                };
                              });
                            }}
                            className={"selectOptionDiv"}
                          >
                            {dataObj.countryName}
                          </div>
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
              <a
                className="plusButton"
                onClick={() => {
                  setIsAddCountryModalVisible(true);
                }}
              >
                <AiOutlinePlus className="iconColor" />
              </a>
            </div>
            <Form.Item name="image" label="Image" style={{ width: "100%" }}>
              <Input
                type="file"
                onChange={(e) => {
                  setContactImage(e.target.files[0]);
                }}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="contactSubmit"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    (getFieldValue("email") === undefined ||
                      getFieldValue("email") === "" ||
                      getFieldValue("email") === null) &&
                    (getFieldValue("phoneNumber") === undefined ||
                      getFieldValue("phoneNumber") === "" ||
                      getFieldValue("phoneNumber") === null)
                  ) {
                    return Promise.reject(
                      new Error("Please enter either email or phone number!")
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            hasFeedback
          >
            <Button name="contactSubmit" block type="primary" htmlType="submit">
              Add Contact
            </Button>
          </Form.Item>
        </Form>
        {isAddSourceModalVisible && (
          <AddLeadSource
            branchRecord={branchRecord}
            isAddLeadSourceModalVisible={isAddSourceModalVisible}
            setIsAddLeadSourceModalVisible={setIsAddSourceModalVisible}
          />
        )}
        {isAddCampaignModalVisible && (
          <AddCampaign
            branchRecord={branchRecord}
            isAddCampaignModalVisible={isAddCampaignModalVisible}
            setIsAddCampaignModalVisible={setIsAddCampaignModalVisible}
          />
        )}
        {isAddCountryModalVisible && (
          <AddCountry
            isAddCountryModalVisible={isAddCountryModalVisible}
            setIsAddCountryModalVisible={setIsAddCountryModalVisible}
          />
        )}
      </>
    </Drawer>
  );
};

export default AddContacts;
