import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Tooltip,
} from "antd";
import Webcam from "react-webcam";
import moment from "moment/moment";
// import atob from "atob";
import { useDispatch } from "react-redux";
import axios from "axios";
import { getCookie } from "../../Helpers/FrontendHelper";
import testImage from "./../../Assets/profile.png";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { date00 } from "../../HelperFunction";
const { Option } = Select;
const { TextArea } = Input;

const CheckOut = ({
  checkOutId,
  isCheckOutModalVisible,
  setIsCheckOutModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      CheckInState: state.AttendanceReducer,
    };
  });

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [capturedImage, setCapturedImage] = useState();
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState(null);

  useEffect(() => {
    dispatch({
      type: "FETCH_TODAY_ASSIGNED_TASK_REQUEST",
      payload: { date: date00() },
    });
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // setLocation(
        //   getAddressFromLatLng(
        //     position.coords.latitude,
        //     position.coords.longitude
        //   )
        // );
        getAddressFromLatLng(
          position.coords.latitude,
          position.coords.longitude
        ).then((e) => {
          setLocation(e.formatted_address);
        });
      },
      (error) => {}
    );
  }, []);
  async function getAddressFromLatLng(lat, lng) {
    const API_KEY = "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU"; // Replace with your API key
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`;
    const response = await axios.get(url);
    const address = response.data.results[0];
    return address;
  }

  const CheckOutSubmitHandler = (value) => {
    // const [, mime, base64] = capturedImage.match(/^data:(.+);base64,(.+)$/);
    // const binaryData = atob(capturedImage);
    // const blob = new Blob(
    //   [new Uint8Array(Array.from(binaryData).map((c) => c.charCodeAt(0)))],
    //   { type: mime }
    // );
    // const file = new File([blob], "image.webp", { type: mime });
    // your base64 image

    // Converting binary string to Uint8Array
    const byteCharacters = window.atob(capturedImage.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });
    // const blob = new Blob([capturedImage], { type: "image/png" });
    // const formImage = new File([blob], "image.png", { type: "image/png" });
    // console.lo g(formImage, "file");
    const CheckOutFormData = new FormData();
    CheckOutFormData.append("checkOutDescription", value.description);
    CheckOutFormData.append("checkOutLocation", location);
    capturedImage !== null && CheckOutFormData.append("image", blob);
    const formData = {
      ...value,
    };
    // axios.post(
    //   "http://192.168.10.111:8090/khrouch/v1/api/users/CheckOut",
    //   CheckOutFormData,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${getCookie("accessToken")}`,
    //     },
    //   }
    // );
    // return;
    dispatch({
      type: "CHECK_OUT_REQUEST",
      payload: { CheckOutFormData, id: checkOutId },
    });
    // form.resetFields();

    setIsCheckOutModalVisible(false);
  };
  const videoConstraints = {
    width: 160,
    height: 90,
    facingMode: "user",
  };
  return (
    <Modal
      title={`Check Out:- ${moment().local().format("ddd")}, ${moment()
        .local()
        .format("Do MMMM YYYY, h:mm:ss a")}`}
      open={isCheckOutModalVisible}
      onCancel={() => {
        setIsCheckOutModalVisible(false);
      }}
      footer={null}
      width={640}
    >
      <Form
        layout="vertical"
        onFinish={CheckOutSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["description"],
            value: description,
          },
        ]}
      >
        <div style={{ display: "flex" }}>
          <Form.Item>
            <Webcam
              audio={false}
              height={160}
              screenshotFormat="image/png"
              width={300}
              videoConstraints={videoConstraints}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {({ getScreenshot }) => (
                <Button
                  type="primary"
                  onClick={(e) => {
                    const imageSrc = getScreenshot();
                    if (imageSrc) {
                      setCapturedImage(imageSrc);
                    }
                  }}
                  style={{ margin: "0.5rem" }}
                >
                  Take Snapshot
                </Button>
              )}
            </Webcam>
          </Form.Item>
          <img
            src={capturedImage ? capturedImage : null}
            height={160}
            width={300}
          />
        </div>
        <div>
          {store.CheckInState.taskAssignedToday?.data?.length > 0 && (
            <>
              <div style={{ margin: "0.3rem" }}>Assigned Tasks:</div>
              {store.CheckInState.taskAssignedToday?.data?.map((dataObj) => {
                return (
                  <Tooltip key={dataObj.id} title={dataObj.taskDescription}>
                    <Checkbox
                      style={{ margin: "0.3rem" }}
                      onChange={(e) => {
                        // setCheckedTask(dataObj.taskTitle);

                        e.target.checked === true &&
                          setDescription(description + dataObj.taskTitle);
                      }}
                    >
                      {dataObj.taskTitle}
                    </Checkbox>
                  </Tooltip>
                );
              })}
            </>
          )}
          {/* <Checkbox
            style={{ margin: "0.3rem" }}
            onChange={() => {
              // setCheckedTask(dataObj.taskTitle);
              setDescription(description + " dataObj.taskTitle");
            }}
          >
            dataObj.taskTitle
          </Checkbox> */}
        </div>
        <Form.Item
          label="Update"
          name={"description"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter today's update!",
            },
          ]}
        >
          <TextArea
            placeholder="Today's Update"
            cols={100}
            autoSize={{
              minRows: 8,
              maxRows: 12,
            }}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Form.Item>

        {/* <Form.Item
          label="Snap Image"
          name={"image"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter snap image!",
            },
          ]}
        >
          <Input type="file" />
        </Form.Item> */}

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Check Out
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CheckOut;
