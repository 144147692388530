import React, { useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import { useDispatch } from "react-redux";

const AddCourseCommission = ({
  commissionType,
  isAsignModelVisible,
  setIsAsignModelVisible,
  isUpdate,
  record,
  fetchCourseDetail,
}) => {
  const dispatch = useDispatch();

  const handleSubmitCourseCommission = (values) => {
    dispatch({
      type: "ADD_COMMISSION_REQUEST",
      payload: {
        id: record?.id,
        offshoreCommission:
          commissionType === "onshore" ? 0 : values?.offshoreCommission,
        onshoreCommission:
          commissionType === "offShore" ? 0 : values?.onshoreCommission,
      },
      isSuccessFn: true,
      successFunction: () => {
        setIsAsignModelVisible(false);
        fetchCourseDetail();
      },
    });
  };

  return (
    <Modal
      title={`${isUpdate ? "Edit" : "Add"} Course Commission`}
      open={isAsignModelVisible}
      onCancel={() => {
        setIsAsignModelVisible(false);
      }}
      footer={null}
    >
      <Form
        initialValues={{
          offshoreCommission: record?.offshoreCommission,
          onshoreCommission: record?.onshoreCommission,
        }}
        layout="vertical"
        onFinish={handleSubmitCourseCommission}
      >
        {(commissionType === "both" || commissionType === "offshore") && (
          <Form.Item
            label="Offshore Commission"
            name={"offshoreCommission"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter offshore commission",
              },
            ]}
          >
            <Input min={0} type="number" placeholder="Offshore Commission" />
          </Form.Item>
        )}
        {(commissionType === "both" || commissionType === "onshore") && (
          <Form.Item
            label="Onshore Commission"
            name={"onshoreCommission"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter onshore commission",
              },
            ]}
          >
            <Input min={0} type="number" placeholder="Onshore Commission" />
          </Form.Item>
        )}
        <Button block type="primary" htmlType="submit">
          {`${isUpdate ? "Edit" : "Add"} Course Commission`}
        </Button>
      </Form>
    </Modal>
  );
};

export default AddCourseCommission;
