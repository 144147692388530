import {
  Input,
  Radio,
  Form,
  Descriptions,
  Select,
  Checkbox,
  Button,
  DatePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { scrollToTopFormError } from "../../../HelperFunction";

export default function UpdateClientVisaInfo({
  setCurrent,
  caseFormData,
  setCaseFormData,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
    };
  });
  // const []

  console.log("record", caseFormData, store.caseState?.instituteDegree);
  const [traverseStatus, setTraverseStatus] = useState("");
  const [courseList, setCoureList] = useState([]);
  const [intakeList, setIntakeList] = useState([]);
  const [workflowList, setWorkflowList] = useState([]);
  const [workflowStatusList, setWorkflowStatusList] = useState([]);
  const [instituteList, setInstituteList] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [visaSubclassList, setVisaSubclassList] = useState([]);
  const [payFor, setPayFor] = useState(
    caseFormData?.wantToPayFor !== "SEMESTER" &&
      caseFormData?.wantToPayFor !== "TRIMESTER" &&
      caseFormData?.wantToPayFor !== "YEARLY"
      ? "OTHERS"
      : caseFormData?.wantToPayFor
  );

  console.log("testing", caseFormData);

  useEffect(() => {
    if (caseFormData?.visaType === "EDUCATION") {
      fetchEducationVisaSubClassRecord();
    } else {
      fetchMigrationVisaSubclassRecord();
    }
    fetchCountryWorkflowList(caseFormData?.countryId);

    fetchWorkFlowStatusList(caseFormData?.workflowId);
  }, []);

  const fetchCountryWorkflowList = (countryId) => {
    dispatch({
      type: "FETCH_COUNTRY_WORKFLOW_BY_ID_REQUEST",
      payload: {
        id: countryId,
        visaCategory: caseFormData?.visaType,
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setWorkflowList(res?.data?.data);
      },
    });
  };

  const fetchWorkFlowStatusList = (workflowId) => {
    dispatch({
      type: "FETCH_WORKFLOW_STATUSES_BY_WORKFLOW_ID_REQUEST",
      payload: { id: workflowId },
      isSuccessFn: true,
      successFunction: (res) => {
        setWorkflowStatusList(res?.data?.data);
      },
    });
  };

  const fetchEducationVisaSubClassRecord = () => {
    dispatch({
      type: "FETCH_EDUCATION_VISAS_REQUEST",
      payload: {
        page: 1,
        pageSize: 100,
        branchId: localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setVisaSubclassList(res?.data?.data);
      },
    });
  };

  const fetchMigrationVisaSubclassRecord = () => {
    dispatch({
      type: "FETCH_MIGRATION_VISAS_REQUEST",
      payload: {
        page: 1,
        pageSize: 100,
        branchId: localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setVisaSubclassList(res?.data?.data);
      },
    });
  };

  useEffect(() => {
    // dispatch({
    //   type: "FETCH_CASES_DROPDOWN_REQUEST",
    //   payload: {
    //     branchId: localStorage.getItem("branchId"),
    //   },
    // });

    dispatch({
      type: "FETCH_INSTITUTE_BASED_ON_LOCATION_AND_COUNTRY_REQUEST",
      payload: {
        location: caseFormData?.currentStatus ? "OFFSHORE" : "ONSHORE",
        countryId: caseFormData?.countryId,
      },
    });

    fetchDegreeByInstituteId(caseFormData?.instituteId);
    fetchInstituteByCountry(caseFormData?.country);
    caseFormData?.degreeId && fetchCourseList();
  }, []);

  const fetchDegreeByInstituteId = (id) => {
    dispatch({
      type: "FETCH_DEGREE_BY_INSTITUTE_ID_REQUEST",
      payload: id,
      isSuccessFn: true,
      successFunction: ({ data }) => {},
    });
  };

  const fetchInstituteByCountry = (country) => {
    dispatch({
      type: "FETCH_ALL_COLLEGE_REQUEST",
      payload: {
        selectedCountry: country,
        selectedDegree: "Select",
        selectedCourse: "Select",
        selectedIntake: "Select",
        branchId: localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setInstituteList(res?.data?.data);
      },
    });
  };

  const fetchCourseList = () => {
    dispatch({
      type: "FETCH_COURSESLIST_BY_INSTITUTE_AND_DEGREE_REQUEST",
      payload: {
        instituteId: caseFormData?.instituteId,
        degreeId: caseFormData?.degreeId,
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setCoureList(res?.data?.data);
        setIntakeList(
          res?.data?.data?.filter(
            (course) => course?.name === caseFormData?.courseName
          )[0].collegeIntakeResponseList
        );
      },
    });
  };

  const handleSubmitVisaInfo = (values) => {
    delete values?.isScholershipAllowed;
    setCaseFormData((prev) => {
      return {
        ...prev,
        ...values,
        wantToPayFor:
          values?.wantToPayFor === "OTHERS"
            ? values?.wantToPayDuration
            : values?.wantToPayFor,
      };
    });
    traverseStatus === "next" && setCurrent((prev) => prev + 1);
    traverseStatus === "back" && setCurrent((prev) => prev - 1);
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmitVisaInfo}
      onFinishFailed={scrollToTopFormError}
      layout="vertical"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "1rem",
        margin: "2rem 2rem 0",
      }}
      initialValues={{
        visaType: caseFormData?.visaType,
        visaSubClass: caseFormData?.visaSubClass,
        country: caseFormData?.country,
        currentStatus: caseFormData?.currentStatus,
        instituteName: caseFormData?.instituteName,
        degreeName: caseFormData?.degreeName,
        courseName: caseFormData?.courseName,
        intakeName: caseFormData?.intakeName,
        isScholershipAllowed: caseFormData?.isScholershipAllowed,
        scholarShipAmount: caseFormData?.scholarShipAmount,
        scholarShipType: caseFormData?.scholarShipType,
        wantToPayFor:
          caseFormData?.wantToPayFor !== "SEMESTER" &&
          caseFormData?.wantToPayFor !== "TRIMESTER" &&
          caseFormData?.wantToPayFor !== "YEARLY"
            ? "OTHERS"
            : caseFormData?.wantToPayFor,
        wantToPayDuration:
          caseFormData?.wantToPayFor !== "SEMESTER" &&
          caseFormData?.wantToPayFor !== "TRIMESTER" &&
          caseFormData?.wantToPayFor !== "YEARLY"
            ? caseFormData?.wantToPayFor
            : null,
        fee: caseFormData?.fee,
        workflowId: caseFormData?.workflowId,
        workflowName: caseFormData?.workflowName,
        workflowStatusName: caseFormData?.workflowStatusName,
      }}
    >
      <Form.Item
        label="Country"
        name="country"
        rules={[
          {
            required: true,
            message: "Please enter country!",
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Select Country"
          onChange={(value, option) => {
            setCaseFormData((prev) => {
              return { ...prev, countryId: option?.key };
            });
            fetchInstituteByCountry(value);
            fetchCountryWorkflowList(option?.key);
            fetchWorkFlowStatusList(caseFormData?.workflowId);
          }}
        >
          {store.caseState.caseDropdown?.country?.map((dataObj) => {
            return (
              <Option
                key={dataObj.id}
                value={dataObj.name}
                onClick={() => {
                  dispatch({
                    type: "FETCH_COUNTRY_WORKFLOW_BY_ID_REQUEST",
                    payload: {
                      id: dataObj.id,
                      visaCategory: caseFormData.visaType,
                    },
                  });
                  dispatch({
                    type: "CLEAR_INSTITUTE_SEARCH_DROPDOWN",
                  });
                }}
              >
                {dataObj.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Application Type"
        name="currentStatus"
        rules={[
          {
            required: true,
            message: "Please enter current status!",
          },
        ]}
      >
        <Radio.Group>
          <Radio value={false}>Onshore</Radio>
          <Radio value={true}>Offshore</Radio>
        </Radio.Group>
      </Form.Item>
      <span style={{ height: "fit-content" }}>
        Visa Type: {caseFormData?.visaType}{" "}
      </span>
      {/* <Descriptions>
        <Descriptions.Item style={{ margin: "0" }} label="Visa Type">
          {caseFormData?.visaType}
        </Descriptions.Item>
      </Descriptions> */}
      <Form.Item
        label="Visa Sub Category"
        name="visaSubClass"
        rules={[
          {
            required: true,
            message: "Please enter visa sub category!",
          },
        ]}
      >
        {caseFormData.visaType === "EDUCATION" ? (
          <Select>
            {visaSubclassList
              ?.filter(
                (data) => data?.country === form.getFieldValue("country")
              )
              ?.map((dataObj) => {
                return <Option key={dataObj.id}>{dataObj?.subClass}</Option>;
              })}
          </Select>
        ) : caseFormData.visaType === "MIGRATION" ? (
          <Select
            onChange={(value, option) => {
              setCaseFormData((prev) => {
                return {
                  ...prev,
                  visaSubClassId: option?.key,
                };
              });
            }}
          >
            {visaSubclassList
              ?.filter(
                (data) => data?.country === form.getFieldValue("country")
              )
              ?.map((dataObj) => {
                return <Option key={dataObj.id}>{dataObj.subClass}</Option>;
              })}
          </Select>
        ) : null}
      </Form.Item>

      <Form.Item
        label="Work Flow"
        name="workflowName"
        rules={[
          {
            required: true,
            message: "Please enter workflow!",
          },
        ]}
      >
        <Select
          onChange={(value, option) => {
            const tempObj = JSON.parse(option?.key);
            setCaseFormData((prev) => {
              return {
                ...prev,
                workflowId: tempObj?.id,
              };
            });

            fetchWorkFlowStatusList(tempObj?.id);
          }}
        >
          {workflowList?.map((dataObj) => {
            return (
              <Option key={JSON.stringify(dataObj)} value={dataObj?.statusName}>
                {dataObj.statusName}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="Work Flow Status"
        name="workflowStatusName"
        rules={[
          {
            required: true,
            message: "Please enter current status!",
          },
        ]}
      >
        <Select
          onChange={(value, option) => {
            setCaseFormData((prev) => {
              return {
                ...prev,
                workflowStatusId: option?.key,
              };
            });
          }}
        >
          {workflowStatusList?.map((dataObj) => {
            return (
              <Option key={dataObj.id} value={dataObj?.statusName}>
                {dataObj.statusName}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      {caseFormData?.visaType === "EDUCATION" && (
        <Form.Item name={"searchInstitute"} style={{ gridColumn: "span 2" }}>
          <Select
            showSearch
            placeholder={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <AiOutlineSearch fontSize={20} />
                Search institutes
              </div>
            }
            style={{
              width: "100%",
              borderRadius: "25px",
            }}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={(e) => {
              dispatch({
                type: "SEARCH_INSTITUTE_FOR_COURSES_REQUEST",
                payload: {
                  keyword: e,
                  shore: form.getFieldValue("currentStatus")
                    ? "OFFSHORE"
                    : "ONSHORE",
                  countryId: caseFormData?.countryId,
                  branchId: localStorage.getItem("branchId"),
                },
              });
            }}
            notFoundContent={null}
            disabled={!form.getFieldValue("country")}
          >
            {store.caseState.searchedInstitutes?.map((dataObj) => {
              return (
                <Option key={dataObj.id}>
                  <div
                    onClick={() => {
                      console.log("testing", dataObj);
                      setCaseFormData((prev) => {
                        return {
                          ...prev,
                          instituteId: dataObj?.institute?.id,
                          degreeId: dataObj?.degree?.id,
                          courseId: dataObj?.id,
                        };
                      });
                      form?.setFieldsValue({
                        instituteName: dataObj?.institute?.name,
                        degreeName: dataObj?.degree?.name,
                        courseName: dataObj?.name,
                        courseFee: form.getFieldValue("currentStatus")
                          ? dataObj?.offShoreFee
                          : dataObj?.onShoreFee,
                      });
                    }}
                  >
                    {`${dataObj.institute?.name} (Course: ${
                      dataObj?.name
                    }) (Degree: ${
                      dataObj.degree?.name
                    }) (Intake: ${dataObj?.collegeIntakeResponseList?.map(
                      (dataObj, index) =>
                        `${index === 0 ? "" : ","}${dataObj.intakeSessionName}`
                    )}
                        )`}
                  </div>
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      )}

      {caseFormData?.visaType === "EDUCATION" && (
        <div
          style={{
            gridColumn: "span 2",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
          }}
        >
          <Form.Item
            label="Institute"
            name="instituteName"
            rules={[
              {
                required: true,
                message: "Please enter a institute!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select an Institute"
              onChange={(value, option) => {
                setCaseFormData((prev) => {
                  return {
                    ...prev,
                    instituteId: option?.key,
                  };
                });
                form.setFieldsValue({
                  degreeName: null,
                  courseName: null,
                  intakeName: null,
                });

                // dispatch({
                //   type: "FETCH_DEGREE_BY_INSTITUTE_REQUEST",
                //   payload: {
                //     instituteId: option.key,
                //   },
                //   isSuccessFn: true,
                //   successFunction: ({ data }) => {},
                // });
                fetchDegreeByInstituteId(option?.key);
              }}
            >
              {/* {store.caseState.filterInstitutes?.map((dataObj) => { */}
              {instituteList?.map((dataObj) => {
                return (
                  <Option key={dataObj.id} value={dataObj.instituteName}>
                    {dataObj?.instituteName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Degree"
            name="degreeName"
            rules={[
              {
                required: true,
                message: "Please enter a Degree!",
              },
            ]}
          >
            <Select
              placeholder="Select Degree"
              onChange={(value, option) => {
                setCaseFormData((prev) => {
                  return {
                    ...prev,
                    degreeId: option?.key,
                  };
                });

                dispatch({
                  type: "FETCH_COURSESLIST_BY_INSTITUTE_AND_DEGREE_REQUEST",
                  payload: {
                    instituteId: caseFormData?.instituteId,
                    degreeId: option?.key,
                  },
                  isSuccessFn: true,
                  successFunction: (res) => {
                    setCoureList(res?.data?.data);
                  },
                });
                form.setFieldsValue({
                  courseName: null,
                  intakeName: null,
                });
              }}
            >
              {store.caseState?.instituteDegree?.data?.map((dataObj) => {
                return <Option key={dataObj.id}>{dataObj.degreeName}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Course"
            name="courseName"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a Course!",
              },
            ]}
          >
            <Select
              onChange={(value, option) => {
                const dataObj = JSON.parse(option?.key);
                setCaseFormData((prev) => ({ ...prev, courseId: dataObj?.id }));
                form.setFieldsValue({
                  intakeName: null,
                });
              }}
            >
              {courseList?.map((dataObj) => {
                return (
                  <Option key={JSON.stringify(dataObj)} value={dataObj?.name}>
                    {dataObj.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Intake"
            name="intakeName"
            rules={[
              {
                required: true,
                message: "Please enter a Intake!",
              },
            ]}
          >
            <Select
              onChange={(value, option) => {
                setCaseFormData((prev) => {
                  return {
                    ...prev,
                    intakeId: option?.key,
                  };
                });
              }}
            >
              {intakeList?.map((intake) => (
                <Option key={intake?.id} value={intake?.intakeSessionName}>
                  {intake?.intakeSessionName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="isScholershipAllowed"
            style={{
              gridColumn: caseFormData?.isScholershipAllowed
                ? "span 1"
                : "span 2",
            }}
          >
            <Checkbox
              defaultChecked={caseFormData?.isScholershipAllowed}
              onChange={(e) => {
                setCaseFormData((prev) => {
                  return {
                    ...prev,
                    isScholershipAllowed: e.target?.checked,
                  };
                });
              }}
            >
              Is Scholarship Allowed?
            </Checkbox>
          </Form.Item>
          {caseFormData?.isScholershipAllowed && (
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "0.5rem",
              }}
            >
              <Form.Item label="Scholarship amount" name="scholarShipAmount">
                <Input type="number" placeholder="Scholorship" />
              </Form.Item>
              <Form.Item name="scholarShipType">
                <Radio.Group
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Radio value={true}>is Flat</Radio>
                  <Radio value={false}>is Percentage</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          )}
          <div>
            {" "}
            <Form.Item
              label="Student want to pay for"
              name="wantToPayFor"
              rules={[
                {
                  required: true,
                  message:
                    "Please select the option which student want to pay for!",
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => {
                  setPayFor(e.target.value);
                }}
              >
                <Radio value={"SEMESTER"}>One Semester</Radio>
                <Radio value={"TRIMESTER"}>Tri-semester</Radio>
                <Radio value={"YEARLY"}>One Year</Radio>s
                <Radio value={"OTHERS"}>Others</Radio>
              </Radio.Group>
            </Form.Item>
            {payFor === "OTHERS" && (
              <Form.Item
                name={"wantToPayDuration"}
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter the duration that student want to pay for!",
                  },
                ]}
              >
                <Input placeholder="Wants to pay for" />
              </Form.Item>
            )}
          </div>
          <Form.Item label="Student Paying Fee" name="fee">
            <Input type="number" />
          </Form.Item>
        </div>
      )}

      <Form.Item label="Commence Date" name={"commenceDate"}>
        <DatePicker
          format={"DD-MM-YYYY"}
          onChange={(e) => {
            // console.log(e);
            // setVisaCaseData((previousData) => {
            //   return {
            //     ...previousData,
            //     commenceDate: e,
            //   };
            // });
          }}
        />
      </Form.Item>

      <div
        style={{
          gridColumn: "span 2",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={() => {
            setTraverseStatus("back");
          }}
          type="outline"
          htmlType="submit"
        >
          Back
        </Button>
        <Button
          onClick={() => {
            setTraverseStatus("next");
          }}
          type="primary"
          htmlType="submit"
        >
          Next
        </Button>
      </div>
    </Form>
  );
}
