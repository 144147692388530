import React, { useState, useEffect } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { BsRecord } from "react-icons/bs";
const { Option } = Select;

const AddDocumentChecklist = ({
  getActiveCourse,
  getAllCourse,
  courseType,
  record,
  setRecord,
  isOnshore,
  document,
  isAddDocumentChecklistModalVisible,
  setIsAddDocumentChecklistModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const store = useSelector((state) => {
    return { documentChecklistState: state?.DocumentCheckListReducer };
  });

  useEffect(() => {
    handleGetDocumentChecklist();
  }, []);

  const handleGetDocumentChecklist = () => {
    dispatch({
      type: "FETCH_DOCUMENT_CHECKLIST_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  const addDocumentChecklistSubmitHandler = (value) => {
    const formData = {
      ...value,
      documentType: value.item,
    };
    const type = isOnshore === true ? "onshore" : "offshore";
    dispatch({
      type: "ADD_COURSES_DOCUMENT_CHECKLIST_REQUEST",
      payload: { id: record.id, type, formData: { ...formData, isOnshore } },
      isSuccessFn: true,
      successFunction: () => {
        setRecord("");
        courseType === "get-all-courses" ? getAllCourse() : getActiveCourse();
      },
    });
    form.resetFields();
    setIsAddDocumentChecklistModalVisible(false);
  };

  return (
    <Modal
      title="Add Document Checklist"
      open={isAddDocumentChecklistModalVisible}
      onCancel={() => {
        setIsAddDocumentChecklistModalVisible(false);
        setRecord("");
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addDocumentChecklistSubmitHandler}
        colon={true}
        form={form}
        initialValues={{
          item: document?.map((data) => data?.item),
        }}
      >
        <Form.Item
          label="Document Name"
          name={"item"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a item!",
            },
          ]}
        >
          {/* <Select showSearch placeholder="Select Document">
            {store?.documentChecklistState?.documentCheclistData?.data?.map(
              (item) => (
                <Select.Option value={item?.name}>{item?.name}</Select.Option>
              )
            )}
          </Select> */}
          <Checkbox.Group
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
            options={store?.documentChecklistState?.documentCheclistData?.data?.map(
              (data) => {
                return {
                  label: data?.name,
                  value: data?.name,
                };
              }
            )}
          />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Document Checklist
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddDocumentChecklist;
