import {
  Card,
  Divider,
  Drawer,
  Image,
  Modal,
  Input,
  Tooltip,
  Checkbox,
  Button,
  Tag,
} from "antd";
import React, { useEffect } from "react";
import {
  formattedDate,
  formattedDateTime,
  twoTagResponses,
} from "../../HelperFunction";
import client from "../../Assets/client.png";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { FcDocument } from "react-icons/fc";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ImCheckboxChecked } from "react-icons/im";
import SendComposedMail from "../Mailbox/SendComposedMail";
import { BsChatLeftText } from "react-icons/bs";
const { Meta } = Card;

const ConsultantDetails = ({
  record,
  consultantDetailsTitle,
  isConsultantDetailsModelVisible,
  setIsConsultantDetailsModelVisible,
}) => {
  const visaState = useSelector((state) => state.EmployeeReducer);

  const dispatch = useDispatch();

  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [emailRecord, setEmailRecord] = useState({});
  const [current, setCurrent] = useState("consultantDetails");
  const clientDetailsTabList = [
    {
      key: "consultantDetails",
      tab: "Consultant Details",
    },
    {
      key: "scheduleDays",
      tab: "Schedule Days",
    },
    // {
    //   key: "description",
    //   tab: "Description",
    // },
  ];
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };

  return (
    <Drawer
      title={consultantDetailsTitle}
      open={isConsultantDetailsModelVisible}
      onClose={() => {
        setIsConsultantDetailsModelVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.5rem" }} className="drawerStyle">
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "1rem",
          }}
        >
          <Image src={client} className={"clientImage"} />

          <div
            style={{
              gap: "1rem",
              position: "relative",
              minHeight: "10rem",
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <div
              className="flexColumnWithoutStyle"
              style={{
                justifyContent: "flex-start",
                width: "100%",
                gap: "0.5rem",
              }}
            >
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                {record?.fullName}
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.4rem", color: "#aaa" }}
              >
                {record?.phoneNumber && (
                  <span className="flexRowWithGap">
                    <AiOutlinePhone
                      style={{
                        fontSize: "20",
                      }}
                    />{" "}
                    <a href={`tel:${record?.phoneNumber}`}>
                      {record?.phoneNumber}
                    </a>
                  </span>
                )}

                {record?.emailId && (
                  <span className="flexRowWithGap">
                    <AiOutlineMail
                      style={{
                        fontSize: "16",
                      }}
                    />{" "}
                    <a>
                      <div
                        className="bordered"
                        onClick={() => {
                          setEmailRecord(record);
                          setIsSendComposedEmailModalVisible(true);
                        }}
                      >
                        {record?.emailId}
                      </div>
                    </a>
                  </span>
                )}
                {record?.address && (
                  <span
                    className="flexRowWithGap"
                    onClick={() =>
                      window.open(
                        `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                          record?.address
                        )}`
                      )
                    }
                  >
                    <GoLocation
                      style={{
                        fontSize: "16",
                      }}
                    />{" "}
                    <a>{record?.address} </a>
                  </span>
                )}
              </div>

              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.4rem", color: "#aaa" }}
              >
                {record?.courseCode}
              </div>
              <div>{record?.degree?.name}</div>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.5rem",
                alignSelf: "flex-start",
                flexWrap: "wrap",
                position: "absolute",
                bottom: 0,
              }}
            >
              {" "}
              <button
                className="emailAndMessage"
                onClick={() => {
                  // setIsSendComposedEmailModalVisible(true);
                }}
              >
                <BsChatLeftText style={{ fontSize: "10px" }} />
                <span>Message</span>
              </button>
              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
                <span>Email</span>
              </button>
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "consultantDetails" && (
            <>
              {detailFun("Consultant ID", `${record?.id ? record?.id : "N/A"}`)}
              {detailFun(
                "User Name",
                `${record?.userName ? record?.userName : "N/A"}`
              )}
              {detailFun(
                "Email",
                `${record?.emailId ? record?.emailId : "N/A"}`
              )}
              {detailFun(
                "Phone Number",
                `${record?.phoneNumber ? record?.phoneNumber : "N/A"}`
              )}
              {detailFun(
                "Is Consultant?",
                twoTagResponses(record?.isConsultant, true, false)
              )}
              {detailFun(
                "Is Active?",
                twoTagResponses(record?.isActive, true, false)
              )}
              {detailFun(
                "Country",
                `${record?.country ? record?.country : "N/A"}`
              )}
              {detailFun(
                "Address Line 1",
                `${record?.address ? record?.address : "N/A"}`
              )}
              {record?.address2 &&
                detailFun(
                  "Address Line 2",
                  `${record?.address2 ? record?.address2 : "N/A"}`
                )}

              {record?.state &&
                detailFun("State", `${record?.state ? record?.state : "N/A"}`)}
              {record?.suburb &&
                detailFun(
                  "Suburb",
                  `${record?.suburb ? record?.suburb : "N/A"}`
                )}
              {record?.zipCode &&
                detailFun(
                  "Post Code/ Zip Code",
                  `${record?.zipCode ? record?.zipCode : "N/A"}`
                )}

              {record?.department ? (
                <>
                  {detailFun(
                    "Assigned User group",
                    `${
                      record?.department?.departmentName
                        ? record?.department?.departmentName
                        : "N/A"
                    }`
                  )}
                  <div style={{ width: "100%" }}>
                    <div className="flexRowSpaceBetween">
                      Roles:{" "}
                      <div>
                        {record?.department?.rolesAndPermissionList
                          ? Object?.entries(
                              record?.department?.rolesAndPermissionList
                            )?.map((dataobj) => {
                              if (dataobj[1] !== null) {
                                return (
                                  <Tag
                                    key={dataobj}
                                    className="rolename"
                                    style={{ margin: "2px" }}
                                    color={"green"}
                                  >
                                    {dataobj[0]}
                                  </Tag>
                                );
                              }
                              return;
                            })
                          : "N/A"}
                      </div>
                    </div>
                    <Divider
                      orientationMargin="0"
                      style={{ margin: "0.5rem" }}
                    />
                  </div>
                </>
              ) : null}
            </>
          )}
          {current === "scheduleDays" && (
            <>
              {record?.availableDays?.scheduledDaysDtoResponse?.map(
                (dataObj) => {
                  return (
                    <div
                      key={dataObj.id}
                      style={{
                        backgroundColor: "#eeeeee55",
                        borderRadius: "0.5rem",
                        padding: "0.5rem",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <div>
                        {detailFun(
                          "Schedule Day",
                          `${dataObj?.days ? dataObj?.days : "N/A"}`
                        )}
                        {detailFun(
                          "Start Time",
                          `${dataObj?.start ? dataObj?.start : "N/A"}`
                        )}
                        {detailFun(
                          "End Time",
                          `${dataObj?.end ? dataObj?.end : "N/A"}`
                        )}
                        {detailFun(
                          "Break Start Time",
                          `${
                            dataObj?.breakStartTime
                              ? dataObj?.breakStartTime
                              : "N/A"
                          }`
                        )}
                        {detailFun(
                          "Break End Time",
                          `${
                            dataObj?.breakEndTime
                              ? dataObj?.breakEndTime
                              : "N/A"
                          }`
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </>
          )}
        </Card>
      </Card>
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          composeMailRecord={{
            toUser: [record?.emailId],
          }}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default ConsultantDetails;
