import React, { useEffect, useState } from "react";
import {
  Avatar,
  Form,
  Input,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import course from "./../../Assets/course.png";
import activeCourse from "./../../Assets/courseActive.png";
import SiderDemo from "../../Components/Layout/SiderDemo";
import AddCourse from "./AddCourse";
import UpdateCourse from "./UpdateCourse";
import { MdOutlineDisabledVisible } from "react-icons/md";
import {
  AiOutlineEye,
  AiOutlineFullscreen,
  AiOutlineFullscreenExit,
  AiOutlinePoweroff,
} from "react-icons/ai";
import AddOffshoreDocumentChecklist from "./AddOffshoreDocumentChecklist";
import AddOnshoreDocumentChecklist from "./AddOnshoreDocumentChecklist";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import CoursesDetail from "./CoursesDetail";
import { BiBlock } from "react-icons/bi";
import AddDocumentChecklist from "./AddDocumentCheckList";
const { Search } = Input;

const Courses = () => {
  const store = useSelector((state) => {
    return {
      coursesState: state.CoursesReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isAddDocumentVisible, setIsAddDocumentVisible] = useState(false);
  const [isAddOnshoreDocumentVisible, setIsAddOnshoreDocumentVisible] =
    useState(false);

  const [searchValue, setSearchValue] = useState("");

  const [isAddCourseModalVisible, setIsAddCourseModalVisible] = useState(false);
  const [courseDataList, setCourseDataList] = useState([]);
  const [activeCourseDataList, setActiveCourseDataList] = useState([]);

  const [isUpdateCourseModelVisible, setIsUpdateCourseModelVisible] =
    useState(false);
  const [isOffshoreChecklistModelVisible, setIsOffshoreChecklistModelVisible] =
    useState(false);
  const [isOnshoreChecklistModelVisible, setIsOnshoreChecklistModelVisible] =
    useState(false);
  const [isCourseDetailsModelVisible, setIsCourseDetailsModelVisible] =
    useState(false);
  const [courseType, setCourseType] = useState("get-all-courses");
  const [record, setRecord] = useState("");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onSearch = (e) => {
    if (courseType === "get-active-courses") {
      dispatch({
        type: "SEARCH_ACTIVE_COURSES_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else if (courseType === "get-all-courses") {
      dispatch({
        type: "SEARCH_COURSES_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    }
  };
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  // useEffect(() => {
  //   if (courseType === "get-active-courses") {
  //     getActiveCourse();
  //   } else {
  //     getAllCourse();
  //   }
  // }, []);

  useEffect(() => {
    if (courseType === "get-all-courses") {
      getAllCourse();
    } else {
      getActiveCourse();
    }
  }, [courseType, page, pageSize, store.branchState?.branchRecord.id]);

  const getActiveCourse = () => {
    dispatch({
      type: "FETCH_ACTIVE_COURSES_REQUEST",
      payload: {
        page,
        pageSize,
        branchId:
          store.branchState?.branchRecord.id ??
          localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setActiveCourseDataList(res?.data?.data);
      },
    });
  };

  const getAllCourse = () => {
    dispatch({
      type: "FETCH_COURSES_REQUEST",
      payload: {
        page,
        pageSize,
        branchId:
          store.branchState?.branchRecord.id ??
          localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setCourseDataList(res?.data?.data);
      },
    });
  };

  const columns = [
    {
      title: "Course Code",
      width: 100,
      align: "center",
      render: (data) => data?.courseCode,
    },
    {
      title: "Course Name",
      // dataIndex: "name",
      // sorter: (a, b) => {
      //   if (
      //     a?.name?.props?.children?.props?.children?.props?.children <
      //     b?.name?.props?.children?.props?.children?.props?.children
      //   ) {
      //     return -1;
      //   }
      //   if (
      //     a?.name?.props?.children?.props?.children?.props?.children >
      //     b?.name?.props?.children?.props?.children?.props?.children
      //   ) {
      //     return 1;
      //   }
      //   return 0;
      // },
      width: 200,
      align: "center",
      render: (dataObj) => (
        <a>
          <div
            className="bordered"
            onClick={() => {
              setRecord(dataObj);
              setIsCourseDetailsModelVisible(true);
            }}
          >
            {dataObj.name ?? "N/A"}
          </div>
        </a>
      ),
    },
    {
      title: "Degree",
      // dataIndex: "degree",
      width: 100,
      align: "center",
      render: (data) => data?.degree?.name,
    },
    {
      title: "Course Duration",
      dataIndex: "courseDuration",
      width: 100,
      align: "center",
    },

    {
      title: "Institute Name",
      // dataIndex: "institute",
      width: 250,
      align: "center",
      render: (data) => data?.institute?.name,
    },

    {
      title: "Status",
      // dataIndex: "isActive",
      width: 250,
      align: "center",
      render: (data) => {
        return data?.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        );
      },
    },
    {
      title: "Actions",
      // dataIndex: "actions",
      width: 50,
      align: "center",
      fixed: "right",
      render: (dataObj) =>
        courseType === "get-all-courses" ? (
          <Space size="middle">
            {/* <Tooltip title="Update Course">
        <a>
          <div
            className="bordered"
            onClick={() => {
              setIsUpdateCourseModelVisible(true);
              setRecord(dataObj);
            }}
          >
            <EditOutlined />
          </div>
        </a>
      </Tooltip> */}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update Course">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsUpdateCourseModelVisible(true);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {/* <Tooltip title="Delete Course">
        <Popconfirm
          title="Are you sure to delete this course?"
          onConfirm={() => {
            dispatch({
              type: "DELETE_COURSE_REQUEST",
              payload: { id: dataObj.id },
            });
          }}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <a>
            <div className="bordered">
              <DeleteOutlined />
            </div>
          </a>{" "}
        </Popconfirm>
      </Tooltip> */}

            {/* {store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
        "ATTACH_DOCUMENT"
      ) && (
        <Tooltip title="Document Checklist">
          <a>
            <div
              className="bordered"
              onClick={() => {
                setIsOffshoreChecklistModelVisible(true);
                setRecord(dataObj);
              }}
            >
              <AiOutlineFullscreen />
            </div>
          </a>
        </Tooltip>
      )} */}

            {store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
              "ATTACH_DOCUMENT"
            ) && (
              <Tooltip title="Offshore Checklist">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      // setIsOffshoreChecklistModelVisible(true);
                      setRecord(dataObj);
                      setIsAddDocumentVisible(true);
                    }}
                  >
                    <AiOutlineFullscreen />
                  </div>
                </a>
              </Tooltip>
            )}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
              "ATTACH_DOCUMENT"
            ) && (
              <Tooltip title="Onshore Checklist">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      // setIsOnshoreChecklistModelVisible(true);
                      setIsAddOnshoreDocumentVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineFullscreenExit />
                  </div>
                </a>
              </Tooltip>
            )}

            {dataObj.isActive === true &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
                "CHANGE_STATUS"
              ) && (
                <Tooltip title="Deactivate Course">
                  <a style={{ color: "red" }}>
                    <div
                      className="bordered"
                      onClick={() => {
                        const actionStatus = dataObj.isActive
                          ? "inactive"
                          : "active";
                        dispatch({
                          type: "TOGGLE_COURSE_STATUS_REQUEST",
                          payload: { id: dataObj.id, actionStatus },
                        });
                      }}
                    >
                      <BiBlock />
                    </div>
                  </a>
                </Tooltip>
              )}
            {dataObj.isActive === false &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
                "CHANGE_STATUS"
              ) && (
                <Tooltip title="Activate Course">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        const actionStatus = dataObj.isActive
                          ? "inactive"
                          : "active";
                        dispatch({
                          type: "TOGGLE_COURSE_STATUS_REQUEST",
                          payload: { id: dataObj.id, actionStatus },
                        });
                      }}
                    >
                      <AiOutlinePoweroff />
                    </div>
                  </a>
                </Tooltip>
              )}
            <Tooltip title="Course Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsCourseDetailsModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
          </Space>
        ) : (
          <Space size="middle">
            {store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update Course">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateCourseModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}

            {dataObj.isActive === true &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
                "CHANGE_STATUS"
              ) && (
                <Tooltip title="Deactivate Course">
                  <a style={{ color: "red" }}>
                    <div
                      className="bordered"
                      onClick={() => {
                        const actionStatus = dataObj.isActive
                          ? "inactive"
                          : "active";
                        dispatch({
                          type: "TOGGLE_COURSE_STATUS_REQUEST",
                          payload: { id: dataObj.id, actionStatus },
                        });
                      }}
                    >
                      <BiBlock />
                    </div>
                  </a>
                </Tooltip>
              )}
            {dataObj.isActive === false &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
                "CHANGE_STATUS"
              ) && (
                <Tooltip title="Activate Course">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        const actionStatus = dataObj.isActive
                          ? "inactive"
                          : "active";
                        dispatch({
                          type: "TOGGLE_COURSE_STATUS_REQUEST",
                          payload: { id: dataObj.id, actionStatus },
                        });
                      }}
                    >
                      <AiOutlinePoweroff />
                    </div>
                  </a>
                </Tooltip>
              )}
            <Tooltip title="Course Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsCourseDetailsModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
          </Space>
        ),
    },
  ];

  let data = [];
  if (courseType === "get-all-courses") {
    data = store?.coursesState.courses.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        name: (
          <Tooltip title="Course Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsCourseDetailsModelVisible(true);
                }}
              >
                {dataObj.name ?? "N/A"}
              </div>
            </a>
          </Tooltip>
        ),
        degree: dataObj?.name?.degree,
        courseDuration:
          dataObj.courseDuration + " " + dataObj.courseAccordingTo,
        institute: dataObj.institute?.name,
        degree: dataObj.degree?.name,
        isActive: dataObj.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {/* <Tooltip title="Update Course">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateCourseModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip> */}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update Course">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsUpdateCourseModelVisible(true);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {/* <Tooltip title="Delete Course">
              <Popconfirm
                title="Are you sure to delete this course?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_COURSE_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip> */}

            {/* {store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
              "ATTACH_DOCUMENT"
            ) && (
              <Tooltip title="Document Checklist">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsOffshoreChecklistModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineFullscreen />
                  </div>
                </a>
              </Tooltip>
            )} */}

            {store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
              "ATTACH_DOCUMENT"
            ) && (
              <Tooltip title="Offshore Checklist">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      // setIsOffshoreChecklistModelVisible(true);
                      setRecord(dataObj);
                      setIsAddDocumentVisible(true);
                    }}
                  >
                    <AiOutlineFullscreen />
                  </div>
                </a>
              </Tooltip>
            )}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
              "ATTACH_DOCUMENT"
            ) && (
              <Tooltip title="Onshore Checklist">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      // setIsOnshoreChecklistModelVisible(true);
                      setIsAddOnshoreDocumentVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineFullscreenExit />
                  </div>
                </a>
              </Tooltip>
            )}

            {dataObj.isActive === true &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
                "CHANGE_STATUS"
              ) && (
                <Tooltip title="Deactivate Course">
                  <a style={{ color: "red" }}>
                    <div
                      className="bordered"
                      onClick={() => {
                        const actionStatus = dataObj.isActive
                          ? "inactive"
                          : "active";
                        dispatch({
                          type: "TOGGLE_COURSE_STATUS_REQUEST",
                          payload: { id: dataObj.id, actionStatus },
                        });
                      }}
                    >
                      <BiBlock />
                    </div>
                  </a>
                </Tooltip>
              )}
            {dataObj.isActive === false &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
                "CHANGE_STATUS"
              ) && (
                <Tooltip title="Activate Course">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        const actionStatus = dataObj.isActive
                          ? "inactive"
                          : "active";
                        dispatch({
                          type: "TOGGLE_COURSE_STATUS_REQUEST",
                          payload: { id: dataObj.id, actionStatus },
                        });
                      }}
                    >
                      <AiOutlinePoweroff />
                    </div>
                  </a>
                </Tooltip>
              )}
            <Tooltip title="Course Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsCourseDetailsModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
          </Space>
        ),
      };
    });
  } else if (courseType === "get-active-courses") {
    data = store?.coursesState.activeCourses?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        name: (
          <Tooltip title="Course Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsCourseDetailsModelVisible(true);
                }}
              >
                {dataObj.name ?? "N/A"}
              </div>
            </a>
          </Tooltip>
        ),
        degree: dataObj?.name?.degree,
        courseDuration:
          dataObj.courseDuration + " " + dataObj.courseAccordingTo,
        institute: dataObj.institute?.name,
        degree: dataObj.degree?.name,
        isActive: dataObj.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update Course">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateCourseModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}

            {dataObj.isActive === true &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
                "CHANGE_STATUS"
              ) && (
                <Tooltip title="Deactivate Course">
                  <a style={{ color: "red" }}>
                    <div
                      className="bordered"
                      onClick={() => {
                        const actionStatus = dataObj.isActive
                          ? "inactive"
                          : "active";
                        dispatch({
                          type: "TOGGLE_COURSE_STATUS_REQUEST",
                          payload: { id: dataObj.id, actionStatus },
                        });
                      }}
                    >
                      <BiBlock />
                    </div>
                  </a>
                </Tooltip>
              )}
            {dataObj.isActive === false &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
                "CHANGE_STATUS"
              ) && (
                <Tooltip title="Activate Course">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        const actionStatus = dataObj.isActive
                          ? "inactive"
                          : "active";
                        dispatch({
                          type: "TOGGLE_COURSE_STATUS_REQUEST",
                          payload: { id: dataObj.id, actionStatus },
                        });
                      }}
                    >
                      <AiOutlinePoweroff />
                    </div>
                  </a>
                </Tooltip>
              )}
            <Tooltip title="Course Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsCourseDetailsModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
          </Space>
        ),
      };
    });
  }

  return (
    <SiderDemo>
      <div className="flexColumn">
        <div className="leadFlexRow">
          {store.profileState?.profile?.department?.rolesAndPermissionList?.COURSES?.includes(
            "ADD"
          ) && (
            <button
              className="button"
              onClick={() => {
                setIsAddCourseModalVisible(true);
              }}
            >
              <span>Add Course</span>
            </button>
          )}
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={activeCourse} size={20} shape="square" />
                    <div>All Courses</div>
                  </div>
                ),
                value: "get-all-courses",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={course} size={20} shape="square" />
                    <div>Active Courses</div>
                  </div>
                ),
                value: "get-active-courses",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setCourseType(value);
            }}
          />
          <Search
            className="search"
            style={{
              width: "30%",
              borderRadius: "10px ! important",
            }}
            placeholder="Search for course"
            allowClear
            size="large"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              onSearch(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {store?.coursesState.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              bordered
              columns={columns}
              // dataSource={data}
              dataSource={
                courseType === "get-all-courses"
                  ? courseDataList
                  : activeCourseDataList
              }
              pagination={false}
            />
          )}

          {store?.coursesState.courses?.totalData ? (
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              defaultPageSize={pageSize}
              onChange={onShowSizeChange}
              total={store?.coursesState.courses?.totalPage * 10}
            />
          ) : null}
        </div>
      </div>
      {/* ADD courses */}
      {isAddCourseModalVisible && (
        <AddCourse
          isAddCourseModalVisible={isAddCourseModalVisible}
          setIsAddCourseModalVisible={setIsAddCourseModalVisible}
        />
      )}
      {/* UPDATE courses */}
      {isUpdateCourseModelVisible && (
        <UpdateCourse
          record={record}
          isUpdateCourseModelVisible={isUpdateCourseModelVisible}
          setIsUpdateCourseModelVisible={setIsUpdateCourseModelVisible}
        />
      )}
      {isCourseDetailsModelVisible && (
        <CoursesDetail
          record={record}
          isCourseDetailsModelVisible={isCourseDetailsModelVisible}
          setIsCourseDetailsModelVisible={setIsCourseDetailsModelVisible}
        />
      )}
      {isOffshoreChecklistModelVisible && (
        <AddOffshoreDocumentChecklist
          workflowRecord={record}
          isOnshore={false}
          isAddOffshoreDocumentListModalVisible={
            isOffshoreChecklistModelVisible
          }
          setIsAddOffshoreDocumentListModalVisible={
            setIsOffshoreChecklistModelVisible
          }
        />
      )}
      {isOnshoreChecklistModelVisible && (
        <AddOnshoreDocumentChecklist
          workflowRecord={record}
          isOnshore={true}
          isAddOnshoreDocumentListModalVisible={isOnshoreChecklistModelVisible}
          setIsAddOnshoreDocumentListModalVisible={
            setIsOnshoreChecklistModelVisible
          }
        />
      )}

      {isAddDocumentVisible && record && (
        <AddDocumentChecklist
          getActiveCourse={getActiveCourse}
          getAllCourse={getAllCourse}
          courseType={courseType}
          isOnshore={false}
          record={record}
          document={record?.offShoreCheckList}
          setRecord={setRecord}
          isAddDocumentChecklistModalVisible={isAddDocumentVisible}
          setIsAddDocumentChecklistModalVisible={setIsAddDocumentVisible}
        />
      )}

      {isAddOnshoreDocumentVisible && (
        <AddDocumentChecklist
          getActiveCourse={getActiveCourse}
          getAllCourse={getAllCourse}
          courseType={courseType}
          isOnshore={true}
          document={record?.onShoreCheckList}
          record={record}
          setRecord={setRecord}
          isAddDocumentChecklistModalVisible={isAddOnshoreDocumentVisible}
          setIsAddDocumentChecklistModalVisible={setIsAddOnshoreDocumentVisible}
        />
      )}
    </SiderDemo>
  );
};

export default Courses;
