import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  isCheckedIn: false,
  report: {
    name: "",
    workingDays: 0,
    actualWorkingDays: 0,
    workingHours: 0,
    actualWorkingHours: 0,
    leaveDays: 0,
  },
  taskAssignedToday: {
    data: [],
    totalPage: 0,
    totalData: 0,
    pageNumber: 0,
  },
  attendance: {
    data: [],
    totalPage: 0,
    totalData: 0,
    pageNumber: 0,
  },
  employeeAttendance: {
    data: [],
    totalPage: 0,
    totalData: 0,
    pageNumber: 0,
  },
};

const AttendanceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_ATTENDANCE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ATTENDANCE_REQUEST_SUCCESS": {
      return {
        ...state,
        attendance: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ATTENDANCE_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHECK_IN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "CHECK_IN_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Checked In.");

      return {
        ...state,
        isCheckedIn: true,
        isLoading: false,
      };
    }
    case "CHECK_IN_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to check in.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHECK_OUT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "CHECK_OUT_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Checked out.");

      return {
        ...state,
        isCheckedIn: true,
        isLoading: false,
      };
    }
    case "CHECK_OUT_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to check out.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_ATTENDANCE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_ATTENDANCE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Attendance added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_ATTENDANCE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add Attendance.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_ATTENDANCE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_ATTENDANCE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Attendance updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_ATTENDANCE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update Attendance.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_ATTENDANCE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_ATTENDANCE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Attendance deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_ATTENDANCE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete Attendance.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_ATTENDANCE_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_ATTENDANCE_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Attendance status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_ATTENDANCE_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change Attendance status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_REPORT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_REPORT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Report found.");

      return {
        ...state,
        report: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_REPORT_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to found report.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_TODAY_ASSIGNED_TASK_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_TODAY_ASSIGNED_TASK_REQUEST_SUCCESS": {
      return {
        ...state,
        taskAssignedToday: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_TODAY_ASSIGNED_TASK_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to fetch assigned task for today.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ATTENDANCE_OF_EMPLOYEES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_ATTENDANCE_OF_EMPLOYEES_REQUEST_SUCCESS": {
      return {
        ...state,
        employeeAttendance: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ATTENDANCE_OF_EMPLOYEES_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to .");

      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default AttendanceReducer;
