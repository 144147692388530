import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
const { Option } = Select;

const UpdateCustomerExperience = ({
  record,
  isUpdateCustomerExperienceModalVisible,
  setIsUpdateCustomerExperienceModalVisible,
  setIsClientDetailsModelVisible,
}) => {
  const leadState = useSelector((state) => state.LeadReducer);
  useEffect(() => {
    dispatch({ type: "FETCH_LEADS_DROPDOWN_REQUEST" });
  }, []);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const updateCustomerExperienceSubmitHandler = (value) => {
    const yearsOfCommencement = moment(value.yearsOfCommencement).format(
      "YYYY"
    );
    const yearsOfCompletion = moment(value.yearsOfCompletion).format("YYYY");
    const formData = {
      experience: value.experience,
      institution: value.institution,
      position: value.position,
      country: value.country,
      yearsOfCommencement,
      yearsOfCompletion,
    };
    dispatch({
      type: "ADD_CUSTOMER_EXPERIENCE_REQUEST",
      payload: { id: record.id, formData },
    });
    // form.resetFields();
    setIsUpdateCustomerExperienceModalVisible(false);
  };

  return (
    <Drawer
      title="Add Experience"
      open={isUpdateCustomerExperienceModalVisible}
      onClose={() => {
        setIsUpdateCustomerExperienceModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={updateCustomerExperienceSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                gap: "0.5rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Form.Item
                label="Title"
                name={"experience"}
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: "Please enter title!",
                  },
                ]}
              >
                <Input placeholder="Title" />
              </Form.Item>
              <Form.Item
                label="Position"
                name={"position"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter position!",
                  },
                ]}
              >
                <Input placeholder="Position" />
              </Form.Item>
              <Form.Item
                label="Company Name"
                name={"institution"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter company name!",
                  },
                ]}
              >
                <Input placeholder="Company Name" />
              </Form.Item>

              <Form.Item
                label="Location"
                name={"country"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter location!",
                  },
                ]}
              >
                <Input placeholder="Location" />
              </Form.Item>
              <Form.Item
                label="Commencement Year"
                name={"yearsOfCommencement"}
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: "Please enter commencement year!",
                  },
                ]}
              >
                <DatePicker picker="year" placeholder="Commencement year" />
              </Form.Item>
              <Form.Item
                label="Completion Year"
                style={{ width: "100%" }}
                name={"yearsOfCompletion"}
                rules={[
                  {
                    required: true,
                    message: "Please enter Completion year!",
                  },
                ]}
              >
                <DatePicker picker="year" placeholder="Completion Year" />
              </Form.Item>
            </div>
          </div>
        </div>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Experience
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateCustomerExperience;
