import React, { useEffect, useState } from "react";
import SiderDemo from "../../Components/Layout/SiderDemo";
import { Button, Pagination, Table } from "antd";
import AddDocumentChecklist from "./AddDocumentChecklist";
import { EditOutlined } from "@ant-design/icons";
import EditDocumentChecklist from "./EditDocumentChecklist";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export default function DocumentChecklist() {
  const dispatch = useDispatch();
  const store = useSelector((state) => {
    return { documentChecklistState: state?.DocumentCheckListReducer };
  });

  // const store = useSelector((state) => {
  //   return {
  //     leadState: state.LeadReducer,
  //     profileState: state.ProfileReducer,
  //     branchState: state.BranchReducer,
  //   };
  // });
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [documentActive, setDocumentActive] = useState("");

  useEffect(() => {
    handleGetDocumentChecklist();
  }, []);

  const handleGetDocumentChecklist = () => {
    dispatch({
      type: "FETCH_DOCUMENT_CHECKLIST_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "Document Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Action",
      align: "center",
      render: (data) => {
        return (
          <EditOutlined
            onClick={() => {
              setDocumentActive(data);
              setIsEditModalVisible(true);
            }}
            style={{
              cursor: "pointer",
              border: "1px solid grey",
              borderRadius: "100%",
              padding: "0.3rem",
            }}
          />
        );
      },
    },
  ];

  return (
    <SiderDemo>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <button
          className="button"
          style={{ width: "fit-content" }}
          onClick={() => setIsAddModalVisible(true)}
        >
          <span>Add Document</span>
        </button>
        <Table
          bordered
          pagination={false}
          columns={columns}
          dataSource={store?.documentChecklistState?.documentCheclistData?.data}
        />
        <Pagination
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            alignSelf: "flex-end",
          }}
          defaultCurrent={1}
          current={
            store?.documentChecklistState?.documentCheclistData?.pageNumber + 1
          }
          defaultPageSize={10}
          //   onChange={onShowSizeChange}
          total={
            10 * store?.documentChecklistState?.documentCheclistData?.totalPage
          }
        />
        <AddDocumentChecklist
          handleGetDocumentChecklist={handleGetDocumentChecklist}
          isAddModalVisible={isAddModalVisible}
          setIsAddModalVisible={setIsAddModalVisible}
        />

        {documentActive && (
          <EditDocumentChecklist
            setDocumentActive={setDocumentActive}
            handleGetDocumentChecklist={handleGetDocumentChecklist}
            documentActive={documentActive}
            isEditModalVisible={isEditModalVisible}
            setIsEditModalVisible={setIsEditModalVisible}
          />
        )}
      </div>
    </SiderDemo>
  );
}
