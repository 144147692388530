import React, { useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

const UpdateDegree = ({
  record,
  isUpdateDegreeModalVisible,
  setIsUpdateDegreeModalVisible,
  fromCases,
}) => {
  const store = useSelector((state) => {
    return {
      caseState: state.VisasReducer,
    };
  });
  const dispatch = useDispatch();
  //   useEffect(() => {
  //     dispatch({ type: "FETCH_CASES_DROPDOWN_REQUEST" });
  //   }, []);
  const [form] = Form.useForm();
  const successFunction = (response) => {
    if (fromCases) {
      dispatch({
        type: "FETCH_DEGREE_BY_INSTITUTE_REQUEST",
        payload: {
          institute: fromCases,
        },
      });
    }
  };
  const updateDegreeSubmitHandler = (value) => {
    const formData = {
      ...value,
    };
    dispatch({
      type: "UPDATE_DEGREE_REQUEST",
      payload: { degreeId: record.id, formData },

      //   payload2: successFunction,
    });
    // form.resetFields();
    setIsUpdateDegreeModalVisible(false);
  };
  return (
    <Modal
      title="Update Degree"
      open={isUpdateDegreeModalVisible}
      onCancel={() => {
        setIsUpdateDegreeModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateDegreeSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["degreeName"],
            value: record.degreeName,
          },
        ]}
      >
        <Form.Item
          label="Degree Name"
          name={"degreeName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter degree name!",
            },
          ]}
        >
          <Input placeholder="Degree Name" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Degree
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateDegree;
