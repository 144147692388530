import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
const { Option } = Select;

const AddState = ({ isAddStateModalVisible, setIsAddStateModalVisible }) => {
  const AddStateState = useSelector((state) => state.LeadReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAddStateModalVisible === true) {
      dispatch({ type: "FETCH_LEADS_DROPDOWN_REQUEST" });
    }
  }, [isAddStateModalVisible]);
  const [form] = Form.useForm();
  const addStateSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({
      type: "ADD_STATE_REQUEST",
      payload: { id: value.countryId, values: { stateName: value.stateName } },
    });
    // form.resetFields();
    setIsAddStateModalVisible(false);
  };
  return (
    <Modal
      title="Add State"
      open={isAddStateModalVisible}
      onCancel={() => {
        setIsAddStateModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addStateSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Country"
          name={"countryId"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select a country !",
            },
          ]}
        >
          <Select>
            {AddStateState.leadsDropdown?.countries?.map((dataObj) => {
              return <Option key={dataObj.id}>{dataObj.countryName}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="State"
          name={"stateName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a state!",
            },
          ]}
        >
          <Input placeholder="State" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add State
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddState;
