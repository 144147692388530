import React, { useState } from "react";
import { Button, Form, Input, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;
const { TextArea } = Input;

const UpdateQuotationiStatus = ({
  quoteStatus,
  quoteId,
  isStatusHistoryModalVisible,
  setIsStatusHistoryModalVisible,
  fetchQuotationById,
}) => {
  const dispatch = useDispatch();
  const handleUpdateServiceStatus = (values) => {
    dispatch({
      type: "UPDATE_QUOTATION_STATUS_REQUEST",
      payload: {
        id: quoteId,
        status: values?.quoteStatus,
      },
      isSuccessFn: true,
      successFunction: () => {
        setIsStatusHistoryModalVisible(false);
        fetchQuotationById();
      },
    });
  };
  return (
    <Modal
      title="Update Quotation Status"
      open={isStatusHistoryModalVisible}
      onCancel={() => {
        setIsStatusHistoryModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        initialValues={{ quoteStatus: quoteStatus }}
        onFinish={handleUpdateServiceStatus}
        colon={true}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ gap: "0.3rem", width: "100%" }}
        >
          <Form.Item
            label="Quotation Status"
            name={"quoteStatus"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter your quotation status!",
              },
            ]}
          >
            <Select placeholder="Select Quotation Status">
              <Option value="QUOTE_DRAFT">Quote Draft</Option>
              <Option value="QUOTE_SENT">Quote Sent</Option>
              <Option value="WIN">Win</Option>
              <Option value="LOSE">Lose</Option>
            </Select>
          </Form.Item>
        </div>

        <Button block type="primary" htmlType="submit">
          Update Quotation Status
        </Button>
      </Form>
    </Modal>
  );
};

export default UpdateQuotationiStatus;
