import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AddCountry from "../../Leads/Country/AddCountry";
import { AiOutlinePlus } from "react-icons/ai";
const { Option } = Select;

const UpdateBranch = ({
  record,
  isUpdateBranchModalVisible,
  setIsUpdateBranchModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      profileState: state.ProfileReducer,
    };
  });
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const updateBranchSubmitHandler = (value) => {
    const formData = {
      branchName: value.branchName,
      branchLocation: value.branchLocation,
      branchContactPersonInfo: {
        firstName: value.contactName,
        lastName: value.lastName,
        contactEmail: value.contactEmail,
        contactNumber: value.contactNumber,
      },
    };
    dispatch({
      type: "UPDATE_BRANCH_REQUEST",
      payload: { id: record.id, formData },
    });
    // form.resetFields();
    setIsUpdateBranchModalVisible(false);
  };
  return (
    <Drawer
      title="Update Branch"
      open={isUpdateBranchModalVisible}
      onClose={() => {
        setIsUpdateBranchModalVisible(false);
      }}
      width={1163}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateBranchSubmitHandler}
        colon={true}
        fields={[
          {
            name: ["branchName"],
            value: record.branchName,
          },
          {
            name: ["branchLocation"],
            value: record.branchLocation,
          },
          // {
          //     name: [""],
          //     value: record.
          // },
          // {
          //     name: [""],
          //     value: record.
          // },
          // {
          //     name: [""],
          //     value: record.
          // },
        ]}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Branch Name"
            name={"branchName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter branch name!",
              },
            ]}
          >
            <Input placeholder="Branch Name" />
          </Form.Item>
          <Form.Item
            label="Branch Location"
            name={"branchLocation"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter branch location!",
              },
            ]}
          >
            <Input placeholder="Branch Location" />
          </Form.Item>
        </div>

        <Form.Item
          label="Contact Name"
          name={"contactName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a contact name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Form.Item
            label="Contact Email"
            name={"contactEmail"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid contact email!",
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            label="Country"
            name={"country"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a country!",
              },
            ]}
          >
            <Select
              optionFilterProp="children"
              showSearch
              filterOption={(input, option) => {
                return (option?.children?.props?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              {store.leadState.leadsDropdown?.countries?.map((dataObj) => {
                return (
                  <Option key={dataObj.id}>
                    <div onClick={() => {}}>{dataObj.countryName}</div>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <a
            className="plusButton"
            onClick={() => {
              setIsAddCountryModalVisible(true);
            }}
          >
            <AiOutlinePlus className="iconColor" />
          </a>
        </div>

        <div
          style={{
            backgroundColor: "#eeeeee55",
            borderRadius: "0.5rem",
            padding: "0.5rem",
          }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Form.Item
              label="Login Email"
              name={"contactEmail"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid contact email!",
                },
              ]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              label="Company Registration Number"
              name={"companyRegistrationNumber"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a company registration number!",
                },
              ]}
            >
              <Input type={"number"} />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Password"
              name={"password"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter password!",
                },
              ]}
            >
              <Input.Password placeholder="Password!" />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name={"confirmPassword"}
              dependencies={["password"]}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please confirm the password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Password and confirm password do not match!")
                    );
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password placeholder="Confirm Password!" />
            </Form.Item>
          </div>
        </div>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Branch
          </Button>
        </Form.Item>
      </Form>
      {isAddCountryModalVisible && (
        <AddCountry
          isAddCountryModalVisible={isAddCountryModalVisible}
          setIsAddCountryModalVisible={setIsAddCountryModalVisible}
        />
      )}
    </Drawer>
  );
};

export default UpdateBranch;
