import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Image,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import {
  formattedDate,
  twoTagResponses,
  twoTagResponsesWithStatuses,
} from "../../HelperFunction";
import CheckIn from "./CheckIn";
import CheckOut from "./CheckOut";
import moment from "moment";
import Calendar from "./Calender";
import { BsClock, BsClockHistory } from "react-icons/bs";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Employee",
    dataIndex: "consultancyEmployee",
    align: "center",
  },

  {
    title: "Check In",
    dataIndex: "checkIn",
    align: "center",
  },
  {
    title: "Check In Location",
    dataIndex: "checkInLocation",
    align: "center",
  },
  {
    title: "Checkin Time",
    dataIndex: "addedDate",
    align: "center",
  },
  {
    title: "Check out",
    dataIndex: "checkOut",
    align: "center",
  },
  {
    title: "Check Out Location",
    dataIndex: "checkOutLocation",
    align: "center",
  },
  {
    title: "Checkout Time",
    dataIndex: "checkoutTime",
    align: "center",
  },

  {
    title: "Daily Standup",
    dataIndex: "checkInDescription",
    align: "center",
  },
  {
    title: "Updates",
    dataIndex: "checkOutDescription",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
  },

  // {
  //   title: "Actions",
  //   dataIndex: "actions",
  //   align: "center",
  // },
];

const Attendance = () => {
  const store = useSelector((state) => {
    return {
      attendanceState: state.AttendanceReducer,
      profileState: state.ProfileReducer,
    };
  });
  const [isAddAttendanceModalVisible, setIsAddAttendanceModalVisible] =
    useState(false);
  const [isAddCheckOutModalVisible, setIsAddCheckOutModalVisible] =
    useState(false);
  const [isUpdateAttendanceModalVisible, setIsUpdateAttendanceModalVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const [attendanceType, setAttendanceType] = useState("attandance");
  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState(null);
  const [checkinRecord, setCheckinRecord] = useState(null);
  const [latestCheckinRecord, setLatestCheckinRecord] = useState(null);
  // const checkinResponseFn = (res) => {
  //   if (res?.data?.data?.length > 0) {
  //     setLatestCheckinRecord(res?.data?.data?.[0]);
  //   }

  //   console.log("checkin response function", res);
  // };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_ATTENDANCE_REQUEST",
      payload: {
        date: moment().local().format("YYYY-MM-DD"),
        // isCheckinResponse: true,
        // checkinResponseFn: checkinResponseFn,
      },
    });
    dispatch({
      type: "FETCH_ATTENDANCE_OF_EMPLOYEES_REQUEST",
      payload: { date: moment().local().format("YYYY-MM-DD") },
    });
    dispatch({ type: "FETCH_PROFILE_REQUEST" });
  }, []);
  const [date, setDate] = useState(moment().local().format("YYYY-MM-DD"));

  useEffect(() => {
    // if (store.attendanceState.attendance?.data.length > 0) {
    console.log("attendance data is greater than 0");

    const than = moment(date).local();
    const now = moment().local();

    if (
      store.attendanceState.attendance?.data?.length === 0 &&
      than.isSame(now, "date")
    ) {
      console.log(than.isSame(now, "date"));
      // if (than.isSame(now, "date")) {
      if (
        store.attendanceState.attendance?.status !== "CHECKED_IN" &&
        store.attendanceState.attendance?.status !== "CHECKED_OUT"
      ) {
        setIsAddAttendanceModalVisible(true);
      }
      // }
    } else if (store.attendanceState.attendance?.data?.length !== 0) {
      setLatestCheckinRecord(store.attendanceState.attendance?.data?.[0]);
    }
    // }
  }, [store.attendanceState.attendance?.data]);
  // const tableData = [
  //   {
  //     key: store.attendanceState.attendance?.id,
  //     id: store.attendanceState.attendance?.id,
  //     consultancyEmployee:
  //       store.attendanceState.attendance?.consultancyEmployee?.fullName,
  //     checkIn: (
  //       <Button
  //         type="primary"
  //         onClick={() => {
  //           setIsAddAttendanceModalVisible(true);
  //         }}
  //         style={{ borderRadius: "0.5rem" }}
  //       >
  //         <BsClock />
  //       </Button>
  //     ),
  //     checkOut: store.attendanceState.attendance?.status === "CHECKED_IN" && (
  //       <Button
  //         type="danger"
  //         onClick={() => {
  //           setIsAddCheckOutModalVisible(true);
  //         }}
  //         style={{ borderRadius: "0.5rem" }}
  //       >
  //         <BsClockHistory />
  //       </Button>
  //     ),
  //     checkInImage: store.attendanceState.attendance?.checkInImage ? (
  //       <Image
  //         src={store.attendanceState.attendance?.checkInImage}
  //         style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
  //       />
  //     ) : (
  //       "N/A"
  //     ),
  //     checkInLocation: store.attendanceState.attendance?.checkInLocation ?? "",
  //     checkOutLocation:
  //       store.attendanceState.attendance?.checkOutLocation ?? "",
  //     addedDate: store.attendanceState.attendance?.checkedInTime
  //       ? moment
  //           .utc(store.attendanceState.attendance?.checkedInTime, "HH:mm:ss")
  //           .local()
  //           .format("HH:mm:ss")
  //       : "N/A",
  //     checkOutImage: store.attendanceState.attendance?.checkOutImage ? (
  //       <Image
  //         src={store.attendanceState.attendance?.checkOutImage}
  //         style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
  //       />
  //     ) : (
  //       "N/A"
  //     ),
  //     checkoutTime: store.attendanceState.attendance?.checkoutTime
  //       ? moment
  //           .utc(store.attendanceState.attendance?.checkoutTime, "HH:mm:ss")
  //           .local()
  //           .format("HH:mm:ss")
  //       : "N/A",
  //     checkInDescription:
  //       store.attendanceState.attendance?.checkInDescription ?? "N/A",
  //     checkOutDescription:
  //       store.attendanceState.attendance?.checkOutDescription ?? "N/A",
  //     status: store.attendanceState.attendance?.status
  //       ? twoTagResponsesWithStatuses(
  //           store.attendanceState.attendance?.status,
  //           "CHECKED_IN",
  //           "CHECKED_OUT"
  //         )
  //       : "N/A",
  //   },
  // ];

  const tableData = store.attendanceState.attendance?.data?.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      consultancyEmployee: dataObj?.consultancyEmployee?.fullName,
      checkIn: (
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
        >
          <Button
            type="primary"
            onClick={() => {
              setCheckinRecord(dataObj);
              setIsAddAttendanceModalVisible(true);
            }}
            style={{ borderRadius: "0.5rem" }}
            disabled={
              dataObj?.status === "CHECKED_OUT" ||
              dataObj?.status === "CHECKED_IN"
            }
          >
            <BsClock />
          </Button>
          {dataObj?.checkInImage ? (
            <Image
              src={dataObj?.checkInImage}
              style={{
                height: "50px",
                width: "50px",
                border: "1px solid #ccc",
              }}
            />
          ) : (
            ""
          )}
        </div>
      ),
      checkOut: (
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
        >
          <Button
            type="danger"
            onClick={() => {
              setCheckinRecord(dataObj);
              setIsAddCheckOutModalVisible(true);
            }}
            style={{ borderRadius: "0.5rem" }}
            disabled={dataObj?.status === "CHECKED_OUT"}
          >
            <BsClockHistory />
          </Button>
          {dataObj?.checkOutImage ? (
            <Image
              src={dataObj?.checkOutImage}
              style={{
                height: "50px",
                width: "50px",
                border: "1px solid #ccc",
              }}
            />
          ) : (
            ""
          )}
        </div>
      ),
      checkInImage: dataObj?.checkInImage ? (
        <Image
          src={dataObj?.checkInImage}
          style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
        />
      ) : (
        "N/A"
      ),
      checkInLocation: dataObj?.checkInLocation ?? "",
      checkOutLocation: dataObj?.checkOutLocation ?? "",
      addedDate: dataObj?.checkedInTime
        ? moment
            .utc(dataObj?.checkedInTime, "HH:mm:ss")
            .local()
            .format("HH:mm:ss")
        : "N/A",
      checkOutImage: dataObj?.checkOutImage ? (
        <Image
          src={dataObj?.checkOutImage}
          style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
        />
      ) : (
        "N/A"
      ),
      checkoutTime: dataObj?.checkoutTime
        ? moment
            .utc(dataObj?.checkoutTime, "HH:mm:ss")
            .local()
            .format("HH:mm:ss")
        : "N/A",
      checkInDescription: dataObj?.checkInDescription ?? "N/A",
      checkOutDescription: dataObj?.checkOutDescription ?? "N/A",
      status: dataObj?.status
        ? twoTagResponsesWithStatuses(
            dataObj?.status,
            "CHECKED_IN",
            "CHECKED_OUT"
          )
        : "N/A",
    };
  });
  const tableData2 = store.attendanceState.employeeAttendance?.data?.map(
    (dataObj) => {
      return {
        key: dataObj?.id,
        id: store.attendanceState.attendance?.id,
        consultancyEmployee: dataObj?.consultancyEmployee?.fullName,
        checkIn: (
          <Button
            type="primary"
            onClick={() => {
              setIsAddAttendanceModalVisible(true);
            }}
            style={{ borderRadius: "0.5rem" }}
            disabled={dataObj?.status === "CHECKED_OUT"}
          >
            <BsClock />
          </Button>
        ),
        checkOut: dataObj?.status === "CHECKED_IN" && (
          <Button
            type="danger"
            onClick={() => {
              setIsAddCheckOutModalVisible(true);
            }}
            style={{ borderRadius: "0.5rem" }}
          >
            <BsClockHistory />
          </Button>
        ),
        checkInImage: dataObj?.checkInImage ? (
          <Image
            src={dataObj?.checkInImage}
            style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
          />
        ) : (
          "N/A"
        ),
        checkInLocation: dataObj?.checkInLocation ?? "",
        checkOutLocation: dataObj?.checkOutLocation ?? "",
        addedDate: dataObj?.checkedInTime
          ? moment
              .utc(dataObj?.checkedInTime, "HH:mm:ss")
              .local()
              .format("HH:mm:ss")
          : "N/A",
        checkOutImage: dataObj?.checkOutImage ? (
          <Image
            src={dataObj?.checkOutImage}
            style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
          />
        ) : (
          "N/A"
        ),
        checkoutTime: dataObj?.checkoutTime
          ? moment
              .utc(dataObj?.checkoutTime, "HH:mm:ss")
              .local()
              .format("HH:mm:ss")
          : "N/A",
        checkInDescription: dataObj?.checkInDescription ?? "N/A",
        checkOutDescription: dataObj?.checkOutDescription ?? "N/A",
        status: dataObj?.status
          ? twoTagResponsesWithStatuses(
              dataObj?.status,
              "CHECKED_IN",
              "CHECKED_OUT"
            )
          : "N/A",
      };
    }
  );

  const showDetailsHandle = (dayStr) => {
    setData(dayStr);
    setShowDetails(true);
  };
  // const tableData = [
  //   {
  //     status: "status",
  //     addedDate: "date",
  //     checkInImage: "image",
  //     consultancyEmployee: "employee",
  //     key: "key",
  //   },
  // ];
  const utcTime = moment
    .utc(store.attendanceState.attendance?.checkedInTime, "HH:mm:ss")
    .local();
  // const localTime = utcTime;

  console.log("from attendance state checkin time", utcTime.format("HH:mm:ss"));
  console.log(
    "form attendance proile",
    store.profileState.profile?.allowedPermissionList?.includes(
      "ATTENDANCE_ACCESS"
    )
  );
  return (
    <SiderDemo>
      <div
        className="flexColumnwithoutStyle"
        style={{ width: "100%", gap: "1rem" }}
      >
        <div className="flexRow">
          {latestCheckinRecord?.status === "CHECKED_IN" && (
            <button
              className="button"
              onClick={() => {
                setIsAddCheckOutModalVisible(true);
              }}
            >
              <span>Check Out</span>
            </button>
          )}
          {latestCheckinRecord?.status === "CHECKED_OUT" && (
            <button
              className="button"
              onClick={() => {
                setIsAddAttendanceModalVisible(true);
              }}
            >
              <span>Check In</span>
            </button>
          )}
        </div>
        <Calendar
          showDetailsHandle={showDetailsHandle}
          date={date}
          setDate={setDate}
        />
        <div style={{ marginTop: "0rem" }}>
          {store.profileState.profile?.allowedPermissionList?.includes(
            "ATTENDANCE_ACCESS"
          ) ? (
            <Table
              bordered
              columns={columns}
              dataSource={tableData2}
              pagination={false}
            />
          ) : (
            <Table
              bordered
              columns={columns}
              dataSource={tableData}
              pagination={false}
            />
          )}
        </div>
      </div>
      {isAddAttendanceModalVisible && (
        <CheckIn
          setIsCheckInModalVisible={setIsAddAttendanceModalVisible}
          isCheckInModalVisible={isAddAttendanceModalVisible}
        />
      )}
      {isAddCheckOutModalVisible && (
        <CheckOut
          checkOutId={latestCheckinRecord?.id}
          setIsCheckOutModalVisible={setIsAddCheckOutModalVisible}
          isCheckOutModalVisible={isAddCheckOutModalVisible}
        />
      )}
    </SiderDemo>
  );
};

export default Attendance;
