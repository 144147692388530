import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const AddNewFolder = ({
  driveType,
  parentId,
  isAddNewFolderModalVisible,
  setIsAddNewFolderModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addNewFolderSubmitHandler = (value) => {
    const isRoot = parentId === undefined;
    const formData = {
      driveType,
      isRoot,
      parentId: parentId,
      ...value,
    };
    dispatch({
      type: "ADD_NEW_FOLDER_REQUEST",
      payload: { branchId: localStorage.getItem("branchId"), formData },
    });
    // form.resetFields();
    setIsAddNewFolderModalVisible(false);
  };
  console.log("parent ID", parentId);

  return (
    <Modal
      title="Add New Folder"
      open={isAddNewFolderModalVisible}
      onCancel={() => {
        setIsAddNewFolderModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addNewFolderSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Folder Name"
          name={"folderName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter folder name!",
            },
          ]}
        >
          <Input placeholder="Folder Name" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add New Folder
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNewFolder;
