import { Button, Card, Form, Input } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import OrganizationalSettings from "../OrganizationalSettings";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const AppointmentSettings = () => {
  const store = useSelector((state) => {
    return {
      profileState: state.ProfileReducer,
    };
  });
  useEffect(() => {
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
  }, []);
  const successFunction = (response) => {
    setAppointmentSettingsData((previousData) => {
      return {
        ...previousData,
        appointmentPerfix: response?.data?.prefix?.appointmentPrefix,
        appointmentReminder:
          response?.data?.expiryDates?.appointmentReminderDateAfter,
        appointmentInAdditionalEmails:
          response?.data?.appointmentInAdditionalEmails,
      };
    });
  };
  const [appointmentPrefix, setAppointmentPrefix] = useState("");
  const [appointmentSettingsData, setAppointmentSettingsData] = useState({
    appointmentPerfix: "",
    appointmentReminder: "",
    appointmentInAdditionalEmails: [],
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  return (
    <OrganizationalSettings>
      <div>
        <h3>Appointment Settings</h3>
        <Card>
          <Form
            labelCol={{ span: 5 }}
            fields={[
              {
                name: ["appointmentPrefix"],
                value: appointmentSettingsData.appointmentPerfix,
              },
              {
                name: ["appointmentReminder"],
                value: appointmentSettingsData.appointmentReminder,
              },
            ]}
          >
            <Form.Item label="Appointment Prefix" name={"appointmentPrefix"}>
              <Input
                placeholder="Enter the prefix you want to use"
                onChange={(e) => {
                  setAppointmentSettingsData((previousData) => {
                    return {
                      ...previousData,
                      appointmentPerfix: e.target.value,
                    };
                  });
                  // setAppointmentPrefix(e.target.value);
                }}
              />
            </Form.Item>
            {/* <Form.Item
              name={"appointmentReminder"}
              label="Appointment Reminder"
            >
              <Input
                type="number"
                value={appointmentSettingsData.appointmentReminder}
                onChange={(e) => {
                  setAppointmentSettingsData((previousData) => {
                    return {
                      ...previousData,
                      appointmentReminder: e.target.value,
                    };
                  });
                }}
                style={{ width: "4rem" }}
              />{" "}
              days before.
            </Form.Item> */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "0 0 1rem",
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  dispatch({
                    type: "UPDATE_PREFIX_REQUEST",
                    payload: {
                      appointmentPrefix:
                        appointmentSettingsData.appointmentPerfix,
                    },
                  });
                }}
              >
                Save Prefix
              </Button>
            </div>
          </Form>
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              const formEmail = values?.users?.map((item) => {
                return {
                  email: item.email,
                  isLeads: false,
                  isCase: false,
                  isAppointment: true,
                  isCheckIn: false,
                };
              });
              dispatch({
                type: "ADDITIONAL_EMAILS_FROM_GENERAL_SETTINGS_REQUEST",
                payload: { additionalEmails: [...formEmail] },
              });
              // dispatch({
              //   type: "UPDATE_PREFIX_REQUEST",
              //   payload: {
              //     appointmentPrefix: appointmentSettingsData.appointmentPerfix,
              //   },
              // });
              // dispatch({
              //   type: "UPDATE_EXPIRY_REMINDER_WORKING_HOUR_REQUEST",
              //   payload: {
              //     branchId:
              //       store.branchState?.branchRecord.id ??
              //       localStorage.getItem("branchId"),
              //     appointmentReminderDateAfter:
              //       appointmentSettingsData.appointmentReminder,
              //   },
              // });
            }}
            style={{
              maxWidth: "100%",
            }}
            autoComplete="off"
            fields={appointmentSettingsData.appointmentInAdditionalEmails?.map(
              (item, index) => {
                return { name: ["users", index, "email"], value: item.email };
              }
            )}

            // fields={[
            //   {
            //     name: ["users", 0, "email"],
            //     value: "anksdfjaksj@saklfd.com",
            //   },
            // ]}
          >
            <div
              style={{
                backgroundColor: "#eeeeee55",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                marginBottom: "0.4rem",
              }}
            >
              <h4>Additional Emails:</h4>
              <Form.List
                fields={[
                  {
                    name: "email",
                    value: "anksdfjaksj@saklfd.com",
                  },
                ]}
                name="users"
                initialValue={appointmentSettingsData.additionalEmails?.map(
                  (item, index) => {
                    return {
                      fieldKey: index,
                      isListField: true,
                      key: index,
                    };
                  }
                )}
              >
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                            width: "100%",
                            alignItems: "center",
                            gap: "0.3rem",
                          }}
                        >
                          <Form.Item
                            label="Additional Email"
                            labelCol={{ span: 4 }}
                            {...restField}
                            name={[name, "email"]}
                            style={{
                              width: "100%",
                              margin: 0,
                              padding: "0",
                            }}
                            rules={[
                              {
                                type: "email",
                                message: "Please enter a valid email",
                              },
                            ]}
                          >
                            <Input
                              type="email"
                              placeholder="e.g. username@gmail.com"
                            />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Additional Email
                        </Button>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
            </div>

            {store.profileState?.profile?.department?.rolesAndPermissionList?.APPOINTMENT_SETTINGS?.includes(
              "UPDATE"
            ) && (
              <Form.Item
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button type="primary" htmlType="submit">
                  Save Changes
                </Button>
              </Form.Item>
            )}
          </Form>
        </Card>
      </div>
    </OrganizationalSettings>
  );
};

export default AppointmentSettings;
