import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Tag,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
const { Option } = Select;
const { Panel } = Collapse;

const ReplyToMaiSubmitHandler = ({
  fromInbox,
  mailRecord,
  isReplyToMaiModalVisible,
  setIsReplyToMailModalVisible,
  mailTemplateType = "",
  forWhatId = null,
  f,
}) => {
  const store = useSelector((state) => {
    return {
      emailTemplateState: state.OrganizationSettingsReducer,
      searchedEmailState: state.MailboxReducer,
    };
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [value, setValue] = useState(null);
  const [templateType, setTemplateType] = useState(
    mailTemplateType ? mailTemplateType : "Select"
  );

  const [replyToMailData, setReplyToMailData] = useState({
    bodyContent: "",
    subjectContent: mailRecord?.subjectContent,
    toEmail:
      fromInbox === false && mailRecord?.toUser?.length > 0
        ? mailRecord?.toUser?.map((item) => {
            return {
              value: item,
              label: item,
            };
          })
        : fromInbox &&
          mailRecord?.fromUserName !== null &&
          mailRecord?.fromUserName !== undefined &&
          mailRecord?.fromUserName !== ""
        ? [{ value: mailRecord?.fromUserName, label: mailRecord?.fromUserName }]
        : [],
    bccTo: [],
    ccTo: [],
  });
  const successPayloadFn = (data) => {
    setReplyToMailData((previousData) => {
      return {
        ...previousData,
        bodyContent: data?.data?.templateText,
      };
    });
  };

  useEffect(() => {
    if (templateType === "LEAD") {
      dispatch({
        type: "FETCH_LEAD_EMAIL_TEMPLATE_REQUEST",
        payload: "LEAD",
        successPayload: successPayloadFn,
      });
    } else if (templateType === "CASE") {
      dispatch({
        type: "FETCH_CASE_EMAIL_TEMPLATE_REQUEST",
        payload: "CASE",
        successPayload: successPayloadFn,
      });
    } else if (templateType === "APPOINTMENT") {
      dispatch({
        type: "FETCH_APPOINTMENT_EMAIL_TEMPLATE_REQUEST",
        payload: "APPOINTMENT",
        successPayload: successPayloadFn,
      });
    } else if (templateType === "CLIENT") {
      dispatch({
        type: "FETCH_CLIENT_EMAIL_TEMPLATE_REQUEST",
        payload: "CLIENT",
        successPayload: successPayloadFn,
      });
    }
  }, [templateType]);

  const replytoMailSubmitHandler = (values) => {
    const formData = {
      toEmail: replyToMailData.toEmail?.map((dataObj) => {
        return dataObj?.value;
      }),
      subjectContent: replyToMailData.subjectContent,
      ccTo: replyToMailData.ccTo?.map((dataObj) => {
        return dataObj?.value;
      }),
      bccTo: replyToMailData.bccTo?.map((dataObj) => {
        return dataObj?.value;
      }),
      bodyContent: replyToMailData.bodyContent,
      identifierId: mailRecord.identifierId,
      forWhat: mailTemplateType ? mailTemplateType : null,
      forWhatId: forWhatId ? forWhatId : null,
    };
    dispatch({ type: "REPLY_TO_MAIL_REQUEST", payload: { formData } });
    // form.resetFields();
    setIsReplyToMailModalVisible(false);
  };

  return (
    <Drawer
      title="Reply"
      open={isReplyToMaiModalVisible}
      onClose={() => {
        setIsReplyToMailModalVisible(false);
      }}
      footer={null}
      width={630}
    >
      <Form
        layout="vertical"
        onFinish={replytoMailSubmitHandler}
        colon={true}
        form={form}
        className={"drawerStyle"}
        initialValues={{
          templates: mailTemplateType,
        }}
        fields={[
          {
            name: ["toEmail"],
            value: replyToMailData.toEmail,
          },
          {
            name: ["subjectContent"],
            value: replyToMailData.subjectContent,
          },
          {
            name: ["bodyContent"],
            value: replyToMailData.bodyContent,
          },
        ]}
      >
        <Form.Item
          label="To"
          name={"toEmail"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter email address you want to send to!",
            },
          ]}
        >
          <Select
            mode="tags"
            value={replyToMailData.toEmail}
            labelInValue
            showSearch
            placeholder="Select Emails"
            onChange={(e) => {
              setReplyToMailData((previousData) => {
                return {
                  ...previousData,
                  toEmail: e,
                };
              });
            }}
            onSearch={(e) => {
              dispatch({
                type: "FETCH_AVAILABLE_EMAILS_REQUEST",
                payload: {
                  keyword: e,
                },
              });
            }}
            options={store.searchedEmailState?.searchedAvialbleEmails?.data?.map(
              (dataObj, index) => {
                return {
                  value: dataObj.email,
                  label: (
                    <div className="flexRowSpaceBetween">
                      <span>{dataObj.email}</span>
                      <Tag
                        color="#2db7f5"
                        style={{ borderRadius: "10px", margin: "3px" }}
                      >
                        {dataObj.sourceType}
                      </Tag>
                    </div>
                  ),
                };
              }
            )}
          />
        </Form.Item>
        <Collapse
          size="small"
          style={{ padding: "0", margin: "0" }}
          bordered={false}
        >
          <Panel header="CC and BCC" key="1">
            <Form.Item label="CC" name={"cc"} style={{ width: "100%" }}>
              <Select
                mode="tags"
                // value={mailboxComposeData.toEmail}
                labelInValue
                showSearch
                placeholder="Select Emails"
                onChange={(e) => {
                  setReplyToMailData((previousData) => {
                    return {
                      ...previousData,
                      ccTo: e,
                    };
                  });
                }}
                onSearch={(e) => {
                  dispatch({
                    type: "FETCH_AVAILABLE_EMAILS_REQUEST",
                    payload: {
                      keyword: e,
                    },
                  });
                }}
                options={store.searchedEmailState?.searchedAvialbleEmails?.data?.map(
                  (dataObj, index) => {
                    return {
                      value: dataObj.email,
                      label: (
                        <div className="flexRowSpaceBetween">
                          <span>{dataObj.email}</span>
                          <Tag
                            color="#2db7f5"
                            style={{ borderRadius: "10px", margin: "3px" }}
                          >
                            {dataObj.sourceType}
                          </Tag>
                        </div>
                      ),
                    };
                  }
                )}
              />
            </Form.Item>
            <Form.Item label="BCC" name={"bcc"} style={{ width: "100%" }}>
              <Select
                mode="tags"
                // value={mailboxComposeData.toEmail}
                labelInValue
                showSearch
                placeholder="Select Emails"
                onChange={(e) => {
                  setReplyToMailData((previousData) => {
                    return {
                      ...previousData,
                      bccTo: e,
                    };
                  });
                }}
                onSearch={(e) => {
                  dispatch({
                    type: "FETCH_AVAILABLE_EMAILS_REQUEST",
                    payload: {
                      keyword: e,
                    },
                  });
                }}
                options={store.searchedEmailState?.searchedAvialbleEmails?.data?.map(
                  (dataObj, index) => {
                    return {
                      value: dataObj.email,
                      label: (
                        <div className="flexRowSpaceBetween">
                          <span>{dataObj.email}</span>
                          <Tag
                            color="#2db7f5"
                            style={{ borderRadius: "10px", margin: "3px" }}
                          >
                            {dataObj.sourceType}
                          </Tag>
                        </div>
                      ),
                    };
                  }
                )}
              />
            </Form.Item>
          </Panel>
        </Collapse>
        <Form.Item
          label="Subject"
          name={"subjectContent"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a subject!",
            },
          ]}
        >
          <Input
            placeholder=""
            onChange={(e) => {
              setReplyToMailData((previousData) => {
                return {
                  ...previousData,
                  subjectContent: e.target.value,
                };
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Choose a Template"
          name={"templates"}
          style={{ width: "100%" }}
        >
          <Select
            onChange={(e) => {
              setTemplateType(e);
            }}
          >
            <Option value="Select">Select</Option>
            <Option value="LEAD">Lead</Option>
            <Option value="CASE">Case</Option>
            <Option value="APPOINTMENT">Appointment</Option>
            <Option value="CLIENT">Client</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Message"
          name={"bodyContent"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a message!",
            },
          ]}
        >
          <ReactQuill
            style={{
              background: "white",
              marginBottom: "2rem",
              height: "200px",
            }}
            modules={{
              toolbar: {
                container: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ align: [] }],
                  ["link", "image"],
                  ["clean"],
                  [{ color: [] }],
                ],
              },
            }}
            theme="snow"
            value={replyToMailData.bodyContent}
            onChange={(e) => {
              setReplyToMailData((previousData) => {
                return {
                  ...previousData,
                  bodyContent: e,
                };
              });
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Send
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ReplyToMaiSubmitHandler;
