import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Input,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  MdLink,
  MdOutlineAssignmentTurnedIn,
  MdOutlineDisabledVisible,
  MdPreview,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import AddEmployee from "../EmployeeManager/AddEmployee";
import UpdateEmployee from "../EmployeeManager/UpdateEmployee";
import ConsultantDetails from "./ConultantDetails";
import { AiOutlineEye, AiOutlinePlus, AiOutlinePoweroff } from "react-icons/ai";
import AssignDepartment from "../EmployeeManager/AssignDepartment";
import { BiBlock } from "react-icons/bi";
import EmployeeConfirmation from "../EmployeeManager/EmployeeConfirmation";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import AddAppointment from "../Appointments/AddAppointment";
import ConsultantShortUrl from "../EmployeeManager/ConsultantShortUrl";
import { SiGoogleclassroom } from "react-icons/si";
import AddPermissionForEmployee from "../EmployeeManager/AddPermissionForEmployee";
import SendComposedMail from "../Mailbox/SendComposedMail";
const { Search } = Input;

const columns = [
  {
    title: "Consultant Name",
    dataIndex: "employeeName",
    sorter: (a, b) => {
      if (
        a?.employeeName?.props?.children?.props?.children?.props?.children <
        b?.employeeName?.props?.children?.props?.children?.props?.children
      ) {
        return -1;
      }
      if (
        a?.employeeName?.props?.children?.props?.children?.props?.children >
        b?.employeeName?.props?.children?.props?.children?.props?.children
      ) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "emailId",
    sorter: (a, b) => {
      if (a.emailId < b.emailId) {
        return -1;
      }
      if (a.emailId > b.emailId) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
    sorter: (a, b) => {
      if (a.phoneNumber < b.phoneNumber) {
        return -1;
      }
      if (a.phoneNumber > b.phoneNumber) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "User Name",
    dataIndex: "userName",
    sorter: (a, b) => {
      if (a.userName < b.userName) {
        return -1;
      }
      if (a.userName > b.userName) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    sorter: (a, b) => {
      if (a.gender < b.gender) {
        return -1;
      }
      if (a.gender > b.gender) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },

  {
    title: "Availability",
    dataIndex: "availability",
    width: 150,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "isActive",
    width: 150,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 150,
    align: "center",
    fixed: "right",
  },
];

const Consultants = () => {
  const store = useSelector((state) => {
    return {
      consultantState: state.EmployeeReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const [searchValue, setSearchValue] = useState("");

  const [isAddConsultantsModalVisible, setIsAddConsultantsModalVisible] =
    useState(false);
  const [isUpdateConsultantsModalVisible, setIsUpdateConsultantsModalVisible] =
    useState(false);
  const [isConsultantDetailsModelVisible, setIsConsultantDetailsModelVisible] =
    useState(false);
  const [isAssignDepartmentModalVisible, setIsAssignDepartmentModalVisible] =
    useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [isAddAppointmentModelVisible, setIsAddAppointmentModelVisible] =
    useState(false);
  const [isConsultantUrlLinkModalVisible, setIsConsultantUrlLinkModalVisible] =
    useState(false);
  const [isAddPermissionModalVisible, setIsAddPermissionModalVisible] =
    useState(false);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const [consultantType, setConsultantsType] = useState("allConsultants");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };

  const dispatch = useDispatch();
  const onSearch = (e) => {
    if (consultantType === "activeConsultants") {
      dispatch({
        type: "SEARCH_ACTIVE_CONSULTANTS_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else if (consultantType === "allConsultants") {
      dispatch({
        type: "SEARCH_CONSULTANTS_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    }
  };

  useEffect(() => {
    if (consultantType === "activeConsultants") {
      dispatch({
        type: "FETCH_ACTIVE_CONSULTANTS_REQUEST",
        payload: {
          page,
          pageSize,
          payload: {
            branchId:
              store.branchState?.branchRecord.id ??
              localStorage.getItem("branchId"),
          },
        },
      });
    } else {
      dispatch({
        type: "FETCH_CONSULTANTS_REQUEST",
        payload: {
          page,
          pageSize,
          payload: {
            branchId:
              store.branchState?.branchRecord.id ??
              localStorage.getItem("branchId"),
          },
        },
      });
    }
  }, [consultantType, page, pageSize, store.branchState?.branchRecord]);
  let data = [];
  if (consultantType === "activeConsultants") {
    data = store?.consultantState.activeConsultants?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        employeeName: dataObj.firstName ? (
          <Tooltip placement="topRight" title="Employee Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsConsultantDetailsModelVisible(true);
                }}
              >
                {dataObj.firstName} {dataObj.lastName}
              </div>
            </a>
          </Tooltip>
        ) : (
          "N/A"
        ),
        emailId: dataObj.emailId ? (
          <Tooltip placement="topRight" title="Send Email">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                {dataObj.emailId}
              </div>
            </a>
          </Tooltip>
        ) : (
          "N/A"
        ),
        phoneNumber: dataObj.phoneNumber,
        gender: dataObj.gender,
        userName: dataObj.userName,
        roles:
          dataObj.roleList?.length > 0
            ? dataObj.roleList?.map((dataobj) => {
                return (
                  <Tag
                    key={dataobj.id}
                    className="rolename"
                    style={{ margin: "2px" }}
                    color={"green"}
                  >
                    {dataobj.roleName}
                  </Tag>
                );
              })
            : "N/A",

        availability:
          dataObj?.availableDays?.scheduleDays?.length > 0 ? (
            <p className="greenTag">YES</p>
          ) : (
            <p className="redTag">NO</p>
          ),
        isActive: dataObj.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CONSULTANTS?.includes(
              "UPDATE"
            ) && (
              <Tooltip placement="topRight" title="Update Consultants">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsUpdateConsultantsModalVisible(true);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.EMPLOYEES?.includes(
              "ASSIGN_ROLES"
            ) && (
              <Tooltip placement="topRight" title="Assign User Group">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAssignDepartmentModalVisible(true);
                    }}
                  >
                    <SiGoogleclassroom />
                  </div>
                </a>
              </Tooltip>
            )}
            {/* {store.profileState?.profile?.department?.rolesAndPermissionList?.CONSULTANTS?.includes(
              "ASSIGN_ROLES"
            ) && (
              <Tooltip title="Assign Department">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAssignDepartmentModalVisible(true);
                    }}
                  >
                    <MdOutlineAssignmentTurnedIn />
                  </div>
                </a>
              </Tooltip>
            )} */}
            <Tooltip placement="topRight" title="Consultant Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsConsultantDetailsModelVisible(true);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {store.profileState?.profile?.department?.rolesAndPermissionList?.EMPLOYEES?.includes(
              "ASSIGN_PERMISSION"
            ) && (
              <Tooltip placement="topRight" title="View Permissions">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAddPermissionModalVisible(true);
                    }}
                  >
                    <MdPreview />
                  </div>
                </a>
              </Tooltip>
            )}
            {dataObj.isActive ? (
              <>
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CONSULTANTS?.includes(
                  "CHANGE_STATUS"
                ) && (
                  <Tooltip placement="topRight" title="Deactivate Employee">
                    <a style={{ color: "red" }}>
                      <div
                        className="bordered"
                        onClick={() => {
                          setRecord(dataObj);
                          setIsConfirmationModalVisible(true);
                        }}
                      >
                        <BiBlock />
                      </div>
                    </a>
                  </Tooltip>
                )}
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CONSULTANTS?.includes(
                  "GENERATE_APPOINTMENT_CONSULTANT_URL"
                ) && (
                  <Tooltip
                    placement="topRight"
                    title="Appointment Form URL Link"
                  >
                    <a>
                      <div
                        className="bordered"
                        onClick={() => {
                          setRecord(dataObj);
                          setIsConsultantUrlLinkModalVisible(true);
                        }}
                      >
                        <MdLink />
                      </div>
                    </a>
                  </Tooltip>
                )}
              </>
            ) : (
              <>
                {/* <Tooltip title="Delete employee">
                  <Popconfirm
                    title="Are you sure to delete this employee?"
                    onConfirm={() => {
                      dispatch({
                        type: "DELETE_EMPLOYEES_REQUEST",
                        payload: { id: dataObj.id },
                      });
                    }}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <a>
                      <div className="bordered">
                        <DeleteOutlined />
                      </div>
                    </a>{" "}
                  </Popconfirm>
                </Tooltip> */}
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CONSULTANTS?.includes(
                  "CHANGE_STATUS"
                ) && (
                  <Tooltip title="Activate">
                    <a>
                      <div
                        className="bordered"
                        onClick={() => {
                          setRecord(dataObj);
                          setIsConfirmationModalVisible(true);
                        }}
                      >
                        <AiOutlinePoweroff />
                      </div>
                    </a>
                  </Tooltip>
                )}
              </>
            )}
          </Space>
        ),
      };
    });
  } else {
    data = store?.consultantState.allConsultants?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        employeeName: dataObj.firstName ? (
          <Tooltip placement="topRight" title="Employee Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsConsultantDetailsModelVisible(true);
                }}
              >
                {dataObj.firstName} {dataObj.lastName}
              </div>
            </a>
          </Tooltip>
        ) : (
          "N/A"
        ),
        emailId: dataObj.emailId ? (
          <Tooltip placement="topRight" title="Send Email">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                {dataObj.emailId}
              </div>
            </a>
          </Tooltip>
        ) : (
          "N/A"
        ),
        phoneNumber: dataObj.phoneNumber,
        gender: dataObj.gender,
        userName: dataObj.userName,
        roles:
          dataObj.roleList?.length > 0
            ? dataObj.roleList?.map((dataobj) => {
                return (
                  <Tag
                    key={dataobj.id}
                    className="rolename"
                    style={{ margin: "2px" }}
                    color={"green"}
                  >
                    {dataobj.roleName}
                  </Tag>
                );
              })
            : "N/A",

        availability:
          dataObj?.availableDays?.scheduleDays?.length > 0 ? (
            <p className="greenTag">YES</p>
          ) : (
            <p className="redTag">NO</p>
          ),
        isActive: dataObj.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {/* {dataObj.isActive && (
              <Tooltip title="Add Appointment">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAddAppointmentModelVisible(true);
                    }}
                  >
                    <AiOutlinePlus />
                  </div>
                </a>
              </Tooltip>
            )} */}

            {store.profileState?.profile?.department?.rolesAndPermissionList?.CONSULTANTS?.includes(
              "UPDATE"
            ) && (
              <Tooltip placement="topRight" title="Update Consultants">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsUpdateConsultantsModalVisible(true);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.EMPLOYEES?.includes(
              "ASSIGN_ROLES"
            ) && (
              <Tooltip placement="topRight" title="Assign User Group">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAssignDepartmentModalVisible(true);
                    }}
                  >
                    <SiGoogleclassroom />
                  </div>
                </a>
              </Tooltip>
            )}
            {/* {store.profileState?.profile?.department?.rolesAndPermissionList?.CONSULTANTS?.includes(
              "ASSIGN_ROLES"
            ) && (
              <Tooltip title="Assign Department">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAssignDepartmentModalVisible(true);
                    }}
                  >
                    <MdOutlineAssignmentTurnedIn />
                  </div>
                </a>
              </Tooltip>
            )} */}
            <Tooltip placement="topRight" title="Consultant Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsConsultantDetailsModelVisible(true);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {store.profileState?.profile?.department?.rolesAndPermissionList?.EMPLOYEES?.includes(
              "ASSIGN_PERMISSION"
            ) && (
              <Tooltip placement="topRight" title="View Permissions">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAddPermissionModalVisible(true);
                    }}
                  >
                    <MdPreview />
                  </div>
                </a>
              </Tooltip>
            )}
            {dataObj.isActive ? (
              <>
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CONSULTANTS?.includes(
                  "CHANGE_STATUS"
                ) && (
                  <Tooltip placement="topRight" title="Deactivate Employee">
                    <a style={{ color: "red" }}>
                      <div
                        className="bordered"
                        onClick={() => {
                          setRecord(dataObj);
                          setIsConfirmationModalVisible(true);
                        }}
                      >
                        <BiBlock />
                      </div>
                    </a>
                  </Tooltip>
                )}
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CONSULTANTS?.includes(
                  "GENERATE_APPOINTMENT_CONSULTANT_URL"
                ) && (
                  <Tooltip
                    placement="topRight"
                    title="Appointment Form URL Link"
                  >
                    <a>
                      <div
                        className="bordered"
                        onClick={() => {
                          setRecord(dataObj);
                          setIsConsultantUrlLinkModalVisible(true);
                        }}
                      >
                        <MdLink />
                      </div>
                    </a>
                  </Tooltip>
                )}
              </>
            ) : (
              <>
                {/* <Tooltip title="Delete employee">
                  <Popconfirm
                    title="Are you sure to delete this employee?"
                    onConfirm={() => {
                      dispatch({
                        type: "DELETE_EMPLOYEES_REQUEST",
                        payload: { id: dataObj.id },
                      });
                    }}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <a>
                      <div className="bordered">
                        <DeleteOutlined />
                      </div>
                    </a>{" "}
                  </Popconfirm>
                </Tooltip> */}
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CONSULTANTS?.includes(
                  "CHANGE_STATUS"
                ) && (
                  <Tooltip title="Activate">
                    <a>
                      <div
                        className="bordered"
                        onClick={() => {
                          setRecord(dataObj);
                          setIsConfirmationModalVisible(true);
                        }}
                      >
                        <AiOutlinePoweroff />
                      </div>
                    </a>
                  </Tooltip>
                )}
              </>
            )}
          </Space>
        ),
      };
    });
  }

  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="leadFlexRow">
          {store.profileState?.profile?.department?.rolesAndPermissionList?.CONSULTANTS?.includes(
            "ADD"
          ) && (
            <div style={{ width: "20%" }}>
              <button
                className="button"
                onClick={() => {
                  setIsAddConsultantsModalVisible(true);
                }}
              >
                <span>Add Consultants</span>
              </button>
            </div>
          )}
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>All Consultants</div>
                  </div>
                ),
                value: "allConsultants",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Active Consultants</div>
                  </div>
                ),
                value: "activeConsultants",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setConsultantsType(value);
            }}
          />
          <Search
            className="search"
            style={{
              width: "30%",
              borderRadius: "10px ! important",
            }}
            placeholder="Search for consultants"
            allowClear
            size="large"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              onSearch(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {store?.consultantState?.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              bordered
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          )}

          {consultantType === "allConsultants" &&
          store?.consultantState.allConsultants?.totalData ? (
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              defaultPageSize={pageSize}
              onChange={onShowSizeChange}
              total={store?.consultantState.allConsultants?.totalPage * 10}
            />
          ) : null}
          {consultantType === "activeConsultants" &&
          store?.consultantState.activeConsultants?.totalData ? (
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              defaultPageSize={pageSize}
              onChange={onShowSizeChange}
              total={store?.consultantState.activeConsultants?.totalPage * 10}
            />
          ) : null}
        </div>
      </div>
      {isAddConsultantsModalVisible && (
        <AddEmployee
          AddEmployeeTitle={"Add Consultant"}
          isConsultantFrmConsultant={true}
          isAddEmployeeModalVisible={isAddConsultantsModalVisible}
          setIsAddEmployeeModalVisible={setIsAddConsultantsModalVisible}
        />
      )}
      {isUpdateConsultantsModalVisible && (
        <UpdateEmployee
          record={record}
          updateEmployeeTitle={"Update Consultants"}
          isUpdateEmployeeModalVisible={isUpdateConsultantsModalVisible}
          setIsUpdateEmployeeModalVisible={setIsUpdateConsultantsModalVisible}
        />
      )}
      {isConsultantDetailsModelVisible && (
        <ConsultantDetails
          record={record}
          consultantDetailsTitle={"Consultant Details"}
          isConsultantDetailsModelVisible={isConsultantDetailsModelVisible}
          setIsConsultantDetailsModelVisible={
            setIsConsultantDetailsModelVisible
          }
        />
      )}
      {isAssignDepartmentModalVisible && (
        <AssignDepartment
          record={record}
          isAssignDepartmentModalVisible={isAssignDepartmentModalVisible}
          setIsAssignDepartmentModalVisible={setIsAssignDepartmentModalVisible}
        />
      )}
      {isConfirmationModalVisible && (
        <EmployeeConfirmation
          record={record}
          message={"Are you sure to change status?"}
          isConfirmationModalVisible={isConfirmationModalVisible}
          setIsConfirmationModalVisible={setIsConfirmationModalVisible}
        />
      )}
      {isAddAppointmentModelVisible && (
        <AddAppointment
          counsellorRecord={record}
          navigateAppointmentList={true}
          // appointmentReminderData={appointmentData}
          isAddAppointmentModalVisible={isAddAppointmentModelVisible}
          setIsAddAppointmentModelVisible={setIsAddAppointmentModelVisible}
        />
      )}
      {isAddPermissionModalVisible && (
        <AddPermissionForEmployee
          employeeRecord={{
            id: record.id,
            rolesAndPermissionList: {
              ...record?.department?.rolesAndPermissionList,
            },
          }}
          isAddPermissionModalVisible={isAddPermissionModalVisible}
          setIsAddPermissionModalVisible={setIsAddPermissionModalVisible}
        />
      )}
      {isConsultantUrlLinkModalVisible && (
        <ConsultantShortUrl
          record={record}
          isConsultantUrlLinkModalVisible={isConsultantUrlLinkModalVisible}
          setIsConsultantUrlLinkModalVisible={
            setIsConsultantUrlLinkModalVisible
          }
        />
      )}
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          composeMailRecord={{
            toUser: [record?.emailId],
          }}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
    </SiderDemo>
  );
};

export default Consultants;
