import { Card, Timeline } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { formattedDate } from "../../../HelperFunction";
import moment from "moment";
import { ImCheckboxChecked } from "react-icons/im";

export default function ServiceActivitylogComp({ serviceId, record }) {
  const dispatch = useDispatch();
  const serviceActivityLog = useSelector(
    (store) => store?.QuotationReducer?.serviceActivityLog
  );

  const serviceStatusCheckList = [
    {
      id: 0,
      name: "Pending",
      value: "PENDING",
    },
    {
      id: 1,
      name: "In Progress",
      value: "IN_PROGRESS",
    },

    {
      id: 2,
      name: "Completed",
      value: "COMPLETED",
    },
    {
      id: 3,
      name: "Cancelled",
      value: "CANCELLED",
    },
    {
      id: 4,
      name: "Hold",
      value: "HOLD",
    },
  ];

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 2fr",
        gap: "1rem",
        marginTop: "1.5rem",
      }}
    >
      <div>
        <Timeline mode="left">
          {serviceActivityLog?.data?.map((log) => (
            <Timeline.Item
              label={
                log?.activityTime
                  ? moment
                      .utc(log?.activityTime)
                      .local()
                      .format("D MMMM YYYY, h:mm A")
                  : ""
              }
            >
              {log?.activity}
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "flex-end",
        }}
      >
        {serviceStatusCheckList?.map((status, index) => (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              // border:
              //   index <=
              //   serviceStatusCheckList?.find(
              //     (status) => status?.value === record?.serviceStatus
              //   )?.id
              //     ? "1px solid #1890ff"
              //     : "1px solid #ccc",
              border:
                serviceStatusCheckList?.find(
                  (status) => status?.value === record?.serviceStatus
                )?.id === 4 && index === 4
                  ? "1px solid orange"
                  : serviceStatusCheckList?.find(
                      (status) => status?.value === record?.serviceStatus
                    )?.id === 3 && index === 3
                  ? "1px solid red"
                  : serviceStatusCheckList?.find(
                      (status) => status?.value === record?.serviceStatus
                    )?.id === 2 && index === 2
                  ? "1px solid green"
                  : index <=
                      serviceStatusCheckList?.find(
                        (status) => status?.value === record?.serviceStatus
                      )?.id && index < 2
                  ? "1px solid #1890ff"
                  : "1px solid #ccc",
              borderRadius: "20rem",
              padding: "2px 4px",
              margin: "0.5rem",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 3fr",
                gap: "1rem",
                padding: "0.5rem 1rem",
                fontSize: "1rem",
                width: "10rem",
                color:
                  serviceStatusCheckList?.find(
                    (status) => status?.value === record?.serviceStatus
                  )?.id === 4 && index === 4
                    ? "orange"
                    : serviceStatusCheckList?.find(
                        (status) => status?.value === record?.serviceStatus
                      )?.id === 3 && index === 3
                    ? "red"
                    : serviceStatusCheckList?.find(
                        (status) => status?.value === record?.serviceStatus
                      )?.id === 2 && index === 2
                    ? "green"
                    : index <=
                        serviceStatusCheckList?.find(
                          (status) => status?.value === record?.serviceStatus
                        )?.id && index < 2
                    ? "#1890ff"
                    : "#ccc",
              }}
            >
              <ImCheckboxChecked
                style={{
                  color:
                    serviceStatusCheckList?.find(
                      (status) => status?.value === record?.serviceStatus
                    )?.id === 4 && index === 4
                      ? "orange"
                      : serviceStatusCheckList?.find(
                          (status) => status?.value === record?.serviceStatus
                        )?.id === 3 && index === 3
                      ? "red"
                      : serviceStatusCheckList?.find(
                          (status) => status?.value === record?.serviceStatus
                        )?.id === 2 && index === 2
                      ? "green"
                      : index <=
                          serviceStatusCheckList?.find(
                            (status) => status?.value === record?.serviceStatus
                          )?.id && index < 2
                      ? "#1890ff"
                      : "#ccc",
                  marginTop: "0.3rem",
                }}
              />
              <span>{status?.name}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
