import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  educationVisas: {},
  migrationVisas: {},
  migrationVisaCases: {},
  educationVisaCases: {},
  visaSubclassList: "",
  educationVisasById: "",
};

const VisaClassReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_MIGRATION_VISA_TYPE_CASES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_MIGRATION_VISA_TYPE_CASES_REQUEST_SUCCESS": {
      return {
        ...state,
        migrationVisaCases: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_MIGRATION_VISA_TYPE_CASES_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load migration visa cases.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_EDUCATION_VISA_TYPE_CASES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_EDUCATION_VISA_TYPE_CASES_REQUEST_SUCCESS": {
      return {
        ...state,
        educationVisaCases: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_EDUCATION_VISA_TYPE_CASES_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load education visa cases.");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_VISAS_SUBCLASS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_VISAS_SUBCLASS_REQUEST_SUCCESS": {
      return {
        ...state,
        visaSubclassList: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_VISAS_SUBCLASS_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_EDUCATION_VISAS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_EDUCATION_VISAS_REQUEST_SUCCESS": {
      return {
        ...state,
        educationVisas: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_EDUCATION_VISAS_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_EDUCATION_VISAS_BYID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_EDUCATION_VISAS_BYID_REQUEST_SUCCESS": {
      return {
        ...state,
        educationVisasById: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_EDUCATION_VISAS_BYID_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_MIGRATION_VISAS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_MIGRATION_VISAS_REQUEST_SUCCESS": {
      return {
        ...state,
        migrationVisas: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_MIGRATION_VISAS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load visa sub class.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_EDUCATION_VISAS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_EDUCATION_VISAS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Visa class added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_EDUCATION_VISAS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add visa class.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_MIGRATION_VISA_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_MIGRATION_VISA_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Visa class added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_MIGRATION_VISA_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add visa class.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_EDUCATION_VISAS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_EDUCATION_VISAS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! VisaClass updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_EDUCATION_VISAS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update VisaClass.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_EDUCATION_VISAS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_EDUCATION_VISAS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! VisaClass deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_EDUCATION_VISAS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete VisaClass.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_EDUCATION_VISAS_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_EDUCATION_VISAS_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! VisaClass status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_EDUCATION_VISAS_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change VisaClass status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default VisaClassReducer;
