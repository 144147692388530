import React, { useState, useEffect } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

const AddDocuments = ({
  document,
  workflowRecord,
  fetchEducationWorkflow,
  isAddDocumentsModalVisible,
  setIsAddDocumentsModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const store = useSelector((state) => {
    return { documentChecklistState: state?.DocumentCheckListReducer };
  });

  useEffect(() => {
    handleGetDocumentChecklist();
  }, []);

  const handleGetDocumentChecklist = () => {
    dispatch({
      type: "FETCH_DOCUMENT_CHECKLIST_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  const addDocumentsSubmitHandler = (value) => {
    const formData = {
      ...value,
      documentType: value.item,
    };

    dispatch({
      type: "ADD_DOCUMENTS_REQUEST",
      payload: { workflowId: workflowRecord.id, formData },
      isSuccessFn: true,
      successFunction: handleCallSuccessfunction,
    });
    form.resetFields();
    setIsAddDocumentsModalVisible(false);
  };

  const handleCallSuccessfunction = () => {
    fetchEducationWorkflow();
  };

  return (
    <Modal
      title="Add Document Checklist"
      open={isAddDocumentsModalVisible}
      onCancel={() => {
        setIsAddDocumentsModalVisible(false);
        form.resetFields();
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addDocumentsSubmitHandler}
        colon={true}
        form={form}
        initialValues={{
          item: document,
        }}
      >
        <Form.Item
          label="Document Name"
          name={"item"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a item!",
            },
          ]}
        >
          <Checkbox.Group
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
            options={store?.documentChecklistState?.documentCheclistData?.data?.map(
              (data) => {
                return {
                  label: data?.name,
                  value: data?.name,
                };
              }
            )}
          />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Document
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddDocuments;
