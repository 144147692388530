import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Input } from "antd";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

// import Geocode from "react-geocode";
// Geocode.setApiKey("AIzaSyDI2L0hJI3Q-C-TmfdhoZTQcMpBDETg4dM");

const AddressAutocomplete = ({ setAddress, address }) => {
  GoogleApiWrapper({
    apiKey: "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU",
    libraries: ["places"],
  })();
  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      setAddress(address);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (address) => {
    setAddress(address);
  };

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input
              {...getInputProps({
                placeholder: "Enter an address",
                className: "location-search-input",
              })}
              value={address}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={index}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default AddressAutocomplete;
