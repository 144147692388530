import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { BsRecord } from "react-icons/bs";
const { Option } = Select;

const RenameFolder = ({
  item,
  isRenameFolderModalVisible,
  setIsRenameFolderModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const renameFolderSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({
      type: "RENAME_FOLDER_REQUEST",
      payload: { id: item.id, formData },
    });
    // form.resetFields();
    setIsRenameFolderModalVisible(false);
  };
  console.log("item form rename folder", item);
  return (
    <Modal
      title="Rename Folder"
      open={isRenameFolderModalVisible}
      onCancel={() => {
        setIsRenameFolderModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={renameFolderSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["newName"],
            value: item?.contentName,
          },
        ]}
      >
        <Form.Item
          label="New Name"
          name={"newName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter new name!",
            },
          ]}
        >
          <Input placeholder="New Name" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Rename Folder
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RenameFolder;
