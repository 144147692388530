import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  description: {
    width: "85%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    fontSize: 9,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
    fontSize: 9,
  },
});

const PackageTableFooterStatic = ({ name, value }) => {
  let actualValue;
  if (name === "Discount") {
    actualValue = `${value?.discount ?? 0}
        ${
          value?.percentage === false
            ? ""
            : value?.percentage === true
            ? "%"
            : ""
        }`;
  } else if (name === "GST/TAX") {
    actualValue = value?.gstApplicable ? `${value?.gstAmount ?? 0} %` : `0 %`;
  }
  return (
    <View style={styles.row}>
      <Text style={styles.description}>{name}</Text>
      <Text style={styles.total}>{actualValue}</Text>
    </View>
  );
};

export default PackageTableFooterStatic;
