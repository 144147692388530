import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useMemo } from "react";
const { Option } = Select;
let contenting = "";

const TextPicker = () => {
  const [selectedValue, setSelectedValue] = useState("Option 1");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <select value={selectedValue} onChange={handleChange}>
      <option value="Option 1">Option 1</option>
      <option value="Option 2">Option 2</option>
      <option value="Option 3">Option 3</option>
    </select>
  );
};
// const TextPicker = () => {
//   const [selectedValue, setSelectedValue] = useState("Option 1");

//   const handleChange = (event) => {
//     setSelectedValue(event);
//   };

//   return (
//     <Select value={selectedValue} onChange={handleChange}>
//       <Option value="Company Name">Company Name</Option>
//       <Option value="Client Name">Client Name</Option>
//       <Option value="Client Address">Client Address</Option>
//     </Select>
//   );
// };

const CustomToolbar = () => (
  <div id="toolbar">
    <select className="ql-header">
      <option value="1"></option>
      <option value="2"></option>
      <option selected></option>
    </select>
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <select className="ql-color">
      <option value="red"></option>
      <option value="green"></option>
      <option value="blue"></option>
      <option value="orange"></option>
      <option value="violet"></option>
      <option value="#d0d1d2"></option>
      <option selected></option>
    </select>

    <select className="ql-insertCustomTags">
      <option value="1">One</option>
      <option value="2">Two</option>
    </select>
  </div>
);

const AddEmailTemplate = ({
  fetchTemplateList,
  templateDescription,
  templateSubject,
  emailTemplateType,
  templateText,
  isAddEmailTemplateModalVisible,
  setIsAddEmailTemplateModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [templateType, setTemplateType] = useState("LEAD");
  const [value, setValue] = useState(null);
  contenting = templateText;
  const [htmlContent, setHtmlContent] = useState(
    templateText.replace(/<\/p><p>/g, "</p><br/><p>")
  );

  useEffect(() => {
    const placeholderPickerItems = Array.prototype.slice.call(
      document.querySelectorAll(".ql-picker-item")
    );

    placeholderPickerItems.forEach(
      (item) => (item.textContent = item.dataset.value)
    );
    // document.querySelector(".ql-picker-label")?.innerHTML =
    //   `<span id="placeholder">Insert Placeholders<span>` +
    //   document.querySelector(".ql-picker-label").innerHTML;
  }, []);

  const addEmailTemplateSubmitHandler = (value) => {
    const formData = {
      ...value,
    };

    dispatch({
      type: "ADD_EMAIL_TEMPLATE_REQUEST",
      payload: {
        formData: {
          // templateText: contenting,
          templateText: htmlContent.replace(/<\/p><br\/><p>/g, "</p><p>"),
          templateSubject: value?.templateSubject,
          templateDescription: value?.templateDescription,
        },
        templateType: emailTemplateType,
      },
      isSuccessFn: true,
      successFunction: () => {
        fetchTemplateList();
      },
    });
    // form.resetFields();
    setIsAddEmailTemplateModalVisible(false);
  };

  return (
    <Drawer
      title="Update Email Template"
      open={isAddEmailTemplateModalVisible}
      onClose={() => {
        setIsAddEmailTemplateModalVisible(false);
      }}
      footer={null}
      width={800}
    >
      <Form
        layout="vertical"
        onFinish={addEmailTemplateSubmitHandler}
        colon={true}
        form={form}
        className={"drawerStyle"}
        initialValues={{
          templateSubject: templateSubject,
          templateDescription: templateDescription,
        }}
      >
        <Form.Item name="templateSubject" label="Subject">
          <Input placeholder="Subject" />
        </Form.Item>
        <Form.Item
          label={"Template Body"}
          style={{
            width: "100%",
          }}
        >
          {/* <div className="text-editor">
            <CustomToolbar />
            <ReactQuill
              value={contenting}
              onChange={(e) => {
                setValue(e);
                console.log("change change", e);
              }}
              placeholder={"Write Something here!"}
              modules={{
                toolbar: {
                  container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ align: [] }],
                    ["link", "image"],
                    ["clean"],
                    [{ color: [] }],
                  ],
                },
              }}
            ></ReactQuill>
          </div> */}
          <ReactQuill
            style={{
              background: "white",
              marginBottom: "4rem",
              height: "250px",
            }}
            modules={{
              toolbar: {
                container: [
                  [
                    {
                      placeholder: [
                        "${ID}",
                        "${NAME}",
                        "${COMPANY_NAME}",
                        "${VISA_OUTCOME}",
                        "${CASE_WORKFLOW_STATUS}",
                        "${CASE_WORKFLOW}",
                        "${LEAD_STATUS}",
                        "${APPOINTMENT_STATUS}",
                        "${APPOINTMENT_DATE}",
                        "${APPOINTMENT_SESSION}",
                        "${APPOINTMENT_START_TIME}",
                        "${APPOINTMENT_END_TIME}",
                        "${APPOINTMENT_COUNSELLING_BY}",
                      ],
                    },
                  ],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ align: [] }],
                  ["link", "image"],
                  ["clean"],
                  [{ color: [] }],
                ],
                // handlers: {
                //   placeholder: function (value) {
                //     if (value) {
                //       const cursorPosition = this.quill.getSelection().index;
                //       this.quill.insertText(cursorPosition, value);
                //       this.quill.setSelection(cursorPosition + value.length);
                //     }
                //   },
                // },
              },
            }}
            preserveWhitespace={true}
            theme="snow"
            // value={contenting}
            value={htmlContent}
            onChange={(content, delta, source, editor) => {
              // contenting = content;
              setHtmlContent(editor.getHTML());
            }}
          />
        </Form.Item>
        <Form.Item label="Template Description" name="templateDescription">
          <Input.TextArea rows={4}></Input.TextArea>
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Email Template
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddEmailTemplate;
