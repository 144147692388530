import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  dateAndTime,
  formattedDate,
  scrollToTopFormError,
} from "../../../HelperFunction";
const { Option } = Select;

const AddConsultant = ({
  isAddConsultantModalVisible,
  setIsAddConsultantModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSamePW, setIsSamePW] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const addCountrySubmitHandler = (value) => {
    const dob = dateAndTime(value.birthData);
    const formData = {
      ...value,
      dob,
    };
    dispatch({ type: "ADD_CONSULTANT_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddConsultantModalVisible(false);
  };
  return (
    <Modal
      title="Add Consultant"
      open={isAddConsultantModalVisible}
      onCancel={() => {
        setIsAddConsultantModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addCountrySubmitHandler}
        onFinishFailed={scrollToTopFormError}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="First Name"
            name={"firstName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter first name!",
              },
            ]}
          >
            <Input placeholder="Please enter first name!" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name={"lastName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter last name!",
              },
            ]}
          >
            <Input placeholder="Please enter last name!" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Gender"
            name={"gender"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter last name!",
              },
            ]}
          >
            <Select>
              <Option value="MALE">Male</Option>
              <Option value="FEMALE">Female</Option>
              <Option value="OTHERS">Others</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Date of Birth"
            name={"birthData"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick birth date!",
              },
            ]}
          >
            <DatePicker
              disabledDate={(currentDate) => {
                const date = new Date();
                return currentDate > date;
              }}
            />
          </Form.Item>
        </div>
        <Form.Item
          label="Email"
          name={"email"}
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter valid email!",
            },
          ]}
        >
          <Input type="email" placeholder="e.g, name@domainname.com" />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Phone Number"
            name={"phoneNumber"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter phone number!",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Mobile number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input type="number" placeholder="Please enter first name!" />
          </Form.Item>
          <Form.Item
            label="Calling Code"
            name={"callingCode"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter last name!",
              },
            ]}
          >
            <Input type="number" placeholder="Calling Code" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="password"
            name={"password"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Password!"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name={"confirmPassword"}
            dependencies={["password"]}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please confirm the password!",
              },
              {
                required: trigger,
                message: "Password and confirm password must be same!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Password and confirm password do not match!")
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password
              placeholder="Confirm Password!"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setIsSamePW(password === confirmPassword);
              }}
            />
          </Form.Item>
        </div>
        <Form.Item name={"isConsultant"} valuePropName="checked">
          <Checkbox>Is Consultant</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Consultant
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddConsultant;
