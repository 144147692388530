import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Col,
  Row,
} from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

const AddPermission = ({
  employeeRecord,
  role,
  setRole,
  parmissionAdded,
  permission,
  setPermission,
  isAddPermissionModalVisible,
  setIsAddPermissionModalVisible,
}) => {
  const addPermissionState = useSelector(
    (state) => state.RoleAndPermissionReducer
  );
  // const { rolesAndPermissionList } = employeeRecord;
  const [permissions, setPermissions] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAddPermissionModalVisible === true) {
      dispatch({ type: "FETCH_PERMISSIONS_REQUEST" });
    }
  }, [isAddPermissionModalVisible]);
  const [form] = Form.useForm();
  const addPermissionSubmitHandler = (value) => {
    console.log("from checklist", value);
    console.log(employeeRecord);
    const formData = {
      roleAndPermissionList: {
        DASHBOARD: dashboardAccess ? ["VIEW_ONLY"] : null,
        LEADS: leadAccess ? [...leadAccessList] : null,
        CLIENTS: clientAccess ? [...clientAccessList] : null,
        CONTACTS: contactAccess ? [...contactAccessList] : null,
        APPOINTMENTS: appoitnmentAccess ? [...appoitnmentAccessList] : null,
        CASES: caseAccess ? [...caseAccessList] : null,
        QUOTATION: quotationAccess ? [...quotationAccessList] : null,
        CHECKIN: checkinAccess ? ["VIEW_ONLY"] : null,
        CHECKIN_REPORT: monthlyReportAccess ? ["VIEW_ONLY"] : null,
        BRANCH: branchAccess ? [...branchAccessList] : null,
        EMPLOYEES: employeeAccess ? [...employeeAccessList] : null,
        DEPARTMENT: departmentAccess ? [...departmentAccessList] : null,
        ROLES: rolesAndPermissionAccess
          ? [...rolesAndPermissionAccessList]
          : null,
        COSULTANTS: consultantAccess ? [...consultantAccessList] : null,
        CAMPAIGN: campaignAccess ? [...campaignAccessList] : null,
        RESOURCES: resourcesAccess ? [...resourcesAccessList] : null,
        COMPANIES: companiesAccess ? [...companiesAccessList] : null,
        HEAD_AGENTS: headAgentAccess ? [...headAgentAccessList] : null,
        SUB_AGENTS: subAgentAccess ? [...subAgentAccessList] : null,
        TASKS: taskAccess ? [...taskAccessList] : null,
        EDUCATION_VISAS: educationVisasAccess
          ? [...educationVisasAccessList]
          : null,
        MIGRATION_VISAS: migrationVisasAccess
          ? [...migrationVisasAccessList]
          : null,
        INSTITUTES: instituteAccess
          ? [...instituteAccessList, ...instituteDocumentAccessList]
          : null,
        COURSES: coursesAccess
          ? [...coursesAccessList, ...coursesOffshoreDocumentAccessList]
          : null,
        DEGREE: degreeAccess ? [...degreeAccessList] : null,
        SUPPORT: supportAccess ? [...supportAccessList] : null,
        COLLEGE_FINDER: collegeFinderAccess ? ["VIEW_ONLY"] : null,
        TODOS: todoAccess ? [...todoAccessList] : null,
        EMAIL: emailAccess ? [...emailAccessList] : null,
        GENERAL_SETTINGS: generalSettingsAccess
          ? [...generalSettingsAccessList]
          : null,
        IMPORT_SETTINGS: importSettingsAccess
          ? [...importSettingsAccessList]
          : null,
        TEMPLATE_SETTINGS: templateSettingsAccess
          ? [...templateSettingsAccessList]
          : null,
        DASHBOARD_SETTINGS: dashboardSettingsAccess
          ? [...dashboardSettingsAccessList]
          : null,
        AUTOREMINDER_SETTINGS: autoreminderSettingsAccess
          ? [...autoreminderSettingsAccessList]
          : null,
        LEAD_SETTINGS: leadSettingsAccess ? [...leadSettingsAccessList] : null,
        APPOINTMENT_SETTINGS: appointmentSettingsAccess
          ? [...appointmentSettingsAccessList]
          : null,
        CASE_SETTINGS: caseSettingsAccess ? [...caseSettingsAccessList] : null,
        QUOTATION_SETTINGS: quotatinSettingsAccess
          ? [...quotatinSettingsAccessList]
          : null,
        CHECKIN_SETTINGS: checkInSettingsAccess
          ? [...checkInSettingsAccessList]
          : null,
        EMPLOYEE_SETTINGS: employeeSettingsAccess
          ? [...employeeSettingsAccessList]
          : null,
        WORKFLOW_SETTINGS: workflowSettingsAccess
          ? [
              ...workflowSettingsAccessList,
              ...workflowSettingsEducationAccessList,
              ...workflowSettingsMigrationAccessList,
              ...workflowSettingsOthersAccessList,
            ]
          : null,
      },
      // employeeId: employeeRecord?.id
    };
    console.log("roles and permission", formData);
    // dispatch({
    //   type: "ADD_PERMISSION_OF_EMPLOYEE_REQUEST",
    //   payload: { id: employeeRecord?.id, formData },
    // });

    dispatch({
      type: "ADD_PERMISSION_OF_DEPARTMENT_REQUEST",
      payload: { id: employeeRecord?.id, formData },
    });

    // setRole(record.roleName);
    // setPermission(addPermissionState.roles?.data?.[0].associatedPermissions);
    // form.resetFields();
    setIsAddPermissionModalVisible(false);
  };
  console.log("From permission role", employeeRecord);

  useEffect(() => {
    if (employeeRecord?.rolesAndPermissionList) {
      const permissionList = Object.keys(
        employeeRecord?.rolesAndPermissionList
      )?.map((key) => key);
      console.log("leads permission", permissionList.includes("LEADS"));
      console.log(
        "leads permission2",
        employeeRecord?.rolesAndPermissionList?.LEADS?.length > 0
      );
      if (
        permissionList.includes("DASHBOARD") &&
        employeeRecord?.rolesAndPermissionList?.DASHBOARD?.length > 0
      ) {
        setDashboardAccess(true);
      }
      if (
        permissionList.includes("LEADS") &&
        employeeRecord?.rolesAndPermissionList?.LEADS?.length > 0
      ) {
        setLeadAccess(true);
        setLeadAccessList([...employeeRecord?.rolesAndPermissionList?.LEADS]);
      }
      if (
        permissionList.includes("CLIENTS") &&
        employeeRecord?.rolesAndPermissionList?.CLIENTS?.length > 0
      ) {
        setClientAccess(true);
        setClientAccessList([
          ...employeeRecord?.rolesAndPermissionList?.CLIENTS,
        ]);
      }
      if (
        permissionList.includes("CONTACTS") &&
        employeeRecord?.rolesAndPermissionList?.CONTACTS?.length > 0
      ) {
        setContactAccess(true);
        setContactAccessList([
          ...employeeRecord?.rolesAndPermissionList?.CONTACTS,
        ]);
      }
      if (
        permissionList.includes("APPOINTMENTS") &&
        employeeRecord?.rolesAndPermissionList?.APPOINTMENTS?.length > 0
      ) {
        setAppoitnmentAccess(true);
        setAppoitnmentAccessList([
          ...employeeRecord?.rolesAndPermissionList?.APPOINTMENTS,
        ]);
      }
      if (
        permissionList.includes("CASES") &&
        employeeRecord?.rolesAndPermissionList?.CASES?.length > 0
      ) {
        setCaseAccess(true);
        setCaseAccessList([...employeeRecord?.rolesAndPermissionList?.CASES]);
      }
      if (
        permissionList.includes("QUOTATION") &&
        employeeRecord?.rolesAndPermissionList?.QUOTATION?.length > 0
      ) {
        setQuotationAccess(true);
        setQuotationAccessList([
          ...employeeRecord?.rolesAndPermissionList?.QUOTATION,
        ]);
      }
      if (
        permissionList.includes("CHECKIN") &&
        employeeRecord?.rolesAndPermissionList?.CHECKIN?.length > 0
      ) {
        setcheckinAccess(true);
        // setCheck([...employeeRecord?.rolesAndPermissionList?.CHECKIN]);
      }
      if (
        permissionList.includes("CHECKIN_REPORT") &&
        employeeRecord?.rolesAndPermissionList?.CHECKIN_REPORT?.length > 0
      ) {
        setMonthlyReportAccess(true);
        // setClientAccessList([...employeeRecord?.rolesAndPermissionList?.CHECKIN_REPORT]);
      }
      if (
        permissionList.includes("BRANCH") &&
        employeeRecord?.rolesAndPermissionList?.BRANCH?.length > 0
      ) {
        setBranchAccess(true);
        setBranchAccessList([
          ...employeeRecord?.rolesAndPermissionList?.BRANCH,
        ]);
      }
      if (
        permissionList.includes("EMPLOYEES") &&
        employeeRecord?.rolesAndPermissionList?.EMPLOYEES?.length > 0
      ) {
        setEmployeeAccess(true);
        setEmailAccessList([
          ...employeeRecord?.rolesAndPermissionList?.EMPLOYEES,
        ]);
      }
      if (
        permissionList.includes("DEPARTMENT") &&
        employeeRecord?.rolesAndPermissionList?.DEPARTMENT?.length > 0
      ) {
        setDepartmentAccess(true);
        setDepartmentAccessList([
          ...employeeRecord?.rolesAndPermissionList?.DEPARTMENT,
        ]);
      }
      if (
        permissionList.includes("ROLES") &&
        employeeRecord?.rolesAndPermissionList?.ROLES?.length > 0
      ) {
        setRolesAndPermissionAccess(true);
        setRolesAndPermissionAccessList([
          ...employeeRecord?.rolesAndPermissionList?.ROLES,
        ]);
      }
      if (
        permissionList.includes("COSULTANTS") &&
        employeeRecord?.rolesAndPermissionList?.COSULTANTS?.length > 0
      ) {
        setConsultantAccess(true);
        setConsultantAccessList([
          ...employeeRecord?.rolesAndPermissionList?.COSULTANTS,
        ]);
      }
      if (
        permissionList.includes("CAMPAIGN") &&
        employeeRecord?.rolesAndPermissionList?.CAMPAIGN?.length > 0
      ) {
        setCampaignAccess(true);
        setCampaignAccessList([
          ...employeeRecord?.rolesAndPermissionList?.CAMPAIGN,
        ]);
      }
      if (
        permissionList.includes("RESOURCES") &&
        employeeRecord?.rolesAndPermissionList?.RESOURCES?.length > 0
      ) {
        setResourcesAccess(true);
        setResourcesAccessList([
          ...employeeRecord?.rolesAndPermissionList?.RESOURCES,
        ]);
      }
      if (
        permissionList.includes("COMPANIES") &&
        employeeRecord?.rolesAndPermissionList?.COMPANIES?.length > 0
      ) {
        setCompaniesAccess(true);
        setCompaniesAccessList([
          ...employeeRecord?.rolesAndPermissionList?.COMPANIES,
        ]);
      }
      if (
        permissionList.includes("HEAD_AGENTS") &&
        employeeRecord?.rolesAndPermissionList?.HEAD_AGENTS?.length > 0
      ) {
        setHeadAgentAccess(true);
        setHeadAgentAccessList([
          ...employeeRecord?.rolesAndPermissionList?.HEAD_AGENTS,
        ]);
      }
      if (
        permissionList.includes("SUB_AGENTS") &&
        employeeRecord?.rolesAndPermissionList?.SUB_AGENTS?.length > 0
      ) {
        setSubAgentAccess(true);
        setSubAgentAccessList([
          ...employeeRecord?.rolesAndPermissionList?.SUB_AGENTS,
        ]);
      }
      if (
        permissionList.includes("TASKS") &&
        employeeRecord?.rolesAndPermissionList?.TASKS?.length > 0
      ) {
        setTaskAccess(true);
        setTaskAccessList([...employeeRecord?.rolesAndPermissionList?.TASKS]);
      }
      if (
        permissionList.includes("EDUCATION_VISAS") &&
        employeeRecord?.rolesAndPermissionList?.EDUCATION_VISAS?.length > 0
      ) {
        setEducationVisasAccess(true);
        setEducationVisasAccessList([
          ...employeeRecord?.rolesAndPermissionList?.EDUCATION_VISAS,
        ]);
      }
      if (
        permissionList.includes("MIGRATION_VISAS") &&
        employeeRecord?.rolesAndPermissionList?.MIGRATION_VISAS?.length > 0
      ) {
        setMigrationVisasAccess(true);
        setMigrationVisasAccessList([
          ...employeeRecord?.rolesAndPermissionList?.MIGRATION_VISAS,
        ]);
      }
      if (
        permissionList.includes("INSTITUTES") &&
        employeeRecord?.rolesAndPermissionList?.INSTITUTES?.length > 0
      ) {
        setInstituteAccess(true);
        setInstituteAccessList([
          ...employeeRecord?.rolesAndPermissionList?.INSTITUTES,
        ]);
        setInstituteDocumentAccessList([
          ...employeeRecord?.rolesAndPermissionList?.INSTITUTES,
        ]);
      }
      if (
        permissionList.includes("COURSES") &&
        employeeRecord?.rolesAndPermissionList?.COURSES?.length > 0
      ) {
        setCoursesAccess(true);
        setCoursesAccessList([
          ...employeeRecord?.rolesAndPermissionList?.COURSES,
        ]);
        setCoursesOffshoreDocumentAccessList([
          ...employeeRecord?.rolesAndPermissionList?.COURSES,
        ]);
        setCoursesOnshoreDocumentAccessList([
          ...employeeRecord?.rolesAndPermissionList?.COURSES,
        ]);
      }
      if (
        permissionList.includes("DEGREE") &&
        employeeRecord?.rolesAndPermissionList?.DEGREE?.length > 0
      ) {
        setDegreeAccess(true);
        setDegreeAccessList([
          ...employeeRecord?.rolesAndPermissionList?.DEGREE,
        ]);
      }
      if (
        permissionList.includes("SUPPORT") &&
        employeeRecord?.rolesAndPermissionList?.SUPPORT?.length > 0
      ) {
        setSupportAccess(true);
        setSupportAccessList([
          ...employeeRecord?.rolesAndPermissionList?.SUPPORT,
        ]);
      }
      if (
        permissionList.includes("COLLEGE_FINDER") &&
        employeeRecord?.rolesAndPermissionList?.COLLEGE_FINDER?.length > 0
      ) {
        setCollegeFinderAccess(true);
        // setCo([...employeeRecord?.rolesAndPermissionList?.COLLEGE_FINDER]);
      }
      if (
        permissionList.includes("TODOS") &&
        employeeRecord?.rolesAndPermissionList?.TODOS?.length > 0
      ) {
        setTodoAccess(true);
        setTodoAccessList([...employeeRecord?.rolesAndPermissionList?.TODOS]);
      }
      if (
        permissionList.includes("EMAIL") &&
        employeeRecord?.rolesAndPermissionList?.EMAIL?.length > 0
      ) {
        setEmailAccess(true);
        setEmailAccessList([...employeeRecord?.rolesAndPermissionList?.EMAIL]);
      }
      if (
        permissionList.includes("GENERAL_SETTINGS") &&
        employeeRecord?.rolesAndPermissionList?.GENERAL_SETTINGS?.length > 0
      ) {
        setGeneralSettingsAccess(true);
        setGeneralSettingsAccessList([
          ...employeeRecord?.rolesAndPermissionList?.GENERAL_SETTINGS,
        ]);
      }
      if (
        permissionList.includes("IMPORT_SETTINGS") &&
        employeeRecord?.rolesAndPermissionList?.IMPORT_SETTINGS?.length > 0
      ) {
        setImportSettingsAccess(true);
        setImportSettingsAccessList([
          ...employeeRecord?.rolesAndPermissionList?.IMPORT_SETTINGS,
        ]);
      }
      if (
        permissionList.includes("TEMPLATE_SETTINGS") &&
        employeeRecord?.rolesAndPermissionList?.TEMPLATE_SETTINGS?.length > 0
      ) {
        setTemplateSettingsAccess(true);
        setTemplateSettingsAccessList([
          ...employeeRecord?.rolesAndPermissionList?.TEMPLATE_SETTINGS,
        ]);
      }
      if (
        permissionList.includes("DASHBOARD_SETTINGS") &&
        employeeRecord?.rolesAndPermissionList?.DASHBOARD_SETTINGS?.length > 0
      ) {
        setDashboardSettingsAccess(true);
        setDashboardSettingsAccessList([
          ...employeeRecord?.rolesAndPermissionList?.DASHBOARD_SETTINGS,
        ]);
      }
      if (
        permissionList.includes("AUTOREMINDER_SETTINGS") &&
        employeeRecord?.rolesAndPermissionList?.AUTOREMINDER_SETTINGS?.length >
          0
      ) {
        setAutoreminderSettingsAccess(true);
        setAutoreminderSettingsAccessList([
          ...employeeRecord?.rolesAndPermissionList?.AUTOREMINDER_SETTINGS,
        ]);
      }
      if (
        permissionList.includes("LEAD_SETTINGS") &&
        employeeRecord?.rolesAndPermissionList?.LEAD_SETTINGS?.length > 0
      ) {
        setLeadSettingsAccess(true);
        setLeadSettingsAccessList([
          ...employeeRecord?.rolesAndPermissionList?.LEAD_SETTINGS,
        ]);
      }
      if (
        permissionList.includes("APPOINTMENT_SETTINGS") &&
        employeeRecord?.rolesAndPermissionList?.APPOINTMENT_SETTINGS?.length > 0
      ) {
        setAppointmentSettingsAccess(true);
        setAppointmentSettingsAccessList([
          ...employeeRecord?.rolesAndPermissionList?.APPOINTMENT_SETTINGS,
        ]);
      }
      if (
        permissionList.includes("CASE_SETTINGS") &&
        employeeRecord?.rolesAndPermissionList?.CASE_SETTINGS?.length > 0
      ) {
        setCaseSettingsAccess(true);
        setCaseSettingsAccessList([
          ...employeeRecord?.rolesAndPermissionList?.CASE_SETTINGS,
        ]);
      }
      if (
        permissionList.includes("QUOTATION_SETTINGS") &&
        employeeRecord?.rolesAndPermissionList?.QUOTATION_SETTINGS?.length > 0
      ) {
        setQuotatinSettingsAccess(true);
        setQuotatinSettingsAccessList([
          ...employeeRecord?.rolesAndPermissionList?.QUOTATION_SETTINGS,
        ]);
      }
      if (
        permissionList.includes("CHECKIN_SETTINGS") &&
        employeeRecord?.rolesAndPermissionList?.CHECKIN_SETTINGS?.length > 0
      ) {
        setQuotatinSettingsAccess(true);
        setQuotatinSettingsAccessList([
          ...employeeRecord?.rolesAndPermissionList?.CHECKIN_SETTINGS,
        ]);
      }
      if (
        permissionList.includes("EMPLOYEE_SETTINGS") &&
        employeeRecord?.rolesAndPermissionList?.EMPLOYEE_SETTINGS?.length > 0
      ) {
        setEmployeeSettingsAccess(true);
        setEmployeeSettingsAccessList([
          ...employeeRecord?.rolesAndPermissionList?.EMPLOYEE_SETTINGS,
        ]);
      }
      if (
        permissionList.includes("WORKFLOW_SETTINGS") &&
        employeeRecord?.rolesAndPermissionList?.WORKFLOW_SETTINGS?.length > 0
      ) {
        setWorkflowSettingsAccess(true);
        setWorkflowSettingsAccessList([
          ...employeeRecord?.rolesAndPermissionList?.WORKFLOW_SETTINGS,
        ]);
        setWorkflowSettingsEducationAccessList([
          ...employeeRecord?.rolesAndPermissionList?.WORKFLOW_SETTINGS,
        ]);
        setWorkflowSettingsMigrationAccessList([
          ...employeeRecord?.rolesAndPermissionList?.WORKFLOW_SETTINGS,
        ]);
        setWorkflowSettingsOthersAccessList([
          ...employeeRecord?.rolesAndPermissionList?.WORKFLOW_SETTINGS,
        ]);
      }
    }
  }, []);

  //new permission
  const [dashboardAccess, setDashboardAccess] = useState(false);
  const [leadAccess, setLeadAccess] = useState(false);
  const [leadAccessList, setLeadAccessList] = useState([]);
  const [clientAccess, setClientAccess] = useState(false);
  const [clientAccessList, setClientAccessList] = useState([]);
  const [contactAccess, setContactAccess] = useState(false);
  const [contactAccessList, setContactAccessList] = useState([]);
  const [appoitnmentAccess, setAppoitnmentAccess] = useState(false);
  const [appoitnmentAccessList, setAppoitnmentAccessList] = useState([]);
  const [caseAccess, setCaseAccess] = useState(false);
  const [caseAccessList, setCaseAccessList] = useState([]);
  const [quotationAccess, setQuotationAccess] = useState(false);
  const [quotationAccessList, setQuotationAccessList] = useState([]);
  const [checkinAccess, setcheckinAccess] = useState(false);
  const [monthlyReportAccess, setMonthlyReportAccess] = useState(false);
  const [branchAccess, setBranchAccess] = useState(false);
  const [branchAccessList, setBranchAccessList] = useState([]);

  const [employeeAccess, setEmployeeAccess] = useState(false);
  const [employeeAccessList, setEmployeeAccessList] = useState([]);
  const [departmentAccess, setDepartmentAccess] = useState(false);
  const [departmentAccessList, setDepartmentAccessList] = useState([]);
  const [rolesAndPermissionAccess, setRolesAndPermissionAccess] =
    useState(false);
  const [rolesAndPermissionAccessList, setRolesAndPermissionAccessList] =
    useState([]);
  const [consultantAccess, setConsultantAccess] = useState(false);
  const [consultantAccessList, setConsultantAccessList] = useState([]);
  const [campaignAccess, setCampaignAccess] = useState(false);
  const [campaignAccessList, setCampaignAccessList] = useState([]);
  const [resourcesAccess, setResourcesAccess] = useState(false);
  const [resourcesAccessList, setResourcesAccessList] = useState([]);
  const [companiesAccess, setCompaniesAccess] = useState(false);
  const [companiesAccessList, setCompaniesAccessList] = useState([]);
  const [headAgentAccess, setHeadAgentAccess] = useState(false);
  const [headAgentAccessList, setHeadAgentAccessList] = useState([]);
  const [subAgentAccess, setSubAgentAccess] = useState(false);
  const [subAgentAccessList, setSubAgentAccessList] = useState([]);
  const [taskAccess, setTaskAccess] = useState(false);
  const [taskAccessList, setTaskAccessList] = useState([]);
  const [educationVisasAccess, setEducationVisasAccess] = useState(false);
  const [educationVisasAccessList, setEducationVisasAccessList] = useState([]);
  const [migrationVisasAccess, setMigrationVisasAccess] = useState(false);
  const [migrationVisasAccessList, setMigrationVisasAccessList] = useState([]);
  const [instituteAccess, setInstituteAccess] = useState(false);
  const [instituteAccessList, setInstituteAccessList] = useState([]);
  // const [instituteDocumentAccess, setInstituteDocumentAccess] = useState(false);
  const [instituteDocumentAccessList, setInstituteDocumentAccessList] =
    useState([]);
  const [coursesAccess, setCoursesAccess] = useState(false);
  const [coursesAccessList, setCoursesAccessList] = useState([]);
  // const [coursesOnshoreDocumentAccess, setCoursesOnshoreDocumentAccess] =
  //   useState(false);
  const [
    coursesOnshoreDocumentAccessList,
    setCoursesOnshoreDocumentAccessList,
  ] = useState([]);
  // const [coursesOffshoreDocumentAccess, setCoursesOffshoreDocumentAccess] =
  //   useState(false);
  const [
    coursesOffshoreDocumentAccessList,
    setCoursesOffshoreDocumentAccessList,
  ] = useState([]);
  const [degreeAccess, setDegreeAccess] = useState(false);
  const [degreeAccessList, setDegreeAccessList] = useState([]);
  const [supportAccess, setSupportAccess] = useState(false);
  const [supportAccessList, setSupportAccessList] = useState([]);
  const [collegeFinderAccess, setCollegeFinderAccess] = useState(false);
  const [todoAccess, setTodoAccess] = useState(false);
  const [todoAccessList, setTodoAccessList] = useState([]);
  const [emailAccess, setEmailAccess] = useState(false);
  const [emailAccessList, setEmailAccessList] = useState([]);
  const [generalSettingsAccess, setGeneralSettingsAccess] = useState(false);
  const [generalSettingsAccessList, setGeneralSettingsAccessList] = useState(
    []
  );
  const [importSettingsAccess, setImportSettingsAccess] = useState(false);
  const [importSettingsAccessList, setImportSettingsAccessList] = useState([]);
  const [templateSettingsAccess, setTemplateSettingsAccess] = useState(false);
  const [templateSettingsAccessList, setTemplateSettingsAccessList] = useState(
    []
  );
  const [dashboardSettingsAccess, setDashboardSettingsAccess] = useState(false);
  const [dashboardSettingsAccessList, setDashboardSettingsAccessList] =
    useState([]);
  const [autoreminderSettingsAccess, setAutoreminderSettingsAccess] =
    useState(false);
  const [autoreminderSettingsAccessList, setAutoreminderSettingsAccessList] =
    useState([]);
  const [leadSettingsAccess, setLeadSettingsAccess] = useState(false);
  const [leadSettingsAccessList, setLeadSettingsAccessList] = useState([]);
  const [appointmentSettingsAccess, setAppointmentSettingsAccess] =
    useState(false);
  const [appointmentSettingsAccessList, setAppointmentSettingsAccessList] =
    useState([]);
  const [caseSettingsAccess, setCaseSettingsAccess] = useState(false);
  const [caseSettingsAccessList, setCaseSettingsAccessList] = useState([]);
  const [quotatinSettingsAccess, setQuotatinSettingsAccess] = useState(false);
  const [quotatinSettingsAccessList, setQuotatinSettingsAccessList] = useState(
    []
  );
  const [checkInSettingsAccess, setcheckInSettingsAccess] = useState(false);
  const [checkInSettingsAccessList, setcheckInSettingsAccessList] = useState(
    []
  );
  const [employeeSettingsAccess, setEmployeeSettingsAccess] = useState(false);
  const [employeeSettingsAccessList, setEmployeeSettingsAccessList] = useState(
    []
  );
  const [workflowSettingsAccess, setWorkflowSettingsAccess] = useState(false);
  const [workflowSettingsAccessList, setWorkflowSettingsAccessList] = useState(
    []
  );
  const [
    workflowSettingsEducationAccessList,
    setWorkflowSettingsEducationAccessList,
  ] = useState([]);
  const [
    workflowSettingsMigrationAccessList,
    setWorkflowSettingsMigrationAccessList,
  ] = useState([]);
  const [
    workflowSettingsOthersAccessList,
    setWorkflowSettingsOthersAccessList,
  ] = useState([]);
  const [orderSettingsAccess, setOrderSettingsAccess] = useState(false);
  const [orderSettingsAccessList, setOrderSettingsAccessList] = useState([]);
  const [invoiceSettingsAccess, setInvoiceSettingsAccess] = useState(false);
  const [invoiceSettingsAccessList, setInvoiceSettingsAccessList] = useState(
    []
  );

  console.log("leadAccessList", leadAccessList);

  return (
    <Drawer
      title="Add New Permission"
      open={isAddPermissionModalVisible}
      onClose={() => {
        setIsAddPermissionModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={addPermissionSubmitHandler}
        colon={true}
        form={form}
        initialValues=""
        className="drawerStyle"
      >
        {/* dashboard */}
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Dashboard</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setDashboardAccess(e.target.checked);
              }}
              checked={dashboardAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        {/* lead */}
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Leads</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setLeadAccess(e.target.checked);
                if (leadAccessList.length === 0 && e.target.checked === true) {
                  let list = leadAccessList;
                  list?.push("VIEW_ONLY");
                  setLeadAccessList(list);
                } else if (e.target.checked === false) {
                  setLeadAccessList([]);
                }
              }}
              checked={leadAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Lead</div>
          <Checkbox.Group
            value={leadAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setLeadAccessList(e);
              if (e.length > 0) {
                setLeadAccess(true);
              } else if (e.length === 0) {
                setLeadAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="DOWNLOAD_CSV">Download CSV</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPLOAD_CSV">Upload CSV</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="SEND_EMAIL">Send Email</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="SEND_MESSAGE">Send Message</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* clients */}
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Clients</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setClientAccess(e.target.checked);
                if (
                  clientAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = clientAccessList;
                  list?.push("VIEW_ONLY");
                  setClientAccessList(list);
                } else if (e.target.checked === false) {
                  setClientAccessList([]);
                }
              }}
              checked={clientAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Client</div>
          <Checkbox.Group
            value={clientAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setClientAccessList(e);
              if (e.length > 0) {
                setClientAccess(true);
              } else if (e.length === 0) {
                setClientAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="DOWNLOAD_CSV">Download CSV</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPLOAD_CSV">Upload CSV</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="SEND_EMAIL">Send Email</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="SEND_MESSAGE">Send Message</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/* contact */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Contacts</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setContactAccess(e.target.checked);
                if (
                  contactAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = contactAccessList;
                  list?.push("VIEW_ONLY");
                  setContactAccessList(list);
                } else if (e.target.checked === false) {
                  setContactAccessList([]);
                }
              }}
              checked={contactAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Contact</div>
          <Checkbox.Group
            value={contactAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setContactAccessList(e);
              if (e.length > 0) {
                setContactAccess(true);
              } else if (e.length === 0) {
                setContactAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="SEND_EMAIL">Send Email</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="SEND_MESSAGE">Send Message</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* appointments */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Appointments</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setAppoitnmentAccess(e.target.checked);
                if (
                  appoitnmentAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = appoitnmentAccessList;
                  list?.push("VIEW_ONLY");
                  setAppoitnmentAccessList(list);
                } else if (e.target.checked === false) {
                  setAppoitnmentAccessList([]);
                }
              }}
              checked={appoitnmentAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Appointment</div>
          <Checkbox.Group
            value={appoitnmentAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setAppoitnmentAccessList(e);
              if (e.length > 0) {
                setAppoitnmentAccess(true);
              } else if (e.length === 0) {
                setAppoitnmentAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="SEND_EMAIL">Send Email</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="SEND_MESSAGE">Send Message</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/* Cases */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Cases</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setCaseAccess(e.target.checked);
                if (caseAccessList.length === 0 && e.target.checked === true) {
                  let list = caseAccessList;
                  list?.push("VIEW_ONLY");
                  setCaseAccessList(list);
                } else if (e.target.checked === false) {
                  setCaseAccessList([]);
                }
              }}
              checked={caseAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Case</div>
          <Checkbox.Group
            value={caseAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setCaseAccessList(e);
              if (e.length > 0) {
                setCaseAccess(true);
              } else if (e.length === 0) {
                setCaseAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="SEND_EMAIL">Send Email</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="SEND_MESSAGE">Send Message</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <Checkbox value="ASSIGN_AGENTS">Assign Agents</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="ATTACH_DOCUMENT">Attach Document</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/* quotation */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Quotation</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setQuotationAccess(e.target.checked);
                if (
                  quotationAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = quotationAccessList;
                  list?.push("VIEW_ONLY");
                  setQuotationAccessList(list);
                } else if (e.target.checked === false) {
                  setQuotationAccessList([]);
                }
              }}
              checked={quotationAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Quotation</div>
          <Checkbox.Group
            value={quotationAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setQuotationAccessList(e);
              if (e.length > 0) {
                setQuotationAccess(true);
              } else if (e.length === 0) {
                setQuotationAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="SEND_EMAIL">Send Email</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="SEND_MESSAGE">Send Message</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/* checkin */}
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Check In</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setcheckinAccess(e.target.checked);
              }}
              checked={checkinAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>

        {/* monthly report */}
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Checkin Report</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setMonthlyReportAccess(e.target.checked);
              }}
              checked={monthlyReportAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>

        {/* Branch */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Branch</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setBranchAccess(e.target.checked);
                if (
                  branchAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = branchAccessList;
                  list?.push("VIEW_ONLY");
                  setBranchAccessList(list);
                } else if (e.target.checked === false) {
                  setBranchAccessList([]);
                }
              }}
              checked={branchAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Branch</div>
          <Checkbox.Group
            value={branchAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setBranchAccessList(e);
              if (e.length > 0) {
                setBranchAccess(true);
              } else if (e.length === 0) {
                setBranchAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>

              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/* employee */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Employees</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setEmployeeAccess(e.target.checked);
                if (
                  employeeAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = employeeAccessList;
                  list?.push("VIEW_ONLY");
                  setEmployeeAccessList(list);
                } else if (e.target.checked === false) {
                  setEmployeeAccessList([]);
                }
              }}
              checked={employeeAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Employee</div>
          <Checkbox.Group
            value={employeeAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setEmployeeAccessList(e);
              if (e.length > 0) {
                setEmployeeAccess(true);
              } else if (e.length === 0) {
                setEmployeeAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="ASSIGN_ROLES">Assign Roles</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="ASSIGN_PERMISSION">Assign Roles</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="GENERATE_APPOINTMENT_CONSULTANT_URL">
                  Generate consultant URL link
                </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/* department */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">User Group</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setDepartmentAccess(e.target.checked);
                if (
                  departmentAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = departmentAccessList;
                  list?.push("VIEW_ONLY");
                  setDepartmentAccessList(list);
                } else if (e.target.checked === false) {
                  setDepartmentAccessList([]);
                }
              }}
              checked={departmentAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage User Group</div>
          <Checkbox.Group
            value={departmentAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setDepartmentAccessList(e);
              if (e.length > 0) {
                setDepartmentAccess(true);
              } else if (e.length === 0) {
                setDepartmentAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="ASSIGN_ROLES">Assign Roles</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/* roles and permission */}

        {/* <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Roles</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setRolesAndPermissionAccess(e.target.checked);
                if (
                  rolesAndPermissionAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = rolesAndPermissionAccessList;
                  list?.push("VIEW_ONLY");
                  setRolesAndPermissionAccessList(list);
                } else if (e.target.checked === false) {
                  setRolesAndPermissionAccessList([]);
                }
              }}
              checked={rolesAndPermissionAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Role</div>
          <Checkbox.Group
            value={rolesAndPermissionAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setRolesAndPermissionAccessList(e);
              if (e.length > 0) {
                setRolesAndPermissionAccess(true);
              } else if (e.length === 0) {
                setRolesAndPermissionAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>

              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="ASSIGN_PERMISSION">Assign Roles</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div> */}

        {/* consultant */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Consultants</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setConsultantAccess(e.target.checked);
                if (
                  consultantAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = consultantAccessList;
                  list?.push("VIEW_ONLY");
                  setConsultantAccessList(list);
                } else if (e.target.checked === false) {
                  setConsultantAccessList([]);
                }
              }}
              checked={consultantAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Consultants</div>
          <Checkbox.Group
            value={consultantAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setConsultantAccessList(e);
              if (e.length > 0) {
                setConsultantAccess(true);
              } else if (e.length === 0) {
                setConsultantAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="ASSIGN_ROLES">Assign Roles</Checkbox>
              </Col>
              {/* <Col span={4}>
                <Checkbox value="ASSIGN_PERMISSION">Assign Roles</Checkbox>
              </Col> */}
              <Col span={4}>
                <Checkbox value="GENERATE_APPOINTMENT_CONSULTANT_URL">
                  Generate consultant URL link
                </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/* campaign manager */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Campaign</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setCampaignAccess(e.target.checked);
                if (
                  campaignAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = campaignAccessList;
                  list?.push("VIEW_ONLY");
                  setCampaignAccessList(list);
                } else if (e.target.checked === false) {
                  setCampaignAccessList([]);
                }
              }}
              checked={campaignAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Campaign</div>
          <Checkbox.Group
            value={campaignAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setCampaignAccessList(e);
              if (e.length > 0) {
                setCampaignAccess(true);
              } else if (e.length === 0) {
                setCampaignAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="GENERATE_LEAD_URL">
                  Generate lead URL/QR link
                </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="GENERATE_APPOINTMENT_URL">
                  Generate appointment URL link
                </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/* resources */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Resources</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setResourcesAccess(e.target.checked);
                if (
                  resourcesAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = resourcesAccessList;
                  list?.push("VIEW_ONLY");
                  setResourcesAccessList(list);
                } else if (e.target.checked === false) {
                  setResourcesAccessList([]);
                }
              }}
              checked={resourcesAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Resources</div>
          <Checkbox.Group
            value={resourcesAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setResourcesAccessList(e);
              if (e.length > 0) {
                setResourcesAccess(true);
              } else if (e.length === 0) {
                setResourcesAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="UPLOAD_FILE">Upload File</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="ADD_FOLDER">Add Folder</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="RENAME">Rename</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="DELETE">Delete</Checkbox>
              </Col>

              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/* companies */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Companies</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setCompaniesAccess(e.target.checked);
                if (
                  companiesAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = companiesAccessList;
                  list?.push("VIEW_ONLY");
                  setCompaniesAccessList(list);
                } else if (e.target.checked === false) {
                  setCompaniesAccessList([]);
                }
              }}
              checked={companiesAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Company</div>
          <Checkbox.Group
            value={companiesAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setCompaniesAccessList(e);
              if (e.length > 0) {
                setCompaniesAccess(true);
              } else if (e.length === 0) {
                setCompaniesAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="ADD_PACKAGE">Add Package</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="DELETE">Delete</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* head agent */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Head Agents</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setHeadAgentAccess(e.target.checked);
                if (
                  headAgentAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = headAgentAccessList;
                  list?.push("VIEW_ONLY");
                  setHeadAgentAccessList(list);
                } else if (e.target.checked === false) {
                  setHeadAgentAccessList([]);
                }
              }}
              checked={headAgentAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Head Agent</div>
          <Checkbox.Group
            value={headAgentAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setHeadAgentAccessList(e);
              if (e.length > 0) {
                setHeadAgentAccess(true);
              } else if (e.length === 0) {
                setHeadAgentAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="DELETE">Delete</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* sub agent */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Sub Agents</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setSubAgentAccess(e.target.checked);
                if (
                  subAgentAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = subAgentAccessList;
                  list?.push("VIEW_ONLY");
                  setSubAgentAccessList(list);
                } else if (e.target.checked === false) {
                  setSubAgentAccessList([]);
                }
              }}
              checked={subAgentAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Sub Agent</div>
          <Checkbox.Group
            value={subAgentAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setSubAgentAccessList(e);
              if (e.length > 0) {
                setSubAgentAccess(true);
              } else if (e.length === 0) {
                setSubAgentAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="DELETE">Delete</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/* tasks */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Tasks</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setTaskAccess(e.target.checked);
                if (taskAccessList.length === 0 && e.target.checked === true) {
                  let list = taskAccessList;
                  list?.push("VIEW_ONLY");
                  setTaskAccessList(list);
                } else if (e.target.checked === false) {
                  setTaskAccessList([]);
                }
              }}
              checked={taskAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Task</div>
          <Checkbox.Group
            value={taskAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setTaskAccessList(e);
              if (e.length > 0) {
                setTaskAccess(true);
              } else if (e.length === 0) {
                setTaskAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="DOWNLOAD_CSV">Download CSV</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPLOAD_CSV">Upload CSV</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/*education visas */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Education Visas</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setEducationVisasAccess(e.target.checked);
                if (
                  educationVisasAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = educationVisasAccessList;
                  list?.push("VIEW_ONLY");
                  setEducationVisasAccessList(list);
                } else if (e.target.checked === false) {
                  setEducationVisasAccessList([]);
                }
              }}
              checked={educationVisasAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Education Visa</div>
          <Checkbox.Group
            value={educationVisasAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setEducationVisasAccessList(e);
              if (e.length > 0) {
                setEducationVisasAccess(true);
              } else if (e.length === 0) {
                setEducationVisasAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="DELETE">Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/*migration visas */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Migration Visas</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setMigrationVisasAccess(e.target.checked);
                if (
                  migrationVisasAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = migrationVisasAccessList;
                  list?.push("VIEW_ONLY");
                  setMigrationVisasAccessList(list);
                } else if (e.target.checked === false) {
                  setMigrationVisasAccessList([]);
                }
              }}
              checked={migrationVisasAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Migration Visa</div>
          <Checkbox.Group
            value={migrationVisasAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setMigrationVisasAccessList(e);
              if (e.length > 0) {
                setMigrationVisasAccess(true);
              } else if (e.length === 0) {
                setMigrationVisasAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="DELETE">Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/* institute */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Institutes</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setInstituteAccess(e.target.checked);
                if (
                  instituteAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = instituteAccessList;
                  list?.push("VIEW_ONLY");
                  setInstituteAccessList(list);
                } else if (e.target.checked === false) {
                  setInstituteAccessList([]);
                }
              }}
              checked={instituteAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Institute</div>
          <Checkbox.Group
            value={instituteAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setInstituteAccessList(e);
              if (e.length > 0 && instituteDocumentAccessList?.length > 0) {
                setInstituteAccess(true);
              } else if (
                e.length === 0 &&
                instituteDocumentAccessList?.length === 0
              ) {
                setInstituteAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="ADD_PACKAGE">Add Package</Checkbox>
              </Col>

              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Institute Documents</div>
          <Checkbox.Group
            value={instituteDocumentAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setInstituteDocumentAccessList(e);
              if (e.length > 0 && instituteAccessList?.length > 0) {
                setInstituteAccess(true);
              } else if (e.length === 0 && instituteAccessList?.length === 0) {
                setInstituteAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>

              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="DELETE">Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/* courses */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Courses</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setCoursesAccess(e.target.checked);
                if (
                  coursesAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = coursesAccessList;
                  list?.push("VIEW_ONLY");
                  setCoursesAccessList(list);
                } else if (e.target.checked === false) {
                  setCoursesAccessList([]);
                  setCoursesOnshoreDocumentAccessList([]);
                }
              }}
              checked={coursesAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Courses</div>
          <Checkbox.Group
            value={coursesAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setCoursesAccessList(e);
              if (e.length > 0) {
                setCoursesAccess(true);
              } else if (
                e.length === 0 &&
                coursesOnshoreDocumentAccessList?.length === 0
              ) {
                setCoursesAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Course Documents</div>
          <Checkbox.Group
            value={coursesOnshoreDocumentAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setCoursesOnshoreDocumentAccessList(e);
              if (e.length > 0 && coursesAccessList?.length > 0) {
                setCoursesAccess(true);
              } else if (e.length === 0 && coursesAccessList?.length === 0) {
                setCoursesAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/* degree */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Degree</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setDegreeAccess(e.target.checked);
                if (
                  degreeAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = degreeAccessList;
                  list?.push("VIEW_ONLY");
                  setDegreeAccessList(list);
                } else if (e.target.checked === false) {
                  setDegreeAccessList([]);
                }
              }}
              checked={degreeAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Degree</div>
          <Checkbox.Group
            value={degreeAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setDegreeAccessList(e);
              if (e.length > 0) {
                setDegreeAccess(true);
              } else if (e.length === 0) {
                setDegreeAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="DELETE">Delete</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* support */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Support</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setSupportAccess(e.target.checked);
                if (
                  supportAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = supportAccessList;
                  list?.push("VIEW_ONLY");
                  setSupportAccessList(list);
                } else if (e.target.checked === false) {
                  setSupportAccessList([]);
                }
              }}
              checked={supportAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Support</div>
          <Checkbox.Group
            value={supportAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setSupportAccessList(e);
              if (e.length > 0) {
                setSupportAccess(true);
              } else if (e.length === 0) {
                setSupportAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="REPLY_TO_TICKET">Reply to Ticket</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/* college finder */}
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

        <div className="permissionRow">
          <div className="permissionTitle permissionFont">College Finder</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setCollegeFinderAccess(e.target.checked);
              }}
              checked={collegeFinderAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>

        {/* todos */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Todos</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setTodoAccess(e.target.checked);
                if (todoAccessList.length === 0 && e.target.checked === true) {
                  let list = todoAccessList;
                  list?.push("VIEW_ONLY");
                  setTodoAccessList(list);
                } else if (e.target.checked === false) {
                  setTodoAccessList([]);
                }
              }}
              checked={todoAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Todo</div>
          <Checkbox.Group
            value={todoAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setTodoAccessList(e);
              if (e.length > 0) {
                setTodoAccess(true);
              } else if (e.length === 0) {
                setTodoAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add </Checkbox>
              </Col>

              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>

              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        {/* Email */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Email</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setEmailAccess(e.target.checked);
                if (emailAccessList.length === 0 && e.target.checked === true) {
                  let list = emailAccessList;
                  list?.push("VIEW_ONLY");
                  setEmailAccessList(list);
                } else if (e.target.checked === false) {
                  setEmailAccessList([]);
                }
              }}
              checked={emailAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Email</div>
          <Checkbox.Group
            value={emailAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setEmailAccessList(e);
              if (e.length > 0) {
                setEmailAccess(true);
              } else if (e.length === 0) {
                setEmailAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="COMPOSE">Compose </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="INBOX">Inbox</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="SENT">Sent</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="DRAFT">Draft</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="REPLY_EMAIL">Reply EMAIL</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="DELETE_DRAFT_EMAIL">
                  Delete Draft Email
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* general settings */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">General Settings</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setGeneralSettingsAccess(e.target.checked);
                if (
                  generalSettingsAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = generalSettingsAccessList;
                  list?.push("VIEW_ONLY");
                  setGeneralSettingsAccessList(list);
                } else if (e.target.checked === false) {
                  setGeneralSettingsAccessList([]);
                }
              }}
              checked={generalSettingsAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage General Settings</div>
          <Checkbox.Group
            value={generalSettingsAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setGeneralSettingsAccessList(e);
              if (e.length > 0) {
                setGeneralSettingsAccess(true);
              } else if (e.length === 0) {
                setGeneralSettingsAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* import settings */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Import Settings</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setImportSettingsAccess(e.target.checked);
                if (
                  importSettingsAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = importSettingsAccessList;
                  list?.push("VIEW_ONLY");
                  setImportSettingsAccessList(list);
                } else if (e.target.checked === false) {
                  setImportSettingsAccessList([]);
                }
              }}
              checked={importSettingsAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Import Settings</div>
          <Checkbox.Group
            value={importSettingsAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setImportSettingsAccessList(e);
              if (e.length > 0) {
                setImportSettingsAccess(true);
              } else if (e.length === 0) {
                setImportSettingsAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="UPDATE">Import</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* template settings */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">
            Template Settings
          </div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setTemplateSettingsAccess(e.target.checked);
                if (
                  templateSettingsAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = templateSettingsAccessList;
                  list?.push("VIEW_ONLY");
                  setTemplateSettingsAccessList(list);
                } else if (e.target.checked === false) {
                  setTemplateSettingsAccessList([]);
                }
              }}
              checked={templateSettingsAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Template Settings</div>
          <Checkbox.Group
            value={templateSettingsAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setTemplateSettingsAccessList(e);
              if (e.length > 0) {
                setTemplateSettingsAccess(true);
              } else if (e.length === 0) {
                setTemplateSettingsAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* dashboard settings */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">
            Dashboard Settings
          </div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setDashboardSettingsAccess(e.target.checked);
                if (
                  dashboardSettingsAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = dashboardSettingsAccessList;
                  list?.push("VIEW_ONLY");
                  setDashboardSettingsAccessList(list);
                } else if (e.target.checked === false) {
                  setDashboardSettingsAccessList([]);
                }
              }}
              checked={dashboardSettingsAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Dashboard Settings</div>
          <Checkbox.Group
            value={dashboardSettingsAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setDashboardSettingsAccessList(e);
              if (e.length > 0) {
                setDashboardSettingsAccess(true);
              } else if (e.length === 0) {
                setDashboardSettingsAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* Autoreminder settings */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">
            Autoreminder Settings
          </div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setAutoreminderSettingsAccess(e.target.checked);
                if (
                  autoreminderSettingsAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = autoreminderSettingsAccessList;
                  list?.push("VIEW_ONLY");
                  setAutoreminderSettingsAccessList(list);
                } else if (e.target.checked === false) {
                  setAutoreminderSettingsAccessList([]);
                }
              }}
              checked={autoreminderSettingsAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Autoreminder Settings</div>
          <Checkbox.Group
            value={autoreminderSettingsAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setAutoreminderSettingsAccessList(e);
              if (e.length > 0) {
                setAutoreminderSettingsAccess(true);
              } else if (e.length === 0) {
                setAutoreminderSettingsAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* lead settings */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Lead Settings</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setLeadSettingsAccess(e.target.checked);
                if (
                  leadSettingsAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = leadSettingsAccessList;
                  list?.push("VIEW_ONLY");
                  setLeadSettingsAccessList(list);
                } else if (e.target.checked === false) {
                  setLeadSettingsAccessList([]);
                }
              }}
              checked={leadSettingsAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Lead Settings</div>
          <Checkbox.Group
            value={leadSettingsAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setLeadSettingsAccessList(e);
              if (e.length > 0) {
                setLeadSettingsAccess(true);
              } else if (e.length === 0) {
                setLeadSettingsAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* Appointment settings */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">
            Appointment Settings
          </div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setAppointmentSettingsAccess(e.target.checked);
                if (
                  appointmentSettingsAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = appointmentSettingsAccessList;
                  list?.push("VIEW_ONLY");
                  setAppointmentSettingsAccessList(list);
                } else if (e.target.checked === false) {
                  setAppointmentSettingsAccessList([]);
                }
              }}
              checked={appointmentSettingsAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Appointment Settings</div>
          <Checkbox.Group
            value={appointmentSettingsAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setAppointmentSettingsAccessList(e);
              if (e.length > 0) {
                setAppointmentSettingsAccess(true);
              } else if (e.length === 0) {
                setAppointmentSettingsAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* Case settings */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">Case Settings</div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setCaseSettingsAccess(e.target.checked);
                if (
                  caseSettingsAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = caseSettingsAccessList;
                  list?.push("VIEW_ONLY");
                  setCaseSettingsAccessList(list);
                } else if (e.target.checked === false) {
                  setCaseSettingsAccessList([]);
                }
              }}
              checked={caseSettingsAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Case Settings</div>
          <Checkbox.Group
            value={caseSettingsAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setCaseSettingsAccessList(e);
              if (e.length > 0) {
                setCaseSettingsAccess(true);
              } else if (e.length === 0) {
                setCaseSettingsAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* quotation settings */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">
            Quotation Settings
          </div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setQuotatinSettingsAccess(e.target.checked);
                if (
                  quotatinSettingsAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = quotatinSettingsAccessList;
                  list?.push("VIEW_ONLY");
                  setQuotatinSettingsAccessList(list);
                } else if (e.target.checked === false) {
                  setQuotatinSettingsAccessList([]);
                }
              }}
              checked={quotatinSettingsAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Quotation Settings</div>
          <Checkbox.Group
            value={quotatinSettingsAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setQuotatinSettingsAccessList(e);
              if (e.length > 0) {
                setQuotatinSettingsAccess(true);
              } else if (e.length === 0) {
                setQuotatinSettingsAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* check in settings */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">
            Check In Settings
          </div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setcheckInSettingsAccess(e.target.checked);
                if (
                  checkInSettingsAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = checkInSettingsAccessList;
                  list?.push("VIEW_ONLY");
                  setcheckInSettingsAccessList(list);
                } else if (e.target.checked === false) {
                  setcheckInSettingsAccessList([]);
                }
              }}
              checked={checkInSettingsAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Check In Settings</div>
          <Checkbox.Group
            value={checkInSettingsAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setcheckInSettingsAccessList(e);
              if (e.length > 0) {
                setcheckInSettingsAccess(true);
              } else if (e.length === 0) {
                setcheckInSettingsAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* Employee settings */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">
            Employee Settings
          </div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setEmployeeSettingsAccess(e.target.checked);
                if (
                  employeeSettingsAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = employeeSettingsAccessList;
                  list?.push("VIEW_ONLY");
                  setEmployeeSettingsAccessList(list);
                } else if (e.target.checked === false) {
                  setEmployeeSettingsAccessList([]);
                }
              }}
              checked={employeeSettingsAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Employee Settings</div>
          <Checkbox.Group
            value={employeeSettingsAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setEmployeeSettingsAccessList(e);
              if (e.length > 0) {
                setEmployeeSettingsAccess(true);
              } else if (e.length === 0) {
                setEmployeeSettingsAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        {/* Workflow settings */}

        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle permissionFont">
            Workflow Settings
          </div>
          <div style={{ width: "100%" }}>
            <Checkbox
              onChange={(e) => {
                setWorkflowSettingsAccess(e.target.checked);
                if (
                  workflowSettingsAccessList.length === 0 &&
                  e.target.checked === true
                ) {
                  let list = workflowSettingsAccessList;
                  list?.push("VIEW_ONLY");
                  setWorkflowSettingsAccessList(list);
                } else if (e.target.checked === false) {
                  setWorkflowSettingsAccessList([]);
                  setWorkflowSettingsEducationAccessList([]);
                }
              }}
              checked={workflowSettingsAccess}
            >
              Access
            </Checkbox>
          </div>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage workflow Settings</div>
          <Checkbox.Group
            value={workflowSettingsAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setWorkflowSettingsAccessList(e);
              if (
                e.length > 0 &&
                workflowSettingsEducationAccessList?.length > 0
              ) {
                setWorkflowSettingsAccess(true);
              } else if (
                e.length === 0 &&
                workflowSettingsEducationAccessList?.length === 0
              ) {
                setWorkflowSettingsAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD_COUNTRY">Add Country</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_STATUS">Change Status</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_DEFAULT_STATUS">
                  Change Default Status
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Workflow</div>
          <Checkbox.Group
            value={workflowSettingsEducationAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setWorkflowSettingsEducationAccessList(e);
              if (e.length > 0 && workflowSettingsAccessList?.length > 0) {
                setWorkflowSettingsAccess(true);
              } else if (
                e.length === 0 &&
                workflowSettingsAccessList?.length === 0
              ) {
                setWorkflowSettingsAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="ADD_UPDATE_WORKFLOW_STATUS">
                  Add/Modify Workflow Status
                </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="DELETE">Delete</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_DEFAULT_STATUS">
                  Change Default Status
                </Checkbox>
              </Col>
              <Checkbox value="ADD_UPDATE_WORKFLOW_DOCUMENT">
                Add/Modify Workflow Document
              </Checkbox>
            </Row>
          </Checkbox.Group>
        </div>
        {/* <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Migration Workflow</div>
          <Checkbox.Group
            value={workflowSettingsMigrationAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setWorkflowSettingsMigrationAccessList(e);
              if (e.length > 0) {
                setWorkflowSettingsAccess(true);
              } else if (e.length === 0) {
                setWorkflowSettingsAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="ADD_UPDATE_WORKFLOW_STATUS">
                  Add/Modify Workflow Status
                </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="DELETE">Delete</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_DEFAULT_STATUS">
                  Change Default Status
                </Checkbox>
              </Col>
              <Checkbox value="ADD_UPDATE_WORKFLOW_DOCUMENT">
                Add/Modify Workflow Document
              </Checkbox>
            </Row>
          </Checkbox.Group>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        <div className="permissionRow">
          <div className="permissionTitle">Manage Others Workflow</div>
          <Checkbox.Group
            value={workflowSettingsOthersAccessList}
            onChange={(e) => {
              console.log("lead access", e);
              setWorkflowSettingsOthersAccessList(e);
              if (e.length > 0) {
                setWorkflowSettingsAccess(true);
              } else if (e.length === 0) {
                setWorkflowSettingsAccess(false);
              }
            }}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={4}>
                <Checkbox value="ADD">Add</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="UPDATE">Update</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="ADD_UPDATE_WORKFLOW_STATUS">
                  Add/Modify Workflow Status
                </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="VIEW_ONLY">View Only</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="DELETE">Delete</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="CHANGE_DEFAULT_STATUS">
                  Change Default Status
                </Checkbox>
              </Col>
              <Checkbox value="ADD_UPDATE_WORKFLOW_DOCUMENT">
                Add/Modify Workflow Document
              </Checkbox>
            </Row>
          </Checkbox.Group> 
        </div>*/}

        <Form.Item style={{ marginTop: "2rem" }}>
          <Button block type="primary" htmlType="submit">
            Add New Permission
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddPermission;

{
  /* <Checkbox.Group
          options={addPermissionState.permissions}
          defaultValue={["Apple"]}
          onChange={(values) => {
            setPermissions(values);
            console.log("from checklist", values);
          }}
        /> */
}
