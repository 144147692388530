import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const UpdateWorkflowStatus = ({
  statusList,
  setStatusList,
  workflowStatusRecord,
  workflowRecord,
  isUpdateWorkflowStatusModalVisible,
  setIsUpdateWorkflowStatusModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const successFn = (response) => {
    setStatusList(response?.data?.data);
  };
  const updateWorkflowStatusSubmitHandler = (value) => {
    const formData = {
      ...value,
    };
    dispatch({
      type: "UPDATE_WORKFLOW_STATUS_REQUEST",
      payload: {
        fetchId: workflowRecord.id,
        id: workflowStatusRecord.id,
        formData,
      },
      payload2: successFn,
    });
    // form.resetFields();
    setIsUpdateWorkflowStatusModalVisible(false);
  };
  return (
    <Modal
      title="Update Workflow Status"
      open={isUpdateWorkflowStatusModalVisible}
      onCancel={() => {
        setIsUpdateWorkflowStatusModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateWorkflowStatusSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["statusName"],
            value: workflowStatusRecord?.statusName,
          },
          {
            name: ["tasks"],
            value: workflowStatusRecord?.tasks,
          },
          {
            name: ["colorCode"],
            value: workflowStatusRecord?.colorCode,
          },
        ]}
      >
        <Form.Item
          label="Work Flow Status"
          name={"statusName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a workflow name!",
            },
          ]}
        >
          <Input placeholder="Enter Work Flow Name" />
        </Form.Item>
        <Form.Item
          label="Task"
          name={"tasks"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a task!",
            },
          ]}
        >
          <Input placeholder="Enter a Task" />
        </Form.Item>
        <Form.Item
          label="Color Code"
          name={"colorCode"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter color code for the status!",
            },
          ]}
        >
          <Input type="color" placeholder="Hex value for color code" />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Workflow Status
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateWorkflowStatus;
