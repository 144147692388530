import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import AddCountry from "../../Leads/Country/AddCountry";
import { useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import axios from "axios";
import { scrollToTopFormError } from "../../../HelperFunction";
const { Option } = Select;

const AddBranch = ({ isAddBranchModalVisible, setIsAddBranchModalVisible }) => {
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      profileState: state.ProfileReducer,
    };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_LEADS_DROPDOWN_REQUEST",
      payload: {
        fromInstituteVisaCase: true,
        visaSuccessFunction: leadSuccessFunction,
      },
    });
  }, []);
  const leadSuccessFunction = (response) => {
    setBranchData((previousData) => {
      return {
        ...previousData,
        country: response?.data?.defaultCountry?.name,
      };
    });
  };

  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);

  const [branchData, setBranchData] = useState({
    isSameAsParent: false,
    isSameAsContactEmail: false,
    contactEmail: "",
    ABNACN: "",
    country: null,
  });
  const [isSameAsParent, setIsSameAsParent] = useState(false);
  const [isSameAsContactEmail, setIsSameAsContactEmail] = useState(false);
  const [form] = Form.useForm();
  const addBranchSubmitHandler = (value) => {
    const formData = {
      branchName: value.branchName,
      branchLocation: value.branchLocation,
      branchContactPersonInfo: {
        contactFirstName: value.contactFirstName,
        contactLastName: value.contactLastName,
        contactEmail: value.contactEmail,
        contactNumber: value.contactNumber,
      },
      country: value.country,
      companyRegistrationNumber: value.companyRegistrationNumber,
      password: value.password,
      confirmPassword: value.confirmPassword,
      loginEmail: value.loginemail,
      // loginCompanyUrl: value?.loginCompanyUrl,
    };
    dispatch({ type: "ADD_BRANCH_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddBranchModalVisible(false);
  };

  return (
    <Drawer
      title="Add Branch"
      open={isAddBranchModalVisible}
      onClose={() => {
        setIsAddBranchModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={addBranchSubmitHandler}
        onFinishFailed={scrollToTopFormError}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["companyRegistrationNumber"],
            value: isSameAsParent
              ? store.profileState?.profile?.companyRegistrationNumber
              : branchData.ABNACN,
          },
          {
            name: ["loginemail"],
            value: branchData.isSameAsContactEmail
              ? branchData.contactEmail
              : null,
          },
          {
            name: ["country"],
            value: branchData?.country ? branchData?.country : null,
          },
        ]}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Branch Name"
            name={"branchName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter branch name!",
              },
            ]}
          >
            <Input placeholder="Branch Name" />
          </Form.Item>
          <Form.Item
            label="Branch Location"
            name={"branchLocation"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter branch location!",
              },
            ]}
          >
            <Input placeholder="Branch Location" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Contact First Name"
            name={"contactFirstName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a contact first name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Contact Last Name"
            name={"contactLastName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a contact last name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Form.Item
            label="Contact Email"
            name={"contactEmail"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid contact email!",
              },
            ]}
          >
            <Input
              type="email"
              onChange={(e) => {
                setBranchData((previousData) => {
                  return {
                    ...previousData,
                    contactEmail: e.target.value,
                  };
                });
              }}
              //   value={branchContactInfo[index].contactEmail}
            />
          </Form.Item>
          <Form.Item
            label="Contact Number"
            name={"contactNumber"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a contact number!",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Mobile number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input
              type={"number"}
              //   value={branchContactInfo[index].contactNumber}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item
              label="Country"
              name={"country"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a country!",
                },
              ]}
            >
              <Select
                optionFilterProp="children"
                showSearch
                filterOption={(input, option) => {
                  return (option?.children?.props?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {store.leadState?.leadsDropdown?.countries?.map((dataObj) => {
                  return (
                    <Option key={dataObj.id} value={dataObj.countryName}>
                      <div
                        onClick={() => {
                          setBranchData((previousData) => {
                            return {
                              ...previousData,
                              country: dataObj.countryName,
                            };
                          });
                        }}
                        className={"selectOptionDiv"}
                      >
                        {dataObj.countryName}
                      </div>
                    </Option>
                  );
                })}
              </Select>
              {/* <Select>
                {store.leadState.leadsDropdown?.countries?.map((dataObj) => {
                  return (
                    <Option key={dataObj.id}>
                      <div onClick={() => {}}>{dataObj.countryName}</div>
                    </Option>
                  );
                })}
              </Select> */}
            </Form.Item>
            {/* <a
              className="plusButton"
              onClick={() => {
                setIsAddCountryModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a> */}
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              alignItems: "flex-end",
              gap: "0.2rem",
              width: "100%",
            }}
          >
            <Form.Item
              label="ABN/ACN"
              name={"companyRegistrationNumber"}
              style={{ width: "70%" }}
            >
              <Input
                placeholder="Company Registration Number"
                onChange={(e) => {
                  setBranchData((previousData) => {
                    return {
                      ...previousData,
                      ABNACN: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item name={"sameAsParent"} valuePropName="checked">
              <Checkbox
                onChange={(e) => {
                  setIsSameAsParent(e.target.checked);
                }}
              >
                Same as parent?
              </Checkbox>
            </Form.Item>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#eeeeee55",
            borderRadius: "0.5rem",
            padding: "0.5rem",
          }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            {/* <Form.Item
              label="Domain"
              name="loginCompanyUrl"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter your preferred domain!",
                },
                {
                  validator: async (_, value) => {
                    const response = await axios.get(
                      `${process.env.REACT_APP_TEST_URL}v1/api/customer/validate-company-url?loginCompanyUrl=${value}`,
                      {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    );
                    if (
                      response?.data === false ||
                      value === "" ||
                      value === null ||
                      value === undefined
                    ) {
                      return Promise.resolve();
                    } else if (response?.data === true) {
                      return Promise.reject(
                        new Error(
                          "Entered domain already exists! Try another one."
                        )
                      );
                    }
                  },
                },
              ]}
              validateTrigger={["onBlur"]}
            >
              <Input
                placeholder="companyName"
                addonAfter=".khrouch.com"
                size="medium"
                style={{ borderRadius: "25px" }}
              />
            </Form.Item> */}
            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                alignItems: "flex-end",
                gap: "0.2rem",
                width: "100%",
              }}
            >
              <Form.Item
                label="Login Email"
                name={"loginemail"}
                style={{ width: "80%" }}
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter a valid contact email!",
                  },
                ]}
              >
                <Input type="email" />
              </Form.Item>
              <Form.Item name={"sameAsContactEmail"} valuePropName="checked">
                <Checkbox
                  onChange={(e) => {
                    setBranchData((previousData) => {
                      return {
                        ...previousData,
                        isSameAsContactEmail: e.target.checked,
                      };
                    });
                  }}
                >
                  Same as contact email?
                </Checkbox>
              </Form.Item>
            </div>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Password"
              name={"password"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter password!",
                },
              ]}
            >
              <Input.Password placeholder="Password!" />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name={"confirmPassword"}
              dependencies={["password"]}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please confirm the password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Password and confirm password do not match!")
                    );
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password placeholder="Confirm Password!" />
            </Form.Item>
          </div>
        </div>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Branch
          </Button>
        </Form.Item>
      </Form>
      {isAddCountryModalVisible && (
        <AddCountry
          isAddCountryModalVisible={isAddCountryModalVisible}
          setIsAddCountryModalVisible={setIsAddCountryModalVisible}
        />
      )}
    </Drawer>
  );
};

export default AddBranch;
