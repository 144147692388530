import { Button, Form, Input, Modal, Select, message } from "antd";
import { update } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";

export default function UpdateTaskStatus({
  isStatusUpdateModalVisible,
  setStatusUpdateModalVisible,
  record,
  updateType = "",
  fetchClientById,
  fetchCaseDetail,
  fetchCompanyByID,
  fetchInstituteById,
}) {
  const { Option } = Select;
  const { TextArea } = Input;
  const dispatch = useDispatch();

  const handlUpdateTaskStatus = (values) => {
    dispatch({
      type: "UPDATE_TASK_STATUS_REQUEST",
      payload: { ...values, taskId: record?.id },
      isSuccessFn: true,
      successFunction: () => {
        if (updateType === "client") {
          fetchClientById();
        } else if (updateType === "case") {
          fetchCaseDetail();
        } else if (updateType === "company") {
          fetchCompanyByID();
        } else if (updateType === "institute") {
          fetchInstituteById();
        }
        message.success("Task status updated successfully");
        setStatusUpdateModalVisible(false);
      },
    });
  };

  return (
    <Modal
      title="Update Task Status"
      open={isStatusUpdateModalVisible}
      onCancel={() => {
        setStatusUpdateModalVisible(false);
      }}
      footer={false}
    >
      <Form
        layout="vertical"
        initialValues={{
          taskStatus: record?.taskStatus,
        }}
        onFinish={handlUpdateTaskStatus}
      >
        <Form.Item
          label="Task Status"
          name={"taskStatus"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select a taskStatus!",
            },
          ]}
        >
          <Select>
            <Option value={"PENDING"}>Pending</Option>
            <Option value={"ASSIGNED"}>Assigned</Option>
            <Option value={"COMPLETED"}>Completed</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Update Note"
          name={"updateNote"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter updateNote!",
            },
          ]}
        >
          <TextArea placeholder="Write recent update" />
        </Form.Item>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button type="primary" htmlType="submit">
            Update Task Status
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
