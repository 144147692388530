import { Button, Divider, Empty } from "antd";
import React, { useEffect, useState } from "react";
import AddEditInstituteBranch from "./AddEditInstituteBranch";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
export default function InstituteBranchDetails({ record }) {
  const dispatch = useDispatch();
  const InstituteState = useSelector((store) => store?.InstituteReducer);
  const [instituteBranches, setInstituteBranches] = useState([]);

  useEffect(() => {
    fetchInstituteBranches();
  }, []);

  const fetchInstituteBranches = () => {
    dispatch({
      type: "FETCH_INSTITUTE_BRANCHS_REQUEST",
      payload: record?.id,
      isSuccessFn: true,
      successFunction: (res) => {
        setInstituteBranches(res?.data?.instiuteBranchResponse);
      },
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [branch, setBranch] = useState(null);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="primary"
          onClick={() => {
            setIsModalOpen(true);
          }}
          style={{ width: "fit-content" }}
        >
          Add Branch
        </Button>
      </div>

      {instituteBranches?.length > 0 ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
            margin: "1rem 0",
          }}
        >
          {instituteBranches?.map((branch) => (
            <BranchCardDetail
              branch={branch}
              setIsEditModalOpen={setIsEditModalOpen}
              setBranch={setBranch}
            />
          ))}
        </div>
      ) : (
        <Empty />
      )}
      {isModalOpen && (
        <AddEditInstituteBranch
          type="Add"
          record=""
          instituteIdNumber={record?.id}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          fetchInstituteBranches={fetchInstituteBranches}
        />
      )}
      {isEditModalOpen && (
        <AddEditInstituteBranch
          type="Edit"
          record={branch}
          instituteIdNumber={record?.id}
          isModalOpen={isEditModalOpen}
          setIsModalOpen={setIsEditModalOpen}
          fetchInstituteBranches={fetchInstituteBranches}
        />
      )}
    </div>
  );
}

export const BranchCardDetail = ({ branch, setIsEditModalOpen, setBranch }) => {
  return (
    <div
      style={{
        border: "1px solid #e6e6e6",
        borderRadius: "0.5rem",
        padding: "1rem 1.5rem",
        position: "relative",
      }}
    >
      <EditOutlined
        onClick={() => {
          setBranch(branch);
          setIsEditModalOpen(true);
        }}
        className="update-button"
        title="Update Branch"
        style={{
          position: "absolute",
          right: "-0.75rem",
          fontSize: "1.3rem",
          background: "#de6421",
          borderRadius: "100%",
          color: "white",
          padding: "0.2rem",
          cursor: "pointer",
          top: "0.5rem",
        }}
      />
      {detailFunction(
        "Branch",
        branch?.branchName ? branch?.branchName : "N/A"
      )}
      {detailFunction("Email", branch?.email ? branch?.email : "N/A")}
      {detailFunction("Country", branch?.country ? branch?.country : "N/A")}
      {detailFunction("City", branch?.city ? branch?.city : "N/A")}
    </div>
  );
};

export const detailFunction = (title, value) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span>{title}</span>
        <span style={{ fontWeight: "bold" }}>{value}</span>
      </div>
      <Divider style={{ margin: "0.5rem 0" }} />
    </div>
  );
};
