import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { formattedDate } from "../../../HelperFunction";
import moment from "moment";
import PdfTable from "./PdfTable";

const PDFFile = ({ record, companyProfile }) => {
  Font.register({
    family: `'Manrope', sans-serif`,
    src: "https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Nunito:wght@300;400;500&family=Roboto:wght@300;400;500&display=swap", // URL to the font file
  });
  const styles = StyleSheet.create({
    Document: { fontFamily: "Roboto" },
    Text: { fontSize: 11 },
    logo: {
      width: 74,
      height: 66,
      marginLeft: "auto",
      marginRight: "auto",
    },
  });
  return (
    <Document>
      <Page
        size="A4"
        style={{
          marginTop: "20pt",
          marginLeft: 0,
          marginRight: "20pt",
          marginBottom: "20pt",
          padding: "10pt",
          gap: 10,
        }}
      >
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text>Quote No: #{record?.id}</Text>
          <Image style={styles.logo} src={companyProfile?.companyLogo} />
          <View style={{ flexDirection: "column", gap: "2pt" }}>
            <Text style={styles.Text}>{companyProfile?.companyName}</Text>
            <Text style={styles.Text}>{companyProfile?.companyAddress}</Text>
            <Text style={styles.Text}>
              Phone: {companyProfile?.callingCode} {companyProfile?.phoneNumber}
            </Text>
            <Text style={styles.Text}>{companyProfile?.emailId}</Text>
            <Text style={styles.Text}>
              ABN: {companyProfile?.companyRegistrationNumber}
            </Text>
          </View>
        </View>
        <Text style={{ border: "1px dashed black" }}></Text>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ flexDirection: "column", gap: "2pt" }}>
            <Text style={styles.Text}>
              ATTN: {record?.customerId?.fullName}
            </Text>
            <Text style={styles.Text}>Phone: {record?.customerId?.mobile}</Text>
            <Text style={styles.Text}>Email: {record?.customerId?.email}</Text>
          </View>
          <View style={{ flexDirection: "column", gap: "2pt" }}>
            <Text style={styles.Text}>
              Date: {moment().utc().local().format("DD-MM-YYYY")}{" "}
            </Text>
            <Text style={[styles.Text, { color: "red" }]}>
              Quote Valid Until:{" "}
              {record?.quoteExpiryDate
                ? formattedDate(record?.quoteExpiryDate)
                : "N/A"}
            </Text>
          </View>
        </View>
        <Text style={styles.Text}>
          Nature of Quote: {record?.natureOfProject}
        </Text>
        <PdfTable record={record} packages={record?.quotationPackages} />
        <Text style={styles.Text}>
          Timeline: {record?.associatedTimelineId?.name ?? "N/A"}
        </Text>
        <Text
          style={[
            styles.Text,
            {
              color: "red",
            },
          ]}
        >
          Note: {record?.specialNote ?? "N/A"}
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
            }}
          >
            <Text style={styles.Text}>Kind Regards,</Text>
            <Text style={styles.Text}>
              {companyProfile?.firstName ?? ""} {companyProfile?.lastName ?? ""}
            </Text>
          </View>
        </View>

        <Text
          style={[
            styles.Text,
            {
              textAlign: "center",
              borderTop: "1pt dashed black",
              borderBottom: "1pt dashed black",
              padding: 3,
            },
          ]}
        >
          Thank you for the opportunity to quote.
        </Text>
      </Page>
    </Document>
  );
};

export default PDFFile;
