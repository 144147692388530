import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Tag,
} from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  date,
  date00Time,
  dateAndTime,
  scrollToTopFormError,
} from "../../HelperFunction";
import { assignIn } from "lodash";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

const AddTask = ({
  isAddTaskModalVisible,
  setIsAddTaskModalVisible,
  taskType = "",
  taskId = null,
  fetchDetailRecordById,
  fetchCaseDetail,
  fetchCompanyByID,
  fetchInstituteById,
}) => {
  const dispatch = useDispatch();
  const VisaState = useSelector((store) => store?.VisasReducer);
  const AddTaskState = useSelector((state) => state.VisasReducer);
  const ProfileState = useSelector((store) => store?.ProfileReducer?.profile);
  const [assignedTo, setAssignedTo] = useState(null);
  const [emailId, setEmailId] = useState(null);
  const [openMore, setOpenMore] = useState(false);
  const [labelName, setLabelName] = useState(taskType ? taskType : "Client");
  const [activeList, setActiveList] = useState([]);
  const [employee, setEmployee] = useState(
    VisaState?.taskDropdown?.employees ? VisaState?.taskDropdown?.employees : []
  );

  console.log("task type", taskType);

  useEffect(() => {
    dispatch({
      type: "FETCH_TASK_DROPDOWN_REQUEST",
      payload: localStorage.getItem("branchId"),
      isSuccessFn: true,
      successFunction: (res) => {
        if (taskType === "Case") {
          setActiveList(res?.data?.cases);
        } else if (taskType === "Client") {
          setActiveList(res?.data?.clients);
        } else if (taskType === "Institute") {
          setActiveList(res?.data?.institutes);
        } else if (taskType === "Company") {
          setActiveList(res?.data?.companies);
        }
        setEmployee(res?.data?.employees);
      },
    });
  }, []);

  useEffect(() => {
    if (isAddTaskModalVisible === true) {
      dispatch({
        type: "FETCH_CASES_DROPDOWN_REQUEST",
        payload: {
          branchId: localStorage.getItem("branchId"),
        },
      });
    }
  }, [isAddTaskModalVisible]);
  const [form] = Form.useForm();
  const [taskData, setTaskData] = useState({
    forCase: false,
    caseId: null,
  });
  const addTaskSubmitHandler = (value) => {
    const startDate = date00Time(value.startDate);
    const dueDate = date00Time(value.dueDate);
    const formData = {
      startDate,
      dueDate,
      ...value,
      assignedTo: assignedTo,
      taskName: value?.taskName?.toString(),
    };
    dispatch({
      type: "ADD_TASK_REQUEST",
      payload: { branchId: localStorage.getItem("branchId"), formData },
      isSuccessFn: true,
      successFunction: () => {
        if (taskType === "Client") {
          fetchDetailRecordById();
        } else if (taskType === "Case") {
          fetchCaseDetail();
        } else if (taskType === "Company") {
          fetchCompanyByID();
        } else if (taskType === "Institute") {
          fetchInstituteById();
        }
      },
    });
    form.resetFields();
    setIsAddTaskModalVisible(false);
  };

  useEffect(() => {
    setEmailId(
      AddTaskState.caseDropdown?.consultancyEmployee?.find(
        (data) => data?.emailId === ProfileState?.emailId
      )?.id
    );
  }, []);

  return (
    <Drawer
      title="Add Task"
      open={isAddTaskModalVisible}
      onClose={() => {
        setIsAddTaskModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={addTaskSubmitHandler}
        onFinishFailed={scrollToTopFormError}
        colon={true}
        form={form}
        className="drawerStyle"
        initialValues={{
          startDate: moment(),
          taskRelateTo: taskType,
          taskName: taskId,
        }}
      >
        <Form.Item
          label="Task Title"
          name={"taskTitle"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter task!",
            },
          ]}
        >
          <Input placeholder="Task Title" />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Start Date"
            name={"startDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick start date!",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>
          <Form.Item
            label="Due Date"
            name={"dueDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a due date",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Form.Item
            label="Assign Task To"
            name={"assignedToEmail"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter assignedTo!",
              },
            ]}
          >
            <Select
              mode="multiple"
              onChange={(value, option) => {
                setAssignedTo(option?.map((keyobj) => parseInt(keyobj?.key)));

                form.setFieldsValue({
                  assignToMyself: value?.includes(ProfileState?.emailId)
                    ? true
                    : false,
                });
              }}
            >
              {AddTaskState.caseDropdown?.consultancyEmployee?.map(
                (dataObj) => {
                  return (
                    <Option key={dataObj.id} value={dataObj?.emailId}>
                      {dataObj.emailId}
                    </Option>
                  );
                }
              )}
            </Select>
          </Form.Item>
          <Form.Item
            name={"assignToMyself"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox
              onChange={(e) => {
                console.log(
                  "testing",
                  ProfileState?.emailId,
                  ProfileState,
                  assignedTo?.includes(emailId),
                  assignedTo
                );
                if (assignedTo?.includes(emailId)) {
                  setAssignedTo(assignedTo?.filter((id) => id !== emailId));

                  form.setFieldsValue({
                    assignedToEmail: form
                      .getFieldValue("assignedToEmail")
                      ?.filter((email) => email !== ProfileState?.emailId),
                  });
                } else {
                  assignedTo?.length > 0
                    ? setAssignedTo((prev) => [...prev, emailId])
                    : setAssignedTo([emailId]);
                  assignedTo?.length > 0
                    ? form.setFieldsValue({
                        assignedToEmail: [
                          ...form?.getFieldValue("assignedToEmail"),
                          ProfileState?.emailId,
                        ],
                      })
                    : form.setFieldsValue({
                        assignedToEmail: [ProfileState?.emailId],
                      });
                }
              }}
            >
              Assign to Myself
            </Checkbox>
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Task Status"
            name={"taskStatus"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a taskStatus!",
              },
            ]}
          >
            <Select>
              <Option value={"PENDING"}>Pending</Option>
              <Option value={"ASSIGNED"}>Assigned</Option>
              <Option value={"COMPLETED"}>Completed</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Priority"
            name={"priority"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter priority!",
              },
            ]}
          >
            <Select>
              <Option value={"NORMAL"}>Normal</Option>
              <Option value={"HIGH"}>High</Option>
              <Option value={"URGENT"}>Urgent</Option>
            </Select>
          </Form.Item>
        </div>

        <Form.Item
          label="Task Description"
          name={"taskDescription"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter !",
            },
          ]}
        >
          <TextArea rows={5} placeholder="Write about task" />
        </Form.Item>

        {!openMore && (
          <div style={{ margin: "0 0 1rem" }}>
            <Divider style={{ margin: 0 }} />
            <Button
              type="outlined"
              size="small"
              icon={<DownOutlined />}
              onClick={() => {
                setOpenMore(true);
              }}
            >
              View More
            </Button>
          </div>
        )}

        {openMore && (
          <div>
            <Form.Item label="Related To" name="taskRelateTo">
              <Radio.Group
                onChange={(e) => {
                  setLabelName(e.target.value);
                  form.setFieldsValue({
                    partnerName: null,
                    projectManager: [],
                  });

                  if (e.target.value === "Client") {
                    setActiveList(VisaState?.taskDropdown?.clients);
                  } else if (e.target.value === "Case") {
                    setActiveList(VisaState?.taskDropdown?.cases);
                  } else if (e.target.value === "Institute") {
                    setActiveList(VisaState?.taskDropdown?.institutes);
                  } else if (e.target.value === "Company") {
                    setActiveList(VisaState?.taskDropdown?.companies);
                  }
                }}
              >
                <Radio value="Client">Client</Radio>
                <Radio value="Case">Case</Radio>
                <Radio value="Institute">Institute</Radio>
                <Radio value="Company">Company</Radio>
                <Radio value="Inhouse">Inhouse</Radio>
              </Radio.Group>
            </Form.Item>
            {labelName !== "Inhouse" && (
              <Form.Item name="taskName" label={`${labelName} Name`}>
                <Select showSearch placeholder="Select Partner Name">
                  {labelName === "Client" &&
                    activeList?.map((data) => (
                      <Select.Option value={data?.id}>
                        {data?.email} ({data?.fullName})
                      </Select.Option>
                    ))}

                  {labelName === "Case" &&
                    activeList?.map((data) => (
                      <Select.Option value={data?.id}>
                        {data?.customer?.email} ({data?.customer?.fullName}){" "}
                        <Tag color="blue">KH{data?.id}</Tag>
                      </Select.Option>
                    ))}
                  {labelName === "Institute" &&
                    activeList?.map((data) => (
                      <Select.Option value={data?.id}>
                        {data?.instituteName}
                      </Select.Option>
                    ))}
                  {labelName === "Company" &&
                    activeList?.map((data) => (
                      <Select.Option value={data?.id}>
                        {data?.companyName} ({data?.email})
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item name="taskManager" label="Task Manager">
              <Select mode="multiple" placeholder="Select task manager">
                {employee?.map((emp) => (
                  <Select.Option value={emp?.id}>
                    {emp?.emailId} ({emp?.fullName}){" "}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div style={{ margin: "1rem 0" }}>
              <Button
                type="outlined"
                size="small"
                icon={<UpOutlined />}
                onClick={() => {
                  setOpenMore(false);
                }}
              >
                View Less
              </Button>
              <Divider style={{ margin: 0 }} />
            </div>
          </div>
        )}
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Task
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddTask;
