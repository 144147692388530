import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, message } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const AddSubclassDocument = ({
  subclassId,
  isModalVisible,
  setIsModalVisible,
  fetchEducationSubClassDocumentById,
}) => {
  const documentState = useSelector((state) => state.ClientsReducer);
  const [selectedDocument, setSelectedDocument] = useState();
  const dispatch = useDispatch({});

  const [isAddDocumentTypeModalVisible, setIsAddDocumentTypeModalVisible] =
    useState(false);
  const addClientDocumentsSubmitHandler = (value) => {
    const documentFormData = new FormData();
    documentFormData.append("document", value.documentType);
    documentFormData.append("document", selectedDocument);
  };

  const [documents, setDocuments] = useState([]);
  const addDocumentsInput = () => {
    setDocuments([
      ...documents,
      {
        documentType: "",
        documents: "",
      },
    ]);
  };

  console.log("checking document", documents);
  const removeDocumentInput = (index) => {
    const list = [...documents];
    list.splice(index, 1);
    setDocuments(list);
  };
  const handleDocumentTypeChange = (e, index) => {
    const list = [...documents];
    list[index].documentType = e;
    setDocuments(list);
  };
  const handleDocumentsChange = (e, index) => {
    const list = [...documents];
    list[index].documents = selectedDocument ?? e;
    setDocuments(list);
  };

  const uploadDocumentHandler = (index) => {
    const documentsFormData = new FormData();
    documentsFormData.append("documentType", documents[index].documentType);
    documentsFormData.append("document", documents[index].documents);
    dispatch({
      type: "ADD_VISA_SUBCLASS_DOCUMENT_REQUEST",
      payload: { id: subclassId, formData: documentsFormData },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success("Subclass Document added successfully");
      },
    });
  };

  return (
    <Modal
      title="Add Subclass  Document"
      open={isModalVisible}
      onCancel={() => {
        setIsModalVisible(false);
        fetchEducationSubClassDocumentById();
      }}
      footer={false}
      width={767}
    >
      <Form
        layout="vertical"
        onFinish={addClientDocumentsSubmitHandler}
        colon={true}
      >
        {documents.map((singleDocument, index) => {
          return (
            <div key={index}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                }}
              >
                <Form
                  style={{
                    display: "grid",
                    gridTemplateColumns: "3fr 2fr 1fr",
                    gap: "1rem 0.5rem",
                    paddingRight: "2rem",
                  }}
                >
                  <Form.Item
                    label="Document Type"
                    rules={[
                      {
                        required: true,
                        message: "Please enter document type!",
                      },
                    ]}
                    onChange={(e) => {
                      handleDocumentTypeChange(e.target.value, index);
                    }}
                  >
                    <Input placeholder="Document Type" />
                  </Form.Item>
                  <Form.Item
                    label="Document"
                    rules={[
                      {
                        required: true,
                        message: "Please upload the document!",
                      },
                    ]}
                  >
                    <Input
                      type="file"
                      name={"documents"}
                      placeholder="document"
                      onChange={(e) => {
                        setSelectedDocument(() => e.target.files[0]);
                        handleDocumentsChange(e.target.files[0], index);
                      }}
                    />
                  </Form.Item>

                  <Button
                    type="primary"
                    style={{ marginTop: "2rem" }}
                    onClick={(e) => {
                      uploadDocumentHandler(index);
                    }}
                  >
                    Upload
                  </Button>
                </Form>
                <CloseOutlined
                  onClick={() => removeDocumentInput(index)}
                  style={{
                    padding: "0",
                    borderRadius: "50%",
                    fontSize: "1.2rem",
                    marginLeft: "0.5rem",
                    color: "red",
                    border: "1px solid red",
                    position: "absolute",
                    right: "0",
                    padding: "2px",
                  }}
                />
              </div>
            </div>
          );
        })}
        <Button
          block
          type="dashed"
          onClick={addDocumentsInput}
          style={{ marginBottom: "1rem" }}
        >
          <PlusOutlined />
          Add Documents
        </Button>
      </Form>
    </Modal>
  );
};
