import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const UpdateCaseStatus = ({
  record,
  isUpdateCaseStatusModalVisible,
  setIsUpdateCaseStatusModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const updateCaseStatusSubmitHandler = (value) => {
    const formData = {
      ...value,
    };
    dispatch({
      type: "UPDATE_CASE_STATUS_REQUEST",
      payload: { id: record.id, formData },
    });
    // form.resetFields();
    setIsUpdateCaseStatusModalVisible(false);
  };
  return (
    <Modal
      // title="Update Case Status"
      open={isUpdateCaseStatusModalVisible}
      onCancel={() => {
        setIsUpdateCaseStatusModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateCaseStatusSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Case Status"
          name={"caseStatus"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter case status!",
            },
          ]}
        >
          <Input placeholder="Case Status" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Case Status
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateCaseStatus;
