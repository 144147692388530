import { Menu, Layout, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { Children } from "react";
import { GoDashboard } from "react-icons/go";

import { useDispatch, useSelector } from "react-redux";
import { NavL, NavLink, NavLinkink } from "react-router-dom";
import SiderDemo from "../../Components/Layout/SiderDemo";
const { Header, Sider, Content } = Layout;

const OrganizationalSettings = ({ children }) => {
  const orgnizationalSettingsState = useSelector(
    (state) => state.OrganizationalSettingsReducer
  );
  const [record, setRecord] = useState({});

  const dispatch = useDispatch();

  return (
    <SiderDemo organizationalSettings={true}>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 4fr" }}>
        <div
          style={{
            backgroundColor: "white",
            border: "2px solid #f0f2f5",
          }}
        >
          <div style={{ padding: "1rem 1rem" }}>
            <h3 style={{ fontWeight: "500" }}>Organization Settings</h3>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

          <div
            className="orgSettingMenu flexColumnWithoutStyle"
            style={{
              // overflow: "scroll",
              overflowX: "hidden",
              overflowY: "hidden",
            }}
          >
            {localStorage.getItem("menuList")?.includes("GENERAL_SETTINGS") && (
              <NavLink
                className={({ isActive }) => {
                  return isActive ? "active-newnav" : "inactive-newnav";
                }}
                to={"/organizationalSettings/general-settings"}
              >
                <div style={{ padding: "1.2rem" }}>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    General
                  </div>
                  <div style={{ color: "#5B5B5B" }}>
                    Manage your general settings.
                  </div>
                </div>
              </NavLink>
            )}
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

            {localStorage
              .getItem("menuList")
              ?.includes("EMPLOYEE_SETTINGS") && (
              <NavLink
                className={({ isActive }) => {
                  return isActive ? "active-newnav" : "inactive-newnav";
                }}
                to={"/organizationalSettings/employee-settings"}
              >
                <div style={{ padding: "1.2rem" }}>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Opening Hours
                  </div>
                  <div style={{ color: "#5B5B5B" }}>
                    Manage your opening office hour.
                  </div>
                </div>
              </NavLink>
            )}
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

            {localStorage.getItem("menuList")?.includes("IMPORT_SETTINGS") && (
              <NavLink
                className={({ isActive }) => {
                  return isActive ? "active-newnav" : "inactive-newnav";
                }}
                to={"/organizationalSettings/import"}
              >
                <div style={{ padding: "1.2rem" }}>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Import Settings
                  </div>
                  <div style={{ color: "#5B5B5B" }}>
                    Manage your imports from differnt sources.
                  </div>
                </div>
              </NavLink>
            )}
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            {localStorage
              .getItem("menuList")
              ?.includes("TEMPLATE_SETTINGS") && (
              <NavLink
                className={({ isActive }) => {
                  return isActive ? "active-newnav" : "inactive-newnav";
                }}
                to={"/organizationalSettings/templates"}
              >
                <div style={{ padding: "1.2rem" }}>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Templates
                  </div>
                  <div style={{ color: "#5B5B5B" }}>
                    Manage your email templates.
                  </div>
                </div>
              </NavLink>
            )}
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            {localStorage
              .getItem("menuList")
              ?.includes("DASHBOARD_SETTINGS") && (
              <NavLink
                className={({ isActive }) => {
                  return isActive ? "active-newnav" : "inactive-newnav";
                }}
                to={"/organizationalSettings/dashboard"}
              >
                <div style={{ padding: "1.2rem" }}>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Dashboard Settings
                  </div>
                  <div style={{ color: "#5B5B5B" }}>Manage your dashboard.</div>
                </div>
              </NavLink>
            )}
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            {localStorage
              .getItem("menuList")
              ?.includes("AUTOREMINDER_SETTINGS") && (
              <NavLink
                className={({ isActive }) => {
                  return isActive ? "active-newnav" : "inactive-newnav";
                }}
                to={"/organizationalSettings/autoreminder"}
              >
                <div style={{ padding: "1.2rem" }}>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Autoreminder Settings
                  </div>
                  <div style={{ color: "#5B5B5B" }}>Manage your reminders.</div>
                </div>
              </NavLink>
            )}
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            {localStorage.getItem("menuList")?.includes("LEAD_SETTINGS") && (
              <NavLink
                className={({ isActive }) => {
                  return isActive ? "active-newnav" : "inactive-newnav";
                }}
                to={"/organizationalSettings/lead-settings"}
              >
                <div style={{ padding: "1.2rem" }}>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Leads Settings
                  </div>
                  <div style={{ color: "#5B5B5B" }}>Manage your leads.</div>
                </div>
              </NavLink>
            )}
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            {localStorage
              .getItem("menuList")
              ?.includes("APPOINTMENT_SETTINGS") && (
              <NavLink
                className={({ isActive }) => {
                  return isActive ? "active-newnav" : "inactive-newnav";
                }}
                to={"/organizationalSettings/appointment-settings"}
              >
                <div style={{ padding: "1.2rem" }}>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Appointments Settings
                  </div>
                  <div style={{ color: "#5B5B5B" }}>
                    Manage your appointments.
                  </div>
                </div>
              </NavLink>
            )}
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            {localStorage.getItem("menuList")?.includes("CASE_SETTINGS") && (
              <NavLink
                className={({ isActive }) => {
                  return isActive ? "active-newnav" : "inactive-newnav";
                }}
                to={"/organizationalSettings/case-settings"}
              >
                <div style={{ padding: "1.2rem" }}>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Cases Settings
                  </div>
                  <div style={{ color: "#5B5B5B" }}>Manage your Cases.</div>
                </div>
              </NavLink>
            )}
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            {localStorage
              .getItem("menuList")
              ?.includes("QUOTATION_SETTINGS") && (
              <NavLink
                className={({ isActive }) => {
                  return isActive ? "active-newnav" : "inactive-newnav";
                }}
                to={"/organizationalSettings/quotation-settings"}
              >
                <div style={{ padding: "1.2rem" }}>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Quotation Settings
                  </div>
                  <div style={{ color: "#5B5B5B" }}>Manage your quotation.</div>
                </div>
              </NavLink>
            )}
            {/* <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            {localStorage.getItem("menuList")?.includes("CHECKIN_SETTINGS") && (
              <NavLink to={"/organizationalSettings/check-in"}>
                <div style={{ padding: "1.2rem" }}>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    
                    }}
                  >
                    Check-In Settings
                  </div>
                  <div style={{ color: "#5B5B5B" }}>Manage your check in.</div>
                </div>
              </NavLink>
            )} */}

            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            {localStorage
              .getItem("menuList")
              ?.includes("WORKFLOW_SETTINGS") && (
              <NavLink
                className={({ isActive }) => {
                  return isActive ? "active-newnav" : "inactive-newnav";
                }}
                to={"/organizationalSettings/country"}
              >
                <div style={{ padding: "1.2rem" }}>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Workflow Settings
                  </div>
                  <div style={{ color: "#5B5B5B" }}>
                    Manage country workflows.
                  </div>
                </div>
              </NavLink>
            )}
          </div>
        </div>
        <div style={{ margin: "1rem" }}>{children}</div>
      </div>
    </SiderDemo>
  );
};

export default OrganizationalSettings;
