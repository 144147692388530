import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

const AddLeadSource = ({
  isAddLeadSourceModalVisible,
  setIsAddLeadSourceModalVisible,
  isHeadBranch,
  branchRecord,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addLeadSourceSubmitHandler = (value) => {
    dispatch({
      type: "ADD_LEAD_SOURCE_REQUEST",
      payload: {
        branchId: branchRecord?.id,
        formData: {
          ...value,
          branchId: branchRecord?.id ?? localStorage.getItem("branchId"),
        },
      },
    });
    form.resetFields();
    setIsAddLeadSourceModalVisible(false);
  };
  return (
    <Modal
      title="Add Lead Source"
      open={isAddLeadSourceModalVisible}
      onCancel={() => {
        setIsAddLeadSourceModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addLeadSourceSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Source name"
          name={"name"}
          rules={[
            {
              required: true,
              message: "Please enter your Source!",
            },
          ]}
        >
          <Input placeholder="Lead Source" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Lead Source
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddLeadSource;
