import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  publicDrive: { data: [], pageNumber: 0, totalData: 0, totalPage: 0 },
  privateDrive: { data: [], pageNumber: 0, totalData: 0, totalPage: 0 },
};

const ResourcesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_PUBLIC_DRIVE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_PUBLIC_DRIVE_REQUEST_SUCCESS": {
      return {
        ...state,
        publicDrive: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_PUBLIC_DRIVE_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_PUBLICBY_ID_DRIVE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_PUBLICBY_ID_DRIVE_REQUEST_SUCCESS": {
      return {
        ...state,
        publicDrive: {
          ...action.payload,
          data: [...action.payload.driveManagerRecordList],
          totalData: null,
        },
        isLoading: false,
      };
    }
    case "FETCH_PUBLICBY_ID_DRIVE_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_PRIVATE_DRIVE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_PRIVATE_DRIVE_REQUEST_SUCCESS": {
      return {
        ...state,
        privateDrive: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_PRIVATE_DRIVE_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_PRIVATEBY_ID_DRIVE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_PRIVATEBY_ID_DRIVE_REQUEST_SUCCESS": {
      return {
        ...state,
        privateDrive: {
          ...action.payload,
          data: [...action.payload.driveManagerRecordList],
          totalData: null,
        },
        isLoading: false,
      };
    }
    case "FETCH_PRIVATEBY_ID_DRIVE_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_PUBLIC_DRIVE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_PUBLIC_DRIVE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Resources added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_PUBLIC_DRIVE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add Resources.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "RENAME_FOLDER_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "RENAME_FOLDER_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success!  Folder renamed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "RENAME_FOLDER_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to rename folder.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_FOLDER_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_FOLDER_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Folder deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_FOLDER_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete folder.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_PUBLIC_DRIVE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_PUBLIC_DRIVE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Resources updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_PUBLIC_DRIVE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update Resources.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_PUBLIC_DRIVE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_PUBLIC_DRIVE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Resources deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_PUBLIC_DRIVE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete Resources.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_PUBLIC_DRIVE_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_PUBLIC_DRIVE_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Resources status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_PUBLIC_DRIVE_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change Resources status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FILE_UPLOAD_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FILE_UPLOAD_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! File uploaded.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FILE_UPLOAD_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to upload file.");

      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default ResourcesReducer;
