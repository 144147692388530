import { Divider, Tag } from "antd";
import moment from "moment";
import React from "react";
import { twoTagResponses } from "../../HelperFunction";

const QuoteSummary = ({ quotationData, current }) => {
  const caseDetails = (title, value) => {
    return (
      <div>
        <div className="profileTitle">{title}:</div>
        <div>{value}</div>
      </div>
    );
  };
  return (
    <div
      style={{
        borderLeft: "1px solid #f0f2f5",
        display: "flex",
        flexDirection: "column",
        padding: "0.5rem",
      }}
    >
      {current === 0 && (
        <>
          <div>
            <h4>CLIENT INFORMATION:</h4>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0" }} />

          <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
            <div style={{ width: "100%" }}>
              {quotationData.firstName !== "" &&
                quotationData.firstName !== null &&
                quotationData.firstName !== undefined &&
                quotationData.lastName !== "" &&
                quotationData.lastName !== null &&
                quotationData.lastName !== undefined &&
                caseDetails(
                  "Name",
                  `${quotationData.firstName} ${quotationData.lastName} ${
                    quotationData.clientType === "EXISTING"
                      ? "(Existing)"
                      : quotationData.clientType === "NEW"
                      ? "(New)"
                      : null
                  } `
                )}
            </div>
            <div style={{ width: "100%" }}>
              {quotationData.mobile !== "" &&
                quotationData.mobile !== null &&
                quotationData.mobile !== undefined &&
                caseDetails("Mobile Number", quotationData.mobile)}
            </div>
          </div>
          {quotationData.email !== "" &&
            quotationData.email !== null &&
            quotationData.email !== undefined &&
            caseDetails("Email", quotationData.email)}
          {quotationData.dob !== "" &&
            quotationData.dob !== null &&
            quotationData.dob !== undefined &&
            caseDetails(
              "Date of Birth",
              moment
                .utc(moment.utc(quotationData.dob).local())
                .utc()
                .local()
                .format("DD-MM-YYYY")
            )}
          {quotationData.phone !== "" &&
            quotationData.phone !== null &&
            quotationData.phone !== undefined &&
            caseDetails("Phone Number", quotationData.phone)}
          {quotationData.address !== "" &&
            quotationData.address !== null &&
            quotationData.address !== undefined &&
            caseDetails("Address", quotationData.address)}
          {quotationData.passportNumber !== "" &&
            quotationData.passportNumber !== null &&
            quotationData.passportNumber !== undefined &&
            caseDetails("Passport Number", quotationData.passportNumber)}
          {quotationData.maritalStatus !== "" &&
            quotationData.maritalStatus !== null &&
            quotationData.maritalStatus !== undefined &&
            caseDetails("Marital Status", quotationData.maritalStatus)}
          {quotationData.sexStatus !== "" &&
            quotationData.sexStatus !== null &&
            quotationData.sexStatus !== undefined &&
            caseDetails("Gender", quotationData.sexStatus)}
          {quotationData.countryOfResidence !== "" &&
            quotationData.countryOfResidence !== null &&
            quotationData.countryOfResidence !== undefined &&
            caseDetails(
              "Country of Residence",
              quotationData.countryOfResidence
            )}
          {quotationData.referralName !== "" &&
            quotationData.referralName !== null &&
            quotationData.referralName !== undefined &&
            caseDetails("Referral", quotationData.referralName)}
        </>
      )}

      {current === 1 && (
        <>
          <div>
            <h4>PACKAGE INFORMATION:</h4>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0" }} />
          <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
            <div style={{ width: "100%" }}>
              {quotationData.natureOfProject !== "" &&
                quotationData.natureOfProject !== null &&
                quotationData.natureOfProject !== undefined &&
                caseDetails("Nature of Quote", quotationData.natureOfProject)}
            </div>
            {quotationData.isDiscountAllowed && (
              <div style={{ width: "100%" }}>
                {quotationData.discount !== "" &&
                  quotationData.discount !== null &&
                  quotationData.discount !== undefined &&
                  quotationData.isPercentage !== "" &&
                  quotationData.isPercentage !== null &&
                  quotationData.isPercentage !== undefined && (
                    <>
                      {caseDetails(
                        "Discount",
                        `${quotationData.discount} ${
                          quotationData.isPercentage === "FLAT"
                            ? "FLAT"
                            : quotationData.isPercentage === "PERCENTAGE"
                            ? "%"
                            : null
                        }
                      `
                      )}
                    </>
                  )}
              </div>
            )}
          </div>
          <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
            {quotationData.gstApplicable && (
              <div style={{ width: "100%" }}>
                {quotationData.gstAmount !== "" &&
                  quotationData.gstAmount !== null &&
                  quotationData.gstAmount !== undefined &&
                  caseDetails("GST amount", quotationData.gstAmount)}
              </div>
            )}
            <div style={{ width: "100%" }}>
              {quotationData.associatedTimelineName !== "" &&
                quotationData.associatedTimelineName !== null &&
                quotationData.associatedTimelineName !== undefined &&
                caseDetails(
                  "Associated timeline",
                  quotationData.associatedTimelineName
                )}
            </div>
          </div>
          <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
            <div style={{ width: "100%" }}>
              {quotationData.paymentTermsInMonths !== "" &&
                quotationData.paymentTermsInMonths !== null &&
                quotationData.paymentTermsInMonths !== undefined &&
                caseDetails(
                  "Payment terms (Months)",
                  quotationData.paymentTermsInMonths
                )}
            </div>
          </div>
          {quotationData.paymentTermsInfo !== "" &&
            quotationData.paymentTermsInfo !== null &&
            quotationData.paymentTermsInfo !== undefined &&
            caseDetails("Payment terms info", quotationData.paymentTermsInfo)}
          {quotationData.displayPaymentTermsInfoInQuoteConfirmation !== "" &&
            quotationData.displayPaymentTermsInfoInQuoteConfirmation !== null &&
            quotationData.displayPaymentTermsInfoInQuoteConfirmation !==
              undefined &&
            caseDetails(
              "Display payment terms info in quote confirmation",
              twoTagResponses(
                quotationData.displayPaymentTermsInfoInQuoteConfirmation,
                true,
                false
              )
            )}

          {quotationData.specialNote !== "" &&
            quotationData.specialNote !== null &&
            quotationData.specialNote !== undefined &&
            caseDetails("Special note", quotationData.specialNote)}

          {quotationData.description !== "" &&
            quotationData.description !== null &&
            quotationData.description !== undefined &&
            caseDetails("Description", quotationData.description)}
          {quotationData.quotesUpdate !== "" &&
            quotationData.quotesUpdate !== null &&
            quotationData.quotesUpdate !== undefined &&
            caseDetails("Quote update", quotationData.quotesUpdate)}

          {quotationData.displayQuotesUpdateInQuoteConfirmation !== "" &&
            quotationData.displayQuotesUpdateInQuoteConfirmation !== null &&
            quotationData.displayQuotesUpdateInQuoteConfirmation !==
              undefined &&
            caseDetails(
              "Display quotes update in quote confirmation",
              twoTagResponses(
                quotationData.displayQuotesUpdateInQuoteConfirmation,
                true,
                false
              )
            )}
        </>
      )}
      {current === 2 && (
        <>
          <div>
            <h4>QUOTATION INFORMATION:</h4>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0" }} />

          {quotationData.quoteStatus !== "" &&
            quotationData.quoteStatus !== null &&
            quotationData.quoteStatus !== undefined &&
            caseDetails(
              "Quote status",
              quotationData?.quoteStatus === "QUOTE_DRAFT" ? (
                <p className="orangeTag">QUOTE_DRAFT</p>
              ) : quotationData?.quoteStatus === "QUOTE_SENT" ? (
                <p className="blueTag">QUOTE_SENT</p>
              ) : quotationData?.quoteStatus === "WIN" ? (
                <p className="greenTag">WIN</p>
              ) : quotationData?.quoteStatus === "LOSE" ? (
                <p className="redTag">LOSE</p>
              ) : (
                "N/A"
              )
            )}
          {quotationData.quoteSource !== "" &&
            quotationData.quoteSource !== null &&
            quotationData.quoteSource !== undefined &&
            caseDetails("Quote Source", quotationData.quoteSourceName)}
          {quotationData.aboutUsName !== "" &&
            quotationData.aboutUsName !== null &&
            quotationData.aboutUsName !== undefined &&
            caseDetails("About us", quotationData.aboutUsName)}
          {quotationData.quoteExpiryDate !== "" &&
            quotationData.quoteExpiryDate !== null &&
            quotationData.quoteExpiryDate !== undefined &&
            caseDetails(
              "Quotation expiry date",
              moment(quotationData.quoteExpiryDate)
                .utc()
                .local()
                .format("DD-MM-YYYY")
            )}
          {quotationData.modeOfContact !== "" &&
            quotationData.modeOfContact !== null &&
            quotationData.modeOfContact !== undefined &&
            caseDetails("Mode of contact", quotationData.modeOfContact)}
        </>
      )}
    </div>
  );
};

export default QuoteSummary;
