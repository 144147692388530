import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../../Components/Layout/SiderDemo";
import AddEducationVisa from "./AddEducationVisa";
import UpdateEducationVisa from "./UpdateEducationVisa";
import Cookies from "js-cookie";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import EducationVisaDetails from "./EducationVisaDetails";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Country",
    dataIndex: "country",
    align: "center",
  },
  {
    title: "Sub Class",
    dataIndex: "subClass",
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
    fixed: "right",
  },
];

const EducationVisa = () => {
  const store = useSelector((state) => {
    return {
      educationVisasState: state.VisaClassReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const [isAddEducationVisaModalVisible, setIsAddEducationVisaModalVisible] =
    useState(false);
  const [
    isUpdateEducationVisaModalVisible,
    setIsUpdateEducationVisaModalVisible,
  ] = useState(false);
  const [isVisaDetailModelVisible, setIsVisaDetailModelVisible] =
    useState(false);
  const [record, setRecord] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState("");

  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_EDUCATION_VISAS_REQUEST",
      payload: {
        page,
        pageSize,
        branchId:
          store.branchState?.branchRecord.id ??
          localStorage.getItem("branchId"),
      },
    });
  }, [page, pageSize, store.branchState?.branchRecord]);

  useEffect(() => {
    fetchCountry();
    setCountry(store.profileState.profile?.country);
  }, []);

  const fetchCountry = () => {
    const tempToken = Cookies.get("accessToken");
    axios
      .get(`v1/api/country/getAllCountry`, {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      })
      .then((res) => {
        setCountryList(res.data.data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  //if (educationVisasType === "educationVisas") {
  const data = store?.educationVisasState?.educationVisas?.data?.map(
    (dataObj) => {
      return {
        key: dataObj.id,
        id: dataObj.id,
        subClass: dataObj.subClass,
        country: dataObj.country,

        actions: (
          <Space size="middle">
            <Tooltip title="Subclass Details">
              <a>
                <div
                  className="bordered"
                  // onClick={() => {
                  //   setRecord(dataObj);
                  //   setIsEditServicesVisible(true);
                  //   // setIsQuotationDetailsModelVisible(true);
                  // }}
                  onClick={() => {
                    setIsVisaDetailModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {store.profileState?.profile?.department?.rolesAndPermissionList?.EDUCATION_VISAS?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update educationVisas">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateEducationVisaModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.EDUCATION_VISAS?.includes(
              "DELETE"
            ) && (
              <Popconfirm
                placement="topRight"
                title="Are you sure to delete this educationVisas?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_EDUCATION_VISAS_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined style={{ color: "red" }} />
                  </div>
                </a>{" "}
              </Popconfirm>
            )}
            {/* <Tooltip title="Toggle educationVisas status">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus =
                        dataObj.status=== "INACTIVE" ? "active" : "inactive";
                      dispatch({
                        type: "TOGGLE_EDUCATION_VISAS_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <MdOutlineDisabledVisible />
                  </div>
                </a>
              </Tooltip> */}
          </Space>
        ),
      };
    }
  );
  //   } else {
  //     data = store?.educationVisasState.activeEducationVisas?.data?.map((dataObj) => {
  //       return {
  //     key: dataObj.id,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //         : dataObj.? (
  //           <Tag color="#00ff00aa">YES</Tag>
  //         ) : (
  //           <Tag color="#ff0000aa">NO</Tag>
  //         ),
  //         :
  //           dataObj.=== "ACTIVE" ? (
  //             <p className="greenTag">{dataObj.}</p>
  //           ) : (
  //             <p className="redTag">{dataObj.}</p>
  //           ),
  //         actions: (
  //           <Space size="middle">
  //             <Tooltip title="Update educationVisas">
  //               <a>
  //                 <div
  //                   className="bordered"
  //                   onClick={() => {
  //                     setIsUpdateEducationVisaModalVisible(true);
  //                     setRecord(dataObj);
  //                   }}
  //                 >
  //                   <EditOutlined />
  //                 </div>
  //               </a>
  //             </Tooltip>
  //             <Tooltip title="Delete educationVisas">
  //               <Popconfirm
  //                 title="Are you sure to delete this educationVisas?"
  //                 onConfirm={() => {
  //                   dispatch({
  //                     type: "DELETE_EDUCATION_VISAS_REQUEST",
  //                     payload: { id: dataObj.id },
  //                   });
  //                 }}
  //                 onCancel={() => {}}
  //                 okText="Yes"
  //                 cancelText="No"
  //               >
  //                 <a>
  //                   <div className="bordered">
  //                     <DeleteOutlined />
  //                   </div>
  //                 </a>{" "}
  //               </Popconfirm>
  //             </Tooltip>
  //             <Tooltip title="Toggle educationVisas status">
  //               <a>
  //                 <div
  //                   className="bordered"
  //                   onClick={() => {
  //                     const actionStatus =
  //                       dataObj.=== "INACTIVE" ? "active" : "inactive";
  //                     dispatch({
  //                       type: "TOGGLE_EDUCATION_VISAS_STATUS_REQUEST",
  //                       payload: { id: dataObj.id, actionStatus },
  //                     });
  //                   }}
  //                 >
  //                   <MdOutlineDisabledVisible />
  //                 </div>
  //               </a>
  //             </Tooltip>
  //           </Space>
  //         ),
  //       };
  //     });
  //   }
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          {store.profileState?.profile?.department?.rolesAndPermissionList?.EDUCATION_VISAS?.includes(
            "ADD"
          ) && (
            <button
              className="button"
              onClick={() => {
                setIsAddEducationVisaModalVisible(true);
              }}
            >
              <span>Add Education Visa</span>
            </button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          <Table
            bordered
            columns={columns}
            dataSource={data}
            pagination={false}
          />
          {store?.educationVisasState?.educationVisas?.totalData ? (
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              defaultPageSize={pageSize}
              onChange={onShowSizeChange}
              total={store?.educationVisasState?.educationVisas?.totalPage * 10}
            />
          ) : null}
        </div>
      </div>
      {isAddEducationVisaModalVisible && (
        <AddEducationVisa
          country={country}
          countryList={countryList}
          isAddEducationVisaModalVisible={isAddEducationVisaModalVisible}
          setIsAddEducationVisaModalVisible={setIsAddEducationVisaModalVisible}
        />
      )}
      {isUpdateEducationVisaModalVisible && (
        <UpdateEducationVisa
          countryList={countryList}
          record={record}
          isUpdateEducationVisaModalVisible={isUpdateEducationVisaModalVisible}
          setIsUpdateEducationVisaModalVisible={
            setIsUpdateEducationVisaModalVisible
          }
        />
      )}

      {isVisaDetailModelVisible && (
        <EducationVisaDetails
          record={record}
          isVisaDetailModelVisible={isVisaDetailModelVisible}
          setIsVisaDetailModelVisible={setIsVisaDetailModelVisible}
        />
      )}
    </SiderDemo>
  );
};

export default EducationVisa;
