import React, { useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Tooltip,
} from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { FiSettings } from "react-icons/fi";
import { IoMdCheckboxOutline } from "react-icons/io";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import AddMigrationWorkflow from "./AddMigrattionWorkFlow";
import { useEffect } from "react";
import UpdateMigrationWorkflow from "./UpdateMigrationWorkflow";
import AddMigrationDocumentList from "./AddMigrationDocumentList";
import ManageEducationWorkflowStaus from "./ManageEducationWorkflowStatus";
import { AiOutlinePlus } from "react-icons/ai";
import AddDocuments from "./AddDocuments";
import { ConnectableObservable } from "rxjs";
const { Option } = Select;

const ManageMigrationWorkflow = ({
  countryRecord,
  isManageMigrationWorkflowModalVisible,
  setIsManageMigrationWorkflowModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      manageMigrationWorkflowState: state.SettingsReducer,
      profileState: state.ProfileReducer,
      addDocumentChecklistState: state.SettingsReducer,
    };
  });

  const [isAddDocumentVisible, setIsAddDocumentVisible] = useState(false);

  console.log("countryRecord", countryRecord);

  const [
    isAddMigrationWorkflModalVisible,
    setIsAddMigrationWorkflModalVisible,
  ] = useState(false);
  const [
    isAddMigrationDocumentListModalVisible,
    setIsAddMigrationDocumentListModalVisible,
  ] = useState(false);
  const [
    isUpdateMigrationWorflowModalVisible,
    setIsUpdateMigrationWorflowModalVisible,
  ] = useState(false);
  const [
    isManageMigrationWorkflowStatusModalVisible,
    setIsManageMigrationWorkflowStatusModalVisible,
  ] = useState(false);
  const [workflowRecord, setWorkflowRecord] = useState({});
  const dispatch = useDispatch();
  useEffect(
    () => {
      // if (
      //   isManageMigrationWorkflowModalVisible === true ||
      //   isUpdateMigrationWorflowModalVisible === false
      // ) {
      fetchMigrationWorkFlow();
      // }
    },
    [
      // isManageMigrationWorkflowModalVisible,
      // isUpdateMigrationWorflowModalVisible,
    ]
  );

  const fetchMigrationWorkFlow = () => {
    dispatch({
      type: "FETCH_MIGRATION_WORKFLOW_REQUEST",
      payload: { id: countryRecord.id },
      isSuccessFn: true,
      successFunction: successFunction,
    });
  };

  const successFunction = (res) => {
    setWorkflowRecord(res?.data?.data[0]);
  };

  const [form] = Form.useForm();

  return (
    <Drawer
      title="Manage Migration Workflow"
      open={isManageMigrationWorkflowModalVisible}
      onClose={(e) => {
        setIsManageMigrationWorkflowModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          {store.profileState?.profile?.department?.rolesAndPermissionList?.WORKFLOW_SETTINGS?.includes(
            "ADD"
          ) && (
            <button
              className="button"
              onClick={() => {
                setIsAddMigrationWorkflModalVisible(true);
              }}
            >
              <span>Add Workflow</span>
            </button>
          )}
        </div>
        <div style={{ marginTop: "2rem" }}>
          {store?.manageMigrationWorkflowState.migrationWorkflow?.data?.map(
            (dataObj, index) => {
              return (
                <div
                  key={dataObj.id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: "20rem",
                    padding: "2px 4px",
                    margin: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "1rem",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setWorkflowRecord(dataObj);

                      setIsManageMigrationWorkflowStatusModalVisible(true);
                    }}
                  >
                    <Avatar
                      style={{
                        backgroundColor: "blue",
                        verticalAlign: "middle",
                      }}
                      size="large"
                    >
                      {index + 1}
                    </Avatar>
                    <h3>
                      <Tooltip title="Click to add workflow status">
                        {dataObj.statusName}{" "}
                        {dataObj.isDefault ? (
                          <p
                            className="greenTag"
                            style={{
                              borderRadius: "20px",
                              fontSize: "12px",
                              paddingTop: "1px",
                              marginLeft: "1rem",
                            }}
                          >
                            Default
                          </p>
                        ) : (
                          ""
                        )}
                      </Tooltip>
                    </h3>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      marginRight: "1.5rem",
                    }}
                  >
                    {store.profileState?.profile?.department?.rolesAndPermissionList?.WORKFLOW_SETTINGS?.includes(
                      "ADD_UPDATE_WORKFLOW_STATUS"
                    ) && (
                      <Tooltip title="Add workflow status">
                        <a>
                          <div
                            className="bordered"
                            onClick={() => {
                              setWorkflowRecord(dataObj);

                              setIsManageMigrationWorkflowStatusModalVisible(
                                true
                              );
                            }}
                          >
                            <AiOutlinePlus />
                          </div>
                        </a>
                      </Tooltip>
                    )}
                    {store.profileState?.profile?.department?.rolesAndPermissionList?.WORKFLOW_SETTINGS?.includes(
                      "UPDATE"
                    ) && (
                      <Tooltip title="Update">
                        <a>
                          <div
                            className="bordered"
                            onClick={() => {
                              setWorkflowRecord(dataObj);
                              setIsUpdateMigrationWorflowModalVisible(true);
                            }}
                          >
                            <EditOutlined style={{ fontSize: "18px" }} />
                          </div>
                        </a>
                      </Tooltip>
                    )}

                    {store.profileState?.profile?.department?.rolesAndPermissionList?.WORKFLOW_SETTINGS?.includes(
                      "DELETE"
                    ) && (
                      <Tooltip title="Delete">
                        <Popconfirm
                          title="Are you sure to delete this workflow?"
                          onConfirm={() => {
                            dispatch({
                              type: "DELETE_MIGRATION_WORKFLOW_REQUEST",
                              payload: {
                                id: dataObj.id,
                                countryId: countryRecord.id,
                              },
                            });
                          }}
                          onCancel={() => {}}
                          okText="Yes"
                          cancelText="No"
                        >
                          <a>
                            <div className="bordered">
                              <DeleteOutlined style={{ fontSize: "18px" }} />
                            </div>
                          </a>{" "}
                        </Popconfirm>
                      </Tooltip>
                    )}
                    {store.profileState?.profile?.department?.rolesAndPermissionList?.WORKFLOW_SETTINGS?.includes(
                      "CHANGE_STATUS"
                    ) && (
                      <Tooltip title="Set as Default">
                        <a>
                          <div
                            className="bordered"
                            onClick={() => {
                              dispatch({
                                type: "SET_WORKFLOW_AS_DEFAULT_REQUEST",
                                payload: {
                                  countryId: countryRecord.id,
                                  workflowId: dataObj.id,
                                },
                              });
                            }}
                          >
                            <FiSettings style={{ fontSize: "18px" }} />
                          </div>
                        </a>
                      </Tooltip>
                    )}
                    <Tooltip title="Manage Document Checklist">
                      <a>
                        <div
                          className="bordered"
                          onClick={() => {
                            setWorkflowRecord(dataObj);
                            dispatch({
                              type: "FETCH_DOCUMENTS_REQUEST",
                              payload: { id: dataObj.id },
                              isSuccessFn: true,
                              successFunction: () => {},
                            });
                            setIsAddDocumentVisible(true);

                            // setIsAddMigrationDocumentListModalVisible(true);
                          }}
                        >
                          <IoMdCheckboxOutline style={{ fontSize: "18px" }} />
                        </div>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
      {isAddMigrationWorkflModalVisible && (
        <AddMigrationWorkflow
          countryRecord={countryRecord}
          isAddMigrationWorkFlowModalVisible={isAddMigrationWorkflModalVisible}
          setIsAddMigrationWorkFlowModalVisible={
            setIsAddMigrationWorkflModalVisible
          }
        />
      )}
      {isUpdateMigrationWorflowModalVisible && (
        <UpdateMigrationWorkflow
          workflowRecord={workflowRecord}
          countryRecord={countryRecord}
          isUpdateMigrationWorkflowModalVisible={
            isUpdateMigrationWorflowModalVisible
          }
          setIsUpdateMigrationWorkflowModalVisible={
            setIsUpdateMigrationWorflowModalVisible
          }
        />
      )}
      {isAddMigrationDocumentListModalVisible && (
        <AddMigrationDocumentList
          workflowRecord={workflowRecord}
          isAddMigrationDocumentListModalVisible={
            isAddMigrationDocumentListModalVisible
          }
          setIsAddMigrationDocumentListModalVisible={
            setIsAddMigrationDocumentListModalVisible
          }
        />
      )}
      {isManageMigrationWorkflowStatusModalVisible && (
        <ManageEducationWorkflowStaus
          workflowRecord={workflowRecord}
          isManageEducationWorkflowStatusModalVisible={
            isManageMigrationWorkflowStatusModalVisible
          }
          setIsManageEducationWorkflowStatusModalVisible={
            setIsManageMigrationWorkflowStatusModalVisible
          }
        />
      )}

      {isAddDocumentVisible && (
        <AddDocuments
          document={workflowRecord?.documentCheckList?.map(
            (data) => data?.item
          )}
          workflowRecord={workflowRecord}
          isAddDocumentsModalVisible={isAddDocumentVisible}
          setIsAddDocumentsModalVisible={setIsAddDocumentVisible}
        />
      )}
    </Drawer>
  );
};

export default ManageMigrationWorkflow;
