import React, { useEffect, useState } from "react";
import SiderDemo from "../../Components/Layout/SiderDemo";
import { Table, Tooltip } from "antd";
import AddServices from "./component/AddServices";
import { AiOutlineEye } from "react-icons/ai";
import EditServices from "./component/EditServices";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";
import ServicePackageDetail from "./ServicePackageDetail";

export default function ProductService() {
  const dispatch = useDispatch();
  const quoteState = useSelector((store) => store?.QuotationReducer);

  console.log("testing state", quoteState?.productService?.data);

  const [isAddServicesVisible, setIsAddServicesVisible] = useState(false);
  const [isEditServicesVisible, setIsEditServicesVisible] = useState(false);
  const [record, setRecord] = useState("");
  const [isServiceDetailModel, setIsServiceDetailModel] = useState(false);
  const [serviceId, setServiceId] = useState(null);

  useEffect(() => {
    fetchProductService();
  }, []);

  const fetchProductService = () => {
    dispatch({
      type: "FETCH_PRODUCT_SERVICE_REQUEST",
      payload: {},
    });
  };

  const data = [
    {
      id: 1,
      customer: "test",
    },
  ];

  const columns = [
    {
      title: "Source Id",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "Customer",
      // dataIndex: "customer",
      align: "center",
      render: (dataObj) => {
        return (
          <span
            style={{ cursor: "pointer", color: "#1d63a9" }}
            onClick={() => {
              setIsServiceDetailModel(true);
              setServiceId(dataObj?.id);
            }}
          >
            {dataObj?.customerId?.fullName}
          </span>
        );
      },
    },
    {
      title: "Nature of Source",
      dataIndex: "natureOfProject",
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      align: "center",
      render: (data) => <span>{data ? <span> {data} AUD </span> : "N/A"}</span>,
    },
    {
      title: "Service Date",
      dataIndex: "addedDateTime",
      align: "center",
      render: (date) => (
        <span>{date ? moment(date).format("DD-MM-YYYY") : "N/A"}</span>
      ),
    },
    {
      title: "Service Expiry",
      dataIndex: "serviceExpiryDate",
      align: "center",
      render: (date) => (
        <span>{date ? moment(date).format("DD-MM-YYYY") : "N/A"}</span>
      ),
    },
    {
      title: "Service Status",
      dataIndex: "serviceStatus",
      align: "center",
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: (dataObj) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <Tooltip title="Update Service">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsEditServicesVisible(true);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Service Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsServiceDetailModel(true);
                    setServiceId(dataObj?.id);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <SiderDemo>
      <button
        className="button"
        onClick={() => {
          setIsAddServicesVisible(true);
        }}
      >
        <span>Add Services</span>
      </button>
      <div style={{ margin: "1rem 0" }}>
        <Table
          dataSource={quoteState?.productService?.data}
          pagination={false}
          columns={columns}
          bordered
        />
      </div>
      {isAddServicesVisible && (
        <AddServices
          fetchProductService={fetchProductService}
          isAddServicesVisible={isAddServicesVisible}
          setIsAddServicesVisible={setIsAddServicesVisible}
        />
      )}
      {isEditServicesVisible && (
        <EditServices
          record={record}
          isEditServicesVisible={isEditServicesVisible}
          setIsEditServicesVisible={setIsEditServicesVisible}
          fetchProductService={fetchProductService}
        />
      )}

      {isServiceDetailModel && (
        <ServicePackageDetail
          serviceId={serviceId}
          isServiceDetailModel={isServiceDetailModel}
          setIsServiceDetailModel={setIsServiceDetailModel}
        />
      )}
    </SiderDemo>
  );
}
