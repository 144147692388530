import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../../Components/Layout/SiderDemo";
import AddBranch from "./AddBranch";
import UpdateBranch from "./UpdateBranch";
import BranchLogin from "./BranchLogin";
import TableSkeleton from "../../../Helpers/TableSkeleton/tableSkelaton";
import { BiBlock } from "react-icons/bi";
import { AiOutlinePoweroff } from "react-icons/ai";
const columns = [
  {
    title: "Branch ID",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Branch Name",
    dataIndex: "branchName",
    align: "center",
  },
  {
    title: "Branch Location",
    dataIndex: "branchLocation",
    align: "center",
  },
  {
    title: "Contact Name",
    dataIndex: "contactFullName",
    align: "center",
  },
  {
    title: "Contact Number",
    dataIndex: "contactNumber",
    align: "center",
  },
  {
    title: "Login Email",
    dataIndex: "email",
    align: "center",
  },
  {
    title: "ABN/ACN",
    dataIndex: "companyRegistrationNumber",
    align: "center",
  },
  {
    title: "Country",
    dataIndex: "country",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "isActive",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
    fixed: "right",
  },
];

const Branch = () => {
  const store = useSelector((state) => {
    return {
      branchState: state.BranchReducer,
      profileState: state.ProfileReducer,
    };
  });
  const [isAddBranchModalVisible, setIsAddBranchModalVisible] = useState(false);
  const [isUpdateBranchModalVisible, setIsUpdateBranchModalVisible] =
    useState(false);
  const [loginRecord, setLoginRecord] = useState();
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [record, setRecord] = useState({});
  const [branchType, setBranchType] = useState("allBranches");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (branchType === "allBranches") {
      dispatch({ type: "FETCH_BRANCH_REQUEST", payload: { page, pageSize } });
    } else {
      dispatch({
        type: "FETCH_ACTIVE_BRANCH_REQUEST",
        payload: { page, pageSize },
      });
    }
  }, [branchType, page, pageSize]);
  useEffect(() => {
    if (
      isAddBranchModalVisible === false ||
      isUpdateBranchModalVisible === false
    ) {
      dispatch({ type: "FETCH_BRANCH_REQUEST" });
    }
  }, [isAddBranchModalVisible, isUpdateBranchModalVisible]);
  let data = [];
  if (branchType === "allBranches") {
    data = store?.branchState.branches?.data?.map((dataObj) => {
      return {
        key: dataObj?.id,
        id: dataObj?.id,
        branchName: dataObj?.branchName,
        branchLocation: dataObj?.branchLocation,
        contactFullName: dataObj?.branchContactPersonInfo?.contactFullName,
        contactNumber: dataObj?.branchContactPersonInfo?.contactNumber,
        companyRegistrationNumber: dataObj?.companyRegistrationNumber,
        country: dataObj?.country,
        email: dataObj.loginEmail,
        isActive:
          dataObj.isActive || dataObj.isBranch === false ? (
            <p className="greenTag">ACTIVE</p>
          ) : (
            <p className="redTag">INACTIVE</p>
          ),
        actions: (
          <Space size="middle">
            {/* <Tooltip title="Update branch">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsUpdateBranchModalVisible(true);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip> */}
            {dataObj.isBranch &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.BRANCH?.includes(
                "CHANGE_STATUS"
              ) && (
                <>
                  {dataObj.isActive ? (
                    <>
                      <Tooltip title="Deactivate Branch">
                        <a style={{ color: "red" }}>
                          <div
                            className="bordered"
                            onClick={() => {
                              dispatch({
                                type: "TOGGLE_BRANCH_STATUS_REQUEST",
                                payload: {
                                  status: "inactive",
                                  branchId: dataObj.id,
                                },
                              });
                            }}
                          >
                            <BiBlock />
                          </div>
                        </a>
                      </Tooltip>
                    </>
                  ) : store.profileState?.profile?.department?.rolesAndPermissionList?.BRANCH?.includes(
                      "CHANGE_STATUS"
                    ) ? (
                    <>
                      <Tooltip title="Activate Branch">
                        <a>
                          <div
                            className="bordered"
                            onClick={() => {
                              dispatch({
                                type: "TOGGLE_BRANCH_STATUS_REQUEST",
                                payload: {
                                  status: "active",
                                  branchId: dataObj.id,
                                },
                              });
                            }}
                          >
                            <AiOutlinePoweroff />
                          </div>
                        </a>
                      </Tooltip>
                    </>
                  ) : null}
                </>
              )}
            {/* <Tooltip title="Delete branch">
              <Popconfirm
                title="Are you sure to delete this branch?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_BRANCH_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Toggle branch status">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    const actionStatus =
                      dataObj.status === "INACTIVE" ? "active" : "inactive";
                    dispatch({
                      type: "TOGGLE_BRANCH_STATUS_REQUEST",
                      payload: { id: dataObj.id, actionStatus },
                    });
                  }}
                >
                  <MdOutlineDisabledVisible />
                </div>
              </a>
            </Tooltip> */}
          </Space>
        ),
      };
    });
  } else {
    data = store?.branchState.activeBranches?.data?.map((dataObj) => {
      return {
        key: dataObj?.id,
        id: dataObj?.id,
        branchName: dataObj?.branchName,
        branchLocation: dataObj?.branchLocation,
        contactFullName: dataObj?.branchContactPersonInfo?.contactFullName,
        contactNumber: dataObj?.branchContactPersonInfo?.contactNumber,
        companyRegistrationNumber: dataObj?.companyRegistrationNumber,
        country: dataObj?.country,
        email: dataObj.loginEmail,
        isActive: dataObj.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {/* <Tooltip title="Update branch">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsUpdateBranchModalVisible(true);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip> */}
            {dataObj.isBranch &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.BRANCH?.includes(
                "CHANGE_STATUS"
              ) && (
                <>
                  {dataObj.isActive ? (
                    <>
                      <Tooltip title="Deactivate Branch">
                        <a style={{ color: "red" }}>
                          <div
                            className="bordered"
                            onClick={() => {
                              dispatch({
                                type: "TOGGLE_BRANCH_STATUS_REQUEST",
                                payload: {
                                  status: "inactive",
                                  branchId: dataObj.id,
                                },
                              });
                            }}
                          >
                            <BiBlock />
                          </div>
                        </a>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Activate Branch">
                        <a>
                          <div
                            className="bordered"
                            onClick={() => {
                              dispatch({
                                type: "TOGGLE_BRANCH_STATUS_REQUEST",
                                payload: {
                                  status: "active",
                                  branchId: dataObj.id,
                                },
                              });
                            }}
                          >
                            <AiOutlinePoweroff />
                          </div>
                        </a>
                      </Tooltip>
                    </>
                  )}
                </>
              )}

            {/* <Tooltip title="Delete branch">
              <Popconfirm
                title="Are you sure to delete this branch?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_BRANCH_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Toggle branch status">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    const actionStatus =
                      dataObj.status === "INACTIVE" ? "active" : "inactive";
                    dispatch({
                      type: "TOGGLE_BRANCH_STATUS_REQUEST",
                      payload: { id: dataObj.id, actionStatus },
                    });
                  }}
                >
                  <MdOutlineDisabledVisible />
                </div>
              </a>
            </Tooltip> */}
          </Space>
        ),
      };
    });
  }

  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          <div style={{ width: "20%" }}>
            {store.profileState?.profile?.department?.rolesAndPermissionList?.BRANCH?.includes(
              "ADD"
            ) && (
              <button
                className="button"
                onClick={() => {
                  setIsAddBranchModalVisible(true);
                }}
              >
                <span>Add Branch</span>
              </button>
            )}
          </div>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>All Branch</div>
                  </div>
                ),
                value: "allBranches",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Active Branch</div>
                  </div>
                ),
                value: "activeBranches",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);

              setBranchType(value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {store?.branchState?.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              bordered
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          )}

          {branchType === "allBranches" &&
            store?.branchState?.branches?.totalData && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                current={page}
                defaultPageSize={pageSize}
                onChange={onShowSizeChange}
                total={store?.branchState?.branches?.totalPage * 10}
              />
            )}
          {branchType === "activeBranches" &&
            store?.branchState?.activeBranches?.totalData && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                current={page}
                defaultPageSize={pageSize}
                onChange={onShowSizeChange}
                total={store?.branchState?.activeBranches?.totalPage * 10}
              />
            )}
        </div>
      </div>
      {isAddBranchModalVisible && (
        <AddBranch
          isAddBranchModalVisible={isAddBranchModalVisible}
          setIsAddBranchModalVisible={setIsAddBranchModalVisible}
        />
      )}
      {isUpdateBranchModalVisible && (
        <UpdateBranch
          record={record}
          isUpdateBranchModalVisible={isUpdateBranchModalVisible}
          setIsUpdateBranchModalVisible={setIsUpdateBranchModalVisible}
        />
      )}
    </SiderDemo>
  );
};

export default Branch;
