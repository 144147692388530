import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
const { Option } = Select;

const AddAppointmentForm = ({
  record,
  isAddAppointmentFormModalVisible,
  setIsAddAppointmentFormModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const successfn = (response) => {
    setAppointmentData((previousdata) => {
      return {
        ...previousdata,
        firstName: response?.data?.firstName,
        lastName: response?.data?.lastName,
        email: response?.data?.email,
        mobile: response?.data?.mobile,
        countryOfResidence: response?.data?.countryOfResidence,
        appointmentDate: response?.data?.appointmentDate,
        reminderDateAndTime: response?.data?.reminderDateAndTime,
        country: response?.data?.country,
        consultant: response?.data?.consultant,
        sessionTime: response?.data?.sessionTime,
        note: response?.data?.note,
      };
    });
  };
  useEffect(() => {
    dispatch({
      type: "FETCH_APPOINTMENT_FORM_DETAILS_OF_CAMPAIGN_REQUEST",
      payload: {
        campaignId: record?.id,
        isSuccessFn: true,
        successfn: successfn,
      },
    });
  }, []);
  const [appointmentData, setAppointmentData] = useState({
    firstName: null,
    lastName: null,
    email: null,
    mobile: null,
    countryOfResidence: null,
    appointmentDate: null,
    reminderDateAndTime: null,
    country: null,
    consultant: null,
    sessionTime: null,
    note: null,
  });

  const addAppointmentFormSubmitHandler = (value) => {
    const appointmentFormData = {
      formData: {
        firstName:
          value.firstName === undefined || value.firstName === null
            ? null
            : value.firstName,
        lastName:
          value.lastName === undefined || value.lastName === null
            ? null
            : value.lastName,
        email:
          value.email === undefined || value.email === null
            ? null
            : value.email,
        countryOfResidence:
          value.countryOfResidence === undefined ||
          value.countryOfResidence === null
            ? null
            : value.countryOfResidence,
        mobile:
          value.mobile === undefined || value.mobile === null
            ? null
            : value.mobile,
        appointmentDate:
          value.appointmentDate === undefined || value.appointmentDate === null
            ? null
            : value.appointmentDate,
        reminderDateAndTime:
          value.reminderDateAndTime === undefined ||
          value.reminderDateAndTime === null
            ? null
            : value.reminderDateAndTime,

        country:
          value.country === undefined || value.country === null
            ? null
            : value.country,

        countryOfResidence:
          value.countryOfResidence === undefined ||
          value.countryOfResidence === null
            ? null
            : value.countryOfResidence,
        consultant:
          value.consultant === undefined || value.consultant === null
            ? null
            : value.consultant,

        // sendByEmail:
        //   value.sendByEmail === undefined || value.sendByEmail === null
        //     ? null
        //     : value.sendByEmail,
        sessionTime:
          value.sessionTime === undefined || value.sessionTime === null
            ? null
            : value.sessionTime,
        counsellingStartTime: value.sessionTime ? true : null,
        counsellingEndTime: value.sessionTime ? true : null,

        note:
          value.note === undefined || value.note === null ? null : value.note,
      },
    };

    dispatch({
      type: "ADD_APPOINTMENT_FORM_FIELDS_REQUEST",
      payload: {
        campaignId: record.id,
        formData: appointmentFormData?.formData,
      },
    });
    form.resetFields();
    setIsAddAppointmentFormModalVisible(false);
  };
  return (
    <Modal
      title="Add Appointment form fields"
      open={isAddAppointmentFormModalVisible}
      onCancel={() => {
        setIsAddAppointmentFormModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addAppointmentFormSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["firstName"],
            value: appointmentData.firstName,
          },
          {
            name: ["lastName"],
            value: appointmentData.lastName,
          },
          {
            name: ["email"],
            value: appointmentData.email,
          },
          {
            name: ["mobile"],
            value: appointmentData.mobile,
          },
          {
            name: ["countryOfResidence"],
            value: appointmentData.countryOfResidence,
          },
          {
            name: ["appointmentDate"],
            value: appointmentData.appointmentDate,
          },
          {
            name: ["reminderDateAndTime"],
            value: appointmentData.reminderDateAndTime,
          },
          {
            name: ["country"],
            value: appointmentData.country,
          },
          {
            name: ["consultant"],
            value: appointmentData.consultant,
          },
          {
            name: ["sessionTime"],
            value: appointmentData.sessionTime,
          },
          {
            name: ["note"],
            value: appointmentData.note,
          },
        ]}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Form.Item
            name={"firstName"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox
              value={true}
              checked
              onChange={(e) => {
                setAppointmentData((previousData) => {
                  return {
                    ...previousData,
                    firstName: e.target.checked,
                  };
                });
              }}
            >
              Firstname
            </Checkbox>
          </Form.Item>
          <Form.Item
            name={"lastName"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox
              value={true}
              checked
              onChange={(e) => {
                setAppointmentData((previousData) => {
                  return {
                    ...previousData,
                    lastName: e.target.checked,
                  };
                });
              }}
            >
              Lastname
            </Checkbox>
          </Form.Item>
          <Form.Item
            name={"email"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox
              value={"email"}
              onChange={(e) => {
                setAppointmentData((previousData) => {
                  return {
                    ...previousData,
                    email: e.target.checked,
                  };
                });
              }}
            >
              Email
            </Checkbox>
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Form.Item
            name={"mobile"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox
              value={"mobile"}
              onChange={(e) => {
                setAppointmentData((previousData) => {
                  return {
                    ...previousData,
                    mobile: e.target.checked,
                  };
                });
              }}
            >
              Mobile
            </Checkbox>
          </Form.Item>
          <Form.Item
            name={"appointmentDate"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox
              value={"appointmentDate"}
              onChange={(e) => {
                setAppointmentData((previousData) => {
                  return {
                    ...previousData,
                    appointmentDate: e.target.checked,
                  };
                });
              }}
            >
              Appointment date
            </Checkbox>
          </Form.Item>
          <Form.Item
            name={"reminderDateAndTime"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox
              value={"reminderDateAndTime"}
              onChange={(e) => {
                setAppointmentData((previousData) => {
                  return {
                    ...previousData,
                    reminderDateAndTime: e.target.checked,
                  };
                });
              }}
            >
              Reminder date/time
            </Checkbox>
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Form.Item
            name={"country"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox
              value={"country"}
              onChange={(e) => {
                setAppointmentData((previousData) => {
                  return {
                    ...previousData,
                    country: e.target.checked,
                  };
                });
              }}
            >
              Country
            </Checkbox>
          </Form.Item>

          <Form.Item
            name={"countryOfResidence"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox
              value={"countryOfResidence"}
              onChange={(e) => {
                setAppointmentData((previousData) => {
                  return {
                    ...previousData,
                    countryOfResidence: e.target.checked,
                  };
                });
              }}
            >
              Country of residence
            </Checkbox>
          </Form.Item>
          <Form.Item
            name={"consultant"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox
              value={"consultant"}
              onChange={(e) => {
                setAppointmentData((previousData) => {
                  return {
                    ...previousData,
                    consultant: e.target.checked,
                  };
                });
              }}
            >
              Counsellor
            </Checkbox>
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Form.Item
            name={"sessionTime"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox
              value={"sessionTime"}
              onChange={(e) => {
                setAppointmentData((previousData) => {
                  return {
                    ...previousData,
                    sessionTime: e.target.checked,
                  };
                });
              }}
            >
              Session time
            </Checkbox>
          </Form.Item>

          <Form.Item
            name={"note"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox
              value={"note"}
              onChange={(e) => {
                setAppointmentData((previousData) => {
                  return {
                    ...previousData,
                    note: e.target.checked,
                  };
                });
              }}
            >
              Note
            </Checkbox>
          </Form.Item>
          {/* <Form.Item
            name={"sendbyEmail"}
            valuePropName="checked"
            style={{ width: "25%", margin: 0 }}
          >
            <Checkbox value={"sendByEmail"}>Send by email</Checkbox>
          </Form.Item> */}
        </div>

        {/* </Checkbox.Group> */}

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Appointment Form Fields
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddAppointmentForm;
