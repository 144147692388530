import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  dashboardData: {},
  dashboardDataList: {},
};

const DashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_DASHBOARD_DATA_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_DASHBOARD_DATA_REQUEST_SUCCESS": {
      return {
        ...state,
        dashboardData: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_DASHBOARD_DATA_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load dashboard");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_DASHBOARD_DATA_LIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_DASHBOARD_DATA_LIST_REQUEST_SUCCESS": {
      return {
        ...state,
        dashboardDataList: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_DASHBOARD_DATA_LIST_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load dashboard");
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default DashboardReducer;
