import { Button, Card, Checkbox, Form } from "antd";
import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import OrganizationalSettings from "../OrganizationalSettings";
const CheckboxGroup = Checkbox.Group;

const DashboardSettings = () => {
  const store = useSelector((state) => {
    return {
      dashboardState: state.DashboardReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const [counterOptionsList, setCounterOptionsList] = useState([]);
  const [analysisOptionsList, setAnalysisOptionsList] = useState([]);
  // defaultAnalysisOptionsCheckedList
  const [latestOptionsList, setLatestOptionsList] = useState([]);
  // defaultLatestOptionsCheckedList
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "FETCH_DASHBOARD_DATA_LIST_REQUEST",
      payload: {
        branchId:
          store.branchState?.branchRecord.id ??
          localStorage.getItem("branchId"),
      },
    });
  }, [store.branchState?.branchRecord]);
  const [current, setCurrent] = useState("counter");

  const clientDetailsTabList = [
    {
      key: "counter",
      tab: "Counter",
    },
    {
      key: "analysis",
      tab: "Analysis",
    },
    {
      key: "latest",
      tab: "Latest",
    },
  ];

  const counterOptions = [
    "Contact",
    "Client",
    "Leads",
    "Appointment",
    "Cases",
    "Courses",
    "Institute",
    "Employees",
    "Companies",
  ];
  const analysisOptions = [
    "Contact",
    "Client",
    "Leads",
    "Appointment",
    "Cases",
    "Courses",
    "Institute",
    "Employees",

    "Companies",
  ];
  const latestOptions = ["Contact", "Leads", "Appointment", "Cases", "Client"];

  useMemo(() => {
    const filterCounter =
      store.dashboardState?.dashboardDataList?.dashboardSettingResponse &&
      Object.keys(
        store.dashboardState?.dashboardDataList?.dashboardSettingResponse
      ).filter((key) => {
        return (
          store.dashboardState?.dashboardDataList?.dashboardSettingResponse[
            key
          ] === true
        );
      });
    const filterMapCounter =
      filterCounter &&
      filterCounter?.filter((key) => {
        return key.includes("counter");
      });
    const filterMapAnalysis =
      filterCounter &&
      filterCounter?.filter((key) => {
        return key.includes("analysis");
      });
    const filterMapLatest =
      filterCounter &&
      filterCounter?.filter((key) => {
        return key.includes("latest");
      });

    const defaultCounterOptionsCheckedList =
      filterMapCounter &&
      filterMapCounter?.map((key) => {
        let newString = "";
        if (key.includes("counter")) {
          newString = key.replace(new RegExp("counter", "g"), "");
        }
        return newString;
      });
    const defaultAnalysisOptionsCheckedList =
      filterMapAnalysis &&
      filterMapAnalysis?.map((key) => {
        let newString = "";
        if (key.includes("analysis")) {
          newString = key.replace(new RegExp("analysis", "g"), "");
        }
        return newString;
      });
    const defaultLatestOptionsCheckedList =
      filterMapLatest &&
      filterMapLatest?.map((key) => {
        let newString = "";
        if (key.includes("latest")) {
          newString = key.replace(new RegExp("latest", "g"), "");
        }
        return newString;
      });
    setCounterOptionsList(
      defaultCounterOptionsCheckedList ? defaultCounterOptionsCheckedList : []
    );
    setAnalysisOptionsList(
      defaultAnalysisOptionsCheckedList ? defaultAnalysisOptionsCheckedList : []
    );
    setLatestOptionsList(
      defaultLatestOptionsCheckedList ? defaultLatestOptionsCheckedList : []
    );
  }, [store.dashboardState?.dashboardDataList?.dashboardSettingResponse]);

  const defaultAnalysisOptionsCheckedList = [
    "Contact",
    "Cases",
    "Leads",
    "Client",
  ];
  const defaultLatestOptionsCheckedList = [
    "Contact",
    "Cases",
    "Leads",
    "Appointment",
  ];

  const handleDashboardSettings = () => {
    const objList = {
      counterContact: false,
      counterClient: false,
      counterLeads: false,
      counterAppointment: false,
      counterCases: false,
      counterCourses: false,
      counterInstitute: false,
      counterEmployees: false,
      counterDepartment: false,
      counterCompanies: false,
      analysisContact: false,
      analysisClient: false,
      analysisLeads: false,
      analysisAppointment: false,
      analysisCases: false,
      analysisCourses: false,
      analysisInstitute: false,
      analysisEmployees: false,
      analysisDepartment: false,
      analysisCompanies: false,
      latestContact: false,
      latestLeads: false,
      latestAppointment: false,
      latestCases: false,
    };
    const obj = {};
    counterOptionsList.forEach((elem, i) => {
      obj[`counter${elem}`] = true;
    });
    const obj1 = {};
    analysisOptionsList.forEach((elem, i) => {
      obj1[`analysis${elem}`] = true;
    });
    const obj2 = {};
    latestOptionsList.forEach((elem, i) => {
      obj2[`latest${elem}`] = true;
    });

    const payload = {
      ...obj,
      ...obj1,
      ...obj2,
    };
    const formData = {
      ...objList,
      ...payload,
      leadVsCases: true,
    };
    dispatch({
      type: "UPDATE_DASHBOARD_SETTINGS_REQUEST",
      payload: {
        branchId:
          store.branchState?.branchRecord.id ??
          localStorage.getItem("branchId"),
        formData,
      },
    });
  };

  return (
    <OrganizationalSettings>
      <div>
        <h3>Dashboard Settings</h3>

        <Card
          bordered={false}
          //   tabList={clientDetailsTabList}
          //   activeTabKey={current}
          //   onTabChange={(key) => {
          //     setCurrent(key);
          //   }}
        >
          <div>
            <h3>
              Counters: <h6>Choose 4 blocks to display on dashboard</h6>
            </h3>
            <CheckboxGroup
              options={counterOptions}
              value={counterOptionsList}
              onChange={(list) => {
                if (counterOptionsList?.length < 4) {
                  setCounterOptionsList(list);
                } else {
                  if (list?.length < counterOptionsList?.length) {
                    setCounterOptionsList(list);
                    return;
                  }
                  setCounterOptionsList(counterOptionsList);
                }
              }}
              //   disabled={counterOptionsList?.length > 5}
              style={{ margin: "1rem" }}
            />
          </div>
          <div>
            <h3>
              Analysis: <h6>Choose 4 blocks to display on dashboard</h6>
            </h3>
            <CheckboxGroup
              options={analysisOptions}
              value={analysisOptionsList}
              onChange={(list) => {
                if (analysisOptionsList?.length < 4) {
                  setAnalysisOptionsList(list);
                } else {
                  if (list?.length < analysisOptionsList?.length) {
                    setAnalysisOptionsList(list);
                    return;
                  }
                  setAnalysisOptionsList(counterOptionsList);
                }
              }}
              style={{ margin: "1rem" }}
            />
          </div>
          <div>
            <h3>Latest:</h3>
            <CheckboxGroup
              options={latestOptions}
              value={latestOptionsList}
              onChange={(list) => {
                setLatestOptionsList(list);
                // if (latestOptionsList?.length <= 4) {
                //   setLatestOptionsList(list);
                // } else {
                //   setLatestOptionsList(latestOptionsList);
                // }
              }}
              style={{ margin: "1rem" }}
            />
          </div>
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {store.profileState?.profile?.department?.rolesAndPermissionList?.DASHBOARD_SETTINGS?.includes(
              "UPDATE"
            ) && (
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleDashboardSettings}
              >
                Save Changes
              </Button>
            )}
          </Form.Item>
        </Card>
      </div>
    </OrganizationalSettings>
  );
};

export default DashboardSettings;
