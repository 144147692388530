import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../../Components/Layout/SiderDemo";
import { formattedDate } from "../../../HelperFunction";
import { AiOutlineEye, AiOutlineHistory } from "react-icons/ai";
import { CgAssign } from "react-icons/cg";
import AddVisaCases from "../../Visas/AddVisas";
import UpdateCaseStatus from "../../Visas/UpdateCaseStatus";
import CaseStatusHistory from "../../Visas/CaseStatusHistory";
import VisaCaseDetails from "../../Visas/VisaCaseDetails";
import AssignAgents from "../../Visas/AssignAgents";
const columns = [
  {
    title: "Case No.",
    dataIndex: "caseNo",
  },
  {
    title: "Client",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "Visa Type",
    dataIndex: "visaCategory",
    align: "center",
  },
  {
    title: "Sales Rep",
    dataIndex: "salesRepName",
    align: "center",
  },
  {
    title: "Grand Total",
    dataIndex: "totalAmount",
    align: "center",
  },

  {
    title: "Added Date",
    dataIndex: "addedOn",
    align: "center",
  },

  {
    title: "Lead Type",
    dataIndex: "leadType",
    align: "center",
  },
  {
    title: "Visa Outcome",
    dataIndex: "visaOutcome",
    align: "center",
  },
  {
    title: "Case Status",
    dataIndex: "status",
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const VisaTypeCases = () => {
  const visaState = useSelector((state) => state.VisaClassReducer);
  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);
  const [isUpdateCaseStatusModalVisible, setIsUpdateCaseStatusModalVisible] =
    useState(false);
  const [isStatusHistoryModelVisible, setIsStatusHistoryModelVisible] =
    useState(false);
  const [isVisaCaseDetailsModalVisible, setIsVisaCaseDetailsModalVisible] =
    useState(false);
  const [isAssignAgentsModallVisible, setIsAssignAgentsModallVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const [visaType, setVisaType] = useState("migrationVisaCases");

  const dispatch = useDispatch();
  useEffect(() => {
    if (visaType === "migrationVisaCases") {
      dispatch({
        type: "FETCH_MIGRATION_VISA_TYPE_CASES_REQUEST",
      });
    } else if (visaType === "educationVisaCases") {
      dispatch({ type: "FETCH_EDUCATION_VISA_TYPE_CASES_REQUEST" });
    }
  }, [visaType]);

  let data = [];
  if (visaType === "migrationVisaCases") {
    data = visaState.migrationVisaCases?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        caseNo: dataObj.id,
        visaOutcome: dataObj.visaOutcome ? (
          <Tag
            color={
              dataObj.visaOutcomeColorCode
                ? dataObj.visaOutcomeColorCode
                : "White"
            }
          >
            {dataObj.visaOutcome}
          </Tag>
        ) : (
          "N/A"
        ),
        caseStatus: dataObj.caseStatus?.caseStatus,
        name: dataObj.customer.firstName + " " + dataObj.customer.lastName,
        visaCategory: dataObj.visaCategory,
        salesRepName: dataObj.salesRepresentativeResponse?.salesRepName,
        totalAmount: dataObj.totalAmount ?? 0,
        percentage: dataObj.institute?.percentage,
        commission: dataObj.institute?.commission,
        addedOn: formattedDate(dataObj.addedOn),
        leadType: dataObj.leadType,
        status: dataObj.workFlowStatusDto?.statusName ? (
          <Tag
            color={
              dataObj.workFlowStatusDto?.colorCode
                ? dataObj.workFlowStatusDto?.colorCode
                : "green"
            }
          >
            {dataObj.workFlowStatusDto?.statusName}
          </Tag>
        ) : null,

        actions: (
          <Space size="middle">
            {/* <Tooltip title="Update Case Status">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateCaseStatusModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip> */}
            {/* <Tooltip title="Delete Visas">
            <Popconfirm
              title="Are you sure to delete this Visas?"
              onConfirm={() => {
                dispatch({
                  type: "DELETE_VISAS_REQUEST",
                  payload: { id: dataObj.id },
                });
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <div className="bordered">
                  <DeleteOutlined />
                </div>
              </a>{" "}
            </Popconfirm>
          </Tooltip> */}

            <Tooltip title="Case Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsVisaCaseDetailsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="View status History">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsStatusHistoryModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineHistory />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Assign Agents">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsAssignAgentsModallVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <CgAssign />
                </div>
              </a>
            </Tooltip>
          </Space>
        ),
      };
    });
  } else if (visaType === "educationVisaCases") {
    data = visaState.educationVisaCases?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        caseNo: dataObj.id,
        caseStatus: dataObj.caseStatus?.caseStatus,
        visaOutcome: dataObj.visaOutcome ? (
          <Tag
            color={
              dataObj.visaOutcomeColorCode
                ? dataObj.visaOutcomeColorCode
                : "White"
            }
          >
            {dataObj.visaOutcome}
          </Tag>
        ) : (
          "N/A"
        ),
        name: dataObj.customer.firstName + " " + dataObj.customer.lastName,
        visaCategory: dataObj.visaCategory,
        salesRepName: dataObj.salesRepresentativeResponse?.salesRepName,
        totalAmount: dataObj.totalAmount ?? 0,
        percentage: dataObj.institute?.percentage,
        commission: dataObj.institute?.commission,
        addedOn: formattedDate(dataObj.addedOn),
        leadType: dataObj.leadType,
        status: dataObj.workFlowStatusDto?.statusName ? (
          <Tag
            color={
              dataObj.workFlowStatusDto?.colorCode
                ? dataObj.workFlowStatusDto?.colorCode
                : "green"
            }
          >
            {dataObj.workFlowStatusDto?.statusName}
          </Tag>
        ) : null,

        statusUpdate: (
          <Space>
            <Tooltip title="Case Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsVisaCaseDetailsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="View status History">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsStatusHistoryModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineHistory />
                </div>
              </a>
            </Tooltip>
          </Space>
        ),

        actions: (
          <Space size="middle">
            {/* <Tooltip title="Update Case Status">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateCaseStatusModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip> */}
            {/* <Tooltip title="Delete Visas">
              <Popconfirm
                title="Are you sure to delete this Visas?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_VISAS_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip> */}

            <Tooltip title="Case Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsVisaCaseDetailsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="View status History">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsStatusHistoryModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineHistory />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Assign Agents">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsAssignAgentsModallVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <CgAssign />
                </div>
              </a>
            </Tooltip>
          </Space>
        ),
      };
    });
  }
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          <button
            className="button"
            onClick={() => {
              setIsAddVisasModalVisible(true);
            }}
          >
            <span>Add VisaTypeCases</span>
          </button>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Migration</div>
                  </div>
                ),
                value: "migrationVisaCases",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Education</div>
                  </div>
                ),
                value: "educationVisaCases",
              },
            ]}
            onChange={(value) => {
              setVisaType(value);
            }}
          />
        </div>
        <div style={{ marginTop: "4rem" }}>
          <Table bordered columns={columns} />
        </div>
      </div>
      <AddVisaCases
        isAddVisaCasesModalVisible={isAddVisasModalVisible}
        setIsAddVisaCasesModalVisible={setIsAddVisasModalVisible}
      />
      <UpdateCaseStatus
        record={record}
        isUpdateCaseStatusModalVisible={isUpdateCaseStatusModalVisible}
        setIsUpdateCaseStatusModalVisible={setIsUpdateCaseStatusModalVisible}
      />
      <CaseStatusHistory
        record={record}
        isUpdateCaseStatusModalVisible={isStatusHistoryModelVisible}
        setIsUpdateCaseStatusModalVisible={setIsStatusHistoryModelVisible}
      />
      <VisaCaseDetails
        visaRecord={record}
        record={record}
        isVisaCaseDetailsModalVisible={isVisaCaseDetailsModalVisible}
        setIsVisaCaseDetailsModalVisible={setIsVisaCaseDetailsModalVisible}
      />
      <AssignAgents
        record={record}
        isAssignAgentsModalVisible={isAssignAgentsModallVisible}
        setIsAssignAgentsModalVisible={setIsAssignAgentsModallVisible}
      />
      {/* <AddVisaTypeCases
        isAddVisaTypeCasesModalVisible={isAddVisaTypeCasesModalVisible}
        setIsAddVisaTypeCasesModalVisible={setIsAddVisaTypeCasesModalVisible}
      />
      <UpdateVisaTypeCases
        record={record}
        isUpdateVisaTypeCasesModalVisible={isUpdateVisaTypeCasesModalVisible}
        setIsUpdateVisaTypeCasesModalVisible={
          setIsUpdateVisaTypeCasesModalVisible
        }
      /> */}
    </SiderDemo>
  );
};

export default VisaTypeCases;
