import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Tag,
  TimePicker,
} from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  date,
  dateAndTime,
  getTimeZone,
  scrollToTopFormError,
  YYYYMMDD,
} from "../../HelperFunction";
import moment from "moment";
import Purpose from "./Purpose/Purpose";
import AddAboutUs from "./AboutUs/AddAboutUs";
import AddCountry from "./Country/AddCountry";
import AddLeadSource from "./LeadSource/AddLeadSource";
import AddLeadStatus from "./LeadStatus/AddLeadStatus";
import AddEmployee from "../EmployeeManager/AddEmployee";
import AddReferral from "./Referral/AddReferral";
import { useNavigate } from "react-router-dom";
import AddContacts from "../Contacts/AddContacts";
import AddSalesRep from "../Visas/AddSalesRep";
import axios from "axios";
import { getCookie } from "../../Helpers/FrontendHelper";
import Cookies from "js-cookie";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
const { Option } = Select;
const { TextArea } = Input;

const AddLead = ({
  leadReminderData,
  fromClientsDetails,
  fromSiderDemo,
  fromContacts,
  contactRecord,
  isAddLeadVisible,
  setIsAddLeadVisible,

  // setIsAddAboutUsModalVisible,
  // setIsAddLeadSourceModalVisible,
  // setIsAddCountryModalVisible,
  // setIsAddLeadStatusModalVisible,
  // setIsAddPurposeModalVisible,
  // setIsAddConsultantModalVisible,
  // setIsAddReferralModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      timingDropdownState: state.AppointmentsReducer,
      leadState: state.LeadReducer,
    };
  });

  console.log("contact", contactRecord);
  const addContactsState = useSelector((state) => state.LeadReducer);
  const [showMore, setShowMore] = useState(false);

  const [isAddPurposeModalVisible, setIsAddPurposeModalVisible] =
    useState(false);
  const [isAddAboutUsModalVisible, setIsAddAboutUsModalVisible] =
    useState(false);
  const [isAddLeadSourceModalVisible, setIsAddLeadSourceModalVisible] =
    useState(false);
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [isAddLeadStatusModalVisible, setIsAddLeadStatusModalVisible] =
    useState(false);
  const [isAddConsultantModalVisible, setIsAddConsultantModalVisible] =
    useState(false);
  const [isAddReferralModalVisible, setIsAddReferralModalVisible] =
    useState(false);
  const [isAddContactVisible, setIsAddContactVisible] = useState(false);

  const [firstName, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [purposeName, setPurposeName] = useState(null);
  const [leadSourceName, setLeadSourceName] = useState();
  const [leadSourceId, setLeadSourceId] = useState();
  const [countryOfResidence, setCountryOfResidence] = useState();
  const [countryToMigrate, setCountryToMigrate] = useState();
  const [leadStatusId, setLeadStatusId] = useState(null);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isCouncelling, setIsCouncelling] = useState();
  const [isReferral, setIsReferral] = useState(false);
  const [record, setRecord] = useState(contactRecord);
  const [counsellorId, setCounsellorId] = useState();
  const [appointmentDate, setAppointmentDate] = useState();
  const [sessionTime, setSessionTime] = useState();
  const [isContactType, setIsContactType] = useState(true);
  const [contactId, setContactId] = useState();
  const [isBookingCouncelling, setIsBookingCouncelling] = useState(false);
  const [activeEmployees, setActiceEmployees] = useState([]);
  const [leadData, setLeadData] = useState({
    salesRepresentativeName: null,
    salesRepresentativeId: null,
    updateWeightage: null,
  });
  const [isAddSalesRepModalVisible, setIsAddSalesRepModalVisible] =
    useState(false);

  useEffect(() => {
    fetchActiveEmployeList();
  }, []);

  const fetchActiveEmployeList = async () => {
    const tempToken = Cookies.get("accessToken");
    await axios
      .get(`v1/api/client/get-active-user-list`, {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      })
      .then((res) => {
        setActiceEmployees(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (counsellorId && appointmentDate && sessionTime) {
      dispatch({
        type: "FETCH_TIMING_DROPDOWN_REQUEST",
        payload: {
          counsellorId,
          sessionTime,
          values: {
            appointmentDate: appointmentDate,
            timeOffset: getTimeZone(),
          },
        },
      });
    }
  }, [counsellorId, appointmentDate, sessionTime]);
  useEffect(() => {
    if (isAddLeadVisible === true) {
      dispatch({
        type: "FETCH_LEADS_DROPDOWN_REQUEST",
        payload: {
          branchId: localStorage.getItem("branchId"),
          fromInstituteVisaCase: true,
          visaSuccessFunction: leadSuccessFunction,
        },
      });
      setRecord(contactRecord);
      setContactId(contactRecord?.id);
      setCountryOfResidence(contactRecord?.countryOfResidence);
      setCountryToMigrate(contactRecord?.countryToMigrate);
      setLeadSourceId(contactRecord?.source?.id);
      setLeadSourceName(contactRecord?.source?.name);
      setMobileNumber(
        contactRecord?.phoneNumber
          ? contactRecord?.phoneNumber
          : contactRecord?.mobile
          ? contactRecord?.mobile
          : null
      );
      setEmail(contactRecord?.email);
      setLastname(contactRecord?.lastName);
      setFirstname(contactRecord?.firstName);
      dispatch({ type: "CLEAR_AVAILABLE_DATE_AND_TIME" });
    }
  }, [isAddLeadVisible]);
  useEffect(() => {
    if (setIsContactType === false) {
      setRecord(null);
    }
  }, [setIsContactType]);
  const navigate = useNavigate();
  const leadSuccessFunction = (response) => {
    setCountryOfResidence(response?.data?.defaultCountry?.name);
    // response?.data?.leadStatus?.map((dataObj) => {
    //   console.log("defaulst leadStatus", dataObj);
    //   if (dataObj?.isDefault) {
    //     console.log("defaulst leadStatus da", dataObj);
    //     setIsCouncelling(dataObj?.statusName);
    //     setLeadStatusId(dataObj?.id);
    //   }
    // });
    setIsCouncelling(response?.data?.leadStatus[0]?.statusName);
    setLeadStatusId(response?.data?.leadStatus[0]?.id);
  };

  const addLeadSubmitHandler = (values) => {
    const reminderDateAndTime = dateAndTime(
      values.remainderDate,
      values.remainderTime
    );
    const counsellingBookingDateAndTime = dateAndTime(
      values.bookingDate,
      values.bookingTime
    );

    let payload;
    const bookingDate = isCouncelling ? date(values.appointmentDate) : null;
    const bookingDay = moment(values.appointmentDate).format("dddd");
    if (isCouncelling === "BOOK_AN_APPOINTMENT") {
      payload = {
        ...values,
        // campaign: isContactType ? null : values?.campaign,
        branchId: localStorage.getItem("branchId"),
        contactId: isContactType ? contactId : null,
        leadSourceId: leadSourceId ? leadSourceId : null,
        appointmentDate: date(values.appointmentDate),
        assignCounsellor: isBookingCouncelling,
        leadStatusId: leadStatusId,
        bookingDate,
        consultantId: counsellorId,
        reminderDateAndTime,
        bookingDay,
        appointmentDateTime: counsellingBookingDateAndTime,
        sendByEmail: values.sendByEmail ? values.sendByEmail : false,
        salesRepresentativeId: leadData?.salesRepresentativeId
          ? leadData?.salesRepresentativeId
          : null,
        updateWeightage: leadData?.updateWeightage
          ? leadData?.updateWeightage
          : null,
      };
    } else {
      payload = {
        ...values,
        // campaign: isContactType ? null : values?.campaign,
        branchId: localStorage.getItem("branchId"),
        leadSourceId: leadSourceId ? leadSourceId : null,
        leadStatusId: leadStatusId,
        contactId: isContactType ? contactId : null,
        reminderDateAndTime,
        sendByEmail: values.sendByEmail ? values.sendByEmail : false,
        salesRepresentativeId: leadData?.salesRepresentativeId
          ? leadData?.salesRepresentativeId
          : null,
        updateWeightage: leadData?.updateWeightage
          ? leadData?.updateWeightage
          : null,
      };
    }

    dispatch({
      type: "ADD_LEAD_REQUEST",
      payload,
      payload2: {
        fromClientsDetails: fromClientsDetails ? true : false,
        contactRecordID: contactRecord?.id,
        successFunction: successFunction,
      },
    });
    dispatch({ type: "CLEAR_AVAILABLE_DATE_AND_TIME" });

    setIsCouncelling(null);
    form.resetFields();
    setIsAddLeadVisible(false);
    setLeadSourceId(null);
    setLeadSourceName(null);
    setMobileNumber(null);
    setEmail(null);
    setLastname(null);
    setFirstname(null);
    setRecord(null);
    if (fromSiderDemo || fromContacts) {
      navigate("/leads");
    }
  };
  const successFunction = () => {
    dispatch({
      type: "FETCH_LEAD_CUSTOMER_BY_ID_REQUEST",
      payload: contactRecord?.id,
    });
  };

  return (
    <Drawer
      title="Add Lead"
      placement="right"
      open={isAddLeadVisible}
      onClose={() => {
        setIsAddLeadVisible(false);
        setLeadSourceId(null);
        setLeadSourceName(null);
        setMobileNumber(null);
        setEmail(null);
        setLastname(null);
        setFirstname(null);
        setRecord(null);
      }}
      width={1163}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addLeadSubmitHandler}
        onFinishFailed={scrollToTopFormError}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["ContactType"],
            value: isContactType,
          },
          {
            name: ["contact"],
            value: contactId,
          },
          {
            name: ["firstName"],
            value: firstName,
          },
          {
            name: ["lastName"],
            value: lastname,
          },
          {
            name: ["email"],
            value: email,
          },
          {
            name: ["mobile"],
            value: mobileNumber,
          },
          {
            name: ["leadSource"],
            value: leadSourceName,
          },
          {
            name: ["leadStatus"],
            value: isCouncelling,
            // value: "PENDING",
          },
          {
            name: ["countryToMigrate"],
            value: countryToMigrate,
          },
          {
            name: ["countryOfResidence"],
            value: countryOfResidence,
          },
          {
            name: ["bookingStatus"],
            value: isBookingCouncelling ?? false,
          },
          {
            name: ["salesRepresentative"],
            value: leadData?.salesRepresentativeName,
          },
          {
            name: ["updateWeightage"],
            value: leadData?.updateWeightage,
          },
          {
            name: ["campaign"],
            value: contactRecord?.campaign
              ? contactRecord?.campaign?.name
              : addContactsState?.leadsDropdown?.campaign?.length > 0 &&
                addContactsState.leadsDropdown?.campaign[0]?.campaignName,
          },
        ]}
        // initialValues={[
        //   {
        //     name: ["firstName"],
        //     value: record?.firstName,
        //   },
        //   {
        //     name: ["lastName"],
        //     value: record?.lastName,
        //   },
        //   {
        //     name: ["email"],
        //     value: record?.email,
        //   },
        //   {
        //     name: ["mobile"],
        //     value: record?.phoneNumber,
        //   },
        // ]}
        initialValues={{
          remainderDate: moment().add(leadReminderData?.leadReminder, "days"),
          remainderTime: moment(),
          // purposeId: store.leadState.leadsDropdown?.purposes?.[0].id,
          // aboutUsId: store.leadState.leadsDropdown?.aboutUs?.[0].id,
          referral: false,
          leadType: "COMPANY ASSIGNED",
        }}
      >
        <div className="flexColumn">
          <div>
            <h2 className="cardHeader">Add a Lead</h2>

            <Form.Item
              name={"ContactType"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select client type",
                },
              ]}
            >
              <Radio.Group
                defaultValue={true}
                onChange={(e) => {
                  console.log("from chang contact type", e);
                  setIsContactType(e.target.value);
                  if (e.target.value === false) {
                    setRecord(null);
                    setContactId(null);
                    setCountryOfResidence(null);
                    setLeadSourceId(null);
                    setLeadSourceName(null);
                    setMobileNumber(null);
                    setEmail(null);
                    setLastname(null);
                    setFirstname(null);
                  }
                }}
              >
                <Radio value={true}>Existing Contact</Radio>
                <Radio value={false}>New Contact</Radio>
              </Radio.Group>
            </Form.Item>
            {isContactType === true ? (
              <div className="flexRowWithoutStyle" style={{ gap: "0.3rem" }}>
                <Form.Item
                  label="Select Contact"
                  name={"contact"}
                  style={{ width: "95%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter contact!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select a Contact"
                    optionFilterProp="children"
                    onSearch={(e) => {
                      console.log("On search", e);
                    }}
                    filterOption={(input, option) => {
                      console.log("input and option", input, option);
                      return (option?.children?.props?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    value={
                      record ? `${record?.firstName} ${record?.lastName}` : null
                    }
                  >
                    {store.leadState.leadsDropdown?.contacts?.map((dataObj) => {
                      return (
                        <Option key={dataObj.id} value={dataObj.id}>
                          <div
                            onClick={() => {
                              setRecord(dataObj);
                              setContactId(dataObj.id);
                              setCountryOfResidence(
                                dataObj?.countryOfResidence
                              );
                              setLeadSourceId(dataObj?.source?.id);
                              setLeadSourceName(dataObj?.source?.name);
                              setMobileNumber(
                                dataObj?.phoneNumber
                                  ? dataObj?.phoneNumber
                                  : dataObj?.mobile
                                  ? dataObj?.mobile
                                  : null
                              );
                              setEmail(dataObj?.email);
                              setLastname(dataObj?.lastName ?? "");
                              setFirstname(dataObj?.firstName ?? "");
                            }}
                            className={"selectOptionDiv"}
                          >
                            <span>
                              {" "}
                              {`${dataObj.firstName ?? ""} ${
                                dataObj?.lastName ?? ""
                              } 
                            ${
                              dataObj.email !== null && dataObj.email !== ""
                                ? `(${dataObj.email})`
                                : dataObj.phoneNumber !== null &&
                                  dataObj.phoneNumber !== ""
                                ? `(${dataObj.phoneNumber})`
                                : ""
                            }
                            `}
                              {dataObj?.isClient ? (
                                <Tag color="blue">Client</Tag>
                              ) : (
                                <Tag color="orange">Contact</Tag>
                              )}
                            </span>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                {!fromContacts && (
                  <a
                    className="plusButton"
                    onClick={() => {
                      setIsAddContactVisible(true);
                    }}
                  >
                    <AiOutlinePlus className="iconColor" />
                  </a>
                )}
              </div>
            ) : null}

            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="First Name"
                name={"firstName"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter first name!",
                  },
                ]}
              >
                <Input
                  placeholder="First Name"
                  onChange={(e) => {
                    setFirstname(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name={"lastName"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter last name!",
                  },
                ]}
              >
                <Input
                  placeholder="Last Name"
                  onChange={(e) => {
                    setLastname(e.target.value);
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Email"
                name={"email"}
                style={{ width: "100%" }}
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email",
                  },
                  {
                    validator: async (_, value) => {
                      const token = getCookie("accessToken");
                      const response = await axios.get(
                        // `${
                        //   process.env.REACT_APP_TEST_URL
                        // }v2/api/client/branch/customer/validate-email/${localStorage.getItem(
                        //   "branchId"
                        // )}?email=${value}`,
                        `v2/api/client/branch/customer/validate-email/${localStorage.getItem(
                          "branchId"
                        )}?email=${value}`,
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                          },
                        }
                      );
                      console.log("response frm email", response);
                      if (
                        response?.data === false ||
                        value === "" ||
                        value === null ||
                        value === undefined
                      ) {
                        return Promise.resolve();
                      } else if (
                        response?.data === true &&
                        isContactType === false
                      ) {
                        return Promise.reject(
                          new Error("Entered email already exists")
                        );
                      }
                    },
                  },
                ]}
                validateTrigger={["onBlur"]}
              >
                <Input
                  type="email"
                  placeholder="user@domain.com"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    // dispatch({
                    //   type: "CHECK_FOR_EXISTING_EMAIL_REQUEST",
                    //   payload: {
                    //     keyword: e.target.value,
                    //   },
                    // });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Mobile Number"
                name={"mobile"}
                style={{ width: "100%" }}
                rules={[
                  {
                    pattern: /^[\d]{9,11}$/,
                    message: "Mobile number should contain 9 to 11 numbers",
                  },
                  {
                    validator: async (_, value) => {
                      const token = getCookie("accessToken");
                      const response = await axios.get(
                        // `${
                        //   process.env.REACT_APP_TEST_URL
                        // }v2/api/client/branch/customer/validate-mobile/${localStorage.getItem(
                        //   "branchId"
                        // )}?mobile=${value}`,
                        `v2/api/client/branch/customer/validate-mobile/${localStorage.getItem(
                          "branchId"
                        )}?mobile=${value}`,
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                          },
                        }
                      );
                      if (
                        response?.data === false ||
                        value === "" ||
                        value === null ||
                        value === undefined
                      ) {
                        return Promise.resolve();
                      } else if (
                        response?.data === true &&
                        isContactType === false
                      ) {
                        return Promise.reject(
                          new Error("Entered mobile number already exists")
                        );
                      }
                    },
                  },
                ]}
                validateTrigger={["onBlur"]}
              >
                <Input
                  type="number"
                  placeholder="Mobile Number"
                  onChange={(e) => {
                    setMobileNumber(e.target.value);
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Purpose"
                  name={"purposeId"}
                  style={{ width: "95%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select your purpose!",
                    },
                  ]}
                >
                  <Select
                  // defaultValue={
                  //   store.leadState.leadsDropdown?.purposes?.[0].id
                  // }
                  >
                    {store.leadState.leadsDropdown?.purposes?.map((dataObj) => {
                      return (
                        <Option key={dataObj.id} value={dataObj.id}>
                          <div
                            onClick={() => {
                              setPurposeName(dataObj.purposeName);
                            }}
                          >
                            {dataObj.purposeName}
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddPurposeModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
              {purposeName?.toUpperCase() === "MIGRATION" ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.3rem", width: "100%" }}
                >
                  <Form.Item
                    label="Country to Migrate"
                    name={"countryToMigrate"}
                    style={{ width: "100%" }}
                  >
                    <Select
                      optionFilterProp="children"
                      showSearch
                      filterOption={(input, option) => {
                        console.log("input and option", input, option);
                        return (option?.value ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      {store.leadState?.leadsDropdown?.countries?.map(
                        (dataObj) => {
                          return (
                            <Option
                              key={dataObj.id}
                              value={dataObj.countryName}
                            >
                              <div
                                onClick={() => {
                                  setCountryToMigrate(dataObj.countryName);
                                }}
                                className={"selectOptionDiv"}
                              >
                                {dataObj.countryName}
                              </div>
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                  <a
                    className="plusButton"
                    onClick={() => {
                      setIsAddCountryModalVisible(true);
                    }}
                  >
                    <AiOutlinePlus className="iconColor" />
                  </a>
                </div>
              ) : (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.3rem", width: "100%" }}
                >
                  <Form.Item
                    label="Country to Study"
                    name={"countryId"}
                    style={{ width: "100%" }}
                  >
                    <Select
                      optionFilterProp="children"
                      showSearch
                      filterOption={(input, option) => {
                        console.log("input and option", input, option);
                        return (option?.children ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      {store.leadState.leadsDropdown?.countries?.map(
                        (dataObj) => {
                          return (
                            <Option key={dataObj.id}>
                              {dataObj.countryName}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                  <a
                    className="plusButton"
                    onClick={() => {
                      setIsAddCountryModalVisible(true);
                    }}
                  >
                    <AiOutlinePlus className="iconColor" />
                  </a>
                </div>
              )}
            </div>

            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              {/* {!isContactType && ( */}
              <Form.Item
                label="Campaign"
                name={"campaign"}
                style={{ width: "100%" }}
              >
                <Select>
                  {addContactsState.leadsDropdown?.campaign?.map((dataObj) => {
                    return (
                      <Option value={dataObj.campaignName}>
                        {dataObj.campaignName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {/* )} */}
              <Form.Item
                label="Lead Assign to"
                name={"assignedToEmail"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select weightage",
                //   },
                // ]}
              >
                <Select placeholder="Lead Assign to">
                  {activeEmployees?.map((emp) => (
                    <Select.Option value={emp.emailId}>
                      {emp.fullName}({emp.emailId})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <Form.Item label="Description" name={"description"}>
              <TextArea rows={6} placeholder="Write something here" />
            </Form.Item>

            {email !== null && email !== "" && (
              <Form.Item name={"sendByEmail"} valuePropName="checked">
                <Checkbox
                  onChange={(e) => {
                    console.log(e);
                  }}
                >
                  Send emails
                </Checkbox>
              </Form.Item>
            )}
            {!showMore && (
              <div style={{ margin: "0 0 1rem" }}>
                <Divider style={{ margin: 0 }} />
                <Button
                  type="outlined"
                  size="small"
                  icon={<DownOutlined />}
                  onClick={() => {
                    setShowMore(true);
                  }}
                >
                  View More
                </Button>
              </div>
            )}
            {showMore && (
              <div>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  {" "}
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.3rem", width: "100%" }}
                  >
                    <Form.Item
                      label="How do you know about us?"
                      name={"aboutUsId"}
                      style={{ width: "95%" }}
                    >
                      <Select
                      // defaultValue={
                      //   store.leadState.leadsDropdown?.aboutUs?.[0].id
                      // }
                      >
                        {store.leadState.leadsDropdown?.aboutUs?.map(
                          (dataObj) => {
                            return (
                              <Option key={dataObj.id} value={dataObj.id}>
                                {dataObj.aboutUs}
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    <a
                      className="plusButton"
                      onClick={() => {
                        setIsAddAboutUsModalVisible(true);
                      }}
                    >
                      <AiOutlinePlus className="iconColor" />
                    </a>
                  </div>
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.3rem", width: "100%" }}
                  >
                    <Form.Item
                      label="Lead Source"
                      name={"leadSource"}
                      style={{ width: "95%" }}
                    >
                      <Select>
                        {store.leadState.leadsDropdown?.leadSource?.map(
                          (dataObj) => {
                            return (
                              <Option key={dataObj.id} value={dataObj.id}>
                                <div
                                  onClick={() => {
                                    setLeadSourceName(dataObj.leadSource);
                                    setLeadSourceId(dataObj.id);
                                  }}
                                >
                                  {dataObj.leadSource}
                                </div>
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    <a
                      className="plusButton"
                      onClick={() => {
                        setIsAddLeadSourceModalVisible(true);
                      }}
                    >
                      <AiOutlinePlus className="iconColor" />
                    </a>
                  </div>
                </div>

                {/* <Form.Item
              label="Want to book for councelling?"
              name={"bookingStatus"}
              rules={[
                {
                  required: true,
                  message: "Please select a option!",
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => {
                  setIsCouncelling(e.target.value);
                }}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            {isCouncelling === true && (
              <>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.3rem", width: "100%" }}
                  >
                    <Form.Item
                      label="Counsellor"
                      name={"counsellor"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please select counsellor!",
                        },
                      ]}
                    >
                      <Select
                        onChange={(e) => {
                          setCounsellorId(e);
                        }}
                      >
                        {store.leadState?.leadsDropdown?.consultantList?.map(
                          (dataObj) => {
                            return (
                              <Option key={dataObj.id}>
                                {dataObj.firstName} {dataObj.lastName}
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    <a
                      className="plusButton"
                      onClick={() => {
                        setIsAddConsultantModalVisible(true);
                      }}
                    >
                      <AiOutlinePlus className="iconColor" />
                    </a>
                  </div>
                  <Form.Item
                    label="Booking Date"
                    name={"bookingDate"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please pick a booking date!",
                      },
                    ]}
                  >
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      disabledDate={(cd) => {
                        const d = new Date();
                        return cd < d;
                      }}
                      onChange={(e) => {
                        setAppointmentDate(YYYYMMDD(e));
                      }}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  label="Session Time"
                  name={"sessionTime"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select a session time!",
                    },
                  ]}
                >
                  <Select
                    onChange={(e) => {
                      console.log(e);
                      setSessionTime(e);
                    }}
                  >
                    <Option value={"THIRTY_MINUTES"}>30 Minutes</Option>
                    <Option value={"ONE_HOUR"}>1 Hour</Option>
                    <Option value={"TWO_HOUR"}>2 Hour</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Available Time Slots"
                  name={"startTime"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please pick a your time slot!",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      console.log("selected start time: ", e.target.value);
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {store.timingDropdownState &&
                      store.timingDropdownState.timingDropdown.map(
                        (dataObj, index) => {
                          return (
                            <div key={index}>
                              <Radio value={dataObj.startTime}>
                                {dataObj.startTime} - {dataObj.endTime}
                              </Radio>
                            </div>
                          );
                        }
                      )}
                  </Radio.Group>
                </Form.Item>
              </>
            )} */}

                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.3rem", width: "100%" }}
                >
                  <Form.Item
                    label="Weightage"
                    name={"updateWeightage"}
                    style={{ width: "100%" }}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select weightage",
                    //   },
                    // ]}
                  >
                    <Select>
                      {store.leadState?.leadsDropdown?.weightage?.map(
                        (dataObj, index) => {
                          return (
                            <Option key={dataObj}>
                              <div
                                onClick={(e) => {
                                  setLeadData((previouData) => {
                                    return {
                                      ...previouData,
                                      updateWeightage: dataObj,
                                    };
                                  });
                                }}
                              >
                                {dataObj}
                              </div>
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.3rem", width: "100%" }}
                  >
                    <Form.Item
                      label="Country of Residence"
                      name={"countryOfResidence"}
                      style={{ width: "100%" }}
                    >
                      <Select
                        optionFilterProp="children"
                        showSearch
                        filterOption={(input, option) => {
                          console.log("input and option", input, option);
                          return (option?.value ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                      >
                        {store.leadState?.leadsDropdown?.countries?.map(
                          (dataObj) => {
                            return (
                              <Option
                                key={dataObj.id}
                                value={dataObj.countryName}
                              >
                                <div
                                  onClick={() => {
                                    setCountryOfResidence(dataObj.countryName);
                                  }}
                                  className={"selectOptionDiv"}
                                >
                                  {dataObj.countryName}
                                </div>
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    <a
                      className="plusButton"
                      onClick={() => {
                        setIsAddCountryModalVisible(true);
                      }}
                    >
                      <AiOutlinePlus className="iconColor" />
                    </a>
                  </div>
                </div>
                {/* <Form.Item
            label="Lead Source (Attachment)"
            name={"knowAbout"}
            rules={[
              {
                required: true,
                message: "Please select a option!",
              },
            ]}
          >
            <input type={"file"} />
          </Form.Item> */}
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <Form.Item
                    label="Referral"
                    name={"referral"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select a option!",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        setIsReferral(e.target.value);
                      }}
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {isReferral === true && (
                    <div
                      className="flexRowWithoutStyle"
                      style={{ gap: "0.3rem", width: "100%" }}
                    >
                      <Form.Item
                        label="Select Referral"
                        name={"referredById"}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please select a referral!",
                          },
                        ]}
                      >
                        <Select>
                          {store.leadState.leadsDropdown?.references?.map(
                            (dataObj) => {
                              return (
                                <Option key={dataObj.id}>
                                  {dataObj.firstName} {dataObj.lastName}
                                </Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                      <a
                        className="plusButton"
                        onClick={() => {
                          setIsAddReferralModalVisible(true);
                        }}
                      >
                        <AiOutlinePlus className="iconColor" />
                      </a>
                    </div>
                  )}
                </div>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.3rem", width: "100%" }}
                  >
                    <Form.Item
                      label="Sales Reps"
                      name={"salesRepresentative"}
                      style={{ width: "100%" }}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please select Sales Reps",
                      //   },
                      // ]}
                    >
                      <Select>
                        {store.leadState?.leadsDropdown?.salesRepresentativeResponse?.map(
                          (dataObj) => {
                            return (
                              <Option key={dataObj.id}>
                                <div
                                  onClick={() => {
                                    setLeadData((previouData) => {
                                      return {
                                        ...previouData,
                                        salesRepresentativeId: dataObj.id,
                                        salesRepresentativeName:
                                          dataObj.salesRepName,
                                      };
                                    });
                                    // setSalesRepresentativeId(dataObj.id);
                                    // setSalesRepresentativeName(dataObj.salesRepName);
                                  }}
                                >
                                  {dataObj.salesRepName}
                                </div>
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    <a
                      className="plusButton"
                      onClick={() => {
                        setIsAddSalesRepModalVisible(true);
                      }}
                    >
                      <AiOutlinePlus className="iconColor" />
                    </a>
                  </div>
                  <Form.Item
                    label="Lead Type"
                    name={"leadType"}
                    style={{ width: "100%" }}
                  >
                    <Select defaultValue={"COMPANY ASSIGNED"}>
                      <Option value={"SELF ASSIGNED"}>Self Assigned</Option>
                      <Option value={"COMPANY ASSIGNED"}>
                        Company Assigned
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
                <Space direction="hoizontal">
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.3rem", width: "100%" }}
                  >
                    <Form.Item
                      label="Reminder Date"
                      name={"remainderDate"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please pick a remainder date!",
                        },
                      ]}
                    >
                      <DatePicker
                        format={"DD-MM-YYYY"}
                        disabledDate={(currentDate) => {
                          const date = new Date();
                          return currentDate < date;
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Reminder Time"
                      name={"remainderTime"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please pick a reminder time!",
                        },
                      ]}
                    >
                      <TimePicker />
                    </Form.Item>
                  </div>
                </Space>

                {/* <Form.Item
            label="Attach Document"
            name={"knowAbout"}
            rules={[
              {
                required: true,
                message: "Please select a file!",
              },
            ]}
          >
            <input type={"file"} />
          </Form.Item> */}

                <div>
                  <h2 className="cardHeader">Status Update</h2>
                  <Form.Item
                    label="Today's Description"
                    name={"todayDescription"}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select a file!",
                    //   },
                    // ]}
                  >
                    <TextArea placeholder="Type here" />
                  </Form.Item>
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.3rem" }}
                  >
                    <Form.Item
                      label="Status"
                      name={"leadStatus"}
                      style={{ width: "95%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please select a status!",
                        },
                      ]}
                    >
                      <Select>
                        {store.leadState.leadsDropdown?.leadStatus?.map(
                          (dataObj) => {
                            // if (dataObj?.isDefault) {
                            //   setIsCouncelling(dataObj.statusName);
                            // }
                            return (
                              <Option key={dataObj.id}>
                                <div
                                  onClick={() => {
                                    setLeadStatusId(dataObj.id);
                                    setIsCouncelling(dataObj.statusName);
                                  }}
                                >
                                  {dataObj.statusName}
                                </div>
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    <a
                      className="plusButton"
                      onClick={() => {
                        setIsAddLeadStatusModalVisible(true);
                      }}
                    >
                      <AiOutlinePlus className="iconColor" />
                    </a>
                  </div>
                </div>
                {/* <Form.Item
              label="Want to book for councelling?"
              name={"bookingStatus"}
              rules={[
                {
                  required: true,
                  message: "Please select a option!",
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => {
                  setIsCouncelling(e.target.value);
                }}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item> */}
                {/* {isCouncelling === "COUNSELLING" && (
              <>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.3rem", width: "100%" }}
                  >
                    <Form.Item
                      label="Counsellor"
                      name={"counsellor"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please select counsellor!",
                        },
                      ]}
                    >
                      <Select
                        onChange={(e) => {
                          setCounsellorId(e);
                        }}
                      >
                        {store.leadState?.leadsDropdown?.consultantList?.map(
                          (dataObj) => {
                            return (
                              <Option key={dataObj.id}>
                                {dataObj.firstName} {dataObj.lastName}
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    <a
                      className="plusButton"
                      onClick={() => {
                        setIsAddConsultantModalVisible(true);
                      }}
                    >
                      <AiOutlinePlus className="iconColor" />
                    </a>
                  </div>
                  <Form.Item
                    label="Booking Date"
                    name={"bookingDate"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please pick a booking date!",
                      },
                    ]}
                  >
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      disabledDate={(cd) => {
                        const d = new Date();
                        return cd < d;
                      }}
                      onChange={(e) => {
                        setAppointmentDate(YYYYMMDD(e));
                      }}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  label="Session Time"
                  name={"sessionTime"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select a session time!",
                    },
                  ]}
                >
                  <Select
                    onChange={(e) => {
                      console.log(e);
                      setSessionTime(e);
                    }}
                  >
                    <Option value={"THIRTY_MINUTES"}>30 Minutes</Option>
                    <Option value={"ONE_HOUR"}>1 Hour</Option>
                    <Option value={"TWO_HOUR"}>2 Hour</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Available Time Slots"
                  name={"startTime"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please pick a your time slot!",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      console.log("selected start time: ", e.target.value);
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {store.timingDropdownState &&
                      store.timingDropdownState.timingDropdown.map(
                        (dataObj, index) => {
                          return (
                            <div key={index}>
                              <Radio value={dataObj.startTime}>
                                {dataObj.startTime} - {dataObj.endTime}
                              </Radio>
                            </div>
                          );
                        }
                      )}
                  </Radio.Group>
                </Form.Item>
              </>
            )} */}
                {isCouncelling === "BOOK_AN_APPOINTMENT" ? (
                  <>
                    <Form.Item
                      label="Want to confirm appointment now?"
                      name={"bookingStatus"}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please select a option!",
                      //   },
                      // ]}
                    >
                      <Radio.Group
                        onChange={(e) => {
                          setIsBookingCouncelling(e.target.value);
                        }}
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {isBookingCouncelling === false ? (
                      <>
                        <Form.Item
                          label="Appointment Date"
                          name={"appointmentDate"}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please enter appointment date!",
                            },
                          ]}
                        >
                          <DatePicker
                            disabledDate={(cd) => {
                              const date = new Date();
                              return (
                                cd.day() ===
                                  store.timingDropdownState?.workingDaysOfCounsellor?.find(
                                    (data) => {
                                      return data === 0;
                                    }
                                  ) ||
                                cd.day() ===
                                  store.timingDropdownState?.workingDaysOfCounsellor?.find(
                                    (data) => {
                                      return data === 1;
                                    }
                                  ) ||
                                cd.day() ===
                                  store.timingDropdownState?.workingDaysOfCounsellor?.find(
                                    (data) => {
                                      return data === 2;
                                    }
                                  ) ||
                                cd.day() ===
                                  store.timingDropdownState?.workingDaysOfCounsellor?.find(
                                    (data) => {
                                      return data === 3;
                                    }
                                  ) ||
                                cd.day() ===
                                  store.timingDropdownState?.workingDaysOfCounsellor?.find(
                                    (data) => {
                                      return data === 4;
                                    }
                                  ) ||
                                cd.day() ===
                                  store.timingDropdownState?.workingDaysOfCounsellor?.find(
                                    (data) => {
                                      return data === 5;
                                    }
                                  ) ||
                                cd.day() ===
                                  store.timingDropdownState?.workingDaysOfCounsellor?.find(
                                    (data) => {
                                      return data === 6;
                                    }
                                  ) ||
                                cd < moment().subtract(1, "days")
                              );
                            }}
                          />
                        </Form.Item>
                        <div
                          className="flexRowWithoutStyle"
                          style={{ gap: "0.3rem", width: "100%" }}
                        >
                          <Form.Item
                            label="Counsellor"
                            name={"counsellor"}
                            style={{ width: "100%" }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select counsellor!",
                            //   },
                            // ]}
                          >
                            <Select>
                              {store.leadState?.leadsDropdown?.consultantList?.map(
                                (dataObj) => {
                                  return (
                                    <Option key={dataObj.id}>
                                      <div
                                        onClick={() => {
                                          setCounsellorId(dataObj.id);
                                          dispatch({
                                            type: "FETCH_WORKING_DAYS_OF_COUNSELLOR_REQUEST",
                                            payload: {
                                              consultantId: dataObj.id,
                                            },
                                          });
                                        }}
                                        className={"selectOptionDiv"}
                                      >
                                        {dataObj.firstName} {dataObj.lastName}
                                      </div>
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                          <a
                            className="plusButton"
                            onClick={() => {
                              setIsAddConsultantModalVisible(true);
                            }}
                          >
                            <AiOutlinePlus className="iconColor" />
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="flexRowWithoutStyle"
                          style={{
                            justifyContent: "space-between",
                            gap: "1rem",
                          }}
                        >
                          <div
                            className="flexRowWithoutStyle"
                            style={{ gap: "0.3rem", width: "100%" }}
                          >
                            <Form.Item
                              label="Counsellor"
                              name={"counsellor"}
                              style={{ width: "100%" }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select counsellor!",
                                },
                              ]}
                            >
                              <Select>
                                {store.leadState?.leadsDropdown?.consultantList?.map(
                                  (dataObj) => {
                                    return (
                                      <Option key={dataObj.id}>
                                        <div
                                          onClick={() => {
                                            setCounsellorId(dataObj.id);
                                            dispatch({
                                              type: "FETCH_WORKING_DAYS_OF_COUNSELLOR_REQUEST",
                                              payload: {
                                                consultantId: dataObj.id,
                                              },
                                            });
                                          }}
                                          className={"selectOptionDiv"}
                                        >
                                          {dataObj.firstName} {dataObj.lastName}
                                        </div>
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                            </Form.Item>
                            <a
                              className="plusButton"
                              onClick={() => {
                                setIsAddConsultantModalVisible(true);
                              }}
                            >
                              <AiOutlinePlus className="iconColor" />
                            </a>
                          </div>
                          <Form.Item
                            label="Booking Date"
                            name={"appointmentDate"}
                            style={{ width: "100%" }}
                            rules={[
                              {
                                required: true,
                                message: "Please pick a booking date!",
                              },
                            ]}
                          >
                            <DatePicker
                              format={"DD-MM-YYYY"}
                              disabledDate={(cd) => {
                                const d = new Date();
                                return (
                                  cd.day() ===
                                    store.timingDropdownState?.workingDaysOfCounsellor?.find(
                                      (data) => {
                                        return data === 0;
                                      }
                                    ) ||
                                  cd.day() ===
                                    store.timingDropdownState?.workingDaysOfCounsellor?.find(
                                      (data) => {
                                        return data === 1;
                                      }
                                    ) ||
                                  cd.day() ===
                                    store.timingDropdownState?.workingDaysOfCounsellor?.find(
                                      (data) => {
                                        return data === 2;
                                      }
                                    ) ||
                                  cd.day() ===
                                    store.timingDropdownState?.workingDaysOfCounsellor?.find(
                                      (data) => {
                                        return data === 3;
                                      }
                                    ) ||
                                  cd.day() ===
                                    store.timingDropdownState?.workingDaysOfCounsellor?.find(
                                      (data) => {
                                        return data === 4;
                                      }
                                    ) ||
                                  cd.day() ===
                                    store.timingDropdownState?.workingDaysOfCounsellor?.find(
                                      (data) => {
                                        return data === 5;
                                      }
                                    ) ||
                                  cd.day() ===
                                    store.timingDropdownState?.workingDaysOfCounsellor?.find(
                                      (data) => {
                                        return data === 6;
                                      }
                                    ) ||
                                  cd < moment().subtract(1, "days")
                                );
                              }}
                              onChange={(e) => {
                                setAppointmentDate(YYYYMMDD(e));
                              }}
                            />
                          </Form.Item>
                        </div>
                        <Form.Item
                          label="Session Time"
                          name={"sessionTime"}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please select a session time!",
                            },
                          ]}
                        >
                          <Select
                            onChange={(e) => {
                              console.log(e);
                              setSessionTime(e);
                            }}
                          >
                            <Option value={"THIRTY_MINUTES"}>30 Minutes</Option>
                            <Option value={"ONE_HOUR"}>1 Hour</Option>
                            <Option value={"TWO_HOUR"}>2 Hour</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="Available Time Slots"
                          name={"startTime"}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please pick a your time slot!",
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={(e) => {
                              console.log(
                                "selected start time: ",
                                e.target.value
                              );
                            }}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            {store.timingDropdownState &&
                              store.timingDropdownState.timingDropdown.map(
                                (dataObj, index) => {
                                  return (
                                    <div key={index}>
                                      <Radio value={dataObj.startTime}>
                                        {dataObj.startTime} - {dataObj.endTime}
                                      </Radio>
                                    </div>
                                  );
                                }
                              )}
                          </Radio.Group>
                        </Form.Item>
                      </>
                    )}
                  </>
                ) : null}
                <div
                  style={{
                    margin: "1rem 0",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    type="outlined"
                    size="small"
                    icon={<UpOutlined />}
                    onClick={() => {
                      setShowMore(false);
                    }}
                    // className="trigger-button"
                  >
                    View Less
                  </Button>
                  {/* <div
                    className="sibling-div"
                  ></div> */}
                  <Divider style={{ margin: "0" }} />
                </div>
              </div>
            )}
          </div>
        </div>
        <Form.Item
          name="leadSubmit"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                console.log("value frm head agent", value);
                console.log(
                  "is percentage frm head agent",
                  getFieldValue("mobile"),
                  getFieldValue("email")
                );
                if (
                  (getFieldValue("email") === undefined ||
                    getFieldValue("email") === "" ||
                    getFieldValue("email") === null) &&
                  (getFieldValue("mobile") === undefined ||
                    getFieldValue("mobile") === "" ||
                    getFieldValue("mobile") === null)
                ) {
                  return Promise.reject(
                    new Error("Please enter either email or phone number!")
                  );
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
          hasFeedback
        >
          <Button name="leadSubmit" block type="primary" htmlType="submit">
            Add Lead
          </Button>
        </Form.Item>
      </Form>
      {isAddPurposeModalVisible && (
        <Purpose
          isAddPurposeModalVisible={isAddPurposeModalVisible}
          setIsAddPurposeModalVisible={setIsAddPurposeModalVisible}
        />
      )}
      {isAddAboutUsModalVisible && (
        <AddAboutUs
          isAddAboutUsModalVisible={isAddAboutUsModalVisible}
          setIsAddAboutUsModalVisible={setIsAddAboutUsModalVisible}
        />
      )}
      {isAddCountryModalVisible && (
        <AddCountry
          isAddCountryModalVisible={isAddCountryModalVisible}
          setIsAddCountryModalVisible={setIsAddCountryModalVisible}
        />
      )}
      {isAddLeadSourceModalVisible && (
        <AddLeadSource
          isAddLeadSourceModalVisible={isAddLeadSourceModalVisible}
          setIsAddLeadSourceModalVisible={setIsAddLeadSourceModalVisible}
        />
      )}
      {isAddLeadStatusModalVisible && (
        <AddLeadStatus
          isAddLeadStatusModalVisible={isAddLeadStatusModalVisible}
          setIsAddLeadStatusModalVisible={setIsAddLeadStatusModalVisible}
        />
      )}
      {isAddConsultantModalVisible && (
        <AddEmployee
          AddEmployeeTitle={"Add Consultant"}
          isConsultantFrmConsultant={true}
          isAddEmployeeModalVisible={isAddConsultantModalVisible}
          setIsAddEmployeeModalVisible={setIsAddConsultantModalVisible}
        />
      )}
      {isAddReferralModalVisible && (
        <AddReferral
          isAddReferralModalVisible={isAddReferralModalVisible}
          setIsAddReferralModalVisible={setIsAddReferralModalVisible}
        />
      )}
      {isAddContactVisible && (
        <AddContacts
          isAddContactVisible={isAddContactVisible}
          setIsAddContactVisible={setIsAddContactVisible}
        />
      )}
      {isAddSalesRepModalVisible && (
        <AddSalesRep
          isAddSalesRepModalVisible={isAddSalesRepModalVisible}
          setIsAddSalesRepModalVisible={setIsAddSalesRepModalVisible}
        />
      )}
    </Drawer>
  );
};

export default AddLead;
