import { Button } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SiderDemo from "../../Components/Layout/SiderDemo";
import greenTick from "./../../Assets/greenTick.png";

const PaymentSuccess = ({ image, messageText1, messageText2 }) => {
  const store = useSelector((state) => {
    return {
      loginState: state.ProfileReducer,
    };
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentId = new URLSearchParams(window.location.search).get(
    "paymentId"
  );
  useEffect(() => {
    if (paymentId) {
      dispatch({
        type: "VERIFY_SUBSCRIPTION_PURCHASE_REQUEST",
        payload: { paymentId: paymentId },
      });
    }
  }, [paymentId]);
  return (
    <SiderDemo>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          alignItems: "flex-start",
        }}
      >
        <div
          className="subscription_payment flipInX animated"
          style={{
            width: "35vw",
            height: "minContent",
            display: "flex",
            flexDirection: "column",
            marginTop: "4rem",
            borderRadius: "10px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
        >
          <div
            style={{
              padding: "1.5rem",
              width: "100%",
              backgroundColor: "#f0f2f5",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={image} alt="Green Tick" height={39} width={39} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem",
            }}
          >
            <p style={{ fontSize: "18px" }}>{messageText1}</p>
            <p style={{ fontSize: "18px" }}>{messageText2}</p>
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "30px",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Go To Dashboard
            </Button>
          </div>
        </div>
      </div>
    </SiderDemo>
  );
};

export default PaymentSuccess;
