import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const AddServiceSource = ({ isAddSSVisible, setIsAddSSVisible }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addQuotationSourceSubmitHandler = (value) => {
    const formData = {
      ...value,
    };
    dispatch({
      type: "ADD_SERVICE_SOURCE_REQUEST",
      payload: formData,
      isSuccessFn: true,
      successFunction: () => {
        dispatch({
          type: "FETCH_SERVICE_SOURCE_REQUEST",
          payload: {},
        });
      },
    });
    setIsAddSSVisible(false);
  };
  return (
    <Modal
      title="Add Service Source"
      open={isAddSSVisible}
      onCancel={() => {
        setIsAddSSVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addQuotationSourceSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Service Source"
            name={"sourceName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter source name!",
              },
            ]}
          >
            <Input placeholder="Source name" />
          </Form.Item>
        </div>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Service source
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddServiceSource;
