import React from "react";
import { Button, Form, Input, Modal } from "antd";
import { useDispatch } from "react-redux";

const AddAboutUs = ({
  isAddAboutUsModalVisible,
  setIsAddAboutUsModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addAboutUsSubmitHandler = (value) => {
    dispatch({
      type: "ADD_ABOUT_US_REQUEST",
      payload: { ...value, branchId: localStorage.getItem("branchId") },
    });
    form.resetFields();
    setIsAddAboutUsModalVisible(false);
  };
  return (
    <Modal
      title="Add About Us"
      open={isAddAboutUsModalVisible}
      onCancel={() => {
        setIsAddAboutUsModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addAboutUsSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="About Us"
          name={"name"}
          rules={[
            {
              required: true,
              message: "Please enter about us!",
            },
          ]}
        >
          <Input placeholder="About us" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add About Us
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddAboutUs;
