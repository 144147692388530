import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  MdOutlineAssignmentTurnedIn,
  MdOutlineDisabledVisible,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import department1 from "./../../../Assets/department2.png";
import activeDepartment from "./../../../Assets/department.png";

import AddDepartment from "./AddDepartment";
import SiderDemo from "../../../Components/Layout/SiderDemo";
import { formattedDateTime } from "../../../HelperFunction";
import UpdateDepartment from "./UpdateDepartment";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { BsCircle } from "react-icons/bs";
import Confirmation from "./Confirmation";
import AssignRoles from "./AssignRoles";
import { BiBlock } from "react-icons/bi";
import TableSkeleton from "../../../Helpers/TableSkeleton/tableSkelaton";
import AddPermission from "../../RolesAndPermission/AddPermission";
const columns = [
  {
    title: "User Group",
    dataIndex: "departmentName",
    sorter: (a, b) => {
      if (a.departmentName < b.departmentName) {
        return -1;
      }
      if (a.departmentName > b.departmentName) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },

  {
    title: "Created On",
    dataIndex: "createdOn",
    align: "center",
  },

  {
    title: "Roles",
    dataIndex: "roles",
    align: "center",
  },
  // {
  //   title: "Permissions",
  //   dataIndex: "permissions",
  // },
  {
    title: "Status",
    dataIndex: "isActive",
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const Departments = () => {
  const store = useSelector((state) => {
    return {
      departmentState: state.DepartmentReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const [isAddDepartmentModalVisible, setIsAddDepartmentModalVisible] =
    useState(false);
  const [isUpdateDepartmentModalVisible, setIsUpdateDepartmentModalVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const [isconfirmationModalVisible, setIsconfirmationModalVisible] =
    useState(false);
  const [departmentType, setDepartmentType] = useState("list-all");
  const [isAssignRolesModalVisible, setIsAssignRolesModalVisible] =
    useState(false);
  const [isAddPermissionModalVisible, setIsAddPermissionModalVisible] =
    useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  useEffect(() => {
    if (departmentType === "list-active") {
      dispatch({
        type: "FETCH_ACTIVE_DEPARTMENT_REQUEST",
        payload: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else {
      dispatch({
        type: "FETCH_DEPARTMENT_REQUEST",
        payload: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    }
  }, [departmentType, store.branchState?.branchRecord]);

  let data = [];
  if (departmentType === "list-all") {
    data = store?.departmentState.departments?.data?.map((dataObj) => {
      return {
        key: dataObj?.id,
        departmentName: dataObj?.departmentName,
        modifierDetails: dataObj?.modifierDetails?.userName,
        creatorDetails: dataObj?.creatorDetails?.userName,
        deactivationDateTime: formattedDateTime(dataObj?.deactivationDateTime),
        activationDateTime: formattedDateTime(dataObj?.activationDateTime),
        modifiedOn: formattedDateTime(dataObj?.modifiedOn),
        createdOn: formattedDateTime(dataObj?.createdOn),
        permissions: dataObj.associatedPermissionList?.map((dataobj, index) => {
          return (
            <Tag key={index} style={{ margin: "2px" }} color={"blue"}>
              {dataobj}
            </Tag>
          );
        }),
        roles: Object.entries(dataObj.rolesAndPermissionList)?.map(
          (dataobj) => {
            if (dataobj[1] !== null) {
              return (
                <Tag
                  key={dataobj}
                  className="rolename"
                  style={{ margin: "2px" }}
                  color={"green"}
                >
                  {dataobj[0]}
                </Tag>
              );
            }
            return;
          }
        ),
        isActive: dataObj?.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),

        actions: (
          <Space size="middle">
            {store.profileState?.profile?.department?.rolesAndPermissionList?.DEPARTMENT?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update User Group">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsUpdateDepartmentModalVisible(true);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {/* <Tooltip title="Delete department">
                <Popconfirm
                  title="Are you sure to delete this department?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_DEPARTMENT_REQUEST",
                      payload: { id: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip> */}
            {/* {store.profileState?.profile?.department?.rolesAndPermissionList?.DEPARTMENT?.includes(
              "ASSIGN_ROLES"
            ) && (
              <Tooltip title="Assign Permission">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj.departmentResponse);
                      setIsAssignRolesModalVisible(true);
                    }}
                  >
                    <MdOutlineAssignmentTurnedIn />
                  </div>
                </a>
              </Tooltip>
            )} */}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.EMPLOYEES?.includes(
              "ASSIGN_PERMISSION"
            ) && (
              <Tooltip title="Assign Permission">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAddPermissionModalVisible(true);
                    }}
                  >
                    <MdOutlineAssignmentTurnedIn />
                  </div>
                </a>
              </Tooltip>
            )}
            {dataObj?.isActive &&
            store.profileState?.profile?.department?.rolesAndPermissionList?.DEPARTMENT?.includes(
              "CHANGE_STATUS"
            ) ? (
              <Tooltip title="Deactivate User Group">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsconfirmationModalVisible(true);
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            ) : store.profileState?.profile?.department?.rolesAndPermissionList?.DEPARTMENT?.includes(
                "CHANGE_STATUS"
              ) ? (
              <Tooltip title="Activate User Group">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsconfirmationModalVisible(true);
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            ) : null}
          </Space>
        ),
      };
    });
  } else {
    data = store?.departmentState.activeDepartments?.data?.map((dataObj) => {
      return {
        key: dataObj?.id,
        departmentName: dataObj?.departmentName,
        modifierDetails: dataObj?.modifierDetails?.userName,
        creatorDetails: dataObj?.creatorDetails?.userName,
        deactivationDateTime: formattedDateTime(dataObj?.deactivationDateTime),
        activationDateTime: formattedDateTime(dataObj?.activationDateTime),
        modifiedOn: formattedDateTime(dataObj?.modifiedOn),
        createdOn: formattedDateTime(dataObj?.createdOn),
        permissions: dataObj.associatedPermissionList?.map((dataobj, index) => {
          return (
            <Tag key={index} style={{ margin: "2px" }} color={"blue"}>
              {dataobj}
            </Tag>
          );
        }),
        roles: Object.entries(dataObj.rolesAndPermissionList)?.map(
          (dataobj) => {
            if (dataobj[1] !== null) {
              return (
                <Tag
                  key={dataobj}
                  className="rolename"
                  style={{ margin: "2px" }}
                  color={"green"}
                >
                  {dataobj[0]}
                </Tag>
              );
            }
            return;
          }
        ),
        isActive: dataObj?.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {store.profileState?.profile?.department?.rolesAndPermissionList?.DEPARTMENT?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update User Group">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateDepartmentModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.EMPLOYEES?.includes(
              "ASSIGN_PERMISSION"
            ) && (
              <Tooltip title="Assign Permission">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAddPermissionModalVisible(true);
                    }}
                  >
                    <MdOutlineAssignmentTurnedIn />
                  </div>
                </a>
              </Tooltip>
            )}
            {/* <Tooltip title="Delete department">
                <Popconfirm
                  title="Are you sure to delete this department?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_DEPARTMENT_REQUEST",
                      payload: { id: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip> */}
            {dataObj?.isActive &&
            store.profileState?.profile?.department?.rolesAndPermissionList?.DEPARTMENT?.includes(
              "CHANGE_STATUS"
            ) ? (
              <Tooltip title="Deactivate User Group">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsconfirmationModalVisible(true);
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            ) : store.profileState?.profile?.department?.rolesAndPermissionList?.DEPARTMENT?.includes(
                "CHANGE_STATUS"
              ) ? (
              <Tooltip title="Activate User Group">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsconfirmationModalVisible(true);
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            ) : null}
          </Space>
        ),
      };
    });
  }

  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          <div style={{ width: "20%" }}>
            {store.profileState?.profile?.department?.rolesAndPermissionList?.DEPARTMENT?.includes(
              "ADD"
            ) && (
              <button
                className="button"
                onClick={() => {
                  setIsAddDepartmentModalVisible(true);
                }}
              >
                <span>Add User Group</span>
              </button>
            )}
          </div>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={department1} size={20} shape="square" />
                    <div>All User Group</div>
                  </div>
                ),
                value: "list-all",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={activeDepartment} size={20} shape="square" />
                    <div>Active User Group</div>
                  </div>
                ),
                value: "list-active",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setDepartmentType(value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {store?.departmentState.isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <Table
                bordered
                columns={columns}
                dataSource={data}
                pagination={false}
              />
              {departmentType === "list-all" &&
              store?.departmentState?.departments?.totalData ? (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={page}
                  defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={store?.departmentState?.departments?.totalPage * 10}
                />
              ) : null}
              {departmentType === "list-active" &&
              store?.departmentState?.activeDepartments?.totalData ? (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={page}
                  defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={
                    store?.departmentState?.activeDepartments?.totalPage * 10
                  }
                />
              ) : null}
            </>
          )}
        </div>
      </div>
      {isAssignRolesModalVisible && (
        <AssignRoles
          record={record}
          isAssignRolesModalVisible={isAssignRolesModalVisible}
          setIsAssignRolesModalVisible={setIsAssignRolesModalVisible}
        />
      )}
      <Confirmation
        onSubmit={(value) => {
          console.warn(value, "value from teh confirmation box");
          setIsconfirmationModalVisible(false);
          if (record.isActive) {
            dispatch({
              type: "DEACTIVATE_DEPARTMENT_REQUEST",
              payload: { id: record.id, values: value },
            });

            return;
          }
          dispatch({
            type: "ACTIVATE_DEPARTMENT_REQUEST",
            payload: { id: record.id, values: value },
          });
        }}
        isconfirmationModalVisible={isconfirmationModalVisible}
        setIsconfirmationModalVisible={setIsconfirmationModalVisible}
      />
      {isAddDepartmentModalVisible && (
        <AddDepartment
          isAddDepartmentModalVisible={isAddDepartmentModalVisible}
          setIsAddDepartmentModalVisible={setIsAddDepartmentModalVisible}
        />
      )}
      {isUpdateDepartmentModalVisible && (
        <UpdateDepartment
          record={record}
          isUpdateDepartmentModalVisible={isUpdateDepartmentModalVisible}
          setIsUpdateDepartmentModalVisible={setIsUpdateDepartmentModalVisible}
        />
      )}
      {isAddPermissionModalVisible && (
        <AddPermission
          employeeRecord={record}
          isAddPermissionModalVisible={isAddPermissionModalVisible}
          setIsAddPermissionModalVisible={setIsAddPermissionModalVisible}
        />
      )}
    </SiderDemo>
  );
};

export default Departments;
